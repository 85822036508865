/**
 * Labstep
 */

import MoleculeSafety from 'labstep-web/components/Molecule/Safety';
import Pictograms from 'labstep-web/components/Molecule/Safety/Pictograms';
import { EntityReadEntityContainer } from 'labstep-web/containers';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import {
  getEditable,
  getEntityDefault,
  stopEditingAndFocus,
} from 'labstep-web/core/DataGrid/utils';
import { Molecule } from 'labstep-web/models';
import React from 'react';
import { colDefMoleculeType } from '../types';

/**
 * Column definition for Safety fields
 * @param field Field (IUPACName, MolecularWeight, Density, MolecularFormula, CAS)
 * @param options Options
 * @returns Column definition
 */
const colDefSafety: colDefMoleculeType = (options = {}) => {
  const { getNestedEntity, columnProps, getCreateProps } = options;
  const getEntity = getNestedEntity || getEntityDefault;
  const editable = getEditable(getNestedEntity);

  return {
    colId: 'pubchem_safety',
    cellEditor: (params) => {
      const entity = getEntity(params);
      if (!(entity instanceof Molecule) || !entity.properties) {
        return null;
      }
      return (
        <EntityReadEntityContainer
          entityName={entity.entityName}
          id={entity.idAttr}
        >
          {({ entity: molecule }) => (
            <MoleculeSafety
              parent={molecule}
              pubChemMetadata={molecule.properties}
              modalProps={{
                open: true,
                onClose: () => stopEditingAndFocus(params),
                viewComponent: null,
              }}
            />
          )}
        </EntityReadEntityContainer>
      );
    },
    cellEditorPopup: true,
    cellRenderer: (params) => {
      const entity = getEntity(params);
      if (
        !(entity instanceof Molecule) ||
        !entity.properties ||
        entity.properties.Safety.pictograms?.length === 0
      ) {
        return (
          <DataGridPlaceholder
            params={params}
            children="Select"
            editable={editable}
          />
        );
      }
      return (
        <Pictograms
          pubChemMetadata={entity.properties}
          isInline
          size="mini"
        />
      );
    },
    headerName: 'Safety',
    editable: (params) =>
      editable(params) &&
      !!getEntity(params)?.properties &&
      // templating not supported yet
      !getCreateProps?.(params),
    valueGetter: (params): string | null => {
      const entity = getEntity(params);
      if (entity instanceof Molecule && entity.properties) {
        return JSON.stringify(entity.properties.Safety);
      }
      return null;
    },
    ...columnProps,
  };
};

export default colDefSafety;
