/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/Reference
 * @desc Renders an Reference node
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import ProtocolChildRouter from 'labstep-web/containers/Router/ProtocolChild';
import Icon from 'labstep-web/core/Icon';
import { ParamsContext } from 'labstep-web/hoc/Params/context';
import InlineView from 'labstep-web/prosemirror/components/Inline/View';
import ProtocolTimerModal from 'labstep-web/components/ProtocolTimer/Modal';
import ProtocolTimerActionEdit from 'labstep-web/components/ProtocolTimer/Action/Edit';
import { ParamsHOC } from 'labstep-web/hoc/Params';
import TableModal from 'labstep-web/components/Table/Modal';
import DevicePreview from 'labstep-web/components/Device/Preview';
import { IIconProps } from 'labstep-web/core/Icon/types';
import { ToggleModalType } from 'labstep-web/hoc/Modal/types';
import { Entity } from 'labstep-web/prosemirror/extensions/referencing/plugin/types';
import {
  Experiment,
  Metadata,
  Protocol,
  ProtocolDevice,
  ProtocolStep,
  ProtocolTable,
  ProtocolTimer,
  ProtocolValue,
} from 'labstep-web/models';
import { METADATA_DEFAULT_LABEL_DATA } from 'labstep-web/models/metadata/constants';
import { ICONS } from 'labstep-web/constants';
import { useStepsOrderContext } from 'labstep-web/prosemirror/context';
import { getExperimentPath } from '../../Experiment/utils';
import { IReferenceNodeContentProps } from './types';

export const getIcon = (
  value: string,
): IIconProps['name'] | undefined => {
  if (value.includes('step')) {
    return ICONS.protocol.step;
  }
  if (value.includes('table')) {
    return ICONS.protocol.table;
  }
  if (value.includes('timer')) {
    return ICONS.protocol.timer;
  }
  if (value.includes('metadata')) {
    return ICONS.protocol.metadata;
  }
  if (value.includes('value')) {
    return ICONS.protocol.value;
  }
  if (value.includes('experiment')) {
    return ICONS.protocol.primary;
  }
  if (value.includes('device')) {
    return ICONS.device.primary;
  }
  return undefined;
};

export const getLabel = (
  entity: Entity,
  stepIds: (number | string)[],
) => {
  if (entity instanceof Metadata || entity instanceof ProtocolValue) {
    return entity.label || METADATA_DEFAULT_LABEL_DATA;
  }

  if (entity instanceof ProtocolStep) {
    const position =
      stepIds.findIndex(
        (stepId) => String(stepId) === String(entity.guid),
      ) + 1;
    return `Step ${position}`;
  }
  return entity.name;
};

export const getSecondaryLabel = (
  entity: Entity,
  withIcon = false,
) => {
  let experiment;
  if (entity instanceof ProtocolValue) {
    experiment = entity.experiment;
  } else if (entity instanceof Metadata) {
    experiment =
      entity.metadata_thread && entity.metadata_thread.experiment;
  }

  if (!experiment || experiment.is_root) {
    return null;
  }

  return (
    <span>
      {withIcon ? <Icon name={ICONS.experiment.primary} /> : 'from'}{' '}
      {experiment.name}
    </span>
  );
};

export const ReferenceNodeContent: React.FC<
  IReferenceNodeContentProps
> = ({ entity, parentEntity, history }) => {
  const { stepsOrder } = useStepsOrderContext();

  const viewComponent = ({
    toggleModal,
  }: {
    toggleModal?: ToggleModalType;
  }) => (
    <InlineView
      entity={entity}
      onClick={toggleModal}
      icon={getIcon(entity.entityName)}
    >
      {getLabel(entity, stepsOrder)}{' '}
      {parentEntity instanceof Experiment &&
        parentEntity.is_root &&
        getSecondaryLabel(entity, false)}{' '}
      <Icon name="external" />
    </InlineView>
  );

  if (entity instanceof Experiment) {
    return (
      <InlineView
        entity={entity}
        onClick={(): void => history.push(getExperimentPath(entity))}
        icon={getIcon(entity.entityName)}
      >
        {getLabel(entity, stepsOrder)} <Icon name="external" />
      </InlineView>
    );
  }

  if (entity instanceof ProtocolTimer) {
    return entity.is_experiment_child ? (
      <ProtocolTimerModal
        protocolTimer={entity}
        protocol={parentEntity as Experiment}
        viewComponent={viewComponent}
      />
    ) : (
      <ProtocolTimerActionEdit
        protocolTimer={entity}
        viewComponent={viewComponent}
      />
    );
  }

  if (entity instanceof ProtocolValue) {
    return (
      <ProtocolChildRouter route="value_show" id={entity.guid}>
        {({ navigate }) =>
          viewComponent({
            toggleModal: navigate,
          })
        }
      </ProtocolChildRouter>
    );
  }

  if (entity instanceof Metadata) {
    return (
      <ProtocolChildRouter route="metadata_show" id={entity.id}>
        {({ navigate }) =>
          viewComponent({
            toggleModal: navigate,
          })
        }
      </ProtocolChildRouter>
    );
  }

  if (entity instanceof ProtocolTable) {
    return (
      <TableModal
        table={entity}
        viewComponent={viewComponent}
        allowLocking={parentEntity.entityName === Protocol.entityName}
      />
    );
  }

  if (entity instanceof ProtocolStep) {
    if (stepsOrder.indexOf(entity.guid) === -1) {
      return (
        <InlineView
          entity={entity}
          onClick={() => null}
          icon="ban"
          children="deleted"
        />
      );
    }
    return (
      <ParamsHOC historyAction="replace">
        <ParamsContext.Consumer>
          {({ setParams }) => (
            <InlineView
              entity={entity}
              onClick={() => {
                setParams({ step_id: entity.guid });
              }}
              icon={getIcon(entity.entityName)}
            >
              {getLabel(entity, stepsOrder)} <Icon name="external" />
            </InlineView>
          )}
        </ParamsContext.Consumer>
      </ParamsHOC>
    );
  }

  if (entity instanceof ProtocolDevice) {
    return (
      <DevicePreview
        device={entity.device}
        modalProps={{
          viewComponent: ({ toggleModal }) => (
            <InlineView
              entity={entity}
              icon={getIcon(entity.entityName)}
              onClick={toggleModal}
            >
              {getLabel(entity, stepsOrder)} <Icon name="external" />
            </InlineView>
          ),
        }}
      />
    );
  }

  return null;
};

export default withRouter(ReferenceNodeContent);
