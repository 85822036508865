// @ts-strict-ignore
/**
 * Labstep
 *
 * @module core/ProtocolCondition/Modal/Content/Right/VariableManager
 * @desc Protocol Condition Modal Content Right Variable Manager
 */

import Container from 'labstep-web/components/Layout/Container';
import RightPaneSubPage from 'labstep-web/components/Layout/RightPane/SubPage';
import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import ProtocolActionAddVariableMetadata from 'labstep-web/components/Protocol/Action/AddVariable/Metadata';
import ProtocolActionAddVariableProtocolValue from 'labstep-web/components/Protocol/Action/AddVariable/ProtocolValue';
import { ICONS } from 'labstep-web/constants';
import { HorizontalDivider } from 'labstep-web/core/Divider';
import { capitalize } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IProtocolConditionModalContentRightVariableManagerProps } from './types';

export const ProtocolConditionModalContentRightVariableManager: React.FC<
  IProtocolConditionModalContentRightVariableManagerProps
> = ({ protocol }) => {
  const { variableManager, setVariableManager } =
    useProtocolConditionModalContext();

  return (
    <RightPaneSubPage
      icon={ICONS.protocol_condition.info[variableManager]}
      header={capitalize(variableManager)}
      children={
        <div>
          <Container>
            <ProtocolActionAddVariableProtocolValue
              protocol={protocol}
              isInput={variableManager === 'inputs'}
            />
          </Container>
          <Container>
            <HorizontalDivider />
          </Container>
          <ProtocolActionAddVariableMetadata
            protocol={protocol}
            isInput={variableManager === 'inputs'}
          />
        </div>
      }
      onClose={(): void => setVariableManager(null)}
    />
  );
};

export default ProtocolConditionModalContentRightVariableManager;
