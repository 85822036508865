/**
 * Labstep
 *
 * @module components/EntityImport/Action/Create
 * @desc Create Action for EntityImport
 */

import {
  Device,
  EntityImport,
  EntityImportColDef,
  EntityImportDataRowData,
  Resource,
} from 'labstep-web/models';
import React, { useCallback, useMemo, useState } from 'react';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import { AGGridEntityImportExcelService } from 'labstep-web/services/agGrid/ag-grid-entity-import-excel.service';
import { AGGridEntityImportService } from 'labstep-web/services/agGrid/ag-grid-entity-import.service';
import { EntityCreateContainer } from 'labstep-web/containers';
import {
  EntityImportActionCreateContainerProps,
  EntityImportActionCreateProps,
} from './types';
import EntityImportActionCreateStepsImportMode from './Steps/ImportMode';
import EntityImportActionCreateStepsImportFileMapping from './Steps/ImportFileMapping';
import EntityImportActionCreateStepsFieldDefinitions from './Steps/FieldDefinitions';
import EntityImportActionCreateStepsPreview from './Steps/Preview';

export const ENTITY_IMPORT_CREATE_ROUTE_ID = 'entity-import-create';
export const N_EMPTY_ROWS = 50;

// eslint-disable-next-line no-shadow
enum Steps {
  ImportMode = 'Import Mode',
  ImportFileMapping = 'Import File Mapping',
  FieldDefinitions = 'Field Definitions',
  Preview = 'Preview & Validate',
}

export const EntityImportActionCreate: React.FC<
  EntityImportActionCreateProps
> = ({
  entityName,
  templateId,
  importData,
  setImportData,
  create,
  status,
}) => {
  const [importEntityName, setImportEntityName] =
    useState<typeof entityName>(entityName);
  const [headerRowIndex, setHeaderRowIndex] = useState<number>(0);
  const [nameColumn, setNameColumn] = useState<string | null>(null);
  const [rowData, setRowData] = useState<EntityImportDataRowData>([]);
  const [columnDefs, setColumnDefs] = useState<EntityImportColDef[]>(
    [],
  );
  const [selectedTemplateEntity, setSelectedTemplateEntity] =
    useState<Resource | Device | null>(null);

  const reset = useCallback((): void => {
    setImportData(undefined);
    setImportEntityName(entityName);
    setHeaderRowIndex(0);
    setNameColumn(null);
    setRowData([]);
    setColumnDefs([]);
    setSelectedTemplateEntity(null);
  }, []);

  const steps = useMemo(() => {
    const stepsAll = [
      Steps.ImportMode,
      Steps.ImportFileMapping,
      Steps.FieldDefinitions,
      Steps.Preview,
    ];
    if (!importData) {
      stepsAll.splice(1, 1);
    }
    if (entityName === Device.entityName) {
      stepsAll.splice(0, 1);
    }
    return stepsAll;
  }, [importData, entityName]);

  return (
    <ModalWizard
      mode="fullscreen"
      routeId={ENTITY_IMPORT_CREATE_ROUTE_ID}
      title="Import"
      onClose={() => reset()}
      steps={steps}
      status={status}
    >
      {entityName !== Device.entityName && (
        <EntityImportActionCreateStepsImportMode
          index={steps.indexOf(Steps.ImportMode)}
          entityName={entityName}
          onContinue={(selectedEntityName): void => {
            setImportEntityName(selectedEntityName);
          }}
        />
      )}
      {importData && (
        <EntityImportActionCreateStepsImportFileMapping
          index={steps.indexOf(Steps.ImportFileMapping)}
          importData={importData}
          onContinue={(
            selectedHeaderRow,
            selectedNameColumn,
          ): void => {
            setHeaderRowIndex(selectedHeaderRow);
            setNameColumn(selectedNameColumn);
          }}
        />
      )}
      <EntityImportActionCreateStepsFieldDefinitions
        templateId={templateId}
        index={steps.indexOf(Steps.FieldDefinitions)}
        entityName={importEntityName}
        nameColumn={nameColumn}
        onContinue={(template, headerMap) => {
          const newColumnDefs =
            AGGridEntityImportService.getColumnDefsTemplate(
              importEntityName,
              template,
            );
          setSelectedTemplateEntity(template);
          if (importData) {
            const newRowData =
              AGGridEntityImportExcelService.generateRowData(
                headerMap,
                newColumnDefs,
                importData,
                headerRowIndex,
              );
            setRowData(newRowData);
          } else {
            setRowData(
              AGGridEntityImportService.generateEmptyRowData(
                N_EMPTY_ROWS,
                newColumnDefs,
              ),
            );
          }
          setColumnDefs(newColumnDefs);
        }}
        importData={importData}
        headerRowIndex={headerRowIndex}
      />
      {selectedTemplateEntity && (
        <EntityImportActionCreateStepsPreview
          index={steps.indexOf(Steps.Preview)}
          rowData={rowData}
          columnDefs={columnDefs}
          entityName={importEntityName}
          onContinue={(): void => reset()}
          template={selectedTemplateEntity}
          create={create}
        />
      )}
    </ModalWizard>
  );
};

export const EntityImportActionCreateContainer: React.FC<
  EntityImportActionCreateContainerProps
> = (props) => (
  <EntityCreateContainer entityName={EntityImport.entityClass}>
    {({ create, status }): React.ReactElement => (
      <EntityImportActionCreate
        create={create}
        status={status}
        {...props}
      />
    )}
  </EntityCreateContainer>
);

export default EntityImportActionCreateContainer;
