/**
 * Labstep
 *
 * @module components/Folder/Breadcrumb
 * @desc Breadcrumb for Folder
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import SubHeader from 'labstep-web/core/SubHeader';
import Desktop from 'labstep-web/core/MediaQuery/Desktop';
import DropdownPortalHover from 'labstep-web/core/Dropdown/Portal/Hover';
import { ParamsHOC } from 'labstep-web/hoc';
import { ParamsContext } from 'labstep-web/hoc/Params/context';
import styles from './styles.module.scss';
import { IScreensIndexBreadcrumbProps } from './types';

export const ScreensIndexBreadcrumb: React.FC<
  IScreensIndexBreadcrumbProps
> = ({ text, right, icon, dropdownContent, rightMobileIcon }) => (
  <ParamsHOC historyAction="replace">
    <ParamsContext.Consumer>
      {({ clearAll, searchParams }) => {
        const Right = right && right({ searchParams });

        const ViewComponent = ({ onClick = clearAll }) => (
          <SubHeader className={styles.subHeader}>
            <div className={styles.container}>
              <div onClick={() => onClick()}>
                <Icon name={icon} />
                {text}
              </div>
              {Right && (
                <div className={styles.right}>
                  <Icon name="chevron right" size="small" />
                  {Right}
                </div>
              )}
            </div>
          </SubHeader>
        );

        return (
          <Desktop>
            {({ match }) => {
              if (dropdownContent && !match) {
                return (
                  <DropdownPortalHover
                    trigger={
                      <SubHeader className={styles.subHeader}>
                        {Right ? (
                          <div className={styles.mobile}>
                            <Icon name={rightMobileIcon} />
                            {Right}
                            <Icon name="chevron down" />
                          </div>
                        ) : (
                          <div>
                            <Icon name={icon} />
                            {text}
                            <Icon name="chevron down" />
                          </div>
                        )}
                      </SubHeader>
                    }
                    content={dropdownContent({
                      header: (
                        <div
                          onClick={() => {
                            clearAll();
                          }}
                        >
                          <Icon name={icon} />
                          {text}
                        </div>
                      ),
                    })}
                  />
                );
              }
              return <ViewComponent />;
            }}
          </Desktop>
        );
      }}
    </ParamsContext.Consumer>
  </ParamsHOC>
);

export default ScreensIndexBreadcrumb;
