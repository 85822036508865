/**
 * Labstep
 *
 * @module components/Metadata/Value
 * @desc Metadata Value
 */

import React from 'react';
import FileViewer from 'labstep-web/components/File/Viewer';
import Linkify from 'labstep-web/core/Linkify';
import Flex from 'labstep-web/core/Flex';
import MoleculeItem from 'labstep-web/components/Molecule/Item';
import MetadataValueOptions from 'labstep-web/components/Metadata/Value/Options';
import MetadataValueSequence from 'labstep-web/components/Metadata/Value/Sequence';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { IMetadataValueProps } from './types';

export const MetadataValue: React.FC<IMetadataValueProps> = ({
  metadata,
  canEdit,
  createProps,
}) => {
  let value = null;

  const printValue =
    metadata.printValue ||
    (canEdit ? (
      <TextPlaceholder>Specify value</TextPlaceholder>
    ) : (
      <i>Unspecified</i>
    ));

  switch (metadata.type) {
    case 'options':
      value = metadata.hasValue ? (
        <MetadataValueOptions metadata={metadata} />
      ) : (
        printValue
      );

      break;
    case 'file': {
      value = <TextPlaceholder>No file(s)</TextPlaceholder>;
      if (createProps) {
        if (canEdit) {
          value = '';
        }
      } else if (metadata.files.length > 0) {
        value = (
          <Flex wrap>
            {metadata.files
              .filter((file) => file.deleted_at === null)
              .map((file) => (
                <FileViewer
                  key={file.guid}
                  file={file}
                  canEdit={canEdit}
                  createProps={createProps}
                />
              ))}
          </Flex>
        );
      } else if (canEdit) {
        value = '';
      }
      break;
    }
    case 'molecule':
      value = (
        <MoleculeItem parent={metadata} createProps={createProps} />
      );
      break;
    case 'sequence':
      value = metadata.sequence && (
        <MetadataValueSequence
          metadata={metadata}
          createProps={createProps}
        />
      );
      break;
    case 'default':
      value = <Linkify>{printValue}</Linkify>;
      break;
    default:
      value = printValue;
      break;
  }

  if (metadata.device_data && metadata.device_data.files) {
    value =
      metadata.device_data.files.length > 0
        ? metadata.device_data.files
            .filter((file) => file.deleted_at === null)
            .map((file) => <FileViewer key={file.guid} file={file} />)
        : metadata.printValue;
  }

  return <div className="value">{value}</div>;
};

export default MetadataValue;
