/**
 * Labstep
 *
 * @module components/Group/Action/AddUser
 * @desc Action to add user to group (from a list of the organization's users)
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import Link from 'labstep-web/core/Link';
import Flex from 'labstep-web/core/Flex';
import SearchSelect from 'labstep-web/core/Select/Search';
import { CanGroup } from 'labstep-web/components/Entity/Can';
import GroupActionSetDefaultUserGroupType from 'labstep-web/components/Group/Action/SetDefaultUserGroupType';
import {
  EntityCreateContainer,
  withAuthenticatedUserEntity,
} from 'labstep-web/containers';
import { User, UserGroup } from 'labstep-web/models';
import { IGroupActionAddUserProps } from './types';

export const GroupActionAddUser: React.FC<
  IGroupActionAddUserProps
> = ({ group, authenticatedUserEntity }) => (
  <CanGroup action="owner" groupId={group.id}>
    <ModalDefault
      header="Add Members"
      content={({ toggleModal }) => (
        <div>
          <Flex vAlignContent="center">
            <div style={{ flexGrow: 1, paddingRight: 10 }}>
              <EntityCreateContainer
                entityName={UserGroup.entityName}
                parentName={group.entityName}
                parentId={group.id}
              >
                {({ create }) => (
                  <SearchSelect
                    entityName={User.entityName}
                    placeholder="Search users in your organization"
                    params={{
                      organization_id: group.organization.id,
                      group_not_id: group.id,
                    }}
                    onChange={(option) => {
                      create({
                        user_id: option.id,
                      });
                      toggleModal();
                    }}
                  />
                )}
              </EntityCreateContainer>
            </div>
            <GroupActionSetDefaultUserGroupType group={group} />
          </Flex>
          <br />
          {authenticatedUserEntity.userOrganization?.type ===
            'admin' && (
            <Link
              unstyled
              to="organization_show_users"
              params={{ identifier: group.organization.identifier }}
            >
              <ActionComponent
                type="text"
                text="Click here to add new users to the organization"
              />
            </Link>
          )}
        </div>
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          onClick={toggleModal}
          type="button"
          text="Add Members"
        />
      )}
    />
  </CanGroup>
);

export default withAuthenticatedUserEntity(GroupActionAddUser);
