/**
 * Labstep
 *
 * @module prosemirror/utils
 * @desc ProseMirror generic utils
 */

import { allowedImageExtensions } from 'labstep-web/config/mime-types';
import {
  Experiment,
  ExperimentWorkflow,
  Protocol,
} from 'labstep-web/models';
import schema from 'labstep-web/prosemirror/schema';
import { EditorView } from 'prosemirror-view';
import { reactPropsKey } from 'labstep-web/prosemirror/extensions/external-comm';
import {
  EditorState,
  PluginKey,
  Transaction,
} from 'prosemirror-state';
import {
  Node,
  NodeType,
  ResolvedPos,
  Slice,
} from 'prosemirror-model';
import { findWrapping } from 'prosemirror-transform';
import { navigatorService } from 'labstep-web/services/navigator.service';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';
import { createFiles } from '../extensions/paste';
import { sanitizationPluginKey } from '../extensions/sanitization/plugin';
import { ElementsArrayType, ProseMirrorJSON } from './types';

export { sanitizeInitialState } from './sanitization';

const getExperimentIds = (
  experimentWorkflow: ExperimentWorkflow,
): number[] => experimentWorkflow.experiments.map((e) => e.id);

/**
 *
 * @desc Prevents propagation of certain key shortcuts
 * for example cmd+shift+r won't hard reload
 */
export const preventKeyPropagation = (
  e: KeyboardEvent,
  view: EditorView,
): void => {
  if (!view.hasFocus()) {
    return;
  }

  if (e.key === 'Tab') {
    e.stopPropagation();
    e.preventDefault();
  }

  if (e.ctrlKey || e.metaKey) {
    switch (String.fromCharCode(e.which).toLowerCase()) {
      case 'b':
      case 'i':
      case 'u':
      case ',':
      case '.':
      case 'd':
        e.stopPropagation();
        e.preventDefault();
        break;
      default:
        break;
    }
  }

  if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
    switch (String.fromCharCode(e.which).toLowerCase()) {
      case 'l':
      case 'e':
      case 'r':
      case 'j':
        e.stopPropagation();
        e.preventDefault();
        break;
      default:
        break;
    }
  }
};

/**
 * Returns the equivalent text for MOD depending on the platform
 */
export const modToPlatformText = (): string =>
  `${navigatorService.isMac ? '⌘' : 'Ctr'}`;

export const filterElementsFromContentJson = (
  ids: (string | number)[],
  content: any[],
  blockType: string,
  entityName: string,
  experimentWorkflow: null | ExperimentWorkflow,
): any => {
  return content.reduce((result, node) => {
    if (
      node.type === 'experiment_table' ||
      node.type === 'protocol_table'
    ) {
      return result;
    }
    const nodeType =
      node.type === 'reference' ? node.attrs.entityName : node.type;
    if (nodeType === 'experiment_workflow_link') {
      if (
        experimentWorkflow &&
        experimentWorkflow.forward_links
          .map((e) => e.guid)
          .indexOf(node.attrs.guid) > -1
      ) {
        return [...result, node];
      }
      return result;
    }
    if (nodeType === 'experiment') {
      if (
        experimentWorkflow &&
        getExperimentIds(experimentWorkflow).indexOf(node.attrs.id) >
          -1
      ) {
        return [...result, node];
      }
      return result;
    }

    // We are not allowing experiment timer in experimentWorkflow atm
    if (
      experimentWorkflow &&
      (nodeType === 'experiment_timer' ||
        nodeType === 'protocol_timer')
    ) {
      return result;
    }

    if (nodeType !== blockType) {
      if (node.content) {
        return [
          ...result,
          {
            ...node,
            content: filterElementsFromContentJson(
              ids,
              node.content,
              blockType,
              entityName,
              experimentWorkflow,
            ),
          },
        ];
      }
      return [...result, node];
    }

    const idAttribute = getIdAttribute(nodeType);
    let id = node.attrs[idAttribute];
    id = Number(id) || id;
    // Should be in the provided ids to be valid
    if (ids.indexOf(id) > -1) {
      return [...result, node];
    }
    return result;
  }, []);
};

const getJsonWithCorrectSteps = (
  entity: Experiment | Protocol,
  slice: Slice,
  docNode: Node,
): {
  contentWithUnwrappedSteps: any[];
  openStart: number;
  openEnd: number;
} => {
  const contentJson = slice.content.toJSON() as ProseMirrorJSON[];
  let { openStart } = slice;
  let { openEnd } = slice;

  const stepsIdsOfEntity = entity.protocol_steps.map((e) => e.guid);
  const { content } = docNode.toJSON() as ProseMirrorJSON;
  const stepsIdsInDoc = content
    ? content
        .filter((n) => n.type.includes('step'))
        .map((n) => n.attrs?.guid)
    : [];

  const contentWithUnwrappedSteps = contentJson.reduce(
    (result, node, index) => {
      const guid = node.attrs?.guid as string | null;
      if (
        node.type.includes('step') &&
        (guid === null ||
          stepsIdsOfEntity.indexOf(guid) === -1 ||
          stepsIdsInDoc.indexOf(guid) > -1)
      ) {
        if (index === 0 && openStart > 0) {
          openStart -= 1;
        }
        if (index === contentJson.length - 1 && openEnd > 0) {
          openEnd -= 1;
        }
        return node.content ? [...result, ...node.content] : result;
      }
      return [...result, node];
    },
    [] as ProseMirrorJSON[],
  );
  return { contentWithUnwrappedSteps, openStart, openEnd };
};

export const getElementsArray = (
  entity: Experiment | Protocol,
): ElementsArrayType => {
  const metadataIds = entity.metadata_thread.metadatas.map(
    (m) => m.id,
  );
  const fileIds = entity.files.map((f) => f.id);
  const moleculeIds = entity.molecules.map((e) => e.guid);
  const protocolValueIds = entity.protocol_values.map((e) => e.guid);
  const protocolTimerIds = entity.protocol_timers.map((e) => e.guid);
  const protocolTableIds = entity.protocol_tables.map((e) => e.guid);
  const protocolDeviceIds = entity.protocol_devices.map(
    (e) => e.guid,
  );
  const protocolStepIds = entity.protocol_steps.map((e) => e.guid);

  const elementsArray: ElementsArrayType = [
    { key: 'metadata', ids: metadataIds },
    { key: 'file', ids: fileIds },
    { key: 'molecule', ids: moleculeIds },
    { key: 'protocol_value', ids: protocolValueIds },
    { key: 'protocol_timer', ids: protocolTimerIds },
    { key: 'protocol_table', ids: protocolTableIds },
    { key: 'protocol_device', ids: protocolDeviceIds },
    { key: 'protocol_step', ids: protocolStepIds },
  ];

  if (entity instanceof Experiment) {
    return [
      ...elementsArray,
      // legacy
      { key: 'experiment_value', ids: protocolValueIds },
      { key: 'experiment_timer', ids: protocolTimerIds },
      { key: 'experiment_table', ids: protocolTableIds },
      { key: 'experiment_step', ids: protocolStepIds },
    ];
  }

  return elementsArray;
};

export const getJsonWithoutForeignEntities = (
  entity: Experiment | Protocol,
  json: any[],
  experimentWorkflow: ExperimentWorkflow,
): any[] => {
  const elementsArray = getElementsArray(entity);
  return elementsArray.reduce(
    (result, elementArray) =>
      filterElementsFromContentJson(
        elementArray.ids,
        result,
        elementArray.key,
        entity.entityName,
        experimentWorkflow,
      ),
    json,
  );
};

/**
 *
 * @description Filters out nodes that don't correspond to
 * sub-entities of the entity
 */
export const getFilteredSlice = (
  entity: Experiment | Protocol,
  slice: Slice,
  docNode: Node,
  experimentWorkflow: ExperimentWorkflow,
): Slice => {
  const { contentWithUnwrappedSteps, openStart, openEnd } =
    getJsonWithCorrectSteps(entity, slice, docNode);

  const finalJson = getJsonWithoutForeignEntities(
    entity,
    contentWithUnwrappedSteps,
    experimentWorkflow,
  );

  const node = Node.fromJSON(schema, {
    type: 'doc',
    content: finalJson,
  });
  const fragment = node.content;
  return new Slice(fragment, openStart, openEnd);
};

export const getTopLevelBlocksSelected = (
  state: EditorState,
): Node[] => {
  let blocks: any = [];
  state.doc.nodesBetween(
    state.selection.from,
    state.selection.to,
    (n) => {
      if (n.type.spec.group === 'block') {
        blocks = [...blocks, n];
        return false;
      }
      return true;
    },
  );
  return blocks;
};

export const isSingleBlockSelected = (
  state: EditorState,
): boolean => {
  return getTopLevelBlocksSelected(state).length < 2;
};

export const isSingleBlockSelectedAndAllowsContent = (
  state: EditorState,
): boolean => {
  if (state.selection.toJSON().type !== 'text') {
    return false;
  }
  const topLevelBlocksSelected = getTopLevelBlocksSelected(state);
  return (
    topLevelBlocksSelected.length === 1 &&
    !!topLevelBlocksSelected[0].type.spec.content
  );
};

export const isSingleTextSelected = (state: EditorState): boolean => {
  if (!isSingleBlockSelected(state)) {
    return false;
  }
  let nodes: Node[] = [];
  state.doc.nodesBetween(
    state.selection.from,
    state.selection.to,
    (n) => {
      if (n.type.spec.group !== 'block') {
        nodes = [...nodes, n];
        return false;
      }
      return true;
    },
  );
  return nodes.length === 1 && nodes[0].type.name === 'text';
};

/**
 *
 * @param state ProseMirror State
 * @returns selected text
 */
export const getSelectedText = (state: EditorState): string => {
  const {
    doc,
    selection: { from, to },
  } = state;
  const text = doc.textBetween(from, to);
  return text;
};

export const getSelectedTextForElement = (
  state: EditorState,
): string => {
  if (isSingleTextSelected(state)) {
    return getSelectedText(state);
  }
  return '';
};

export const canAddElement = (state: EditorState): boolean =>
  isSingleTextSelected(state) || state.selection.empty;

export const handlePaste = (
  view: EditorView,
  e: ClipboardEvent,
  slice: Slice,
): boolean => {
  // eslint-disable-next-line no-console
  console.log('slice=', slice);

  const { entity, experimentWorkflow } = reactPropsKey.getState(
    view.state,
  );

  const { clipboardData } = e;
  if (!clipboardData) {
    return false;
  }

  const { files } = clipboardData;

  // https://github.com/Labstep/web/issues/5959
  // Need to check if files is set
  if (files && files.length > 0) {
    const contentAsText = clipboardData.getData('Text');
    const isSupported = allowedImageExtensions.some((extension) =>
      contentAsText.endsWith(extension),
    );
    // Copy pasting images from html result in content size of 0
    // so we need to check for that case as well
    if (isSupported || slice.content.size === 0) {
      e.preventDefault();
      createFiles(view.state, view.dispatch, entity, files);
      return true;
    }
  }

  if (
    slice.openEnd === slice.openStart &&
    // If table paste don't do filtering (taken from prosemirror-tables)
    slice.content.firstChild?.type.name.startsWith('table')
  ) {
    return false;
  }

  if (
    slice.content.size === 0 ||
    // If table paste don't do filtering (taken from prosemirror-tables)
    slice.content.firstChild?.type.spec.tableRole === 'table'
  ) {
    return false;
  }

  const filteredSlice = getFilteredSlice(
    entity,
    slice,
    view.state.doc,
    experimentWorkflow,
  );
  const tr = view.state.tr.replaceSelection(filteredSlice);
  tr.setMeta(sanitizationPluginKey, {});

  view.dispatch(tr);

  return true;
};

export const isWrappingPossible = (
  nodeType: NodeType,
  state: EditorState,
): boolean => {
  const { $from, $to } = state.selection;
  const range = $from.blockRange($to);
  if (!range) {
    return false;
  }

  const wrap = findWrapping(range, nodeType);
  if (!wrap) {
    return false;
  }

  return true;
};

export const wrapIn = (
  nodeType: NodeType,
  attrs: { [key: string]: any },
  tr: Transaction,
  $from: ResolvedPos,
  $to: ResolvedPos,
): Transaction => {
  const range = $from.blockRange($to);
  const wrapping = range && findWrapping(range, nodeType, attrs);
  if (wrapping) {
    // eslint-disable-next-line no-param-reassign
    tr = tr.wrap(range, wrapping);
  }
  return tr;
};

/**
 * Returns true if cursor is at a blank line
 *
 */
export const isCursorAtBlankLine = (state: EditorState): boolean => {
  const {
    doc,
    selection: { head, empty },
  } = state;
  return empty && doc.resolve(head).parent.content.size === 0;
};

export const isCursorInTable = (state: EditorState): boolean => {
  const {
    doc,
    selection: { head },
  } = state;
  const node = doc.resolve(head);
  const grandparent = node.node(node.depth - 1);
  return grandparent && grandparent.type.name === 'table_cell';
};

/**
 *
 * @description Returns the plugin state
 * @param state
 * @returns object
 */
export const getPluginState = (
  state: EditorState,
  key: PluginKey,
): any => {
  return state.plugins
    .find((p) => p.spec.key === key)
    ?.getState(state);
};
