/**
 * Labstep
 *
 * @module containers/Thumbnail
 */

import { connect } from 'react-redux';
import {
  thumbnailErrorAction,
  thumbnailFetchUrlAction,
} from 'labstep-web/state/actions';
import {
  selectThumbnailStatus,
  selectThumbnailUrl,
} from 'labstep-web/state/selectors';
import { IOptions } from 'labstep-web/typings';
import { LabstepReduxState } from 'labstep-web/state/types';
import { INewThumbnailContainerProps, OwnProps } from './types';

const Thumbnail = ({ children, ...rest }) => children(rest);

const mapStateToProps = (
  state: LabstepReduxState,
  ownProps: OwnProps,
) => ({
  src: selectThumbnailUrl(state, ownProps.fileGuid, ownProps.params),
  status: selectThumbnailStatus(
    state,
    ownProps.fileGuid,
    ownProps.params,
  ),
});

const mapDispatchToProps = (dispatch, ownProps: OwnProps) => ({
  fetchUrl(options = {} as IOptions) {
    dispatch(
      thumbnailFetchUrlAction(
        ownProps.fileGuid,
        ownProps.params,
        options,
      ),
    );
  },
  onError() {
    dispatch(
      thumbnailErrorAction(ownProps.fileGuid, ownProps.params, {}),
    );
  },
});

export const NewThumbnailContainer: React.FC<INewThumbnailContainerProps> =
  connect(mapStateToProps, mapDispatchToProps)(Thumbnail);
