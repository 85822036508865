/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/ShowEdit/ResourceItem/Input/Bulk
 * @desc ProtocolValue ShowEdit ResourceItem Input Bulk for variable
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ICONS } from 'labstep-web/constants';
import SearchSelectModal from 'labstep-web/core/Select/Search/Modal';
import { ProtocolCondition, ResourceItem } from 'labstep-web/models';
import React from 'react';
import { getSearchSelectProps } from '../../../Form/ShowEdit/ResourceItem/Input/utils';
import { IProtocolValueVariableActionSetItemsProps } from './types';
import { getOnChange } from './utils';

export const ProtocolValueVariableActionSetItems: React.FC<
  IProtocolValueVariableActionSetItemsProps
> = ({
  protocolValue,
  createProtocolCondition,
  createProtocolValue,
  protocolValues = [],
  ...rest
}) => {
  const canEdit = useHasAccess(
    protocolValue.entityName,
    protocolValue.guid,
    Action.edit,
  );

  /** Edit items on existing values rather than create new conditions */
  const editItems =
    protocolValues.length > 1 && protocolValue.is_input;

  return (
    <SearchSelectModal
      {...getSearchSelectProps(protocolValue)}
      entityName={ResourceItem.entityName}
      canEdit={canEdit}
      rowSelection="multiple"
      requiredCount={editItems ? protocolValues.length : undefined}
      onChange={getOnChange(
        protocolValue,
        createProtocolCondition,
        createProtocolValue,
        protocolValues,
      )}
      actionComponentProps={{
        type: 'text',
        text: editItems
          ? `Select ${ResourceItem.getHumanReadableEntityName(
              true,
              true,
            )}`
          : `Add ${ProtocolCondition.getHumanReadableEntityName(
              true,
              true,
            )}`,
        icon: editItems ? ICONS.resource_item.primary : 'plus',
      }}
      {...rest}
    />
  );
};

export default ProtocolValueVariableActionSetItems;
