/**
 * Labstep
 *
 * @module components/Group/Template
 * @desc Group Templatable Entity
 */

import React from 'react';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import { IGroupTemplateProps } from './types';
import GroupTemplateEmptyState from './EmptyState';

export const GroupTemplate: React.FC<IGroupTemplateProps> = ({
  group,
  field,
  entityName,
  extraRows,
  options,
  extraBody,
}) => {
  const id = group[field];
  if (!id) {
    return (
      <GroupTemplateEmptyState
        group={group}
        entityName={entityName}
        options={options}
        extraBody={extraBody}
      />
    );
  }
  return (
    <ReadOnMountHOC
      type="entities"
      entityName={entityName}
      params={{ id, get_single: 1 }}
      loading={{ loader: 'placeholder', cached: true }}
      children={({ entity }) => (
        <MetadataManager
          isTemplate
          entity={entity}
          extraRows={extraRows}
        />
      )}
    />
  );
};

export default GroupTemplate;
