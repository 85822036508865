/**
 * Labstep
 *
 * @module components/Signature/Item
 * @desc Signature item
 */

import React from 'react';
import { dateOnly } from 'labstep-web/services/date.service';
import { Size } from 'labstep-web/core/Initials/types';
import Card from 'labstep-web/core/Card/Generic';
import ProfilePictureImage from 'labstep-web/components/Profile/Picture/Image';
import SignatureActionMenu from 'labstep-web/components/Signature/Action/Menu';
import UserShowProfile from 'labstep-web/components/User/Show/Profile';
import Pre from 'labstep-web/core/Pre';
import TextError from 'labstep-web/core/Text/Error';
import { ISignatureItemProps } from './types';

const SignatureItem: React.FC<ISignatureItemProps> = ({
  signature,
  experimentWorkflow,
}) => (
  <Card
    headerImage={
      <ProfilePictureImage
        entity={signature.author}
        size={Size.mini}
      />
    }
    headerHeader={
      <div>
        <div>
          <Pre>{signature.statement}</Pre>
          <span>
            Signed by{' '}
            <UserShowProfile
              user={signature.author}
              viewComponent={signature.author.name}
            />{' '}
            on {dateOnly(signature.created_at)}
            {signature.revoked_at && (
              <TextError>
                <b> Revoked on {dateOnly(signature.revoked_at)}</b>
              </TextError>
            )}
          </span>
        </div>
      </div>
    }
    actionMenu={
      <SignatureActionMenu
        signature={signature}
        experimentWorkflow={experimentWorkflow}
        actions={['revoke']}
      />
    }
  />
);

export default SignatureItem;
