import colDefBulkSelect from 'labstep-web/components/Entity/DataGrid/colDefs/bulkSelect';
import colDefEntityUser from 'labstep-web/components/Entity/DataGrid/colDefs/entityUser';
import { colDefOrderRequestActionMenu } from 'labstep-web/components/OrderRequest/DataGrid/colDefs/actionMenu';
import { colDefAmount } from 'labstep-web/components/OrderRequest/DataGrid/colDefs/amount';
import { colDefLogAuthor } from 'labstep-web/components/OrderRequest/DataGrid/colDefs/logAuthor';
import { colDefLogCreatedAt } from 'labstep-web/components/OrderRequest/DataGrid/colDefs/logCreatedAt';
import { colDefPurchaseOrder } from 'labstep-web/components/OrderRequest/DataGrid/colDefs/purchaseOrder';
import { colDefQuantity } from 'labstep-web/components/OrderRequest/DataGrid/colDefs/quantity';
import { colDefOrderRequestName } from 'labstep-web/components/OrderRequest/DataGrid/colDefs/name';
import colDefStatus from 'labstep-web/components/OrderRequest/DataGrid/colDefs/status';
import { OrderRequest, OrderRequestStatus } from 'labstep-web/models';
import { colDefIsUrgent } from 'labstep-web/components/OrderRequest/DataGrid/colDefs/isUrgent';
import { getOrderRequestMetadataColDefs } from 'labstep-web/components/OrderRequest/DataGrid/colDefs/metadata';

export const columnDefs = (
  onChangeStatus: (
    orderRequest: OrderRequest,
    status: OrderRequestStatus,
  ) => void,
  template?: OrderRequest,
) => {
  let colDefs = [
    colDefOrderRequestActionMenu,
    colDefBulkSelect,
    colDefOrderRequestName,
    colDefIsUrgent,
    colDefQuantity,
    colDefStatus(onChangeStatus),
    colDefPurchaseOrder,
    colDefAmount,
    colDefLogAuthor(OrderRequestStatus.new),
    colDefLogCreatedAt(OrderRequestStatus.new),
    colDefLogAuthor(OrderRequestStatus.ordered),
    colDefLogCreatedAt(OrderRequestStatus.ordered),
    colDefLogAuthor(OrderRequestStatus.received),
    colDefLogCreatedAt(OrderRequestStatus.received),
  ];
  if (template) {
    colDefs = [
      ...colDefs,
      ...getOrderRequestMetadataColDefs(template),
      colDefEntityUser,
    ];
  } else {
    colDefs = [...colDefs, colDefEntityUser];
  }
  return colDefs;
};
