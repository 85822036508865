/**
 * Labstep
 *
 * @module models/entity
 * @desc Typescript export class for any entity
 */

import { humanReadableDate } from 'labstep-web/services/date.service';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

interface IPermissions {
  edit: boolean;
  view: boolean;
  owner: boolean;
}

export class Entity {
  public static entityName: string;

  public static readonly idAttr: string = 'id';

  protected name!: string | null;

  protected id!: number;

  guid!: string;

  created_at!: string;

  updated_at!: string;

  deleted_at!: string;

  permissions!: IPermissions;

  permission_entity_info!: null | {
    entityName: string;
    id: string | number;
  };

  constructor(data: Partial<Entity> = {}) {
    Object.assign(this, data);
  }

  public get idAttr(): string | number {
    return this[
      (this.constructor as typeof Entity).idAttr as 'id' | 'guid'
    ];
  }

  get displayName(): string | null {
    return this.name;
  }

  get printDeletedAtDate() {
    if (!this.deleted_at) {
      return '';
    }
    return `deleted on ${humanReadableDate(this.deleted_at)}`;
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
