/**
 * Labstep
 *
 * @module components/ExperimentWorkflowLink/Item
 * @desc ExperimentWorkflowLink Item
 */

import React from 'react';
import LinkButton from 'labstep-web/core/Link/Button';
import ElementCard from 'labstep-web/core/Card/Element';
import ExperimentWorkflowLinkActionMenu from 'labstep-web/components/ExperimentWorkflowLink/Action/Menu';
import { ICONS } from 'labstep-web/constants';
import { IExperimentWorkflowLinkItemProps } from './types';

export const ExperimentWorkflowLinkItem: React.FC<
  IExperimentWorkflowLinkItemProps
> = ({ experimentWorkflowLink, showBackLinks }) => {
  const key = showBackLinks ? 'src' : 'dest';
  return (
    <ElementCard
      name={
        <LinkButton
          route={{
            to: 'experiment_workflow_show',
            params: { id: experimentWorkflowLink[key].id },
          }}
        >
          {experimentWorkflowLink[key].displayName}
        </LinkButton>
      }
      icon={ICONS.experiment_workflow.primary}
      actions={
        !showBackLinks && (
          <ExperimentWorkflowLinkActionMenu
            experimentWorkflowLink={experimentWorkflowLink}
            actions={['delete']}
          />
        )
      }
      isLink
    />
  );
};

export default ExperimentWorkflowLinkItem;
