/**
 * Labstep
 *
 * @module components/OutboundPurchaseOrder/Action/Create
 * @desc Create Action for outbound purchase order
 */

import React from 'react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import GuardLabstepAdmin from 'labstep-web/components/Guard/LabstepAdmin';
import { OutboundPurchaseOrder } from 'labstep-web/models';
import { IOutboundPurchaseOrderActionCreateProps } from './types';

export const OutboundPurchaseOrderActionCreate: React.FC<
  IOutboundPurchaseOrderActionCreateProps
> = ({ purchaseOrder }) =>
  purchaseOrder.canCreateOutboundPurchaseOrder ? (
    <GuardLabstepAdmin>
      <EntityActionCreate
        entityName={OutboundPurchaseOrder.entityName}
        body={{ purchase_order_id: purchaseOrder.id }}
        options={{ onSuccess: () => window.location.reload() }}
        actionComponentProps={{
          type: 'button',
          elementProps: { color: 'green', basic: true },
        }}
      />
    </GuardLabstepAdmin>
  ) : null;

export default OutboundPurchaseOrderActionCreate;
