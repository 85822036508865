/**
 * Labstep
 *
 * @module components/ResourceLocation/Table
 * @desc Table showing resource locations
 */

import React from 'react';
import ResourceLocationActionMenu from 'labstep-web/components/ResourceLocation/Action/Menu';
import EntityTable from 'labstep-web/components/Entity/Table';
import { ResourceLocation } from 'labstep-web/models';
import EntityLink from 'labstep-web/components/Entity/Link';
import { IResourceLocationTableProps } from './types';

const extraColumns = [
  {
    header: 'Path',
    content: (resourceLocation: ResourceLocation) =>
      resourceLocation.location_path,
    cellProps: { style: { flex: 3 } },
  },
  {
    header: 'Sub-locations',
    content: (resourceLocation: ResourceLocation) =>
      resourceLocation.inner_location_count || '0',
    cellProps: { style: { flex: 3 } },
  },
  {
    header: 'Items',
    content: (resourceLocation: ResourceLocation) =>
      resourceLocation.resource_item_count || '0',
    cellProps: { style: { flex: 3 } },
  },
];

const primaryColumn = {
  header: 'Location',
  content: (resourceLocation: ResourceLocation) => (
    <div>
      <EntityLink entity={resourceLocation} />
    </div>
  ),
  cellProps: { style: { flex: 3 } },
};

export const ResourceLocationTable: React.FC<
  IResourceLocationTableProps
> = ({ resourceLocations, actions, ...rest }) => (
  <EntityTable
    id="resource-location-table"
    entities={resourceLocations}
    editableName
    withCheckbox={false}
    extraColumns={extraColumns}
    actionMenu={({ entity }) => (
      <ResourceLocationActionMenu
        resourceLocation={entity}
        actions={actions}
      />
    )}
    primaryColumn={primaryColumn}
    {...rest}
  />
);

export default ResourceLocationTable;
