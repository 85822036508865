import { IParamsHOCChildrenProps } from 'labstep-web/hoc/Params/types';
import { User } from 'labstep-web/models';
import { PostFilterAssignedTo } from 'labstep-web/services/postFilter/filters/assignedTo';
import { PostFilterAuthor } from 'labstep-web/services/postFilter/filters/author';
import { FilterUserType } from './types';

export const onUserChange = (
  selectedOption: User | null,
  type: FilterUserType,
  setParams: IParamsHOCChildrenProps['setParams'],
  addPostFilter?: IParamsHOCChildrenProps['addPostFilter'],
): void => {
  if (addPostFilter) {
    const guid = selectedOption ? selectedOption.guid : undefined;
    addPostFilter(
      type === 'author'
        ? PostFilterAuthor.getNode([guid])
        : PostFilterAssignedTo.getNode([guid]),
    );
  } else {
    setParams({
      [type === 'author' ? 'author_id' : 'assignee_id']:
        selectedOption ? selectedOption.id : undefined,
    });
  }
};
