/**
 * Labstep
 *
 * @module core/Spreadsheet/FormulaBar
 * @desc FormulaBar for Spreadsheet tables
 */

import React, { useCallback, useState } from 'react';
import { GC } from 'labstep-web/core/SpreadJS/imports';
import { getCellPositionString } from 'labstep-web/core/Spreadsheet/helpers';
import { ISpreadsheetState } from '../types';
import styles from './styles.module.scss';
import { IFormulaBarProps } from './types';

function getSelectedCellString(
  spread: NonNullable<ISpreadsheetState['spread']>,
) {
  const sheet = spread.getActiveSheet();
  const selection = sheet.getSelections().slice(-1)[0];
  if (selection) {
    return getCellPositionString(sheet, selection.row, selection.col);
  }
  return null;
}

export const FormulaBar: React.FC<IFormulaBarProps> = ({
  spread,
}) => {
  const [selectedCell, setSelectedCell] = useState(
    getSelectedCellString(spread),
  );
  spread.bind(GC.Spread.Sheets.Events.SelectionChanged, () => {
    setSelectedCell(getSelectedCellString(spread));
  });
  const formulaBox = useCallback(
    (node) => {
      const fbx = new GC.Spread.Sheets.FormulaTextBox.FormulaTextBox(
        node,
        {
          rangeSelectMode: false,
          absoluteReference: false,
        },
      );
      fbx.workbook(spread);
    },
    [spread],
  );
  return (
    <div className={styles.bar}>
      <div id="selection" className={styles.selection}>
        {selectedCell}
      </div>
      <div
        className={styles.formulaBox}
        ref={formulaBox}
        contentEditable
        spellCheck={false}
      />
    </div>
  );
};

const FormulaBarGuard: React.FC<IFormulaBarProps> = ({
  spread,
  readOnly,
}) => {
  if (!spread) {
    return null;
  }
  return <FormulaBar spread={spread} readOnly={readOnly} />;
};

export default FormulaBarGuard;
