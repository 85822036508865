/**
 * Labstep
 *
 * @module components/Protocol/Form/ShowEdit/State
 * @desc State showedit field
 */

import React from 'react';
import { Prompt } from 'react-router';
import { navigation } from 'labstep-web/services/navigation';
import classNames from 'classnames';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Button from 'labstep-web/core/Button';
import ProseMirror from 'labstep-web/prosemirror/Container';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalEdit from 'labstep-web/components/Modal/Edit';
import { withProseMirror } from 'labstep-web/containers';
import proseMirrorStyles from 'labstep-web/prosemirror/styles.module.scss';
import { getOnClick } from 'labstep-web/components/Experiment/Form/ShowEdit/State/utils';
import WarningModal from './WarningModal';
import { IProtocolFormShowEditStateProps } from './types';
import styles from './styles.module.scss';
import ToolbarRight from './ToolbarRight';

export const ProtocolFormShowEditState: React.FC<
  IProtocolFormShowEditStateProps
> = ({
  protocol,
  protocolCollection,
  onChange,
  debouncingStatus,
  proseMirrorKey,
  match,
  canEdit,
  setEditMode,
  setViewMode,
  editing,
}) => {
  const toolbarRight = React.useMemo(
    () => (
      <ToolbarRight
        protocolCollection={protocolCollection}
        setViewMode={setViewMode}
      />
    ),
    [protocolCollection, setViewMode],
  );
  return (
    <>
      <WarningModal status={debouncingStatus} />
      <Prompt
        when={debouncingStatus.isFetching || !!debouncingStatus.error}
        message={(location): boolean | string =>
          location.pathname.startsWith(
            navigation.get('protocol_collection_show', {
              id: protocolCollection.id,
            }),
          )
            ? true
            : 'You have unsaved changes, are you sure you want to leave?'
        }
      />
      {protocol.state === null && !editing ? (
        <EmptyState
          title="Blank protocol"
          explanation="Start writing your protocol."
          src="/img/empty-state/protocols.svg"
          action={
            canEdit && (
              <Button primary onClick={setEditMode}>
                Edit
              </Button>
            )
          }
        />
      ) : (
        <div
          id="protocol-state"
          onClick={
            !match && canEdit
              ? getOnClick(editing, setEditMode, setViewMode)
              : undefined
          }
          onDragEnter={setEditMode}
        >
          {canEdit && !editing && (
            <div
              className={classNames(
                proseMirrorStyles.topMenu,
                styles.floatingButton,
              )}
            >
              <ActionComponent
                type="text"
                text="Edit"
                icon="pencil"
                onClick={setEditMode}
              />
            </div>
          )}
          <ProseMirror
            key={proseMirrorKey}
            editable={canEdit && editing}
            entity={protocol}
            onChange={onChange}
            initialState={protocol.state}
            toolbarRight={toolbarRight}
          />
          <ModalEdit parent={protocol} />
        </div>
      )}
    </>
  );
};

export default withProseMirror(ProtocolFormShowEditState);
