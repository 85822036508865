/**
 * Labstep
 *
 * @module components/OrganizationPlan/Manager/Customer
 * @desc Form to create the customer in Stripe
 */

import React from 'react';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import { OrganizationCustomerContainer } from 'labstep-web/containers';
import validationRules from 'labstep-web/services/validation/rules';
import { COUNTRIES, Country } from 'labstep-web/constants';
import { useModalWizardContext } from 'labstep-web/core/Modal/Wizard/context';
import {
  FieldType,
  IFieldProps,
} from 'labstep-web/core/Form/Reusable/types';
import { OrganizationPlanManagerCustomerProps } from './types';

export const FORM_CREATE_CUSTOMER_FIELDS: IFieldProps[] = [
  {
    fieldType: FieldType.Text,
    name: 'line1',
    placeholder:
      'Address line 1 (e.g., street, PO Box, or company name)',
    validation: validationRules.stripe_customer_address.line1,
  },
  {
    fieldType: FieldType.Text,
    name: 'line2',
    placeholder:
      'Address line 2 (e.g., apartment, suite, unit, or building)',
    validation: validationRules.stripe_customer_address.line2,
  },
  {
    fieldType: FieldType.Text,
    name: 'postal_code',
    placeholder: 'ZIP or postal code',
    validation: validationRules.stripe_customer_address.postal_code,
  },
  {
    fieldType: FieldType.Text,
    name: 'city',
    placeholder: 'City, district, suburb, town, or village',
    validation: validationRules.stripe_customer_address.city,
  },
  {
    fieldType: FieldType.Text,
    name: 'state',
    placeholder: 'State, county, province, or region',
    validation: validationRules.stripe_customer_address.state,
  },
  {
    name: 'country',
    placeholder: 'Country',
    validation: validationRules.stripe_customer_address.country,
    fieldType: FieldType.ReactSelect,
    elementProps: {
      options: COUNTRIES.map((country: Country) => ({
        label: country.Name,
        value: country.Code,
      })),
    },
  },
];

export const OrganizationPlanManagerCustomer: React.FC<
  OrganizationPlanManagerCustomerProps
> = ({ organization, ...rest }) => {
  const { goForward } = useModalWizardContext();
  return (
    <OrganizationCustomerContainer>
      {({ createCustomer }) => (
        <ReusableForm
          onSubmit={(values: any) => {
            if (values.country && values.country.value) {
              // eslint-disable-next-line no-param-reassign
              values.country = values.country.value;
            }
            createCustomer(organization, values);
            goForward();
          }}
          autoFocus
          fields={FORM_CREATE_CUSTOMER_FIELDS}
          submitButton={() => null}
          {...rest}
        />
      )}
    </OrganizationCustomerContainer>
  );
};

export default OrganizationPlanManagerCustomer;
