import colDefBulkSelect from 'labstep-web/components/Entity/DataGrid/colDefs/bulkSelect';
import colDefEntityUser from 'labstep-web/components/Entity/DataGrid/colDefs/entityUser';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import { Resource, ResourceItem } from 'labstep-web/models';
import colDefResourceLocation from 'labstep-web/components/ResourceItem/DataGrid/colDefs/resourceLocation';
import { colDefResourceItemActionMenu } from 'labstep-web/components/ResourceItem/DataGrid/colDefs/actionMenu';
import { getResourceItemResourceMetadataColDefs } from 'labstep-web/components/ResourceItem/DataGrid/colDefs/metadata/resource';
import { colDefResourceItemName } from 'labstep-web/components/ResourceItem/DataGrid/colDefs/name';
import { colDefResourceItemResource } from 'labstep-web/components/ResourceItem/DataGrid/colDefs/resource';
import { colDefResourceItemAmount } from 'labstep-web/components/ResourceItem/DataGrid/colDefs/amount';
import { colDefStatusHidden } from 'labstep-web/components/ResourceItem/DataGrid/colDefs/status/hidden';
import { getResourceItemMetadataColDefs } from 'labstep-web/components/ResourceItem/DataGrid/colDefs/metadata';

export default (
  resourceTemplate?: Resource | null,
): (ColDef<ResourceItem> | ColDef<Resource>)[] => {
  const colDefs = resourceTemplate
    ? [
        colDefResourceItemActionMenu,
        colDefBulkSelect,
        colDefResourceItemName,
        colDefResourceLocation,
        colDefResourceItemAmount,
        colDefResourceItemResource,
        ...getResourceItemMetadataColDefs(resourceTemplate),
        ...getResourceItemResourceMetadataColDefs(resourceTemplate),
        colDefEntityUser,
        colDefStatusHidden,
      ]
    : [
        colDefResourceItemActionMenu,
        colDefBulkSelect,
        colDefResourceItemName,
        colDefResourceLocation,
        colDefResourceItemAmount,
        colDefResourceItemResource,
        colDefEntityUser,
        colDefStatusHidden,
      ];
  return colDefs;
};
