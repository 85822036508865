/**
 * Labstep
 *
 * @module state/epics/resource-item-template
 * @desc Redux epic for resource-item-template actions
 */

import { Observable, concat, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Action, Resource } from 'labstep-web/models';
import { readEntities } from 'labstep-web/state/actions';

/**
 * Reload resource after creating a new resource item template.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const reloadAfterCreateResourceItemTemplateActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SUCCESS_CREATE_RESOURCE_ITEM' &&
        action.meta &&
        action.meta.body &&
        action.meta.body.resource_id &&
        action.meta.body.is_template,
    ),
    map((action: Action) =>
      readEntities(Resource.entityName, {
        get_single: 1,
        id: action.meta.body.resource_id,
        is_deleted: 'both',
      }),
    ),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_READ_RESOURCE_AFTER_CREATE_RESOURCE_ITEM_TEMPLATE',
          payload: err,
        }),
        source$,
      ),
    ),
  );
