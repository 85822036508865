/**
 * Labstep
 *
 * @module components/Organization/UserManager
 */

import React from 'react';
import UserOrganizationTable from 'labstep-web/components/UserOrganization/Table';
import EntitySearch from 'labstep-web/components/Entity/Search';
import SharelinkInvitationActionCreateOrganization from 'labstep-web/components/ShareLinkInvitation/Action/Create/Organization';
import ShareLinkInvitationModal from 'labstep-web/components/ShareLinkInvitation/Modal';
import { UserOrganization } from 'labstep-web/models';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { IUserOrganizationManagerProps } from './types';

const UserOrganizationManager: React.FC<
  IUserOrganizationManagerProps
> = ({ organization }) => (
  <EntitySearch
    searchKey="search_query"
    entityName={UserOrganization.entityName}
    params={{
      organization_id: organization.id,
    }}
    filters={[Filter.is_user_deleted]}
    action={() => (
      <SharelinkInvitationActionCreateOrganization
        organization={organization}
      />
    )}
  >
    {({ entities }) => (
      <div>
        {!organization.is_saml_enabled &&
          organization.logged_user_user_organization.type ===
            'admin' && (
            <ShareLinkInvitationModal entity={organization} />
          )}
        <UserOrganizationTable userOrganizations={entities} />
      </div>
    )}
  </EntitySearch>
);

export default UserOrganizationManager;
