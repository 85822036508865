import type { RangeSelectionChangedEvent } from 'ag-grid-community';
import { getRowIdsFromRange } from 'labstep-web/core/DataGrid/CellEditor/utils';
import { Entity } from 'labstep-web/models';
import uniqBy from 'lodash/uniqBy';
import { IRangeType } from './types';

/**
 * Get entities related to the column selected
 * @param ranges Cell range
 * @param colId Column id
 * @returns Entities
 */
export const getEntitiesByColId = <T extends Entity>(
  ranges: IRangeType[],
  colId: string,
): T[] => {
  const entities = ranges.reduce((prev: T[], range: IRangeType) => {
    if (
      range.columns.length === 1 &&
      range.columns[0].getColId() === colId
    ) {
      return [...prev, ...range.entities];
    }
    return prev;
  }, []);
  return uniqBy(entities, 'guid');
};

/**
 * Get column/row ranges from selection
 * @param event Range selection changed event
 * @returns Ranges
 */
export const getRangesFromSelection = (
  event: RangeSelectionChangedEvent,
): IRangeType[] => {
  const ranges: IRangeType[] = [];
  event.api.getCellRanges()?.forEach((cellRange) => {
    const range: IRangeType = {
      entities: [],
      columns: cellRange.columns,
    };
    const rowIds = getRowIdsFromRange(cellRange);
    rowIds.forEach((rowId) => {
      const row = event.api.getDisplayedRowAtIndex(rowId);
      if (row) {
        range.entities.push(row.data);
      }
    });
    ranges.push(range);
  });
  return ranges;
};
