/**
 * Labstep
 *
 * @module components/Filter/AvailableResourceItemCount
 * @desc AvailableResourceItemCount min/max filter
 */

import React from 'react';
import Flex from 'labstep-web/core/Flex';
import Modal from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import ReactSelect from 'labstep-web/core/Select';
import { ICONS } from 'labstep-web/constants';
import LayoutContainerActionButtons from 'labstep-web/components/Layout/Container/ActionButtons';
import Container from 'labstep-web/components/Layout/Container';
import styles from './styles.module.scss';
import { AvailableResourceItemCountFilterProps } from './types';

/* @var Options for minimum */
export const MIN_OPTIONS = [
  'No minimum',
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
];

/* @var Options for maximum */
export const MAX_OPTIONS = [
  'No maximum',
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
];

const AvailableResourceItemCountFilter: React.FC<
  AvailableResourceItemCountFilterProps
> = ({ searchParams, setParams, actionComponentProps }) => {
  return (
    <Modal
      header="Filter by Available Items"
      content={({ toggleModal }) => (
        <div>
          <Container>
            <Flex>
              <ReactSelect
                className={styles.reactSelect}
                isClearable
                placeholder="Select minimum"
                defaultValue={
                  searchParams.available_resource_item_count_min
                    ? {
                        value:
                          searchParams.available_resource_item_count_min,
                        label:
                          MIN_OPTIONS[
                            parseInt(
                              searchParams.available_resource_item_count_min,
                              10,
                            ) + 1
                          ],
                      }
                    : {
                        value: MIN_OPTIONS[0],
                        label: MIN_OPTIONS[0],
                      }
                }
                options={Object.keys(MIN_OPTIONS).map((key) => ({
                  value: MIN_OPTIONS[key],
                  label: MIN_OPTIONS[key],
                }))}
                onChange={(selectedOption) =>
                  setParams({
                    available_resource_item_count_min:
                      selectedOption &&
                      selectedOption.value !== MIN_OPTIONS[0]
                        ? selectedOption.value
                        : undefined,
                  })
                }
              />
              <ReactSelect
                className={styles.reactSelect}
                isClearable
                placeholder="Select maximum"
                defaultValue={
                  searchParams.available_resource_item_count_max
                    ? {
                        value:
                          searchParams.available_resource_item_count_max,
                        label:
                          MAX_OPTIONS[
                            parseInt(
                              searchParams.available_resource_item_count_max,
                              10,
                            ) + 1
                          ],
                      }
                    : {
                        value: MAX_OPTIONS[0],
                        label: MAX_OPTIONS[0],
                      }
                }
                options={Object.keys(MAX_OPTIONS).map((key) => ({
                  value: MAX_OPTIONS[key],
                  label: MAX_OPTIONS[key],
                }))}
                onChange={(selectedOption) =>
                  setParams({
                    available_resource_item_count_max:
                      selectedOption &&
                      selectedOption.value !== MAX_OPTIONS[0]
                        ? selectedOption.value
                        : undefined,
                  })
                }
              />
            </Flex>
          </Container>
          <LayoutContainerActionButtons>
            <ActionComponent
              type="button"
              text="Done"
              onClick={toggleModal}
            />
          </LayoutContainerActionButtons>
        </div>
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon={ICONS.resource_item.primary}
          text="Available Items"
          onClick={toggleModal}
          {...actionComponentProps}
        />
      )}
    />
  );
};

export default AvailableResourceItemCountFilter;
