/**
 * Labstep
 *
 * @module containers/JupyterEdit
 * @desc Calls serverless endpoint to get jupyterlab url
 */

import React from 'react';
import { connect } from 'react-redux';
import { jupyterGetLink } from 'labstep-web/state/actions';
import { selectJupyterGetLinkStatus } from 'labstep-web/state/selectors';
import {
  WithJupyterEditInjectedProps,
  WithJupyterEditMapDispatchToProps,
  WithJupyterEditMapStateToProps,
} from './types';

const mapStateToProps: any = (
  state,
): WithJupyterEditMapStateToProps => ({
  status: selectJupyterGetLinkStatus(state),
});

const mapDispatchToProps = (
  dispatch,
): WithJupyterEditMapDispatchToProps => ({
  getLink(guid: string) {
    dispatch(jupyterGetLink(guid));
  },
});

export const withJupyterEdit = <P, S>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<P, Exclude<keyof P, keyof WithJupyterEditInjectedProps>>
> => connect(mapStateToProps, mapDispatchToProps)(Component as any);
