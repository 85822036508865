/**
 * Labstep
 *
 * @module core/DataGrid/CellRenderer
 * @desc Generic CellRenderer parent component
 */

import { Entity } from 'labstep-web/models';
import React from 'react';
import DataGridCellRendererActionMenu from './ActionMenu';
import { ICellRendererActionMenuProps } from './ActionMenu/types';
import DataGridCellRendererFile from './File';
import { ICellRendererFileProps } from './File/types';
import DataGridCellRendererMultiSelect from './MultiSelect';
import { ICellRendererMultiSelectProps } from './MultiSelect/types';
import DataGridCellRendererRowNumber from './RowNumber';
import { ICellRendererRowNumberProps } from './RowNumber/types';

class DataGridCellRenderer extends React.Component {
  public static File: React.FC<ICellRendererFileProps<Entity>>;

  public static MultiSelect: React.FC<
    ICellRendererMultiSelectProps<Entity>
  >;

  public static RowNumber: React.FC<
    ICellRendererRowNumberProps<Entity>
  >;

  public static ActionMenu: React.FC<
    ICellRendererActionMenuProps<Entity>
  >;
}

DataGridCellRenderer.ActionMenu = DataGridCellRendererActionMenu;
DataGridCellRenderer.File = DataGridCellRendererFile;
DataGridCellRenderer.MultiSelect = DataGridCellRendererMultiSelect;
DataGridCellRenderer.RowNumber = DataGridCellRendererRowNumber;

export default DataGridCellRenderer;
