/**
 * Labstep
 *
 * @module components/ResourceItem/Action/Create/Template/Metadata
 * @desc Action to create a ResourceItem template from metadata
 */

import React from 'react';
import { Metadata, Resource, ResourceItem } from 'labstep-web/models';
import EntityCanCreateContainer from 'labstep-web/containers/Entity/Create/Can';
import { EntityCreateContainer } from 'labstep-web/containers';
import { MetadataActionCreateModal } from 'labstep-web/components/Metadata/Action/Create/Modal';
import { IResourceItemActionCreateTemplateMetadataProps } from './types';
import { getOnSubmit } from './utils';

export const ResourceItemActionCreateTemplateMetadata: React.FC<
  IResourceItemActionCreateTemplateMetadataProps
> = ({ resource, actionComponentProps, options, ...rest }) => (
  <EntityCanCreateContainer
    entityName={ResourceItem.entityName}
    parentName={Resource.entityName}
    parentId={resource.id}
  >
    {({
      create: createResourceItemTemplate,
      status: resourceItemStatus,
    }): React.ReactElement => (
      <EntityCreateContainer entityName={Metadata.entityName}>
        {({
          create: createMetadata,
          status: metadataStatus,
        }): React.ReactElement => (
          <MetadataActionCreateModal
            entity={resource.resource_item_template}
            entityName={ResourceItem.entityName}
            actionComponentProps={actionComponentProps}
            isTemplate
            onSubmit={getOnSubmit({
              createResourceItemTemplate,
              createMetadata,
              resource,
              options,
            })}
            disableAddAnother
            options={options}
            status={
              resourceItemStatus?.isFetching
                ? resourceItemStatus
                : metadataStatus
            }
            {...rest}
          />
        )}
      </EntityCreateContainer>
    )}
  </EntityCanCreateContainer>
);

export default ResourceItemActionCreateTemplateMetadata;
