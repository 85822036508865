import { Action } from 'labstep-web/models';
import { normalizeEntities } from 'labstep-web/services/normalize';

export const getIdTimestamp = (
  id: number | string,
  timestamp?: string,
): string | number => (timestamp ? `${id}-${timestamp}` : id);

/**
 * Normalize entity with timestamp
 * Replaces id and guid with original id and guid
 * @param action Action
 * @returns Normalized payload
 */
export const normalizeEntityWithTimestamp = (
  action: Action,
): Record<string, unknown> => {
  const payload = { ...action.payload };
  const className = action.meta.normalize;
  const timestamp = action.meta.params.at;
  const normalizedPayload = normalizeEntities(
    {
      ...payload,
      id: getIdTimestamp(payload.id, timestamp),
      guid: getIdTimestamp(payload.guid, timestamp),
    },
    className,
  );
  const { result, entities } = normalizedPayload;
  const normalizedPayloadWithIdAndGuidReset = {
    ...normalizedPayload,
    entities: {
      ...entities,
      [className]: {
        ...entities[className],
        [result]: {
          ...entities[className][result],
          id: payload.id,
          guid: payload.guid,
        },
      },
    },
  };
  return normalizedPayloadWithIdAndGuidReset;
};
