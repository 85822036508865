/**
 * Labstep
 *
 * @module components/Resource/ResourceItem/Card/EmptyState/Template
 * @desc Empty State for ResourceItem template
 */

import React from 'react';
import ResourceItemActionCreateTemplate from 'labstep-web/components/ResourceItem/Action/Create/Template';
import ResourceItemActionDeleteTemplate from 'labstep-web/components/ResourceItem/Action/Delete/Template';
import ResourceFormShowEditResourceTemplate from 'labstep-web/components/Resource/Form/ShowEdit/ResourceTemplate';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Button from 'labstep-web/core/Button';
import { Resource } from 'labstep-web/models';
import { IResourceItemCardEmptyStateTemplateProps } from './types';
import { getExplanation, getTitle } from './utils';

export const EmptyStatePrimaryAction: React.FC<
  IResourceItemCardEmptyStateTemplateProps
> = ({ resource }) =>
  resource.resource_item_template ? (
    <ResourceItemActionDeleteTemplate
      resourceItem={resource.resource_item_template}
    />
  ) : (
    <ResourceItemActionCreateTemplate
      resource={resource as Resource}
    />
  );

export const EmptyStateSecondaryAction: React.FC<
  IResourceItemCardEmptyStateTemplateProps
> = ({ resource }) =>
  resource.template ? (
    <Button
      route={{
        to: 'resource_template_show_resource_item_template',
        params: { id: resource.template.id },
      }}
      basic
      primary
    >
      {`Go to ${Resource.getHumanReadableEntityName(
        false,
        true,
        true,
      )}`}
    </Button>
  ) : (
    <ResourceFormShowEditResourceTemplate
      resource={resource as Resource}
    />
  );

const ResourceItemCardEmptyStateTemplate: React.FC<
  IResourceItemCardEmptyStateTemplateProps
> = ({ resource }) => (
  <EmptyState
    title={getTitle(resource as Resource)}
    explanation={getExplanation(resource as Resource)}
    src="/img/empty-state/inventory.svg"
    action={<EmptyStatePrimaryAction resource={resource} />}
    secondaryAction={
      !resource.is_template && (
        <EmptyStateSecondaryAction resource={resource} />
      )
    }
  />
);

export default ResourceItemCardEmptyStateTemplate;
