/**
 * Labstep
 *
 * @module components/AccessKey/Action/Delete
 * @desc Action to delete an accessKey
 */

import React from 'react';
import DeleteAction from 'labstep-web/components/Entity/Action/Delete';
import { AccessKey } from 'labstep-web/models';
import { IAccessKeyActionDeleteProps } from './types';

export const AccessKeyActionDelete: React.FC<
  IAccessKeyActionDeleteProps
> = ({ accessKeyItem }) => (
  <DeleteAction
    header={`Remove ${AccessKey.getHumanReadableEntityName()}`}
    confirmButtonContent="Remove"
    confirmMessage={`Are you sure you want to remove this ${AccessKey.getHumanReadableEntityName()}?`}
    entity={accessKeyItem}
    isRemoveAction
  />
);

export default AccessKeyActionDelete;
