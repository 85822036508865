/**
 * Labstep
 *
 * @module components/Entity/Action/Delete/Bulk
 * @desc Delete
 */

import React from 'react';
import {
  capitalize,
  getHumanReadableEntityName,
} from 'labstep-web/services/i18n.service';
import ActionComponentBulk from 'labstep-web/core/Action/Component/Bulk';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { EntityActionDeleteComponent } from '..';
import { IEntityActionDeleteBulkProps } from './types';

export const EntityActionDeleteBulk: React.FC<
  IEntityActionDeleteBulkProps
> = (props) => {
  const { entities, options = {}, header, entityName } = props;

  const entityIdsAccessObject = useHasAccessMultiple(
    entityName,
    entities.filter((e) => e.id).map((e) => e.id),
    Action.delete,
  );

  const entitiesWithPermission = entities
    ? entities.filter((e) => entityIdsAccessObject[e.id])
    : [];

  if (
    !entities ||
    entities.length === 0 ||
    entitiesWithPermission.length === 0
  ) {
    return null;
  }

  const id = entitiesWithPermission.map((e) => e.id);
  const firstEntity = entities[0];

  const isActive = firstEntity.deleted_at === null;
  const name = getHumanReadableEntityName(
    firstEntity.entityName,
    true,
  );

  const actionText = isActive ? 'Delete' : 'Restore';
  const message = `Are you sure you want to ${actionText.toLowerCase()} these ${name}?`;

  return (
    <EntityActionDeleteComponent
      entityName={firstEntity.entityName}
      id={id}
      message={message}
      header={header || `${actionText} ${capitalize(name)}`}
      isActive={isActive}
      confirmButtonContent={actionText}
      actionComponentProps={{
        type: 'children',
        children: ({ onClick }) => (
          <ActionComponentBulk
            entitiesWithPermissionCount={
              entitiesWithPermission.length
            }
            entitiesSelectedCount={entities.length}
            content={actionText}
            icon={isActive ? 'trash' : 'undo'}
            onClick={onClick}
          />
        ),
      }}
      options={options}
    />
  );
};

export default EntityActionDeleteBulk;
