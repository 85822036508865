/**
 * Labstep
 *
 * @module components/Protocol/Action/AddVariable/Metadata
 * @desc Add metadata variable action for protocol
 */

import MetadataActionCreate from 'labstep-web/components/Metadata/Action/Create';
import { ICONS } from 'labstep-web/constants';
import { Metadata } from 'labstep-web/models';
import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import React from 'react';
import ProtocolActionAddVariableGroup from '../Group';
import { IProtocolActionAddVariableMetadataProps } from './types';

export const ProtocolActionAddVariableMetadata: React.FC<
  IProtocolActionAddVariableMetadataProps
> = ({ protocol, isInput }) => {
  const { setActiveVariable } = useProtocolConditionModalContext();

  const name = Metadata.getHumanReadableEntityName(
    false,
    true,
    protocol,
  );
  const params = {
    metadata_thread_id: protocol.metadata_thread.id,
  };
  return (
    <ProtocolActionAddVariableGroup
      entityName={Metadata.entityName}
      protocol={protocol}
      name={name}
      params={params}
      icon={ICONS.metadata.primary}
      isInput={isInput}
      action={
        <MetadataActionCreate
          entity={protocol}
          body={{
            is_variable: true,
            is_input: !!isInput,
            is_output: !isInput,
            ...params,
          }}
          actionComponentProps={{
            type: 'text',
            icon: 'plus',
          }}
          options={{
            onSuccess: ({ response }): void =>
              setActiveVariable({
                field_type: Metadata.entityName,
                id: response.result,
              }),
          }}
        />
      }
    />
  );
};

export default ProtocolActionAddVariableMetadata;
