/**
 * Labstep
 *
 * @module components/PurchaseOrder/Filter/Status/List
 * @desc Filter for PurchaseOrder status as list
 */

import React from 'react';
import { FilterSection } from 'labstep-web/components/Filter/List';
import Icon from 'labstep-web/core/Icon';
import { ICONS } from 'labstep-web/constants';
import { STRINGS } from 'labstep-web/strings';
import { PurchaseOrderStatusValues } from 'labstep-web/models';
import styles from './styles.module.scss';

export const PurchaseOrderFilterStatusList: React.FC = () => (
  <FilterSection
    header={STRINGS.filter.header.status}
    filter={{
      entityName: 'purchase_order',
      name: 'status',
    }}
    filters={Object.keys(PurchaseOrderStatusValues).map((key) => ({
      component: (
        <Icon
          className={styles[key]}
          name={ICONS.purchase_order.info.status}
        />
      ),
      text: PurchaseOrderStatusValues[
        key as keyof typeof PurchaseOrderStatusValues
      ],
      params: { status: key },
    }))}
  />
);

export default PurchaseOrderFilterStatusList;
