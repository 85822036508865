/**
 * Labstep
 *
 * @module components/Entity/ActionToggle
 * @desc Toggle (add/remove child from parent) action
 */

import React from 'react';
import Confirmation from 'labstep-web/core/Modal/Confirmation';
import ActionComponent from 'labstep-web/core/Action/Component';
import { EntityToggleContainer } from 'labstep-web/containers';
import {
  capitalize,
  getHumanReadableEntityName,
} from 'labstep-web/services/i18n.service';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IActionComponentProps } from 'labstep-web/core/Action/Component/types';
import { IEntityActionToggleProps } from './types';

const EntityActionToggle: React.FC<IEntityActionToggleProps> = (
  props,
) => {
  const {
    action,
    entity,
    entityId,
    entityName,
    parentName,
    parentId,
    single,
    confirmationMessage,
    onSuccess,
    toast = true,
    header,
    childKeyName,
    noConfirmation = false,
    actionComponentProps,
  } = props;

  const id = entityId || entity.idAttr;
  const icon = action === 'add' ? 'plus' : 'times';

  const name = getHumanReadableEntityName(entityName);

  const verb = action === 'add' ? 'to my' : 'from my';

  const additionalProps = {
    type: 'option',
    text: `${capitalize(action)} ${verb} ${name}s`,
    icon,
    ...actionComponentProps,
  } as IActionComponentProps;

  const children = (
    <EntityToggleContainer
      single={single}
      parentName={parentName}
      parentId={parentId}
      entityName={entityName}
      childKeyName={childKeyName}
      entityIds={id}
    >
      {({ toggle, status }) => {
        if (
          action === 'add' ||
          (action === 'remove' && noConfirmation)
        ) {
          return (
            <ActionComponent
              {...additionalProps}
              status={status}
              onClick={(e: React.MouseEvent<any>) => {
                e.preventDefault();
                toggle(action, undefined, {
                  toast,
                  onSuccess: () => {
                    if (onSuccess) {
                      onSuccess();
                    }
                  },
                });
              }}
            />
          );
        }

        return (
          <Confirmation
            header={header || `Remove ${name}`}
            status={status}
            onConfirm={({ toggleModal }) =>
              toggle(action, undefined, {
                toast,
                onSuccess: () => {
                  toggleModal();
                  if (onSuccess) {
                    onSuccess();
                  }
                },
              })
            }
            message={
              confirmationMessage ||
              `Are you sure you want to remove this ${name} from your ${getHumanReadableEntityName(
                parentName,
              )}?`
            }
            confirmButtonContent="Remove"
          >
            {({ toggleModal }) => (
              <ActionComponent
                {...additionalProps}
                onClick={toggleModal}
              />
            )}
          </Confirmation>
        );
      }}
    </EntityToggleContainer>
  );

  // Only handle case when parentId is singular.
  // TODO: Do we want to hide it in case of array if all
  // entities have false edit permissions?
  if (!Array.isArray(parentId)) {
    return (
      <Can
        entityName={parentName}
        id={parentId}
        action={`${entityName}:${Action.add_remove}` as Action}
      >
        {children}
      </Can>
    );
  }
  return children;
};

export default EntityActionToggle;
