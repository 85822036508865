/**
 * Labstep
 *
 * @module services/analytics/index
 * @desc ES6 barrel for analytics actions
 */

import actions from './actions';

export default actions;
