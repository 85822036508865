/**
 * Labstep
 *
 * @module components/Device/Card/EmptyState
 * @desc Empty state for device
 */

import React, { useMemo } from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import DeviceActionCreate from 'labstep-web/components/Device/Action/Create';
import { Device } from 'labstep-web/models';

const DeviceEmptyStateTemplate: React.FC = () => {
  const action = useMemo(() => <DeviceActionCreate isTemplate />, []);
  return (
    <EmptyState
      title={`No ${Device.getHumanReadableEntityName(true, false)}`}
      explanation={`${Device.getHumanReadableEntityName(
        true,
        true,
      )} are customisable metadata templates to apply to device. This makes it easy to upload and manage device with the same descriptive information.`}
      src="/img/empty-state/inventory.svg"
      action={action}
    />
  );
};

export default DeviceEmptyStateTemplate;
