/**
 * Labstep
 *
 * @desc A service to deal with amount and units
 */

import * as ExcelIO from '@grapecity/spread-excelio';
import { configService } from '../config.service';

export class ExcelIOService {
  /**
   * Initialise SpreadJS LicenseKey and return a new IO instance.
   */
  public static get IO(): ExcelIO.IO {
    if (configService && configService.spreadJSLicenseKey) {
      (ExcelIO as any).LicenseKey = configService.spreadJSLicenseKey;
    }
    return new ExcelIO.IO();
  }
}
