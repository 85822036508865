/**
 * Labstep
 *
 * @module components/Layout/Menu/TopBar/Profile
 * @desc Profile (opening in right side bar)
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import Dropdown from 'labstep-web/core/Dropdown';
import UserActionSignout from 'labstep-web/components/User/Action/Signout';
import Settings from 'labstep-web/components/User/Show/Settings';
import Profile from 'labstep-web/components/User/Show/Profile';
import PersonalNotes from 'labstep-web/components/User/Show/PersonalNotes';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import { ICONS } from 'labstep-web/constants';
import { ILayoutMenuTopBarProfileProps } from './types';
import LayoutMenuTopBarProfileViewComponent from './ViewComponent';
import LayoutMenuTopBarProfileMobile from './Mobile';

export const LayoutMenuTopBarProfile: React.FC<
  ILayoutMenuTopBarProfileProps
> = ({ authenticatedUserEntity }) => {
  if (!authenticatedUserEntity) {
    return null;
  }

  return (
    <Dropdown
      pointing="top right"
      icon={null}
      trigger={<LayoutMenuTopBarProfileViewComponent />}
    >
      <LayoutMenuTopBarProfileMobile />
      <PersonalNotes
        user={authenticatedUserEntity}
        viewComponent={({ toggleModal }): React.ReactElement => (
          <Dropdown.Item onClick={toggleModal}>
            <Icon name={ICONS.thread.primary} />
            Personal Notes
          </Dropdown.Item>
        )}
      />
      <Profile
        user={authenticatedUserEntity}
        viewComponent={({ toggleModal }): React.ReactElement => (
          <Dropdown.Item onClick={toggleModal}>
            <Icon name="user circle" />
            Profile
          </Dropdown.Item>
        )}
      />
      <Settings
        user={authenticatedUserEntity}
        viewComponent={({ toggleModal }): React.ReactElement => (
          <Dropdown.Item onClick={toggleModal}>
            <Icon name="cog" />
            Account Settings
          </Dropdown.Item>
        )}
      />
      <UserActionSignout
        actionComponentProps={{
          icon: 'sign out',
          type: 'option',
        }}
      />
    </Dropdown>
  );
};

export default withAuthenticatedUserEntity(LayoutMenuTopBarProfile);
