/**
 * Labstep
 */

import { ColDef } from 'labstep-web/core/DataGrid/types';
import {
  OrderRequest,
  OrderRequestStatus,
  OrderRequestStatusValues,
} from 'labstep-web/models';
import { dateOnly } from 'labstep-web/services/date.service';

export const colDefLogCreatedAt = (
  status: OrderRequestStatus,
): ColDef<OrderRequest> => ({
  colId: `${status}_log_created_at`,
  headerName: `Date ${OrderRequestStatusValues[status]}`,
  headerComponentParams: {
    icon: 'calendar alternate outline',
  },
  valueFormatter: (params) => {
    const date = params.data.getStatusLog(status)?.created_at;
    return date ? dateOnly(date) : '';
  },
  valueGetter: (params) =>
    params.data.getStatusLog(status)?.created_at || null,
  editable: false,
});
