/**
 * Labstep
 *
 * @module components/ResourceLocation/Form/ShowEdit/SubOuter
 * @desc ShowEdit form to add sub location to a resource location
 */

import React from 'react';
import FormShowEditSearchSelect from 'labstep-web/core/Form/ShowEdit/SearchSelect';
import ActionComponent from 'labstep-web/core/Action/Component';
import {
  Resource,
  ResourceItem,
  ResourceLocation,
} from 'labstep-web/models';
import { IResourceLocationFormShowEditSubOuterProps } from './types';

export const ResourceLocationFormShowEditSubOuter = <
  TParentEntity extends ResourceLocation | ResourceItem | Resource,
>({
  outerLocation,
  parentEntity,
  childKeyName,
}: IResourceLocationFormShowEditSubOuterProps<TParentEntity>) => {
  return outerLocation ? (
    <FormShowEditSearchSelect
      parentEntity={parentEntity}
      childEntityName={ResourceLocation.entityName}
      childKeyName={childKeyName}
      optimistic={false}
      hasValue
      customShow={({ toggle }) => (
        <ActionComponent
          type="icon"
          icon="plus"
          elementProps={{ popup: { content: 'Specify sublocation' } }}
          onClick={toggle}
        />
      )}
      searchSelectProps={{
        value: null,
        createBody: {
          outer_location_guid: outerLocation.guid,
        },
        params: {
          not_guid:
            parentEntity instanceof ResourceLocation
              ? [parentEntity.guid]
              : [],
          outer_location_guid: outerLocation.guid,
        },
        creatable: true,
        isClearable: false,
      }}
    >
      <div />
    </FormShowEditSearchSelect>
  ) : null;
};

export default ResourceLocationFormShowEditSubOuter;
