/**
 * Labstep
 */

import React from 'react';
import { Metadata, ProtocolDevice } from 'labstep-web/models';
import { METADATA_DEFAULT_LABEL_DATA } from 'labstep-web/models/metadata/constants';

export const getDisallowedMessage = (metadatas: Metadata[]) => {
  return (
    <div>
      <p>
        {`This ${ProtocolDevice.getHumanReadableEntityName()} is linked to the following ${Metadata.getHumanReadableEntityName(
          true,
          false,
          null,
          true,
        )}:`}
      </p>
      <p>
        {metadatas.map((metadata, i) => (
          <b key={metadata.id}>{`${
            metadata.displayName || METADATA_DEFAULT_LABEL_DATA
          }${i !== metadatas.length - 1 ? ', ' : ''}`}</b>
        ))}
      </p>
      <p>
        {`Please unlink these before proceeding to remove this ${ProtocolDevice.getHumanReadableEntityName()}.`}
      </p>
    </div>
  );
};
