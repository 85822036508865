/**
 * Labstep
 */

import { IEntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import { denormalizeNormalizedEntity } from 'labstep-web/state/selectors';
import { ISearchSelectModalContentProps } from './types';

export const getCreateOptions = (
  entityName: ISearchSelectModalContentProps['entityName'],
  onChange: ISearchSelectModalContentProps['onChange'],
) => ({
  onSuccess: ({ response }) => {
    const entity = denormalizeNormalizedEntity(response, entityName);
    onChange(entity);
  },
});

export const getBody = (
  createBody: ISearchSelectModalContentProps['createBody'],
  searchParams?: IEntitySearchContainerChildrenProps['searchParams'],
) => ({
  name: searchParams?.search_query,
  ...createBody,
});
