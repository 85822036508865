/**
 * Labstep
 *
 * @module components/Toast
 * @desc A dummy component to fire toasts
 */

import React from 'react';
import hotToast from 'react-hot-toast';
import CoreToast from 'labstep-web/core/Toast';
import {
  withActiveGroup,
  withUi,
  withToast,
} from 'labstep-web/containers';
import { ErrorBoundaryContainer } from 'labstep-web/containers/ErrorBoundary';
import { v4 } from 'uuid';
import { IToastProps } from './types';
import { getToastParams } from './utils';

export class Toast extends React.Component<IToastProps> {
  public componentDidUpdate(prevProps: IToastProps): void {
    const { toast, activeGroup } = this.props;

    if (toast.timestamp !== prevProps.toast.timestamp) {
      const params = getToastParams(toast, activeGroup);
      const id = v4();
      if (params !== null) {
        const { type, message, options = {} } = params;
        if (type in hotToast) {
          hotToast[type as 'success' | 'error'](
            () => (
              <CoreToast
                message={message}
                options={{ type, ...options }}
                close={(): void => hotToast.dismiss(id)}
              />
            ),
            {
              duration: options.timeout || 5000,
              position: 'top-right',
              id,
            },
          );
        }
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public render(): React.ReactElement {
    return (
      <ErrorBoundaryContainer FallbackComponent={<span />}>
        <span />
      </ErrorBoundaryContainer>
    );
  }
}

export default withToast(withUi(withActiveGroup(Toast)));
