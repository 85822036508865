/**
 * Labstep
 *
 * @module state/selectors/entity
 * @desc Selectors for any entity with the generic API
 */

import camelCase from 'lodash/camelCase';
import {
  denormalizeEntities,
  denormalizeEntity,
} from 'labstep-web/services/normalize';
import {
  getCreateKeyPrefix,
  getToggleKey,
} from 'labstep-web/state/actions/entity';
import { stringifyParamsInOrder } from 'labstep-web/state/utils';
import { IStatus } from 'labstep-web/typings';
import { LabstepReduxState } from '../types';
import { getNestedEntitiesObject, hasKey } from './helpers';

export const denormalizeNormalizedEntity = (response, entityName) =>
  denormalizeEntity(
    response.entities[entityName][response.result],
    camelCase(entityName),
    response.entities,
  );

/**
 * Select a denormalized entity by id
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} entityName - Entity name
 * @param  {integer} id - Entity id
 */
export const selectEntity = (
  state: any,
  entityName: string,
  id: number | string,
) => {
  const denormalizedEntity = denormalizeEntity(
    state.entities[entityName].byId[id],
    camelCase(entityName),
    getNestedEntitiesObject(state),
  );
  return denormalizedEntity;
};

/**
 * Select a created entity
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} entityName - Entity name
 * @param  {integer} uuid - Unique identifier
 */
export const selectCreatedEntity = (
  state: any,
  entityName: string,
  uuid: string,
) => {
  const { creatingIds } = state.entities[entityName];
  const creatingIdKey = Object.keys(creatingIds).find((key) =>
    key.includes(uuid),
  );
  const creatingId = creatingIds[creatingIdKey];

  if (creatingId && creatingId.status.id) {
    const { id } = creatingId.status;
    return selectEntity(state, entityName, id);
  }

  return undefined;
};

/**
 * Select a newly created entity by id
 *
 * @function
 * @param  {object}. state - Redux state
 * @param  {string}  entityName - Entity name
 * @param  {string}  parentName
 * @param  {integer} parentId
 * @param  {string} customKey
 */
export const selectCreatedEntities = (
  state: any,
  entityName: string,
  parentName: string,
  parentId: number | string,
  uuid?: string,
) => {
  const prefix = getCreateKeyPrefix(parentName, parentId, uuid);
  const { creatingIds } = state.entities[entityName];
  const creatingEntities = Object.keys(creatingIds)
    .filter(
      (key: any) =>
        key.startsWith(prefix) && creatingIds[key].status.id,
    )
    .map((key: any) =>
      selectEntity(state, entityName, creatingIds[key].status.id),
    );

  return creatingEntities;
};

/**
 * Support legacy selectors
 *
 * @function
 */
export const selectEntitiesByArray = (
  state: any,
  entityName: string,
  array: any[],
) =>
  denormalizeEntities(
    array,
    entityName,
    getNestedEntitiesObject(state),
  );

/**
 * Select entities by params
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} entityName - Entity name
 * @param  {object} params - Query parameters
 */
export const selectEntities = (
  state: any,
  entityName: string,
  params: any,
) => {
  const readingId =
    state.entities[entityName].readingIds[
      stringifyParamsInOrder(params)
    ];
  const array = (readingId && readingId.items) || [];

  return selectEntitiesByArray(state, entityName, array);
};

/**
 * Select page items
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} entityName - Entity name
 * @param  {object} params - Query parameters
 * @param  {number} page - Page
 */
export const selectPageItems = (
  state: any,
  entityName: string,
  params: any,
  page: number,
) =>
  denormalizeEntities(
    state.entities[entityName].readingIds[
      stringifyParamsInOrder(params)
    ][page].items,
    entityName,
    getNestedEntitiesObject(state),
  );

/**
 * Select page count
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} entityName - Entity name
 * @param  {object} params - Query parameters
 * @param  {number} page - Page
 */
export const selectPageCount = (
  state: any,
  entityName: string,
  params: any,
) =>
  state.entities[entityName].readingIds[
    stringifyParamsInOrder(params)
  ].count;

/**
 * Select page total
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} entityName - Entity name
 * @param  {object} params - Query parameters
 * @param  {number} page - Page
 */
export const selectReadPageTotal = (
  state: any,
  entityName: string,
  params: any,
) => {
  const { readingIds } = state.entities[entityName];
  const key = stringifyParamsInOrder(params);
  if (!hasKey(readingIds, key)) {
    return false;
  }
  return readingIds[key].total;
};

/**
 * Select cursor items
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} entityName - Entity name
 * @param  {object} params - Query parameters
 * @param  {string} cursor - cursor
 */
export const selectSingleItems = (
  state: any,
  entityName: string,
  params: any,
) => {
  const readingId =
    state.entities[entityName].readingIds[
      stringifyParamsInOrder(params)
    ];
  const singleItem: any = (readingId && readingId.items) || undefined;
  if (undefined === singleItem) {
    return null;
  }
  return denormalizeEntity(
    singleItem,
    camelCase(entityName),
    getNestedEntitiesObject(state),
  );
};

/**
 * Select cursor items
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} entityName - Entity name
 * @param  {object} params - Query parameters
 * @param  {string} cursor - cursor
 */
export const selectCursorItems = (
  state: any,
  entityName: string,
  params: any,
) => {
  const readingId =
    state.entities[entityName].readingIds[
      stringifyParamsInOrder(params)
    ];
  const array = (readingId && readingId.items) || [];
  return denormalizeEntities(
    array,
    entityName,
    getNestedEntitiesObject(state),
  );
};

/**
 * Returns search entities
 *
 * @function
 * @param  {object} state - Redux State
 * @param  {string} entityName - Entity Name
 */
export const selectSearchEntities = (
  state: any,
  entityName: string,
) => {
  const readingId = state.entities[entityName].readingIds.search;
  const array = (readingId && readingId.items) || [];
  return selectEntitiesByArray(state, entityName, array);
};

/**
 * Returns the search status
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} entityName - Entity name
 * @return {object} - Status
 */
export const selectSearchEntitiesStatus = (
  state: any,
  entityName: string,
) => {
  const readingId = state.entities[entityName].readingIds.search;
  return readingId && readingId.status;
};

/**
 * Select the request status when creating an entity
 *
 * @function
 */
export const selectCreateEntityStatus = (
  state: any,
  entityName: string,
  uuid: string,
) => {
  const { creatingIds } = state.entities[entityName];
  const creatingIdKey = Object.keys(creatingIds).find((key) =>
    key.includes(uuid),
  );
  const creatingId = creatingIds[creatingIdKey];
  return creatingId && creatingId.status;
};

/**
 * Select the request status of all creating entities
 *
 * @function
 */
export const selectCreateEntityAllStatuses = (
  state: any,
  entityName: string,
) => {
  const { creatingIds } = state.entities[entityName];
  return Object.keys(creatingIds).map(
    (creatingIdKey) => creatingIds[creatingIdKey].status,
  );
};

/**
 * Select the request status when creating an entity
 */
export const selectCreateEntitiesStatuses = (
  state: any,
  entityName: string,
  parentName: string,
  parentId: number | string,
  uuid?: string,
) => {
  const prefix = getCreateKeyPrefix(parentName, parentId, uuid);
  return Object.keys(state.entities[entityName].creatingIds)
    .filter((key) => key.startsWith(prefix))
    .map((key) => state.entities[entityName].creatingIds[key].status);
};

/**
 * Select the request status when reading an entity
 *
 * @function
 */
export const selectReadEntityStatus = (
  state: any,
  entityName: string,
  id: number | string,
) => {
  const { readingIds } = state.entities[entityName];
  return hasKey(readingIds, id.toString())
    ? readingIds[id].status
    : false;
};

/**
 * Select the request status when reading a list of entities
 *
 * @function
 */
export const selectReadEntitiesStatus = (
  state: any,
  entityName: string,
  params: any,
) => {
  const { readingIds } = state.entities[entityName];
  const key = stringifyParamsInOrder(params);
  return hasKey(readingIds, key) ? readingIds[key].status : false;
};

/**
 * Select the page based pagination entities
 *
 * @function
 */
export const selectReadPageEntities = (
  state: any,
  entityName: string,
  params: any,
  page: number,
) => {
  const { readingIds } = state.entities[entityName];
  const key = stringifyParamsInOrder(params);
  if (!hasKey(readingIds, key)) {
    return [];
  }
  if (!readingIds[key].pages[page]) {
    return [];
  }
  return denormalizeEntities(
    readingIds[key].pages[page].items,
    entityName,
    getNestedEntitiesObject(state),
  );
};

/**
 * Select all page based pagination entities
 * @param state State
 * @param entityName Entity name
 * @param params Parameters
 * @returns Entities
 */
export const selectReadPageEntitiesAllPages = (
  state: LabstepReduxState,
  entityName: string,
  params: Record<string, unknown>,
): any[] => {
  const { readingIds } = state.entities[entityName];
  const key = stringifyParamsInOrder(params);
  if (!hasKey(readingIds, key)) {
    return [];
  }
  const { pages } = readingIds[key];
  const items = Object.keys(pages).reduce(
    (acc, page) => [...acc, ...pages[page].items],
    [] as string[],
  );
  // Remove duplicates that can appear when items are added or deleted
  const uniqueItems = Array.from(new Set(items));
  return denormalizeEntities(
    uniqueItems,
    entityName,
    getNestedEntitiesObject(state),
  );
};

/**
 * Select the request status when reading a page-based paginated list of entities
 *
 * @function
 */
export const selectReadPageStatus = (
  state: any,
  entityName: string,
  params: any,
  page: number,
) => {
  const { readingIds } = state.entities[entityName];
  const key = stringifyParamsInOrder(params);
  if (!hasKey(readingIds, key)) {
    return false;
  }
  return readingIds[key].pages[page]
    ? readingIds[key].pages[page].status
    : false;
};

export const selectReadPageStatusAllPages = (
  state: any,
  entityName: string,
  params: any,
): Record<number, IStatus> => {
  const { readingIds } = state.entities[entityName];
  const key = stringifyParamsInOrder(params);
  if (!hasKey(readingIds, key)) {
    return {};
  }
  const statuses: Record<number, IStatus> = {};
  Object.keys(readingIds[key].pages).forEach((page: any) => {
    statuses[page] = readingIds[key].pages[page].status;
  });
  return statuses;
};

export const selectReadTotalPages = (
  state: any,
  entityName: string,
  params: any,
) => {
  const { readingIds } = state.entities[entityName];
  const key = stringifyParamsInOrder(params);
  if (!hasKey(readingIds, key)) {
    return false;
  }
  return readingIds[key].totalPages;
};

/**
 * Select the request status when reading a cursor-based paginated list of entities
 *
 * @function
 */
export const selectReadCursorStatus = (
  state: any,
  entityName: string,
  params: any,
) => {
  const { readingIds } = state.entities[entityName];
  const key = stringifyParamsInOrder(params);
  return hasKey(readingIds, key) ? readingIds[key].status : false;
};

/**
 * Select current cursor
 *
 * @function
 */
export const selectCurrentCursor = (
  state: any,
  entityName: string,
  params: any,
) => {
  const { readingIds } = state.entities[entityName];
  const key = stringifyParamsInOrder(params);
  return hasKey(readingIds, key)
    ? readingIds[key].current_cursor
    : undefined;
};

/**
 * Select total in cursor
 *
 * @function
 */
export const selectCursorTotal = (
  state: any,
  entityName: string,
  params: any,
) => {
  const { readingIds } = state.entities[entityName];
  const key = stringifyParamsInOrder(params);
  return hasKey(readingIds, key) ? readingIds[key].total : 0;
};

/**
 * Select next cursor
 *
 * @function
 */
export const selectNextCursor = (
  state: any,
  entityName: string,
  params: any,
) => {
  const { readingIds } = state.entities[entityName];
  const key = stringifyParamsInOrder(params);
  return hasKey(readingIds, key)
    ? readingIds[key].next_cursor
    : undefined;
};

/**
 * Select the request status when updating an entity
 *
 * @function
 */
export const selectUpdateEntityStatus = (
  state: any,
  entityName: string,
  id: number | string,
) => {
  const { updatingIds } = state.entities[entityName];
  return hasKey(updatingIds, id.toString())
    ? updatingIds[id].status
    : false;
};

/**
 * Select the request status of all updating entities
 *
 * @function
 */
export const selectUpdateEntityAllStatuses = (
  state: any,
  entityName: string,
) => {
  const { updatingIds } = state.entities[entityName];
  return Object.keys(updatingIds).map(
    (updatingIdKey) => updatingIds[updatingIdKey].status,
  );
};

/**
 * Select the request status when deleting an entity
 *
 * @function
 */
export const selectDeleteEntityStatus = (
  state: any,
  entityName: string,
  id: number | string,
) => {
  const { deletingIds } = state.entities[entityName];
  return hasKey(deletingIds, id.toString())
    ? deletingIds[id].status
    : false;
};

/**
 * Select the request status when toggling an entity
 *
 * @function
 */
export const selectToggleStatus = (
  state: any,
  entityName: string,
  entityIds: string | number | (string | number)[],
  parentName: string,
  parentId: string | number | (string | number)[],
) => {
  const identifier = getToggleKey(
    entityName,
    entityIds,
    parentName,
    parentId,
  );
  const { togglingIds } = state.entities[parentName];

  return hasKey(togglingIds, identifier)
    ? togglingIds[identifier].status
    : false;
};
