/**
 * Labstep
 *
 * @module components/Entity/DataGrid/Action/SaveView
 * @desc Entity DataGrid Action Save View
 */

import { actionComponentProps } from 'labstep-web/components/Entity/DataGrid/Action/SaveView/constants';
import { useEntityViewContext } from 'labstep-web/components/EntityView/context';
import UpdateOrCreate from 'labstep-web/containers/UpdateOrCreate';
import ActionComponent from 'labstep-web/core/Action/Component';
import { useDataGridContext } from 'labstep-web/core/DataGrid/context';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import { EntityView } from 'labstep-web/models';
import React, { useEffect, useRef, useState } from 'react';

export const EntityDataGridActionSaveView: React.FC = () => {
  const entityViewContext = useEntityViewContext();
  const { agGrid } = useDataGridContext();
  const [stateChange, setStateChange] = useState(false);
  const { activeGroup } = useActiveGroup();

  useEffect(() => {
    let i = 0;
    agGrid?.api.addGlobalListener((type: string) => {
      if (
        type.indexOf('column') >= 0 &&
        type !== 'columnHoverChanged'
      ) {
        // hack to skip initial column state change
        if (entityViewContext?.activeEntityView) {
          // columnEverythingChanged, columnMoved, columnResized
          if (i > 2) {
            setStateChange(true);
          }
        } else {
          setStateChange(true);
        }
        i += 1;
      }
    });
  }, [agGrid]);

  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      setStateChange(true);
    }
    didMountRef.current = true;
  }, [entityViewContext?.columnOptions]);

  if (
    !agGrid?.columnApi?.getColumnState() ||
    !entityViewContext ||
    !activeGroup
  ) {
    return null;
  }

  const {
    activeEntityView,
    entityName,
    parameters,
    context,
    allowSave,
  } = entityViewContext;

  if (!allowSave || !stateChange) {
    return null;
  }

  return (
    <UpdateOrCreate
      entityName={EntityView.entityName}
      updateProps={{ id: activeEntityView?.guid as string }}
      createProps={
        activeEntityView
          ? undefined
          : {
              createBody: {
                parameters,
                entity_name: entityName,
                context,
                group_id: activeGroup.id,
              },
            }
      }
    >
      {({ action, status }) => (
        <ActionComponent
          {...actionComponentProps}
          status={status}
          onClick={() =>
            action(
              {
                state: agGrid.columnApi?.getColumnState(),
                column_options: entityViewContext.columnOptions,
              },
              {
                onSuccess: () => setStateChange(false),
              },
            )
          }
        />
      )}
    </UpdateOrCreate>
  );
};

export default EntityDataGridActionSaveView;
