/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/Show/CenterDeleted
 * @desc ExperimentWorkflow Show Screen Center Deleted
 */

import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import Card from 'labstep-web/core/Card/Card';
import Header from 'labstep-web/core/Header';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import LayoutMainContentCenter from 'labstep-web/components/Layout/MainContent/Center';
import { ICenterDeletedProps, IDeletedCardProps } from './types';

export const DeletedCard: React.FC<IDeletedCardProps> = ({
  entity,
}) => (
  <div>
    <p>{`This ${getHumanReadableEntityName(
      entity.entityName,
    )} is deleted.`}</p>
    <EntityActionDelete
      actionComponentProps={{
        type: 'button',
      }}
      entity={entity}
    />
  </div>
);

const CenterDeleted: React.FC<ICenterDeletedProps> = ({
  entity,
  messageForDeleted,
}) => (
  <LayoutMainContentCenter>
    <Card>
      <Header size="large">{entity.displayName}</Header>
      <DeletedCard entity={entity} />
      {messageForDeleted}
    </Card>
  </LayoutMainContentCenter>
);

export default CenterDeleted;
