/**
 * Labstep
 *
 * @module components/Resource/Card/EmptyState
 * @desc Empty state for resource
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { Resource } from 'labstep-web/models';
import EntityActionCreateMenu from 'labstep-web/components/Entity/Action/Create/Menu';
import EntityImportModal from 'labstep-web/components/EntityImport/Modal/Trigger';
import { IResourceEmptyStateProps } from './types';

const label = getHumanReadableEntityName(
  Resource.entityName,
  true,
  true,
);
export const ResourceEmptyState: React.FC<
  IResourceEmptyStateProps
> = ({ resourceTemplateId }) => (
  <EmptyState
    src="/img/empty-state/inventory.svg"
    title={`No ${label}`}
    explanation={
      <>
        <span>
          Manage the inventory you use in your work. Keep track of the
          unique details, technical specifications and safety
          documentation relevant to your resources and reference them
          in protocols.
        </span>
        <br />
        <br />
        <span>
          Specify batches of a particular resource as items. You can
          reference items when you use them in experiments.
        </span>
      </>
    }
    action={
      <EntityActionCreateMenu
        entityName={Resource.entityName}
        templateId={resourceTemplateId}
      />
    }
    secondaryAction={
      <EntityImportModal
        targetEntityName={Resource.entityName}
        templateId={resourceTemplateId}
        actionComponentProps={{
          type: 'button',
          elementProps: { basic: true },
        }}
      />
    }
  />
);
