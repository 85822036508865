/**
 * Labstep
 *
 * @module screens/PurchaseOrder/Show
 * @desc PurchaseOrder Show Screen
 */

import React from 'react';
import MasterShow from 'labstep-web/screens/Master/Show';
import DocumentHead from 'labstep-web/core/DocumentHead';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { PurchaseOrder } from 'labstep-web/models';
import GuardLabstepAdmin from 'labstep-web/components/Guard/LabstepAdmin';
import Center from './Center';
import Right from './Right';
import {
  IPurchaseOrderShowScreenContainerProps,
  IPurchaseOrderShowScreenProps,
} from './types';

export const PurchaseOrderShowScreen: React.FC<
  IPurchaseOrderShowScreenProps
> = ({ purchaseOrder }) => (
  <>
    <DocumentHead title={purchaseOrder.name} />
    <GuardLabstepAdmin>
      {({ isLabstepAdmin }) => (
        <MasterShow
          entity={purchaseOrder}
          center={<Center purchaseOrder={purchaseOrder} />}
          right={
            isLabstepAdmin &&
            purchaseOrder.outbound_purchase_order ? null : (
              <Right purchaseOrder={purchaseOrder} />
            )
          }
        />
      )}
    </GuardLabstepAdmin>
  </>
);

const PurchaseOrderShowScreenContainer: React.FC<
  IPurchaseOrderShowScreenContainerProps
> = ({ match }) => (
  <ReadOnMountHOC
    type="entities"
    entityName={PurchaseOrder.entityName}
    params={{
      get_single: 1,
      is_deleted: 'both',
      id: match.params.id,
    }}
    loading={{ loader: 'show', cached: true }}
    children={({ entity }) => (
      <PurchaseOrderShowScreen purchaseOrder={entity} />
    )}
  />
);

export default PurchaseOrderShowScreenContainer;
