/**
 * Labstep
 *
 * @module components/User/SearchSelect
 * @desc Search users
 */

import React from 'react';
import SearchSelect from 'labstep-web/core/Select/Search';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import { User } from 'labstep-web/models';
import { IUserSearchSelectProps } from './types';

export const UserSearchSelect: React.FC<IUserSearchSelectProps> = ({
  onChange,
  authenticatedUserEntity,
  authenticatedUserEntityStatus,
  read,
  ...rest
}) => {
  const { activeGroup } = useActiveGroup();
  let params: Record<string, unknown> = {};
  const searchKey = 'search_query';
  if (authenticatedUserEntity.userOrganization) {
    params = {
      is_deleted: 'both',
      organization_id:
        authenticatedUserEntity.userOrganization.organization.id,
    };
  } else if (activeGroup) {
    params = {
      is_deleted: 'both',
      group_id: activeGroup.id,
    };
  }

  return (
    <SearchSelect
      params={params}
      entityName={User.entityName}
      valueKey="id"
      labelKey="name"
      searchKey={searchKey}
      onChange={(user): void => onChange(user)}
      placeholder="Search for users"
      {...rest}
    />
  );
};

export default withAuthenticatedUserEntity(UserSearchSelect);
