/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Commands/Menu
 * @desc A menu to list elements
 */

import React, { forwardRef } from 'react';
import Menu from 'labstep-web/core/Menu';
import Ref from 'labstep-web/core/Ref';
import { IMenuCommandsMenuProps } from './types';
import MenuCommandsMenuElement from './Element';

const MenuCommandsMenu: React.FC<IMenuCommandsMenuProps> = forwardRef(
  (
    {
      state,
      dispatch,
      elements,
      index,
      activeItemRef,
      experimentWorkflow,
    },
    ref,
  ) => (
    <Ref innerRef={ref}>
      <Menu vertical borderless fluid size="mini">
        {elements.map((element, i) => (
          <MenuCommandsMenuElement
            key={element.id}
            element={element}
            state={state}
            dispatch={dispatch}
            index={index}
            activeItemRef={activeItemRef}
            i={i}
            experimentWorkflow={experimentWorkflow}
          />
        ))}
        {elements.length === 0 && <Menu.Item>No results</Menu.Item>}
      </Menu>
    </Ref>
  ),
);

export default MenuCommandsMenu;
