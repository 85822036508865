/**
 * Labstep
 *
 * @module components/Molecule/Action/Edit
 * @desc Action to update comment post
 */

import React from 'react';
import Modal from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import { Mobile } from 'labstep-web/core/MediaQuery';
import MoleculeShow from 'labstep-web/components/Molecule/Show';
import MoleculeMobilePlaceholder from '../../Placeholder';
import {
  IMoleculeActionEditContainerProps,
  IMoleculeActionEditProps,
} from './types';

export const MoleculeActionEdit: React.FC<
  IMoleculeActionEditProps
> = ({ molecule, isMobile, canEdit, onDone, parent, ...rest }) => (
  <Modal
    size="fullscreen"
    header="Chemistry"
    formatContent={isMobile}
    content={({ toggleModal }) => {
      const onClose = onDone || toggleModal;
      return isMobile ? (
        <MoleculeMobilePlaceholder onClose={onClose} />
      ) : (
        <MoleculeShow
          id={molecule.getId}
          canEdit={canEdit}
          onDone={onClose}
          parent={parent}
        />
      );
    }}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        type="option"
        text="Edit"
        icon="pencil"
        onClick={toggleModal}
      />
    )}
    closeIcon={false}
    {...rest}
  />
);

export const MoleculeActionEditContainer: React.FC<
  IMoleculeActionEditContainerProps
> = (props) => (
  <Mobile>
    {({ match }) => (
      <MoleculeActionEdit isMobile={match} {...props} />
    )}
  </Mobile>
);

export default MoleculeActionEditContainer;
