/**
 * Labstep
 *
 * @module core/AutoSave/Spinner/ProseMirror
 * @desc Autosave spinner based on ProseMirror status
 */

import React from 'react';
import Loader from 'labstep-web/core/Loader';
import { withProseMirror } from 'labstep-web/containers';
import {
  ProseMirrorStatus,
  WithProseMirrorInjectedProps,
} from 'labstep-web/containers/ProseMirror/types';
import { getSaveMessage } from '../../utils';

export const AutoSaveSpinnerProseMirror: React.FC<
  WithProseMirrorInjectedProps
> = ({ proseMirrorStatus }) => {
  const props = {
    saving: proseMirrorStatus === ProseMirrorStatus.debouncing,
    error: proseMirrorStatus === ProseMirrorStatus.error,
    saved: proseMirrorStatus === ProseMirrorStatus.saved,
  };

  return props.saving ? (
    <Loader size="tiny" inline active />
  ) : (
    <span style={{ marginRight: '5px' }}>
      {getSaveMessage(props)}
    </span>
  );
};

export default withProseMirror(AutoSaveSpinnerProseMirror);
