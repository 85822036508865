/**
 * Labstep
 *
 * @module components/Signature/Card/EmptyState
 * @desc Empty state for signature
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { ExperimentWorkflow, Signature } from 'labstep-web/models';
import { ISignatureEmptyStateProps } from './types';

export const SignatureEmptyState: React.FC<
  ISignatureEmptyStateProps
> = ({ action }) => (
  <EmptyState
    src="/img/empty-state/signatures.svg"
    explanation={`This ${ExperimentWorkflow.getHumanReadableEntityName()} has not yet been signed. Enforce processes and reduce risk by adding / requesting ${Signature.getHumanReadableEntityName(
      true,
    )} and witness statements to your ${ExperimentWorkflow.getHumanReadableEntityName()}.`}
    action={action}
  />
);

export default SignatureEmptyState;
