/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/UpdateStatus
 * @desc Action to update status for experimentWorkflow
 */

import React from 'react';
import { generateNewDateString } from 'labstep-web/services/date.service';
import EntityActionUpdateDropdownPill from 'labstep-web/components/Entity/Action/Update/DropdownPill';
import ExperimentCompletionChecklistModal from 'labstep-web/components/Experiment/CompletionChecklist/Modal';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { ICONS } from 'labstep-web/constants';
import {
  ExperimentWorkflow,
  ExperimentWorkflowStatusKeys,
  ExperimentWorkflowStatusValues,
} from 'labstep-web/models';
import { withActiveGroup } from 'labstep-web/containers';
import { IExperimentWorkflowActionUpdateStatusProps } from './types';
import styles from './styles.module.scss';

export const updateStatus = (
  experimentWorkflow: ExperimentWorkflow,
  key: keyof typeof ExperimentWorkflowStatusValues,
) => {
  let body = {};
  const newDateString = generateNewDateString();
  switch (key) {
    case ExperimentWorkflowStatusKeys.planning:
      body = {
        started_at: null,
        ended_at: null,
      };
      break;
    case ExperimentWorkflowStatusKeys.in_progress:
      body = {
        started_at: newDateString,
        ended_at: null,
      };
      break;
    case ExperimentWorkflowStatusKeys.completed:
      body = {
        ended_at: newDateString,
      };
      if (!experimentWorkflow.started_at) {
        body = { ...body, started_at: newDateString };
      }
      break;
    default:
      break;
  }
  return body;
};

export const ExperimentWorkflowActionUpdateStatus: React.FC<
  IExperimentWorkflowActionUpdateStatusProps
> = ({ experimentWorkflow, activeGroup }) => (
  <ExperimentCompletionChecklistModal
    experiment={experimentWorkflow.root_experiment}
    experimentWorkflow={experimentWorkflow}
    action={({ toggleModal }) => (
      <EntityActionUpdate
        entityName={experimentWorkflow.entityName}
        id={experimentWorkflow.id}
        actionComponentProps={{
          type: 'button',
          text: 'Mark as completed',
          disabled:
            experimentWorkflow.root_experiment
              .metadataRequiredWithValueMissingCount > 0,
        }}
        body={updateStatus(experimentWorkflow, 'completed')}
        options={{ onSuccess: toggleModal }}
      />
    )}
    viewComponent={({ toggleModal }) => (
      <EntityActionUpdateDropdownPill
        entity={experimentWorkflow}
        triggerIcon={
          ICONS.experiment_workflow.info[experimentWorkflow.status]
        }
        triggerClassName={styles[experimentWorkflow.status]}
        currentValue={
          ExperimentWorkflowStatusValues[experimentWorkflow.status]
        }
        items={Object.keys(ExperimentWorkflowStatusValues).map(
          (key) => ({
            label:
              ExperimentWorkflowStatusValues[
                key as keyof typeof ExperimentWorkflowStatusValues
              ],
            body: updateStatus(
              experimentWorkflow,
              key as keyof typeof ExperimentWorkflowStatusValues,
            ),
            onClick:
              key === 'completed' &&
              activeGroup?.is_completion_checklist_enabled &&
              experimentWorkflow.status !== 'completed'
                ? toggleModal
                : undefined,
          }),
        )}
      />
    )}
  />
);

export default withActiveGroup(ExperimentWorkflowActionUpdateStatus);
