/**
 * Labstep
 *
 * @module components/Device/Action/Menu
 * @desc Action Menu for group
 */

import React from 'react';
import ActionsMenu from 'labstep-web/core/Action/Menu';
import EntityActionTag from 'labstep-web/components/Entity/Action/Tag';
import DeviceActionDelete from 'labstep-web/components/Device/Action/Delete';
import DeviceActionShare from 'labstep-web/components/Device/Action/Share';
import DeviceActionDuplicate from 'labstep-web/components/Device/Action/Duplicate';
import EntityActionTransferOwnership from 'labstep-web/components/Entity/Action/TransferOwnership';
import EntityActionRevoke from 'labstep-web/components/Entity/Action/Revoke';
import EntityUserActionManage from 'labstep-web/components/EntityUser/Action/Manage';
import DeviceActionUpdateTemplate from '../UpdateTemplate';
import { Action, IDeviceActionMenuProps } from './types';

const DeviceActionMenu: React.FC<IDeviceActionMenuProps> = ({
  device,
  type,
  actions = [],
}) => {
  return (
    <ActionsMenu type={type}>
      {actions.includes(Action.tag) && (
        <EntityActionTag parentEntity={device} />
      )}
      {actions.includes(Action.share) && (
        <DeviceActionShare device={device} />
      )}
      {actions.includes(Action.duplicate) && (
        <DeviceActionDuplicate device={device} />
      )}
      {actions.includes(Action.revoke) && (
        <EntityActionRevoke entity={device} />
      )}
      {actions.includes(Action.assign) && (
        <EntityUserActionManage entity={device} />
      )}
      {actions.includes(Action.set_template) && (
        <DeviceActionUpdateTemplate device={device} />
      )}
      {actions.includes(Action.transfer_ownership) && (
        <EntityActionTransferOwnership entity={device} />
      )}
      {actions.includes(Action.delete) && (
        <DeviceActionDelete device={device} />
      )}
    </ActionsMenu>
  );
};

DeviceActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default DeviceActionMenu;
