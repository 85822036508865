/**
 * Labstep
 *
 * @module screens/PurchaseOrder/Show/Right
 * @desc PurchaseOrder Show Right
 */

import React from 'react';
import RightPane from 'labstep-web/components/Layout/RightPane';
import ThreadForm from 'labstep-web/components/Thread/Form';
import LogList from 'labstep-web/components/Log/List';
import { ICONS } from 'labstep-web/constants';
import { IScreensPurchaseOrderShowRightProps } from './types';

const ScreensPurchaseOrderShowRight: React.FC<
  IScreensPurchaseOrderShowRightProps
> = ({ purchaseOrder }) => (
  <RightPane
    route={{
      to: 'purchase_order_show',
      params: { id: purchaseOrder.id },
    }}
    subPages={[
      {
        name: 'Notes',
        icon: ICONS.thread.primary,
        count: purchaseOrder.thread.comment_count,
        route: {
          to: 'purchase_order_show_thread',
          params: { id: purchaseOrder.id },
        },
        component: (
          <ThreadForm
            createThreadId={purchaseOrder.thread.id}
            readThreadIds={purchaseOrder.thread.id}
            entityNameLabel="notes"
          />
        ),
      },
      {
        name: 'Activity',
        icon: 'history',
        route: {
          to: 'purchase_order_show_activity',
          params: { id: purchaseOrder.id },
        },
        component: <LogList entity={purchaseOrder} />,
      },
    ]}
  />
);

export default ScreensPurchaseOrderShowRight;
