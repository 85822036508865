/**
 * Labstep
 *
 * @module components/ProtocolCondition/CollapsibleCard
 * @desc Component to show a collapsible protocolCondition card
 */

import React, { useEffect, useState } from 'react';
import CollapsibleCard from 'labstep-web/core/Collapsible/Card';
import { ICONS } from 'labstep-web/constants';
import { ProtocolCondition } from 'labstep-web/models';
import ProtocolConditionActionViewVariablesTable from '../Action/ViewVariablesTable';
import ProtocolConditionDataGrid from '../DataGrid';
import { IProtocolConditionCollapsibleCardProps } from './types';

export const ProtocolConditionCollapsibleCard: React.FC<
  IProtocolConditionCollapsibleCardProps
> = ({ protocol, initialExpandedState, gridProps }) => {
  // Weird AG Grid bug where the grid crashes on 'Enter' from the slash commands
  // Need to mount and then update the content
  const [content, setContent] = useState<React.ReactElement | null>(
    null,
  );

  useEffect(() => {
    setContent(
      <ProtocolConditionDataGrid
        // required for infinite scroll
        isCursor
        height="400px"
        protocol={protocol}
        {...gridProps}
      />,
    );
  }, [protocol.protocol_condition_variables]);

  return (
    <CollapsibleCard>
      <CollapsibleCard.Item
        icon={ICONS.protocol_condition.primary}
        name={`${ProtocolCondition.getHumanReadableEntityName(
          true,
          true,
        )} Table`}
        actions={
          <ProtocolConditionActionViewVariablesTable
            actionComponentProps={{
              type: 'icon',
              icon: 'external',
              elementProps: {
                asLink: true,
              },
            }}
            protocol={protocol}
          />
        }
        content={content}
        initialExpandedState={initialExpandedState}
      />
    </CollapsibleCard>
  );
};

export default ProtocolConditionCollapsibleCard;
