/**
 * Labstep
 *
 * @module components/Table/Form/Edit
 * @desc Edit form for protocol table
 */

import React from 'react';
import Button from 'labstep-web/core/Button';
import Loadable from 'labstep-web/core/Loadable';
import LayoutContainerActionButtons from 'labstep-web/components/Layout/Container/ActionButtons';
import TableEditContainer from 'labstep-web/containers/Table/Edit';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import {
  ITableFormEditContainerProps,
  ITableFormEditProps,
} from './types';
import styles from './styles.module.scss';

export const Spreadsheet = React.lazy(
  () => import('labstep-web/core/SpreadJS/spreadsheet'),
);

export const TableFormEditComponent: React.FC<
  ITableFormEditProps
> = ({
  table,
  readOnly = false,
  allowLocking,
  debouncingStatus,
  onChange,
  onError,
  onDone,
}) => {
  const canEdit = useHasAccess(
    table.entityName,
    table.idAttr,
    Action.edit,
  );

  return (
    <div className={styles.container}>
      <div className={styles.tableContainer}>
        <Loadable>
          <Spreadsheet
            showToolbar={!(readOnly || !canEdit)}
            data={table.data}
            readOnly={readOnly || !canEdit}
            onChange={onChange}
            onError={onError}
            allowLocking={allowLocking}
          />
        </Loadable>
      </div>
      <div className={styles.footer}>
        <LayoutContainerActionButtons>
          <Button onClick={onDone} status={debouncingStatus} primary>
            Done
          </Button>
        </LayoutContainerActionButtons>
      </div>
    </div>
  );
};

export const TableFormEditContainer: React.FC<
  ITableFormEditContainerProps
> = ({ table, options, ...rest }) => (
  <TableEditContainer table={table} options={options}>
    {(containerProps) => (
      <TableFormEditComponent {...containerProps} {...rest} />
    )}
  </TableEditContainer>
);

export default TableFormEditContainer;
