/**
 * Labstep
 *
 * @module screens/Experiment/Show
 * @desc Experiment show
 */

import React from 'react';
import MasterShow from 'labstep-web/screens/Master/Show';
import Redirect from 'labstep-web/core/Redirect';
import { EntityReadEntityContainer } from 'labstep-web/containers';
import ProtocolAlertTimer from 'labstep-web/components/Protocol/Alert/Timer';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { Experiment } from 'labstep-web/models';
import ProtocolConditionModal from 'labstep-web/components/ProtocolCondition/Modal/Container';
import Center from './Center';
import Right from './Right';
import ExperimentTopBar from './TopBar';
import {
  IExperimentShowScreenContainerProps,
  IExperimentShowTopBarProps,
  IExperimentShowScreenProps,
} from './types';

export const ExperimentShowScreenTopBar: React.FC<
  IExperimentShowTopBarProps
> = ({ id, activeExperimentWorkflowRoute }) => (
  <EntityReadEntityContainer
    entityName={Experiment.entityName}
    id={id}
  >
    {({ entity: experiment }): JSX.Element | null =>
      experiment ? (
        <ExperimentTopBar
          experiment={experiment}
          to={activeExperimentWorkflowRoute?.url}
        />
      ) : null
    }
  </EntityReadEntityContainer>
);

export const ExperimentShowScreen: React.FC<
  IExperimentShowScreenProps
> = ({ experiment, activeExperimentWorkflowRoute }) => (
  <>
    <ProtocolAlertTimer protocol={experiment} />
    <ProtocolConditionModal protocol={experiment} />
    <MasterShow
      entity={experiment}
      center={<Center experiment={experiment} />}
      right={
        <Right
          experiment={experiment}
          activeExperimentWorkflowRoute={
            activeExperimentWorkflowRoute
          }
        />
      }
    />
  </>
);

export const ExperimentShowScreenContainer: React.FC<
  IExperimentShowScreenContainerProps
> = ({ id, activeExperimentWorkflowRoute }) => (
  <>
    <ExperimentShowScreenTopBar
      id={id}
      activeExperimentWorkflowRoute={activeExperimentWorkflowRoute}
    />
    <ReadOnMountHOC
      type="entity"
      id={id}
      entityName={Experiment.entityName}
      loading={{ loader: 'show' }}
      children={({
        entity: experiment,
      }: {
        entity: Experiment;
      }): React.ReactElement =>
        experiment.is_root ? (
          <Redirect
            to="experiment_workflow_show"
            params={{ id: experiment.experiment_workflow.id }}
            state={{ is_root: true }}
          />
        ) : (
          <ExperimentShowScreen
            experiment={experiment}
            activeExperimentWorkflowRoute={
              activeExperimentWorkflowRoute
            }
          />
        )
      }
    />
  </>
);

export default ExperimentShowScreenContainer;
