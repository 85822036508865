/**
 * Labstep
 *
 * @module containers/User/Login
 * @desc Login container
 */

import { connect } from 'react-redux';
import {
  getSAMLLoginUrlAction,
  requestGetSAMLLoginUrlActionType,
} from 'labstep-web/state/actions';
import { selectApiStatus } from 'labstep-web/state/selectors';

const ChildrenContainer = ({ children, ...rest }: any) =>
  children(rest);

const mapStateToProps = (state: any) => ({
  status: selectApiStatus(state, requestGetSAMLLoginUrlActionType),
});

const mapDispatchToProps = (dispatch: (...args: any[]) => void) => ({
  getSAMLLoginUrl: (organization: string, options?: any) => {
    dispatch(getSAMLLoginUrlAction(organization, options));
  },
});

export const UserGetSAMLLoginUrlContainer = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ChildrenContainer);
