/**
 * Labstep
 *
 * @module components/ProtocolValue/List/Lineage/Parents
 * @desc Lineage Parents List
 */

import React from 'react';
import List from 'labstep-web/core/List';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import ProtocolValueItemLineage from 'labstep-web/components/ProtocolValue/Item/Lineage';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { ProtocolValue } from 'labstep-web/models';
import {
  ProtocolValueListLineageParentsContainerProps,
  ProtocolValueListLineageParentsProps,
} from './types';

export const ProtocolValueListLineageParents: React.FC<
  ProtocolValueListLineageParentsProps
> = ({ protocolValues }) => (
  <List id="protocol-value-list">
    {protocolValues.map((protocolValue: ProtocolValue) => (
      <ProtocolValueItemLineage
        key={protocolValue.guid}
        protocolValue={protocolValue}
      />
    ))}
  </List>
);

export const ProtocolValueListLineageParentsContainer: React.FC<
  ProtocolValueListLineageParentsContainerProps
> = ({ resourceItem }) => {
  const protocolValueOrigin = resourceItem.protocol_value_origin;
  if (
    !protocolValueOrigin?.parent ||
    !protocolValueOrigin?.is_lineage
  ) {
    return null;
  }
  const params: Record<string, unknown> = {
    [resourceItem.protocol_value_origin.experiment
      ? 'experiment_id'
      : 'protocol_id']: resourceItem.protocol_value_origin.experiment
      ? resourceItem.protocol_value_origin.experiment.id
      : resourceItem.protocol_value_origin.protocol.id,
    is_input: 1,
    is_lineage: 1,
    has_resource_item: true,
    search: 1,
  };
  if (protocolValueOrigin.protocol_condition_guid) {
    params.protocol_condition_guid_or_null =
      protocolValueOrigin.protocol_condition_guid;
  }
  return (
    <>
      <TextWithHelp
        text="Parents"
        helperText="These items were Input Inventory in the protocol run where this item was created"
        header
      />

      <ReadOnMountHOC
        type="cursor"
        entityName={ProtocolValue.entityName}
        params={params}
        children={({ entities: protocolValues }) => (
          <ProtocolValueListLineageParents
            protocolValues={protocolValues}
          />
        )}
      />
    </>
  );
};

export default ProtocolValueListLineageParentsContainer;
