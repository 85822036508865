/**
 * Labstep
 *
 * @module components/User/Form/ShowEdit/Name
 * @desc User show edit form
 */

import React from 'react';
import ShowEditTextMultiple from 'labstep-web/core/Form/ShowEdit/Text/Multiple';
import Header from 'labstep-web/core/Header';
import {
  fieldUserFirstName,
  fieldUserLastName,
} from 'labstep-web/components/User/Form/fields';
import { IUserFormShowEditNameProps } from './types';

export const UserFormShowEditName: React.FC<
  IUserFormShowEditNameProps
> = ({ user }) => (
  <ShowEditTextMultiple
    id={user.id}
    entity={user}
    entityName={user.entityName}
    fields={[fieldUserFirstName, fieldUserLastName]}
    customChildren={(children) => (
      <Header size="small" noMargin>
        {children}
      </Header>
    )}
  />
);

export default UserFormShowEditName;
