/**
 * Labstep
 *
 * @module components/Group/Action/SetCustomIdentifier
 * @desc Action to set Custom Identifier
 */

import React from 'react';
import CustomIdentifierSetPreview from 'labstep-web/components/CustomIdentifierSet/Preview';
import CustomIdentifierSetActionCreate from 'labstep-web/components/CustomIdentifierSet/Action/Create';
import { IGroupFormShowEditExperimentWorkflowCustomIdentifierSetProps } from './types';

export const GroupFormShowEditExperimentWorkflowCustomIdentifierSet: React.FC<
  IGroupFormShowEditExperimentWorkflowCustomIdentifierSetProps
> = ({ group }) => {
  if (group.experiment_workflow_custom_identifier_set) {
    return <CustomIdentifierSetPreview group={group} />;
  }
  if (group.canChangeCustomIdentifierSet) {
    return <CustomIdentifierSetActionCreate groupId={group.id} />;
  }
  return <span>No Custom Identifier</span>;
};

export default GroupFormShowEditExperimentWorkflowCustomIdentifierSet;
