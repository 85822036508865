/**
 * Labstep
 *
 * @module core/ProtocolValue/DataGrid
 * @desc ProtocolValue DataGrid
 */

import { ICONS } from 'labstep-web/constants';
import Flex from 'labstep-web/core/Flex';
import { ResourceItem } from 'labstep-web/models';
import React from 'react';
import ProtocolValueVariableActionSetItems from '../SetItems';
import ProtocolValueVariableActionSetItemsContainer from '../SetItems/Container';
import styles from './styles.module.scss';
import { IProtocolValueVariableDataGridProps } from './types';

export const ProtocolValueVariableActionContainer: React.FC<
  IProtocolValueVariableDataGridProps
> = ({ protocolValue }) => (
  <Flex className={styles.container} column grow shrink={false}>
    <ProtocolValueVariableActionSetItemsContainer>
      {({ disabled, ...containerProps }): React.ReactElement => (
        <ProtocolValueVariableActionSetItems
          {...containerProps}
          protocolValue={protocolValue}
          actionComponentProps={{
            type: 'button',
            text: `Bulk Select ${ResourceItem.getHumanReadableEntityName(
              true,
              true,
            )}`,
            showIcon: true,
            icon: ICONS.resource_item.primary,
            elementProps: {
              size: 'large',
              primary: true,
              basic: true,
              fluid: true,
              disabled,
            },
          }}
        />
      )}
    </ProtocolValueVariableActionSetItemsContainer>
  </Flex>
);

export default ProtocolValueVariableActionContainer;
