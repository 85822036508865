/**
 * Labstep
 *
 * @module core/DataGrid/CellEditor/SearchSelect
 * @desc CellEditor Search Select
 */

import FormShowEditSelectOptions from 'labstep-web/core/Form/ShowEdit/SelectOptions';
import { Entity } from 'labstep-web/models';
import React from 'react';
import { stopEditingAndFocus } from '../../../utils';
import styles from './styles.module.scss';
import { ICellEditorShowEditOptionsProps } from './types';

export const DataGridCellEditorShowEditOptions: React.FC<
  ICellEditorShowEditOptionsProps<Entity>
> = React.memo((params) => (
  <div
    className={styles.container}
    style={{
      width: params.column.getActualWidth(),
      height: params.node.rowHeight,
    }}
  >
    <FormShowEditSelectOptions
      canEdit
      children={null}
      initialEditingState
      onToggle={(): void => stopEditingAndFocus(params)}
      options={{
        onSuccess: () =>
          params.api.refreshCells({
            rowNodes: [params.node],
            columns: [params.column],
            force: true,
          }),
      }}
      {...params.props}
    />
  </div>
));

export default DataGridCellEditorShowEditOptions;
