import { ICONS } from 'labstep-web/constants';
import { STRINGS } from 'labstep-web/strings';
import { ISubLinksProps } from './types';

export const subLinks: ISubLinksProps = {
  overview: {
    route: {
      to: 'group_show',
    },
    icon: ICONS.group.info.home,
    text: STRINGS.navigation.overview.singular.capitalized,
  },
  experiments: {
    route: {
      to: 'group_experiment_workflows',
    },
    icon: ICONS.experiment_workflow.primary,
    text: STRINGS.experiment_workflow.plural.capitalized,
  },
  protocols: {
    route: {
      to: 'group_protocol_collections',
    },
    icon: ICONS.protocol_collection.primary,
    text: STRINGS.navigation.protocol_library.singular.capitalized,
  },
  inventory: {
    route: {
      to: 'group_resources',
    },
    routes: ['group_resources', 'group_resource_items'],
    icon: ICONS.resource.primary,
    text: STRINGS.navigation.inventory.singular.capitalized,
  },
  ordering: {
    route: {
      to: 'group_order_requests',
    },
    routes: ['group_order_requests', 'group_purchase_orders'],
    icon: ICONS.order_request.primary,
    text: STRINGS.navigation.ordering.singular.capitalized,
  },
  devices: {
    route: {
      to: 'group_devices',
    },
    icon: ICONS.device.primary,
    text: STRINGS.navigation.device.singular.capitalized,
  },
  settings: {
    route: {
      to: 'group_settings',
    },
    routes: [
      'group_settings',
      'group_users',
      'group_settings_notifications',
      'group_settings_order_info',
      'group_settings_auto_sharing',
      'group_settings_experiment_workflow_template',
      'group_settings_export',
      'group_settings_signatures',
    ],
    icon: 'cog',
    text: STRINGS.navigation.group_setting.plural.capitalized,
  },
  files: {
    route: {
      to: 'group_files',
    },
    icon: ICONS.file.primary,
    text: 'Uploaded Files',
    hide: true,
  },
  tags: {
    route: {
      to: 'group_tags',
    },
    icon: ICONS.tag.primary,
    text: 'Tag Manager',
    hide: true,
  },
  experiment_templates: {
    route: {
      to: 'group_experiment_workflow_templates',
    },
    icon: ICONS.experiment_workflow.template,
    text: 'Experiment Templates',
    hide: true,
  },
  locations: {
    route: {
      to: 'group_resource_locations',
    },
    icon: ICONS.resource_location.primary,
    text: STRINGS.navigation.resource_location.plural.capitalized,
    hide: true,
  },
  categories: {
    route: {
      to: 'group_resource_categories',
    },
    icon: ICONS.resource_template.primary,
    text: STRINGS.resource_template.plural.capitalized,
    hide: true,
  },
};

export const primaryLinks = Object.values(subLinks).filter(
  (link) => !link.hide,
);

export const extraLinks = Object.values(subLinks).filter(
  (link) => link.hide,
);
