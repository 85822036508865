/**
 * Labstep
 *
 * @module containers/File/Download
 * @desc Download a file
 */

import { connect } from 'react-redux';
import { downloadFile } from 'labstep-web/state/actions';
import { selectDownloadFileStatus } from 'labstep-web/state/selectors';
import { IFileDownloadContainerProps, OwnProps } from './types';

export type FileDownloadContainerChildrenProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

const DownloadContainer = ({
  children,
  ...rest
}: FileDownloadContainerChildrenProps & {
  children: (
    childrenProps: Omit<
      FileDownloadContainerChildrenProps,
      'children'
    >,
  ) => JSX.Element | null;
}) => children(rest);

const mapStateToProps = (state: any, ownProps: OwnProps) => ({
  status: selectDownloadFileStatus(state, ownProps.file.id),
});

const mapDispatchToProps = {
  downloadFile,
};

export const FileDownloadContainer: React.FC<IFileDownloadContainerProps> =
  connect(mapStateToProps, mapDispatchToProps)(DownloadContainer);
