/**
 * Labstep
 *
 *
 * @desc Signin Screen
 */

import React from 'react';
import Columns from 'labstep-web/screens/Authentication/Columns';
import Image from 'labstep-web/core/Image';
import DocumentHead from 'labstep-web/core/DocumentHead';
import {
  withAuthenticatedUser,
  withUiPersistent,
} from 'labstep-web/containers';
import Right from './Right';
import ShareLinkHeader from './ShareLinkHeader';
import styles from './styles.module.scss';
import { ISigninScreenContainerProps } from './types';

export const SigninScreenContainer: React.FC<
  ISigninScreenContainerProps
> = ({ right, authenticatedUser, uiPersistent }) => {
  const activeShareLink = uiPersistent.active_share_link;
  const isMfaScreen =
    authenticatedUser.mfa_required &&
    !authenticatedUser.authenticated;

  let header = 'Welcome back';
  let explainer = 'Sign into your account';
  if (isMfaScreen) {
    header = 'Two-factor Authentication';
    explainer = '';
  } else if (activeShareLink) {
    header = '';
    explainer = '';
  }

  return (
    <>
      <DocumentHead title="Signin" />
      <Columns
        header={header}
        explainer={explainer}
        left={
          <div className={styles.imageContainer}>
            <Image src="/img/landing/robot_main.svg" fluid />
          </div>
        }
        right={
          <div>
            {!isMfaScreen && activeShareLink && (
              <ShareLinkHeader
                sign="in"
                shareLink={activeShareLink}
              />
            )}
            {right || <Right isMfaScreen={isMfaScreen} />}
          </div>
        }
      />
    </>
  );
};

export default withUiPersistent(
  withAuthenticatedUser(SigninScreenContainer),
);
