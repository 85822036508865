import colDefBulkSelect from 'labstep-web/components/Entity/DataGrid/colDefs/bulkSelect';
import colDefEntityUser from 'labstep-web/components/Entity/DataGrid/colDefs/entityUser';
import colDefTemplate from 'labstep-web/components/Entity/DataGrid/colDefs/template';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import { Device } from 'labstep-web/models';
import { IParamsHOCChildrenProps } from 'labstep-web/hoc/Params/types';
import {
  colDefDeviceActionMenu,
  colDefDeviceLink,
  getDeviceMetadataColDefs,
} from './utils';

export default (
  setParams: IParamsHOCChildrenProps['setParams'],
  deviceTemplate?: Device,
): ColDef<Device>[] => {
  const colDefs = deviceTemplate
    ? [
        colDefDeviceActionMenu(),
        colDefBulkSelect,
        colDefDeviceLink,
        ...getDeviceMetadataColDefs(deviceTemplate),
        colDefEntityUser,
      ]
    : [
        colDefDeviceActionMenu(),
        colDefBulkSelect,
        colDefDeviceLink,
        colDefTemplate(Device.entityName, setParams),
        colDefEntityUser,
      ];
  return colDefs;
};
