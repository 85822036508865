/**
 * Labstep
 *
 * @module state/reducers/authenticatedUser
 * @desc Reducers for logged User
 */

import { combineReducers } from 'redux';
import { getType } from 'labstep-web/state/constants/helpers';
import { SET_AUTHENTICATED_USER_DISABLED } from 'labstep-web/state/constants/user';
import { Action } from 'labstep-web/models';
import { failStatus, receiveStatus, requestStatus } from './helpers';

/**
 * Initial state of authenticated_user
 */
const authenticatedUserInitialState = {
  authProvider: '',
  authenticated: false,
  boarded: true,
  mfa_required: false,
};

/**
 * Update state when user register, login, update its profile, confirm his email
 *
 * FIXME Refactor later with apiKey and token only
 *
 * @function
 * @param  {object} state
 * @param  {string} action
 * @return {string} action
 */
export const byId = (
  state: any = authenticatedUserInitialState,
  action: Action,
) => {
  switch (action.type) {
    case 'SUCCESS_CONFIRM_MFA': {
      return {
        ...state,
        is_two_factor_enabled: true,
      };
    }
    case 'SUCCESS_DISABLE_MFA': {
      return {
        ...state,
        is_two_factor_enabled: false,
      };
    }
    case 'REQUEST_BOOT_CLIENT': {
      return {
        ...state,
        mfa_required: false,
      };
    }
    case 'MFA_CODE_REQUIRED': {
      return {
        ...state,
        mfa_required: true,
      };
    }
    case 'SUCCESS_INTERNAL_REGISTER': {
      return {
        ...state,
        boarded: false,
        mfa_required: false,
      };
    }
    case 'SUCCESS_INTERNAL_LOGIN':
      if (!action.meta) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
        authenticated: true,
        authProvider: action.meta.authProvider,
        mfa_required: false,
      };
    case getType('success', 'read', 'user'): {
      const { entities, result } = action.payload;
      if (result !== state.id) {
        return state;
      }
      if (
        entities.user[result].token &&
        entities.user[result].refresh_token
      ) {
        return {
          ...state,
          enabled: entities.user[result].enabled,
          token: entities.user[result].token,
          refresh_token: entities.user[result].refresh_token,
        };
      }
      return {
        ...state,
        enabled: entities.user[result].enabled,
      };
    }
    case getType('success', 'update', 'user'): {
      const { entities, result } = action.payload;
      if (result !== state.id) {
        return state;
      }
      const { first_name, last_name, name } = entities.user[result];
      return {
        ...state,
        first_name,
        last_name,
        name,
      };
    }
    case 'LOGOUT_SUCCESS':
      return authenticatedUserInitialState;
    case 'SUCCESS_CONFIRM_TOKEN': {
      return {
        ...state,
        enabled: true,
      };
    }
    case SET_AUTHENTICATED_USER_DISABLED:
      return {
        ...state,
        enabled: false,
      };
    case 'SUCCESS_REFRESH_TOKEN':
      return {
        ...state,
        token: action.payload.token,
        refresh_token: action.payload.refresh_token,
      };
    default: {
      return state;
    }
  }
};

/**
 * Update status when user login/register/logout
 *
 * @function
 * @param  {object} state
 * @param  {string} action
 * @return {string} action
 */
export const logging = (state: any = {}, action: Action) => {
  switch (action.type) {
    case 'REQUEST_LOGOUT':
    case 'REQUEST_INTERNAL_LOGIN':
    case 'REQUEST_INTERNAL_LOGIN_CHECK':
    case 'REQUEST_INTERNAL_REGISTER':
      return requestStatus(state, action, 'authenticated_user', {
        authProvider: action.meta && action.meta.authProvider,
      });
    case 'SUCCESS_INTERNAL_LOGIN':
    case 'SUCCESS_INTERNAL_LOGIN_CHECK':
    case 'SUCCESS_INTERNAL_REGISTER':
      return receiveStatus(state, action, 'authenticated_user', {
        authProvider: null,
      });
    case 'FAIL_LOGOUT':
    case 'FAIL_INTERNAL_LOGIN':
    case 'FAIL_INTERNAL_LOGIN_CHECK':
    case 'FAIL_INTERNAL_REGISTER':
      return failStatus(state, action, 'authenticated_user');
    case 'FAIL_READ_USER':
      return action.meta.url.endsWith('user/info')
        ? failStatus(state, action, 'authenticated_user')
        : state;
    case 'MFA_CODE_REQUIRED':
    case 'CLEAR_LOGIN_ERROR':
      return {};
    default:
      return state;
  }
};

/**
 * Store the uuid of a Token type SAML_LOGIN.
 * Used for SAML logout.
 *
 * @function
 * @param  {object} state
 * @param  {string} action
 * @return {string} SAML Token uuid if any
 */
export const tokenTypeSamlLogin = (
  state: any = null,
  action: Action,
) => {
  if (action.type === 'REQUEST_SAML_LOGIN') {
    return action.meta.body.token;
  }

  return state;
};

const reducers: any = combineReducers({
  byId,
  logging,
  token_saml_login: tokenTypeSamlLogin,
});

export default reducers;
