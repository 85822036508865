/**
 * Labstep
 * @module components/Group/Card/Preview
 * @desc Card for showing a single group
 */

import React from 'react';
import GroupInitials from 'labstep-web/components/Group/Initials';
import UserGroupActionMenu from 'labstep-web/components/UserGroup/Action/Menu';
import { Action } from 'labstep-web/components/UserGroup/Action/Menu/types';
import LinkButton from 'labstep-web/core/Link/Button';
import Icon from 'labstep-web/core/Icon';
import Header from 'labstep-web/core/Header';
import { Size } from 'labstep-web/core/Initials/types';
import Flex from 'labstep-web/core/Flex';
import styles from './styles.module.scss';
import { IUserGroupCardPreviewProps } from './types';

const UserGroupCardPreview: React.FC<IUserGroupCardPreviewProps> = ({
  userGroup,
}) => (
  <div className={styles.container}>
    <LinkButton
      route={{ to: 'group_show', params: { id: userGroup.group.id } }}
    >
      <div className={styles.info}>
        <GroupInitials size={Size.tiny} group={userGroup.group} />
        <div className={styles.name}>
          <Header size={Size.tiny}> {userGroup.group.name}</Header>
        </div>
      </div>
    </LinkButton>
    <Flex>
      {userGroup.is_home && (
        <Icon name="home" popup={{ content: 'Home Workspace' }} />
      )}
      <UserGroupActionMenu
        userGroup={userGroup}
        actions={[
          Action.leave,
          Action.view,
          Action.set_home,
          Action.delete_group,
        ]}
      />
    </Flex>
  </div>
);

export default UserGroupCardPreview;
