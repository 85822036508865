/**
 * Labstep
 *
 * @module core/MediaQuery/Desktop
 * @desc MediaQuery for Desktop
 */

import React from 'react';
import MediaQuery from './MediaQuery';
import { IMediaQueryProps, Size } from './MediaQuery/types';

type IMediaQueryDesktopProps = Omit<IMediaQueryProps, 'size'>;

const MediaQueryDesktop: React.FC<IMediaQueryDesktopProps> = ({
  children,
}) => <MediaQuery size={Size.desktop}>{children}</MediaQuery>;

export default MediaQueryDesktop;
