/**
 * Labstep
 *
 * @module core/ProtocolValue/Variable/Detail
 * @desc ProtocolValue Variable Detail
 */

import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import { Protocol } from 'labstep-web/models';
import React from 'react';
import ProtocolValueVariableActionContainer from '../Action/Container';
import ProtocolValueVariableDataGrid from '../DataGrid';
import { IProtocolValueVariableDataGridProps } from './types';

export const ProtocolValueVariableDetail: React.FC<
  IProtocolValueVariableDataGridProps
> = ({ protocol, protocolValue }) => {
  const { isOpen } = useProtocolConditionModalContext();
  if (isOpen) {
    return null;
  }
  return protocol.n_protocol_conditions ||
    protocol instanceof Protocol ||
    protocolValue.is_output ? (
    <ProtocolValueVariableDataGrid
      protocol={protocol}
      variableTemplate={protocolValue}
    />
  ) : (
    <ProtocolValueVariableActionContainer
      protocolValue={protocolValue}
    />
  );
};

export default ProtocolValueVariableDetail;
