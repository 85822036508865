/**
 * Labstep
 *
 * @module screens/PurchaseOrder/Index/Breadcrumb
 * @desc PurchaseOrder Index Breadcrumb
 */

import { PurchaseOrder } from 'labstep-web/models';
import React from 'react';
import { ICONS } from 'labstep-web/constants';
import ScreensIndexBreadcrumb from 'labstep-web/screens/Breadcrumb';

export const PurchaseOrderIndexBreadcrumb: React.FC = () => (
  <ScreensIndexBreadcrumb
    text={`All ${PurchaseOrder.getHumanReadableEntityName(
      true,
      true,
    )}`}
    icon={ICONS[PurchaseOrder.entityName].primary}
  />
);

export default PurchaseOrderIndexBreadcrumb;
