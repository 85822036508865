/**
 * Labstep
 *
 * @module components/ResourceItem/Form/Location
 * @desc ShowEdit Form for location field
 */

import React from 'react';
import ModalDefault from 'labstep-web/core/Modal/Default';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import SearchSelect from 'labstep-web/core/Select/Search';
import { resourceLocationSearchSelectProps } from 'labstep-web/components/ResourceItem/Form/ShowEdit/ResourceLocation';
import { ResourceItem, ResourceLocation } from 'labstep-web/models';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import {
  EntityUpdateContainer,
  withActiveGroup,
} from 'labstep-web/containers';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import { IResourceItemActionUpdateResourceLocationBulkProps } from './types';

export const ResourceItemActionUpdateResourceLocationBulk: React.FC<
  IResourceItemActionUpdateResourceLocationBulkProps
> = ({ entities, options, activeGroup }) => {
  const entityIdsAccessObject = useHasAccessMultiple(
    ResourceItem.entityName,
    entities ? entities.map((e) => e.id) : [],
    Action.edit,
  );

  const entitiesWithPermissions = entities
    ? entities.filter((e) => entityIdsAccessObject[e.id])
    : [];

  if (
    !entities ||
    entities.length === 0 ||
    entitiesWithPermissions.length === 0
  ) {
    return null;
  }

  return (
    <ModalDefault
      header="Select location"
      viewComponent={({ toggleModal }) => (
        <BulkActionComponent
          content="Set location"
          onClick={toggleModal}
          icon="point"
          entitiesSelectedCount={entities.length}
          entitiesWithPermissionCount={entitiesWithPermissions.length}
        />
      )}
      content={({ toggleModal }) => (
        <EntityUpdateContainer
          entityName={ResourceItem.entityName}
          id={entitiesWithPermissions.map((e) => e.id)}
        >
          {({ update }) => (
            <SearchSelect
              ejectFromElasticsearch
              autoFocus
              entityName={ResourceLocation.entityName}
              value={null}
              onChange={(selectedOption) =>
                update(
                  { resource_location_id: selectedOption.id },
                  enhanceOptions({ options, onSuccess: toggleModal }),
                )
              }
              params={{
                group_id: activeGroup?.id,
              }}
              {...resourceLocationSearchSelectProps}
            />
          )}
        </EntityUpdateContainer>
      )}
    />
  );
};

export default withActiveGroup(
  ResourceItemActionUpdateResourceLocationBulk,
);
