/**
 * Labstep
 *
 * @module models/organization-plan
 * @desc Typescript export class for OrganizationInvoice
 */

import { currencies } from 'labstep-web/constants';
import { Entity } from 'labstep-web/models/entity.model';

export class OrganizationInvoice extends Entity {
  public static readonly idAttr = 'stripe_id' as const;

  static readonly entityName = 'organization_invoice';

  get entityName(): string {
    return OrganizationInvoice.entityName;
  }

  constructor(data: Partial<OrganizationInvoice>) {
    super();
    Object.assign(this, data);
  }

  id: number;

  stripe_id: string;

  number: number;

  created: string;

  amount: number;

  currency: string;

  paid: boolean;

  hosted_invoice_url: string;

  get currencyConstant() {
    if (currencies[this.currency.toUpperCase()]) {
      return currencies[this.currency.toUpperCase()];
    }

    return null;
  }

  get currencyDisplay() {
    return this.currencyConstant ? this.currencyConstant.symbol : '';
  }

  get amountDisplay() {
    return this.currencyConstant
      ? (
          this.amount /
          10 ** this.currencyConstant.decimal_digits
        ).toFixed(this.currencyConstant.decimal_digits)
      : '';
  }
}
