/**
 * Labstep
 *
 * @module components/Metadata/Action/Edit
 * @desc Action to edit metadata
 */

import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import MetadataFormEdit from 'labstep-web/components/Metadata/Form/Edit';
import EntityActionEditModal from 'labstep-web/components/Entity/Action/Edit/Modal';
import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import ProtocolChildRouter from 'labstep-web/containers/Router/ProtocolChild';
import ActionComponent from 'labstep-web/core/Action/Component';
import Message from 'labstep-web/core/Message';
import { Experiment, Protocol } from 'labstep-web/models';
import { IMetadataActionEditProps } from './types';

export const MetadataActionEdit: React.FC<
  IMetadataActionEditProps
> = ({
  entity,
  metadata,
  modalProps,
  actionComponentProps = {
    type: 'option',
    icon: 'cog',
    text: 'Configure Field',
  },
}) => {
  if (entity instanceof Protocol || entity instanceof Experiment) {
    return (
      <ProtocolChildRouter route="metadata_show" id={metadata.id}>
        {({ navigate }) => (
          <EntityActionEdit
            entity={metadata}
            renderIfNoEditPermissions
            actionComponentProps={{
              onClick: navigate,
              ...actionComponentProps,
            }}
          />
        )}
      </ProtocolChildRouter>
    );
  }

  return (
    <EntityActionEditModal
      entity={metadata}
      header={`Edit ${metadata.label}`}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          onClick={toggleModal}
          {...actionComponentProps}
        />
      )}
      content={({ toggleModal }) => (
        <div>
          {metadata.is_template && (
            <Message color="yellow">
              {`You are editing a template field. Any changes you make will apply to all ${getHumanReadableEntityName(
                entity.entityName,
                true,
              )} using this template.`}
            </Message>
          )}
          <MetadataFormEdit
            metadata={metadata}
            options={{ toast: true, onSuccess: toggleModal }}
          />
        </div>
      )}
      {...modalProps}
    />
  );
};

export default MetadataActionEdit;
