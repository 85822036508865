import { PremiumFeature } from 'labstep-web/models';

interface PremiumFeatureInfo {
  link?: string;
  name: string;
}

export const premiumFeaturesInfo: Record<
  PremiumFeature,
  PremiumFeatureInfo
> = {
  jupyter: {
    link: 'https://help.labstep.com/en/articles/6463715-getting-started-with-jupyter-notebooks',
    name: 'Jupyter Notebooks',
  },
  ip_whitelist: {
    name: 'IP Whitelist',
    link: 'https://help.labstep.com/en/articles/4699892-adding-ip-whitelisting-to-your-account',
  },
  '2fa': {
    name: 'Two-factor authentication',
    link: 'https://help.labstep.com/en/articles/4699786-adding-two-factor-authentication-to-your-account',
  },
  security_log: {
    name: 'Security Log',
  },
  custom_permissions: {
    name: 'Custom Permissions',
  },
  time_machine: {
    name: 'Time Machine',
  },
  timeline: {
    name: 'Timeline',
  },
  chemistry: {
    name: 'Chemistry',
  },
  auto_sharing: {
    name: 'Auto Sharing',
  },
  sso: {
    name: 'Single sign-on',
  },
  multiplexing: {
    name: '',
  },
  entity_import: {
    name: '',
  },
  marvinjs: {
    name: 'MarvinJS Chemistry Editor',
  },
  lock_settings: {
    name: 'Advanced Locking',
  },
};
