/**
 * Labstep
 *
 * @module screens/ProtocolCollection/Edit/Right/Steps
 * @desc Protocol Elements Steps
 */

import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ProtocolStepList from 'labstep-web/components/ProtocolStep/List';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import ProtocolStepActionStartEnd from 'labstep-web/components/ProtocolStep/Action/StartEnd';
import Icon from 'labstep-web/core/Icon';
import { Experiment, ProtocolCollection } from 'labstep-web/models';
import { ICONS } from 'labstep-web/constants';
import styles from './styles.module.scss';
import { IProtocolStepManagerProps } from './types';

const ProtocolStepManager: React.FC<IProtocolStepManagerProps> = ({
  protocol,
  protocolCollection,
}) => {
  const canEdit = useHasAccess(
    protocol.entityName,
    protocol.id,
    Action.edit,
  );
  return (
    <div>
      {protocol.protocolStepsOrdered.length === 0 && (
        <EmptyState
          src="/img/empty-state/protocols.svg"
          explanation={
            <div>
              Use the <Icon name={ICONS.protocol.step} /> button
              {` to add steps to your ${getHumanReadableEntityName(
                ProtocolCollection.entityName,
              )} so you track which actions have been completed${
                protocol instanceof Experiment
                  ? ''
                  : ` each time you follow the ${getHumanReadableEntityName(
                      ProtocolCollection.entityName,
                    )}`
              }.`}
            </div>
          }
        />
      )}
      {protocol instanceof Experiment &&
        protocol.protocolStepsOrdered.length > 0 &&
        canEdit &&
        !protocol.isTemplate && (
          <div className={styles.completeAction}>
            {!protocol.hasAllStepsCompleted && (
              <span className={styles.completeOption}>
                <ProtocolStepActionStartEnd
                  actionComponentProps={{
                    type: 'text',
                    text: 'Mark all as complete',
                    disabled: !canEdit,
                  }}
                  protocolSteps={protocol.protocolStepsOrdered.filter(
                    (step) => !step.ended_at,
                  )}
                />
              </span>
            )}
            {protocol.hasAtLeastOneStepCompleted && (
              <span className={styles.completeOption}>
                <ProtocolStepActionStartEnd
                  actionComponentProps={{
                    text: 'Mark all as incomplete',
                    type: 'text',
                    disabled: !canEdit,
                  }}
                  endedAt={null}
                  protocolSteps={protocol.protocolStepsOrdered}
                />
              </span>
            )}
          </div>
        )}
      <div className={styles.listContainer}>
        <ProtocolStepList
          protocol={protocol}
          protocolSteps={protocol.protocolStepsOrdered}
          protocolCollection={protocolCollection}
        />
      </div>
    </div>
  );
};

export default ProtocolStepManager;
