/**
 * Labstep
 */

import { IActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { Resource } from 'labstep-web/models';
import { IResourceTemplateDeleteActionProps } from '../Delete/types';

// eslint-disable-next-line no-shadow
export enum Action {
  view = 'view',
  share = 'share',
  duplicate = 'duplicate',
  delete = 'delete',
  export = 'export',
  revoke = 'revoke',
  transfer_ownership = 'transfer_ownership',
}

export interface IResourceTemplateActionMenuProps
  extends Pick<
    IActionMenuProps,
    Exclude<keyof IActionMenuProps, 'children' | 'options'>
  > {
  resourceTemplate: Resource;
  actions: Action[];
  options?: IResourceTemplateDeleteActionProps['options'];
}
