import { ICONS } from 'labstep-web/constants';
import { IIconProps } from 'labstep-web/core/Icon/types';
import {
  OrderRequestStatusValues,
  PurchaseOrderStatusValues,
  ResourceItemStatusValues,
} from 'labstep-web/models';

export const getNameAndIcon = (
  status: string,
): { name: string; icon: IIconProps['name'] } => ({
  name:
    ResourceItemStatusValues[status] ||
    OrderRequestStatusValues[status] ||
    PurchaseOrderStatusValues[status],

  icon:
    ICONS.resource_item.info[status] ||
    (OrderRequestStatusValues[status]
      ? ICONS.order_request.primary
      : ICONS.purchase_order.primary),
});
