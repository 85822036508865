/**
 * Labstep
 *
 * @module containers/User/Register/SocialMedia
 * @desc Register with social container
 */

import { connect } from 'react-redux';
import { googleLoginAction } from 'labstep-web/state/actions';

export type UserRegisterSocialMediaContainerChildrenProps =
  typeof mapDispatchToProps;

const Container = ({
  children,
  ...rest
}: UserRegisterSocialMediaContainerChildrenProps & {
  children: React.FC<
    Omit<UserRegisterSocialMediaContainerChildrenProps, 'children'>
  >;
}) => children(rest);

const mapDispatchToProps = {
  loginGoogle: googleLoginAction,
};

export const UserRegisterSocialMediaContainer = connect(
  () => ({}),
  mapDispatchToProps,
)(Container);
