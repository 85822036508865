/**
 * Labstep
 *
 * @module containers/JupyterRun
 * @desc Calls serverless endpoint to run a jupyter notebook
 */

import React from 'react';
import { connect } from 'react-redux';
import { jupyterRun } from 'labstep-web/state/actions';
import { selectJupyterRunStatus } from 'labstep-web/state/selectors';
import {
  WithJupyterRunInjectedProps,
  WithJupyterRunMapDispatchToProps,
  WithJupyterRunMapStateToProps,
} from './types';

const mapStateToProps: any = (
  state,
): WithJupyterRunMapStateToProps => ({
  status: selectJupyterRunStatus(state),
});

const mapDispatchToProps = (
  dispatch,
): WithJupyterRunMapDispatchToProps => ({
  run(guid: string) {
    dispatch(jupyterRun(guid));
  },
});

export const withJupyterRun = <P, S>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<P, Exclude<keyof P, keyof WithJupyterRunInjectedProps>>
> => connect(mapStateToProps, mapDispatchToProps)(Component as any);
