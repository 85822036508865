/**
 * Labstep
 *
 * @module screens/Master/Show
 * @desc Master Show Screen (deals with deleted)
 */

import React from 'react';
import Desktop from 'labstep-web/core/MediaQuery/Desktop';
import SplitView from 'labstep-web/core/SplitView';
import { withRouter } from 'react-router';
import { navigation, routing } from 'labstep-web/services/navigation';
import { IRouting } from 'labstep-web/services/navigation.types';
import { ResourceItem } from 'labstep-web/models';
import { IScreensMasterShowProps } from './types';
import CenterDeleted from './CenterDeleted';
import styles from './styles.module.scss';

const SHOW_PAGE_SPLIT_VIEW_POSITION = 'showPageSplitViewPosition';

export class ScreensMasterShow extends React.Component<IScreensMasterShowProps> {
  componentDidUpdate(prevProps: IScreensMasterShowProps) {
    const { history, entity } = this.props;

    if (!prevProps.entity.deleted_at && entity.deleted_at) {
      let route = {
        to: `${entity.entityName}_index` as keyof IRouting,
        params: {},
      };
      if (routing[route.to]) {
        if (entity instanceof ResourceItem) {
          route = {
            to: 'resource_show' as const,
            params: { id: entity.resource.id },
          };
        }
        history.push(navigation.get(route.to, route.params));
      }
    }
  }

  render() {
    const { entity, center, noBorder, messageForDeleted, right } =
      this.props;

    if (entity.deleted_at) {
      return (
        <CenterDeleted
          entity={entity}
          messageForDeleted={messageForDeleted}
        />
      );
    }

    if (noBorder) {
      return center;
    }

    const left = (
      <div className={styles.center} id="screens-master-show-center">
        {center}
      </div>
    );

    const storedInitialWidth = localStorage.getItem(
      SHOW_PAGE_SPLIT_VIEW_POSITION,
    );
    const initialWidth = storedInitialWidth
      ? parseInt(storedInitialWidth, 10)
      : 1000;

    const onChange = (x: number) =>
      localStorage.setItem(SHOW_PAGE_SPLIT_VIEW_POSITION, String(x));

    return (
      <Desktop>
        {({ match }) => (
          <div className={styles.container}>
            {match ? (
              <SplitView
                left={left}
                right={right}
                initialWidth={initialWidth}
                minLeftWidth={500}
                onChange={onChange}
              />
            ) : (
              <>
                {left}
                {right}
              </>
            )}
          </div>
        )}
      </Desktop>
    );
  }
}

export default withRouter(ScreensMasterShow);
