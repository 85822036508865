/**
 * Labstep
 *
 * @module components/ResourceItem/Card/EmptyState
 * @desc Empty state for resource item
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { ResourceItem } from 'labstep-web/models';
import { IResourceItemEmptyStateProps } from './types';

export const ResourceItemEmptyState: React.FC<
  IResourceItemEmptyStateProps
> = (props) => (
  <EmptyState
    title={`No ${ResourceItem.getHumanReadableEntityName(true)}`}
    explanation="Items are used to track the different batches, containers or aliquots of your resources. Track the location, availability and amount remaining of each item. Reference which item was used in each experiment."
    src="/img/empty-state/inventory.svg"
    {...props}
  />
);

export default ResourceItemEmptyState;
