/**
 * Labstep
 *
 * @module components/Group/Card/About
 * @desc About card for group
 */

import React from 'react';
import Card from 'labstep-web/core/Card/Card';
import GroupFormShowEditDescription from 'labstep-web/components/Group/Form/ShowEdit/Description';
import UserList from 'labstep-web/components/User/List/Cap';
import GroupActionShare from 'labstep-web/components/Group/Action/Share';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import Button from 'labstep-web/core/Button';
import Popup from 'labstep-web/core/Popup';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import { fieldName } from 'labstep-web/components/Group/Form/fields';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import Container from 'labstep-web/components/Layout/Container';
import { Size } from 'labstep-web/core/Initials/types';
import { IGroupCardAboutProps, IMembersProps } from './types';
import styles from './styles.module.scss';

export const Members: React.FC<IMembersProps> = ({ group }) => (
  <div>
    <Container>
      <span>Members</span>
    </Container>
    <Flex style={{ justifyContent: 'space-between' }}>
      <Flex>
        <UserList params={{ group_id: group.id }} />
        <Popup
          trigger={
            <Button
              route={{ to: 'group_users', params: { id: group.id } }}
              icon="cog"
              circular
            />
          }
          content="Manage Members"
          size="mini"
        />
      </Flex>
      <GroupActionShare
        group={group}
        actionComponentProps={{
          type: 'button',
        }}
      />
    </Flex>
  </div>
);

export const GroupCardAbout: React.FC<IGroupCardAboutProps> = ({
  group,
}) => (
  <Card className={styles.card}>
    <div className={styles.container}>
      <div>
        <ShowEditText
          entityName={group.entityName}
          entity={group}
          field={{ ...fieldName, header: true }}
        >
          <Header size="small" noMargin>
            {group.name}
          </Header>
        </ShowEditText>
        <GroupFormShowEditDescription group={group} />
      </div>
      <ProfilePicture
        bordered
        entity={group}
        size={Size.small}
        circular={false}
        rounded
        uploadable
      />
    </div>
    <Members group={group} />
  </Card>
);

export default GroupCardAbout;
