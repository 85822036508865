/**
 * Labstep
 */

import { IEntitySearchProps } from 'labstep-web/components/Entity/Search/types';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { UserGroup } from 'labstep-web/models';
import { ISearchContainerProps } from 'labstep-web/hoc/Search/types';
import { AVAILABLE_COUNTS } from 'labstep-web/core/Pagination/CountPerPage';
import { IStatus } from 'labstep-web/typings';

export type IFilter = (entity: any) => any;

export const getFilteredEntities = (
  entities: ISearchContainerProps['entities'],
  searchParams: ISearchContainerProps['searchParams'],
  filters: IEntitySearchProps['filters'],
  additionalFilters?: IFilter[],
): ISearchContainerProps['entities'] => {
  let filteredEntities = entities;
  if (filters && filters.indexOf(Filter.deleted) > -1) {
    if (searchParams.is_deleted === '1') {
      filteredEntities = filteredEntities.filter((entity) => {
        if (entity instanceof UserGroup) {
          return entity.group.deleted_at !== null;
        }
        return entity.deleted_at !== null;
      });
    } else {
      filteredEntities = filteredEntities.filter((entity) => {
        if (entity instanceof UserGroup) {
          return entity.group.deleted_at === null;
        }
        return entity.deleted_at === null;
      });
    }
  }

  if (filters && filters.indexOf(Filter.is_user_deleted) > -1) {
    if (searchParams.is_user_deleted === '1') {
      filteredEntities = filteredEntities.filter(
        (entity: any) => entity.user.deleted_at !== null,
      );
    } else {
      filteredEntities = filteredEntities.filter(
        (entity: any) => entity.user.deleted_at === null,
      );
    }
  }

  if (filters && filters.indexOf(Filter.is_group_deleted) > -1) {
    if (searchParams.is_group_deleted === '1') {
      filteredEntities = filteredEntities.filter(
        (entity: any) => entity.group.deleted_at !== null,
      );
    } else {
      filteredEntities = filteredEntities.filter(
        (entity: any) => entity.group.deleted_at === null,
      );
    }
  }

  if (additionalFilters) {
    additionalFilters.map((filter) => {
      filteredEntities = filteredEntities.filter(filter);
      return true;
    });
  }

  return filteredEntities;
};

/**
 *
 * Selector to get count per page from state
 */
export const getCountPerPage = (uiPersistentState: any): number => {
  const stateCountPerPage = uiPersistentState.count_per_page;
  // Applying a max count per page for performance. Any legacy counts larger
  // than this will fall back to the max count
  const maxCountPerPage =
    AVAILABLE_COUNTS[AVAILABLE_COUNTS.length - 1];
  if (stateCountPerPage > maxCountPerPage) {
    return maxCountPerPage;
  }
  return stateCountPerPage;
};

/**
 * Of the given statuses, returns the first one that is fetching
 * @param statuses Statuses
 * @returns
 */
export const getStatusIsFetching = (
  statuses: Record<number, IStatus> | undefined,
) => {
  if (!statuses) {
    return undefined;
  }
  return Object.values(statuses).find((s) => s.isFetching);
};
