/**
 * Labstep
 *
 * @module components/Entity/Action/Assign
 * @desc Action to tag entity. Opens modal showing tag manager.
 */

import React from 'react';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ModalDefault from 'labstep-web/core/Modal/Default';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import SearchSelect from 'labstep-web/core/Select/Search';
import {
  EntityToggleContainer,
  withActiveGroup,
} from 'labstep-web/containers';
import { STRINGS } from 'labstep-web/strings';
import { ICONS } from 'labstep-web/constants';
import { User } from 'labstep-web/models';
import { IEntityActionAssignBulkProps } from './types';

export const EntityActionAssignBulk: React.FC<
  IEntityActionAssignBulkProps
> = ({ parentEntities, options, activeGroup, parentEntityName }) => {
  const parentEntityIdsAccessObject = useHasAccess(
    parentEntityName,
    parentEntities.map((e) => e.id),
    Action.assign,
  );

  if (parentEntities.length === 0) {
    return null;
  }

  const parentEntitiesWithPermission = parentEntities.filter(
    (e) => parentEntityIdsAccessObject[e.id],
  );

  return (
    <ModalDefault
      header={STRINGS.entity.actions.assign}
      content={({ toggleModal }) => (
        <EntityToggleContainer
          action="add"
          childKeyName="assignee"
          entityName={User.entityName}
          parentName={parentEntityName}
          parentId={parentEntitiesWithPermission.map((e) => e.id)}
        >
          {({ toggle }) => (
            <SearchSelect
              labelKey="name"
              autoFocus
              params={{ group_id: activeGroup?.id }}
              entityName={User.entityName}
              value={null}
              onChange={(selectedOption) => {
                toggle(undefined, selectedOption.id, options);
                toggleModal();
              }}
            />
          )}
        </EntityToggleContainer>
      )}
      viewComponent={({ toggleModal }) => (
        <BulkActionComponent
          entitiesWithPermissionCount={
            parentEntitiesWithPermission.length
          }
          entitiesSelectedCount={parentEntities.length}
          onClick={toggleModal}
          content={STRINGS.entity.actions.assign}
          icon={ICONS.entity.actions.assign}
        />
      )}
    />
  );
};

export default withActiveGroup(EntityActionAssignBulk);
