/**
 * Labstep
 */

import React from 'react';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import ProtocolConditionActionSetIsVariable from 'labstep-web/components/ProtocolCondition/Action/SetIsVariable';
import MetadataFormShowEditValueWithClear from 'labstep-web/components/Metadata/Form/ShowEdit/Value/WithClear';
import { ICONS } from 'labstep-web/constants';
import { Experiment, Protocol } from 'labstep-web/models';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { RowsType } from '../../types';

export const getRows = (entity: Protocol | Experiment): RowsType => [
  {
    header: (
      <TextWithHelp text="Value" icon={ICONS.entity.info.id} header />
    ),
    content: (metadata): React.ReactElement => (
      <MetadataFormShowEditValueWithClear
        entity={entity}
        metadata={metadata}
        componentProps={{
          [MetadataType.numeric]: { type: 'both' },
        }}
      />
    ),
    action: (metadata): React.ReactElement =>
      entity instanceof Experiment && (
        <ProtocolConditionActionSetIsVariable
          entity={metadata}
          protocol={entity}
        />
      ),
    dataTestId: 'metadata-value',
  },
];
