/**
 * Labstep
 *
 * @module components/Group/Action/Share
 * @desc Share a group
 */

import EntityActionShare from 'labstep-web/components/Entity/Action/Share';
import { ICONS } from 'labstep-web/constants';
import { STRINGS } from 'labstep-web/strings';
import React from 'react';
import { IGroupActionShareProps } from './types';

export const GroupActionShare: React.FC<IGroupActionShareProps> = ({
  group,
  actionComponentProps,
}) =>
  group.canInvite ? (
    <EntityActionShare
      entity={group}
      actionComponentProps={{
        type: 'option',
        text: STRINGS.group.actions.share,
        icon: ICONS.group.actions.share,
        ...actionComponentProps,
      }}
    />
  ) : null;

export default GroupActionShare;
