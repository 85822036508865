/**
 * Labstep
 *
 * @module components/Entity/Action/Tag/Bulk
 * @desc Action to tag entity in bulk
 */

import React from 'react';
import EntityActionAddSearchBulk from 'labstep-web/components/Entity/Action/AddSearch/Bulk';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { STRINGS } from 'labstep-web/strings';
import { ICONS } from 'labstep-web/constants';
import { Tag } from 'labstep-web/models';
import { IEntityActionTagBulkProps } from './types';

export const EntityActionTagBulk: React.FC<
  IEntityActionTagBulkProps
> = ({ entityName, parentEntities, options }) => {
  const parentEntityIdsAccessObject = useHasAccessMultiple(
    entityName,
    parentEntities ? parentEntities.map((e) => e.id) : [],
    Action.tag_add_remove,
  );

  const parentEntitiesWithPermission = parentEntities
    ? parentEntities.filter((e) => parentEntityIdsAccessObject[e.id])
    : [];

  if (
    !parentEntities ||
    parentEntities.length === 0 ||
    parentEntitiesWithPermission.length === 0
  ) {
    return null;
  }

  return (
    <EntityActionAddSearchBulk
      entityName={Tag.entityName}
      parentEntities={parentEntities}
      parentEntitiesWithPermission={parentEntitiesWithPermission}
      actionText={STRINGS.entity.actions.tag}
      icon={ICONS.entity.actions.tag}
      options={options}
    />
  );
};

export default EntityActionTagBulk;
