/**
 * Labstep
 *
 * @module components/ProtocolCollection/Card/Overview
 * @desc Overview Card for protocolCollections
 */

import React from 'react';
import ExperimentWorkflowActionCreate from 'labstep-web/components/ExperimentWorkflow/Action/Create';
import EntityCardOverview from 'labstep-web/components/Entity/Card/Overview';
import { ProtocolCollectionEmptyState } from 'labstep-web/components/ProtocolCollection/Card/EmptyState';
import ProtocolCollectionActionMenu from 'labstep-web/components/ProtocolCollection/Action/Menu';
import ProtocolCollectionActionCreate from 'labstep-web/components/ProtocolCollection/Action/Create';
import { actions } from 'labstep-web/components/ProtocolCollection/ListOrTable/constants';
import { ProtocolCollection } from 'labstep-web/models';
import { ICONS } from 'labstep-web/constants';
import { IProtocolCollectionCardOverviewProps } from './types';
import styles from './styles.module.scss';

export const ProtocolCollectionCardOverview: React.FC<
  IProtocolCollectionCardOverviewProps
> = (props) => (
  <EntityCardOverview
    {...props}
    emptyState={<ProtocolCollectionEmptyState title={undefined} />}
    entityName={ProtocolCollection.entityName}
    icon={ICONS.protocol_collection.primary}
    actionMenu={({ entity }) => (
      <div className={styles.actions}>
        <ExperimentWorkflowActionCreate
          protocol={entity.last_version}
        />
        <ProtocolCollectionActionMenu
          protocolCollection={entity}
          actions={actions}
        />
      </div>
    )}
    action={<ProtocolCollectionActionCreate />}
  />
);

export default ProtocolCollectionCardOverview;
