/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Create/Wizard
 * @desc OrderRequest create wizard
 */

import React from 'react';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import { Resource } from 'labstep-web/models';
import SearchSelect from 'labstep-web/core/Select/Search';
import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import EntityActionUpdateSearchSelect from 'labstep-web/components/Entity/Action/Update/SearchSelect';
import { EntityReadEntityContainer } from 'labstep-web/containers';
import TextWithIcon from 'labstep-web/core/Text/WithIcon';
import { ICONS } from 'labstep-web/constants';
import Segment from 'labstep-web/core/Segment';
import styles from './styles.module.scss';
import { IOrderRequestActionCreateWizardStepsSelectResourceProps } from './types';

export const OrderRequestActionCreateWizardStepsSelectResource: React.FC<
  IOrderRequestActionCreateWizardStepsSelectResourceProps
> = ({ resourceId, setResourceId }) =>
  resourceId ? (
    <EntityReadEntityContainer
      entityName={Resource.entityName}
      id={resourceId}
    >
      {({ entity: resource }) => (
        <Segment
          className={styles.segment}
          onClose={() => setResourceId(null)}
        >
          <TextWithIcon
            className={styles.name}
            icon={ICONS.resource_template.primary}
            iconProps={{ size: 'large' }}
            text={
              <EntityActionUpdateSearchSelect
                entityName={Resource.entityName}
                value={resource.template}
                entity={resource}
                params={{ is_template: true }}
                getBody={(template) => ({
                  template_id: template?.guid || null,
                })}
                usePortal
                creatable
                createBody={{ is_template: true }}
                createLabel="Create new category"
              />
            }
          />
          <EntityPrimaryInfo
            entity={resource}
            withPermaLink={false}
            disabled
          />
          <MetadataManager entity={resource} />
        </Segment>
      )}
    </EntityReadEntityContainer>
  ) : (
    <SearchSelect
      autoFocus
      entityName={Resource.entityName}
      creatable
      onChange={(value) => setResourceId(value?.idAttr || null)}
      usePortal
    />
  );

export default OrderRequestActionCreateWizardStepsSelectResource;
