/**
 * Labstep
 *
 * @module containers/Ui
 * @desc Container for ui
 */

import { connect } from 'react-redux';
import React from 'react';
import {
  clearActiveEntity,
  setActiveEntity,
} from 'labstep-web/state/actions';
import { selectUi } from 'labstep-web/state/selectors';
import { LabstepReduxState } from 'labstep-web/state/types';
import {
  IWithUiInjectedProps,
  IWithUiMapDispatchToProps,
  IWithUiMapStateToProps,
} from './types';

const mapStateToProps = (
  state: LabstepReduxState,
): IWithUiMapStateToProps => ({
  ui: selectUi(state),
});

const mapDispatchToProps: IWithUiMapDispatchToProps = {
  setActiveEntity,
  clearActiveEntity,
};

export const withUi = <P, S>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<Pick<P, Exclude<keyof P, keyof IWithUiInjectedProps>>> =>
  connect(mapStateToProps, mapDispatchToProps)(Component as any);
