/**
 * Labstep
 *
 * @module components/Thread/CountIcon
 * @desc A component showing the thread count + comments icon
 */

import React from 'react';
import { Icon } from 'semantic-ui-react';
import Popup from 'labstep-web/core/Popup';
import { ICONS } from 'labstep-web/constants';
import styles from 'labstep-web/core/Action/Icon/styles.module.scss';
import { IThreadCountIconProps } from './types';

export const ThreadCountIcon: React.FC<IThreadCountIconProps> = ({
  count,
  popup = 'Notes',
  onClick,
}) => (
  <Popup
    trigger={
      <div
        className={styles.iconContainer}
        style={{ alignItems: 'center' }}
        onClick={onClick}
      >
        <Icon.Group>
          <Icon name={ICONS.thread.primary} />
          {count > 0 && (
            <Icon name="circle" corner="top right" color="red" />
          )}
        </Icon.Group>
      </div>
    }
    size="mini"
    content={popup}
  />
);

export default ThreadCountIcon;
