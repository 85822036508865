import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import { Filter } from 'labstep-web/components/Filter/Menu/types';

export const bulkActions = [
  BulkAction.delete,
  BulkAction.tag,
  BulkAction.assign,
  BulkAction.transfer_ownership,
  BulkAction.share,
  BulkAction.add_to_folder,
  BulkAction.remove_from_folder,
  BulkAction.signature_request_create,
];

export const filters = [
  Filter.created_by,
  Filter.assigned_to,
  Filter.date_range,
  Filter.deleted,
  Filter.tag,
  Filter.signed,
  Filter.experiment_workflow_status,
  Filter.protocol,
  Filter.resource,
  Filter.molecule,
];

export const filtersTemplate = [
  Filter.created_by,
  Filter.date_range,
  Filter.deleted,
  Filter.tag,
  Filter.protocol,
  Filter.resource,
];
