/**
 * Labstep
 *
 * @module components/Group/Settings
 * @desc Group settings
 */

import React from 'react';
import GroupActionSetIsInviteOnly from 'labstep-web/components/Group/Action/SetIsInviteOnly';
import GroupActionSetIsAutoMember from 'labstep-web/components/Group/Action/SetIsAutoMember';
import GroupActionSetDefaultUserGroupType from 'labstep-web/components/Group/Action/SetDefaultUserGroupType';
import GroupActionLeave from 'labstep-web/components/Group/Action/Leave';
import GroupActionDelete from 'labstep-web/components/Group/Action/Delete';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import GroupFormShowEditDescription from 'labstep-web/components/Group/Form/ShowEdit/Description';
import { useHasAccessGroup } from 'labstep-web/components/Entity/Can';
import GroupFormShowEditName from 'labstep-web/components/Group/Form/ShowEdit/Name';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import { Size } from 'labstep-web/core/Initials/types';
import { Group } from 'labstep-web/models';
import { IGroupSettingsProps } from './types';

export const GroupSettings: React.FC<IGroupSettingsProps> = ({
  group,
}) => {
  const isGroupOwner = useHasAccessGroup('owner', group.id);

  let rows = [
    {
      header: 'Name',
      content: () => <GroupFormShowEditName group={group} />,
    },
    {
      header: 'Description',
      content: () => <GroupFormShowEditDescription group={group} />,
    },
    {
      header: 'Cover Image',
      content: () => (
        <div style={{ width: 'min-content' }}>
          <ProfilePicture
            centered={false}
            bordered
            entity={group}
            size={Size.small}
            circular={false}
            rounded
            uploadable
          />
        </div>
      ),
    },
  ];
  if (group.organization && isGroupOwner) {
    rows = [
      ...rows,
      {
        header: 'Invite Only',
        content: () => (
          <div>
            <GroupActionSetIsInviteOnly group={group} />
            <p>
              Disable to allow anyone from {group.organization.name}{' '}
              to join the {Group.getHumanReadableEntityName()}{' '}
            </p>
          </div>
        ),
      },
      {
        header: 'Auto invite',
        content: () => (
          <div>
            <GroupActionSetIsAutoMember group={group} />
            <p>
              Enable for new members of {group.organization.name} to
              automatically join the{' '}
              {Group.getHumanReadableEntityName()}{' '}
            </p>
          </div>
        ),
      },
    ];
  }

  if (isGroupOwner) {
    rows = [
      ...rows,
      {
        header: 'Default Role',
        content: () => (
          <div>
            <GroupActionSetDefaultUserGroupType group={group} />
          </div>
        ),
      },
    ];
  }

  return (
    <div id="group-settings">
      <HorizontalTable
        padded
        headerProps={{ width: 3 }}
        rows={rows}
        columns={[group]}
      />
      {isGroupOwner ? (
        <GroupActionDelete
          actionComponentProps={{
            type: 'button',
            text: `Delete ${Group.getHumanReadableEntityName(
              false,
              true,
            )}`,
          }}
          group={group}
        />
      ) : (
        <GroupActionLeave
          actionComponentProps={{
            type: 'button',
            elementProps: {
              color: 'red',
            },
          }}
          group={group}
          userGroup={group.logged_user_user_group}
        />
      )}
    </div>
  );
};

export default GroupSettings;
