/**
 * Labstep
 *
 * @module components/Signature/Manager
 * @desc Signature manager
 */

import React from 'react';
import SignatureActionCreate from 'labstep-web/components/Signature/Action/Create';
import SignatureRequestActionCreate from 'labstep-web/components/SignatureRequest/Action/Create';
import SignatureList from 'labstep-web/components/Signature/List';
import SignatureEmptyState from 'labstep-web/components/Signature/EmptyState';
import Container from 'labstep-web/components/Layout/Container';
import SignatureRequestActionViewList from 'labstep-web/components/SignatureRequest/Action/ViewList';
import Flex from 'labstep-web/core/Flex';
import { ISignatureManagerProps } from './types';

export const SignatureManager: React.FC<ISignatureManagerProps> = ({
  experimentWorkflow,
}) => {
  const action = (
    <Flex wrap>
      <SignatureActionCreate
        experimentWorkflow={experimentWorkflow}
      />
      <SignatureRequestActionCreate
        experimentWorkflow={experimentWorkflow}
      />
      <SignatureRequestActionViewList
        experimentWorkflow={experimentWorkflow}
      />
    </Flex>
  );
  return experimentWorkflow.signatures.length === 0 ? (
    <SignatureEmptyState action={action} />
  ) : (
    <div>
      <Container>{action}</Container>
      <SignatureList experimentWorkflow={experimentWorkflow} />
    </div>
  );
};

export default SignatureManager;
