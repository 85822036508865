import colDefEntityLink from 'labstep-web/components/Entity/DataGrid/colDefs/entityLink';
import { Resource, ResourceItem } from 'labstep-web/models';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import React from 'react';
import { ICONS } from 'labstep-web/constants';
import { objectOrFunction } from 'labstep-web/services/react.service';

const colDefResourceItemResourceEntityLink =
  colDefEntityLink<ResourceItem>({
    getNestedEntity: (params) => params.data.resource,
    getEntityPreviewProps: (resource) => ({
      secondaryInfo: <MetadataManager entity={resource} />,
    }),
  });

export const colDefResourceItemResource = {
  ...colDefResourceItemResourceEntityLink,
  colId: 'resource_name',
  headerComponentParams: (params) => ({
    ...objectOrFunction(
      colDefResourceItemResourceEntityLink.headerComponentParams,
      params,
    ),
    icon: ICONS.resource.primary,
  }),
  headerName: Resource.getHumanReadableEntityName(false, true),
};
