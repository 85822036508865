/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * Labstep
 *
 * @desc Shows an  entity_show link but switches workspace if
 * the entity is not shared with current active workspace
 */

import React, { useState } from 'react';
import EntityLink from 'labstep-web/components/Entity/Link';
import { PurchaseOrder, ResourceItem } from 'labstep-web/models';
import { withActiveGroup } from 'labstep-web/containers';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { IProtectedEntityLinkProps } from './types';
import styles from './styles.module.scss';
import Content from './Content';

export const ProtectedEntityLink: React.FC<
  IProtectedEntityLinkProps
> = ({ entity, activeGroup }) => {
  const [groupIdToRead, setGroupIdToRead] = useState<number | null>(
    null,
  );

  let groupId: number;
  if (entity instanceof PurchaseOrder) {
    groupId = entity.group.id;
  } else if (entity instanceof ResourceItem) {
    groupId = entity.resource.owner.id;
  } else {
    groupId = entity.owner.id;
  }

  if (groupId !== activeGroup?.id) {
    return (
      <ModalDefault
        id="switch-workspace-modal"
        closeIcon={false}
        header={false}
        size="fullscreen"
        content={
          <Content
            groupIdToRead={groupIdToRead}
            setGroupIdToRead={setGroupIdToRead}
            entity={entity}
          />
        }
        viewComponent={({ toggleModal }) => (
          <>
            <a
              className={styles.name}
              onClick={() => {
                if (
                  entity instanceof PurchaseOrder ||
                  entity instanceof ResourceItem
                ) {
                  setGroupIdToRead(groupId);
                }
                toggleModal();
              }}
            >
              {entity.displayName}
            </a>
            {entity instanceof ResourceItem && (
              <>
                <span>-</span> <b>{entity.resource.name}</b>
              </>
            )}
          </>
        )}
      />
    );
  }
  return <EntityLink entity={entity} />;
};

export default withActiveGroup(ProtectedEntityLink);
