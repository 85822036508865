/**
 * Labstep
 *
 * @module components/PurchaseOrder/Action/UpdateIsLabstepFulfilled
 * @desc Action to update is_labstep_fulfilled field
 */

import React from 'react';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import Checkbox from 'labstep-web/core/Checkbox';
import ModalDefault from 'labstep-web/core/Modal/Default';
import Popup from 'labstep-web/core/Popup';
import Button from 'labstep-web/core/Button';
import { withActiveGroup } from 'labstep-web/containers';
import { IPurchaseOrderActionUpdateIsLabstepFulfilledProps } from './types';

export const PurchaseOrderActionUpdateIsLabstepFulfilled: React.FC<
  IPurchaseOrderActionUpdateIsLabstepFulfilledProps
> = ({ purchaseOrder, activeGroup }) => {
  const { organization } = activeGroup;
  if (!organization) {
    return null;
  }
  if (!organization.is_labstep_managed_outbound) {
    return (
      <ModalDefault
        header="Labstep Order Service"
        viewComponent={
          <Checkbox
            toggle
            label="Enable Labstep Order Service"
            checked={false}
          />
        }
        content={({ toggleModal }) => (
          <div>
            <p>
              The Labstep Order Service takes away the manual and
              time-consuming aspect of your procurement process, while
              keeping a record of all your purchases in one place.
            </p>
            <p>
              Once you finalise an Order, your Labstep officer will
              place the order with the suppliers for you, maintaining
              any negotiated discounts and updating the status on the
              Order Requests as they progress.
            </p>
            <p>
              If you are interested in this service please contact{' '}
              <a href="mailto:ordering@labstep.com">
                ordering@labstep.com
              </a>
            </p>
            <div>
              <Button primary onClick={toggleModal}>
                Okay
              </Button>
            </div>
          </div>
        )}
      />
    );
  }
  return (
    <EntityActionUpdate
      entityName={purchaseOrder.entityName}
      id={purchaseOrder.id}
      body={{
        is_labstep_fulfilled: !purchaseOrder.is_labstep_fulfilled,
      }}
      optimistic
      actionComponentProps={({ canEdit }) => ({
        type: 'children',
        children: ({ onClick }) => (
          <Popup
            inverted
            content="Enable to have this order fulfilled by your Labstep representative"
            trigger={
              <Checkbox
                toggle
                label="Enable Labstep Order Service"
                onChange={onClick}
                checked={purchaseOrder.is_labstep_fulfilled}
                disabled={
                  !(
                    purchaseOrder.canToggleIsLabstepFulfilled &&
                    canEdit
                  )
                }
              />
            }
          />
        ),
      })}
    />
  );
};

export default withActiveGroup(
  PurchaseOrderActionUpdateIsLabstepFulfilled,
);
