/**
 * Labstep
 *
 * @module components/File/Filter/Extension/Select
 * @desc Select filter for extensions
 */

import React from 'react';
import ReactSelect from 'labstep-web/core/Select';
import Modal from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import { fileTypes } from 'labstep-web/models';
import { STRINGS } from 'labstep-web/strings';
import { IFileFilterExtensionSelectProps } from './types';
import { placeholder } from './strings';

const FileFilterExtensionSelect: React.FC<
  IFileFilterExtensionSelectProps
> = ({ setParams, searchParams }) => {
  return (
    <Modal
      header={STRINGS.filter.header.extension}
      content={({ toggleModal }) => (
        <ReactSelect
          value={
            searchParams.extension
              ? {
                  value: searchParams.extension,
                  label: searchParams.extension,
                }
              : null
          }
          options={fileTypes.map((key) => ({
            value: key,
            label: key,
          }))}
          isClearable
          onChange={(selectedOption) => {
            setParams({
              extension: selectedOption
                ? selectedOption.value
                : undefined,
            });

            toggleModal();
          }}
          placeholder={placeholder}
        />
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon="file"
          text={STRINGS.filter.header.extension}
          onClick={toggleModal}
        />
      )}
    />
  );
};

export default FileFilterExtensionSelect;
