// @ts-strict-ignore
/**
 * Labstep
 *
 * @module core/ProtocolCondition/Modal/Content/Right/ActiveVariable
 * @desc Protocol Condition Modal Content Right Active Variable
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import RightPaneSubPage from 'labstep-web/components/Layout/RightPane/SubPage';
import { IRightPaneSubPageContainerProps } from 'labstep-web/components/Layout/RightPane/SubPage/types';
import MetadataShow, {
  MetadataShowName,
} from 'labstep-web/components/Metadata/Show';
import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import ProtocolValueShow, {
  ProtocolValueShowName,
} from 'labstep-web/components/ProtocolValue/Show';
import { ICONS } from 'labstep-web/constants';
import { Metadata } from 'labstep-web/models';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';
import React from 'react';
import { IProtocolConditionModalContentRightActiveVariableProps } from './types';

export const ProtocolConditionModalContentRightActiveVariable: React.FC<
  IProtocolConditionModalContentRightActiveVariableProps
> = ({ protocol }) => {
  const { activeVariable, setActiveVariable } =
    useProtocolConditionModalContext();

  const { id, guid, field_type } = activeVariable;
  const idAttribute = getIdAttribute(field_type);
  const onClose = (): void => setActiveVariable(null);

  let props: IRightPaneSubPageContainerProps = {
    action: (
      <EntityActionDelete
        entity={{
          entityName: field_type,
          [idAttribute]: activeVariable[idAttribute],
        }}
        options={{ onSuccess: onClose }}
        actionComponentProps={{
          type: 'icon',
          elementProps: { size: 'large', noBackground: true },
        }}
      />
    ),
    onClose,
  };

  if (field_type === Metadata.entityName) {
    props = {
      ...props,
      icon: ICONS.metadata.primary,
      header: <MetadataShowName id={id} />,
      children: (
        <MetadataShow
          parentEntityName={protocol.entityName}
          id={id}
        />
      ),
    };
  } else {
    props = {
      ...props,
      icon: ICONS.protocol_value.primary,
      header: <ProtocolValueShowName id={guid} />,
      children: <ProtocolValueShow id={guid} />,
    };
  }

  return <RightPaneSubPage {...props} />;
};

export default ProtocolConditionModalContentRightActiveVariable;
