/**
 * Labstep
 *
 * @module components/Folder/Action/Add/Bulk
 * @desc Action to add parentEntity to folder
 */

import React from 'react';
import EntityActionAddSearchBulk from 'labstep-web/components/Entity/Action/AddSearch/Bulk';
import { ICONS } from 'labstep-web/constants';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { Folder } from 'labstep-web/models';
import { CONFIG } from 'labstep-web/constants/config';
import { IFolderActionAddBulkProps } from './types';

export const FolderActionAddBulk: React.FC<
  IFolderActionAddBulkProps
> = ({ entityName, parentEntities, options }) => {
  const parentEntityIdsAccessObject = useHasAccessMultiple(
    entityName,
    parentEntities.map((e) => e.id),
    Action.folder_add_remove,
  );

  if (parentEntities.length === 0) {
    return null;
  }

  const parentEntitiesWithPermission = parentEntities.filter(
    (e) => parentEntityIdsAccessObject[e.id],
  );

  return (
    <EntityActionAddSearchBulk
      entityName={Folder.entityName}
      parentEntities={parentEntities}
      parentEntitiesWithPermission={parentEntitiesWithPermission}
      actionText={`Add to ${Folder.getHumanReadableEntityName()}`}
      icon={ICONS.folder.primary}
      options={options}
      searchSelectProps={{
        labelKey: 'folder_path',
        extraParams: { count: CONFIG.folder.count },
      }}
    />
  );
};

export default FolderActionAddBulk;
