/**
 * Labstep
 *
 * @module components/App
 * @desc App
 */

import React from 'react';
import { navigation } from 'labstep-web/services/navigation';
import { withActiveGroup } from 'labstep-web/containers';
import { Redirect } from 'react-router-dom';
import { IRedirectionProps } from './types';

export const redirectRoutes = {
  [navigation.get('overview')]: 'group_show',
  [navigation.get('tag_index')]: 'group_tags',
  [navigation.get('protocol_collection_index')]:
    'group_protocol_collections',
  [navigation.get('experiment_workflow_index')]:
    'group_experiment_workflows',
  [navigation.get('experiment_workflow_template_index')]:
    'group_experiment_workflow_templates',
  [navigation.get('order_request_index')]: 'group_order_requests',
  [navigation.get('purchase_order_index')]: 'group_purchase_orders',
  [navigation.get('resource_template_index')]:
    'group_resource_categories',
  [navigation.get('device_template_index')]:
    'group_device_categories',
  [navigation.get('resource_index')]: 'group_resources',
  [navigation.get('resource_item_index')]: 'group_resource_items',
  [navigation.get('device_index')]: 'group_devices',
  [navigation.get('file_index')]: 'group_files',
  [navigation.get('resource_location_index')]:
    'group_resource_locations',
  [navigation.get('settings')]: 'group_settings',
  [navigation.get('settings_notifications')]:
    'group_settings_notifications',
  [navigation.get('settings_order_info')]:
    'group_settings_order_info',
  [navigation.get('user_index')]: 'group_users',
} as const;

export const RedirectionComponent: React.FC<IRedirectionProps> = ({
  location,
  activeGroup,
}) => {
  const { pathname, search } = location;

  let to = navigation.get('login');
  if (activeGroup && activeGroup.id) {
    to = `${navigation.get(redirectRoutes[pathname], {
      id: activeGroup.id,
    })}${search}`;
  }

  return <Redirect to={to} />;
};

export default withActiveGroup(RedirectionComponent);
