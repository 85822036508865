/**
 * Labstep
 *
 * @module components/ShareLink/Action/Create
 * @desc Create a ShareLink
 */

import React from 'react';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import { EntityCreateContainer } from 'labstep-web/containers';
import ModalConfirmation from 'labstep-web/core/Modal/Confirmation';
import ActionComponent from 'labstep-web/core/Action/Component';
import Popup from 'labstep-web/core/Popup';
import {
  IShareLinkActionReCreateContainerProps,
  IShareLinkActionReCreateProps,
} from './types';

export const ShareLinkActionReCreate: React.FC<
  IShareLinkActionReCreateProps
> = ({ create, status, options, parentEntity }) => (
  <ModalConfirmation
    header="Warning"
    positive={false}
    onConfirm={({ toggleModal }) =>
      create(
        {
          is_regenerate: true,
          [`${parentEntity.entityName}_id`]: parentEntity.id,
        },
        enhanceOptions({ options, onSuccess: toggleModal }),
      )
    }
    status={status}
    message="If you regenerate this sharelink, the old link will cease to grant access, are you sure you want to proceed?"
  >
    {({ toggleModal }) => (
      <Popup
        content="Regenerate"
        inverted
        trigger={
          <ActionComponent
            type="icon"
            icon="refresh"
            onClick={toggleModal}
          />
        }
      />
    )}
  </ModalConfirmation>
);

export const ShareLinkActionReCreateContainer: React.FC<
  IShareLinkActionReCreateContainerProps
> = ({ parentEntity, ...rest }) => (
  <EntityCreateContainer
    entityName="share_link"
    uuid={`share_link_${parentEntity.entityName}_${parentEntity.id}`}
  >
    {(containerProps) => (
      <ShareLinkActionReCreate
        parentEntity={parentEntity}
        {...containerProps}
        {...rest}
      />
    )}
  </EntityCreateContainer>
);

export default ShareLinkActionReCreateContainer;
