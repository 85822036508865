/**
 * Labstep
 *
 * @module containers/Reorder
 * @desc A container to reorder entities
 */

import React from 'react';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import {
  IReorderContainerProps,
  IReorderProps,
  IReorderState,
} from './types';

export const getOrderedEntitiesByPosition = (entities) =>
  orderBy(entities, ['position', 'asc']);

export class Reorder extends React.Component<
  IReorderProps,
  IReorderState
> {
  constructor(props) {
    super(props);
    this.moveUp = this.moveUp.bind(this);
    this.moveDown = this.moveDown.bind(this);
    this.canMoveUp = this.canMoveUp.bind(this);
    this.canMoveDown = this.canMoveDown.bind(this);
    this.move = this.move.bind(this);
    this.state = {
      orderedEntities: getOrderedEntitiesByPosition(props.entities),
    };
  }

  componentDidUpdate(prevProps) {
    const { entities } = this.props;
    if (!isEqual(entities, prevProps.entities)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        orderedEntities: getOrderedEntitiesByPosition(entities),
      });
    }
  }

  move(orderFunction) {
    const { update } = this.props;
    const { orderedEntities } = this.state;

    const updatedOrderedEntities: IReorderState['orderedEntities'] =
      orderedEntities.map(orderFunction);
    update(
      updatedOrderedEntities.map((entity, index) => ({
        id: entity.id,
        position: index + 1,
      })),
    );
    this.setState({ orderedEntities: updatedOrderedEntities });
  }

  // Move up means decrease index
  moveUp(id) {
    const { orderedEntities } = this.state;
    if (this.canMoveUp(id)) {
      const entityIndex = orderedEntities.findIndex(
        (entity) => entity.id === id,
      );
      const orderFunction = (entity, index) => {
        if (index === entityIndex - 1) {
          return orderedEntities[index + 1];
        }
        if (index === entityIndex) {
          return orderedEntities[index - 1];
        }
        return orderedEntities[index];
      };
      this.move(orderFunction);
    }
  }

  // Move down means increase index
  moveDown(id) {
    const { orderedEntities } = this.state;
    if (this.canMoveDown(id)) {
      const entityIndex = orderedEntities.findIndex(
        (entity) => entity.id === id,
      );
      const orderFunction = (entity, index) => {
        if (index === entityIndex) {
          return orderedEntities[index + 1];
        }
        if (index === entityIndex + 1) {
          return orderedEntities[index - 1];
        }
        return orderedEntities[index];
      };
      this.move(orderFunction);
    }
  }

  // Can move up means decrease index
  canMoveUp(id) {
    const { orderedEntities } = this.state;
    const index = orderedEntities.findIndex(
      (entity) => entity.id === id,
    );
    return index > 0 && index < orderedEntities.length;
  }

  // Can move up means increase index
  canMoveDown(id) {
    const { orderedEntities } = this.state;
    const index = orderedEntities.findIndex(
      (entity) => entity.id === id,
    );
    return index >= 0 && index < orderedEntities.length - 1;
  }

  render() {
    const { children } = this.props;
    const { orderedEntities } = this.state;

    return children({
      reorder: {
        moveUp: this.moveUp,
        moveDown: this.moveDown,
        canMoveUp: this.canMoveUp,
        canMoveDown: this.canMoveDown,
      },
      orderedEntities,
    });
  }
}

export const ReorderContainer: React.FC<IReorderContainerProps> = ({
  entityName,
  ...rest
}) => (
  <EntityUpdateContainer entityName={entityName} batch>
    {({ update }) => <Reorder update={update} {...rest} />}
  </EntityUpdateContainer>
);
