/**
 * Labstep
 *
 * @module screens/PurchaseOrder/Print
 * @desc Display printable version of an PurchaseOrder
 */

import React from 'react';
import Print from 'labstep-web/core/Print';
import { EntityCreateContainer } from 'labstep-web/containers';
import { EntityExport, PurchaseOrder } from 'labstep-web/models';
import { PurchaseOrderPrintScreenProps } from './types';

export const PurchaseOrderPrintScreen: React.FC<
  PurchaseOrderPrintScreenProps
> = ({ match }) => (
  <EntityCreateContainer entityName={EntityExport.entityName}>
    {({ status, create }) => (
      <Print
        id={match.params.id}
        entityName={PurchaseOrder.entityName}
        status={status}
        create={create}
      />
    )}
  </EntityCreateContainer>
);

export default PurchaseOrderPrintScreen;
