/**
 * Labstep
 *
 * @module components/Entity/Search/BulkActions
 * @desc Components to render bulk actions
 */

import React, { useCallback } from 'react';
import EntityActionDeleteBulk from 'labstep-web/components/Entity/Action/Delete/Bulk';
import EntityActionTagBulk from 'labstep-web/components/Entity/Action/Tag/Bulk';
import EntityActionAssignBulk from 'labstep-web/components/Entity/Action/Assign/Bulk';
import EntityActionTransferOwnershipBulk from 'labstep-web/components/Entity/Action/TransferOwnership/Bulk';
import EntityActionShareBulk from 'labstep-web/components/Entity/Action/Share/Bulk';
import OrderRequestActionUpdateStatusBulk from 'labstep-web/components/OrderRequest/Action/UpdateStatus/Bulk';
import OrderRequestActionUpdateUrgentBulk from 'labstep-web/components/OrderRequest/Action/UpdateUrgent/Bulk';
import OrderRequestActionSetPurchaseOrderBulk from 'labstep-web/components/OrderRequest/Action/SetPurchaseOrder/Bulk';
import ResourceItemActionUpdateStatusBulk from 'labstep-web/components/ResourceItem/Action/UpdateStatus/Bulk';
import ResourceItemActionUpdateResourceLocationBulk from 'labstep-web/components/ResourceItem/Action/UpdateResourceLocation/Bulk';
import ResourceItemActionUpdateAmountBulk from 'labstep-web/components/ResourceItem/Action/UpdateAmount/Bulk';
import OrderRequestActionCreateBulk from 'labstep-web/components/OrderRequest/Action/Create/Bulk';
import SignatureRequestActionCreateBulk from 'labstep-web/components/SignatureRequest/Action/Create/Bulk';
import ExperimentWorkflowActionFinishBulk from 'labstep-web/components/ExperimentWorkflow/Action/Finish/Bulk';
import ExperimentWorkflowActionLockBulk from 'labstep-web/components/ExperimentWorkflow/Action/Lock/Bulk';
import OrderRequestActionRemovePurchaseOrderBulk from 'labstep-web/components/OrderRequest/Action/RemovePurchaseOrder/Bulk';
import FolderActionAddBulk from 'labstep-web/components/Folder/Action/Add/Bulk';
import FolderActionRemoveBulk from 'labstep-web/components/Folder/Action/Remove/Bulk';
import { useDataGridContext } from 'labstep-web/core/DataGrid/context';
import { useBulkSelectContext } from 'labstep-web/containers/BulkSelect/context';
import EntityActionUpdateTemplateBulk from '../../Action/UpdateTemplate/Bulk';
import { BulkAction, IEntitySearchBulkActionsProps } from './types';
import styles from './styles.module.scss';

const EntitySearchBulkActions: React.FC<
  IEntitySearchBulkActionsProps
> = ({ bulkActions = [], entityName }) => {
  const { entities, unselectAll, isAtLeastOneSelected } =
    useBulkSelectContext();
  const context = useDataGridContext();
  const unselectAllWithGrid = useCallback((): void => {
    if (context?.agGrid) {
      context.agGrid.api.deselectAll();
    }
    unselectAll();
  }, [context, unselectAll]);
  if (!isAtLeastOneSelected) {
    return null;
  }
  return (
    <div className={styles.container}>
      {bulkActions.includes(BulkAction.add_to_folder) && (
        <FolderActionAddBulk
          entityName={entityName}
          parentEntities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
            toast: true,
          }}
        />
      )}
      {bulkActions.includes(BulkAction.remove_from_folder) && (
        <FolderActionRemoveBulk
          entityName={entityName}
          parentEntities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(BulkAction.tag) && (
        <EntityActionTagBulk
          entityName={entityName}
          parentEntities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(BulkAction.assign) && (
        <EntityActionAssignBulk
          parentEntityName={entityName}
          parentEntities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(
        BulkAction.order_request_update_status,
      ) && (
        <OrderRequestActionUpdateStatusBulk
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(
        BulkAction.order_request_set_purchase_order,
      ) && (
        <OrderRequestActionSetPurchaseOrderBulk
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(
        BulkAction.order_request_update_urgent,
      ) && (
        <OrderRequestActionUpdateUrgentBulk
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(
        BulkAction.resource_item_update_status,
      ) && (
        <ResourceItemActionUpdateStatusBulk
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(
        BulkAction.resource_item_update_resource_location,
      ) && (
        <ResourceItemActionUpdateResourceLocationBulk
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(
        BulkAction.resource_item_update_amount,
      ) && (
        <ResourceItemActionUpdateAmountBulk
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(
        BulkAction.resource_create_order_request,
      ) && (
        <OrderRequestActionCreateBulk
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(
        BulkAction.experiment_workflow_finish,
      ) && (
        <ExperimentWorkflowActionFinishBulk
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(BulkAction.experiment_workflow_lock) && (
        <ExperimentWorkflowActionLockBulk
          entityName={entityName}
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(BulkAction.signature_request_create) && (
        <SignatureRequestActionCreateBulk
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(BulkAction.update_template) && (
        <EntityActionUpdateTemplateBulk
          entityName={entityName as 'device' | 'resource'}
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(
        BulkAction.order_request_remove_purchase_order,
      ) && (
        <OrderRequestActionRemovePurchaseOrderBulk
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(BulkAction.transfer_ownership) && (
        <EntityActionTransferOwnershipBulk
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(BulkAction.share) && (
        <EntityActionShareBulk
          entityName={entityName}
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
      {bulkActions.includes(BulkAction.delete) && (
        <EntityActionDeleteBulk
          entityName={entityName}
          entities={entities as any}
          options={{
            onSuccess: unselectAllWithGrid,
          }}
        />
      )}
    </div>
  );
};

export default EntitySearchBulkActions;
