/**
 * Labstep
 *
 * @module components/PermaLinkReader/Action/QRScan
 * @desc QR Scan Action for PermaLinkReader
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ICONS } from 'labstep-web/constants';
import { IPermaLinkActionQRScanProps } from './types';

export const PermaLinkActionQRScan: React.FC<
  IPermaLinkActionQRScanProps
> = ({ actionComponentProps }) => {
  return (
    <ActionComponent
      type="button"
      text="Scan Items"
      showIcon
      icon={ICONS.resource_item.info.qr_code}
      elementProps={{
        size: 'large',
        primary: true,
        basic: true,
        fluid: true,
      }}
      {...actionComponentProps}
    />
  );
};

export default PermaLinkActionQRScan;
