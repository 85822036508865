/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/FilterPane
 * @desc Filter pane for experiment worklfow
 */

import React from 'react';
import FilterPane from 'labstep-web/components/Filter/Pane';
import ExperimentWorkflowFilterPrimaryList from 'labstep-web/components/ExperimentWorkflow/Filter/Primary/List';
import ExperimentWorkflowFilterStatusList from 'labstep-web/components/ExperimentWorkflow/Filter/Status/List';
import ExperimentWorkflowFilterSignedList from 'labstep-web/components/ExperimentWorkflow/Filter/Signed/List';
import { ICONS } from 'labstep-web/constants';
import { STRINGS } from 'labstep-web/strings';

const ExperimentWorkflowFilterPane: React.FC = () => (
  <FilterPane
    entityName="experiment_workflow"
    extraFilters={
      <>
        <ExperimentWorkflowFilterPrimaryList />
        <ExperimentWorkflowFilterStatusList />
        <ExperimentWorkflowFilterSignedList />
      </>
    }
    icon={ICONS.experiment_workflow.primary}
    text={STRINGS.experiment_workflow.plural.capitalized}
  />
);

export default ExperimentWorkflowFilterPane;
