/**
 * Labstep
 *
 * @module components/Table/Item
 * @desc Display Editable Table
 */

import React, { useState } from 'react';
import Icon from 'labstep-web/core/Icon';
import TableEditContainer from 'labstep-web/containers/Table/Edit';
import TableCard from 'labstep-web/components/Table/Card';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ProtocolTable } from 'labstep-web/models';
import classNames from 'classnames';
import { ICONS } from 'labstep-web/constants';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import TableFormShowEditName from '../Form/ShowEdit/Name';
import { ITableItemContainerProps, ITableItemProps } from './types';
import styles from './styles.module.scss';

export const TableItem: React.FC<ITableItemProps> = ({
  table,
  onDelete,
  readOnly,
  allowLocking,
  activeEditModal,
  setActiveEditModal,
  ...rest
}) => {
  const canEdit = useHasAccess(
    table.entityName,
    table.idAttr,
    Action.edit,
  );
  const isExpanded =
    activeEditModal?.id === table.guid &&
    activeEditModal?.entityName === table.entityName;
  const finalReadOnly = readOnly || !canEdit;

  const canDelete =
    !finalReadOnly &&
    onDelete &&
    table.entityName === ProtocolTable.entityName;

  return (
    <div
      data-testid="table-item-container"
      className={classNames({ [styles.tableContainer]: isExpanded })}
    >
      <TableCard
        name={<TableFormShowEditName table={table} />}
        data={table.data}
        readOnly={finalReadOnly}
        isExpanded={isExpanded}
        actions={
          <>
            {canDelete && (
              <Icon name="trash" color="blue" onClick={onDelete} />
            )}
            <Icon
              name={
                isExpanded ? 'compress' : ICONS.file.actions.preview
              }
              data-testid="expand-button"
              color="blue"
              popup={{
                content: isExpanded ? 'Collapse' : 'Expand',
              }}
              onClick={() => {
                setActiveEditModal(
                  isExpanded
                    ? null
                    : {
                        entityName: table.entityName,
                        id: table.guid,
                      },
                );
              }}
            />
          </>
        }
        allowLocking={allowLocking}
        {...rest}
      />
    </div>
  );
};

const TableItemContainer: React.FC<ITableItemContainerProps> = ({
  table,
  ...rest
}) => (
  <TableEditContainer table={table}>
    {(containerProps) => <TableItem {...containerProps} {...rest} />}
  </TableEditContainer>
);

export default withActiveEditModal(TableItemContainer);
