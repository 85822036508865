/**
 * Labstep
 *
 * @module components/DataTable/Item
 * @desc Display Editable DataTable
 */

import React from 'react';
import Modal from 'labstep-web/core/Modal/Default';
import TableFormEdit from 'labstep-web/components/Table/Form/Edit';
import TableFormShowEditName from 'labstep-web/components/Table/Form/ShowEdit/Name';
import { ITableModalProps } from './types';

export const TableModal: React.FC<ITableModalProps> = ({
  table,
  onDone,
  readOnly,
  allowLocking,
  ...rest
}) => (
  <Modal
    size="fullscreen"
    header={<TableFormShowEditName table={table} />}
    closeIcon={false}
    content={({ toggleModal }) => (
      <TableFormEdit
        table={table}
        readOnly={readOnly}
        allowLocking={allowLocking}
        onDone={() => {
          if (onDone) {
            onDone();
          }
          toggleModal();
        }}
      />
    )}
    {...rest}
  />
);

export default TableModal;
