import colDefBulkSelect from 'labstep-web/components/Entity/DataGrid/colDefs/bulkSelect';
import colDefEntityUser from 'labstep-web/components/Entity/DataGrid/colDefs/entityUser';
import colDefTemplate from 'labstep-web/components/Entity/DataGrid/colDefs/template';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import { Resource } from 'labstep-web/models';
import { IParamsHOCChildrenProps } from 'labstep-web/hoc/Params/types';
import { getResourceMetadataColDefs } from '../../../components/Resource/DataGrid/colDefs/metadata';
import colDefAvailableItems from '../../../components/Resource/DataGrid/colDefs/availableItems';
import { colDefResourceName } from '../../../components/Resource/DataGrid/colDefs/name';
import { colDefResourceActionMenu } from '../../../components/Resource/DataGrid/colDefs/actionMenu';

export default (
  setParams: IParamsHOCChildrenProps['setParams'],
  resourceTemplate?: Resource,
): ColDef<Resource>[] => {
  const colDefs = resourceTemplate
    ? [
        colDefResourceActionMenu,
        colDefBulkSelect,
        colDefResourceName,
        colDefAvailableItems,
        ...getResourceMetadataColDefs(resourceTemplate),
        colDefEntityUser,
      ]
    : [
        colDefResourceActionMenu,
        colDefBulkSelect,
        colDefResourceName,
        colDefTemplate(Resource.entityName, setParams),
        colDefAvailableItems,
        colDefEntityUser,
      ];
  return colDefs;
};
