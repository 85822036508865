/**
 * Labstep
 *
 * @module components/EntityUser/Manager
 */

import React from 'react';
import EntityUserActionAdd from 'labstep-web/components/EntityUser/Action/Add';
import EntityUserTable from 'labstep-web/components/EntityUser/Table';
import { EntityUser } from 'labstep-web/models';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { IEntityUserManagerProps } from './types';

const EntityUserManager: React.FC<IEntityUserManagerProps> = ({
  entity,
}) => (
  <div>
    <EntityUserActionAdd entity={entity} />
    <br />
    <ReadOnMountHOC
      type="cursor"
      pagination={{ loader: 'placeholder' }}
      entityName={EntityUser.entityName}
      params={{ [`${entity.entityName}_id`]: entity.id }}
      children={({ entities }) => (
        <EntityUserTable entity={entity} entityUsers={entities} />
      )}
    />
  </div>
);

export default EntityUserManager;
