/**
 * Labstep
 *
 * @module components/UserOrganization/Table
 * @desc A multilist listing user organizations
 */

import React from 'react';
import TableSimple from 'labstep-web/core/Table/Simple';
import Icon from 'labstep-web/core/Icon';
import Popup from 'labstep-web/core/Popup';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import EntityLinkComponent from 'labstep-web/components/Entity/Link/Component';
import Profile from 'labstep-web/components/User/Show/Profile';
import UserOrganizationActionMenu from 'labstep-web/components/UserOrganization/Action/Menu';
import { UserOrganization } from 'labstep-web/models';
import { Size } from 'labstep-web/core/Initials/types';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import UserOrganizationActionUpdate from '../Action/Update';
import { IUserOrganizationTableProps } from './types';

const UserOrganizationTable: React.FC<
  IUserOrganizationTableProps
> = ({ userOrganizations }) => {
  const columns = [
    {
      header: ' ',
      content: (
        userOrganization: UserOrganization,
      ): React.ReactElement => (
        <ProfilePicture
          size={Size.mini}
          entity={userOrganization.user}
        />
      ),
      cellProps: { style: { width: 50 } },
    },
    {
      header: 'Name',
      content: (
        userOrganization: UserOrganization,
      ): React.ReactElement => (
        <div>
          <Profile
            user={userOrganization.user}
            viewComponent={
              <EntityLinkComponent entity={userOrganization.user} />
            }
          />
          <p>{userOrganization.user.email}</p>
        </div>
      ),
      cellProps: { flex: 5 },
    },
    {
      header: <TextWithHelp text="Role" />,
      content: (
        userOrganization: UserOrganization,
      ): React.ReactElement =>
        userOrganization.user.deleted_at ? (
          <p>Disabled</p>
        ) : (
          <UserOrganizationActionUpdate
            userOrganization={userOrganization}
          />
        ),
      cellProps: { style: { width: 130 } },
    },
    {
      header: '',
      content: (
        userOrganization: UserOrganization,
      ): React.ReactElement | null =>
        // eslint-disable-next-line no-nested-ternary
        userOrganization.user.saml_id ? (
          <div>
            SSO Enabled <Icon name="check" />
          </div>
        ) : userOrganization.user.is_two_factor_enabled ? (
          <Popup
            inverted
            on="hover"
            content="Secured by Two Factor Authentication"
            trigger={
              <div>
                2FA <Icon name="check" />
              </div>
            }
          />
        ) : null,
      cellProps: { style: { width: 80 } },
    },
    {
      header: '',
      content: (
        userOrganization: UserOrganization,
      ): React.ReactElement => (
        <UserOrganizationActionMenu
          userOrganization={userOrganization}
        />
      ),
      cellProps: { textAlign: 'right', style: { width: 50 } },
    },
  ];
  return <TableSimple rows={userOrganizations} columns={columns} />;
};

export default UserOrganizationTable;
