/**
 * Labstep
 *
 * @module components/EntityImport/Card/Status
 * @desc Display EntityImport status and progress
 */

import React from 'react';
import { SemanticCOLORS } from 'semantic-ui-react';
import Progress from 'labstep-web/core/Progress';
import {
  EntityImportStatus,
  EntityImportStepMode,
  Step,
} from 'labstep-web/models';
import Flex from 'labstep-web/core/Flex';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { EntityImportProgressProps } from './types';
import styles from './styles.module.scss';

export const getProgressColor = (step: Step): SemanticCOLORS => {
  if (step.fail_count) {
    return 'red';
  }
  if (step.success_count === step.total_count) {
    return 'green';
  }
  return 'blue';
};

const actionWords = {
  create: 'created',
  link: 'linked',
  revert: 'reverted',
};

export const EntityImportProgress: React.FC<
  EntityImportProgressProps
> = ({ entityImport }) => (
  <Flex spacing="gap" column>
    {entityImport.step_data &&
      Object.entries(entityImport.step_data.steps)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, step]) => {
          return (
            entityImport.status ===
              EntityImportStatus.revert_started ||
            entityImport.status ===
              EntityImportStatus.revert_completed ||
            step.mode !== EntityImportStepMode.revert
          );
        })
        .map(([key, step]) => {
          return (
            <Flex
              key={key}
              grow
              spacing="gap"
              vAlignContent="center"
              className={styles.rowContainer}
            >
              <div className={styles.progress}>
                <Progress
                  percent={Math.ceil(
                    (step.success_count / step.total_count) * 100,
                  )}
                  indicating={
                    entityImport.status === EntityImportStatus.started
                  }
                  success={step.success_count === step.total_count}
                  error={!!step.fail_count}
                  progress
                  color={getProgressColor(step)}
                />
              </div>
              <span>
                {step.success_count} / {step.total_count}{' '}
                {getHumanReadableEntityName(step.entity_name)}{' '}
                {actionWords[step.mode]}
              </span>
            </Flex>
          );
        })}
  </Flex>
);

export default EntityImportProgress;
