/**
 * Labstep
 */

import { GC } from 'labstep-web/core/SpreadJS/imports';
import { MetadataTypeValues } from 'labstep-web/models/metadata/types';

export interface IRow {
  [key: string]: any;
}

export interface IFieldsData {
  fields: string[];
  data: IRow[];
}

export interface IArgsClipboardChanging {
  cancel: boolean;
  copyData: {
    text: string;
    html: string;
  };
  sheet: GC.Spread.Sheets.Worksheet;
  sheetName: string;
}

export interface IArgsClipboardPasting {
  cancel: boolean;
  cellRange: {
    row: number;
    rowCount: number;
    col: number;
    colCount: number;
  };
  pasteDate: {
    text: string;
    html: string;
  };
  pasteOption: number;
  sheet: GC.Spread.Sheets.Worksheet;
  sheetName: string;
}

interface IBaseCell {
  col: number;
  row: number;
  sheet: GC.Spread.Sheets.Worksheet;
  sheetName: string;
}

export interface IArgsValidationError extends IBaseCell {
  validationResult: number;
  validator: GC.Spread.Sheets.DataValidation.DefaultDataValidator;
}

export interface ICellChanged extends IBaseCell {
  isUndo: boolean;
  newValue: MetadataTypeValues;
  oldValue: MetadataTypeValues;
  propertyName: '[styleinfo]' | 'value';
  sheetArea: number;
}

export interface ICellEnter extends IBaseCell {
  sheetArea: number;
}

export const GRAPECITY_ROW_LIMIT = 5000;

export class GrapecityService {
  transformExcelData = (data) => {
    const { sheets } = data;
    const firstSheetKey = Object.keys(sheets)[0];
    const rowsInObjectForm =
      data.sheets[firstSheetKey].data.dataTable;

    const firstRowKey = Object.keys(rowsInObjectForm)[0];
    const firstRow = rowsInObjectForm[firstRowKey];
    const fields = firstRow
      ? Object.keys(firstRow).reduce((firstRowFields, cellKey) => {
          const { value } = firstRow[cellKey];
          return [...firstRowFields, value];
        }, [])
      : [];

    const rowsObject = Object.keys(rowsInObjectForm)
      .filter(
        (row, rowIndex) =>
          rowIndex > 0 && rowIndex <= GRAPECITY_ROW_LIMIT,
      )
      .reduce((rows, rowKey) => {
        const rowInObjectForm = rowsInObjectForm[rowKey];

        const row = fields.reduce((result, field, index) => {
          const value =
            (rowInObjectForm[index] &&
              rowInObjectForm[index].value) ||
            '';
          return { ...result, [field]: value };
        }, {});

        return [...rows, row];
      }, []);

    return [rowsObject, fields];
  };

  convertCSV = (fields: string[], rows: any[]) => {
    const alphabet = this.generateAlphabets(fields.length);

    const data = [];

    const header = {};
    let i = 0;
    fields.forEach((field) => {
      header[alphabet[i]] = field;
      i += 1;
    });
    data.push(header);

    rows.forEach((row) => {
      let j = 0;
      const newRow: any = {};
      fields.forEach((field) => {
        if (row[field]) {
          newRow[alphabet[j]] = row[field];
        }
        j += 1;
      });
      data.push(newRow);
    });

    return data;
  };

  generateAlphabets = (size: number): string[] =>
    Array.from(Array(size).keys()).map((value) =>
      this.getNameFromNumber(value),
    );

  protected getNameFromNumber = (value: number) => {
    const numeric = value % 26;
    const letter = String.fromCharCode(65 + numeric);
    const range = Math.floor(value / 26);
    if (range > 0) {
      return this.getNameFromNumber(range - 1) + letter;
    }
    return letter;
  };

  /**
   *
   * @param sheetArray dataSource from active spreadsheet as an array of objects
   * @returns filters away empty rows given an active sheet (represented as a nested list)
   */
  filterOutEmptyRowsFromSheetArray = (sheetArray: IRow[]): IRow[] => {
    return sheetArray.filter((rowObj) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { row, fieldType, inputOutput, ...remainingRow } = rowObj;
      return Object.values(remainingRow).some((elem) => !!elem);
    });
  };

  /**
   *
   * *@param type data type of validator to get
   * @param message main text body of the data validator message box
   * @param title title of data validator message box
   * @returns a grapecity text data validation object
   */
  getValidator = (
    type: MetadataTypeValues,
    message: string,
    title: string,
  ): GC.Spread.Sheets.DataValidation.DefaultDataValidator => {
    let dv: GC.Spread.Sheets.DataValidation.DefaultDataValidator;
    switch (type) {
      case MetadataTypeValues.default:
        dv =
          GC.Spread.Sheets.DataValidation.createTextLengthValidator(
            GC.Spread.Sheets.ConditionalFormatting.ComparisonOperators
              .greaterThan,
            '0',
            '0',
          );
        break;
      case MetadataTypeValues.date:
      case MetadataTypeValues.datetime:
        dv = GC.Spread.Sheets.DataValidation.createDateValidator(
          GC.Spread.Sheets.ConditionalFormatting.ComparisonOperators
            .greaterThan,
          new Date(0, 1, 1),
          new Date(0, 1, 1),
        );
        break;
      case MetadataTypeValues.numeric:
        dv = GC.Spread.Sheets.DataValidation.createNumberValidator(
          GC.Spread.Sheets.ConditionalFormatting.ComparisonOperators
            .greaterThanOrEqualsTo,
          '0',
          '0',
          false,
        );
        break;
      default:
        dv =
          GC.Spread.Sheets.DataValidation.createTextLengthValidator(
            GC.Spread.Sheets.ConditionalFormatting.ComparisonOperators
              .greaterThan,
            '0',
            '0',
          );
        break;
    }
    dv.errorMessage(`Incorrect ${type.toLowerCase()} value entered`);
    dv.errorTitle('Spreadsheet problem');
    dv.showInputMessage(true);
    dv.inputTitle(title);
    dv.inputMessage(message);
    return dv;
  };

  /**
   *
   * @param activeSheetRange cell range selected on active spreadsheet.
   * @param thickness line thickness for border.
   * @param borderColour colour of the border.
   * @param backColor colour of the background.
   * @returns activeSheetRange with properties applied to the range
   */
  applyBorderingAndBackground = (
    activeSheetRange: GC.Spread.Sheets.CellRange,
    thickness: string,
    borderColour = '#d4d4d4',
    backColor = '#ffffff',
  ): GC.Spread.Sheets.CellRange => {
    activeSheetRange.backColor(backColor);
    activeSheetRange.setBorder(
      new GC.Spread.Sheets.LineBorder(
        borderColour,
        GC.Spread.Sheets.LineStyle[thickness],
      ),
      { all: true },
    );
    return activeSheetRange;
  };

  /**
   *
   * @param args object containing cell range and other properties of the clipboard pasting
   * @param showToast function triggering toast notification to appear.
   */
  handleValidationError = (
    sheet: GC.Spread.Sheets.Worksheet,
    args: IArgsValidationError,
    showToast: ({ type, message, options }) => void,
  ) => {
    const cell = sheet.getCell(args.row, args.col);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    grapecityService.applyBorderingAndBackground(
      cell,
      'medium',
      '#d74040',
    );
    showToast({
      type: 'error',
      message: args.validator.errorMessage(),
      options: {
        header: args.validator.errorTitle(),
        timeout: 4000,
      },
    });
  };

  /**
   *
   * @param args object containing cell range and other properties of the clipboard pasting
   * @param showToast function triggering toast notification to appear
   * @param forbiddenColumn column of spreadsheet that can trigger the showToast
   */
  handleClipboardPasting = (
    args: IArgsClipboardPasting,
    showToast: ({ type, message, options }) => void,
    forbiddenColumn: number,
  ) => {
    if (args.cellRange.col === forbiddenColumn) {
      showToast({
        type: 'error',
        message: 'Cannot paste to field type',
        options: {
          header: 'Metadata spreadsheet',
        },
      });
      // eslint-disable-next-line no-param-reassign
      args.cancel = true;
    }
  };

  handleClipboardChanging = (
    args: IArgsClipboardChanging,
    showToast: ({ type, message, options }) => void,
    colVal: number,
    forbiddenColumn: number,
  ) => {
    if (colVal === forbiddenColumn) {
      showToast({
        type: 'error',
        message: 'Cannot copy/cut from field type',
        options: {
          header: 'Metadata spreadsheet',
        },
      });
      // eslint-disable-next-line no-param-reassign
      args.cancel = true;
    }
  };
}

export const grapecityService = new GrapecityService();
