/**
 * Labstep
 *
 * @module containers/Ui/OfflineDetection
 * @desc Container for toggling offline/online connectivity
 */

import { connect } from 'react-redux';
import { setConnected } from 'labstep-web/state/actions';
import { selectConnectivity } from 'labstep-web/state/selectors';

const mapDispatchToProps = {
  setConnected,
};

const mapStateToProps = (state) => ({
  connected: selectConnectivity(state),
});

export const withOfflineDetection = (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);
