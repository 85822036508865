/**
 * Labstep
 *
 * @module core/Form/ShowEdit/Amount
 * @desc A component to toggle between Show and Edit mode
 * for text fields. The intention is to toggle onBlur and do
 * an optimistic update. If the field is left empty no api
 * request will be send and the last saved value will be restored.
 */

import React from 'react';
import { omit, replace } from 'lodash';
import classnames from 'classnames';
import CurrencyService from 'labstep-web/services/currency.service';
import { EntityUpdateContainer } from 'labstep-web/containers';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ShowEditToggleComponent } from '..';
import ReusableForm from '../../Reusable';
import { IFieldWithoutActionProps } from '../../Reusable/types';
import { IFormShowEditAmountProps } from './types';
import styles from './styles.module.scss';

const FormShowEditAmount: React.FC<IFormShowEditAmountProps> = (
  props,
) => {
  const {
    field,
    value,
    entityName,
    id,
    entity,
    options,
    children,
    currency,
  } = props;
  const name = field.fieldLabel || replace(field.name, /_/g, ' ');

  const hasValue =
    (value !== undefined ? value : entity[field.name]) !== null;

  const fields: IFieldWithoutActionProps[] = [
    { ...omit(field, ['fieldLabel']) } as IFieldWithoutActionProps,
  ];

  const defaultValues = {
    [field.name]: entity[field.name]
      ? CurrencyService.getPrice(entity[field.name], currency)
      : null,
  };
  const finalId: string | number = id || entity.idAttr;
  const canEdit = useHasAccess(entityName, finalId, Action.edit);

  return (
    <EntityUpdateContainer
      entityName={entityName}
      id={finalId}
      // optimistic
    >
      {({ update }) => (
        <ShowEditToggleComponent
          canEdit={canEdit}
          children={children}
          name={name}
          hasValue={hasValue}
          toggledComponent={({ toggle }) => (
            <div
              className={classnames(styles.saveOnChange, {
                [styles.header]: field.header,
              })}
            >
              <ReusableForm
                fields={fields}
                defaultValues={defaultValues}
                submitButton={false}
                blurToggle={toggle}
                autoFocus
                preSubmit={(values) => {
                  if (values[field.name] === null) {
                    return values;
                  }
                  return {
                    [field.name]: CurrencyService.getNumberFromFloat(
                      values[field.name],
                      currency,
                    ),
                  };
                }}
                onSubmit={(values) => {
                  update(values, options);
                  toggle();
                }}
              />
            </div>
          )}
        />
      )}
    </EntityUpdateContainer>
  );
};

export default FormShowEditAmount;
