/**
 * Labstep
 *
 * @module components/Experiment/Action/Lock
 * @desc Action to toggle lock for experiment
 */

import React from 'react';
import { ExperimentWorkflow } from 'labstep-web/models';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import EntityActionLock from 'labstep-web/components/Entity/Action/Lock';
import { IExperimentActionLockProps } from './types';

export const ExperimentActionLock: React.FC<
  IExperimentActionLockProps
> = ({ experiment, options = {}, actionComponentProps }) => (
  <EntityActionLock
    entity={experiment}
    options={options}
    actionComponentProps={actionComponentProps}
    disabled={
      // TODO: This is a hack to prevent the user from unlocking an experiment
      // when its workflow is locked. This should be handled by permissions.
      !experiment.is_root && experiment.experiment_workflow?.locked_at
        ? {
            message: `Unlock the ${getHumanReadableEntityName(
              ExperimentWorkflow.entityName,
            )} to unlock this ${getHumanReadableEntityName(
              experiment.entityName,
            )}`,
          }
        : undefined
    }
  />
);

export default ExperimentActionLock;
