/**
 * Labstep
 *
 * @module components/Filter/Tag
 * @desc Filter Tag List
 */

import React from 'react';
import FilterListSearch from 'labstep-web/components/Filter/List/Search';
import FilterListHeader from 'labstep-web/components/Filter/List/Header';
import TagActionCreate from 'labstep-web/components/Tag/Action/Create';
import TagActionMenu from 'labstep-web/components/Tag/Action/Menu';
import { Tag } from 'labstep-web/models';
import { ICONS } from 'labstep-web/constants';
import { STRINGS } from 'labstep-web/strings';
import { IFilterTagListProps } from './types';

const FilterTagList: React.FC<IFilterTagListProps> = ({
  entityName,
}) => (
  <FilterListSearch
    params={{ type: entityName }}
    filter={{
      entityName,
      name: 'tag',
    }}
    action={
      <>
        <div style={{ marginBottom: 10 }}>
          <TagActionCreate
            defaultType={entityName}
            actionComponentProps={{
              type: 'text',
              icon: ICONS.entity.actions.create,
            }}
          />
        </div>
      </>
    }
    entityName={Tag.entityName}
    mapping={(entity: Tag) => ({
      icon: ICONS.tag.primary,
      text: entity.name,
      params: { tag_id: String(entity.id) },
      actionMenu: (
        <TagActionMenu actions={['edit', 'delete']} tag={entity} />
      ),
    })}
    searchBarProps={{
      viewComponent: (
        <FilterListHeader
          header={STRINGS.filter.header.tag}
          filter={{
            entityName,
            name: 'tag',
          }}
        />
      ),
    }}
  />
);

export default FilterTagList;
