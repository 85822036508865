/**
 * Labstep
 *
 * @module core/SpreadJS/imports
 * @desc GrapeCity imports
 */

import GC from '@grapecity/spread-sheets';
import {
  Column,
  SpreadSheets as Workbook,
  Worksheet,
} from '@grapecity/spread-sheets-react';
import { configService } from 'labstep-web/services/config.service';
import Spreadsheet from 'labstep-web/core/Spreadsheet';
import SpreadsheetInline from 'labstep-web/core/Spreadsheet/Inline';
import CustomCells from 'labstep-web/core/Spreadsheet/CustomCells';

import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css';

// Initialise SpreadJS LicenseKey
if (configService && configService.spreadJSLicenseKey) {
  GC.Spread.Sheets.LicenseKey = configService.spreadJSLicenseKey;
}

export {
  GC,
  Workbook,
  Worksheet,
  Column,
  Spreadsheet,
  SpreadsheetInline,
  CustomCells,
};
