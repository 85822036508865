/**
 * Labstep
 *
 * @module models/share-link
 * @desc Typescript export class for ShareLink
 */

import { Type } from 'class-transformer';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Group } from 'labstep-web/models/group.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { Resource } from 'labstep-web/models/resource.model';
import { User } from 'labstep-web/models/user.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { Entity } from 'labstep-web/models/entity.model';
import { Device } from './device.model';
import { Organization } from './organization.model';

// eslint-disable-next-line no-shadow
export enum ShareLinkType {
  view = 'view',
  edit = 'edit',
}
export class ShareLink extends Entity {
  static readonly entityName = 'share_link';

  get entityName(): string {
    return ShareLink.entityName;
  }

  constructor(data: Partial<ShareLink> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  token!: string;

  @Type(() => User)
  author!: User;

  @Type(() => ExperimentWorkflow)
  experiment_workflow!: ExperimentWorkflow;

  @Type(() => Group)
  group!: Group;

  @Type(() => ProtocolCollection)
  protocol_collection!: ProtocolCollection;

  @Type(() => Resource)
  resource!: Resource;

  @Type(() => Organization)
  organization!: Organization;

  @Type(() => Device)
  device!: Device;

  @Type(() => OrderRequest)
  order_request!: OrderRequest;

  get entity():
    | ExperimentWorkflow
    | Device
    | ProtocolCollection
    | Resource
    | Organization
    | Group
    | OrderRequest {
    return (
      this.experiment_workflow ||
      this.device ||
      this.protocol_collection ||
      this.resource ||
      this.group ||
      this.organization ||
      this.order_request
    );
  }

  type!: ShareLinkType;
}
