/**
 * Labstep
 *
 * @module state/services/schema
 * @desc Combine all redux actions using normalizr
 */

/* tslint:disable:prefer-array-literal */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { schema } from 'labstep-web/state/normalizr';
import * as models from 'labstep-web/models';

// AccessKey
export const accessKey: schema.Entity = new schema.Entity(
  'access_key',
);
accessKey.EntityClass = models.AccessKey;
export const accessKeys: schema.Array = new schema.Array(accessKey);
// Chemical
export const chemical: schema.Entity = new schema.Entity(
  'chemical',
  {},
  { idAttribute: models.Chemical.idAttr },
);
chemical.EntityClass = models.Chemical;
export const chemicals: schema.Array = new schema.Array(chemical);
// Custom Identifier Set
export const customIdentifierSet: schema.Entity = new schema.Entity(
  'custom_identifier_set',
);
customIdentifierSet.EntityClass = models.CustomIdentifierSet;
export const customIdentifierSets: schema.Array = new schema.Array(
  customIdentifierSet,
);
// DataTable
export const dataTable: schema.Entity = new schema.Entity(
  'data_table',
);
dataTable.EntityClass = models.DataTable;
export const dataTables: schema.Array = new schema.Array(dataTable);
// RichText
export const richText: schema.Entity = new schema.Entity('rich_text');
richText.EntityClass = models.RichText;
export const richTexts: schema.Array = new schema.Array(richText);
// Molecule
export const molecule: schema.Entity = new schema.Entity(
  'molecule',
  {},
  { idAttribute: models.Molecule.idAttr },
);
molecule.EntityClass = models.Molecule;
export const molecules: schema.Array = new schema.Array(molecule);
// Comments
export const comment: schema.Entity = new schema.Entity('comment');
comment.EntityClass = models.Comment;
export const comments: schema.Array = new schema.Array(comment);
// Experiments
export const experiment: schema.Entity = new schema.Entity(
  'experiment',
);
experiment.EntityClass = models.Experiment;
export const experiments: schema.Array = new schema.Array(experiment);
// Experiments
export const experimentWorkflow: schema.Entity = new schema.Entity(
  'experiment_workflow',
);
experimentWorkflow.EntityClass = models.ExperimentWorkflow;
export const experimentWorkflows: schema.Array = new schema.Array(
  experimentWorkflow,
);
// EntityImport
export const entityImport: schema.Entity = new schema.Entity(
  'entity_import',
  {},
  { idAttribute: models.EntityImport.idAttr },
);
entityImport.EntityClass = models.EntityImport;
export const entityImports: schema.Array = new schema.Array(
  entityImport,
);
// EntityExport
export const entityExport: schema.Entity = new schema.Entity(
  'entity_export',
);
entityExport.EntityClass = models.EntityExport;
export const entityExports: schema.Array = new schema.Array(
  entityExport,
);
// Entity User
export const entityUser: schema.Entity = new schema.Entity(
  'entity_user',
);
entityUser.EntityClass = models.EntityUser;
export const entityUsers: schema.Array = new schema.Array(entityUser);
// Entity View
export const entityView: schema.Entity = new schema.Entity(
  'entity_view',
  {},
  { idAttribute: models.EntityView.idAttr },
);
entityView.EntityClass = models.EntityView;
export const entityViews: schema.Array = new schema.Array(entityView);
// Files
export const file: schema.Entity = new schema.Entity('file');
file.EntityClass = models.File;
export const files: schema.Array = new schema.Array(file);
// Folders
export const folder: schema.Entity = new schema.Entity('folder');
folder.EntityClass = models.Folder;
export const folders: schema.Array = new schema.Array(folder);
// Groups
export const group: schema.Entity = new schema.Entity('group');
group.EntityClass = models.Group;
export const groups: schema.Array = new schema.Array(group);
// GroupAutoSharing
export const groupAutoSharing: schema.Entity = new schema.Entity(
  'group_auto_sharing',
  {},
  { idAttribute: models.GroupAutoSharing.idAttr },
);
groupAutoSharing.EntityClass = models.GroupAutoSharing;
export const groupAutoSharings: schema.Array = new schema.Array(
  groupAutoSharing,
);
// Device
export const device: schema.Entity = new schema.Entity('device');
device.EntityClass = models.Device;
export const devices: schema.Array = new schema.Array(device);
// Device Data
export const deviceData: schema.Entity = new schema.Entity(
  'device_data',
);
deviceData.EntityClass = models.DeviceData;
export const deviceDatas: schema.Array = new schema.Array(deviceData);
// Device Booking
export const deviceBooking: schema.Entity = new schema.Entity(
  'device_booking',
);
deviceBooking.EntityClass = models.DeviceBooking;
export const deviceBookings: schema.Array = new schema.Array(
  deviceBooking,
);
// Experiment Workflow Link
export const experimentWorkflowLink: schema.Entity =
  new schema.Entity(
    'experiment_workflow_link',
    {},
    { idAttribute: models.ExperimentWorkflowLink.idAttr },
  );
experimentWorkflowLink.EntityClass = models.ExperimentWorkflowLink;
export const experimentWorkflowLinks: schema.Array = new schema.Array(
  experimentWorkflowLink,
);
// Jupyter Instance
export const jupyterInstance: schema.Entity = new schema.Entity(
  'jupyter_instance',
  {},
  { idAttribute: models.JupyterInstance.idAttr },
);
jupyterInstance.EntityClass = models.JupyterInstance;
export const jupyterInstances: schema.Array = new schema.Array(
  jupyterInstance,
);
// Jupyter Notebook
export const jupyterNotebook: schema.Entity = new schema.Entity(
  'jupyter_notebook',
  {},
  { idAttribute: models.JupyterNotebook.idAttr },
);
jupyterNotebook.EntityClass = models.JupyterNotebook;
export const jupyterNotebooks: schema.Array = new schema.Array(
  jupyterNotebook,
);
// Log
export const log: schema.Entity = new schema.Entity('log');
log.EntityClass = models.Log;
export const logs: schema.Array = new schema.Array(log);
// Notifications
export const notification: schema.Entity = new schema.Entity(
  'notification',
);
notification.EntityClass = models.Notification;
export const notifications: schema.Array = new schema.Array(
  notification,
);
// NotificationAlert
export const notificationAlert: schema.Entity = new schema.Entity(
  'notification_alert',
  {},
  { idAttribute: models.NotificationAlert.idAttr },
);
notificationAlert.EntityClass = models.NotificationAlert;
export const notificationAlerts: schema.Array = new schema.Array(
  notificationAlert,
);
// Order
export const purchaseOrder: schema.Entity = new schema.Entity(
  'purchase_order',
);
purchaseOrder.EntityClass = models.PurchaseOrder;
export const purchaseOrders: schema.Array = new schema.Array(
  purchaseOrder,
);
// Order Request
export const orderRequest: schema.Entity = new schema.Entity(
  'order_request',
);
orderRequest.EntityClass = models.OrderRequest;
export const orderRequests: schema.Array = new schema.Array(
  orderRequest,
);
// Organization
export const organization: schema.Entity = new schema.Entity(
  'organization',
);
organization.EntityClass = models.Organization;
export const organizations: schema.Array = new schema.Array(
  organization,
);
// OrganizationInvoice
export const organizationInvoice: schema.Entity = new schema.Entity(
  'organization_invoice',
  {},
  { idAttribute: models.OrganizationInvoice.idAttr },
);
organizationInvoice.EntityClass = models.OrganizationInvoice;
export const organizationInvoices: schema.Array = new schema.Array(
  organizationInvoice,
);
// OrganizationPlan
export const organizationPlan: schema.Entity = new schema.Entity(
  'organization_plan',
);
organizationPlan.EntityClass = models.OrganizationPlan;
export const organizationPlans: schema.Array = new schema.Array(
  organizationPlan,
);
// OrganizationPlanPrice
export const organizationPlanPrice: schema.Entity = new schema.Entity(
  'organization_plan_price',
);
organizationPlan.EntityClass = models.OrganizationPlan;
export const organizationPlanPrices: schema.Array = new schema.Array(
  organizationPlanPrice,
);
// OrganizationSAML
export const organizationSaml: schema.Entity = new schema.Entity(
  'organization_saml',
  {},
  { idAttribute: models.OrganizationSAML.idAttr },
);
organizationSaml.EntityClass = models.OrganizationSAML;
// OutboundOrderRequest
export const outboundOrderRequest: schema.Entity = new schema.Entity(
  'outbound_order_request',
);
outboundOrderRequest.EntityClass = models.OutboundOrderRequest;
export const outboundOrderRequests: schema.Array = new schema.Array(
  outboundOrderRequest,
);
// OutboundPurchaseOrder
export const outboundPurchaseOrder: schema.Entity = new schema.Entity(
  'outbound_purchase_order',
);
outboundPurchaseOrder.EntityClass = models.OutboundPurchaseOrder;
export const outboundPurchaseOrders: schema.Array = new schema.Array(
  outboundPurchaseOrder,
);
// PermaLink
export const permaLink: schema.Entity = new schema.Entity(
  'perma_link',
  {},
  { idAttribute: models.PermaLink.idAttr },
);
permaLink.EntityClass = models.PermaLink;
export const permaLinks: schema.Array = new schema.Array(permaLink);
// Permission
export const permission: schema.Entity = new schema.Entity(
  'permission',
  {},
  { idAttribute: models.Permission.idAttr },
);
permission.EntityClass = models.Permission;
export const permissions: schema.Array = new schema.Array(permission);
// PermissionRole
export const permissionRole: schema.Entity = new schema.Entity(
  'permission_role',
  {},
  { idAttribute: models.PermissionRole.idAttr },
);
permissionRole.EntityClass = models.PermissionRole;
export const permissionRoles: schema.Array = new schema.Array(
  permissionRole,
);
// PermissionRoleSetting
export const permissionRoleSetting: schema.Entity = new schema.Entity(
  'permission_role_setting',
  {},
  { idAttribute: models.PermissionRoleSetting.idAttr },
);
permissionRoleSetting.EntityClass = models.PermissionRoleSetting;
export const permissionRoleSettings: schema.Array = new schema.Array(
  permissionRoleSetting,
);
// Profiles
export const profile: schema.Entity = new schema.Entity('profile');
profile.EntityClass = models.Profile;
export const profiles: schema.Array = new schema.Array(profile);
// Protocols
export const protocol: schema.Entity = new schema.Entity('protocol');
protocol.EntityClass = models.Protocol;
export const protocols: schema.Array = new schema.Array(protocol);
// Protocol Collections
export const protocolCollection: schema.Entity = new schema.Entity(
  'protocol_collection',
);
protocolCollection.EntityClass = models.ProtocolCollection;
export const protocolCollections: schema.Array = new schema.Array(
  protocolCollection,
);
// ProtocolTables
export const protocolTable: schema.Entity = new schema.Entity(
  'protocol_table',
  {},
  { idAttribute: models.ProtocolTable.idAttr },
);
protocolTable.EntityClass = models.ProtocolTable;
export const protocolTables: schema.Array = new schema.Array(
  protocolTable,
);
// Protocol Timers
export const protocolTimer: schema.Entity = new schema.Entity(
  'protocol_timer',
  {},
  { idAttribute: models.ProtocolTimer.idAttr },
);
protocolTimer.EntityClass = models.ProtocolTimer;
export const protocolTimers: schema.Array = new schema.Array(
  protocolTimer,
);
// Protocol Values
export const protocolValue: schema.Entity = new schema.Entity(
  'protocol_value',
  {},
  { idAttribute: models.ProtocolValue.idAttr },
);
protocolValue.EntityClass = models.ProtocolValue;
export const protocolValues: schema.Array = new schema.Array(
  protocolValue,
);
// Protocol Steps
export const protocolStep: schema.Entity = new schema.Entity(
  'protocol_step',
  {},
  { idAttribute: models.ProtocolStep.idAttr },
);
protocolStep.EntityClass = models.ProtocolStep;
export const protocolSteps: schema.Array = new schema.Array(
  protocolStep,
);
// Protocol Devices
export const protocolDevice: schema.Entity = new schema.Entity(
  'protocol_device',
  {},
  { idAttribute: models.ProtocolDevice.idAttr },
);
protocolDevice.EntityClass = models.ProtocolDevice;
export const protocolDevices: schema.Array = new schema.Array(
  protocolDevice,
);
// Protocol Condition
export const protocolCondition: schema.Entity = new schema.Entity(
  'protocol_condition',
  {},
  { idAttribute: models.ProtocolCondition.idAttr },
);
protocolCondition.EntityClass = models.ProtocolCondition;
export const protocolConditions: schema.Array = new schema.Array(
  protocolCondition,
);
// Resource
export const resource: schema.Entity = new schema.Entity('resource');
resource.EntityClass = models.Resource;
export const resources: schema.Array = new schema.Array(resource);
// Metadata
export const metadata: schema.Entity = new schema.Entity('metadata');
metadata.EntityClass = models.Metadata;
export const metadatas: schema.Array = new schema.Array(metadata);
// Metadata
export const metadataThread: schema.Entity = new schema.Entity(
  'metadata_thread',
);
metadataThread.EntityClass = models.MetadataThread;
export const metadataThreads: schema.Array = new schema.Array(
  metadataThread,
);
// Resource Location
export const resourceLocation: schema.Entity = new schema.Entity(
  'resource_location',
  {},
  { idAttribute: models.ResourceLocation.idAttr },
);
resourceLocation.EntityClass = models.ResourceLocation;
export const resourceLocations: schema.Array = new schema.Array(
  resourceLocation,
);
// Resource Item
export const resourceItem: schema.Entity = new schema.Entity(
  'resource_item',
);
resourceItem.EntityClass = models.ResourceItem;
export const resourceItems: schema.Array = new schema.Array(
  resourceItem,
);
// Research Areas
export const researchArea: schema.Entity = new schema.Entity(
  'research_area',
);
researchArea.EntityClass = models.ResearchArea;
export const researchAreas: schema.Array = new schema.Array(
  researchArea,
);
// Review Thread
export const reviewThread: schema.Entity = new schema.Entity(
  'review_thread',
);
reviewThread.EntityClass = models.ReviewThread;
export const reviewThreads: schema.Array = new schema.Array(
  reviewThread,
);
// Review
export const review: schema.Entity = new schema.Entity('review');
review.EntityClass = models.Review;
export const reviews: schema.Array = new schema.Array(review);
// Sequence
export const sequence: schema.Entity = new schema.Entity('sequence');
sequence.EntityClass = models.Sequence;
export const sequences: schema.Array = new schema.Array(sequence);
// SecurityLog
export const securityLog: schema.Entity = new schema.Entity(
  'security_log',
);
securityLog.EntityClass = models.SecurityLog;
export const securityLogs: schema.Array = new schema.Array(
  securityLog,
);
// ShareLink
export const shareLink: schema.Entity = new schema.Entity(
  'share_link',
);
shareLink.EntityClass = models.ShareLink;
export const shareLinks: schema.Array = new schema.Array(shareLink);
// ShareLink
export const shareLinkInvitation: schema.Entity = new schema.Entity(
  'share_link_invitation',
);
shareLinkInvitation.EntityClass = models.ShareLinkInvitation;
export const shareLinkInvitations: schema.Array = new schema.Array(
  shareLinkInvitation,
);
// Signature
export const signature: schema.Entity = new schema.Entity(
  'signature',
);
signature.EntityClass = models.Signature;
export const signatures: schema.Array = new schema.Array(signature);
// Signature Request
export const signatureRequest: schema.Entity = new schema.Entity(
  'signature_request',
);
signatureRequest.EntityClass = models.SignatureRequest;
export const signatureRequests: schema.Array = new schema.Array(
  signatureRequest,
);
// Threads
export const tag: schema.Entity = new schema.Entity('tag');
tag.EntityClass = models.Tag;
export const tags: schema.Array = new schema.Array(tag);
// Threads
export const thread: schema.Entity = new schema.Entity('thread');
thread.EntityClass = models.Thread;
export const threads: schema.Array = new schema.Array(thread);
// UserGroup
export const userGroup: schema.Entity = new schema.Entity(
  'user_group',
);
userGroup.EntityClass = models.UserGroup;
export const userGroups: schema.Array = new schema.Array(userGroup);
// UserGroupNotification
export const userGroupNotification: schema.Entity = new schema.Entity(
  'user_group_notification',
);
userGroupNotification.EntityClass = models.UserGroupNotification;
export const userGroupNotifications: schema.Array = new schema.Array(
  userGroupNotification,
);
// UserOrganization
export const userOrganization: schema.Entity = new schema.Entity(
  'user_organization',
);
userOrganization.EntityClass = models.UserOrganization;
export const userOrganizations: schema.Array = new schema.Array(
  userOrganization,
);
// Users
export const user: schema.Entity = new schema.Entity('user');
user.EntityClass = models.User;
export const users: schema.Array = new schema.Array(user);
// UserMention
export const userMention: schema.Entity = new schema.Entity(
  'user_mention',
);
export const userMentions: schema.Array = new schema.Array(
  userMention,
);
userMention.EntityClass = models.UserMention;
// UserShareLink
export const userShareLink: schema.Entity = new schema.Entity(
  'user_share_link',
);
export const userShareLinks: schema.Array = new schema.Array(
  userShareLink,
);
userShareLink.EntityClass = models.UserShareLink;

/* DEFINITIONS */
chemical.define({
  protocol_value: protocolValue,
  molecule,
});
comment.define({
  author: user,
  files,
  parent_thread: thread,
  thread,
  data_table: dataTable,
  rich_text: richText,
  molecule,
  resource,
  sequence,
  user_mentions: userMentions,
});
customIdentifierSet.define({
  group,
});
// Experiment
experiment.define({
  thread,
  protocol_steps: protocolSteps,
  protocol_values: protocolValues,
  protocol_tables: protocolTables,
  protocol_timers: protocolTimers,
  jupyter_notebooks: jupyterNotebooks,
  protocol_devices: protocolDevices,
  molecules,
  protocol,
  author: user,
  files,
  experiment_workflow: experimentWorkflow,
  metadata_thread: metadataThread,
  perma_link: permaLink,
});
// Experiment workflow
experimentWorkflow.define({
  assignees: users,
  owner: group,
  signatures,
  thread,
  author: user,
  experiments,
  tags,
  share_link: shareLink,
  metadata_thread: metadataThread,
  root_experiment: experiment,
  entity_users_preview: entityUsers,
  perma_link: permaLink,
  forward_links: experimentWorkflowLinks,
  back_links: experimentWorkflowLinks,
  locked_log: log,
});
// Experiment Workflow Link
experimentWorkflowLink.define({
  src: experimentWorkflow,
  dest: experimentWorkflow,
});
// File
file.define({
  author: user,
  snapgene_output_png: file,
  snapgene_output_svg: file,
  image_annotation: file,
  annotated_file: file,
});
// Group
group.define({
  author: user,
  protocol_collections: protocolCollections,
  profile,
  organization,
  thread,
  user_groups: userGroups,
  logged_user_user_group: userGroup,
  default_experiment_workflow_template: experimentWorkflow,
  default_order_request_template: orderRequest,
  experiment_workflow_custom_identifier_set: customIdentifierSet,
  default_user_group_permission_role: permissionRole,
});
// GroupAutoSharing
groupAutoSharing.define({
  source_group: group,
  target_group: group,
});
// Device
device.define({
  template: device,
  owner: group,
  tags,
  author: user,
  share_link: shareLink,
  metadata_thread: metadataThread,
  image: file,
  thread,
  entity_users_preview: entityUsers,
  perma_link: permaLink,
});
deviceData.define({
  device,
  files,
});
// Device Booking
deviceBooking.define({
  device,
  author: user,
});
entityUser.define({
  user,
  experiment_workflow: experimentWorkflow,
  protocol_collection: protocolCollection,
  resource,
  resource_item: resourceItem,
  resource_location: resourceLocation,
  order_request: orderRequest,
  purchase_order: purchaseOrder,
  device,
});
// EntityImport
entityImport.define({
  device_template: device,
  resource_template: resource,
});
// Jupyter Notebook
jupyterNotebook.define({
  experiment,
  jupyter_instance_edit: jupyterInstance,
  jupyter_instance_run: jupyterInstance,
});
// Log
log.define({
  author: user,
});
// Metadata
metadata.define({
  template: metadata,
  author: user,
  files,
  resource,
  molecule,
  thread,
  metadata_thread: metadataThread,
  sequence,
  device_data: deviceData,
  protocol_device: protocolDevice,
  notification_alert: notificationAlert,
  variable_template: metadata,
});

// eslint-disable-next-line no-underscore-dangle
metadata._processStrategy = (
  entity: Record<string, unknown>,
): Record<string, unknown> => {
  if (entity.template) {
    return entity;
  }
  return {
    ...entity,
    template: entity.template_id,
  };
};

// MetadataThread
metadataThread.define({
  experiment_workflow: experimentWorkflow,
  metadatas,
  metadatas_preview: metadatas,
  experiment,
  protocol,
});
// Molecule
molecule.define({
  comment,
  experiment,
  metadata,
  protocol,
  chemicals,
  limiting_chemical: chemical,
});
resourceLocation.define({
  author: user,
  owner: group,
  outer_location: resourceLocation,
  thread,
  image: file,
  metadata_thread: metadataThread,
  entity_users_preview: entityUsers,
  perma_link: permaLink,
});
// Notification
notification.define({
  author: user,
});

notificationAlert.define({
  metadata,
});
purchaseOrder.define({
  outbound_purchase_order: outboundPurchaseOrder,
  owner: group,
  author: user,
  thread,
  share_link: shareLink,
  metadata_thread: metadataThread,
  order_requests: orderRequests,
  pending_log: log,
  completed_log: log,
  entity_users_preview: entityUsers,
  perma_link: permaLink,
});
organization.define({
  author: user,
  logged_user_user_organization: userOrganization,
  users,
  permission_roles: permissionRoles,
  organization_saml: organizationSaml,
});
organizationSaml.define({
  organization,
});
orderRequest.define({
  outbound_order_request: outboundOrderRequest,
  owner: group,
  author: user,
  resource,
  thread,
  share_link: shareLink,
  metadata_thread: metadataThread,
  purchase_order: purchaseOrder,
  locked_log: log,
  ordered_log: log,
  approved_log: log,
  back_ordered_log: log,
  received_log: log,
  partially_received_log: log,
  cancelled_log: log,
  entity_users_preview: entityUsers,
  perma_link: permaLink,
  template: orderRequest,
});
outboundPurchaseOrder.define({
  purchase_order: purchaseOrder,
});
outboundOrderRequest.define({
  order_request: orderRequest,
});
// Permission
permission.define({
  group,
});
// PermissionRole
permissionRole.define({
  organization,
  permission_role_settings: permissionRoleSettings,
});
// PermissionRoleSetting
permissionRoleSetting.define({
  permission_role: permissionRole,
});
// Profile
profile.define({
  research_areas: researchAreas,
  photo: file,
});
// Protocol
protocol.define({
  author: user,
  protocol_steps: protocolSteps,
  protocol_timers: protocolTimers,
  protocol_values: protocolValues,
  protocol_tables: protocolTables,
  protocol_devices: protocolDevices,
  files,
  experiments,
  collection: protocolCollection,
  children_protocol_collections: protocolCollections,
  protocol_collection_draft: protocol,
  metadata_thread: metadataThread,
  thread,
  perma_link: permaLink,
  molecules,
});
// Protocol Condition
protocolCondition.define({
  experiment,
  protocol,
  metadatas,
  protocol_values: protocolValues,
});
// Protocol Collection
protocolCollection.define({
  owner: group,
  tags,
  author: user,
  versions: protocols,
  last_version: protocol,
  parent_protocol: protocol,
  draft: protocol,
  share_link: shareLink,
  thread,
  entity_users_preview: entityUsers,
  perma_link: permaLink,
});
// Protocol Step
protocolStep.define({
  protocol_tables: protocolTables,
  protocol_values: protocolValues,
  protocol_timers: protocolTimers,
  files,
  protocol,
  thread,
});
protocolTable.define({
  protocol_step: protocolStep,
});
protocolTimer.define({
  protocol,
  protocol_step: protocolStep,
});
// Protocol Value
protocolValue.define({
  resource,
  protocol_step: protocolStep,
  protocol,
  experiment,
  resource_template: resource,
  resource_item: resourceItem,
  resource_item_output: resourceItem,
  amount_deducted_log: log,
  variable_template: protocolValue,
});
// Protocol Device
protocolDevice.define({
  device,
  protocol,
  experiment,
  metadatas,
});
// Resource
resource.define({
  metadatas_with_template: metadatas,
  owner: group,
  author: user,
  provider: user,
  perma_link: permaLink,
  thread,
  share_link: shareLink,
  image: file,
  review_thread: reviewThread,
  template: resource,
  metadata_thread: metadataThread,
  order_request: orderRequest,
  resource_item_template: resourceItem,
  order_request_template: orderRequest,
  entity_users_preview: entityUsers,
  molecule,
});
resourceItem.define({
  author: user,
  perma_link: permaLink,
  thread,
  image: file,
  resource_location: resourceLocation,
  resource,
  metadata_thread: metadataThread,
  order_request: orderRequest,
  entity_users_preview: entityUsers,
  protocol_value_origin: protocolValue,
});
reviewThread.define({
  reviews,
});
review.define({
  author: user,
  review_thread: reviewThread,
});
// ShareLink
shareLink.define({
  organization,
  author: user,
  experiment_workflow: experimentWorkflow,
  group,
  protocol_collection: protocolCollection,
  resource,
  order_request: orderRequest,
  device,
});
shareLinkInvitation.define({
  invited_user: user,
  share_link: shareLink,
});
signature.define({
  author: user,
  experiment_workflow: experimentWorkflow,
});
signatureRequest.define({
  author: user,
  experiment_workflow: experimentWorkflow,
  user,
});
// Tag
tag.define({
  author: user,
});
// Thread
thread.define({
  experiment,
  protocol,
  protocol_step: protocolStep,
  experiment_workflow: experimentWorkflow,
  metadata,
  comments,
});
// User
user.define({
  organization,
  group,
  profile,
  thread,
  user_groups: userGroups,
  user_organizations: userOrganizations,
  metadata_thread: metadataThread,
});
// UserMention
userMention.define({
  user,
});
// UserGroup
userGroup.define({
  user,
  group,
  permission_role: permissionRole,
});
// UserOrganization
userOrganization.define({
  user,
  organization,
});
userGroupNotification.define({
  user_group: userGroup,
});
// UserShareLink
userShareLink.define({
  user,
  share_link: shareLink,
});
