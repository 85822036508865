/**
 * Labstep
 *
 * @module components/OrderRequest/ResourceItemCount
 * @desc
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import Flex from 'labstep-web/core/Flex';
import { OrderRequestStatus } from 'labstep-web/models';
import { IOrderRequestResourceItemCountProps } from './types';

export const OrderRequestResourceItemCount: React.FC<
  IOrderRequestResourceItemCountProps
> = ({ orderRequest }) => {
  if (
    orderRequest.status !== OrderRequestStatus.partially_received &&
    orderRequest.status !== OrderRequestStatus.received
  ) {
    return null;
  }
  return (
    <Flex>
      {orderRequest.resource_item_count >= orderRequest.quantity ? (
        <Icon name="check circle" color="green" />
      ) : (
        <Icon name="warning sign" color="yellow" />
      )}
      <p>{`${orderRequest.resource_item_count} / ${orderRequest.quantity} items added to inventory`}</p>
    </Flex>
  );
};
export default OrderRequestResourceItemCount;
