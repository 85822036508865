/**
 * Labstep
 *
 * @module components/SignatureRequest/Action/Create/Bulk
 * @desc Bulk Create Action for signature request
 */

import React from 'react';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import ModalDefault from 'labstep-web/core/Modal/Default';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import GuardGroupCreate from 'labstep-web/components/Guard/GroupCreate';
import SignatureRequestFormCreateBulk from 'labstep-web/components/SignatureRequest/Form/Create/Bulk';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ICONS } from 'labstep-web/constants';
import { ExperimentWorkflow } from 'labstep-web/models';
import { ISignatureRequestActionCreateBulkProps } from './types';

const SignatureRequestActionCreateBulk: React.FC<
  ISignatureRequestActionCreateBulkProps
> = ({ entities, options }) => {
  const entityIdsAccessObject = useHasAccessMultiple(
    ExperimentWorkflow.entityName,
    entities ? entities.map((e) => e.id) : [],
    Action.edit,
  );

  const entitiesWithPermission = entities
    ? entities.filter((e) => entityIdsAccessObject[e.id])
    : [];

  if (
    !entities ||
    entities.length === 0 ||
    entitiesWithPermission.length === 0
  ) {
    return null;
  }

  return (
    <GuardGroupCreate>
      <ModalDefault
        header="Request Signature"
        content={({ toggleModal }) => (
          <SignatureRequestFormCreateBulk
            experimentWorkflows={entitiesWithPermission}
            options={enhanceOptions({
              options,
              onSuccess: toggleModal,
            })}
          />
        )}
        viewComponent={({ toggleModal }) => (
          <BulkActionComponent
            entitiesWithPermissionCount={
              entitiesWithPermission.length
            }
            entitiesSelectedCount={entities.length}
            onClick={toggleModal}
            content="Request Signature"
            icon={ICONS.signature_request.primary}
          />
        )}
      />
    </GuardGroupCreate>
  );
};

export default SignatureRequestActionCreateBulk;
