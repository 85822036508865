/**
 * Labstep
 *
 * @module components/TimeMachine/Document
 * @desc Component loading experiment with timestamp
 */

import CommentItem from 'labstep-web/components/Comment/Item';
import TableCard from 'labstep-web/components/Table/Card';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import {
  Comment,
  Experiment,
  Protocol,
  ProtocolTable,
} from 'labstep-web/models';
import ProseMirrorReadOnly from 'labstep-web/prosemirror/ReadOnly';
import React from 'react';
import { ITimeMachineComponentProps } from './types';

const TimeMachineComponent: React.FC<ITimeMachineComponentProps> = ({
  entityName,
  timestamp,
  id,
}) => (
  <ReadOnMountHOC
    type="entities"
    entityName={entityName}
    params={{
      id,
      at: timestamp,
      get_single: 1,
    }}
    loading={{ loader: 'main_content', cached: true }}
    children={({ entity: entityAtTimeStamp }) => {
      switch (entityName) {
        case Protocol.entityName:
        case Experiment.entityName:
          return (
            <ProseMirrorReadOnly
              initialState={entityAtTimeStamp.state}
              entity={entityAtTimeStamp}
              timestamp={timestamp}
            />
          );
        case ProtocolTable.entityName:
          return (
            <TableCard
              name={entityAtTimeStamp.name}
              data={entityAtTimeStamp.data}
              debouncingStatus={{ isFetching: false }}
              onChange={() => {}}
              onError={() => {}}
              readOnly
            />
          );
        case Comment.entityName:
          return <CommentItem comment={entityAtTimeStamp} readOnly />;
        default:
          return null;
      }
    }}
  />
);

export default TimeMachineComponent;
