/**
 * Labstep
 *
 * @module components/Modal/Edit
 * @desc Edit modal for experiment and protocol elements
 */

import React from 'react';
import { Redirect } from 'react-router-dom';
import MoleculeActionEdit from 'labstep-web/components/Molecule/Action/Edit';
import ProtocolChildRouter from 'labstep-web/containers/Router/ProtocolChild';
import {
  EntityReadEntityContainer,
  withActiveEditModal,
} from 'labstep-web/containers';
import {
  Metadata,
  Molecule,
  ProtocolValue,
} from 'labstep-web/models';
import { IModalEditProps } from './types';

export const ModalEdit: React.FC<IModalEditProps> = ({
  activeEditModal,
  setActiveEditModal,
  parent,
}) => {
  if (!activeEditModal) {
    return null;
  }

  let modal = null;
  switch (activeEditModal.entityName) {
    case ProtocolValue.entityName: {
      setActiveEditModal(null);
      return (
        <ProtocolChildRouter
          route="value_show"
          id={activeEditModal.id}
        >
          {({ to }) => (
            <Redirect
              to={{ pathname: to, state: { expanded: true } }}
            />
          )}
        </ProtocolChildRouter>
      );
    }
    case Metadata.entityName: {
      setActiveEditModal(null);
      return (
        <ProtocolChildRouter
          route="metadata_show"
          id={activeEditModal.id}
        >
          {({ to }) => (
            <Redirect
              to={{ pathname: to, state: { expanded: true } }}
            />
          )}
        </ProtocolChildRouter>
      );
    }

    case Molecule.entityName:
      modal = (entity: Molecule) => (
        <MoleculeActionEdit
          molecule={entity}
          canEdit
          viewComponent={null}
          onDone={() => setActiveEditModal(null)}
          open
          parent={parent}
        />
      );
      break;
    default:
      break;
  }

  return modal ? (
    <EntityReadEntityContainer
      entityName={activeEditModal.entityName}
      id={activeEditModal.id}
    >
      {({ entity }) => modal(entity)}
    </EntityReadEntityContainer>
  ) : null;
};

export default withActiveEditModal(ModalEdit);
