/* eslint-disable no-param-reassign */
/**
 * Labstep
 */

import { Metadata, Molecule, Sequence } from 'labstep-web/models';
import { METADATA_FIELDS } from 'labstep-web/models/metadata/constants';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { IClearMetadataBody } from './types';

/**
 * function to generate payload for PUT request to clear metadata value.
 * @param metadata metadata object on which to clear value for
 * @returns javascript object representing payload for desired PUT clear request.
 */
export const getClearMetadataBody = (
  metadata: Metadata,
): IClearMetadataBody => {
  const { fields } = METADATA_FIELDS[metadata.type];
  let entityName = '';
  let entityId: number = null;
  const body = fields.reduce((result, item) => {
    switch (item) {
      case MetadataType.options: {
        // Handle no options being set.
        if (metadata.hasValue) {
          result = {
            options: metadata.emptyOptions,
          };
        }

        entityName = metadata.entityName;
        entityId = metadata.id;
        break;
      }
      case MetadataType.molecule: {
        result = Molecule.createBodyDefault;
        entityName = metadata.molecule.entityName;
        entityId = metadata.molecule.getId as any;
        break;
      }
      case MetadataType.sequence: {
        result = Sequence.createBodyDefault;
        if (metadata.sequence) {
          entityName = metadata.sequence.entityName;
          entityId = metadata.sequence.id;
        }
        break;
      }
      default:
        result[item] = null;
        entityName = metadata.entityName;
        entityId = metadata.id;
    }
    return result;
  }, {});

  return {
    entityName,
    entityId,
    body,
  };
};
