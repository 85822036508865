/**
 * Labstep
 *
 * @module components/ResearchArea/Item
 * @desc ResearchArea Item
 */

import React from 'react';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { EntityToggleContainer } from 'labstep-web/containers';
import {
  IResearchAreaProps,
  IResearchAreaToggleContainerProps,
} from './types';

export const ResearchArea: React.FC<IResearchAreaProps> = ({
  researchArea,
  onRemoveClick,
  status,
}) => (
  <RemoveLabel
    name={researchArea.label}
    onRemoveClick={onRemoveClick}
    status={status}
  />
);

const ResearchAreaToggleContainer: React.FC<
  IResearchAreaToggleContainerProps
> = ({ profile, researchArea, remove }) => (
  <EntityToggleContainer
    action="remove"
    entityIds={researchArea.id}
    entityName="research_area"
    parentName="profile"
    parentId={profile.id}
  >
    {({ toggle, status }) => (
      <ResearchArea
        onRemoveClick={remove ? () => toggle() : undefined}
        researchArea={researchArea}
        status={status}
      />
    )}
  </EntityToggleContainer>
);

export default ResearchAreaToggleContainer;
