/**
 * Labstep
 *
 * @module screens/Device/Index
 * @desc Device Index
 */

import React, { useMemo } from 'react';
import DeviceListOrTable from 'labstep-web/components/Device/ListOrTable';
import DeviceEmptyState from 'labstep-web/components/Device/Card/EmptyState';
import EntityImportModal from 'labstep-web/components/EntityImport/Modal/Trigger';
import { Device } from 'labstep-web/models';
import MasterIndex from 'labstep-web/screens/Master/Index';
import EntityActionCreateMenu from 'labstep-web/components/Entity/Action/Create/Menu';
import { useParamsContext } from 'labstep-web/hoc/Params/context';
import store from 'labstep-web/state/store';
import { selectEntity } from 'labstep-web/state/selectors';
import columnDefs from 'labstep-web/components/Device/DataGrid/colDefs';
import { IEntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import MetadataDataGridColumnOptions from 'labstep-web/components/Metadata/DataGrid/ColumnOptions';
import {
  IDeviceIndexListOrTableProps,
  IDeviceIndexProps,
} from './types';
import DeviceIndexLeftPane from './LeftPane';
import { bulkActions, filters, sortOptions } from './constants';
import DeviceIndexBreadcrumb from './Breadcrumb';

export const DeviceIndexListOrTable: React.FC<
  IDeviceIndexListOrTableProps
> = ({ entities, ...rest }) => {
  const { searchParams, setParams } = useParamsContext();
  return (
    <DeviceListOrTable
      devices={entities}
      templateId={searchParams.template_id}
      columnDefs={(template) => columnDefs(setParams, template)}
      toolPanelParams={(template) => ({
        extraColumnOptions: template ? (
          <MetadataDataGridColumnOptions entity={template} />
        ) : undefined,
      })}
      {...rest}
    />
  );
};

export const DeviceIndex: React.FC<IDeviceIndexProps> = ({
  group,
}) => {
  const params = useMemo(() => ({ group_id: group.id }), [group.id]);
  const action = ({ searchParams }) => (
    <EntityActionCreateMenu
      entityName={Device.entityName}
      extraOptions={
        <EntityImportModal
          targetEntityName={Device.entityName}
          templateId={searchParams.template_id}
        />
      }
      templateId={searchParams?.template_id}
    />
  );
  const breadcrumb = useMemo(() => <DeviceIndexBreadcrumb />, []);
  const leftPane = useMemo(() => <DeviceIndexLeftPane />, []);
  const noResultsMessage = ({ searchParams }) => (
    <DeviceEmptyState action={action({ searchParams })} />
  );

  const entityView = (searchParams) => {
    const id = searchParams.template_id;
    // TODO: this is a hack to get the guid of the template
    // Will remove once we migrate to guid
    const guid = id
      ? selectEntity(store.getState(), Device.entityName, id)?.guid
      : undefined;
    return {
      context: 'device_index',
      entityName: Device.entityName,
      parameters: guid ? { device_template_guid: guid } : undefined,
    };
  };

  return (
    <MasterIndex
      action={action}
      bulkActions={bulkActions}
      breadcrumb={breadcrumb}
      entityName={Device.entityName}
      filters={filters}
      leftPane={leftPane}
      noResultsMessage={noResultsMessage}
      params={params}
      tableFormat={false}
      sortOptions={sortOptions}
      useAllPages
      entityView={entityView}
    >
      {({
        entities,
        readNextPage,
        status,
      }: IEntitySearchContainerChildrenProps) => (
        <DeviceIndexListOrTable
          entities={entities}
          loadMore={readNextPage}
          status={status}
        />
      )}
    </MasterIndex>
  );
};

export default DeviceIndex;
