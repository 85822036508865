/**
 * Labstep
 */

import { PostFilterComparison } from 'labstep-web/hoc/Params/types';
import { PostFilter } from 'labstep-web/services/postFilter';

export const PostFilterResourceTemplate = new PostFilter(
  [['guid', PostFilterComparison.eq]],
  'resource.template',
);
