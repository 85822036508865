/**
 * Labstep
 *
 * @module containers/TransferOwnership
 * @desc Transfer Ownership container
 */

import { connect } from 'react-redux';
import {
  transferOwnershipAction,
  transferOwnershipActionType,
} from 'labstep-web/state/actions';
import { selectApiStatus } from 'labstep-web/state/selectors';
import { Action } from 'labstep-web/models';
import { IOptions, IStatus } from 'labstep-web/typings';
import { LabstepReduxState } from 'labstep-web/state/types';

export type IChildrenProps = {
  transferOwnership: (
    entityName: string,
    entityId: string | number | number[],
    groupId: number,
    options: IOptions,
  ) => void;
  status: IStatus;
};

const Container = ({
  children,
  transferOwnership,
  status,
}: {
  children: (props: IChildrenProps) => JSX.Element;
  transferOwnership: IChildrenProps['transferOwnership'];
  status: IChildrenProps['status'];
}) => children({ transferOwnership, status });

const mapStateToProps = (
  state: LabstepReduxState,
): Pick<IChildrenProps, 'status'> => ({
  status: selectApiStatus(state, transferOwnershipActionType),
});

const mapDispatchToProps = (
  dispatch: (action: Action) => void,
): Pick<IChildrenProps, 'transferOwnership'> => ({
  transferOwnership(entityName, entityId, groupId, options) {
    dispatch(
      transferOwnershipAction(entityName, entityId, groupId, options),
    );
  },
});

export const TransferOwnershipContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
