/**
 * Labstep
 *
 * @module state/reducers/helpers/tree
 * @desc Helpers for reducers for tree entities (resource locations/folders)
 */

import { filterOutEntitiesFromItems } from 'labstep-web/state/reducers/helpers/remove';
import { addEntitiesToItems } from 'labstep-web/state/reducers/helpers/add';

/**
 * Add/Remove an entity to/from a branch.
 *
 * @function
 * @param  {object} state
 * @param  {object} action
 */
export const updateTree = (state, action, childKeyName) => {
  const childKey =
    childKeyName === 'outer_location'
      ? `${childKeyName}_guid`
      : `${childKeyName}_id`;
  const childValue = action.meta.body[childKey];
  const hasChildKey = `has_${childKeyName}`;

  // Delete from old outer location
  const newState = filterOutEntitiesFromItems(
    state,
    action,
    (keyState, params) =>
      params[childKey] || params[hasChildKey] === false,
  );

  // Add to new outer location
  return addEntitiesToItems(
    newState,
    action,
    (keyState, params) =>
      (childValue && params[childKey] === childValue) ||
      (childValue === null && params[hasChildKey] === false),
  );
};

/**
 * Create an entity and append to outer branch
 *
 * @function
 * @param  {object} state
 * @param  {object} action
 */
export const addToTree = (state, action, childKeyName) => {
  const childKey =
    childKeyName === 'outer_location'
      ? `${childKeyName}_guid`
      : `${childKeyName}_id`;
  const childValue =
    action.meta.body[childKey] ||
    (action.meta.body.items && action.meta.body.items[0][childKey]);
  const hasChildKey = `has_${childKeyName}`;
  return addEntitiesToItems(
    state,
    action,
    (keyState, params) =>
      // experiment_workflow vs protocol_collection type
      action.meta.body.type === params.type &&
      ((childValue && params[childKey] === childValue) ||
        (!childValue && params[hasChildKey] === false)),
  );
};
