/**
 * Labstep
 *
 * @module components/Table/Form/ShowEdit/Name
 * @desc ShowEdit for name for resource item
 */

import React from 'react';
import ShowEdit from 'labstep-web/core/Form/ShowEdit/Text';
import { fieldTableName } from 'labstep-web/components/Table/Form/fields';
import { ITableFormShowEditNameProps } from './types';

const TableFormShowEditName: React.FC<
  ITableFormShowEditNameProps
> = ({ table }) => (
  <ShowEdit
    entityName={table.entityName}
    entity={table}
    field={fieldTableName}
  >
    <span>{table.name}</span>
  </ShowEdit>
);

export default TableFormShowEditName;
