import { OrderRequest } from 'labstep-web/models';
import { getMetadataColDefs } from 'labstep-web/components/Entity/DataGrid/colDefs/metadatas';

/**
 * For all metadatas on orderRequest item template, return a column
 * @param orderRequestTemplate OrderRequest template
 * @returns Column definitions
 */
export const getOrderRequestMetadataColDefs = (
  orderRequestTemplate: OrderRequest,
) =>
  getMetadataColDefs(OrderRequest.entityName, orderRequestTemplate);
