/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/JupyterNotebook
 * @desc Renders an JupyterNotebook node
 */

import React from 'react';
import Read from 'labstep-web/prosemirror/components/NodeView/Read';
import JupyterNotebookItem from 'labstep-web/components/JupyterNotebook/Item';
import { useReactNodeView } from 'labstep-web/prosemirror/components/ReactNodeView';
import { JupyterNotebook } from 'labstep-web/models';
import { IJupyterNotebookNodeProps } from './types';

export class JupyterNotebookNode extends React.Component<
  IJupyterNotebookNodeProps,
  any
> {
  shouldComponentUpdate() {
    return Boolean(!this);
  }

  render() {
    const { id, timestamp } = this.props;
    return (
      <Read
        entityName={JupyterNotebook.entityName}
        id={id}
        timestamp={timestamp}
      >
        {({ entity: jupyterNotebook }) => (
          <JupyterNotebookItem jupyterNotebook={jupyterNotebook} />
        )}
      </Read>
    );
  }
}

const JupyterNotebookNodeView = ({
  entity: experiment,
  timestamp,
}) => {
  const context = useReactNodeView();
  const { guid } = context.node.attrs;
  return (
    <JupyterNotebookNode
      experiment={experiment}
      id={guid}
      timestamp={timestamp}
    />
  );
};

export default JupyterNotebookNodeView;
