/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Signed/Active
 * @desc Active filter component for signed
 */

import React from 'react';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { isActive } from 'labstep-web/components/Filter/List/utils';
import { SignedTypes } from 'labstep-web/models';
import { IExperimentWorkflowFilterSignedActiveProps } from './types';

const ExperimentWorkflowFilterSignedActive: React.FC<
  IExperimentWorkflowFilterSignedActiveProps
> = ({ clearParams, searchParams }) => {
  const activeSignedType = Object.values(SignedTypes).find((value) =>
    isActive(searchParams, value.params),
  );

  if (searchParams.signature_request_user_id !== undefined) {
    return (
      <RemoveLabel
        icon="edit"
        name="For me to sign"
        onRemoveClick={
          clearParams &&
          (() => clearParams(['signature_request_user_id']))
        }
      />
    );
  }

  if (activeSignedType) {
    return (
      <RemoveLabel
        icon={activeSignedType.icon}
        name={activeSignedType.text}
        onRemoveClick={
          clearParams &&
          (() => clearParams(Object.keys(activeSignedType.params)))
        }
      />
    );
  }

  return null;
};

export default ExperimentWorkflowFilterSignedActive;
