/**
 * Labstep
 *
 * @module components/Sharelink/Action/Delete
 * @desc Action to delete
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { ISharelinkDeleteActionProps } from './types';

export const SharelinkDeleteAction: React.FC<
  ISharelinkDeleteActionProps
> = ({ shareLink, ...rest }) => (
  <EntityActionDelete {...rest} entity={shareLink} />
);

export default SharelinkDeleteAction;
