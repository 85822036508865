/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Form/Create
 * @desc Create Action for experiment workflow
 */

import React from 'react';
import FormCreate from 'labstep-web/core/Form/Create';
import { withActiveGroup } from 'labstep-web/containers';
import {
  fieldExperimentWorkflowNameWithLabel,
  fieldExperimentWorkflowPlannedAt,
  getFieldExperimentWorkflowTemplate,
} from 'labstep-web/components/ExperimentWorkflow/Form/fields';
import { ExperimentWorkflow } from 'labstep-web/models';
import { IExperimentWorkflowFormCreateProps } from './types';

export const ExperimentWorkflowFormCreate: React.FC<
  IExperimentWorkflowFormCreateProps
> = ({ defaultValues, activeGroup, options, ...rest }) => {
  const fields = [
    fieldExperimentWorkflowNameWithLabel,
    fieldExperimentWorkflowPlannedAt,
    getFieldExperimentWorkflowTemplate(activeGroup.id),
  ];

  return (
    <FormCreate
      entityName={ExperimentWorkflow.entityName}
      defaultValues={defaultValues}
      fields={fields}
      options={options}
      autoFocus
      {...rest}
    />
  );
};

export default withActiveGroup(ExperimentWorkflowFormCreate);
