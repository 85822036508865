/**
 * Labstep
 *
 * @module components/UserGroup/Action/Create
 * @desc Action to create a user group
 */

import React from 'react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import { UserGroup } from 'labstep-web/models';
import { IUserGroupActionCreateProps } from './types';

export const UserGroupActionCreate: React.FC<
  IUserGroupActionCreateProps
> = ({
  group,
  authenticatedUserEntity,
  actionComponentProps,
  redirectRoute,
}) => (
  <EntityActionCreate
    entityName={UserGroup.entityName}
    body={{ user_id: authenticatedUserEntity.id, group_id: group.id }}
    redirectRoute={redirectRoute}
    actionComponentProps={actionComponentProps}
    skipPermissionCheck
  />
);

export default withAuthenticatedUserEntity(UserGroupActionCreate);
