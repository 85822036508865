/**
 * Labstep
 *
 * @module models/review-thread
 * @desc Typescript export class for ReviewThread
 */

import { Entity } from 'labstep-web/models/entity.model';

export class ReviewThread extends Entity {
  static readonly entityName = 'review_thread';

  get entityName(): string {
    return ReviewThread.entityName;
  }

  constructor(data: Partial<ReviewThread> = {}) {
    super();
    Object.assign(this, data);
  }

  id: number;

  reviews_count: number;

  average_rating: number;
}
