/**
 * Labstep
 *
 * @module state/new/prosemirror
 * @desc Selectors/Reducers for Prosemirror
 */

import { combineReducers } from 'redux';
import {
  Action,
  Experiment,
  Log,
  Protocol,
  User,
} from 'labstep-web/models';
import { createSelector } from 'reselect';
import { ProseMirrorStatus } from 'labstep-web/containers/ProseMirror/types';
import { LabstepReduxState } from '../types';

export interface ProseMirrorActiveEntity {
  entityName: string;
  entityId: number;
}

export const prosemirrorSetStatusActionType =
  'PROSEMIRROR_SET_STATUS';

export const prosemirrorForceRefreshActionType =
  'PROSEMIRROR_FORCE_REFRESH';

export const setProseMirrorStatusAction = (
  proseMirrorStatus: ProseMirrorStatus,
): Action => ({
  type: prosemirrorSetStatusActionType,
  meta: {
    proseMirrorStatus,
  },
});

export const forceRefreshProseMirrorAction = (): Action => ({
  type: prosemirrorForceRefreshActionType,
});

export const setProseMirrorStatusConflictAction = (
  updateStateLog: Log,
): Action => ({
  type: prosemirrorSetStatusActionType,
  meta: {
    proseMirrorStatus: ProseMirrorStatus.conflict,
    conflict: {
      author: updateStateLog.author,
      user_agent: updateStateLog.log_entry_client_user_agent,
      date: updateStateLog.ended_at
        ? updateStateLog.ended_at
        : updateStateLog.created_at,
    },
  },
});

export interface ProseMirrorConflict {
  author: User;
  user_agent: string;
  date: string;
}

export const selectProseMirrorActiveEntity = createSelector(
  (state: LabstepReduxState) => state.prosemirror.active_entity,
  (activeEntity: ProseMirrorActiveEntity) => activeEntity,
);

export const selectProseMirrorKey = createSelector(
  (state: LabstepReduxState) => state.prosemirror.key,
  (key: number) => key,
);

export const selectProseMirrorStatus = createSelector(
  (state: LabstepReduxState) => state.prosemirror.proseMirrorStatus,
  (proseMirrorStatus: ProseMirrorStatus) => proseMirrorStatus,
);

export const selectProseMirrorConflict = createSelector(
  (state: LabstepReduxState) => state.prosemirror.conflict,
  (conflict: ProseMirrorConflict) => conflict,
);

/**
 * Store active experiment or protocol
 */
export const activeEntity = (
  state = null,
  action: Action,
): null | ProseMirrorActiveEntity => {
  if (action.type === 'SUCCESS_READ_EXPERIMENT') {
    return {
      entityName: Experiment.entityName,
      entityId: action.payload.result,
    };
  }
  if (action.type === 'SUCCESS_READ_PROTOCOL') {
    return {
      entityName: Protocol.entityName,
      entityId: action.payload.result,
    };
  }

  return state;
};

/**
 * ProseMirror view/edit status.
 */
export const proseMirrorStatus = (
  state = null,
  action: Action,
): string | null => {
  if (action.type === 'PROSEMIRROR_SET_STATUS') {
    return action.meta.proseMirrorStatus;
  }

  return state;
};

/**
 * ProseMirror component key (increment for each state conflict).
 */
export const key = (state = 1, action: Action): number => {
  if (action.type === 'PROSEMIRROR_FORCE_REFRESH') {
    return state + 1;
  }

  return state;
};

/**
 * ProseMirror conflict log.
 */
export const conflict = (
  state = null,
  action: Action,
): ProseMirrorConflict | null => {
  if (
    action.type === 'SUCCESS_READ_EXPERIMENT' ||
    action.type === 'SUCCESS_READ_PROTOCOL' ||
    action.type === 'PROSEMIRROR_FORCE_REFRESH'
  ) {
    return null;
  }
  if (
    action.type === 'PROSEMIRROR_SET_STATUS' &&
    action.meta &&
    action.meta.proseMirrorStatus === ProseMirrorStatus.conflict
  ) {
    return action.meta.conflict;
  }

  return state;
};

export const prosemirrorReducers = combineReducers({
  active_entity: activeEntity,
  proseMirrorStatus,
  key,
  conflict,
});
