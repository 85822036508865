/**
 * Labstep
 *
 * @module screens/Device/Index/Template
 * @desc Device Index Template
 */

import React, { useMemo } from 'react';
import DeviceEmptyStateTemplate from 'labstep-web/components/Device/Card/EmptyState/Template';
import { Device } from 'labstep-web/models';
import MasterIndex from 'labstep-web/screens/Master/Index';
import DeviceActionCreate from 'labstep-web/components/Device/Action/Create';
import DeviceListOrTable from 'labstep-web/components/Device/ListOrTable';
import { IEntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import { colDefDeviceActionMenu } from 'labstep-web/components/Device/DataGrid/colDefs/utils';
import { colDefNameTemplate } from 'labstep-web/components/Entity/DataGrid/colDefs/name/template';
import colDefBulkSelect from 'labstep-web/components/Entity/DataGrid/colDefs/bulkSelect';
import { bulkActionsTemplate } from '../constants';
import { IDeviceIndexTemplateProps } from './types';

export const DeviceIndexTemplate: React.FC<
  IDeviceIndexTemplateProps
> = ({ group }) => {
  const params = useMemo(
    () => ({
      group_id: group.id,
      is_template: true,
    }),
    [group.id],
  );

  const action = useMemo(() => <DeviceActionCreate isTemplate />, []);
  const noResultsMessage = useMemo(
    () => <DeviceEmptyStateTemplate />,
    [],
  );

  const columnDefs = useMemo(
    () => [
      colDefDeviceActionMenu(true),
      colDefBulkSelect,
      colDefNameTemplate(Device.entityName),
    ],
    [],
  );

  return (
    <MasterIndex
      action={action}
      bulkActions={bulkActionsTemplate}
      entityName={Device.entityName}
      noResultsMessage={noResultsMessage}
      params={params}
      tableFormat={false}
    >
      {({
        entities,
        readNextPage,
        status,
      }: IEntitySearchContainerChildrenProps) => (
        <DeviceListOrTable
          devices={entities}
          columnDefs={columnDefs}
          loadMore={readNextPage}
          status={status}
        />
      )}
    </MasterIndex>
  );
};

export default DeviceIndexTemplate;
