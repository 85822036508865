import { ICONS } from 'labstep-web/constants';
import colDefName from '..';
import { colDefTemplateType } from '../../template/types';

export const colDefNameTemplate: colDefTemplateType = (
  entityName,
) => ({
  ...colDefName(entityName),
  headerName: 'Category',
  headerComponentParams: {
    icon: ICONS.template.primary,
  },
});
