/**
 * Labstep
 * @desc Generic Card for Interactive Elements in Protocols / Experiments for use in the Overview side panel.
 */

import React from 'react';
import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import Flex from 'labstep-web/core/Flex';
import Card from '../Card';
import styles from './styles.module.scss';
import { IElementCardProps } from './types';

const ElementCard: React.FC<IElementCardProps> = ({
  name,
  icon,
  right,
  detail,
  actions,
  customIcon,
  onClick,
  dragHandle,
  isLink,
}) => (
  <Card className={styles.elementCard}>
    {dragHandle && (
      <span className={styles.dragHandle}>{dragHandle}</span>
    )}
    <div>
      <div>{customIcon || (icon && <Icon name={icon} />)}</div>
      <Flex
        wrap
        grow
        vAlignContent="center"
        className={styles.elementCardDetail}
      >
        <div
          className={classnames(styles.elementCardName, {
            [styles.clickable]: onClick,
            [styles.link]: isLink,
          })}
          onClick={onClick}
        >
          <b>{name}</b>
        </div>
        <>{detail}</>
      </Flex>
    </div>
    <div className={styles.elementCardRight}>
      {right}
      {actions}
    </div>
  </Card>
);

export default ElementCard;
