/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Info/LockedBy
 * @desc Showing who locked the experiment
 */

import React from 'react';
import { ExperimentWorkflow } from 'labstep-web/models';
import TextError from 'labstep-web/core/Text/Error';
import Icon from 'labstep-web/core/Icon';
import UserShowProfile from 'labstep-web/components/User/Show/Profile';
import { ICONS } from 'labstep-web/constants';
import styles from './styles.module.scss';
import { IExperimentWorkflowInfoLockedByProps } from './types';

const ExperimentWorkflowInfoLockedBy: React.FC<
  IExperimentWorkflowInfoLockedByProps
> = ({ experimentWorkflow }) =>
  experimentWorkflow.locked_at ? (
    <div className={styles.container}>
      <Icon name={ICONS.experiment_workflow.actions.lock} />
      <span>
        {` This ${ExperimentWorkflow.getHumanReadableEntityName()} has been `}
        <TextError>locked</TextError>
        {` by `}
        {experimentWorkflow.locked_log && (
          <UserShowProfile
            user={experimentWorkflow.locked_log.author}
            viewComponent={
              <span className={styles.link}>
                {experimentWorkflow.locked_log.author.name}
              </span>
            }
          />
        )}
        . Owner must unlock to make further changes.
      </span>
    </div>
  ) : null;

export default ExperimentWorkflowInfoLockedBy;
