/**
 * Labstep
 *
 * @module components/EntityUser/Table
 */

import React from 'react';
import TableSimple from 'labstep-web/core/Table/Simple';
import EntityUserActionToggleAssign from 'labstep-web/components/EntityUser/Action/ToggleAssign';
import EntityUserAvatar from 'labstep-web/components/EntityUser/Avatar';
import Header from 'labstep-web/core/Header';
import { EntityUser } from 'labstep-web/models';
import { IEntityUserTableProps } from './types';
import styles from './styles.module.scss';

const EntityUserTable: React.FC<IEntityUserTableProps> = ({
  entity,
  entityUsers,
}) => (
  <TableSimple
    hideHeader
    rows={entityUsers.filter(
      (entityUser) =>
        entityUser.is_contributor || entityUser.is_assigned,
    )}
    columns={[
      {
        header: '',
        content: (entityUser: EntityUser) => (
          <EntityUserAvatar entityUser={entityUser} />
        ),
        cellProps: { style: { width: 50 } },
      },
      {
        header: '',
        content: (entityUser: EntityUser) => (
          <div>
            <Header noMargin size="tiny">
              {entityUser.user.name}
            </Header>
            <div>
              <span>
                {entityUser.getIsAuthor(entity.author)
                  ? 'Creator'
                  : entityUser.is_contributor && 'Contributor '}
              </span>
              {entityUser.is_assigned && (
                <>
                  {(entityUser.getIsAuthor(entity.author) ||
                    entityUser.is_contributor) && <span> - </span>}
                  <span className={styles.assigned}>Assigned</span>
                </>
              )}
            </div>
          </div>
        ),
        cellProps: { width: 10 },
      },
      {
        header: '',
        content: (entityUser: EntityUser) => (
          <EntityUserActionToggleAssign
            entity={entity}
            entityUser={entityUser}
          />
        ),
        cellProps: { width: 6 },
      },
    ]}
  />
);

export default EntityUserTable;
