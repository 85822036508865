/**
 * Labstep
 *
 * @module components/Layout/Menu/Content/UserGroups
 * @desc Left side bar user group list
 */

import React from 'react';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { withActiveGroup } from 'labstep-web/containers';
import Collapsible from './Collapsible';
import { ILayoutMenuLeftContentUserGroupsProps } from './types';

export const LayoutMenuLeftContentUserGroups: React.FC<
  ILayoutMenuLeftContentUserGroupsProps
> = ({ activeGroup }) => {
  const params: Record<string, unknown> = {
    search: 1,
    count: 5,
    sort: '-is_home',
  };
  if (activeGroup) {
    params.not_group_id = activeGroup.id;
  }
  return (
    <>
      {activeGroup && (
        <Collapsible group={activeGroup} initialExpandedState />
      )}
      <ReadOnMountHOC
        type="cursor"
        entityName="user_group"
        params={params}
        pagination={{
          loader: false,
          showMore: false,
          infinite: false,
        }}
        children={({ entities: userGroups }) => (
          <>
            {userGroups
              .filter((userGroup) => !userGroup.deleted_at)
              .map((userGroup) => (
                <Collapsible
                  key={userGroup.id}
                  group={userGroup.group}
                />
              ))}
          </>
        )}
      />
    </>
  );
};

export default withActiveGroup(LayoutMenuLeftContentUserGroups);
