/**
 * Labstep
 *
 * @module components/CustomIdentifierSet/Preview
 * @desc Preview for Custom Identifier Set
 */

import React from 'react';
import Hoverable from 'labstep-web/core/Hoverable';
import EntityActionClear from 'labstep-web/components/Entity/Action/Clear';
import styles from './styles.module.scss';
import { ICustomIdentifierSetPreviewProps } from './types';

export const CustomIdentifierSetPreview: React.FC<
  ICustomIdentifierSetPreviewProps
> = ({ group }) => (
  <Hoverable
    className={styles.preview}
    hoverable={
      <>
        <span>{`Prefix: ${group.experiment_workflow_custom_identifier_set.settings.prefix}`}</span>
        <span>{`Min. Digits: ${group.experiment_workflow_custom_identifier_set.settings.zero_padding}`}</span>
        <span>{`Preview: ${group.experiment_workflow_custom_identifier_set.getPreview()}`}</span>
      </>
    }
    component={
      group.canChangeCustomIdentifierSet ? (
        <EntityActionClear
          entityName={group.entityName}
          id={group.id}
          field="experiment_workflow_custom_identifier_set_id"
        />
      ) : null
    }
  />
);

export default CustomIdentifierSetPreview;
