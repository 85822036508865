/**
 * Labstep
 *
 * @module SignatureRequest/Form/Create
 * @desc SignatureRequest Create Form
 */

import React from 'react';
import { Group, SignatureRequest, User } from 'labstep-web/models';
import FormCreate from 'labstep-web/core/Form/Create';
import {
  fieldSignatureRequestMessage,
  fieldSignatureRequestUser,
} from 'labstep-web/components/SignatureRequest/Form/fields';
import {
  withActiveGroup,
  withAuthenticatedUserEntity,
} from 'labstep-web/containers';
import { IFieldTypeSearchSelectProps } from 'labstep-web/core/Form/Reusable/types';
import { ISignatureRequestFormCreateProps } from './types';

export const getDefaultSignatureRequestMessage = (
  authenticatedUserEntity: User,
  group?: Group | null,
): string => {
  return (
    group?.default_signature_request_message ||
    `Hi

Please review this experiment for me.

Thanks,

${authenticatedUserEntity.name}
`
  );
};

export const SignatureRequestFormCreate: React.FC<
  ISignatureRequestFormCreateProps
> = ({
  experimentWorkflow,
  options,
  authenticatedUserEntity,
  activeGroup,
}) => (
  <FormCreate
    autoFocus
    fields={[
      {
        ...fieldSignatureRequestUser,
        elementProps: {
          ...fieldSignatureRequestUser.elementProps,
          params: { group_id: activeGroup?.id },
        },
      } as IFieldTypeSearchSelectProps,
      fieldSignatureRequestMessage,
    ]}
    defaultValues={{
      [fieldSignatureRequestMessage.name]:
        getDefaultSignatureRequestMessage(
          authenticatedUserEntity,
          activeGroup,
        ),
    }}
    entityName={SignatureRequest.entityName}
    parentName={experimentWorkflow.entityName}
    parentId={experimentWorkflow.id}
    options={options}
    submitButtonText="Request"
  />
);

export default withAuthenticatedUserEntity(
  withActiveGroup(SignatureRequestFormCreate),
);
