/**
 * Labstep
 *
 * @module components/Entity/ActionDelete
 * @desc Delete
 */

import React from 'react';
import {
  capitalize,
  getHumanReadableEntityName,
} from 'labstep-web/services/i18n.service';
import { generateNewDateString } from 'labstep-web/services/date.service';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import Confirmation from 'labstep-web/core/Modal/Confirmation';
import ActionComponent from 'labstep-web/core/Action/Component';
import { EntityUpdateContainer } from 'labstep-web/containers';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';
import {
  IEntityActionDeleteComponentProps,
  IEntityActionDeleteProps,
} from './types';

export const EntityActionDeleteComponent: React.FC<
  IEntityActionDeleteComponentProps
> = ({
  entityName,
  id,
  message,
  header,
  isActive,
  options,
  confirmButtonContent,
  actionComponentProps,
  skipConfirmation,
  disallowedMessage,
  actionText,
  iconName,
}) => {
  if (skipConfirmation) {
    return (
      <EntityUpdateContainer entityName={entityName} id={id}>
        {({ status, update }): React.ReactElement => (
          <ActionComponent
            type="option"
            dataTestId="action-delete"
            text={actionText}
            icon={iconName}
            status={status}
            onClick={(): void =>
              update(
                {
                  deleted_at: isActive
                    ? generateNewDateString()
                    : null,
                },
                options,
              )
            }
            {...actionComponentProps}
          />
        )}
      </EntityUpdateContainer>
    );
  }

  return (
    <EntityUpdateContainer entityName={entityName} id={id}>
      {({ status, update }): React.ReactElement => (
        <Confirmation
          header={
            disallowedMessage ? 'This action is not allowed.' : header
          }
          positive={!isActive}
          status={status}
          onConfirm={({ toggleModal }): void => {
            if (disallowedMessage) {
              toggleModal();
            } else {
              update(
                {
                  deleted_at: isActive
                    ? generateNewDateString()
                    : null,
                },
                enhanceOptions({
                  options: { ...options, toast: true },
                  onSuccess: toggleModal,
                }),
              );
            }
          }}
          message={disallowedMessage || message}
          confirmButtonContent={
            disallowedMessage ? 'Done' : confirmButtonContent
          }
          cancelButtonContent={disallowedMessage ? false : undefined}
        >
          {({ toggleModal }): React.ReactElement => (
            <ActionComponent
              type="option"
              dataTestId="action-delete"
              text={actionText}
              icon={iconName}
              onClick={toggleModal}
              {...actionComponentProps}
            />
          )}
        </Confirmation>
      )}
    </EntityUpdateContainer>
  );
};

const EntityActionDelete: React.FC<IEntityActionDeleteProps> = (
  props,
) => {
  const {
    entity,
    options = {},
    confirmMessage,
    header,
    confirmButtonContent,
    humanReadableName,
    actionComponentProps = { type: 'option' },
    skipConfirmation,
    disallowedMessage,
    isRemoveAction,
  } = props;
  const idAttribute = getIdAttribute(entity.entityName);
  const id = entity[idAttribute as keyof typeof entity] as string;

  const isActive = !entity.deleted_at;
  const name =
    humanReadableName ||
    getHumanReadableEntityName(
      entity.entityName,
      false,
      false,
      'is_template' in entity ? entity.is_template : false,
    );

  let extraActionComponentProps = actionComponentProps;
  if (actionComponentProps.type === 'button' && isActive) {
    extraActionComponentProps = {
      ...actionComponentProps,
      elementProps: {
        color: 'red',
        ...actionComponentProps.elementProps,
      },
    };
  }

  let actionText = 'Restore';
  if (isActive) {
    actionText = isRemoveAction ? 'Remove' : 'Delete';
  }
  const message =
    confirmMessage ||
    `Are you sure you want to ${actionText.toLowerCase()} this ${name}?`;

  const iconName = isActive ? 'trash' : 'undo';

  return (
    <Can
      entityName={entity.entityName}
      id={id}
      action={Action.delete}
    >
      <EntityActionDeleteComponent
        entityName={entity.entityName}
        id={id}
        message={message}
        header={header || `${actionText} ${capitalize(name)}`}
        isActive={isActive}
        confirmButtonContent={confirmButtonContent || actionText}
        actionComponentProps={extraActionComponentProps}
        options={options}
        skipConfirmation={skipConfirmation}
        disallowedMessage={disallowedMessage}
        actionText={actionText}
        iconName={iconName}
      />
    </Can>
  );
};

export default EntityActionDelete;
