/**
 * Labstep
 *
 * @desc Selectors for Entity/Can/hooks
 */

import bugsnagService from 'labstep-web/services/bugsnag.service';
import {
  selectIsLocked,
  selectPermissionEntity,
} from 'labstep-web/state/new';
import {
  Action,
  ICanCreateProps,
  ICanProps,
  PermissionEntity,
} from 'labstep-web/components/Entity/Can/types';
import { AuthorizationService } from 'labstep-web/services/authorization.service';
import { LabstepReduxState } from 'labstep-web/state/types';
import { createSelector } from 'reselect';
import { selectEntity } from 'labstep-web/state/selectors';
import { Group } from 'labstep-web/models';

/**
 * @description function to make selector for getHasAccess
 */
export const makeSelectHasAccess = () =>
  createSelector(
    selectPermissionEntity,
    (
      state: any,
      entityName: ICanProps['entityName'],
      id: ICanProps['id'] | ICanProps['id'][],
      action: Action,
    ) => action,
    (
      state: any,
      entityName: ICanProps['entityName'],
      id: ICanProps['id'] | ICanProps['id'][],
    ) => {
      let append = '';
      if (!Array.isArray(id)) {
        try {
          const entity = state.entities[entityName].byId[id];
          append = entity.is_template ? '_template' : '';
        } catch (e) {
          bugsnagService.notify(e, {
            entityName,
            id,
          });
        }
      }

      // TODO: Test
      // This is so that resource_item_template is not editable
      // if resource_template edit are not allowed but resource_item edits are allowed
      return `${entityName}${append}`;
    },
    (
      state: any,
      entityName: ICanProps['entityName'],
      id: ICanProps['id'] | ICanProps['id'][],
    ): boolean => {
      return selectIsLocked(state, entityName, id);
    },
    (permissionEntity, action, entityName, isLocked) => {
      console.log(
        'Checking Access',
        action,
        permissionEntity.entityName,
        permissionEntity.id,
        entityName,
        `isLocked: ${isLocked}`,
      );

      if (!permissionEntity) {
        console.log('No permissionEntity', entityName, action);
        return false;
      }

      if (
        isLocked &&
        !AuthorizationService.isActionAllowedForLockedEntity(
          entityName,
          permissionEntity,
          action,
        )
      ) {
        console.log('Locked', entityName, action);
        return false;
      }

      try {
        if ('allowed_actions' in permissionEntity) {
          return AuthorizationService.hasAccess(
            entityName,
            permissionEntity,
            action,
          );
        }
        console.log(
          'No allowed_actions in permissionEntity',
          entityName,
          action,
          permissionEntity,
        );
        return false;
      } catch (e) {
        bugsnagService.notify(e, {
          permissionEntity,
          action,
          entityName: permissionEntity.entityName,
          isLocked,
        });
        return false;
      }
    },
  );

export const makeSelectHasAccessGroup = () =>
  createSelector<
    readonly ((
      state: LabstepReduxState,
      action: string,
      groupId?: Group['id'],
    ) => any)[],
    boolean
  >(
    (state: any, action: string) => action,
    (state: any, action: string, groupId?: Group['id']) => {
      if (!groupId) {
        return selectEntity(state, 'group', state.activeGroupId);
      }
      return selectEntity(state, 'group', groupId);
    },
    (action: string, group: Group) => {
      return AuthorizationService.hasGroupAccess(action, group);
    },
  );

export const makeSelectHasAccessCreate = () =>
  createSelector<
    readonly ((
      state: LabstepReduxState,
      entityName: string,
      parentName: ICanCreateProps['parentName'],
      parentId: ICanCreateProps['parentId'],
      isLocked?: boolean,
    ) => any)[],
    boolean
  >(
    (
      state: any,
      entityName: string,
      parentName?: ICanCreateProps['parentName'],
      parentId?: ICanCreateProps['parentId'],
    ) => {
      if (!parentName || !parentId) {
        return selectPermissionEntity(
          state,
          'group',
          state.activeGroupId,
        );
      }
      return selectPermissionEntity(state, parentName, parentId);
    },
    (state: any, entityName: string) => entityName,
    (
      state: any,
      entityName: string,
      parentName?: ICanCreateProps['parentName'],
      parentId?: ICanCreateProps['parentId'],
    ) => {
      if (!parentName) {
        if (!state.activeGroupId) {
          return false;
        }
        return selectIsLocked(state, 'group', state.activeGroupId);
      }

      return selectIsLocked(state, parentName, parentId);
    },
    (
      permissionEntity: PermissionEntity,
      entityName: string,
      isLocked?: boolean,
    ) => {
      const createAction = `${entityName}:create` as Action;

      // eslint-disable-next-line no-console
      console.log(
        'Checking Create',
        entityName,
        createAction,
        permissionEntity.entityName,
        `isLocked: ${isLocked}`,
      );

      if (!permissionEntity) {
        console.log('No permissionEntity', entityName);
        return false;
      }

      if (
        isLocked &&
        !AuthorizationService.isActionAllowedForLockedEntity(
          entityName,
          permissionEntity,
          createAction,
        )
      ) {
        // eslint-disable-next-line no-console
        console.log(
          'AuthorizationService - Locked',
          entityName,
          'create',
        );
        return false;
      }

      return AuthorizationService.hasAccess(
        entityName,
        permissionEntity,
        createAction,
      );
    },
  );
