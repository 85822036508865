/**
 * Labstep
 *
 * @module screens/Resource/Show
 * @desc Resource Show Screen
 */

import React from 'react';
import DocumentHead from 'labstep-web/core/DocumentHead';
import LayoutFullScreenPage from 'labstep-web/components/Layout/FullScreenPage';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { Organization } from 'labstep-web/models';
import OrganizationHeader from 'labstep-web/components/Organization/Header';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import Redirect from 'labstep-web/core/Redirect';
import {
  IScreensOrganizationShowProps,
  IScreensOrganizationShowReadContainerProps,
} from './types';
import styles from './styles.module.scss';
import ScreensOrganizationShowCenter from './Center';

export const ScreensOrganizationShow: React.FC<
  IScreensOrganizationShowProps
> = ({ organization }) => (
  <LayoutFullScreenPage>
    <div className={styles.center}>
      <OrganizationHeader organization={organization} />
      <ScreensOrganizationShowCenter organization={organization} />
    </div>
  </LayoutFullScreenPage>
);

export const ScreensOrganizationShowReadContainer: React.FC<
  IScreensOrganizationShowReadContainerProps
> = ({ match, authenticatedUserEntity }) => {
  const { identifier } = match.params;
  if (
    !authenticatedUserEntity.userOrganization ||
    authenticatedUserEntity.userOrganization?.organization
      .identifier !== identifier
  ) {
    return <Redirect to="app_homepage" />;
  }
  return (
    <ReadOnMountHOC
      type="entities"
      entityName={Organization.entityName}
      params={{ identifier, get_single: 1 }}
      loading={{ loader: 'full_screen' }}
      children={({ entity }) => (
        <>
          <DocumentHead title={entity.name} />
          <ScreensOrganizationShow organization={entity} />
        </>
      )}
    />
  );
};

export default withAuthenticatedUserEntity(
  ScreensOrganizationShowReadContainer,
);
