/**
 * Labstep
 *
 * @module components/OrderRequest/Filter/Pane
 * @desc Filter pane for order request
 */

import React from 'react';
import { FilterSection } from 'labstep-web/components/Filter/List';
import FilterPane from 'labstep-web/components/Filter/Pane';
import Icon from 'labstep-web/core/Icon';
import OrderRequestFilterUrgentList from 'labstep-web/components/OrderRequest/Filter/Urgent/List';
import { ICONS } from 'labstep-web/constants';
import { STRINGS } from 'labstep-web/strings';
import {
  OrderRequest,
  OrderRequestStatusValues,
} from 'labstep-web/models';
import styles from './styles.module.scss';

export const OrderRequestExtraFilters: React.FC = () => (
  <FilterSection
    header={STRINGS.filter.header.status}
    filter={{
      entityName: OrderRequest.entityName,
      name: 'status',
    }}
    filters={Object.keys(OrderRequestStatusValues).map((key) => ({
      component: (
        <Icon
          className={styles[key]}
          name={ICONS.order_request.info.status}
        />
      ),
      text: OrderRequestStatusValues[
        key as keyof typeof OrderRequestStatusValues
      ],
      params: { status: key },
    }))}
  />
);

const OrderRequestFilterPane: React.FC = () => (
  <FilterPane
    filterCreatedByText="Requested by"
    entityName={OrderRequest.entityName}
    extraFilters={
      <div>
        <OrderRequestFilterUrgentList />
        <OrderRequestExtraFilters />
      </div>
    }
    icon={ICONS.order_request.primary}
    text={STRINGS.order_request.plural.capitalized}
  />
);

export default OrderRequestFilterPane;
