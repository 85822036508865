/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Labstep
 *
 * @module core/Select/Search
 * @desc Async Search Select Container
 */

import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { flattenObject } from 'labstep-web/services/utils.service';
import { objectOrFunction } from 'labstep-web/services/react.service';
import {
  denormalizeNormalizedEntity,
  mergeStatuses,
} from 'labstep-web/state/selectors';
import { SearchHOC } from 'labstep-web/hoc/Search';
import { EntityCreateContainer } from 'labstep-web/containers';
import Select from 'labstep-web/core/Select';
import Creatable from 'react-select/creatable';
import { Group, ResourceLocation } from 'labstep-web/models';
import { useHasAccessCreate } from 'labstep-web/components/Entity/Can/hooks';
import {
  ICreateLabelProps,
  ISearchSelectContainerProps,
  ISearchSelectProps,
  ISearchSelectState,
} from './types';
import {
  getPlaceholder,
  isValidNewOption,
  noOptionsMessage,
} from './utils';

const debounceTime = 500;

export const CreateLabel: React.FC<ICreateLabelProps> = ({
  createLabel,
  entityName,
  inputValue,
  isTemplate,
}) => (
  <span>
    {createLabel ||
      `Create new ${getHumanReadableEntityName(
        entityName,
        false,
        false,
        isTemplate,
      )}${inputValue && `: "${inputValue}"`}`}
  </span>
);

export class SearchSelect extends React.Component<
  ISearchSelectProps,
  ISearchSelectState
> {
  static defaultProps = {
    searchKey: 'search_query',
    valueKey: 'id',
    labelKey: 'name',
    createKey: 'name',
  };

  async: NodeJS.Timeout | null = null;

  constructor(props: ISearchSelectProps) {
    super(props);
    this.state = { value: '', debouncing: false, hasMounted: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ hasMounted: true });
    }, 10);
  }

  handleChange(selectedOption: unknown) {
    const { onChange, denormalized } = this.props;

    this.setState({ value: selectedOption });

    if (onChange) {
      const option = denormalized
        ? flattenObject(selectedOption)
        : selectedOption;
      onChange(option);
    }
  }

  handleInputChange(input: string) {
    const {
      searchKey = SearchSelect.defaultProps.searchKey,
      setParams,
      minimumCharacters,
    } = this.props;

    if (minimumCharacters && input.length < minimumCharacters) {
      return;
    }

    const param = input === '' ? undefined : input;

    if (this.async) {
      clearTimeout(this.async);
    }
    this.setState({ debouncing: true });
    this.async = setTimeout(() => {
      this.setState({ debouncing: false });
      setParams({ [searchKey]: param });
    }, debounceTime);
  }

  handleCreate(input: string) {
    const {
      create,
      createKey = SearchSelect.defaultProps.createKey,
      onCreateOptions = {},
      entityName,
      refreshUuid,
      setOptions,
      createBody = {},
      skipCreateKey,
      allowCreateWithEmptyInput,
    } = this.props;

    let body = createBody;
    if (
      !skipCreateKey &&
      !(input.length < 1 && allowCreateWithEmptyInput)
    ) {
      body = { ...createBody, [createKey]: input };
    }

    create(body, {
      toast: true,
      onSuccess: ({ response }) => {
        if (onCreateOptions.onSuccess) {
          onCreateOptions.onSuccess({ response });
        }
        refreshUuid();
        const entity = denormalizeNormalizedEntity(
          response,
          entityName,
        );

        const option = setOptions ? setOptions([entity])[0] : entity;
        this.handleChange(option);
      },
    });
  }

  render() {
    const {
      status,
      entities,
      setOptions,
      valueKey = SearchSelect.defaultProps.valueKey,
      labelKey = SearchSelect.defaultProps.labelKey,
      searchKey = SearchSelect.defaultProps.searchKey,
      setParams,
      searchParams,
      onChange,
      creatable,
      createStatus,
      denormalized,
      entityName,
      entityNameForText,
      noEntitiesMessage,
      create,
      createKey,
      value,
      minimumCharacters,
      createLabel,
      caseSensitive,
      allowCreateWithEmptyInput,
      placeholder,
      isTemplate,
      usePortal,
      ...rest
    } = this.props;

    const { debouncing, hasMounted } = this.state;

    const Component = creatable ? Creatable : Select;

    // setOptions can be optionally passed to take over the renderedOptions
    let renderedOptions = setOptions
      ? setOptions(entities)
      : entities;
    const isLoading =
      mergeStatuses([status, createStatus]).isFetching || debouncing;
    // Set results to empty array if minimumCharacters is passed
    if (
      (minimumCharacters &&
        (!searchParams[searchKey] ||
          searchParams[searchKey].length < minimumCharacters)) ||
      debouncing
    ) {
      renderedOptions = [];
    }

    // If we require form field then let it take control of the value
    const finalValue =
      rest.fieldType || value !== undefined
        ? value
        : this.state.value;

    const isDisabled = createStatus && createStatus.isFetching;

    const setEntityNameForText = entityNameForText || entityName;

    const portalProps = usePortal
      ? {
          menuPortalTarget: document.body,
          menuShouldBlockScroll: true,
          styles: {
            menuPortal: (base: any) => ({
              ...base,
              zIndex: 9999,
            }),
          },
        }
      : {};

    return (
      <Component
        className="Select-Search-Container"
        classNamePrefix="Select-Search"
        getNewOptionData={(inputValue) => ({
          [valueKey]: inputValue,
          [labelKey]: (
            <CreateLabel
              createLabel={createLabel}
              entityName={setEntityNameForText}
              inputValue={inputValue}
              isTemplate={isTemplate}
            />
          ),
          // TODO: remove once react-select fixes this issue
          // https://github.com/JedWatson/react-select/issues/3988#issuecomment-670794396
          __isNew__: true,
          isEqual: () => false,
        })}
        createOptionPosition="first"
        isValidNewOption={(inputValue, selectValue, selectOptions) =>
          isValidNewOption(
            inputValue,
            selectValue,
            selectOptions,
            labelKey,
            caseSensitive,
            allowCreateWithEmptyInput,
            creatable,
          )
        }
        onCreateOption={this.handleCreate}
        placeholder={
          placeholder ||
          getPlaceholder(
            setEntityNameForText,
            creatable,
            !!isTemplate,
          )
        }
        getOptionLabel={(option) => option[labelKey]}
        getOptionValue={(option) => option[valueKey]}
        options={renderedOptions}
        isLoading={isLoading}
        isDisabled={isDisabled}
        noOptionsMessage={() =>
          noOptionsMessage(
            setEntityNameForText,
            searchParams,
            noEntitiesMessage,
            creatable,
            minimumCharacters,
            searchKey,
            !!isTemplate,
          )
        }
        value={finalValue}
        // Prevents select component to do internal searching
        filterOption={() => true}
        isClearable
        menuPlacement="auto"
        // hasMounted need because of https://github.com/Labstep/web/issues/7065
        openMenuOnFocus={!hasMounted && rest.autoFocus}
        {...portalProps}
        {...rest}
        onInputChange={this.handleInputChange}
        onChange={this.handleChange}
      />
    );
  }
}

export const SearchSelectContainer: React.FC<
  ISearchSelectContainerProps
> = ({
  entityName,
  params,
  extraParams,
  creatable,
  entityNameForText,
  ejectFromElasticsearch,
  ...props
}) => {
  const canCreateEntity = useHasAccessCreate(entityName);
  let isCreatable = false;
  if (creatable) {
    if (entityName === Group.entityName) {
      isCreatable = true;
    } else {
      isCreatable = canCreateEntity;
    }
  }

  const allParams = { sort: '-score', ...params, ...extraParams };
  if (
    ejectFromElasticsearch ||
    entityName === ResourceLocation.entityName
  ) {
    delete allParams.sort;
  }

  return (
    <SearchHOC
      entityName={entityName}
      params={allParams}
      noSearchParam={ejectFromElasticsearch}
    >
      {({ status, entities, searchParams, setParams }) => (
        <EntityCreateContainer entityName={entityName}>
          {({ create, status: createStatus, refreshUuid }) => (
            <>
              <SearchSelect
                ejectFromElasticsearch={ejectFromElasticsearch}
                // Needed so that it doesn't submit on backspace
                backspaceRemovesValue={false}
                create={create}
                createStatus={createStatus}
                entityName={entityName}
                entityNameForText={entityNameForText}
                entities={entities}
                status={status}
                refreshUuid={refreshUuid}
                setParams={setParams}
                searchParams={searchParams}
                creatable={isCreatable}
                isTemplate={
                  !!objectOrFunction(params, {})?.is_template
                }
                {...props}
              />
            </>
          )}
        </EntityCreateContainer>
      )}
    </SearchHOC>
  );
};

export default SearchSelectContainer;
