/**
 * Labstep
 *
 * @module components/Organization/Header
 * @desc Organization Header component
 */

import React from 'react';
import OrganizationLogo from 'labstep-web/components/Organization/Logo';
import Header from 'labstep-web/core/Header';
import { IOrganizationHeaderProps } from './types';
import styles from './styles.module.scss';

const OrganizationHeader: React.FC<IOrganizationHeaderProps> = ({
  organization,
}) => (
  <div className={styles.container}>
    <OrganizationLogo organization={organization} />
    <Header size="large">{organization.name}</Header>
  </div>
);

export default OrganizationHeader;
