/**
 * Labstep
 *
 * @module components/OrderRequest/Status/Modal/AddItems
 * @desc Confimation modal to add received items to the inventory
 */

import React from 'react';
import ResourceItemActionImport from 'labstep-web/components/ResourceItem/Action/Import/component';
import ModalConfirmation from 'labstep-web/core/Modal/Confirmation';
import { ResourceItem } from 'labstep-web/models';
import { IOrderRequestStatusModalAddItemsProps } from './types';

export const OrderRequestStatusModalAddItems: React.FC<
  IOrderRequestStatusModalAddItemsProps
> = ({ orderRequest, onImport, ...rest }) => (
  <ModalConfirmation
    confirmButtonContent={false}
    cancelButtonContent="Add later"
    positive
    header={`Add ${ResourceItem.getHumanReadableEntityName(
      true,
      true,
    )}`}
    message={
      <div>
        <p>
          You have {orderRequest.remainingItemsCount} items left to
          add to the inventory.
        </p>
        <p>
          Do you want to add these received items to the inventory?
        </p>
      </div>
    }
    extraButtons={
      <ResourceItemActionImport
        resource={orderRequest.resource}
        orderRequest={orderRequest}
        actionComponentProps={{
          type: 'button',
          text: 'Add now',
        }}
        onImport={onImport}
      />
    }
    {...rest}
  />
);

export default OrderRequestStatusModalAddItems;
