/**
 * Labstep
 *
 * @module core/Websocket
 * @desc Ensure we have an active websocket connection
 */

import React, { useEffect } from 'react';
import { withWebsocket } from 'labstep-web/containers';
import styles from './styles.module.scss';

export const Websocket: React.FC<any> = ({
  children,
  connected,
  checkConnected,
}) => {
  useEffect(() => {
    checkConnected();
  });

  if (!connected) {
    return (
      <div className={styles.loading}>
        Could not connect to print server. Please retry later.
      </div>
    );
  }

  return children;
};

export default withWebsocket(Websocket);
