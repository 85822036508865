/**
 * Labstep
 *
 * @module components/DeviceBooking/Form/ShowEdit/Description
 * @desc ShowEditDescription for DeviceBooking
 */

import React from 'react';
import FormShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import { DeviceBooking } from 'labstep-web/models';
import { fieldDescription } from 'labstep-web/components/DeviceBooking/Form/fields';
import { IDeviceBookingFormShowEditDescriptionProps } from './types';

export const DeviceBookingFormShowEditDescription: React.FC<
  IDeviceBookingFormShowEditDescriptionProps
> = ({ deviceBooking }) => (
  <FormShowEditText
    entityName={DeviceBooking.entityName}
    entity={deviceBooking}
    field={fieldDescription}
    placeholder="Add description"
  >
    {deviceBooking.description}
  </FormShowEditText>
);

export default DeviceBookingFormShowEditDescription;
