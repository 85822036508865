/**
 * Labstep
 *
 * @module components/PurchaseOrder/Action/Create
 * @desc Create Action for purchaseOrder request workflow
 */

import rules from 'labstep-web/services/validation/rules';
import { currencies } from 'labstep-web/constants';
import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';

export const fieldPurchaseOrderName: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'name',
  validation: rules.purchase_order.name,
  placeholder: 'Give your order a name',
  header: true,
};

export const fieldPurchaseOrderHandlingAmount: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    name: 'handling_amount',
    fieldLabel: 'Handling Amount',
    validation: rules.purchase_order.handling_amount,
    placeholder: 'Enter handling amount',
  };

export const fieldPurchaseOrderTaxRate: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'tax_rate',
  fieldLabel: 'Vat',
  validation: rules.purchase_order.tax_rate,
  placeholder: 'Enter Vat',
};

export const fieldPurchaseOrderCurrency: IFieldWithoutActionProps = {
  fieldType: FieldType.ReactSelect,
  name: 'currency',
  fieldLabel: 'Currency',
  placeholder: 'Enter Currency',
  validation: rules.purchase_order.currency,
  elementProps: {
    openMenuOnFocus: true,
    options: Object.values(currencies).map((currency) => ({
      label: `${currency.code} - ${currency.name}`,
      value: currency.code,
    })),
  },
};

export const fieldPurchaseOrderDiscountAmount: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    name: 'discount_amount',
    validation: rules.purchase_order.discount_amount,
    placeholder: 'Enter discount amount',
  };
