/**
 * Labstep
 *
 * @module components/Molecule/Item
 * @desc Item to show comment molecule
 */

import React from 'react';
import MoleculeActionEdit from 'labstep-web/components/Molecule/Action/Edit';
import DangerousDiv from 'labstep-web/core/DangerousDiv';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import { IActionComponentProps } from 'labstep-web/core/Action/Component/types';
import { Metadata, Molecule } from 'labstep-web/models';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IMoleculeItemProps } from './types';
import styles from './styles.module.scss';

const drawMoleculeActionComponentProps = {
  type: 'text',
  elementProps: { placeholder: true },
  text: 'Draw structure',
  icon: null,
} as IActionComponentProps;

const actionComponentProps = {
  type: 'icon',
  icon: 'external',
  elementProps: { color: 'blue' },
  text: '',
} as IActionComponentProps;

const MoleculeItem: React.FC<IMoleculeItemProps> = ({
  parent,
  createProps,
  text,
}) => {
  const { molecule } = parent;
  const canEdit = useHasAccess(
    parent.entityName,
    parent.idAttr,
    Action.edit,
  ) as boolean;

  const finalDrawMoleculeActionComponentProps = {
    ...drawMoleculeActionComponentProps,
  };
  if (text) {
    finalDrawMoleculeActionComponentProps.text = text;
  }

  return (
    <MoleculeActionEdit
      molecule={molecule || new Molecule()}
      canEdit={canEdit}
      viewComponent={({ toggleModal }): React.ReactElement => {
        let expandIconComponent = null;
        let mainComponent = null;
        const emptyActionComponent = (
          <ActionComponent
            type="text"
            elementProps={{
              placeholder: true,
            }}
            text="Empty structure"
          />
        );
        if (createProps) {
          if (canEdit) {
            mainComponent = (
              <EntityActionCreate
                parentName={createProps.parentName}
                parentId={createProps.parentId}
                entityName={Metadata.entityName}
                actionComponentProps={
                  finalDrawMoleculeActionComponentProps
                }
                body={{
                  ...createProps.createBody,
                  molecule: {
                    name: 'Untitled',
                    data: '',
                  },
                  type: MetadataType.molecule,
                }}
                options={{ onSuccess: toggleModal }}
              />
            );
          } else {
            mainComponent = emptyActionComponent;
          }
        } else if (molecule) {
          if (molecule.svg && !text) {
            expandIconComponent = (
              <ActionComponent
                onClick={toggleModal}
                {...actionComponentProps}
              />
            );
            mainComponent = (
              <DangerousDiv
                className={styles.innerContainer}
                html={molecule.svg}
              />
            );
          } else if (!canEdit) {
            mainComponent = emptyActionComponent;
          } else {
            mainComponent = (
              <ActionComponent
                onClick={toggleModal}
                {...finalDrawMoleculeActionComponentProps}
              />
            );
          }
        } else {
          mainComponent = (
            <EntityActionUpdate
              entityName={parent.entityName}
              id={parent.id}
              body={{
                molecule: {
                  name: 'Untitled',
                  data: '',
                },
              }}
              actionComponentProps={
                finalDrawMoleculeActionComponentProps
              }
              options={{ onSuccess: toggleModal }}
            />
          );
        }
        return (
          <div className={styles.container}>
            <div className={styles.expandIcon}>
              {expandIconComponent}
            </div>
            {mainComponent}
          </div>
        );
      }}
    />
  );
};

export default MoleculeItem;
