/**
 * Labstep
 *
 * @module components/ResourceItem/DataGrid/Container
 * @desc Resource Item Data Grid Container
 */

import React from 'react';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { Metadata, Resource, ResourceItem } from 'labstep-web/models';
import Loadable from 'labstep-web/core/Loadable';
import { objectOrFunction } from 'labstep-web/services/react.service';
import MetadataDataGridColumnOptions from 'labstep-web/components/Metadata/DataGrid/ColumnOptions';
import { ICONS } from 'labstep-web/constants';
import ResourceItemActionCreateTemplateMetadata from '../../Action/Create/Template/Metadata';
import { IResourceItemDataGridContainerProps } from './types';

export const ResourceItemDataGrid = React.lazy(() => import('..'));

export const ResourceItemDataGridContainer: React.FC<
  IResourceItemDataGridContainerProps
> = ({ templateId, columnDefs, ...rest }) => {
  if (templateId) {
    return (
      <ReadOnMountHOC
        type="entity"
        entityName={Resource.entityName}
        id={templateId}
        loading={{ loader: 'placeholder' }}
        children={({ entity: resourceTemplate }) => (
          <Loadable>
            <ResourceItemDataGrid
              columnDefs={objectOrFunction(
                columnDefs,
                resourceTemplate,
              )}
              toolPanelParams={{
                extraColumnOptions: resourceTemplate ? (
                  <MetadataDataGridColumnOptions
                    entity={resourceTemplate}
                    extraFields={
                      <ResourceItemActionCreateTemplateMetadata
                        resource={resourceTemplate}
                        actionComponentProps={{
                          type: 'text',
                          icon: ICONS.metadata.secondary,
                          cornerIcon: ICONS.resource_item.primary,
                          text: `${ResourceItem.getHumanReadableEntityName(
                            false,
                            true,
                          )} ${Metadata.getHumanReadableEntityName(
                            false,
                            true,
                          )}`,
                        }}
                      />
                    }
                  />
                ) : undefined,
              }}
              {...rest}
            />
          </Loadable>
        )}
      />
    );
  }
  return (
    <Loadable>
      <ResourceItemDataGrid
        columnDefs={objectOrFunction(columnDefs)}
        {...rest}
      />
    </Loadable>
  );
};

export default ResourceItemDataGridContainer;
