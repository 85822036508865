/**
 * Labstep
 *
 * @desc AGGrid Service for importing excel files.
 */

import {
  EntityImportColDef,
  EntityImportDataRowData,
  EntityImportDataRowDataRow,
  ENTITY_IMPORT_DEFAULT_DATE_FORMAT,
} from 'labstep-web/models';
import { ExcelIOService } from 'labstep-web/services/excel-io';
import {
  ExcelIOJSON,
  ExcelIORow,
  ExcelIOData,
} from 'labstep-web/services/excel-io/types';
import { convertDateFormat, formatOADate } from '../date.service';
import PapaParseService from '../papa-parse.service';
import { AGGridEntityImportValidationService } from './ag-grid-entity-import-validation.service';

export class AGGridEntityImportExcelService {
  public onImport: (data: ExcelIOData) => void;

  public constructor(onImport: (dataTable: ExcelIOData) => void) {
    this.onImport = onImport;
  }

  /**
   * Load a file and convert it to AGGrid format.
   *
   * @param {File} file
   */
  public importFile(file: File): void {
    if (file.name.includes('.xls')) {
      this.importFileExcel(file);
    } else if (file.name.includes('.csv')) {
      this.importFileCSV(file);
    }
  }

  /**
   * Import file with ExcelIO.
   * @param file File
   */
  public importFileExcel(file: File): void {
    const excelIO = ExcelIOService.IO;
    excelIO.open(
      file,
      this.importFileSuccess.bind(this),
      AGGridEntityImportExcelService.importFileError.bind(this),
    );
  }

  /**
   * Import file with Papa.
   * @param file File
   */
  public importFileCSV(file: File): void {
    const papaParseService = new PapaParseService(
      (data: unknown[], fields: string[]) => {
        const excelIOData =
          PapaParseService.convertPapaDataToExcelIOData(
            data as Record<string, string>[],
            fields,
          );
        this.onImport(excelIOData);
      },
    );
    papaParseService.parse(file);
  }

  /**
   * If excel file was successfully imported, inject its data into AGGrid.
   *
   * @param {ExcelIOJSON} json
   */
  public importFileSuccess(json: ExcelIOJSON): void {
    const { sheets } = json;

    const keys = Object.keys(sheets);
    if (keys.length === 0) {
      // eslint-disable-next-line no-console
      console.log('No sheets found in the file');
      return;
    }

    const sheetName = keys[0];

    if (!json.sheets[sheetName]) {
      // eslint-disable-next-line no-console
      console.log('First sheet not found in the file');
      return;
    }

    const { data } = json.sheets[sheetName];
    if (!data?.dataTable) {
      // eslint-disable-next-line no-console
      console.log('No data found in the first sheet');
      return;
    }

    this.onImport(data.dataTable);
  }

  /**
   * If excel file imported is not valid then log the error.
   *
   * @param {Error} error
   */
  public static importFileError(error: Error | string): void {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  /**
   * Given a row index, return a preview of the row.
   * @param dataTable Data table
   * @param rowIndex Row index
   * @param maxPreviewLength Max length of preview.
   * @returns Commas separated string of row values: "value1, value2, value3" and the number of other values.
   */
  public static getRowPreview(
    dataTable: ExcelIOData,
    rowIndex: number,
    maxPreviewLength = 3,
  ): [string, number] {
    const keys = Object.keys(dataTable);
    const row = dataTable[keys[rowIndex]];
    const rowKeys = Object.keys(row);

    const rowPreview = rowKeys
      .slice(0, maxPreviewLength)
      .map((key) => {
        const cell = row[key];
        return this.getCellValue(cell);
      })
      .join(', ');

    const nOthers = rowKeys.length - maxPreviewLength;
    return [rowPreview, nOthers > 0 ? nOthers : 0];
  }

  /**
   * Given a column, return the first value of the column.
   * @param dataTable Data table
   * @param colIndex Column
   * @returns First value of the column
   */
  public static getColumnFirstValue(
    dataTable: ExcelIOData,
    column: string,
    headerRowIndex = 0,
  ): string | undefined {
    const keys = Object.keys(dataTable);
    if (keys.length <= headerRowIndex + 1) {
      return undefined;
    }
    const firstRow = dataTable[keys[headerRowIndex + 1]];
    const cell = firstRow[column];
    return this.getCellValue(cell);
  }

  /**
   * Return the number of rows in the data table.
   * @param dataTable Data table
   * @param includeHeader Include header row
   * @returns Number of rows
   */
  public static getNRows(
    dataTable: ExcelIOData,
    includeHeader = false,
  ): number {
    const keys = Object.keys(dataTable);
    return includeHeader ? keys.length : keys.length - 1;
  }

  public static getCellValue(
    cell: ExcelIORow[keyof ExcelIORow],
  ): string {
    let value = cell?.value;
    if (!value) {
      return '';
    }
    value = String(value);
    return formatOADate(value as string);
  }

  public static getHeaderRow(
    dataTable: ExcelIOData,
    headerRowIndex: number,
  ) {
    const keys = Object.keys(dataTable);
    const headerRow = dataTable[keys[headerRowIndex]];

    return headerRow;
  }

  public static getHeaderRowKeys(
    dataTable: ExcelIOData,
    headerRowIndex: number,
  ) {
    const headerRow = AGGridEntityImportExcelService.getHeaderRow(
      dataTable,
      headerRowIndex,
    );
    const headerRowKeys = Object.keys(headerRow);
    return headerRowKeys;
  }

  /**
   * Match the column names in the data table with the column names in the columnDefs.
   *
   * @param dataTable Data table
   * @param columnDefs Column definitions
   * @param nameColumn Name column
   * @param headerRowIndex Header row index
   * @param nameColumnColId Name column colId
   */
  public static matchColumnNames(
    dataTable: ExcelIOData,
    columnDefs: EntityImportColDef[],
    nameColumn: string | null,
    headerRowIndex = 0,
    nameColumnColId = 'name',
  ): Record<string, string | null> {
    const headerRow = AGGridEntityImportExcelService.getHeaderRow(
      dataTable,
      headerRowIndex,
    );
    const columnDefsWithoutName = nameColumn
      ? columnDefs.filter(
          (colDef) => colDef.colId !== nameColumnColId,
        )
      : columnDefs;
    const headerRowKeys = Object.keys(headerRow);
    const headerMap: Record<string, string | null> = {};
    headerRowKeys.forEach((headerRowKey) => {
      if (nameColumn && headerRowKey === nameColumn) {
        headerMap[headerRowKey] = nameColumnColId;
        return;
      }
      const cell = headerRow[headerRowKey];
      const columnName = this.getCellValue(cell);
      const colDefMatch = columnDefsWithoutName.find(
        (colDef) =>
          colDef.headerName?.trim().toLowerCase() ===
          columnName.trim().toLowerCase(),
      );
      headerMap[headerRowKey] =
        colDefMatch &&
        !Object.values(headerMap).includes(colDefMatch.colId!)
          ? colDefMatch.colId!
          : null;
    });
    return headerMap;
  }

  /**
   * Generate a row data from the data table.
   * @param headerMap Header map
   * @param columnDefs Column definitions
   * @param dataTable Data table
   * @param headerRowIndex Header row index
   * @returns Row data
   */
  public static generateRowData(
    headerMap: Record<string, string | null>,
    columnDefs: EntityImportColDef[],
    dataTable: ExcelIOData,
    headerRowIndex = 0,
  ): EntityImportDataRowData {
    const rowData: EntityImportDataRowData = [];
    let rowsKeys = Object.keys(dataTable);
    // remove keys up to header row index
    rowsKeys = rowsKeys.slice(headerRowIndex + 1);
    rowsKeys.forEach((rowKey) => {
      const row: EntityImportDataRowDataRow = {};

      const headerMapKeys = Object.keys(headerMap);
      headerMapKeys.forEach((headerMapKey) => {
        const headerMapValueColId = headerMap[headerMapKey];
        const headerMapValue = columnDefs.find(
          (colDef) => colDef.colId === headerMapValueColId,
        );
        if (!headerMapValue) {
          return;
        }
        const cell = dataTable[rowKey][headerMapKey];
        if (cell?.value) {
          let value = String(cell.value);
          // format OA date
          const valueFromOA = formatOADate(value);
          if (valueFromOA !== value) {
            const dateFormat =
              headerMapValue.entity_import?.date_format ||
              ENTITY_IMPORT_DEFAULT_DATE_FORMAT.date;
            value = convertDateFormat(valueFromOA, dateFormat);
          }
          row[headerMapValue.colId!] = value;
        }
      });
      if (AGGridEntityImportValidationService.isRowEmpty(row)) {
        return;
      }
      rowData.push(row);
    });
    return rowData;
  }
}
