/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Status/Select
 * @desc Select filter for status
 */

import React from 'react';
import Modal from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import ReactSelect from 'labstep-web/core/Select';
import { isActive } from 'labstep-web/components/Filter/List/utils';
import { StatusTypes } from 'labstep-web/models';
import { STRINGS } from 'labstep-web/strings';
import { IExperimentWorkflowFilterStatusSelectProps } from './types';
import { placeholder } from './strings';

const ExperimentWorkflowFilterStatusSelect: React.FC<
  IExperimentWorkflowFilterStatusSelectProps
> = ({ setParams, searchParams }) => {
  const activeStatusType = Object.values(StatusTypes).find((value) =>
    isActive(searchParams, value.params),
  );

  return (
    <Modal
      header={STRINGS.filter.header.status}
      content={({ toggleModal }) => (
        <ReactSelect
          value={
            activeStatusType
              ? {
                  label: activeStatusType.text,
                  value: activeStatusType.params,
                }
              : null
          }
          options={Object.values(StatusTypes).map((value) => ({
            label: value.text,
            value: value.params,
          }))}
          isClearable
          onChange={(selectedOption) => {
            setParams(
              selectedOption
                ? selectedOption.value
                : Object.keys(StatusTypes.completed.params).reduce(
                    (result, key) => ({
                      ...result,
                      [key]: undefined,
                    }),
                    {},
                  ),
            );
            toggleModal();
          }}
          placeholder={placeholder}
        />
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon={StatusTypes.completed.icon}
          text={STRINGS.filter.header.status}
          onClick={toggleModal}
        />
      )}
    />
  );
};

export default ExperimentWorkflowFilterStatusSelect;
