/**
 * Labstep
 *
 * @module screens/Resource/Show/Center/SecondaryInfo
 * @desc Resource Show Center SecondaryInfo
 */

import React from 'react';
import Flex from 'labstep-web/core/Flex';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import ResourceItemActionImport from 'labstep-web/components/ResourceItem/Action/Import';
import OrderRequestActionCreate from 'labstep-web/components/OrderRequest/Action/Create';
import Card from 'labstep-web/core/Card/Card';
import List from 'labstep-web/core/List';
import ResourceActionUpdateResourceTemplate from 'labstep-web/components/Resource/Action/UpdateResourceTemplate';
import ResourceItemActionEditTemplate from 'labstep-web/components/ResourceItem/Action/Edit/Template';
import ResourceActionSetAlertThreshold from 'labstep-web/components/Resource/Action/SetAlertThreshold';
import { ICONS } from 'labstep-web/constants';
import Loadable from 'labstep-web/core/Loadable';
import styles from './styles.module.scss';
import { IResourceShowCenterSecondaryInfoProps } from './types';

export const ResourceShowCenterSecondaryInfoResourceItems =
  React.lazy(() => import('./ResourceItems'));

export const ResourceShowCenterSecondaryInfo: React.FC<
  IResourceShowCenterSecondaryInfoProps
> = ({ resource }) => (
  <List data-testid="resource-show-secondary-info">
    <Card>
      <MetadataManager
        entity={resource}
        tertiaryAction={
          !resource.template && (
            <ResourceActionUpdateResourceTemplate
              actionType="text"
              resource={resource}
            />
          )
        }
      />
    </Card>
    <Card>
      <Flex
        data-testid="resource-show-actions"
        wrap
        className={styles.actions}
      >
        <ResourceItemActionImport resource={resource} />
        <OrderRequestActionCreate
          actionComponentProps={{
            type: 'text',
            icon: ICONS.order_request.primary,
          }}
          resource={resource}
        />
        <ResourceItemActionEditTemplate resource={resource} />
        <ResourceActionSetAlertThreshold resource={resource} />
      </Flex>
      <Loadable>
        <ResourceShowCenterSecondaryInfoResourceItems
          resource={resource}
        />
      </Loadable>
    </Card>
  </List>
);

export default ResourceShowCenterSecondaryInfo;
