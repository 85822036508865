/**
 * Labstep
 *
 * @module components/Resource/Form/ShowEdit/AlertThreshold
 * @desc Edit Resource alert threshold
 */

import React from 'react';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import { fieldResourceAvailableResourceItemCountAlertThreshold } from 'labstep-web/components/Resource/Form/fields';
import { IResourceFormShowEditAlertThresholdProps } from './types';

const ResourceFormShowEditAlertThreshold: React.FC<
  IResourceFormShowEditAlertThresholdProps
> = ({ resource }) => (
  <ShowEditText
    label="Alert Threshold"
    entityName={resource.entityName}
    entity={resource}
    field={fieldResourceAvailableResourceItemCountAlertThreshold}
  >
    {`${resource.available_resource_item_count_alert_threshold} available items`}
  </ShowEditText>
);

export default ResourceFormShowEditAlertThreshold;
