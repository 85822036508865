/**
 * Labstep
 */

import { ReactElement } from 'react';
import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import {
  Experiment,
  ExperimentWorkflow,
  PremiumFeature,
  Protocol,
} from 'labstep-web/models';
import { IProseMirrorCursorPosition } from 'labstep-web/prosemirror/cursor';
import { IIconProps } from 'labstep-web/core/Icon/types';
import { IProseMirrorCommandsElement } from 'labstep-web/prosemirror/extensions/slash/types';
import { EditorView } from 'prosemirror-view';
import { EditorState } from 'prosemirror-state';
import { IWithAuthenticatedUserEntityInjectedProps } from 'labstep-web/containers/AuthenticatedUser/types';

export interface IMenuCommandsProps
  extends IStateDispatchProps,
    IWithAuthenticatedUserEntityInjectedProps {
  entity: Experiment | Protocol;
  experimentWorkflow: ExperimentWorkflow;
  cursorPosition: IProseMirrorCursorPosition;
  view: EditorView;
}

export interface IMenuCommandsElementProps {
  id: IProseMirrorCommandsElement;
  icon?: IIconProps['name'];
  image?: string;
  description: string;
  key: string;
  name?: string;
  /** Only required if element is created on select */
  entityName?: string;
  component?: (props: unknown) => ReactElement;
  premium_feature?: PremiumFeature;
  onCreate?: (
    state: EditorState,
    dispatch: IStateDispatchProps['dispatch'],
  ) => void;
  label?: MenuCommandsLabel;
}

// eslint-disable-next-line no-shadow
export enum MenuCommandsLabel {
  New = 'New',
}
