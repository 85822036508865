/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Sign
 * @desc Action to sign experimentWorkflow
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import SignatureRequestFormCreate from 'labstep-web/components/SignatureRequest/Form/Create';
import { Signature } from 'labstep-web/models';
import { ISignatureRequestActionCreateProps } from './types';

export const SignatureRequestActionCreate: React.FC<
  ISignatureRequestActionCreateProps
> = ({ experimentWorkflow }) => (
  <ModalDefault
    header={`Request ${Signature.getHumanReadableEntityName(
      false,
      true,
    )} via Email`}
    content={({ toggleModal }) => (
      <SignatureRequestFormCreate
        experimentWorkflow={experimentWorkflow}
        options={{ onSuccess: toggleModal, toast: true }}
      />
    )}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        type="button"
        elementProps={{ basic: true }}
        text={`Request ${Signature.getHumanReadableEntityName(
          false,
          true,
        )}`}
        onClick={toggleModal}
      />
    )}
  />
);

export default SignatureRequestActionCreate;
