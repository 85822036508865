/**
 * Labstep
 *
 * @module components/Comment/Action/Comment
 * @desc Comment on a comment action
 */

import React from 'react';
import { ICONS } from 'labstep-web/constants';
import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import { ICommentActionCommentProps } from './types';

const CommentActionComment: React.FC<ICommentActionCommentProps> = ({
  comment,
  onClick,
}) => (
  <EntityActionEdit
    entity={comment}
    actionComponentProps={{
      type: 'option',
      onClick,
      text: 'Comment',
      icon: ICONS.thread.primary,
    }}
  />
);

export default CommentActionComment;
