/**
 * Labstep
 *
 * @module components/Entity/Link/Component
 * @desc Child component for Entity Link
 */

import React from 'react';
import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import { ICONS } from 'labstep-web/constants';
import { IEntityLinkComponentProps } from './types';
import styles from './styles.module.scss';

export const EntityLinkComponent: React.FC<
  IEntityLinkComponentProps
> = ({
  secondary,
  displayName,
  showIcon,
  entity,
  onClick,
  disabled,
}) => {
  const className = classnames(styles.entityLink, {
    [styles.primary]: !secondary,
    [styles.enabled]: !disabled,
  });

  const finalDisplayName = displayName || entity.displayName;

  return (
    <span className={className} onClick={onClick}>
      {showIcon && <Icon name={ICONS[entity.entityName].primary} />}
      {finalDisplayName}
    </span>
  );
};

export default EntityLinkComponent;
