/**
 * Labstep
 *
 * @module Filter/Item
 * @desc A filter item showing an icon/picture and some text
 */

import React from 'react';
import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import styles from './styles.module.scss';
import { IFilterItemProps } from './types';

const FilterItem: React.FC<IFilterItemProps> = ({
  component,
  icon,
  text,
  active,
  onClick,
  actionShortcuts,
  actionMenu,
}) => (
  <div
    data-testid="filter-item"
    className={classnames(styles.container, {
      [styles.active]: active,
    })}
  >
    <div className={styles.innerContainer}>
      <div onClick={onClick}>
        <div>{component || <Icon name={icon} />}</div>
        <div className={styles.text}>
          <span>{text}</span>
        </div>
      </div>
      <div>{actionShortcuts}</div>
    </div>
    {actionMenu}
  </div>
);

export default FilterItem;
