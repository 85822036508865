/**
 * Labstep
 *
 * @module components/Molecule/Filter
 * @desc Filter molecule by inchi
 */

import React, { useEffect, useState } from 'react';
import { Ketcher } from 'labstep-web/core/ChemDraw/types';
import Modal from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import Button from 'labstep-web/core/Button';
import { ICONS } from 'labstep-web/constants';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import ChemistryEditorMarvin from 'labstep-web/core/Chemistry/Editor/Marvin';
import ChemistryEditorKetcher from 'labstep-web/core/Chemistry/Editor/Ketcher';
import { MarvinJSEditor } from 'labstep-web/core/ChemDraw/marvin.types';
import { IMoleculeFilterProps } from './types';

export const MoleculeFilter: React.FC<IMoleculeFilterProps> = ({
  setParams,
  searchParams,
  viewComponent,
}) => {
  const [editor, setEditor] = useState<
    Ketcher | MarvinJSEditor | null
  >(null);
  const hasFeatureEnabled = useHasFeatureFlagEnabled('marvinjs');

  useEffect(() => {
    if (editor) {
      const moleculeInchi = searchParams.molecule_inchi;
      if (moleculeInchi) {
        if (!hasFeatureEnabled) {
          (editor as Ketcher).setMolecule(moleculeInchi);
        } else {
          (editor as MarvinJSEditor).importStructure(
            'inchi',
            moleculeInchi,
          );
        }
      }
    }
  }, [editor, searchParams.molecule_inchi]);

  return (
    <Modal
      header="Chemical Structure"
      size="large"
      onClose={({ toggleModal }) => {
        setEditor(null);
        toggleModal();
      }}
      actions={({ toggleModal }) => (
        <Button
          primary
          onClick={async () => {
            const module = await import(
              'labstep-web/services/molecule.service'
            );
            let smiles;
            if (!hasFeatureEnabled) {
              smiles = await (editor as Ketcher).getSmiles();
            } else {
              smiles = await (
                editor as MarvinJSEditor
              ).exportStructure('smiles');
            }

            if (smiles) {
              const inchis = await module.getInChI(smiles);
              if (inchis.length > 0) {
                setParams({
                  molecule_inchi: inchis[0],
                });
              }
            }
            setEditor(null);
            toggleModal();
          }}
        >
          Done
        </Button>
      )}
      content={() => (
        <div>
          {hasFeatureEnabled ? (
            <ChemistryEditorMarvin
              onLoad={setEditor}
              data-toolbars="standard"
            />
          ) : (
            <ChemistryEditorKetcher onLoad={setEditor} />
          )}
        </div>
      )}
      viewComponent={
        viewComponent ||
        (({ toggleModal }) => (
          <ActionComponent
            type="option"
            icon={ICONS.molecule.primary}
            text="Chemical Structure"
            onClick={toggleModal}
          />
        ))
      }
    />
  );
};

export default MoleculeFilter;
