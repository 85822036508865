/**
 * Labstep
 *
 * @module components/Group/Form/ShowEdit/DefaultExperimentWorkflowTemplate
 * @desc Set the default_experiment_workflow_template for this group
 */

import React from 'react';
import FormShowEditSearchSelect from 'labstep-web/core/Form/ShowEdit/SearchSelect';
import EntityLink from 'labstep-web/components/Entity/Link';
import { ExperimentWorkflow } from 'labstep-web/models';
import { IGroupFormShowEditDefaultExperimentWorkflowTemplateProps } from './types';

export const GroupFormShowEditDefaultExperimentWorkflowTemplate: React.FC<
  IGroupFormShowEditDefaultExperimentWorkflowTemplateProps
> = ({ group }) => (
  <FormShowEditSearchSelect
    placeholder="Specify experiment template"
    childEntityName={ExperimentWorkflow.entityName}
    parentEntity={group}
    searchSelectProps={{
      params: { group_id: group.id, is_template: true },
      entityNameForText: 'experiment template',
    }}
    childKeyName="default_experiment_workflow_template"
  >
    {group.default_experiment_workflow_template && (
      <div>
        <EntityLink
          entity={group.default_experiment_workflow_template}
        />
      </div>
    )}
  </FormShowEditSearchSelect>
);

export default GroupFormShowEditDefaultExperimentWorkflowTemplate;
