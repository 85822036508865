/**
 * Labstep.
 *
 * @module epics/jupyter
 * @desc Redux epic for jupyter
 */

import { Observable, concat, from, of } from 'rxjs';
import qs from 'query-string';
import {
  catchError,
  distinct,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
  delay,
} from 'rxjs/operators';
import { StateObservable } from 'redux-observable';
import { Action, JupyterInstance } from 'labstep-web/models';
import { HttpClientService } from 'labstep-web/services/http-client.service';
import { windowService } from 'labstep-web/services/window.service';
import { authenticationService } from 'labstep-web/services/authentication.service';
import { configService } from 'labstep-web/services/config.service';
import { APP_VERSION } from 'labstep-web/constants';
import { readEntity, showToast } from '../actions';
import { LabstepReduxState } from '../types';

/**
 * Call Jupyter OAuth endpoint.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onJupyterOAuthAuthorizeRequest = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'JUPYTER_OAUTH_AUTHORIZE_REQUEST',
    ),
    distinct((action: Action) => action),
    mergeMap((action: Action) => {
      return authenticationService
        .getAuthentication(action, state$)
        .pipe(
          mergeMap((actionOrAuthentication) => {
            if (
              actionOrAuthentication.type.endsWith('REFRESH_TOKEN')
            ) {
              return of(actionOrAuthentication);
            }

            const queryParameters = qs.stringify({
              ...action.meta.params,
            });
            const url = `${configService.jupyterLambdaUrl}/authorize?${queryParameters}`;
            const headers = {
              'labstep-web-app-version': APP_VERSION,
              Authorization: `Bearer ${actionOrAuthentication.meta?.authentication?.jwt}`,
            };

            return from(
              HttpClientService.send('get', url, headers),
            ).pipe(
              map((payload) => {
                return {
                  ...action,
                  type: `${action.type.replace(
                    'REQUEST',
                    'SUCCESS',
                  )}`,
                  payload,
                };
              }),
              catchError((error) =>
                of({
                  ...action,
                  type: action.type.replace('REQUEST', 'FAIL'),
                  error,
                }),
              ),
            );
          }),
        );
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_JUPYTER_OAUTH_AUTHORIZE_REQUEST',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Redirect to jupyterhub after successful OAuth authorize.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onJupyterOAuthAuthorizeSuccess = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'JUPYTER_OAUTH_AUTHORIZE_SUCCESS',
    ),
    distinct((action: Action) => action),
    tap((action: Action) => {
      if (action.payload && action.payload.redirectUri) {
        windowService.setLocation(action.payload.redirectUri);
      }
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_JUPYTER_OAUTH_AUTHORIZE_SUCCESS',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Call Jupyter Get Link endpoint.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onJupyterGetLinkRequest = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) => action.type === 'JUPYTER_GET_LINK_REQUEST',
    ),
    mergeMap((action: Action) => {
      return authenticationService
        .getAuthentication(action, state$)
        .pipe(
          mergeMap((actionOrAuthentication) => {
            if (
              actionOrAuthentication.type.endsWith('REFRESH_TOKEN')
            ) {
              return of(actionOrAuthentication);
            }

            const url = `${configService.jupyterLambdaUrl}/edit/${action.meta.guid}`;
            const headers = {
              'labstep-web-app-version': APP_VERSION,
              Authorization: `Bearer ${actionOrAuthentication.meta?.authentication?.jwt}`,
            };

            return from(
              HttpClientService.send('get', url, headers),
            ).pipe(
              map((payload) => {
                return {
                  ...action,
                  type: `${action.type.replace(
                    'REQUEST',
                    'SUCCESS',
                  )}`,
                  payload,
                };
              }),
              catchError((error) =>
                of({
                  ...action,
                  type: action.type.replace('REQUEST', 'FAIL'),
                  error,
                }),
              ),
            );
          }),
        );
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_JUPYTER_GET_LINK_REQUEST',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Call Jupyter Run endpoint.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onJupyterRunRequest = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === 'JUPYTER_RUN_REQUEST'),
    mergeMap((action: Action) => {
      return authenticationService
        .getAuthentication(action, state$)
        .pipe(
          mergeMap((actionOrAuthentication) => {
            if (
              actionOrAuthentication.type.endsWith('REFRESH_TOKEN')
            ) {
              return of(actionOrAuthentication);
            }

            const url = `${configService.jupyterLambdaUrl}/run/${action.meta.guid}`;
            const headers = {
              'labstep-web-app-version': APP_VERSION,
              Authorization: `Bearer ${actionOrAuthentication.meta?.authentication?.jwt}`,
            };

            return from(
              HttpClientService.send('get', url, headers),
            ).pipe(
              map((payload) => {
                return {
                  ...action,
                  type: `${action.type.replace(
                    'REQUEST',
                    'SUCCESS',
                  )}`,
                  payload,
                };
              }),
              catchError((error) =>
                of({
                  ...action,
                  type: action.type.replace('REQUEST', 'FAIL'),
                  error,
                }),
              ),
            );
          }),
        );
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_JUPYTER_RUN_REQUEST',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * After JUPYTER_GET_LINK_SUCCESS is received, wait 10s and read jupyter instance.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onJupyterGetLinkReadJupyterInstance = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'JUPYTER_GET_LINK_SUCCESS' &&
        action.payload &&
        action.payload.guid,
    ),
    delay(10000),
    map((action: Action) => {
      return readEntity(
        JupyterInstance.entityName,
        action.payload.guid,
        {},
        {
          jupyterStartGuid: action.payload.guid,
          jupyterStartAttempt: 1,
        },
      );
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_JUPYTER_GET_LINK_READ_JUPYTER_INSTANCE',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * After SUCCESS_READ_JUPYTER_INSTANCE is received,
 * wait 10s and read jupyter instance to check if startedAt is set.
 * Repeat up to 5 times.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onJupyterStartAttempt = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SUCCESS_READ_JUPYTER_INSTANCE_RAW_OUTPUT' &&
        action.meta &&
        action.meta.jupyterStartAttempt &&
        action.meta.jupyterStartGuid &&
        action.payload &&
        !action.payload.started_at,
    ),
    delay(10000),
    map((action: Action) => {
      if (action.meta.jupyterStartAttempt > 5) {
        return showToast({
          type: 'error',
          message: `Could not launch a jupyter instance. Retry again later.`,
          action_type: 'CUSTOM_TOAST',
          options: {
            header: 'Error',
            timeout: 10000,
          },
        });
      }
      return readEntity(
        JupyterInstance.entityName,
        action.meta.jupyterStartGuid,
        {},
        {
          jupyterStartAttempt: action.meta.jupyterStartAttempt + 1,
          jupyterStartGuid: action.meta.jupyterStartGuid,
        },
      );
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_JUPYTER_START_ATTEMPT',
          payload: err,
        }),
        source$,
      ),
    ),
  );
