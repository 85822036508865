/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/Print
 * @desc Display printable version of an ExperimentWorkflow
 */

import React from 'react';
import Print from 'labstep-web/core/Print';
import { EntityCreateContainer } from 'labstep-web/containers';
import { EntityExport, ExperimentWorkflow } from 'labstep-web/models';
import { IExperimentWorkflowPrintScreenProps } from './types';

export const ExperimentWorkflowPrintScreen: React.FC<
  IExperimentWorkflowPrintScreenProps
> = ({ match }) => (
  <EntityCreateContainer entityName={EntityExport.entityName}>
    {({ status, create }) => (
      <Print
        id={match.params.id}
        entityName={ExperimentWorkflow.entityName}
        status={status}
        create={create}
      />
    )}
  </EntityCreateContainer>
);

export default ExperimentWorkflowPrintScreen;
