/**
 * Labstep
 *
 * @module components/ProtocolDevice/List
 * @desc ProtocolDevice list
 */

import React from 'react';
import List from 'labstep-web/core/List';
import ProtocolDeviceItem from 'labstep-web/components/ProtocolDevice/Item';
import EntityDraggable from 'labstep-web/components/Entity/Draggable';
import { ProtocolDevice } from 'labstep-web/models';
import { IProtocolDeviceListProps } from './types';

export const ProtocolDeviceList: React.FC<
  IProtocolDeviceListProps
> = ({ protocolDevices }) => (
  <List id="protocol-device-list">
    {protocolDevices.map((protocolDevice: ProtocolDevice) => (
      <EntityDraggable
        key={protocolDevice.guid}
        entityGuid={protocolDevice.guid}
        entityName={ProtocolDevice.entityName}
        isReference
      >
        <ProtocolDeviceItem protocolDevice={protocolDevice} />
      </EntityDraggable>
    ))}
  </List>
);

export default ProtocolDeviceList;
