/**
 * Labstep
 *
 * @module components/File/IFrame
 * @desc Component to load file into an iframe
 */

import React, { Component } from 'react';
import { FileDownloadContainer } from 'labstep-web/containers';
import {
  IFileIFrameContainerProps,
  IFileIFrameProps,
  IFileIFrameState,
} from './types';

export class FileIFrame extends Component<
  IFileIFrameProps,
  IFileIFrameState
> {
  constructor(props) {
    super(props);
    this.state = { url: null };
  }

  componentDidMount() {
    const { downloadFile, file } = this.props;
    downloadFile({
      id: file.id,
      onSuccess: ({ response }) => {
        this.setState({ url: `${response.signed_url}&inline=1` });
      },
    });
  }

  render() {
    const { url } = this.state;

    if (url) {
      return (
        <iframe
          title="snapgene"
          src={url}
          width="100%"
          height="100%"
          frameBorder="0"
        />
      );
    }

    return null;
  }
}

export const FileIFrameContainer: React.FC<
  IFileIFrameContainerProps
> = (props) => (
  <FileDownloadContainer file={props.file}>
    {(containerProps) => (
      <FileIFrame {...props} {...containerProps} />
    )}
  </FileDownloadContainer>
);

export default FileIFrameContainer;
