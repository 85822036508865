/**
 * Labstep
 *
 * @module components/Comment/List/Pin
 * @desc Comment List for pinned comments
 */

import React from 'react';
import cx from 'classnames';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { HorizontalDivider } from 'labstep-web/core/Divider';
import {
  getPinCommentListParametersExperimentWorkflow,
  getPinCommentListParametersParentThread,
} from 'labstep-web/state/reducers/helpers';
import { CommentList } from '..';
import { ICommentListPinProps } from './types';

const CommentListPin: React.FC<ICommentListPinProps> = ({
  threadId,
  experimentWorkflowId,
  header,
}) => {
  let parameters = {};

  if (experimentWorkflowId) {
    parameters = getPinCommentListParametersExperimentWorkflow(
      experimentWorkflowId,
    );
  } else if (threadId) {
    parameters = getPinCommentListParametersParentThread(threadId);
  }

  return (
    <ReadOnMountHOC
      key={JSON.stringify(parameters)}
      entityName="comment"
      type="cursor"
      params={parameters}
      pagination={{
        infinite: false,
        loader: false,
      }}
      children={({ entities }) => (
        <div
          className={cx('pinCommentList')}
          data-testid="comment-list-pin"
        >
          <CommentList header={header} comments={entities} showPin />
          {entities.length > 0 && <HorizontalDivider />}
        </div>
      )}
    />
  );
};

export default CommentListPin;
