/**
 * Labstep
 *
 * @module components/ResourceLocation/Pane
 * @desc Resource location pane
 */

import React from 'react';
import ResourceLocationActionCreateModal from 'labstep-web/components/ResourceLocation/Action/Create/Modal';
import Icon from 'labstep-web/core/Icon';
import Link from 'labstep-web/core/Link';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ICONS } from 'labstep-web/constants';
import ResourceLocationPaneList from './List';
import { IResourceLocationPaneProps } from './types';

export const viewComponent = (
  <Flex style={{ justifyContent: 'space-between' }}>
    <Header size="tiny">
      <Icon name={ICONS.resource_location.primary} /> Locations
    </Header>
    <Link unstyled to="resource_location_index">
      <ActionComponent
        type="icon"
        icon="cog"
        elementProps={{
          popup: { content: 'Manage Locations' },
        }}
      />
    </Link>
  </Flex>
);

export const ResourceLocationPane: React.FC<
  IResourceLocationPaneProps
> = ({ usePostFilter }) => (
  <div>
    <ResourceLocationPaneList
      action={
        <ResourceLocationActionCreateModal
          actionComponentProps={{
            icon: ICONS.entity.actions.create,
            type: 'text',
          }}
        />
      }
      searchBarProps={{
        viewComponent,
      }}
      usePostFilter={usePostFilter}
    />
  </div>
);

export default ResourceLocationPane;
