/**
 * Labstep
 *
 * @module prosemirror/components/Toolbar/ProtocolStep
 * @desc Button to wrap selection in an ProtocolStep node
 */

import React from 'react';
import ToolbarButton from 'labstep-web/prosemirror/components/Toolbar/Button';
import ActionComponent from 'labstep-web/core/Action/Component';
import { EntityCreateContainer } from 'labstep-web/containers';
import {
  turnListItemsToSteps,
  wrapInProtocolStep,
} from 'labstep-web/prosemirror/nodes/protocol-step/commands';
import { countListItems } from 'labstep-web/prosemirror/nodes/list/utils';
import { ProtocolStep } from 'labstep-web/models';
import { getIsSingleListSelected } from 'labstep-web/prosemirror/utils/selection';
import {
  IStepToolbarContainerProps,
  IStepToolbarProps,
} from './types';

const getAttrs = (response) => {
  const key = ProtocolStep.entityName;
  const attrsArr = response.result.map((id) => ({
    id: response.entities[key][id].id,
    guid: response.entities[key][id].guid,
  }));
  return attrsArr;
};

export const StepToolbar: React.FC<IStepToolbarProps> = ({
  create,
  status,
  dispatch,
  state,
  isToolbarButton = true,
}) => {
  const Component = isToolbarButton ? ToolbarButton : ActionComponent;

  if (getIsSingleListSelected(state)) {
    return (
      <Component
        type="option"
        popup="Convert to Steps"
        onClick={() => {
          create(
            Array(countListItems(state))
              .fill(0)
              .map(() => ({})),
            {
              onSuccess: ({ response }) => {
                turnListItemsToSteps(
                  state,
                  dispatch,
                  getAttrs(response),
                );
              },
            },
          );
        }}
        disabled={
          !turnListItemsToSteps(state) ||
          (status && status.isFetching)
        }
        icon="check circle"
        text={!isToolbarButton && 'Step'}
      />
    );
  }

  return (
    <Component
      type="option"
      popup="Wrap into Step"
      onClick={() => {
        create([{}], {
          onSuccess: ({ response }) => {
            wrapInProtocolStep(
              state,
              dispatch,
              getAttrs(response)[0],
            );
          },
        });
      }}
      disabled={
        !wrapInProtocolStep(state) || (status && status.isFetching)
      }
      icon="check circle"
      text={!isToolbarButton && 'Step'}
    />
  );
};

export const StepToolbarContainer: React.FC<
  IStepToolbarContainerProps
> = ({ protocol, ...rest }) => (
  <EntityCreateContainer
    batch
    entityName={ProtocolStep.entityName}
    parentName={protocol.entityName}
    parentId={protocol.id}
  >
    {({ create, status }) => (
      <StepToolbar create={create} status={status} {...rest} />
    )}
  </EntityCreateContainer>
);

export default StepToolbarContainer;
