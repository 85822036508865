/**
 * Labstep
 */

import colDefStatus from 'labstep-web/components/Entity/DataGrid/colDefs/status';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import { OrderRequest, OrderRequestStatus } from 'labstep-web/models';
import React from 'react';
import { dateOnly } from 'labstep-web/services/date.service';
import EntityLinkComponent from 'labstep-web/components/Entity/Link/Component';
import { EntityViewContextColumnOptionsConsumer } from 'labstep-web/components/EntityView/context';
import EntityDataGridActionToggleColumnOption from 'labstep-web/components/Entity/DataGrid/Action/ToggleColumnOption';

const colDefOrderRequestStatusNoColumnOptions: (
  onChange?: (
    orderRequest: OrderRequest,
    status: OrderRequestStatus,
  ) => void,
) => ColDef<OrderRequest> = (onChange) =>
  colDefStatus(
    OrderRequest.entityName,
    (params) => (
      <EntityViewContextColumnOptionsConsumer colId="status">
        {({ columnOptions: { showLog } }) =>
          showLog && params.data.lastDateAndUser ? (
            <div data-testid="status-log">
              <div>
                on {dateOnly(params.data.lastDateAndUser.date)}
              </div>
              <div>
                by{' '}
                <EntityLinkComponent
                  entity={params.data.lastDateAndUser.user}
                  secondary
                  disabled
                />
              </div>
            </div>
          ) : null
        }
      </EntityViewContextColumnOptionsConsumer>
    ),
    onChange,
  );

const colDefOrderRequestStatus: (
  onChange?: (
    orderRequest: OrderRequest,
    status: OrderRequestStatus,
  ) => void,
) => ColDef<OrderRequest> = (onChange) => ({
  ...colDefOrderRequestStatusNoColumnOptions(onChange),
  headerComponentParams: {
    ...colDefOrderRequestStatusNoColumnOptions(onChange)
      .headerComponentParams,
    columnOptions: (
      <EntityDataGridActionToggleColumnOption
        colId="status"
        columnOption="showLog"
        columnName="Status Info"
      />
    ),
  },
});

export default colDefOrderRequestStatus;
