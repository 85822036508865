/**
 * Labstep
 *
 * @module components/Experiment/List
 * @desc Experiment List
 */

import React from 'react';
import ExperimentCard from 'labstep-web/components/Experiment/Card';
import EntitySortableList from 'labstep-web/components/Entity/Sortable/List';
import EntitySortableActionContainer from 'labstep-web/components/Entity/Sortable/Action/Container';
import SortableItemDragHandle from 'labstep-web/core/Sortable/Item/DragHandle';
import SortableItem from 'labstep-web/core/Sortable/Item';
import EntityDraggable from 'labstep-web/components/Entity/Draggable';
import { Experiment, ExperimentWorkflow } from 'labstep-web/models';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IExperimentListProps } from './types';

export const ExperimentList: React.FC<IExperimentListProps> = ({
  experiments,
  experimentWorkflowId,
  status,
}) => {
  const canEdit = useHasAccess(
    ExperimentWorkflow.entityName,
    experimentWorkflowId,
    Action.edit,
  );
  return (
    <EntitySortableActionContainer>
      {({ disabled: sortDisabled, getSortAction }) => {
        const disabled = sortDisabled || !canEdit;
        return (
          <div id="experiment-list">
            <EntitySortableList
              items={experiments}
              entityName={Experiment.entityName}
              disabled={disabled}
              status={status}
              renderItem={(item): JSX.Element => (
                <SortableItem
                  key={item.id}
                  id={item.id}
                  disabled={disabled}
                >
                  <EntityDraggable
                    key={`experiment-${item.id}`}
                    entityId={item.id}
                    entityName={Experiment.entityName}
                  >
                    <ExperimentCard
                      experiment={item as Experiment}
                      dragHandle={
                        canEdit && (
                          <SortableItemDragHandle
                            children={getSortAction(item.id)}
                          />
                        )
                      }
                    />
                  </EntityDraggable>
                </SortableItem>
              )}
            />
          </div>
        );
      }}
    </EntitySortableActionContainer>
  );
};

export default ExperimentList;
