/**
 * Labstep
 *
 * @module screens/ResourceItem/Index/Breadcrumb
 * @desc ResourceItem Index Breadcrumb
 */

import EntityTemplateBreadcrumb from 'labstep-web/components/Entity/Template/Breadcrumb/Index';
import ResourceItemActionExportMenu from 'labstep-web/components/ResourceItem/Action/Export/Menu';
import ResourceTemplatePreview from 'labstep-web/components/ResourceTemplate/Preview';
import EntityImportActionPending from 'labstep-web/components/EntityImport/Action/Pending';
import Flex from 'labstep-web/core/Flex';
import { Resource, ResourceItem } from 'labstep-web/models';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { PostFilterResourceTemplate } from 'labstep-web/services/postFilter/filters/resource/template';
import React from 'react';

export const ResourceItemIndexBreadcrumb: React.FC = () => (
  <>
    <Flex grow>
      <EntityTemplateBreadcrumb
        entityPreview={({ entity, ...rest }): React.ReactElement => (
          <ResourceTemplatePreview
            resource={entity as Resource}
            {...rest}
          />
        )}
        entityName={Resource.entityName}
        showUncategorised={false}
        text={`All ${getHumanReadableEntityName(
          ResourceItem.entityName,
          true,
          true,
        )}`}
        postFilter={PostFilterResourceTemplate}
      />
    </Flex>
    <EntityImportActionPending
      targetEntityName={ResourceItem.entityName}
    />
    <ResourceItemActionExportMenu />
  </>
);

export default ResourceItemIndexBreadcrumb;
