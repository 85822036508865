/**
 * Labstep
 */

import EntityImportModal from 'labstep-web/components/EntityImport/Modal';
import OrderRequestActionCreateWizard from 'labstep-web/components/OrderRequest/Action/Create/Wizard';
import React from 'react';

const AppModals = () => (
  <>
    <OrderRequestActionCreateWizard />
    <EntityImportModal />
  </>
);

export default AppModals;
