/**
 * Labstep
 *
 * @module containers/User/Register/GDPR
 * @desc Register with GDPR container
 */

import { connect } from 'react-redux';
import { toggleGDPRConsent } from 'labstep-web/state/actions';
import { selectConsent } from 'labstep-web/state/selectors';

const RegisterGDPRContainer = ({ children, ...rest }: any) =>
  children(rest);

const mapStateToProps = (state: any) => ({
  consent: selectConsent(state),
});

const mapDispatchToProps = (dispatch: (...args: any[]) => void) => ({
  toggleGDPRConsent: () => {
    dispatch(toggleGDPRConsent());
  },
});

export const UserRegisterGDPRContainer = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterGDPRContainer);
