/**
 * Labstep
 *
 * @module components/Folder/Form/Create
 * @desc Folder Form Create
 */

import React from 'react';
import FormCreate from 'labstep-web/core/Form/Create';
import { Folder } from 'labstep-web/models';
import { fieldName } from 'labstep-web/components/Folder/Form/fields';
import { IFolderFormCreateProps } from './types';

export const FolderFormCreate: React.FC<IFolderFormCreateProps> = ({
  type,
  ...rest
}) => (
  <FormCreate
    autoFocus
    entityName={Folder.entityName}
    body={{ type }}
    fields={[fieldName]}
    {...rest}
  />
);

export default FolderFormCreate;
