/**
 * Labstep
 *
 * @module core/DataGrid/Placeholder
 * @desc DataGrid Placeholder
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { Entity } from 'labstep-web/models';
import React from 'react';
import { startEditingActiveCell } from '../utils';
import { IDataGridPlaceholderProps } from './types';

export const DataGridPlaceholder: React.FC<
  IDataGridPlaceholderProps<Entity>
> = ({ children, disabled, params, editable, placeholder }) => {
  if (
    !(typeof editable === 'function' ? editable(params) : editable)
  ) {
    return <TextPlaceholder children={placeholder || 'No value'} />;
  }
  return (
    <ActionComponent
      disabled={disabled}
      type="text"
      text={children || 'Enter'}
      icon={undefined}
      elementProps={{
        placeholder: true,
        gridCell: true,
      }}
      onClick={(): void => startEditingActiveCell(params)}
    />
  );
};

export default DataGridPlaceholder;
