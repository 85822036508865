/**
 * Labstep
 *
 * @module screens/Group/Show/Settings
 * @desc Group settings
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import GroupSettings from 'labstep-web/components/Group/Settings';
import UserGroupNotificationManager from 'labstep-web/components/UserGroupNotification/Manager';
import GroupSettingsExperiment from 'labstep-web/components/Group/Settings/Experiment';
import GroupSettingsOrdering from 'labstep-web/components/Group/Settings/Ordering';
import GroupAutoSharingList from 'labstep-web/components/GroupAutoSharing/List';
import ScreensUserGroupUserIndex from 'labstep-web/screens/UserGroup/User';
import Export from 'labstep-web/components/Group/Settings/Export';
import LayoutMainContentCenter from 'labstep-web/components/Layout/MainContent/Center';
import InformationCard from 'labstep-web/core/Card/Information';
import LayoutLinks from 'labstep-web/components/Layout/Links';
import { IEntityCardLink } from 'labstep-web/components/Layout/Links/types';
import { ICONS } from 'labstep-web/constants';
import { STRINGS } from 'labstep-web/strings';
import { IGroupShowSettingsProps } from './types';
import styles from './styles.module.scss';

export const GroupShowSettings: React.FC<IGroupShowSettingsProps> = ({
  group,
}) => {
  const userGroup = group.logged_user_user_group;

  const links: IEntityCardLink[] = [
    {
      route: {
        to: 'group_settings',
        params: { id: group.id },
      },
      children: STRINGS.navigation.general.singular.capitalized,
      render: (): React.ReactElement => (
        <GroupSettings group={group} />
      ),
    },
    {
      route: {
        to: 'group_users',
        params: { id: group.id },
      },
      children: STRINGS.user.plural.capitalized,
      render: (): React.ReactElement => (
        <ScreensUserGroupUserIndex group={group} />
      ),
    },
  ];

  if (userGroup) {
    links.push({
      children: 'Notifications',
      route: {
        to: 'group_settings_notifications',
        params: { id: group.id },
      },
      render: () => <UserGroupNotificationManager />,
    });

    if (userGroup.type === 'owner') {
      links.push({
        children: 'Experiments',
        route: {
          to: 'group_settings_experiment_workflow_template',
          params: { id: group.id },
        },
        render: () => <GroupSettingsExperiment group={group} />,
      });
      links.push({
        children: 'Ordering',
        route: {
          to: 'group_settings_order_info',
          params: { id: group.id },
        },
        render: () => <GroupSettingsOrdering group={group} />,
      });
      links.push({
        children: 'Auto-Sharing',
        route: {
          to: 'group_settings_auto_sharing',
          params: { id: group.id },
        },
        render: () => <GroupAutoSharingList group={group} />,
      });
      links.push({
        children: 'Export',
        route: {
          to: 'group_settings_export',
          params: { id: group.id },
        },
        render: () => <Export />,
      });
    }
  }

  return (
    <LayoutMainContentCenter>
      <InformationCard
        primaryInfo={
          <div className={styles.primaryInfo}>
            <div>
              <Icon
                name={ICONS.navigation.settings}
                size="large"
                color="grey"
              />
              Workspace Settings
            </div>
          </div>
        }
        bottom={<LayoutLinks links={links} inverted />}
        className={styles.card}
      />
    </LayoutMainContentCenter>
  );
};

export default GroupShowSettings;
