/**
 * Labstep
 */

import colDefName from 'labstep-web/components/Entity/DataGrid/colDefs/name';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import { OrderRequest } from 'labstep-web/models';
import OrderRequestShowCenterSecondaryInfo from 'labstep-web/screens/OrderRequest/Show/Center/SecondaryInfo';
import React from 'react';

export const colDefOrderRequestName: ColDef<OrderRequest> = {
  ...colDefName<OrderRequest>(OrderRequest.entityName, {
    getEntityPreviewProps: (orderRequest) => ({
      prefix: 'Request for',
      nameEditable: false,
      secondaryInfo: (
        <OrderRequestShowCenterSecondaryInfo
          orderRequest={orderRequest as OrderRequest}
          showStatusLog={false}
        />
      ),
    }),
    getMetadataPreviewEntity: (orderRequest) => orderRequest.resource,
  }),
  headerName: 'Request for',
  editable: false,
};
