/**
 * Labstep
 *
 * @module components/Molecule/Show
 * @desc Molecule show component
 */

import React from 'react';
import CoreChemDraw from 'labstep-web/core/ChemDraw';
import { EntityUpdateContainer } from 'labstep-web/containers';
import { Molecule } from 'labstep-web/models';
import {
  IMoleculeShowProps,
  IMoleculeShowContainerProps,
} from './types';

export const MoleculeShow: React.FC<IMoleculeShowProps> = ({
  molecule,
  status,
  canEdit,
  ...rest
}) => (
  <CoreChemDraw
    molecule={molecule}
    disableSave={status.isFetching}
    readOnly={!canEdit}
    {...rest}
  />
);

export const MoleculeShowContainer: React.FC<
  IMoleculeShowContainerProps
> = ({ id, ...rest }) => (
  <EntityUpdateContainer entityName={Molecule.entityName} id={id}>
    {({ entity: molecule, ...containerProps }) => (
      <MoleculeShow
        molecule={molecule}
        {...containerProps}
        {...rest}
      />
    )}
  </EntityUpdateContainer>
);

export default MoleculeShowContainer;
