/**
 * Labstep
 *
 * @module components/Experiment/Form/ShowEdit/State/ExperimentActionEdit
 * @desc Button to switch to edit mode
 */

import React from 'react';
import classNames from 'classnames';
import ExperimentActionEdit from 'labstep-web/components/Experiment/Form/ShowEdit/State/ExperimentActionEdit';
import proseMirrorStyles from 'labstep-web/prosemirror/styles.module.scss';
import { ButtonEditProps } from './types';
import styles from './styles.module.scss';

export const ButtonEdit: React.FC<ButtonEditProps> = (props: {
  experiment;
  onClick;
}) => (
  <div
    className={classNames(
      proseMirrorStyles.topMenu,
      styles.editButton,
    )}
  >
    <ExperimentActionEdit {...props} />
  </div>
);

export default ButtonEdit;
