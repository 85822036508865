/**
 * Labstep
 *
 * @module components/ProtocolCollection/ProtocolCollection
 * @desc Table listing all protocolCollections
 */

import React from 'react';
import { ProtocolCollection } from 'labstep-web/models';
import ProtocolCollectionActionMenu from 'labstep-web/components/ProtocolCollection/Action/Menu';
import EntityTable from 'labstep-web/components/Entity/Table';
import { DefaultSort } from 'labstep-web/components/Entity/Table/SortOptions/types';
import ProtocolCollectionActionRun from '../Action/Run';
import { IProtocolCollectionTableProps } from './types';

export const ProtocolCollectionTable: React.FC<
  IProtocolCollectionTableProps
> = ({ protocolCollections, actions, ...rest }) => {
  const extraColumns = [
    {
      header: '',
      content: (protocolCollection: ProtocolCollection) => (
        <ProtocolCollectionActionRun
          protocolCollection={protocolCollection}
        />
      ),
      cellProps: { width: 2 },
    },
  ];

  return (
    <EntityTable
      {...rest}
      id="protocol-collection-table"
      entities={protocolCollections}
      extraColumns={extraColumns}
      sortOptions={{
        name: {
          label: 'Name',
          default_sort: DefaultSort.asc,
        },
        updated_at: {
          label: 'Last Updated',
          default_sort: DefaultSort.desc,
        },
        experiment_created_at: {
          label: 'Last Used',
          default_sort: DefaultSort.desc,
        },
        experiment_count: {
          label: 'Most Used',
          default_sort: DefaultSort.desc,
        },
      }}
      editableName
      actionMenu={({ entity }) => (
        <ProtocolCollectionActionMenu
          protocolCollection={entity}
          actions={actions}
        />
      )}
    />
  );
};

export default ProtocolCollectionTable;
