/**
 * Labstep
 *
 * @module components/Signature/List
 * @desc Signature list
 */

import React from 'react';
import SignatureItem from 'labstep-web/components/Signature/Item';
import { ISignatureListProps } from './types';
import styles from './styles.module.scss';

const SignatureList: React.FC<ISignatureListProps> = ({
  experimentWorkflow,
}) => (
  <div className={styles.list}>
    {experimentWorkflow.signatures.map((signature) => (
      <SignatureItem
        key={signature.id}
        signature={signature}
        experimentWorkflow={experimentWorkflow}
      />
    ))}
  </div>
);

export default SignatureList;
