/**
 * Labstep
 *
 * @module components/CustomIdentifierSet/Form/Create
 * @desc Create Form for Custom Identifier Set
 */

import React from 'react';
import FormCreate from 'labstep-web/core/Form/Create';
import { CustomIdentifierSet, Group } from 'labstep-web/models';
import {
  fieldPrefix,
  fieldPreview,
  fieldZeroPadding,
} from 'labstep-web/components/CustomIdentifierSet/Form/fields';
import { preSubmit } from 'labstep-web/components/CustomIdentifierSet/Form/utils';
import { ICustomIdentifierSetFormCreateProps } from './types';

export const CustomIdentifierSetFormCreate: React.FC<
  ICustomIdentifierSetFormCreateProps
> = ({ groupId, options }) => (
  <FormCreate
    autoFocus
    entityName={CustomIdentifierSet.entityName}
    parentName={Group.entityName}
    parentId={groupId}
    childKeyName="experiment_workflow_custom_identifier_set"
    fields={[fieldPrefix, fieldZeroPadding, fieldPreview]}
    options={options}
    preSubmit={preSubmit}
    submitButtonText="Save"
  />
);

export default CustomIdentifierSetFormCreate;
