/**
 * Labstep
 *
 * @module state/epics/notification
 * @desc Redux epic for notification actions
 */

import { EMPTY, Observable, concat, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { StateObservable } from 'redux-observable';
import { Action } from 'labstep-web/models';
import {
  readEntitiesCount,
  readEntitiesCursor,
  showToast,
  updateEntity,
} from 'labstep-web/state/actions';
import {
  selectCursorItems,
  selectCursorTotal,
} from 'labstep-web/state/selectors';
import { generateNewDateString } from 'labstep-web/services/date.service';
import { REGISTER_USER_IS_NEW } from 'labstep-web/state/constants';
import omit from 'lodash/omit';

/**
 * After settings notification viewed, reload count.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onReloadCountActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SUCCESS_SET_ALL_NOTIFICATIONS_AS_VIEWED',
    ),
    map(() => {
      return readEntitiesCount('notification', { is_viewed: false });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_NOTIFICATION_RELOAD_COUNT',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * After getting notifications count,
 * re-read notifications if response is greater than 0
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onReadMoreActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<any>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SUCCESS_READ_COUNT_NOTIFICATION',
    ),
    map((action: Action) => {
      const total: number = selectCursorTotal(
        state$.value,
        'notification',
        {
          get_count: 1,
          is_viewed: false,
        },
      );
      if (action.payload > total) {
        return readEntitiesCursor(
          'notification',
          { cursor: '-1', count: 5 },
          {},
          {},
        );
      }
      return {
        type: 'SUCCESS_READ_COUNT_NOTIFICATION_DONE',
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_NOTIFICATION_READ_MORE',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Displays annoucement notifications as toast.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onAnnouncementToastActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<any>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SUCCESS_READ_CURSOR_NOTIFICATION',
    ),
    mergeMap((action: Action) => {
      const notifications: any = selectCursorItems(
        state$.value,
        'notification',
        omit(action.meta.params, ['cursor']),
      );
      const announcements: any = notifications.filter(
        (notification: any) =>
          notification &&
          notification.type === 'announcement' &&
          !notification.viewed_at,
      );
      const toasts: Action[] = announcements.map(
        (notification: any) =>
          showToast({
            type: 'success',
            message: notification.message,
            action_type: 'CUSTOM_TOAST',
            options: {
              header: 'Labstep announcement',
              timeout: 10000,
            },
          }),
      );

      if (announcements.length === 0) {
        return EMPTY;
      }

      const actions: Action[] = toasts;

      announcements.forEach((announcement) => {
        actions.push(
          updateEntity('notification', announcement.id, {
            viewed_at: generateNewDateString(),
          }),
        );
      });

      return from(actions);
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_NOTIFICATION_ANNOUNCEMENT_TOAST',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * After signup, re-read notifications count to trigger custom toast
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onSignupAnnouncementToastActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === REGISTER_USER_IS_NEW),
    map(() =>
      readEntitiesCursor(
        'notification',
        { count: 5 },
        {},
        { refresh: true, mount: true },
      ),
    ),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_NOTIFICATION_SIGNUP_ANNOUNCEMENT_TOAST',
          payload: err,
        }),
        source$,
      ),
    ),
  );
