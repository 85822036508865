/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/View/Manager
 * @desc Manager for ExperimentWorkflow Table view
 */

import React from 'react';
import ExperimentWorkflowTable from 'labstep-web/components/ExperimentWorkflow/Table';
import ExperimentWorkflowTimeline from 'labstep-web/components/ExperimentWorkflow/Timeline';
import { withUiPersistent } from 'labstep-web/containers';
import { ParamsContext } from 'labstep-web/hoc/Params/context';
import Icon from 'labstep-web/core/Icon';
import { ParamsHOC } from 'labstep-web/hoc';
import ExperimentWorkflowViewManagerSelect from './Select';
import {
  IExperimentWorkflowTableViewOptionsProps,
  IExperimentWorkflowViewManagerContainerProps,
  IExperimentWorkflowViewManagerProps,
} from './types';

export const viewOptions: IExperimentWorkflowTableViewOptionsProps[] =
  [
    {
      value: 'table',
      label: (
        <p>
          <Icon name="table" /> Table View
        </p>
      ),
    },
    {
      value: 'timeline',
      label: (
        <p>
          <Icon name="calendar alternate" /> Timeline View
        </p>
      ),
    },
  ];

export const ExperimentWorkflowViewManager: React.FC<
  IExperimentWorkflowViewManagerProps
> = ({
  actions,
  isTemplate,
  showCollaborators,
  experimentWorkflows,
  uiPersistent,
  setUiExperimentWorkflowView,
  showMoreAction,
  clearAll,
  ...props
}) => {
  const isTimelineActive =
    uiPersistent.experiment_workflow_view === 'timeline';
  const toggleAction = !isTemplate && (
    <ExperimentWorkflowViewManagerSelect
      value={isTimelineActive ? viewOptions[1] : viewOptions[0]}
      options={viewOptions}
      onChange={(value) => {
        setUiExperimentWorkflowView(value);
        if (value === 'table') {
          clearAll();
        }
      }}
    />
  );
  return isTimelineActive && !isTemplate ? (
    <ExperimentWorkflowTimeline
      actions={actions}
      experimentWorkflows={experimentWorkflows}
      primaryColumnHeader={toggleAction}
      isTemplate={isTemplate}
      showMoreAction={showMoreAction}
      {...props}
    />
  ) : (
    <ExperimentWorkflowTable
      showCollaborators={showCollaborators}
      experimentWorkflows={experimentWorkflows}
      actions={actions}
      isTemplate={isTemplate}
      primaryColumnHeader={toggleAction}
      {...props}
    />
  );
};

export const ExperimentWorkflowViewManagerContainer: React.FC<
  IExperimentWorkflowViewManagerContainerProps
> = (props) => (
  <ParamsHOC historyAction="replace">
    <ParamsContext.Consumer>
      {({ clearAll }) => (
        <ExperimentWorkflowViewManager
          clearAll={clearAll}
          {...props}
        />
      )}
    </ParamsContext.Consumer>
  </ParamsHOC>
);

export default withUiPersistent(
  ExperimentWorkflowViewManagerContainer,
);
