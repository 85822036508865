/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Card/EmptyState
 * @desc Empty state for group
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import ExperimentWorkflowActionCreateMenu from 'labstep-web/components/ExperimentWorkflow/Action/CreateMenu';
import ExperimentWorkflowActionCreateTemplate from 'labstep-web/components/ExperimentWorkflow/Action/CreateTemplate';
import {
  ExperimentWorkflow,
  ProtocolCollection,
} from 'labstep-web/models';
import { IExperimentWorkflowEmptyStateProps } from './types';

const explanation = `
Use ${ExperimentWorkflow.getHumanReadableEntityName(
  true,
)} to document your activities in the lab. Keep all your results and data linked to the ${ProtocolCollection.getHumanReadableEntityName(
  true,
)} that produced them. Track the specific inventory items and amounts used in each ${ProtocolCollection.getHumanReadableEntityName()}.
`;

export const ExperimentWorkflowEmptyState: React.FC<
  IExperimentWorkflowEmptyStateProps
> = (props) => (
  <EmptyState
    title="No experiments"
    explanation={explanation}
    src="/img/empty-state/experiments.svg"
    action={<ExperimentWorkflowActionCreateMenu />}
    {...props}
  />
);

export const ExperimentWorkflowTemplateEmptyState: React.FC<
  IExperimentWorkflowEmptyStateProps
> = (props) => (
  <EmptyState
    title="No experiment templates"
    explanation="Use templates to ensure a consistent structure to your entries or to save time documenting common workflows."
    src="/img/empty-state/experiments.svg"
    action={<ExperimentWorkflowActionCreateTemplate />}
    {...props}
  />
);

export default ExperimentWorkflowEmptyState;
