/**
 * Labstep
 */

import { ResourceLocation } from 'labstep-web/models';
import { IActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { IResourceLocationActionDeleteProps } from '../Delete/types';

// eslint-disable-next-line no-shadow
export enum Action {
  delete = 'delete',
  update_outer = 'update_outer',
  assign = 'assign',
  edit = 'edit',
  view = 'view',
  create_sub = 'create_sub',
  stock_check = 'stock_check',
}

export interface IResourceLocationActionMenuProps
  extends Pick<IActionMenuProps, 'type'> {
  resourceLocation: ResourceLocation;
  actions: Action[];
  options?: IResourceLocationActionDeleteProps['options'];
}
