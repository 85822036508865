/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/ShowEdit/Name
 * @desc ShowEdit for name for protocol value
 */

import React from 'react';
import ShowEdit from 'labstep-web/core/Form/ShowEdit/Text';
import { protocolValueFieldName } from 'labstep-web/components/ProtocolValue/Form/fields';
import { ProtocolValue } from 'labstep-web/models';
import { IProtocolValueFormShowEditNameProps } from './types';

const ProtocolValueFormShowEditName: React.FC<
  IProtocolValueFormShowEditNameProps
> = ({ protocolValue }) => (
  <ShowEdit
    entityName={protocolValue.entityName}
    entity={protocolValue}
    field={protocolValueFieldName}
    placeholder={`Untitled ${ProtocolValue.getHumanReadableEntityName(
      false,
      true,
    )}`}
  >
    {protocolValue.name}
  </ShowEdit>
);

export default ProtocolValueFormShowEditName;
