/* eslint-disable import/no-import-module-exports */
/**
 * Labstep
 *
 * @module app
 * @desc Mount the app with hot reload module
 */

import 'reflect-metadata';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import FullPageError from './labstep-web/components/Layout/FullPageError';
import { APP_VERSION } from './labstep-web/constants';
import { ErrorBoundaryContainer } from './labstep-web/containers/ErrorBoundary';
import store from './labstep-web/state/store';
import './labstep-web/scss/index.scss';
import { setupDropHandler } from './labstep-web/services/drop.service';
import { configService } from './labstep-web/services/config.service';
import App from './labstep-web/components/App';

// Initalises drop handler, services initialised in services/ErrorBoundaries
setupDropHandler();

// Log app version, commit hash and window.location
/* eslint-disable */
console.log('Commit hash is', configService.commitHash);
console.log('App version is', APP_VERSION);
console.log('window.location is', window.location);
/* eslint-enable */

// Handle mounting the root level component (bootstrap the application)
const mount = (Component: () => JSX.Element) => {
  const mountElement = document.getElementById('root');

  // Initialize store

  // eslint-disable-next-line no-inner-declarations
  function Root() {
    return (
      <ErrorBoundaryContainer FallbackComponent={<FullPageError />}>
        <Provider store={store}>
          <Component />
        </Provider>
      </ErrorBoundaryContainer>
    );
  }

  const renderApp = () => render((<Root />) as any, mountElement);

  // Render root level component
  renderApp();

  if (module.hot) {
    module.hot.accept(Component as any, () => {
      render((<Root />) as any, mountElement);
    });
  }
};

mount(App);
