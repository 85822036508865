/**
 * Labstep
 *
 */

import React from 'react';
import PermissionRoleActionUpdateDropdownPill from 'labstep-web/components/PermissionRole/Action/UpdateDropdownPill';
import { PermissionType } from 'labstep-web/models';
import {
  PERMISSION_ROLE_EDITOR,
  PERMISSION_ROLE_OWNER,
  PERMISSION_ROLE_VIEWER,
} from 'labstep-web/constants/roles';
import {
  IBodyProps,
  IGroupActionSetDefaultUserGroupTypeProps,
} from './types';

export const GroupActionSetDefaultUserGroupType: React.FC<
  IGroupActionSetDefaultUserGroupTypeProps
> = ({ group }) => (
  <PermissionRoleActionUpdateDropdownPill
    entity={group}
    withOwner={false}
    body={(permissionRole) => {
      const body: IBodyProps = {
        default_user_group_type: PermissionType.edit,
      };
      if (permissionRole.name === PERMISSION_ROLE_OWNER.name) {
        body.default_user_group_type = PermissionType.owner;
      } else if (
        permissionRole.name === PERMISSION_ROLE_VIEWER.name
      ) {
        body.default_user_group_type = PermissionType.view;
      } else if (
        permissionRole.name !== PERMISSION_ROLE_EDITOR.name
      ) {
        body.default_user_group_permission_role_guid =
          permissionRole.guid;
      }
      return body;
    }}
    currentValue={group.defaultPermissionRoleName}
  />
);

export default GroupActionSetDefaultUserGroupType;
