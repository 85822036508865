/**
 * Labstep
 *
 * @module components/UserOrganization/Action/Update
 * @desc Updates group user entry
 */

import React from 'react';
import EntityActionUpdateDropdownPill from 'labstep-web/components/Entity/Action/Update/DropdownPill';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import { capitalize } from 'labstep-web/services/i18n.service';
import { UserOrganizationType } from 'labstep-web/models/user-organization.model';
import { IUserOrganizationActionUpdateProps } from './types';

export const UserOrganizationActionUpdate: React.FC<
  IUserOrganizationActionUpdateProps
> = ({ userOrganization, authenticatedUserEntity }) =>
  // Only show to admins
  authenticatedUserEntity.user_organizations[0].type === 'admin' ? (
    <EntityActionUpdateDropdownPill
      disabled={!userOrganization.canBeChanged}
      entity={userOrganization}
      items={Object.keys(UserOrganizationType).map((type) => ({
        label: capitalize(type),
        body: { type },
      }))}
      currentValue={capitalize(userOrganization.type)}
    />
  ) : null;

export default withAuthenticatedUserEntity(
  UserOrganizationActionUpdate,
);
