/**
 * Labstep
 */

import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { Experiment, ProtocolTimer } from 'labstep-web/models';
import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const protocolTimerFormFields: IFieldWithoutActionProps[] = [
  {
    fieldType: FieldType.Text,
    name: 'name',
    validation: rules.protocol_timer.name,
    placeholder: 'Timer name',
    fieldLabel: 'Name',
    fieldExplainer: `Give your ${getHumanReadableEntityName(
      ProtocolTimer.entityName,
    )} a name so you can jump straight to it when running an ${getHumanReadableEntityName(
      Experiment.entityName,
    )}.`,
  },
  {
    fieldType: FieldType.Text,
    validation: rules.protocol_timer.hours,
    name: 'hours',
    placeholder: 'Hours',
    fieldLabel: 'Hours',
  },
  {
    fieldType: FieldType.Text,
    validation: rules.protocol_timer.minutes,
    name: 'minutes',
    placeholder: 'Minutes',
    fieldLabel: 'Minutes',
  },
  {
    fieldType: FieldType.Text,
    validation: rules.protocol_timer.seconds,
    name: 'seconds',
    placeholder: 'Seconds',
    fieldLabel: 'Seconds',
  },
];
