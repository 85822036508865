/**
 * Labstep
 *
 * @module components/Group/PurchaseOrderTemplate
 * @desc Will create purchase order template if null / shows metadata manager
 */

import React from 'react';
import PurchaseOrderFormShowEditCurrency from 'labstep-web/components/PurchaseOrder/Form/ShowEdit/Currency';
import TextWithCount from 'labstep-web/core/Text/WithCount';
import { PurchaseOrder } from 'labstep-web/models';
import GroupTemplate from '..';
import { IGroupPurchaseOrderTemplateProps } from './types';

export const GroupPurchaseOrderTemplate: React.FC<
  IGroupPurchaseOrderTemplateProps
> = ({ group }) => (
  <GroupTemplate
    entityName={PurchaseOrder.entityName}
    group={group}
    field="purchase_order_template_id"
    extraRows={[
      {
        header: (
          <TextWithCount
            header
            text="Default Currency"
            icon="dollar"
          />
        ),
        content: (purchaseOrder: PurchaseOrder) => (
          <PurchaseOrderFormShowEditCurrency
            purchaseOrder={purchaseOrder}
          />
        ),
      },
    ]}
  />
);

export default GroupPurchaseOrderTemplate;
