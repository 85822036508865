/**
 * Labstep
 *
 * @module components/ProtocolCollection/Action/Create
 * @desc Create Action for protocol
 */

import React from 'react';
import { navigation } from 'labstep-web/services/navigation';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { IOptions } from 'labstep-web/typings';
import { withRouter } from 'react-router';
import { IProtocolCollectionActionCreateProps } from './types';

export const ProtocolCollectionActionCreate: React.FC<
  IProtocolCollectionActionCreateProps
> = ({ history, body = {}, actionComponentProps }) => {
  const options: IOptions = {
    onSuccess: ({ response }) => {
      history.push(
        navigation.get('protocol_collection_show', {
          id: response.result,
        }),
      );
    },
  };

  return (
    <EntityActionCreate
      entityName="protocol_collection"
      actionComponentProps={{
        type: 'button',
        ...actionComponentProps,
      }}
      body={body}
      options={options}
    />
  );
};

export default withRouter(ProtocolCollectionActionCreate);
