/**
 * Labstep
 *
 * @module components/Entity/Search
 * @desc Generic component for rendering a list of entities with search bar + filters
 */

import React, { useMemo } from 'react';
import Filter from 'labstep-web/components/Filter';
import EntityEmptyState from 'labstep-web/components/Entity/Card/EmptyState';
import Placeholder from 'labstep-web/core/Placeholder';
import { SearchHOC } from 'labstep-web/hoc';
import { Comment } from 'labstep-web/models';
import { DataGridContextProvider } from 'labstep-web/core/DataGrid/context';
import { objectOrFunction } from 'labstep-web/services/react.service';
import { BulkSelectContainer } from 'labstep-web/containers';
import Paginator from 'labstep-web/core/Paginator';
import EntitySearchContext from '../context';
import ActionBar from '../ActionBar';
import styles from '../styles.module.scss';
import { getFilteredEntities } from '../utils';
import {
  IEntitySearchContainerProps,
  IEntitySearchProps,
} from './types';

export class EntitySearch extends React.Component<IEntitySearchProps> {
  public componentDidUpdate(prevProps: IEntitySearchProps) {
    const { total, clearAll, entityName } = this.props;

    /** Clear filters to prevent mismatch of new item and active filters
     * Only enabled for comments
     */
    if (
      total > prevProps.total &&
      entityName === Comment.entityName
    ) {
      clearAll();
    }
  }

  public render() {
    const {
      status,
      entities,
      children,
      entityName,
      action,
      searchKey,
      noResultsMessage,
      searchParams,
      filters,
      errorComponent,
      bulkActions,
      hideFilters,
      nextCursor,
      currentCursor,
      read,
      reverse,
      initialSearchParams,
      hideAction,
      emptyStateAction,
      searchBarProps,
      showEmptyStateOnSearchOnly,
      isTemplate,
      sortOptions,
      hideEmptyState,
    } = this.props;

    const emptyState = (
      <EntityEmptyState
        entityName={entityName}
        noResultsMessage={noResultsMessage}
        searchParams={searchParams}
        initialSearchParams={initialSearchParams}
        action={emptyStateAction}
      />
    );

    if (
      status &&
      status.error &&
      [400, 500].indexOf(status.error.status) > -1 &&
      errorComponent
    ) {
      return errorComponent;
    }

    const childrenEntities = entities.map((entity, index) => {
      if (reverse) {
        return entities[entities.length - 1 - index];
      }
      return entity;
    });

    const noEmptyState =
      (showEmptyStateOnSearchOnly &&
        !('search_query' in searchParams)) ||
      hideEmptyState;

    let component = <Placeholder />;
    if (!(status && status.isFetching && !status.cached)) {
      component =
        entities.length === 0 && !noEmptyState ? (
          emptyState
        ) : (
          <Paginator
            infinite={false}
            reverse={reverse}
            read={read}
            status={status}
            nextCursor={nextCursor}
            currentCursor={currentCursor}
            hideAction={hideAction}
          >
            {({ showMoreAction, loadMore, hasMore }) =>
              children({
                entities: childrenEntities,
                nextCursor,
                currentCursor,
                status,
                emptyState,
                searchParams,
                showMoreAction,
                read,
                loadMore,
                hasMore,
              })
            }
          </Paginator>
        );
    }

    return (
      <DataGridContextProvider>
        {!hideFilters && (
          <div className={styles.filterContainer}>
            <Filter
              action={objectOrFunction(action, {
                searchParams,
              })}
              filters={filters}
              searchKey={searchKey}
              entityName={entityName}
              searchBarProps={searchBarProps}
              isTemplate={isTemplate}
              sortOptions={sortOptions}
            />
          </div>
        )}
        <>
          <ActionBar
            entityName={entityName}
            bulkActions={bulkActions}
          />
          <>{component}</>
        </>
      </DataGridContextProvider>
    );
  }
}

const EntitySearchContainer: React.FC<
  IEntitySearchContainerProps
> = ({
  params,
  entityName,
  historyAction,
  additionalFilters,
  initialSearchParams,
  noSearchParam,
  ...rest
}) => {
  const value = useMemo(
    () => ({
      sortOptions: rest.sortOptions,
    }),
    [rest.sortOptions],
  );
  return (
    <SearchHOC
      entityName={entityName}
      params={params}
      historyAction={historyAction}
      isCursor
      noSearchParam={noSearchParam}
      initialParams={initialSearchParams}
    >
      {({
        entities,
        total,
        nextCursor,
        currentCursor,
        ...restSearchHocParams
      }) => {
        const filteredEntities = getFilteredEntities(
          entities,
          restSearchHocParams.searchParams,
          rest.filters,
          additionalFilters,
        );
        const totalExcludingFilteredEntities =
          total - (entities.length - filteredEntities.length);
        return (
          <BulkSelectContainer
            total={total}
            visibleEntities={filteredEntities}
          >
            <EntitySearchContext.Provider value={value}>
              <EntitySearch
                entityName={entityName}
                entities={filteredEntities}
                total={totalExcludingFilteredEntities}
                nextCursor={nextCursor}
                currentCursor={currentCursor}
                isTemplate={
                  !!objectOrFunction(params, {})?.is_template
                }
                initialSearchParams={initialSearchParams}
                {...rest}
                {...restSearchHocParams}
              />
            </EntitySearchContext.Provider>
          </BulkSelectContainer>
        );
      }}
    </SearchHOC>
  );
};

export default EntitySearchContainer;
