/**
 * Labstep
 */

import { IWithAuthenticatedUserEntityInjectedProps } from 'labstep-web/containers/AuthenticatedUser/types';
import { IActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { Comment } from 'labstep-web/models';

// eslint-disable-next-line no-shadow
export enum Action {
  pin = 'pin',
  comment = 'comment',
  edit = 'edit',
  delete = 'delete',
}

export interface ICommentActionMenuProps
  extends Pick<
      IActionMenuProps,
      Exclude<keyof IActionMenuProps, 'children'>
    >,
    IWithAuthenticatedUserEntityInjectedProps {
  toggle: any;
  comment: Comment;
  actions?: Action[];
}
