/**
 * Labstep
 */

import { EntityReadEntityContainer } from 'labstep-web/containers';
import ChemDrawNewReactionSvg from 'labstep-web/core/ChemDraw/ReactionSvg';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { Molecule } from 'labstep-web/models';
import React from 'react';
import {
  IMoleculeStructureProps,
  IMoleculeStructureContainerProps,
} from './types';

export const MoleculeStructure: React.FC<IMoleculeStructureProps> = ({
  molecule,
}) => <ChemDrawNewReactionSvg svgs={[molecule.svg]} size={40} />;

/**
 * Molecule structure svg
 * Container to read svg on mount if not loaded
 */
const MoleculeStructureContainer: React.FC<
  IMoleculeStructureContainerProps
> = ({ molecule, fallback = null }) => (
  <EntityReadEntityContainer
    entityName={molecule.entityName}
    id={molecule.idAttr}
  >
    {({ entity }: { entity: Molecule }): JSX.Element | null => {
      if (entity.svg === undefined) {
        return (
          <ReadOnMountHOC
            type="entities"
            entityName={entity.entityName}
            params={{
              get_single: 1,
              guid: entity.idAttr,
            }}
            loading={{ loader: 'grid_cell' }}
            children={({ entity: entityWithSvg }) =>
              entityWithSvg.svg ? (
                <MoleculeStructure molecule={entityWithSvg} />
              ) : (
                fallback
              )
            }
          />
        );
      }
      return entity.svg ? (
        <MoleculeStructure molecule={entity} />
      ) : (
        fallback
      );
    }}
  </EntityReadEntityContainer>
);

export default MoleculeStructureContainer;
