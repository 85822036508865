/**
 * Labstep
 *
 * @module prosemirror/components/NodeView
 * @desc ProseMirror NodeViews
 */

import React from 'react';
import { createReactNodeView } from 'labstep-web/prosemirror/components/ReactNodeView';
import ConditionsNodeView from 'labstep-web/prosemirror/components/NodeView/Conditions';
import ExperimentNodeView from 'labstep-web/prosemirror/components/NodeView/Experiment';
import ProtocolValueNodeView from 'labstep-web/prosemirror/components/NodeView/ProtocolValue';
import ProtocolTableNodeView from 'labstep-web/prosemirror/components/NodeView/ProtocolTable';
import ProtocolTimerNodeView from 'labstep-web/prosemirror/components/NodeView/ProtocolTimer';
import ProtocolStepNodeView from 'labstep-web/prosemirror/components/NodeView/ProtocolStep';
import MetadataNodeView from 'labstep-web/prosemirror/components/NodeView/Metadata';
import MoleculeNodeView from 'labstep-web/prosemirror/components/NodeView/Molecule';
import FileNodeView from 'labstep-web/prosemirror/components/NodeView/File';
import ReferenceNodeView from 'labstep-web/prosemirror/components/NodeView/Reference';
import { Experiment, Protocol } from 'labstep-web/models';
import CodeNodeView from 'labstep-web/prosemirror/components/NodeView/Code';
import JupyterNotebookNodeView from 'labstep-web/prosemirror/components/NodeView/JupyterNotebook';
import ExperimentWorkflowLinkNodeView from 'labstep-web/prosemirror/components/NodeView/ExperimentWorkflowLink';
import { ErrorBoundaryContainer } from 'labstep-web/containers/ErrorBoundary';
import ViewInline from 'labstep-web/prosemirror/components/Inline/View';

export const getNodeViews = (
  entity: Experiment | Protocol,
  handleCreatePortal,
  timestamp?: string,
) => {
  return [
    {
      key: 'experiment',
      component: ExperimentNodeView,
    },
    {
      key: 'experiment_value',
      component: ProtocolValueNodeView,
    },
    {
      key: 'experiment_timer',
      component: ProtocolTimerNodeView,
    },
    {
      key: 'experiment_table',
      component: ProtocolTableNodeView,
    },
    {
      key: 'experiment_step',
      component: ProtocolStepNodeView,
    },
    {
      key: 'protocol_value',
      component: ProtocolValueNodeView,
    },
    {
      key: 'protocol_timer',
      component: ProtocolTimerNodeView,
    },
    {
      key: 'protocol_table',
      component: ProtocolTableNodeView,
    },
    {
      key: 'protocol_step',
      component: ProtocolStepNodeView,
    },
    {
      key: 'file',
      component: FileNodeView,
    },
    {
      key: 'reference',
      component: ReferenceNodeView,
    },
    {
      key: 'metadata',
      component: MetadataNodeView,
    },
    {
      key: 'codemirror',
      component: CodeNodeView,
    },
    {
      key: 'jupyter_notebook',
      component: JupyterNotebookNodeView,
    },
    {
      key: 'experiment_workflow_link',
      component: ExperimentWorkflowLinkNodeView,
    },
    {
      key: 'molecule',
      component: MoleculeNodeView,
    },
    {
      key: 'conditions',
      component: ConditionsNodeView,
    },
  ].reduce(
    (result, nodeView) => ({
      ...result,
      [nodeView.key](node, view, getPos, decorations) {
        const Component = nodeView.component;
        return createReactNodeView({
          node,
          view,
          getPos,
          decorations,
          component: (props) => (
            <ErrorBoundaryContainer
              FallbackComponent={<ViewInline entity={null} />}
            >
              <Component
                {...props}
                entity={entity}
                timestamp={timestamp}
              />
            </ErrorBoundaryContainer>
          ),
          onCreatePortal: handleCreatePortal,
        });
      },
    }),
    {},
  );
};
