/**
 * Labstep
 *
 * @module models/custom-identifier-set
 * @desc Typescript export class for CustomIdentifierSet
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';

export const generateCustomIdentifier = (
  prefix: string,
  zero_padding: number,
  number_auto_increment = 0,
) =>
  `${prefix}${String(number_auto_increment + 1).padStart(
    zero_padding,
    '0',
  )}`;

export class CustomIdentifierSet extends Entity {
  static readonly entityName = 'custom_identifier_set';

  static defaultZeroPadding = 3;

  get entityName(): string {
    return CustomIdentifierSet.entityName;
  }

  constructor(data: Partial<CustomIdentifierSet> = {}) {
    super();
    Object.assign(this, data);
  }

  settings: { prefix: string; zero_padding: number };

  number_auto_increment: number;

  @Type(() => Group)
  group: Group;

  getPreview() {
    return generateCustomIdentifier(
      this.settings.prefix,
      this.settings.zero_padding,
      this.number_auto_increment,
    );
  }
}
