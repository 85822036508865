/**
 * Labstep
 *
 * @module components/ProtocolDevice/Action/Create
 * @desc Create a protocolDevice
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import SearchSelect from 'labstep-web/core/Select/Search';
import { CanCreate } from 'labstep-web/components/Entity/Can';
import { EntityCreateContainer } from 'labstep-web/containers';
import { Device, Protocol, ProtocolDevice } from 'labstep-web/models';
import {
  IProtocolDeviceActionCreateContainerProps,
  IProtocolDeviceActionCreateProps,
} from './types';

export const ProtocolDeviceActionCreate: React.FC<
  IProtocolDeviceActionCreateProps
> = ({ parent, create, options, modalProps }) => (
  <Modal
    header="Add Device"
    viewComponent={
      <ActionComponent type="button" text="Add Device" />
    }
    content={({ toggleModal }) => (
      <SearchSelect
        autoFocus
        openMenuOnFocus
        creatable
        entityName={Device.entityName}
        value={null}
        onChange={(option) => {
          create(
            {
              [`${
                parent instanceof Protocol ? 'protocol' : 'experiment'
              }_id`]: parent.id,
              device_id: option.id,
            },
            options,
          );
          toggleModal();
        }}
      />
    )}
    {...modalProps}
  />
);

export const ProtocolDeviceActionCreateContainer: React.FC<
  IProtocolDeviceActionCreateContainerProps
> = (props) => (
  <CanCreate
    parentName={props.parent.entityName}
    parentId={props.parent.id}
    entityName="protocol_device"
  >
    <EntityCreateContainer entityName={ProtocolDevice.entityName}>
      {({ create, status }) => (
        <ProtocolDeviceActionCreate
          create={create}
          status={status}
          {...props}
        />
      )}
    </EntityCreateContainer>
  </CanCreate>
);

export default ProtocolDeviceActionCreateContainer;
