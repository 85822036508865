/**
 * Labstep
 *
 * @module components/Comment/Action/AttachFile
 * @desc Attaches an existing file to a comment
 */

import React from 'react';
import { humanReadableDate } from 'labstep-web/services/date.service';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import Modal from 'labstep-web/core/Modal/Default';
import Button from 'labstep-web/core/Button';
import ActionComponent from 'labstep-web/core/Action/Component';
import { FieldType } from 'labstep-web/core/Form/Reusable/types';
import { object } from 'yup';
import {
  IFileActionAttachContainerProps,
  IFileActionAttachProps,
} from './types';

export const FileActionAttach: React.FC<IFileActionAttachProps> = ({
  onSubmit,
  status,
}) => (
  <ReusableForm
    autoFocus
    submitButton={({ submitForm }) => (
      <Button
        status={status}
        type="submit"
        primary
        onClick={(e) => {
          // To avoid closing any parent modals
          e.preventDefault();
          submitForm();
        }}
      >
        Select File
      </Button>
    )}
    status={status}
    onSubmit={onSubmit as any}
    fields={[
      {
        name: 'file',
        fieldType: FieldType.SearchSelect,
        fieldLabel: 'Data File',
        validation: object().required(),
        skipTransform: true,
        elementProps: {
          ejectFromElasticsearch: true,
          labelKey: 'label',
          entityName: 'file',
          params: { is_external: true, has_parent: false },
          setOptions: (entities) =>
            entities.map((entity) => {
              const { name, created_at } = entity;
              return {
                ...entity,
                label: `${name} - uploaded ${humanReadableDate(
                  created_at,
                )}`,
              };
            }),
        },
      },
    ]}
  />
);

const FileActionAttachContainer: React.FC<
  IFileActionAttachContainerProps
> = ({ status, onSubmit, viewComponent }) => (
  <Modal
    id="modal-folder-watcher"
    header="Select file from watched folder"
    viewComponent={
      viewComponent ||
      (({ toggleModal }) => (
        <ActionComponent
          onClick={toggleModal}
          type="option"
          text="From Folder Watcher"
          icon="hdd"
        />
      ))
    }
    content={({ toggleModal }) => (
      <div>
        <p>
          The Labstep Folder Watcher provides an easy way to
          automatically upload your data files onto Labstep.
          {' Find out how to install the Folder Watcher '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.labstep.com/en/articles/4028093-using-the-labstep-folder-watcher"
          >
            here
          </a>
        </p>
        <FileActionAttach
          status={status}
          onSubmit={(values) => {
            onSubmit(values, { onSuccess: toggleModal });
          }}
        />
      </div>
    )}
  />
);

export default FileActionAttachContainer;
