/**
 * Labstep
 *
 * @module components/ResourceItemExport/Table/fields
 * @desc Fields for resource item export table
 */

import { ICONS } from 'labstep-web/constants';
import {
  Resource,
  ResourceItem,
  ResourceItemStatusValues,
} from 'labstep-web/models';
import { Field } from './types';

export const resourceItemExportTableFields: Field[] = [
  {
    id: 'id',
    icon: ICONS.entity.info.id,
    name: 'Item ID',
    required: true,
    default: true,
    entityName: ResourceItem.entityName,
    content: (resourceItem: ResourceItem) => resourceItem.id,
  },
  {
    id: 'name',
    icon: ICONS.entity.info.name,
    name: 'Item Name',
    required: true,
    default: true,
    entityName: ResourceItem.entityName,
    content: (resourceItem: ResourceItem) => resourceItem.name,
  },
  {
    id: 'location',
    icon: ICONS.resource_location.primary,
    name: 'Location',
    required: false,
    entityName: ResourceItem.entityName,
    content: (resourceItem: ResourceItem) =>
      resourceItem.resource_location &&
      resourceItem.resource_location.name,
  },
  {
    id: 'availability',
    icon: ICONS.resource.info.unavailable,
    name: 'Availability',
    required: false,
    entityName: ResourceItem.entityName,
    content: (resourceItem: ResourceItem) =>
      ResourceItemStatusValues[resourceItem.status],
  },
  {
    id: 'amount_remaining',
    icon: ICONS.resource_item.info.quantity,
    name: 'Amount Remaining',
    required: false,
    entityName: ResourceItem.entityName,
    content: (resourceItem: ResourceItem) =>
      resourceItem.printQuantity,
  },
  {
    id: 'qr_code',
    icon: ICONS.resource_item.info.qr_code,
    name: 'QR code',
    required: false,
    entityName: ResourceItem.entityName,
    content: (resourceItem: ResourceItem) =>
      resourceItem.perma_link.url,
  },
  {
    id: 'resource_item_metadata',
    icon: ICONS.metadata.secondary,
    name: 'Metadata Field',
    required: false,
    custom: true,
    entityName: ResourceItem.entityName,
    content: () => null,
  },
  {
    id: 'resource_id',
    icon: ICONS.entity.info.id,
    name: 'Resource ID',
    required: false,
    entityName: Resource.entityName,
    content: (resourceItem: ResourceItem) => resourceItem.resource.id,
  },
  {
    id: 'resource_name',
    icon: ICONS.entity.info.name,
    name: 'Resource Name',
    required: false,
    default: true,
    entityName: Resource.entityName,
    content: (resourceItem: ResourceItem) =>
      resourceItem.resource.name,
  },
  {
    id: 'resource_metadata',
    icon: ICONS.metadata.secondary,
    name: 'Metadata Field',
    required: false,
    custom: true,
    entityName: Resource.entityName,
    content: () => null,
  },
];

export const getCustomMetadataField = (
  id: string,
  label: string,
  entityName: string,
): Field => ({
  id,
  entityName,
  icon: ICONS.metadata.secondary,
  name: label,
  required: false,
  content: (resourceItem: ResourceItem) => {
    const entity =
      entityName === Resource.entityName
        ? resourceItem.resource
        : resourceItem;
    const metadata = entity.metadata_thread.metadatas.find(
      (x) =>
        (x.template || x).label?.toLowerCase() ===
        label.toLowerCase(),
    );
    return metadata && metadata.printValue;
  },
});

export default resourceItemExportTableFields;
