/**
 * Labstep
 *
 * @module components/OrderRequest/Action/View
 * @desc Button linking to orderRequest show
 */

import React from 'react';
import EntityActionView from 'labstep-web/components/Entity/Action/View';
import { OrderRequest } from 'labstep-web/models';
import { IOrderRequestActionViewProps } from './types';

const OrderRequestActionView: React.FC<
  IOrderRequestActionViewProps
> = ({ orderRequest }) => (
  <EntityActionView
    entityName={OrderRequest.entityName}
    params={{ id: orderRequest.id }}
  />
);

export default OrderRequestActionView;
