/**
 * Labstep
 *
 * @module core/Form/ShowEdit/SearchSelect
 * @desc A showedit component for search select
 */

import React from 'react';
import { EntityUpdateContainer } from 'labstep-web/containers';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IOptions } from 'labstep-web/typings';
import {
  IFormShowEditSearchSelectContainerProps,
  IFormShowEditSearchSelectProps,
  ParentEntityType,
} from './types';
import FormShowEditSearchSelectToggle from './Toggle';

export const FormShowEditSearchSelectContainer = <
  TParentEntity extends ParentEntityType,
>({
  parentEntity,
  childKeyName,
  childEntityName,
  optimistic = true,
  disabled,
  children,
  childIdAttr = 'id',
}: IFormShowEditSearchSelectContainerProps<TParentEntity>) => {
  const canEdit = useHasAccess(
    parentEntity.entityName,
    parentEntity.idAttr,
    Action.edit,
  );
  return (
    <EntityUpdateContainer
      entityName={parentEntity.entityName}
      id={parentEntity.idAttr}
      optimistic={optimistic}
    >
      {({ update, status }) => {
        const childKey = childKeyName || childEntityName;
        const value =
          parentEntity[childKey as keyof typeof parentEntity] || null;
        const onChange = (
          option: (typeof parentEntity)[keyof typeof parentEntity],
          options?: IOptions,
        ) => {
          update(
            {
              [`${childKey}_${childIdAttr}`]: option
                ? option[childIdAttr as keyof typeof option]
                : null,
            },
            options,
          );
        };
        const canEditAndNotDisabled = canEdit && !disabled;
        const hasValue = Boolean(value);
        return children({
          status,
          onChange,
          canEdit: canEditAndNotDisabled,
          hasValue,
          value,
        });
      }}
    </EntityUpdateContainer>
  );
};

const FormShowEditSearchSelect = <T extends ParentEntityType>({
  parentEntity,
  childEntityName,
  childKeyName,
  optimistic,
  disabled,
  childIdAttr,
  ...rest
}: IFormShowEditSearchSelectProps<T>) => (
  <FormShowEditSearchSelectContainer
    parentEntity={parentEntity}
    childKeyName={childKeyName}
    childEntityName={childEntityName}
    optimistic={optimistic}
    disabled={disabled}
    childIdAttr={childIdAttr}
  >
    {({ onChange, canEdit, hasValue, value }) => (
      <FormShowEditSearchSelectToggle
        entityName={childEntityName}
        isTemplate={childKeyName === 'template'}
        hasValue={hasValue}
        value={value}
        canEdit={canEdit}
        onChange={onChange}
        {...rest}
      />
    )}
  </FormShowEditSearchSelectContainer>
);

export default FormShowEditSearchSelect;
