/**
 * Labstep
 *
 * @module models/entity-export
 * @desc Typescript export class for EntityExport
 */

import { Entity } from 'labstep-web/models/entity.model';

export class EntityExport extends Entity {
  static readonly entityName = 'entity_export';

  get entityName(): string {
    return EntityExport.entityName;
  }

  static TYPE_PRINT = 'print';

  constructor(data: Partial<EntityExport> = {}) {
    super();
    Object.assign(this, data);
  }

  created_at!: string;
}
