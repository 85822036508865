/**
 * Labstep
 *
 * @module components/ResearchArea/Manager
 * @desc Manage research areas
 */

import React from 'react';
import ToggleEntityList from 'labstep-web/components/Entity/List/Toggle';
import ResearchAreaItem from 'labstep-web/components/ResearchArea/Item';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ResearchArea } from 'labstep-web/models';
import { IResearchAreaManagerProps } from './types';

const ResearchAreaManager: React.FC<IResearchAreaManagerProps> = ({
  user,
}) => {
  const canEdit = useHasAccess(user.entityName, user.id, Action.edit);
  return (
    <div id="research-area-card">
      <ToggleEntityList
        extraSearchParams={{ is_verified: true }}
        creatable
        createKey="label"
        actionMessageIcon="graduation cap"
        parent={user.profile}
        entityName="research_area"
        list={({ entities }: { entities: ResearchArea[] }) => (
          <div>
            {entities.map((entity) => (
              <ResearchAreaItem
                key={entity.id}
                profile={user.profile}
                researchArea={entity}
                remove={canEdit}
              />
            ))}
          </div>
        )}
      />
    </div>
  );
};

export default ResearchAreaManager;
