/**
 * Labstep
 *
 * @module containers/ResendVerificationEmail
 * @desc Resend email confirmation container
 */

import { connect } from 'react-redux';
import { resendConfirmationEmail } from 'labstep-web/state/actions';
import { selectResendConfirmationEmailStatus } from 'labstep-web/state/selectors';

export const ConfirmEmail = ({
  status,
  resendVerificationEmail,
  children,
}) => {
  const props = {
    status,
    resendVerificationEmail,
  };
  return children(props);
};

const mapStateToProps = (state) => ({
  status: selectResendConfirmationEmailStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  resendVerificationEmail() {
    dispatch(resendConfirmationEmail());
  },
});

export const ResendVerificationEmailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmEmail);
