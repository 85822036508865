/**
 * Labstep
 *
 */

import {
  PermissionRoleSettingsPostParameters,
  PermissionRoleSections,
} from 'labstep-web/constants/roles';
import {
  PermissionRole,
  PermissionRoleSetting,
} from 'labstep-web/models';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { ICreateAction } from 'labstep-web/typings';

/**
 * Human readable labels for each permission type
 */
export const LABELS_FOR_PERMISSIONS = {
  all: 'Always',
  none: 'Never',
  author: 'Author',
  assigned: 'If Assigned',
  custom: 'If Assigned',
};

/**
 * @description Sections for SimpleTableCollapsible
 */
export const sections = Object.keys(PermissionRoleSections).map(
  (entityName, index) => ({
    isInitiallyUncollapsed: index === 0,
    collapsible: true,
    name: getHumanReadableEntityName(entityName, true, true),
    rows: Object.keys(PermissionRoleSections[entityName]).map(
      (permission) => ({
        name: permission,
        entityName,
        permission,
      }),
    ),
  }),
);

/**
 * @description Function to get items for EntityActionUpdateDropdownPill
 */
export const getItems = (
  permissionRole: PermissionRole,
  entityName: string,
  permission: string,
  create: ICreateAction,
) => {
  const items = [
    {
      label: LABELS_FOR_PERMISSIONS.all,
      onClick: () => {
        create(
          {
            type: 'all',
            ...PermissionRoleSettingsPostParameters[entityName][
              permission
            ],
          },
          {},
          permissionRole.entityName,
          permissionRole.guid,
        );
      },
    },
    {
      label: LABELS_FOR_PERMISSIONS.none,
      onClick: () => {
        create(
          {
            type: 'none',
            ...PermissionRoleSettingsPostParameters[entityName][
              permission
            ],
          },
          {},
          permissionRole.entityName,
          permissionRole.guid,
        );
      },
    },
  ];
  if (permission !== 'create') {
    const label = 'If Assigned';
    items.splice(1, 0, {
      label,
      onClick: () => {
        create(
          {
            type: 'custom',
            ...PermissionRoleSettingsPostParameters[entityName][
              permission
            ],
          },
          {},
          permissionRole.entityName,
          permissionRole.guid,
        );
      },
    });
  }
  return items;
};

export const getExistingPermissionRoleSettingType = (
  permissionRole: PermissionRole,
  entityName: string,
  permission: string,
) => {
  const entityNameAction =
    PermissionRoleSettingsPostParameters[entityName][permission];

  const existingPermissionRoleSetting:
    | PermissionRoleSetting
    | undefined = permissionRole.permission_role_settings.find(
    (permissionRoleSetting: PermissionRoleSetting) =>
      permissionRoleSetting.entity_name ===
        entityNameAction.entity_name &&
      permissionRoleSetting.action === entityNameAction.action,
  );

  if (existingPermissionRoleSetting) {
    return LABELS_FOR_PERMISSIONS[existingPermissionRoleSetting.type];
  }
  return LABELS_FOR_PERMISSIONS.none;
};
