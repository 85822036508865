import { IItemProps } from './types';

export function arrayMoveMutable(array, fromIndex, toIndex) {
  const startIndex =
    fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable(array, fromIndex, toIndex) {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}

export const getPosition = (
  items: IItemProps[],
  oldIndex: number,
  newIndex: number,
  sort?: 'asc' | 'desc',
): number => {
  const { length } = items;

  let position: number;

  const topItem = items[0];
  const bottomItem = items[length - 1];

  const getNewHigh = (oldHighest: number): number => oldHighest + 1;
  const getNewLow = (oldLow: number): number => oldLow - oldLow / 2;
  const getInbetween = (before: number, after: number): number =>
    (before + after) / 2;

  if (newIndex === 0) {
    position =
      sort === 'desc'
        ? getNewHigh(topItem.position)
        : getNewLow(topItem.position);
  } else if (newIndex === length - 1) {
    position =
      sort === 'desc'
        ? getNewLow(bottomItem.position)
        : getNewHigh(bottomItem.position);
  } else if (newIndex < oldIndex) {
    position = getInbetween(
      items[newIndex - 1].position,
      items[newIndex].position,
    );
  } else {
    position = getInbetween(
      items[newIndex].position,
      items[newIndex + 1].position,
    );
  }

  return position;
};
