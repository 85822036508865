/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/LinkExperimentWorkflow
 * @desc Link an experiment workflow to an experiment workflow
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import EntityActionEditModal from 'labstep-web/components/Entity/Action/Edit/Modal';
import SearchSelect from 'labstep-web/core/Select/Search';
import { EntityCreateContainer } from 'labstep-web/containers';
import {
  ExperimentWorkflow,
  ExperimentWorkflowLink,
} from 'labstep-web/models';
import {
  IExperimentWorkflowActionLinkExperimentWorkflowContainerProps,
  IExperimentWorkflowActionLinkExperimentWorkflowProps,
} from './types';

export const ExperimentWorkflowActionLinkExperimentWorkflow: React.FC<
  IExperimentWorkflowActionLinkExperimentWorkflowProps
> = ({
  experimentWorkflow,
  viewComponent,
  modalProps,
  create,
  options,
}) => (
  <EntityActionEditModal
    entity={experimentWorkflow}
    header="Link Experiment"
    viewComponent={
      viewComponent ||
      (({ toggleModal }) => (
        <ActionComponent
          type="button"
          text="New Link"
          onClick={toggleModal}
        />
      ))
    }
    content={({ toggleModal }) => (
      <SearchSelect
        labelKey="displayName"
        autoFocus
        openMenuOnFocus
        params={{
          not_id: experimentWorkflow.id,
        }}
        entityName={ExperimentWorkflow.entityName}
        value={null}
        onChange={(option) => {
          create(
            {
              src_id: experimentWorkflow.id,
              dest_id: option.id,
            },
            options,
          );
          toggleModal();
        }}
      />
    )}
    {...modalProps}
  />
);

export const ExperimentWorkflowActionLinkExperimentWorkflowContainer: React.FC<
  IExperimentWorkflowActionLinkExperimentWorkflowContainerProps
> = (props) => (
  <EntityCreateContainer
    entityName={ExperimentWorkflowLink.entityName}
  >
    {({ create, status }) => (
      <ExperimentWorkflowActionLinkExperimentWorkflow
        create={create}
        status={status}
        {...props}
      />
    )}
  </EntityCreateContainer>
);

export default ExperimentWorkflowActionLinkExperimentWorkflowContainer;
