import ResourceItemShowCenterContentSecondaryInfo from 'labstep-web/screens/ResourceItem/Show/Center/SecondaryInfo';
import { ResourceItem } from 'labstep-web/models';
import colDefName from 'labstep-web/components/Entity/DataGrid/colDefs/name';
import React from 'react';

export const colDefResourceItemName = colDefName(
  ResourceItem.entityName,
  {
    getEntityPreviewProps: (resourceItem) => ({
      secondaryInfo: (
        <ResourceItemShowCenterContentSecondaryInfo
          resourceItem={resourceItem as ResourceItem}
        />
      ),
    }),
  },
);
