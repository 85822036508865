/**
 * Labstep
 *
 * @module components/Entity/Filter/EntityTemplate/List
 * @desc Entity template list filter
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import ActionComponent from 'labstep-web/core/Action/Component';
import Link from 'labstep-web/core/Link';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import { ICONS } from 'labstep-web/constants';
import EntityTemplatePaneManager from './Manager';
import { IEntityTemplatePaneProps } from './types';

export const EntityTemplatePane: React.FC<
  IEntityTemplatePaneProps
> = ({
  entityName,
  showUncategorised,
  entityPreview,
  postFilter,
}) => {
  const to = `${entityName}_template_index` as const;
  const viewComponent = (
    <Flex style={{ justifyContent: 'space-between' }}>
      <Header size="tiny">
        <Icon name={ICONS.resource_template.primary} />
        Categories
      </Header>
      <Link unstyled to={to}>
        <ActionComponent
          type="icon"
          icon="cog"
          elementProps={{
            popup: { content: 'Manage Categories' },
          }}
        />
      </Link>
    </Flex>
  );
  return (
    <div data-testid="template-manager-filter">
      <EntityTemplatePaneManager
        entityPreview={entityPreview}
        entityName={entityName}
        showUncategorised={showUncategorised}
        searchBarProps={{
          viewComponent,
        }}
        postFilter={postFilter}
      />
    </div>
  );
};

export default EntityTemplatePane;
