/**
 * Labstep
 *
 * @module components/Layout/Menu/TopBar/Profile/ViewComponent
 * @desc Profile (opening in right side bar)
 */

import React from 'react';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import ActionComponent from 'labstep-web/core/Action/Component';
import { Mobile } from 'labstep-web/core/MediaQuery';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import { Size } from 'labstep-web/core/Initials/types';
import { ILayoutMenuTopBarProfileViewComponentProps } from './types';

export const LayoutMenuTopBarProfileViewComponent: React.FC<
  ILayoutMenuTopBarProfileViewComponentProps
> = ({ authenticatedUserEntity }) => (
  <Mobile>
    {({ match }): React.ReactElement =>
      match ? (
        <ActionComponent
          type="icon"
          icon="ellipsis vertical"
          elementProps={{
            color: 'blue',
          }}
        />
      ) : (
        <ProfilePicture
          linkable={false}
          entity={authenticatedUserEntity}
          size={Size.mini}
        />
      )
    }
  </Mobile>
);

export default withAuthenticatedUserEntity(
  LayoutMenuTopBarProfileViewComponent,
);
