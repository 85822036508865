/**
 * Labstep
 *
 * @module components/Entity/Form/fields
 * @desc Entity fields
 */

import rules from 'labstep-web/services/validation/rules';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import {
  FieldType,
  IFieldTypeSearchSelectProps,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { getPlaceholder } from 'labstep-web/core/Select/Search/utils';

export const fieldEntityName = (
  entityName: string,
): IFieldWithoutActionProps => ({
  fieldType: FieldType.Text,
  fieldLabel: 'Name',
  name: 'name',
  validation: rules.resource.name,
  placeholder: `Give your ${getHumanReadableEntityName(
    entityName,
  )} a name`,
  header: true,
});

export const fieldEntityTemplate = (
  entityName: string,
): IFieldTypeSearchSelectProps => ({
  elementProps: {
    entityName,
    openMenuOnFocus: true,
    creatable: true,
    createBody: {
      is_template: true,
    },
    extraParams: { is_template: 1 },
  },
  name: 'template',
  placeholder: getPlaceholder(entityName, true, true),
  fieldType: FieldType.SearchSelect,
  fieldLabel: getHumanReadableEntityName(
    entityName,
    false,
    true,
    true,
  ),
  validation: rules.resource.resource_template,
});
