/**
 * Labstep
 *
 * @module components/ProtocolValue/Item/Lineage
 * @desc Lineage List Item
 */

import React from 'react';
import ElementCard from 'labstep-web/core/Card/Element';
import EntityLink from 'labstep-web/components/Entity/Link';
import { ICONS } from 'labstep-web/constants';
import { ProtocolValueItemLineageProps } from './types';

export const ProtocolValueItemLineage: React.FC<
  ProtocolValueItemLineageProps
> = ({ protocolValue }) => (
  <ElementCard
    icon={ICONS.resource_item.primary}
    name={
      <div>
        <EntityLink
          entity={protocolValue.resourceItem}
          to="resource_item_show_lineage"
        />
      </div>
    }
    right={
      <span>
        {' '}
        in{' '}
        <EntityLink
          entity={protocolValue.parent}
          showIcon
          secondary
          to="experiment_show_values"
        />
      </span>
    }
  />
);

export default ProtocolValueItemLineage;
