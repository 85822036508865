/**
 * Labstep
 *
 * @module components/Permission/Action/Create
 * @desc Action to create a permission
 */

import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import PermissionSelectGroup from 'labstep-web/components/Permission/Select/Group';
import { PermissionMenu } from 'labstep-web/core/Permission';
import { PermissionType, Group } from 'labstep-web/models';
import styles from './styles.module.scss';
import {
  PermissionActionCreateProps,
  PermissionActionCreateState,
  HandleChangeProps,
} from './types';

export class PermissionActionCreate extends React.Component<
  PermissionActionCreateProps,
  PermissionActionCreateState
> {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = { groupPermission: PermissionType.view };
  }

  handleChange({ type }: HandleChangeProps) {
    this.setState({ groupPermission: type });
  }

  render() {
    const { parentEntity } = this.props;
    const { groupPermission } = this.state;

    return (
      <div>
        <div>
          <span>{`Add to ${getHumanReadableEntityName(
            Group.entityName,
          )}`}</span>
        </div>
        <div className={styles.addContainer}>
          <PermissionSelectGroup
            parentEntity={parentEntity}
            type={groupPermission}
          />
          <div>
            <PermissionMenu
              type={groupPermission as any}
              onClick={this.handleChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PermissionActionCreate;
