/**
 * Labstep
 *
 * @module components/Tag/Form/Edit
 * @desc Editable expeirment table - Auto save on change
 */

import React from 'react';
import EditForm from 'labstep-web/core/Form/Edit';
import { fieldTagName } from 'labstep-web/components/Tag/Form/fields';
import { Tag } from 'labstep-web/models';
import { ITagFormEditProps } from './types';

export const TagFormEdit: React.FC<ITagFormEditProps> = ({
  tag,
  options,
}) => (
  <EditForm
    autoFocus
    entityName={Tag.entityName}
    entity={tag}
    fields={[fieldTagName]}
    options={options}
  />
);

export default TagFormEdit;
