/**
 * Labstep
 *
 * @module components/Comment/Body
 * @desc Comment body
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import Link from 'labstep-web/core/Link';
import UserShowProfile from 'labstep-web/components/User/Show/Profile';
import FileViewer from 'labstep-web/components/File/Viewer';
import RichTextItem from 'labstep-web/components/RichText/Item';
import MoleculeItem from 'labstep-web/components/Molecule/Item';
import SequencePreview from 'labstep-web/components/Sequence/Preview';
import TableItem from 'labstep-web/components/Table/Item';
import Pre from 'labstep-web/core/Pre';
import CommentFormEdit from 'labstep-web/components/Comment/Form/Edit';
import { ICONS } from 'labstep-web/constants';
import { printBody } from 'labstep-web/core/Mentions/utils';
import styles from './styles.module.scss';
import {
  IBodyProps,
  ICommentBodyProps,
  IMessageProps,
} from './types';

export const Message: React.FC<IMessageProps> = ({ comment }) =>
  comment.body && comment.body.length > 0 ? (
    <Pre>
      {printBody(comment.body, (part) => (
        <UserShowProfile
          key={part.id}
          viewComponent={`@${part.name}`}
          user={
            comment.user_mentions.find(
              (userMention) =>
                userMention.user.id === Number(part.id),
            )!.user
          }
        />
      ))}
    </Pre>
  ) : (
    <div />
  );

export const Body: React.FC<IBodyProps> = ({
  comment,
  toggled,
  toggle,
}) => (
  <div className={styles.commentBody} data-testid="comment-body">
    {toggled ? (
      <CommentFormEdit comment={comment} toggle={toggle} />
    ) : (
      <Message comment={comment} />
    )}
  </div>
);

export const CommentBody: React.FC<ICommentBodyProps> = ({
  comment,
  toggled,
  toggle,
  fileViewerProps,
  dataTableProps,
  richTextProps,
}) => (
  <>
    {comment.resource && (
      <div className={styles.resourceContainer}>
        <span>
          <Icon name={ICONS.resource.primary} />
          <Link
            to="resource_show"
            params={{ id: comment.resource.id }}
          >
            {comment.resource.name}
          </Link>
        </span>
      </div>
    )}
    {comment.files && comment.files.length > 0 && (
      <FileViewer file={comment.files[0]} {...fileViewerProps} />
    )}
    {!(comment.data_table || comment.rich_text) && (
      <Body toggled={toggled} comment={comment} toggle={toggle} />
    )}
    {comment.data_table && (
      <TableItem
        table={comment.data_table}
        {...dataTableProps}
        allowLocking
      />
    )}
    {comment.rich_text && (
      <RichTextItem richText={comment.rich_text} {...richTextProps} />
    )}
    {comment.molecule && <MoleculeItem parent={comment} />}
    {comment.sequence && (
      <SequencePreview sequence={comment.sequence} />
    )}
  </>
);

export default CommentBody;
