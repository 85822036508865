/**
 * Labstep
 *
 * @module containers/ShareLinkEmail
 * @desc Email ShareLink container
 */

import { connect } from 'react-redux';
import {
  sharelinkInivtationOrganizationActionType,
  sharelinkInvitationOrganizationAction,
} from 'labstep-web/state/actions';
import { selectApiStatus } from 'labstep-web/state/selectors';
import { IOptions } from 'labstep-web/typings';

const Container = ({ children, ...rest }) => children(rest);

const mapStateToProps = (state) => ({
  status: selectApiStatus(
    state,
    sharelinkInivtationOrganizationActionType,
  ),
});

const mapDispatchToProps = (dispatch) => ({
  createSharelinkInvitationOrganization(
    body: Record<string, unknown>,
    options: IOptions,
  ): void {
    dispatch(sharelinkInvitationOrganizationAction(body, options));
  },
});

export const SharelinkInvitationOrganizationContainer = connect<
  any,
  any,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
