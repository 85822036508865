import React from 'react';
import { IWithUiPersistentInjectedProps } from 'labstep-web/containers/UiPersistent/types';
import { IWithActiveGroupInjectedProps } from 'labstep-web/containers/ActiveGroup/types';
import { RouteComponentProps } from 'react-router';

// eslint-disable-next-line no-shadow
export enum PostFilterComparison {
  'eq' = 'eq',
  'neq' = 'neq',
  'lte' = 'lte',
  'lt' = 'lt',
  'gte' = 'gte',
  'gt' = 'gt',
  'null' = 'null',
  'not_null' = 'not_null',
  'contains' = 'contains',
  'not_contains' = 'not_contains',
}

// eslint-disable-next-line no-shadow
export enum PostFilterComparisonHumanReadable {
  'eq' = 'equals',
  'neq' = 'does not equal',
  'lte' = 'less than or equal to',
  'lt' = 'less than',
  'gte' = 'greater than or equal to',
  'gt' = 'greater than',
  'null' = 'is empty',
  'not_null' = 'is not empty',
  'contains' = 'includes',
  'not_contains' = 'excludes',
}

// eslint-disable-next-line no-shadow
export enum PostFilterComparisonHumanReadableChronologic {
  'eq' = 'equals',
  'neq' = 'does not equal',
  'lte' = 'before or equal to',
  'lt' = 'before',
  'gte' = 'after or equal to',
  'gt' = 'after',
  'null' = 'is empty',
  'not_null' = 'is not empty',
  'contains' = 'includes',
  'not_contains' = 'excludes',
}

// eslint-disable-next-line no-shadow
export enum PostFilterComparisonHumanReadableShort {
  'eq' = 'is',
  'neq' = 'is not',
  'lte' = '≤',
  'lt' = '<',
  'gte' = '≥',
  'gt' = '>',
  'null' = 'is empty',
  'not_null' = 'is not empty',
  'contains' = 'includes',
  'not_contains' = 'excludes',
}

// eslint-disable-next-line no-shadow
export enum PostFilterOperator {
  'and' = 'and',
  'or' = 'or',
}

export interface PostFilterPredicateType {
  json_path?: string;
  attribute: string;
  comparison: PostFilterComparison;
  value?: string | number | boolean;
}

export interface PostFilterNodeType {
  type: PostFilterOperator;
  path?: string;
  predicates: PostFilterPredicateType[]; // | PostFilterNodeType[] but we only allow depth of 1 for now
}

export type PostFilterBranchType = 0 | 1;

export type PostFilterType = [
  // user-controlled branch
  {
    type: PostFilterOperator;
    predicates: PostFilterNodeType[];
  },
  // system-controlled branch
  {
    type: PostFilterOperator;
    predicates: PostFilterNodeType[];
  },
];

export interface IParamsHOCChildrenProps {
  setParams: (params: { [key: string]: any }) => void;
  addPostFilter: (
    filterParams: PostFilterNodeType,
    replace?: boolean | number,
    branch?: PostFilterBranchType,
  ) => void;
  setPostFilterType: (type: PostFilterOperator) => void;
  removePostFilter: (index: number) => void;
  clearAll: (
    ignore?: string[],
    ignoreSystemFilters?: boolean,
  ) => void;
  clearParams: (params: string[]) => void;
  searchParams: { [key: string]: any };
}

export type IParamHOCProps = {
  historyAction?: 'replace';
  initialParams?: { [key: string]: any };
  /** If passed ignored params won't be omitted on mount */
  skipIgnoreParamsOnMount?: boolean;
};
export interface IParamsContainerProps
  extends IParamHOCProps,
    IWithUiPersistentInjectedProps,
    IWithActiveGroupInjectedProps,
    RouteComponentProps {
  /** If passed, store parameters in Redux */
  persistEntity?: string;
}

export interface IParamsContainerState {
  searchParams?: IParamsHOCChildrenProps['searchParams'];
  ready: boolean;
}

export type IParamsHOCContainerProps = Exclude<
  IParamHOCProps,
  'persistEntity'
>;
