/**
 * Labstep
 *
 * @module core/InformationCard
 * @desc InformationCard
 */

import React from 'react';
import classnames from 'classnames';
import { HorizontalDivider } from 'labstep-web/core/Divider';
import Container from 'labstep-web/components/Layout/Container';
import styles from './styles.module.scss';
import { IInformationCardProps, IInformationProps } from './types';

export const Information: React.FC<IInformationProps> = ({
  actionMenu,
  primaryInfo,
  secondaryInfo,
  breadcrumb,
  bottom,
  additionalActions,
  noBottomMargin,
}) => (
  <>
    <div className={styles.top}>
      <div className={styles.topTop}>
        <div>
          {breadcrumb}
          {primaryInfo}
        </div>
        <div className={styles.topRight}>
          {additionalActions}
          {actionMenu}
        </div>
      </div>
    </div>
    {secondaryInfo && (
      <>
        <Container>{secondaryInfo}</Container>
        {bottom && (
          <Container>
            <HorizontalDivider />
          </Container>
        )}
      </>
    )}
    <div className={classnames(styles.bottom, { noBottomMargin })}>
      {bottom}
    </div>
  </>
);

const InformationCard: React.FC<IInformationCardProps> = ({
  className,
  ...rest
}) => (
  <div
    className={classnames(styles.card, className)}
    id="information-card"
  >
    <Information {...rest} />
  </div>
);

export default InformationCard;
