/**
 * Labstep
 *
 * @module components/EntityUser/Action/Assign
 */

import React from 'react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { ICONS } from 'labstep-web/constants';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IEntityUserActionToggleAssignProps } from './types';

const EntityUserActionToggleAssign: React.FC<
  IEntityUserActionToggleAssignProps
> = ({ entityUser, entity, options }) => (
  <Can
    entityName={entity.entityName}
    id={entity.idAttr}
    action={Action.assign}
  >
    <EntityActionCreate
      overwriteCanCreateProps={{
        parentName: entity.entityName,
        parentId: entity.idAttr,
      }}
      entityName={entityUser.entityName}
      actionComponentProps={{
        type: 'text',
        text: entityUser.is_assigned ? 'Unassign' : 'Assign',
        icon: entityUser.is_assigned
          ? ICONS.entity.actions.unassign
          : ICONS.entity.actions.assign,
        elementProps: { id: String(entityUser.id) },
      }}
      body={{
        // Important to keep this top for epics/normalize to work
        [`${entity.entityName}_id`]: entity.id,
        is_assigned: !entityUser.is_assigned,
        user_id: entityUser.user.id,
      }}
      options={options}
    />
  </Can>
);

export default EntityUserActionToggleAssign;
