/**
 * Labstep
 *
 * @module components/Experiment/Form/ShowEdit/Name
 * @desc Experiment name edit form
 */

import React from 'react';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import Header from 'labstep-web/core/Header';
import { fieldExperimentName } from 'labstep-web/components/Experiment/Form/fields';
import styles from './styles.module.scss';
import { IExperimentFormShowEditNameProps } from './types';

const ExperimentFormShowEditName: React.FC<
  IExperimentFormShowEditNameProps
> = ({ experiment }) => (
  <ShowEditText
    entityName={experiment.entityName}
    entity={experiment}
    field={fieldExperimentName}
    className={styles.showEdit}
  >
    <Header size="small" noMargin>
      {experiment.name}
    </Header>
  </ShowEditText>
);

export default ExperimentFormShowEditName;
