/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Card
 * @desc ExperimentWorkflow Card
 */

import { ICONS } from 'labstep-web/constants';
import ProtocolChildRouter from 'labstep-web/containers/Router/ProtocolChild';
import ActionComponent from 'labstep-web/core/Action/Component';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import { IExperimentWorkflowCommentHeaderProps } from './types';

export const ExperimentWorkflowCommentHeader: React.FC<
  IExperimentWorkflowCommentHeaderProps
> = ({ comment }) => {
  const location = useLocation();
  let icon;
  let text;
  let route = 'thread_show';
  let { id }: { id: number | string } = comment;

  const { experiment } = comment.parent_thread;

  if (comment.parent_thread.protocol_step) {
    icon = 'check circle';
    // https://github.com/Labstep/web/issues/5711
    // Because we are showing the cached version we have no
    // guarantee that experiment will be present
    if (comment.parent_thread.protocol_step.experiment) {
      const {
        position,
        experiment: { name },
      } = comment.parent_thread.protocol_step;
      text = `Note on Step ${position} of ${name}`;
      route = 'step_show_thread';
      id = comment.parent_thread.protocol_step.guid;
    }
  }

  if (experiment) {
    if (experiment.is_root) {
      icon = 'book';
      text = 'Comment on Entry';
    } else {
      icon = 'tasks';
      text = `Comment on ${experiment.name}`;
    }
  }

  if (comment.parent_thread.metadata) {
    icon = 'database';
    text = `Note on ${comment.parent_thread.metadata.label}`;
    route = 'metadata_show_thread';
    id = comment.parent_thread.metadata.id;
  }

  if (icon) {
    return (
      <ProtocolChildRouter
        route={route}
        id={id}
        state={{ referrer: location }}
      >
        {({ navigate }) => (
          <div className={styles.container}>
            <div>
              <Icon name={icon} />
            </div>
            <div>
              <b>{text}</b>{' '}
              <span>
                <ActionComponent
                  onClick={navigate}
                  type="text"
                  text="(show thread)"
                />
              </span>
            </div>
          </div>
        )}
      </ProtocolChildRouter>
    );
  }

  return null;
};

export default ExperimentWorkflowCommentHeader;
