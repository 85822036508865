/**
 * Labstep
 *
 * @module containers/Organization/Customer
 * @desc Create customer for organization in Stripe
 */

import { connect } from 'react-redux';
import {
  organizationCreateCustomerAction,
  organizationCreateCustomerActionType,
} from 'labstep-web/state/actions';
import { selectApiStatus } from 'labstep-web/state/selectors';
import { Organization, StripeAddress } from 'labstep-web/models';
import { OrganizationCustomerContainerProps } from './types';

const Container = ({ children, ...rest }) => children(rest);

const mapStateToProps = (state) => ({
  status: selectApiStatus(
    state,
    organizationCreateCustomerActionType,
  ),
});

const mapDispatchToProps = (dispatch) => ({
  createCustomer(organization: Organization, address: StripeAddress) {
    dispatch(organizationCreateCustomerAction(organization, address));
  },
});

export const OrganizationCustomerContainer: React.FC<OrganizationCustomerContainerProps> =
  connect(mapStateToProps, mapDispatchToProps)(Container);
