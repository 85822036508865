/**
 * Labstep
 *
 * @module components/EntityImport/Action/Pending
 * @desc Action and Modal to show pending EntityImport
 */

import React from 'react';
import { withActiveGroup } from 'labstep-web/containers';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import ActionComponent from 'labstep-web/core/Action/Component';
import Loader from 'labstep-web/core/Loader';
import { EntityImport, EntityImportStatus } from 'labstep-web/models';
import EntityImportModal from 'labstep-web/components/EntityImport/Modal/Trigger';
import Flex from 'labstep-web/core/Flex';
import { EntityImportActionPendingProps } from './types';

export const EntityImportActionPending: React.FC<
  EntityImportActionPendingProps
> = ({ activeGroup, targetEntityName, ...rest }) => {
  return (
    <EntityImportModal
      targetEntityName={targetEntityName}
      {...rest}
      viewComponent={({ toggleModal }): React.ReactElement => (
        <ReadOnMountHOC
          type="count"
          loading={false}
          entityName={EntityImport.entityName}
          params={{
            group_id: activeGroup?.id,
            target_entity_name: targetEntityName,
            status: `${EntityImportStatus.preparing},${EntityImportStatus.started}`,
          }}
          children={({ total }) =>
            total && total !== 0 ? (
              <Flex spacing="gap" vAlignContent="center">
                <Loader size="tiny" inline active />
                <ActionComponent
                  type="text"
                  text="Imports pending..."
                  onClick={() => {
                    toggleModal();
                  }}
                />
              </Flex>
            ) : null
          }
        />
      )}
    />
  );
};

export default withActiveGroup(EntityImportActionPending);
