/**
 * Labstep
 *
 * @module components/Experiment/Form/ShowEdit/State
 * @desc State showedit field
 */

import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { withProseMirror } from '..';
import { ProseMirrorStatus } from '../types';
import { IProseMirrorDebouncingContainerProps } from './types';

let fun = null;

export const ProseMirrorDebouncingContainer: React.FC<
  IProseMirrorDebouncingContainerProps
> = ({ status, update, entity, children, setProseMirrorStatus }) => {
  const [debouncing, setDebouncing] = useState(false);
  const debouncingStatus = useMemo(
    () => ({
      ...status,
      isFetching: (status && status.isFetching) || debouncing,
    }),
    [status, debouncing],
  );

  const onChange = useCallback(
    (state, decorations) => {
      if (
        !isEqual(state, entity.state) ||
        !isEqual(decorations, entity.decorations)
      ) {
        if (fun) {
          fun.cancel();
        }
        fun = debounce(() => {
          update(
            { state, decorations },
            { noOutput: true, toast: true },
          );
          setDebouncing(false);
        }, 2000);
        setDebouncing(true);
        fun();
      }
    },
    [entity.state, entity.decorations],
  );

  const cancelDebouncing = useCallback(() => {
    if (fun) {
      fun.cancel();
    }
    setDebouncing(false);
  }, []);

  useEffect(() => {
    if (debouncingStatus.isFetching) {
      setProseMirrorStatus(ProseMirrorStatus.debouncing);
    } else if (debouncingStatus.error) {
      setProseMirrorStatus(ProseMirrorStatus.error);
    } else if (debouncingStatus && debouncingStatus.error === null) {
      setProseMirrorStatus(ProseMirrorStatus.saved);
    }
  }, [debouncingStatus]);

  return children({ onChange, debouncingStatus, cancelDebouncing });
};

export default withProseMirror(ProseMirrorDebouncingContainer);
