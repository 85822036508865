/**
 * Labstep
 */

import { Ref } from 'semantic-ui-react';
import Button from 'labstep-web/core/Button';
import Modal from 'labstep-web/core/Modal';
import TextWithIcon from 'labstep-web/core/Text/WithIcon';
import React from 'react';
import { useModalWizardContext } from '../context';
import styles from './styles.module.scss';
import { IModalWizardActionsProps } from './types';

export const ModalWizardActions: React.FC<
  IModalWizardActionsProps
> = ({
  onContinue,
  onBack,
  hideBackButton,
  hideContinueButton,
  continueLabel,
  actions,
}) => {
  const {
    activeStepIndex,
    startIndex,
    goBack,
    goForward,
    isLastStep,
    status,
    errorMessages,
  } = useModalWizardContext();

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === 'Enter') {
        e.preventDefault();
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [goForward]);

  return (
    <Modal.Actions>
      {!hideBackButton && !(activeStepIndex - 1 < startIndex) ? (
        <Button
          data-testid="modal-wizard-back-button"
          className={styles.actionLeft}
          primary
          basic
          onClick={() => {
            if (onBack) {
              onBack({ goBack });
            } else {
              goBack();
            }
          }}
        >
          Back
        </Button>
      ) : null}
      {errorMessages.map((message) => (
        <Button key={message} className={styles.error}>
          <TextWithIcon
            key={message}
            text={message}
            icon="warning sign"
            iconProps={{ color: 'red' }}
          />
        </Button>
      ))}
      {actions}
      {!hideContinueButton && (
        <Ref innerRef={buttonRef}>
          <Button
            data-testid="modal-wizard-continue-button"
            status={status}
            primary
            onClick={() => goForward(onContinue)}
          >
            {continueLabel || isLastStep ? 'Finish' : 'Continue'}
          </Button>
        </Ref>
      )}
    </Modal.Actions>
  );
};

export default ModalWizardActions;
