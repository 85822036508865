/**
 * Labstep
 *
 * @module components/Metadata/Form/Edit
 * @desc Form to edit metadata
 */

import React from 'react';
import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import EditForm from 'labstep-web/core/Form/Edit';
import { getMetadataFormFields } from 'labstep-web/components/Metadata/Form/fields';
import { Metadata } from 'labstep-web/models';
import { MetadataType } from 'labstep-web/models/metadata/types';
import {
  getOptionByType,
  loadDefaultValues,
} from 'labstep-web/components/Metadata/Form/utils';
import { preSubmit } from 'labstep-web/components/Metadata/Form/preSubmit';
import { IMetadataFormEditProps } from './types';

export const MetadataFormEdit: React.FC<IMetadataFormEditProps> = ({
  metadata,
  options,
}) => {
  const fields = getMetadataFormFields(
    getOptionByType(metadata.type).value,
    metadata.is_template,
    metadata,
  );

  return (
    <EditForm
      autoFocus
      entityName={Metadata.entityName}
      entity={metadata}
      options={options}
      loadDefaultValues={loadDefaultValues}
      preSubmit={preSubmit}
      defaultValues={pick(
        metadata,
        fields.map((field) => field.name),
      )}
      fields={fields}
      confirmationModalMessage={(values: any) => {
        if (
          metadata.type === MetadataType.options &&
          metadata.is_template &&
          !!metadata.options
        ) {
          const intersectionKeys = intersection(
            Object.keys(metadata.options.values),
            values.options_values,
          );
          if (
            intersectionKeys.length !==
            Object.keys(metadata.options.values).length
          ) {
            return 'Changing default options may clear some data from every record using this template.';
          }
        }
        return false;
      }}
    />
  );
};

export default MetadataFormEdit;
