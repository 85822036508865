/**
 * Labstep
 *
 * @module services/fos-router
 * @desc Loads Symfony FOSRouting JS dump
 */

import { fosJsRoutesData } from 'labstep-web/config';
import { configService } from 'labstep-web/services/config.service';
import FosRouting from './fos-routing';

const fosRouting = new FosRouting();
fosRouting.setData({
  ...fosJsRoutesData,
  base_url: '',
  routes: Object.keys(fosJsRoutesData.routes).reduce(
    (result, key) => ({
      ...result,
      [key.replace('app_api', 'app')]:
        fosJsRoutesData.routes[
          key as keyof typeof fosJsRoutesData.routes
        ],
    }),
    {},
  ),
});

export default {
  /**
   * Generates a route URL
   * @function
   * @param  {string} route - Symfony route name
   * @param  {object} params - Parameters
   * @param  {boolean} absolute - Whether to generate an absolute URL or not. Defaults to true.
   * @returns {string} Generated URL
   */
  generate: (route: string, params: any = {}, absolute = true) => {
    const path = fosRouting.generate(route, params);
    return absolute ? configService.labstepApiUrl + path : path;
  },
};
