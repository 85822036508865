/**
 * Labstep
 *
 * @module components/OrderRequestTemplate/Action/Create
 * @desc Action to create a OrderRequestTemplate
 */

import React from 'react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { OrderRequest } from 'labstep-web/models';
import { withActiveGroup } from 'labstep-web/containers';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { OrderRequestTemplateActionCreateProps } from './types';

export const OrderRequestTemplateActionCreate: React.FC<
  OrderRequestTemplateActionCreateProps
> = ({ resource, activeGroup }) => (
  <Can
    entityName={resource.entityName}
    id={resource.id}
    action={Action.edit}
  >
    <EntityActionCreate
      entityName={OrderRequest.entityName}
      parentName={activeGroup?.entityName}
      parentId={activeGroup?.id}
      actionComponentProps={{
        type: 'button',
        text: 'Enable Template',
      }}
      body={{
        template_resource_id: resource.id,
        is_template: true,
        quantity: 1,
      }}
    />
  </Can>
);

export default withActiveGroup(OrderRequestTemplateActionCreate);
