/**
 * Labstep
 *
 * @module screens/Metadata/Show/Thread
 * @desc Metadata show thread
 */

import React from 'react';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { Metadata } from 'labstep-web/models';
import MetadataThreadDetail from 'labstep-web/components/Metadata/Thread/Detail';
import { EntityReadEntityContainer } from 'labstep-web/containers';
import { IMetadataShowThreadProps } from './types';

export const MetadataShowThreadName: React.FC<
  IMetadataShowThreadProps
> = ({ id }) => (
  <EntityReadEntityContainer entityName={Metadata.entityName} id={id}>
    {({ entity }) =>
      entity ? <span>{`Notes on ${entity.label}`}</span> : null
    }
  </EntityReadEntityContainer>
);

const MetadataShowThread: React.FC<IMetadataShowThreadProps> = ({
  id,
}) => (
  <ReadOnMountHOC
    type="entities"
    loading={{ loader: 'placeholder', cached: true }}
    entityName={Metadata.entityName}
    params={{
      get_single: 1,
      id,
    }}
    children={({ entity: metadata }) => (
      <MetadataThreadDetail metadata={metadata} />
    )}
  />
);

export default MetadataShowThread;
