/**
 * Modal for logging/registering
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  withAuthenticatedUser,
  withUiPersistent,
} from 'labstep-web/containers';
import Redirect from 'labstep-web/core/Redirect';
import { navigation } from 'labstep-web/services/navigation';
import { windowService } from 'labstep-web/services/window.service';
import Signin from './Signin';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import SAMLLogin from './SAML/Login';
import SAMLLoginNew from './SAML/LoginNew';
import SigninSAML from './SigninSAML';
import SAMLGetLoginUrl from './SAML/GetLoginUrl';
import SAMLAuthentication from './SAML/Authentication';
import SignupEnterprise from './SignupEnterprise';
import { IAuthenticationScreenProps } from './types';

export const AuthenticationScreen: React.FC<
  IAuthenticationScreenProps
> = ({ authenticatedUser, uiPersistent }) => {
  if (authenticatedUser.authenticated) {
    if (
      uiPersistent.active_share_link &&
      !(
        uiPersistent.active_share_link.organization &&
        authenticatedUser.is_new
      ) &&
      authenticatedUser.enabled
    ) {
      return (
        <Redirect
          to="sharelink_show"
          params={{ token: uiPersistent.active_share_link.token }}
        />
      );
    }
    return <Redirect to="app_homepage" />;
  }

  const organization = windowService.detectOrganization();
  if (organization) {
    return (
      <Switch>
        <Route
          exact
          path={navigation.get('saml_get_login_url')}
          component={SAMLGetLoginUrl}
        />
        <Route
          exact
          path={navigation.get('saml_login')}
          component={SAMLLogin}
        />
        <Route component={SAMLAuthentication} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route
        exact
        path={navigation.get('forgot_password')}
        component={ForgotPassword}
      />
      <Route
        exact
        path={navigation.get('reset_password')}
        component={ResetPassword}
      />
      <Route
        exact
        path={navigation.get('signup')}
        component={Signup}
      />
      <Route
        exact
        path={navigation.get('login_saml')}
        component={SigninSAML}
      />
      <Route
        exact
        path={navigation.get('saml_get_login_url')}
        component={SAMLGetLoginUrl}
      />
      <Route
        exact
        path={navigation.get('saml_login_new')}
        component={SAMLLoginNew}
      />
      <Route
        exact
        path={navigation.get('signup_enterprise')}
        component={SignupEnterprise}
      />
      <Route component={Signin} />
    </Switch>
  );
};

export default withUiPersistent(
  withAuthenticatedUser(AuthenticationScreen),
);
