import {
  Device,
  ExperimentWorkflow,
  File as FileModel,
  OrderRequest,
  ProtocolCollection,
  PurchaseOrder,
  Resource,
  ResourceItem,
} from 'labstep-web/models';

/**
 * Entity Name of options on the LHS
 */
export const ENTITY_NAME_OPTIONS = [
  ExperimentWorkflow.entityName,
  ProtocolCollection.entityName,
  Resource.entityName,
  ResourceItem.entityName,
  PurchaseOrder.entityName,
  OrderRequest.entityName,
  Device.entityName,
  FileModel.entityName,
];

export type EntityNameOptions =
  | typeof ExperimentWorkflow.entityName
  | typeof ProtocolCollection.entityName
  | typeof Resource.entityName
  | typeof ResourceItem.entityName
  | typeof PurchaseOrder.entityName
  | typeof OrderRequest.entityName
  | typeof Device.entityName
  | typeof FileModel.entityName;
