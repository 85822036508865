/**
 * Labstep
 *
 * @module screens/OrderRequest/Index/Breadcrumb
 * @desc OrderRequest Index Breadcrumb
 */

import { OrderRequest } from 'labstep-web/models';
import React from 'react';
import { ICONS } from 'labstep-web/constants';
import ScreensIndexBreadcrumb from 'labstep-web/screens/Breadcrumb';

export const OrderRequestIndexBreadcrumb: React.FC = () => (
  <ScreensIndexBreadcrumb
    text={`All ${OrderRequest.getHumanReadableEntityName(
      true,
      true,
    )}`}
    icon={ICONS[OrderRequest.entityName].primary}
  />
);

export default OrderRequestIndexBreadcrumb;
