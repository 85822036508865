/**
 * Labstep
 *
 * @module screens/Authentication/SAML/LoginNew
 * @desc Assert screen for saml sso
 */

import React from 'react';
import { withRouter } from 'react-router';
import Loader from 'labstep-web/core/Loader';
import { UserSAMLLoginContainer } from 'labstep-web/containers';
import FullPageError from 'labstep-web/components/Layout/FullPageError';
import styles from './styles.module.scss';

export class Login extends React.Component<any> {
  componentDidMount() {
    const { token, samlLogin, organizationSamlIdentifier } =
      this.props;
    samlLogin(organizationSamlIdentifier, { token });
  }

  render() {
    const { status } = this.props;
    return (
      <div className={styles.container}>
        {!(status && status.error) ? (
          <Loader active size="huge">
            Signing in...
          </Loader>
        ) : (
          <FullPageError message="Oops, something went wrong! Please refresh your page. If the problem persists, please contact Labstep at info@labstep.com." />
        )}
      </div>
    );
  }
}

export const AssertContainer = ({
  match: {
    params: { organizationSamlIdentifier, token },
  },
  history,
}) => (
  <UserSAMLLoginContainer>
    {({ samlLogin, status }) => (
      <Login
        history={history}
        token={token}
        organizationSamlIdentifier={organizationSamlIdentifier}
        samlLogin={samlLogin}
        status={status}
      />
    )}
  </UserSAMLLoginContainer>
);

export default withRouter(AssertContainer);
