/**
 * Labstep
 *
 * @module components/ShareLink/Action/Email
 * @desc Email shareLink to collaborators
 */

import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import { EmailShareLinkContainer } from 'labstep-web/containers/EmailShareLink';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import Button from 'labstep-web/core/Button';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import {
  shareLinkFieldEmails,
  shareLinkFieldMessage,
} from 'labstep-web/components/ShareLink/Form/fields';
import { ShareLink, User } from 'labstep-web/models';
import {
  IShareLinkFormEmailProps,
  IShareLinkFormEmailState,
} from './types';

const createMessage = (
  authenticatedUserEntity: User,
  shareLink: ShareLink,
) => `Hi,

Please collaborate with me on this ${getHumanReadableEntityName(
  shareLink.entity.entityName,
)}.

Thanks,

${authenticatedUserEntity.name}
`;

export class ShareLinkFormEmail extends React.Component<
  IShareLinkFormEmailProps,
  IShareLinkFormEmailState
> {
  constructor(props: IShareLinkFormEmailProps) {
    super(props);
    this.state = {
      key: 0,
    };
    this.incrementKey = this.incrementKey.bind(this);
  }

  incrementKey() {
    this.setState((state) => ({ key: state.key + 1 }));
  }

  render() {
    const { shareLink, authenticatedUserEntity, options } =
      this.props;
    const message = createMessage(authenticatedUserEntity, shareLink);
    return (
      <EmailShareLinkContainer>
        {({ emailShareLink, status }) => (
          <ReusableForm
            autoFocus
            key={this.state.key}
            submitButton={
              <Button loader status={status} primary>
                Send
              </Button>
            }
            status={status}
            onSubmit={(values) => {
              emailShareLink(
                {
                  share_link_id: shareLink.id,
                  emails: values.emails,
                  message: values.message,
                },
                enhanceOptions({
                  options: { ...options, toast: true },
                  onSuccess: this.incrementKey,
                }),
              );
            }}
            fields={[shareLinkFieldEmails, shareLinkFieldMessage]}
            defaultValues={{
              message,
              emails: [],
            }}
          />
        )}
      </EmailShareLinkContainer>
    );
  }
}

export default withAuthenticatedUserEntity(ShareLinkFormEmail);
