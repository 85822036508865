/**
 * Labstep
 *
 * @module components/ResourceLocation/Item/StockCheck
 * @desc ResourceLocation StockCheck item
 */

import React from 'react';
import EntityLink from 'labstep-web/components/Entity/Link';
import ElementCard from 'labstep-web/core/Card/Element';
import ResourceItemActionUpdateStockCheck from 'labstep-web/components/ResourceItem/Action/UpdateStockCheck';
import { ICONS } from 'labstep-web/constants';
import { IResourceLocationItemStockCheckProps } from './types';

export const ResourceLocationItemStockCheck: React.FC<
  IResourceLocationItemStockCheckProps
> = ({ resourceItem }) => (
  <ElementCard
    icon={ICONS.resource_item.primary}
    name={<EntityLink entity={resourceItem} />}
    actions={
      <ResourceItemActionUpdateStockCheck
        resourceItem={resourceItem}
      />
    }
  />
);

export default ResourceLocationItemStockCheck;
