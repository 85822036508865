/**
 * Labstep
 *
 * @module components/Tag/FilterPane
 * @desc Filter pane for tag
 */

import React from 'react';
import {
  FilterList,
  FilterSection,
} from 'labstep-web/components/Filter/List';
import { tagTypes } from 'labstep-web/components/Tag/Form/fields';
import { STRINGS } from 'labstep-web/strings';
import { ICONS } from 'labstep-web/constants';

export const TagFilterTagtypeList: React.FC = () => (
  <FilterSection
    header={STRINGS.filter.header.type}
    filters={[]}
    filter={{
      entityName: 'tag',
      name: 'tag_type',
    }}
    extraFilters={
      <FilterList
        filters={Object.keys(tagTypes).map((type) => ({
          icon: ICONS[type].primary,
          text: `${tagTypes[type]} Tags`,
          params: { type },
        }))}
      />
    }
  />
);

export default TagFilterTagtypeList;
