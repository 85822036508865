/**
 * Labstep
 *
 * @module services/analytics/logrocket
 * @desc Logrocket integration
 * @see https://docs.logrocket.com
 */

import LogRocket from 'logrocket';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import { configService } from 'labstep-web/services/config.service';

export const init = (): void => {
  if (!configService.logrocketAppId) {
    return;
  }

  LogRocket.init(configService.logrocketAppId, {
    network: {
      requestSanitizer: (request) => {
        if (request.body) {
          const bodyObject = JSON.parse(request.body);
          bodyObject.password = undefined;
          request.body = JSON.stringify(bodyObject);
        }

        if (request.headers) {
          request.headers.apikey = undefined;
        }

        return request;
      },
    },
  });
  LogRocket.getSessionURL((sessionURL) => {
    bugsnagService.addMetadata('LogRocket', { sessionURL });
  });
};

export const identify = (username: string, name: string): void => {
  init();
  if (!configService.logrocketAppId) {
    return;
  }
  LogRocket.identify(username, { name });
};

export default LogRocket;
