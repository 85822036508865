/**
 * Labstep
 *
 * @module screens/Protocol/Show
 * @desc Protocol show
 */

import React from 'react';
import DocumentHead from 'labstep-web/core/DocumentHead';
import Redirect from 'labstep-web/core/Redirect';
import ScreensMasterShow from 'labstep-web/screens/Master/Show';
import ProtocolConditionModal from 'labstep-web/components/ProtocolCondition/Modal/Container';
import { ProtocolConditionModalProvider } from 'labstep-web/components/ProtocolCondition/Modal/context';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { Protocol } from 'labstep-web/models';
import Center from './Center';
import Right from './Right';
import { IShowProps } from './types';

export const Show: React.FC<IShowProps> = ({ protocol }) => {
  if (protocol.collection.deleted_at) {
    return (
      <Redirect
        to="protocol_collection_show"
        params={{ id: protocol.collection.id }}
      />
    );
  }

  return (
    <>
      <DocumentHead title={protocol.nameAndVersion} />
      <ProtocolConditionModalProvider
        entityName={Protocol.entityName}
      >
        <ProtocolConditionModal protocol={protocol} />
        <ScreensMasterShow
          entity={protocol.collection}
          center={<Center protocol={protocol} />}
          right={<Right protocol={protocol} />}
        />
      </ProtocolConditionModalProvider>
    </>
  );
};

const ShowContainer: React.FC<any> = (props) => (
  <ReadOnMountHOC
    type="entity"
    loading={{ loader: 'full_screen' }}
    entityName={Protocol.entityName}
    id={props.match.params.id}
    children={({ entity: protocol }) => (
      <Show protocol={protocol} {...props} />
    )}
  />
);

export default ShowContainer;
