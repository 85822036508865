/**
 * Labstep
 *
 * @module core/DataGrid/CellEditor/File
 * @desc CellEditor File
 */

import FileActionCreate from 'labstep-web/components/File/Action/Create';
import { Entity } from 'labstep-web/models';
import React from 'react';
import { setValue, stopEditingAndFocus } from '../../utils';
import { ICellEditorFileProps } from './types';

export const DataGridCellEditorFile: React.FC<
  ICellEditorFileProps<Entity>
> = React.memo((params) => {
  const valueUpdateFile = (fileId: number): void => {
    setValue('file_id', fileId, {
      options: {
        optimistic: false, // need to wait for file upload
        onSuccess: () => {
          if (!params.node) {
            return;
          }
          params.api.refreshCells({
            rowNodes: [params.node],
            columns: [params.column],
            force: true,
          });
        },
      },
      entityName: params.props.entity.entityName,
      id: params.props.entity.id,
      createProps: params.props.createProps,
    });
    stopEditingAndFocus(params);
  };

  return (
    <FileActionCreate
      onFolderWatcherSelect={({ file }): void =>
        valueUpdateFile(file.id)
      }
      options={{
        onSuccess: ({ response }): void => {
          const fileId = Array.isArray(response.result)
            ? response.result[0]
            : response.result;
          valueUpdateFile(fileId);
        },
      }}
      modalProps={{
        onClose: (): void => stopEditingAndFocus(params),
        open: true,
        viewComponent: null,
      }}
    />
  );
});

export default DataGridCellEditorFile;
