/**
 * Labstep
 *
 * @module components/Layout/RightPane
 * @desc Sub page on RightPane
 */

import React from 'react';
import classnames from 'classnames';
import { Desktop } from 'labstep-web/core/MediaQuery';
import {
  IRightPaneSubPageContainerProps,
  IRightPaneSubPageProps,
} from './types';
import styles from './styles.module.scss';
import RightPaneSubPageHeader from './Header';

export const RightPaneSubPage: React.FC<IRightPaneSubPageProps> = ({
  header,
  children,
  desktop,
  ...rest
}) => (
  <div
    className={classnames({
      [styles.desktopRight]: desktop,
      [styles.mobile]: !desktop,
    })}
  >
    <div className={styles.container}>
      {header && (
        <div className={styles.header}>
          <RightPaneSubPageHeader header={header} {...rest} />
        </div>
      )}
      <div id="right-pane-body" className={styles.body}>
        {children}
      </div>
    </div>
  </div>
);

export const RightPaneSubPageContainer: React.FC<
  IRightPaneSubPageContainerProps
> = (props) => (
  <Desktop>
    {({ match }): React.ReactElement => (
      <RightPaneSubPage desktop={match} {...props} />
    )}
  </Desktop>
);

export default RightPaneSubPageContainer;
