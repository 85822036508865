/**
 * Labstep
 */

import moment from 'moment';
import { defaultFormat } from 'labstep-web/services/date.service';
import { ProtocolTimer } from 'labstep-web/models';
import { TTimeRemaining } from './types';

export const isAtZero = ({
  hours,
  minutes,
  seconds,
  negative,
}: TTimeRemaining): boolean =>
  (hours === 0 && minutes === 0 && seconds === 0) || negative;

export const isRunningLow = ({
  hours,
  minutes,
  seconds,
  negative,
}: any) =>
  (hours === 0 && minutes === 0 && seconds <= 10) || negative;

/**
 * Check if the timer has started
 * @param timeRemaining
 * @param protocolTimer
 * @returns bool
 */
export const hasTimerStarted = (
  { hours, minutes, seconds, negative }: TTimeRemaining,
  protocolTimer: ProtocolTimer,
) =>
  protocolTimer.seconds === seconds &&
  protocolTimer.minutes === minutes &&
  protocolTimer.hours === hours &&
  !negative;

export const isRunning = ({ paused_at, ended_at }: any) =>
  Boolean(!paused_at) && Boolean(ended_at);

/**
 * Calculates the remaining time of a timer
 * @param  {object} protocolTimer
 * @param  {object} now - Current date as Moment Date
 * @return {object} - Returns an object with hours, minutes and seconds
 */
export const getRemainingTime = (
  protocolTimer: ProtocolTimer,
): any => {
  const now = moment.utc().format(defaultFormat);
  const { paused_at, ended_at } = protocolTimer;

  if (!paused_at && !ended_at) {
    // Return protocol (original) timer values
    return { ...protocolTimer };
  }

  const diff = paused_at
    ? moment(ended_at).diff(moment(paused_at))
    : moment(ended_at).diff(now);

  const duration = moment.duration(diff);

  const remainingTime = {
    negative: diff < 0,
    hours: Math.abs(duration.hours()),
    minutes: Math.abs(duration.minutes()),
    seconds: Math.abs(duration.seconds()),
  };
  return remainingTime;
};
