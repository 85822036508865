/**
 * Labstep
 *
 * @module components/Metadata/Manager/List
 * @desc Metadata Manager in list form
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import MetadataCardList from 'labstep-web/components/Metadata/CardList';
import MetadataActionCreateDataModal from 'labstep-web/components/Metadata/Action/Create/DataModal';
import Container from 'labstep-web/components/Layout/Container';
import { Experiment } from 'labstep-web/models';
import MetadataActionCreateFile from '../../Action/Create/File';
import { IMetadataManagerListProps } from './types';

const MetadataManagerList: React.FC<IMetadataManagerListProps> = ({
  entity,
  explanation = 'Add data fields to capture variable input parameters, such as incubation temperatures, or output data, such as purification yields, in a quick and structured fashion.',
  draggable,
  hideActions,
}) => {
  const showEmptyState =
    (entity instanceof Experiment ? entity : entity.metadata_thread)
      .metadata_count === 0;

  const metadataActionCreate = (
    <MetadataActionCreateDataModal entity={entity} />
  );
  return (
    <div>
      <div style={{ display: showEmptyState ? 'block' : 'none' }}>
        <MetadataActionCreateFile
          metadataThreadId={entity.metadata_thread.id}
        >
          <EmptyState
            src="/img/empty-state/data.svg"
            explanation={explanation}
            action={metadataActionCreate}
          />
        </MetadataActionCreateFile>
      </div>
      <div style={{ display: showEmptyState ? 'none' : 'block' }}>
        <Container>{metadataActionCreate}</Container>
        <MetadataCardList
          entity={entity}
          draggable={draggable}
          hideActions={hideActions}
        />
      </div>
    </div>
  );
};

export default MetadataManagerList;
