/**
 * Labstep
 */

import { ICONS } from 'labstep-web/constants';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import {
  getEditable,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import { OrderRequest } from 'labstep-web/models';
import rules from 'labstep-web/services/validation/rules';

export const colDefQuantity: ColDef<OrderRequest> = {
  colId: 'quantity',
  headerName: '# of items',
  headerComponentParams: {
    icon: ICONS.resource_item.primary,
  },
  valueGetter: (params) => params.data.quantity,
  editable: getEditable(),
  valueSetter: (params) =>
    setValue(
      'quantity',
      Number(params.newValue),
      {
        entityName: params.data.entityName,
        id: params.data.id,
      },
      rules.order_request.quantity,
    ),
};
