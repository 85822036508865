/**
 * Labstep
 *
 * @module screens/ResourceTemplate/LayoutLinks
 * @desc ResourceTemplate Layout Links
 */

import React from 'react';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import ResourceItemManagerTemplate from 'labstep-web/components/ResourceItem/Manager/Template';
import Container from 'labstep-web/components/Layout/Container';
import ResourceFormShowEditAlertThreshold from 'labstep-web/components/Resource/Form/ShowEdit/AlertThreshold';
import LayoutLinks from 'labstep-web/components/Layout/Links';
import LayoutLinksState from 'labstep-web/components/Layout/Links/State';
import { Metadata, Resource } from 'labstep-web/models';
import { Tab } from 'labstep-web/components/Layout/Links/State/types';
import { IResourceTemplateLayoutLinksProps } from './types';

const ResourceTemplateLayoutLinks: React.FC<
  IResourceTemplateLayoutLinksProps
> = ({ resourceTemplate, withRoute = true }) => {
  const links = [
    {
      children: `Resource Template`,
      route: {
        to: 'resource_template_show',
        params: { id: resourceTemplate.id },
      } as const,
      render: () => (
        <div className="resource-template-metadata">
          <Container>
            <ResourceFormShowEditAlertThreshold
              resource={resourceTemplate}
            />
          </Container>
          <MetadataManager
            isTemplate
            entity={resourceTemplate}
            emptyStateMessage={`${Resource.getHumanReadableEntityName(
              true,
              true,
              true,
            )} are customisable ${Metadata.getHumanReadableEntityName()} templates to apply to ${Resource.getHumanReadableEntityName(
              true,
            )}. Build a set of custom fields to easily upload and manage batches of inventory. Set defaults for fields with common values.`}
          />
        </div>
      ),
    },
    {
      children: 'Item Template',
      route: {
        to: 'resource_template_show_resource_item_template',
        params: { id: resourceTemplate.id },
      } as const,
      render: () => (
        <ResourceItemManagerTemplate resource={resourceTemplate} />
      ),
    },
  ];

  if (!withRoute) {
    const tabs: Tab[] = links.map((link) => ({
      key: link.children,
      render: link.render,
    }));
    return <LayoutLinksState tabs={tabs} inverted />;
  }

  return <LayoutLinks links={links} inverted />;
};

export default ResourceTemplateLayoutLinks;
