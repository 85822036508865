/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Referencing/Menu/Creatable/List
 * @desc Menu component for adding external elements as a reference
 */

import React from 'react';
import { RefWrapper } from 'labstep-web/core/Ref';
import Menu from 'labstep-web/core/Menu';
import { getIcon } from 'labstep-web/prosemirror/components/NodeView/Reference/Content';
import { CreatableEntity } from 'labstep-web/prosemirror/extensions/referencing/plugin/types';
import Icon from 'labstep-web/core/Icon';
import { handleCreateItem } from 'labstep-web/prosemirror/extensions/referencing/commands';
import styles from 'labstep-web/prosemirror/components/Menu/Referencing/Menu/styles.module.scss';
import { IMenuReferencingMenuCreatableListProps } from './types';

export const MenuReferencingMenuCreatableList: React.FC<
  IMenuReferencingMenuCreatableListProps
> = ({
  state,
  dispatch,
  selectedCategory,
  items,
  index,
  startIndex = 0,
  activeItemRef,
}) => (
  <>
    <Menu.Item>
      <b>In this workspace ...</b>
    </Menu.Item>
    {items.map((item: CreatableEntity, i: number) => {
      const itemIndex = startIndex + i;
      const isActive = itemIndex === index;
      return (
        <RefWrapper key={itemIndex} innerRef={activeItemRef}>
          <Menu.Item
            key={itemIndex}
            active={isActive}
            onClick={() =>
              handleCreateItem(state, dispatch, itemIndex)
            }
          >
            <div className={styles.item}>
              <Icon name={getIcon(selectedCategory.value)} />
              {item.name}
            </div>
          </Menu.Item>
        </RefWrapper>
      );
    })}
  </>
);

export default MenuReferencingMenuCreatableList;
