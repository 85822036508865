/**
 * Labstep
 *
 * @module components/Experiment/List
 * @desc Experiment List
 */

import React from 'react';
import List from 'labstep-web/core/List';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import LinkButton from 'labstep-web/core/Link/Button';
import ElementCard from 'labstep-web/core/Card/Element';
import { ExperimentWorkflow } from 'labstep-web/models';
import { ICONS } from 'labstep-web/constants';
import styles from './styles.module.scss';
import { IExperimentWorkflowLinkedListProps } from './types';

const ExperimentWorkflowLinkedList: React.FC<
  IExperimentWorkflowLinkedListProps
> = ({ params }) => (
  <ReadOnMountHOC
    type="cursor"
    entityName={ExperimentWorkflow.entityName}
    params={{
      search: 1,
      count: 10,
      ...params,
    }}
    children={({ entities, total }) =>
      total > 0 ? (
        <List className={styles.feed} id="experiment-workflow-list">
          {entities.map((experimentWorkflow: ExperimentWorkflow) => (
            <LinkButton
              key={experimentWorkflow.id}
              route={{
                to: 'experiment_workflow_show',
                params: { id: experimentWorkflow.id },
              }}
            >
              <ElementCard
                name={experimentWorkflow.displayName}
                icon={ICONS.experiment_workflow.primary}
              />
            </LinkButton>
          ))}
        </List>
      ) : (
        <TextPlaceholder>None</TextPlaceholder>
      )
    }
  />
);

export default ExperimentWorkflowLinkedList;
