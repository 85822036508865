/**
 * Labstep
 *
 * @module screens/Master/Index/Center/ListOrTable
 * @desc Generic list or table component
 * @desc Will refactor this to be more generic without listComponent and tableComponent
 */

import { Mobile } from 'labstep-web/core/MediaQuery';
import React from 'react';
import {
  IMasterIndexCenterListOrTableContainerProps,
  IMasterIndexCenterListOrTableProps,
} from './types';

export const MasterIndexCenterListOrTable: React.FC<
  IMasterIndexCenterListOrTableProps
> = ({ listComponent, tableComponent, isMobile, tableFormat }) => {
  if (tableFormat === false) {
    return listComponent;
  }
  return isMobile ? listComponent : tableComponent;
};

export const MasterIndexCenterListOrTableContainer: React.FC<
  IMasterIndexCenterListOrTableContainerProps
> = (props) => (
  <Mobile>
    {({ match }): React.ReactElement => (
      <MasterIndexCenterListOrTable {...props} isMobile={match} />
    )}
  </Mobile>
);

export default MasterIndexCenterListOrTableContainer;
