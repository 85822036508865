import { Resource } from 'labstep-web/models';
import colDefName from 'labstep-web/components/Entity/DataGrid/colDefs/name';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import React from 'react';

export const colDefResourceNameNoLink = colDefName(
  Resource.entityName,
  {
    getEntityPreviewProps: (resource) => ({
      secondaryInfo: <MetadataManager entity={resource} />,
    }),
    noLink: true,
  },
);
