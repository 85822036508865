/**
 * Labstep
 *
 * @module prosemirror/Container
 * @desc ProseMirror container
 */

import React from 'react';
import ReactNodeViewPortalsProvider from 'labstep-web/prosemirror/components/ReactNodeViewPortals';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import { ProseMirrorEditor } from '..';
import { StepsOrderContextProvider } from '../context';
import { IProseMirrorContainerTypes } from './types';

export const ProseMirrorContainer: React.FC<
  IProseMirrorContainerTypes
> = ({
  authenticatedUserEntity,
  authenticatedUserEntityStatus,
  read,
  ...props
}) => (
  <StepsOrderContextProvider
    initialState={props.initialState}
    editable={props.editable}
  >
    <ReactNodeViewPortalsProvider>
      {({ data }) => (
        <ProseMirrorEditor
          {...props}
          key={`${props.entity.entityName}${props.entity.id}`}
          premiumFeatures={authenticatedUserEntity.premiumFeatures}
          portals={data}
        />
      )}
    </ReactNodeViewPortalsProvider>
  </StepsOrderContextProvider>
);

export default withAuthenticatedUserEntity(ProseMirrorContainer);
