/**
 * Labstep
 *
 * @module components/Tag/Table
 * @desc Table showing tags
 */

import React from 'react';
import { humanReadableDate } from 'labstep-web/services/date.service';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import TagActionMenu from 'labstep-web/components/Tag/Action/Menu';
import EntityTable from 'labstep-web/components/Entity/Table';
import EntityLinkComponent from 'labstep-web/components/Entity/Link/Component';
import Link from 'labstep-web/core/Link';
import ActionComponent from 'labstep-web/core/Action/Component';
import { Tag } from 'labstep-web/models';
import { ITagTableProps } from './types';

export const UsedByCell: React.FC<{ tag: Tag }> = ({ tag }) => (
  <Link unstyled to={`${tag.type}_index`} query={{ tag_id: tag.id }}>
    <ActionComponent
      type="text"
      text={`${
        tag.associatedEntitiesCount
      } ${getHumanReadableEntityName(tag.type, true)} tagged`}
    />
  </Link>
);

const extraColumns = [
  {
    header: 'Type',
    content: (tag: Tag) =>
      `${getHumanReadableEntityName(tag.type, false, true)} tag`,
    cellProps: { style: { flex: 3 } },
  },
  {
    header: 'Created on',
    content: (tag: Tag) => humanReadableDate(tag.created_at),
    cellProps: { style: { flex: 3 } },
    sortKey: 'created_at',
  },
  {
    header: 'Times used',
    content: (tag: Tag) => <UsedByCell tag={tag} />,
    cellProps: { style: { flex: 3 } },
  },
];

export const TagTable: React.FC<ITagTableProps> = ({
  tags,
  ...rest
}) => (
  <EntityTable
    {...rest}
    id="tag-table"
    entities={tags}
    primaryColumn={{
      header: 'Name',
      content: (tag: Tag) => (
        <EntityLinkComponent entity={tag} disabled />
      ),
      cellProps: { style: { flex: 3 } },
    }}
    extraColumns={extraColumns}
    actionMenu={({ entity }) => (
      <TagActionMenu tag={entity} actions={['edit', 'delete']} />
    )}
    withCheckbox={false}
  />
);

export default TagTable;
