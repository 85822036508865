/**
 * Labstep
 *
 * @module components/Resource/Action/Menu
 * @desc Action Menu for group
 */

import React from 'react';
import ActionsMenu from 'labstep-web/core/Action/Menu';
import ResourceActionView from 'labstep-web/components/Resource/Action/View';
import ResourceActionDelete from 'labstep-web/components/Resource/Action/Delete';
import ResourceActionShare from 'labstep-web/components/Resource/Action/Share';
import ResourceActionDuplicate from 'labstep-web/components/Resource/Action/Duplicate';
import OrderRequestActionCreate from 'labstep-web/components/OrderRequest/Action/Create';
import EntityActionTransferOwnership from 'labstep-web/components/Entity/Action/TransferOwnership';
import EntityActionTag from 'labstep-web/components/Entity/Action/Tag';
import EntityActionRevoke from 'labstep-web/components/Entity/Action/Revoke';
import EntityUserActionManage from 'labstep-web/components/EntityUser/Action/Manage';
import ResourceActionUpdateResourceTemplate from 'labstep-web/components/Resource/Action/UpdateResourceTemplate';
import { ICONS } from 'labstep-web/constants';
import { Action, IResourceActionMenuProps } from './types';

const ResourceActionMenu: React.FC<IResourceActionMenuProps> = ({
  resource,
  type,
  actions = [],
}) => {
  return (
    <ActionsMenu type={type}>
      {actions.includes(Action.view) && (
        <ResourceActionView resource={resource} />
      )}
      {actions.includes(Action.share) && (
        <ResourceActionShare resource={resource} />
      )}
      {actions.includes(Action.duplicate) && (
        <ResourceActionDuplicate resource={resource} />
      )}
      {actions.includes(Action.write_review) && (
        <ResourceActionView
          resource={resource}
          actionComponentProps={{
            type: 'option',
            text: 'Write a review',
            icon: 'star',
          }}
        />
      )}
      {actions.includes(Action.create_order_request) && (
        <OrderRequestActionCreate
          resource={resource}
          actionComponentProps={{
            type: 'option',
            icon: ICONS.order_request.primary,
          }}
        />
      )}
      {actions.includes(Action.assign) && (
        <EntityUserActionManage entity={resource} />
      )}
      {actions.includes(Action.tag) && (
        <EntityActionTag parentEntity={resource} />
      )}
      {actions.includes(Action.set_resource_template) && (
        <ResourceActionUpdateResourceTemplate resource={resource} />
      )}
      {actions.includes(Action.revoke) && (
        <EntityActionRevoke entity={resource} />
      )}
      {actions.includes(Action.transfer_ownership) && (
        <EntityActionTransferOwnership entity={resource} />
      )}
      {actions.includes(Action.delete) && (
        <ResourceActionDelete resource={resource} />
      )}
    </ActionsMenu>
  );
};

ResourceActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default ResourceActionMenu;
