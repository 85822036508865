/**
 * Labstep
 *
 * @module components/Folder/Form/CreateSub
 * @desc Folder Form Create Sub
 */

import React from 'react';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import {
  EntityCreateContainer,
  withActiveGroup,
} from 'labstep-web/containers';
import { fieldNameMultiple } from 'labstep-web/components/Folder/Form/fields';
import { Folder } from 'labstep-web/models';
import { IFolderFormCreateSubProps } from './types';

export const FolderFormCreateSub: React.FC<
  IFolderFormCreateSubProps
> = ({ body, options, activeGroup, ...rest }) => (
  <EntityCreateContainer batch entityName={Folder.entityName}>
    {({ create, status }): React.ReactElement => (
      <ReusableForm
        status={status}
        fields={[fieldNameMultiple]}
        onSubmit={(values): void => {
          create(
            (values as { names: string[] }).names.map(
              (name: string) => ({
                name,
                group_id: activeGroup?.id,
                ...body,
              }),
            ),
            options,
          );
        }}
        {...rest}
      />
    )}
  </EntityCreateContainer>
);

export default withActiveGroup(FolderFormCreateSub);
