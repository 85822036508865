import { Resource, ResourceItem } from 'labstep-web/models';

export const getParams = (
  entity: Resource | ResourceItem,
  type: 'input' | 'output',
  extraParams?: Record<string, unknown>,
) => {
  const params: Record<string, unknown> = {
    has_value: true,
    is_input: false,
    ...extraParams,
  };

  if (entity instanceof Resource) {
    params[`multiplexing_${type}_resource_id`] = entity.id;
  } else {
    params[`multiplexing_${type}_resource_item_id`] = entity.id;
  }
  return params;
};
