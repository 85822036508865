/**
 * Labstep
 *
 * @module components/OrderRequest/Form/ShowEdit/Currency
 * @desc Currency showedit field
 */

import React from 'react';
import classnames from 'classnames';
import ShowEditSelect from 'labstep-web/core/Form/ShowEdit/Select';
import { fieldOrderRequestCurrency } from 'labstep-web/components/OrderRequest/Form/fields';
import styles from 'labstep-web/core/Form/ShowEdit/styles.module.scss';
import { currencies } from 'labstep-web/constants';
import { IOrderRequestFormShowEditCurrencyProps } from './types';

const OrderRequestFormShowEditCurrency: React.FC<
  IOrderRequestFormShowEditCurrencyProps
> = ({ orderRequest }) => (
  <ShowEditSelect
    entity={orderRequest}
    field={fieldOrderRequestCurrency}
    disabled={!!orderRequest.purchase_order}
    isClearable={false}
    customShow={({ toggle }) => (
      <div
        data-testid="show-edit-toggle"
        className={classnames('toggle', styles.cursor)}
        onClick={toggle}
      >
        {currencies[orderRequest.currency].symbol}
      </div>
    )}
  >
    {currencies[orderRequest.currency].symbol}
  </ShowEditSelect>
);

export default OrderRequestFormShowEditCurrency;
