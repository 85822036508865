/**
 * Labstep
 *
 * @module screens/Experiment/Index
 * @desc Experiment index
 */

import React, { useCallback, useMemo } from 'react';
import { OrderRequestEmptyState } from 'labstep-web/components/OrderRequest/Card/EmptyState';
import MasterIndex from 'labstep-web/screens/Master/Index';
import { OrderRequest } from 'labstep-web/models';
import OrderRequestListOrTable from 'labstep-web/components/OrderRequest/ListOrTable';
import OrderRequestActionCreate from 'labstep-web/components/OrderRequest/Action/Create';
import OrderRequestStatusModalAddItems from 'labstep-web/components/OrderRequest/Status/Modal/AddItems';
import { ParamsStateContextProvider } from 'labstep-web/hoc/Params/context';
import { IEntitySearchChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { IScreensOrderRequestIndexProps } from './types';
import { bulkActions, filters, sortOptions } from './constants';
import OrderRequestIndexBreadcrumb from './Breadcrumb';
import OrderRequestIndexLeftPane from './LeftPane';
import { columnDefs } from './colDefs';

export const ScreensOrderRequestIndex: React.FC<
  IScreensOrderRequestIndexProps
> = ({ group, links }) => {
  const [orderRequestAddItems, setOrderRequestAddItems] =
    React.useState<OrderRequest | null>(null);

  const onChangeStatus: Parameters<typeof columnDefs>[0] =
    useCallback((orderRequest, status) => {
      if (orderRequest.shouldImportResourceItems(status)) {
        setOrderRequestAddItems(orderRequest);
      }
    }, []);

  const closeModal = useCallback(() => {
    setOrderRequestAddItems(null);
  }, []);

  const params = useMemo(
    () => ({
      group_id: group.id,
      is_template: false,
    }),
    [],
  );
  const breadcrumb = useMemo(
    () => <OrderRequestIndexBreadcrumb />,
    [],
  );
  const leftPane = useMemo(
    () => <OrderRequestIndexLeftPane links={links} />,
    [links],
  );
  const noResultsMessage = useMemo(
    () => <OrderRequestEmptyState />,
    [],
  );
  const action = useMemo(() => <OrderRequestActionCreate />, []);

  const entityView = useMemo(
    () => ({
      context: 'order_request_index',
      entityName: OrderRequest.entityName,
    }),
    [],
  );

  const paramsStateContext = useMemo(
    () => ({ persistEntity: OrderRequest.entityName }),
    [],
  );

  return (
    <ParamsStateContextProvider value={paramsStateContext}>
      {orderRequestAddItems && (
        <OrderRequestStatusModalAddItems
          orderRequest={orderRequestAddItems}
          open
          onCancel={closeModal}
          onClose={closeModal}
          onImport={closeModal}
        />
      )}
      <MasterIndex
        breadcrumb={breadcrumb}
        leftPane={leftPane}
        params={params}
        noResultsMessage={noResultsMessage}
        entityName={OrderRequest.entityName}
        action={action}
        bulkActions={bulkActions}
        filters={filters}
        tableFormat={false}
        useAllPages
        entityView={entityView}
        sortOptions={sortOptions}
      >
        {({
          entities,
          readNextPage,
          ...rest
        }: IEntitySearchChildrenProps) => {
          const component = (template?: OrderRequest) => (
            <OrderRequestListOrTable
              orderRequests={entities}
              columnDefs={columnDefs(onChangeStatus, template)}
              loadMore={readNextPage}
              {...rest}
            />
          );
          if (group.default_order_request_template) {
            return (
              <ReadOnMountHOC
                type="entities"
                params={{
                  id: group.default_order_request_template.idAttr,
                  get_single: 1,
                }}
                entityName={OrderRequest.entityName}
                loading={{ loader: false }}
                children={({ entity: template }) =>
                  component(template)
                }
              />
            );
          }
          return component();
        }}
      </MasterIndex>
    </ParamsStateContextProvider>
  );
};

export default ScreensOrderRequestIndex;
