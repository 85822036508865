import { CellRange } from 'ag-grid-community';
import { Entity } from 'labstep-web/models';
import { ColDef } from '../types';

// Event keys
export const KEY_BACKSPACE = 'Backspace';
export const KEY_DELETE = 'Delete';
export const KEY_ENTER = 'Enter';

const CLEAR_KEYS = [KEY_BACKSPACE, KEY_DELETE];

// Needs to be passed as a separate prop in each individual colDef
// More generic event propagation prevention unfortunately does not work
// As described in https://ag-grid.com/react-data-grid/component-cell-editor/#option-1---stop-propagation
export const suppressKeyboardEventDisableEnterOnEdit: NonNullable<
  ColDef<Entity>['suppressKeyboardEvent']
> = (params): boolean => {
  const gridShouldDoNothing =
    params.editing && params.event.key === KEY_ENTER;
  return gridShouldDoNothing;
};

/**
 * Clear cells on backspace/delete
 *
 * Adapted from https://ag-grid.zendesk.com/hc/en-us/articles/360020160932-Removing-selected-rows-or-cells-when-Backspace-or-Delete-is-pressed
 * @param params Grid parameters
 * @returns Boolean (=suppress keyboard event)
 */
export const suppressKeyboardEventClearCells: NonNullable<
  ColDef<Entity>['suppressKeyboardEvent']
> = (params) => {
  if (!params.editing) {
    if (CLEAR_KEYS.includes(params.event.key)) {
      params.api.getCellRanges()?.forEach((range) => {
        const rowIds = getRowIdsFromRange(range);
        rowIds.forEach((rowId) => {
          const rowNode = params.api.getDisplayedRowAtIndex(rowId);
          if (rowNode) {
            range.columns.forEach((col) => {
              const { valueClearer } =
                col.getColDef() as ColDef<Entity>;
              const nullValue =
                typeof valueClearer === 'function'
                  ? valueClearer(params)
                  : valueClearer;
              if (
                nullValue !== undefined &&
                params.api.getValue(col, rowNode) !== nullValue
              ) {
                rowNode.setDataValue(col, nullValue);
              }
            });
          }
        });
      });

      return true;
    }
  }
  return false;
};

/**
 * Returns all row ids in given range
 * @param range Cell range
 * @returns Array of row ids
 */
export const getRowIdsFromRange = (range: CellRange): number[] => {
  const rowIds: number[] = [];
  if (!range.startRow || !range.endRow) {
    return rowIds;
  }

  const startRowIndex = Math.min(
    range.startRow.rowIndex,
    range.endRow.rowIndex,
  );
  const endRowIndex = Math.max(
    range.startRow.rowIndex,
    range.endRow.rowIndex,
  );

  // eslint-disable-next-line no-plusplus
  for (let i = startRowIndex; i <= endRowIndex; i++) {
    rowIds.push(i);
  }

  return rowIds;
};
