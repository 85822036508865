/**
 * Labstep
 */

import { PostFilterComparison } from 'labstep-web/hoc/Params/types';
import { PostFilter } from 'labstep-web/services/postFilter';

export const PostFilterDeleted = new PostFilter([
  ['deletedAt', PostFilterComparison.not_null],
]);
