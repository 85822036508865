/**
 * Labstep
 */

import { IActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { Resource } from 'labstep-web/models';

// eslint-disable-next-line no-shadow
export enum Action {
  view = 'view',
  share = 'share',
  duplicate = 'duplicate',
  write_review = 'write_review',
  tag = 'tag',
  assign = 'assign',
  delete = 'delete',
  revoke = 'revoke',
  create_order_request = 'create_order_request',
  transfer_ownership = 'transfer_ownership',
  set_resource_template = 'set_resource_template',
}

export interface IResourceActionMenuProps
  extends Pick<
    IActionMenuProps,
    Exclude<keyof IActionMenuProps, 'children'>
  > {
  resource: Resource;
  actions?: Action[];
}
