/**
 * Labstep
 */

import { ICONS } from 'labstep-web/constants';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import FilterAvailableResourceItemCount from 'labstep-web/components/Filter/AvailableResourceItemCount';
import { Resource } from 'labstep-web/models';
import React from 'react';
import EntityLink from 'labstep-web/components/Entity/Link';

/**
 * Column definition for available items
 */
const colDefAvailableItems: ColDef<Resource> = {
  colId: 'available_items',
  headerName: 'Available Items',
  headerComponentParams: {
    icon: ICONS.resource.info.available,
    filter: (props) => (
      <FilterAvailableResourceItemCount {...props} />
    ),
  },
  cellRenderer: (params) => (
    <EntityLink
      entity={params.data}
      children={
        <span>{params.data.available_resource_item_count}</span>
      }
    />
  ),
  editable: false,
  valueGetter: (params) => params.data.available_resource_item_count,
};

export default colDefAvailableItems;
