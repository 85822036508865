/**
 * Labstep.
 *
 * @module epics/websocket
 * @desc Redux epic for websocket message
 */

import { Observable, concat, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { StateObservable } from 'redux-observable';
import { Action } from 'labstep-web/models';
import {
  readEntitiesCount,
  readEntitiesCursor,
  showToast,
} from 'labstep-web/state/actions';
import { selectAuthenticatedUser } from 'labstep-web/state/selectors';
import { selectClientUuid } from 'labstep-web/state/new';

/**
 * Receives a message type print from the websocket.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onMessagePrintActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'WEBSOCKET_MESSAGE' &&
        action.payload &&
        action.payload.type === 'print',
    ),
    mergeMap((action: Action) => {
      const message: any = action.payload;
      return of({
        type: 'PRINT_SUCCESS',
        payload: message.payload,
      });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_WEBSOCKET_MESSAGE_PRINT',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Receives a message type notification from the websocket.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onMessageNotificationActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<any>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'WEBSOCKET_MESSAGE' &&
        action.payload &&
        action.payload.type === 'notification',
    ),
    mergeMap((action: Action) => {
      const message: any = action.payload;
      const authenticatedUser = selectAuthenticatedUser(state$.value);
      if (authenticatedUser && authenticatedUser.id) {
        const { entity_name, entity_type } = message.payload;
        return concat(
          message.payload.is_push &&
            of(
              showToast({
                type: 'success',
                message: message.payload.message,
                action_type: 'CUSTOM_TOAST',
                options: {
                  header: `${entity_type}: ${entity_name}`,
                  timeout: 4000,
                },
              }),
            ),
          of(
            readEntitiesCount('notification', {
              is_viewed: false,
            }),
          ),
        );
      }
      return of({
        type: 'EPIC_FAIL_WEBSOCKET_MESSAGE_NOTIFICATION',
        meta: {
          action,
          reason: 'No logged user',
        },
      });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_WEBSOCKET_MESSAGE_NOTIFICATION',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Receives a message type live_experiment from the websocket.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onMessageLiveExperimentActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<any>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'WEBSOCKET_MESSAGE' &&
        action.payload &&
        action.payload.type === 'live_experiment',
    ),
    mergeMap((action: Action) => {
      const authenticatedUser = selectAuthenticatedUser(state$.value);
      if (authenticatedUser && authenticatedUser.id) {
        const params = {
          is_ended: false,
          is_started: true,
          is_locked: false,
          author_id: authenticatedUser.id,
          search: 1,
          count: 10,
          serializerGroups: [
            'default',
            'experiment_workflow_index',
            'experiment_workflow_live_index',
          ],
        };
        return of(
          readEntitiesCursor('experiment_workflow', params, {}, {}),
        );
      }
      return of({
        type: 'EPIC_FAIL_WEBSOCKET_MESSAGE_LIVE_EXPERIMENT',
        meta: {
          action,
          reason: 'No logged user',
        },
      });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_WEBSOCKET_MESSAGE_LIVE_EXPERIMENT',
          payload: err,
        }),
        source$,
      ),
    ),
  );

const detectParentName = (entityName: string) => {
  if (entityName === 'metadata') {
    return 'metadata_thread';
  }
  if (entityName === 'signature') {
    return 'experiment_workflow';
  }

  return undefined;
};

const detectParentId = (entityName: string, data: any) => {
  if (entityName === 'metadata') {
    return data.metadata_thread.id;
  }
  if (entityName === 'signature') {
    return data.experiment_workflow.id;
  }

  return undefined;
};

const detectBody = (entityName: string, data: any) => {
  if (entityName === 'comment') {
    return {
      parent_thread_id: data.parent_thread.id,
      body: data.body,
    };
  }

  return {};
};

const detectParams = (entityName: string, data: any) => {
  if (entityName === 'comment') {
    return {
      parent_thread_id: data.parent_thread.id,
    };
  }

  return undefined;
};

/**
 * Receives a message type data_change from the websocket.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onMessageDataChangeActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<any>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'WEBSOCKET_MESSAGE' &&
        action.payload &&
        action.payload.type === 'data_change',
    ),
    mergeMap((action: Action) => {
      const clientUuid = selectClientUuid(state$.value);

      if (
        clientUuid &&
        action.payload &&
        action.payload.payload &&
        action.payload.payload.clientUuid &&
        clientUuid === action.payload.payload.clientUuid
      ) {
        return of({
          type: `DISCARD_MESSAGE_SAME_CLIENT_UUID`,
        });
      }

      return of({
        type: `SUCCESS_${action.payload.payload.action.toUpperCase()}_${action.payload.payload.entityName.toUpperCase()}_RAW_OUTPUT`,
        meta: {
          origin: 'websocket',
          parentName: detectParentName(
            action.payload.payload.entityName,
          ),
          parentId: detectParentId(
            action.payload.payload.entityName,
            action.payload.payload.data,
          ),
          entityName: action.payload.payload.entityName,
          normalize: action.payload.payload.entityName,
          body: detectBody(
            action.payload.payload.entityName,
            action.payload.payload.data,
          ),
          params: detectParams(
            action.payload.payload.entityName,
            action.payload.payload.data,
          ),
          uuid: action.payload.payload.creationUuid
            ? action.payload.payload.creationUuid
            : undefined,
        },
        payload: action.payload.payload.data,
      });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_WEBSOCKET_MESSAGE_DATA_CHANGE',
          payload: err,
        }),
        source$,
      ),
    ),
  );
