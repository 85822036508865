/**
 * Labstep
 *
 * @module components/Protocol/Action/AddToExperiment
 * @desc Add a Protocol to an ExperimentWorkflow
 */

import React from 'react';
import { navigation } from 'labstep-web/services/navigation';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import ActionComponent from 'labstep-web/core/Action/Component';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import { withRouter } from 'react-router';
import { EntityCreateContainer } from 'labstep-web/containers';
import { ExperimentWorkflow } from 'labstep-web/models';
import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import EntityActionCreateModal from 'labstep-web/components/Entity/Action/Create/Modal';
import { object } from 'yup';
import {
  IAddToExperimentActionContainerProps,
  IAddToExperimentActionProps,
} from './types';

export const fieldExperimentWorkflow: IFieldWithoutActionProps = {
  name: 'experiment_workflow',
  fieldType: FieldType.SearchSelect,
  validation: object().required(),
  elementProps: {
    entityName: 'experiment_workflow',
    creatable: true,
    params: { is_locked: false },
  },
};

export const AddToExperimentAction: React.FC<
  IAddToExperimentActionProps
> = ({ protocol, create, status, history }) => (
  <EntityActionCreateModal
    id="add-to-experiment"
    parent={protocol}
    entityName="experiment"
    header="Add to Experiment"
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        type="option"
        onClick={toggleModal}
        text={`Add to ${getHumanReadableEntityName(
          ExperimentWorkflow.entityName,
        )}`}
        icon="plus"
      />
    )}
    content={() => (
      <ReusableForm
        submitButtonText="Add"
        fields={[fieldExperimentWorkflow]}
        onSubmit={(values) => {
          create(
            {
              protocol_id: protocol.id,
              ...values,
            },
            {
              onSuccess: ({ response }) => {
                history.push(
                  navigation.get('experiment_show', {
                    id: response.result,
                  }),
                );
              },
            },
          );
        }}
        status={status}
      />
    )}
  />
);

export const AddToExperimentActionContainer: React.FC<
  IAddToExperimentActionContainerProps
> = ({ protocol, ...props }) => (
  <EntityCreateContainer entityName="experiment">
    {({ create, status }) => (
      <AddToExperimentAction
        protocol={protocol}
        create={create}
        status={status}
        {...props}
      />
    )}
  </EntityCreateContainer>
);

export default withRouter(AddToExperimentActionContainer);
