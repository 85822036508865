/**
 * Labstep
 *
 * @module components/Comment/Item
 * @desc Single comment
 */

import React from 'react';
import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import CommentActionMenu from 'labstep-web/components/Comment/Action/Menu';
import ProfilePictureImage from 'labstep-web/components/Profile/Picture/Image';
import CommentBody from 'labstep-web/components/Comment/Body';
import Card from 'labstep-web/core/Card/Card';
import CommentThread from 'labstep-web/components/Comment/Thread';
import { ToggleContainer } from 'labstep-web/containers';
import { ICONS } from 'labstep-web/constants';
import { DateSmall } from 'labstep-web/core/DatePreview';
import { Size } from 'labstep-web/core/Initials/types';
import { IItemContainerProps, IItemProps } from './types';
import styles from './styles.module.scss';

export const Item: React.FC<IItemProps> = ({
  comment,
  simple,
  readOnly,
  header,
  showPin,
  actions,
  additionalActions,
  showCommentThread = true,
  ...rest
}) => (
  <div
    data-testid="comment-item"
    className={classnames(styles.commentItemContainer, {
      [styles.indent]:
        (showPin && comment.pinned_at) ||
        (header &&
          (!!comment.parent_thread.protocol_step ||
            !!comment.parent_thread.metadata ||
            !!comment.parent_thread.experiment)),
    })}
  >
    <div>
      {showPin && comment.pinned_at && (
        <div className={styles.headerPin}>
          <b>
            <Icon name={ICONS.comment.actions.pin} />
            Pinned Note
          </b>
        </div>
      )}
      {header && header({ comment })}
    </div>
    <div className={styles.itemContainer}>
      {!simple && (
        <div className={styles.itemLeft}>
          <ProfilePictureImage
            entity={comment.author}
            size={Size.mini}
          />
        </div>
      )}
      <Card className={styles.card}>
        {simple && (
          <ProfilePictureImage
            entity={comment.author}
            size={Size.mini}
          />
        )}
        <div className={styles.itemRight}>
          <div className={styles.itemBody}>
            <div className={styles.itemNameDate}>
              <b>{comment.author.name}</b>
              <DateSmall dateString={comment.created_at} />
            </div>
            <CommentBody comment={comment} {...rest} />
          </div>
          <div className={styles.itemActions}>
            {!readOnly && (
              <>
                <CommentActionMenu
                  toggle={rest.toggle}
                  comment={comment}
                  actions={actions}
                />
                {additionalActions}
                {comment.thread && showCommentThread && (
                  <CommentThread comment={comment} />
                )}
              </>
            )}
          </div>
        </div>
      </Card>
    </div>
  </div>
);

Item.defaultProps = {
  readOnly: false,
};

const ItemContainer: React.FC<IItemContainerProps> = (props) => (
  <ToggleContainer>
    {(containerProps) => <Item {...props} {...containerProps} />}
  </ToggleContainer>
);

export default ItemContainer;
