/**
 * Labstep
 *
 * @module components/User/Form/Signin
 * @desc Signin form
 */

import React from 'react';
import ReusableForm from 'labstep-web/core/Form/Reusable';
import ContinueButton from 'labstep-web/core/Button/ContinueButton';
import { printStatusError } from 'labstep-web/components/User/Form/Signup';
import {
  UserLoginContainer,
  withAuthenticatedUser,
} from 'labstep-web/containers';
import {
  fieldUserCode,
  fieldUserPassword,
  fieldUserUsername,
} from '../fields';
import { IUserFormSigninProps } from './types';
import UserFormSigninUsername from './Username';

export const UserFormSignin: React.FC<IUserFormSigninProps> = ({
  options,
  authenticatedUser,
  formData,
  setFormData,
}) => {
  const { username, password } = formData;

  return (
    <div>
      {username && (
        <UserFormSigninUsername
          username={username}
          onClick={(): void => setFormData({})}
        />
      )}
      <UserLoginContainer>
        {({
          loginInternal,
          loginInternalCheck,
          status,
        }): React.ReactElement => {
          if (
            authenticatedUser.mfa_required &&
            !authenticatedUser.authenticated
          ) {
            return (
              <div>
                <ReusableForm
                  autoFocus
                  status={status}
                  onSubmit={({ code }): void => {
                    loginInternal(
                      { username, password, code },
                      options,
                    );
                  }}
                  fields={[fieldUserCode]}
                  submitButtonText="Verify"
                />
              </div>
            );
          }

          if (!username) {
            return (
              <ReusableForm
                onSubmit={(values): void => {
                  loginInternalCheck(
                    {
                      username: values.username,
                    },
                    {
                      onSuccess: () =>
                        setFormData({
                          username: values.username as string,
                        }),
                    },
                  );
                }}
                autoFocus
                fields={[fieldUserUsername]}
                error={printStatusError(status)}
                submitButton={
                  <ContinueButton fluid status={status}>
                    Continue
                  </ContinueButton>
                }
              />
            );
          }

          return (
            <ReusableForm
              onSubmit={(values: any): void => {
                const newFormData = {
                  username,
                  password: values.password,
                };
                setFormData(newFormData);
                loginInternal(newFormData, options);
              }}
              autoFocus
              fields={[fieldUserPassword]}
              error={printStatusError(status)}
              submitButton={
                <ContinueButton fluid status={status}>
                  Sign In
                </ContinueButton>
              }
            />
          );
        }}
      </UserLoginContainer>
    </div>
  );
};

export default withAuthenticatedUser(UserFormSignin);
