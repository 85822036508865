/**
 * Labstep
 *
 * @module components/GroupAutoSharing/Action/Create
 */

import React from 'react';
import ModalDefault from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ICONS } from 'labstep-web/constants';
import GroupAutoSharingFormCreate from 'labstep-web/components/GroupAutoSharing/Form/Create';
import { GroupActionCreateProps } from './types';

export const GroupAutoSharingActionCreate: React.FC<
  GroupActionCreateProps
> = ({ sourceGroup, actionComponentProps }) => (
  <ModalDefault
    header="New Autosharing Rule"
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        onClick={toggleModal}
        text="Add Autosharing Rule"
        type="text"
        icon={ICONS.access_key.primary}
        {...actionComponentProps}
      />
    )}
    content={({ toggleModal }) => (
      <GroupAutoSharingFormCreate
        body={{
          source_group_guid: sourceGroup.guid,
        }}
        options={{
          onSuccess: toggleModal,
          toast: true,
        }}
        sourceGroup={sourceGroup}
      />
    )}
  />
);

export default GroupAutoSharingActionCreate;
