/**
 * Labstep
 *
 * @module containers/User/SAMLLogin
 * @desc Login Redirect container
 */

import { connect } from 'react-redux';
import {
  requestSAMLLoginActionType,
  samlLoginAction,
} from 'labstep-web/state/actions';
import { selectApiStatus } from 'labstep-web/state/selectors';

const ChildrenContainer = ({ children, ...rest }: any) =>
  children(rest);

const mapStateToProps = (state: any) => ({
  status: selectApiStatus(state, requestSAMLLoginActionType),
});

const mapDispatchToProps = (dispatch: (...args: any[]) => void) => ({
  samlLogin: (organization: string, body: any) => {
    dispatch(samlLoginAction(organization, body));
  },
});

export const UserSAMLLoginContainer = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(ChildrenContainer);
