/**
 * Labstep
 */

import { PostFilterComparison } from 'labstep-web/hoc/Params/types';
import { PostFilter } from 'labstep-web/services/postFilter';

export const PostFilterAuthor = new PostFilter(
  [['guid', PostFilterComparison.eq]],
  'author',
);
