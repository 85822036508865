/**
 * Labstep
 *
 * @module screens/Master/Index/Center
 * @desc Generic Screen for rendering a list of entities with search bar + filters
 */

import React from 'react';
import { withRouter } from 'react-router';
import EntitySearch from 'labstep-web/components/Entity/Search';
import EntitySearchCursor from 'labstep-web/components/Entity/Search/Cursor';
import FullPageError from 'labstep-web/components/Layout/FullPageError';
import { Mobile } from 'labstep-web/core/MediaQuery';
import { withUiPersistent } from 'labstep-web/containers';
import styles from './styles.module.scss';
import {
  IMasterIndexCenterProps,
  IMasterIndexCenterWithHOCProps,
} from './types';

export const MasterIndexCenter: React.FC<
  IMasterIndexCenterWithHOCProps
> = ({
  children,
  leftPane,
  isCursor,
  location,
  initialSearchParams,
  uiPersistent,
  breadcrumb,
  ...rest
}) => {
  const Component = isCursor ? EntitySearchCursor : EntitySearch;
  return (
    <div className={styles.container}>
      <div className={styles.breadcrumbContainer}>{breadcrumb}</div>

      <Component
        historyAction="replace"
        errorComponent={<FullPageError />}
        initialSearchParams={
          uiPersistent.search_params[location.pathname] ||
          initialSearchParams
        }
        {...rest}
      >
        {children}
      </Component>
    </div>
  );
};

export const MasterIndexCenterContainer = withUiPersistent(
  withRouter(MasterIndexCenter),
);

export const MasterIndexCenterMobileContainer: React.FC<
  IMasterIndexCenterProps
> = (props) => (
  <Mobile>
    {({ match }): React.ReactElement => (
      <MasterIndexCenterContainer tableFormat={!match} {...props} />
    )}
  </Mobile>
);

export default MasterIndexCenterMobileContainer;
