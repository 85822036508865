/**
 * Labstep
 *
 * @module core/Card/Select
 * @desc Selectable Card
 */

import Checkbox from 'labstep-web/core/Checkbox';
import React from 'react';
import { Card } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { ICardSelectProps } from './types';

const CardSelect: React.FC<ICardSelectProps> = ({
  content,
  isSelected,
  setIsSelected,
}) => (
  <Card
    data-testid="card-select"
    as="div"
    className={styles.card}
    onClick={() => setIsSelected(!isSelected)}
  >
    <Card.Content>
      <div className={styles.content}>
        <Checkbox radio checked={isSelected} />
        {content}
      </div>
    </Card.Content>
  </Card>
);

export default CardSelect;
