/**
 * Labstep
 *
 * @module screens/Resource/Show/Right
 * @desc Resource Show Right
 */

import React from 'react';
import RightPane from 'labstep-web/components/Layout/RightPane';
import ThreadForm from 'labstep-web/components/Thread/Form';
import { ICONS } from 'labstep-web/constants';
import { IScreensResourceTemplateShowRightProps } from './types';

const ScreensResourceTemplateShowRight: React.FC<
  IScreensResourceTemplateShowRightProps
> = ({ resourceTemplate }) => (
  <RightPane
    route={{
      to: 'resource_template_show',
      params: { id: resourceTemplate.id },
    }}
    subPages={[
      {
        name: 'Notes',
        icon: ICONS.thread.primary,
        count: resourceTemplate.thread.comment_count,
        route: {
          to: 'resource_template_show_thread',
          params: { id: resourceTemplate.id },
        },
        component: (
          <ThreadForm
            createThreadId={resourceTemplate.thread.id}
            readThreadIds={resourceTemplate.thread.id}
            entityNameLabel="notes"
          />
        ),
      },
    ]}
  />
);

export default ScreensResourceTemplateShowRight;
