/**
 * Labstep
 *
 *
 * @desc Set SAML for a user
 */

import { EntityUpdateAnyContainer } from 'labstep-web/containers';
import React from 'react';
import ModalDefault from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import SearchSelect from 'labstep-web/core/Select/Search';
import { User } from 'labstep-web/models';
import { IUserActionSetSamlProps } from './types';

const UserActionSetSaml: React.FC<IUserActionSetSamlProps> = ({
  organization,
}) => (
  <EntityUpdateAnyContainer entityName={User.entityName} optimistic>
    {({ update }): React.ReactElement => {
      return (
        <ModalDefault
          header="Enable SSO for selected users"
          content={({ toggleModal }): React.ReactElement => (
            <SearchSelect
              autoFocus
              entityName={User.entityName}
              params={{ organization_id: organization.id }}
              onChange={(user: User): void => {
                const body = {
                  saml_id: user.username,
                  saml_provider: organization.identifier,
                };
                update(user.id, body);
                toggleModal();
              }}
            />
          )}
          viewComponent={({ toggleModal }): React.ReactElement => (
            <ActionComponent
              elementProps={{ basic: true, fluid: false }}
              text="Enable SSO for selected users"
              type="button"
              onClick={toggleModal}
              disabled={
                !organization.organization_saml
                  ?.areAllAttributesFilled
              }
            />
          )}
        />
      );
    }}
  </EntityUpdateAnyContainer>
);

export default UserActionSetSaml;
