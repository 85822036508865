/**
 * Labstep
 *
 * @module models/experiment
 * @desc Typescript export class for Experiment
 */

import { Type } from 'class-transformer';
import { Experiment } from 'labstep-web/models/experiment.model';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { Tag } from 'labstep-web/models/tag.model';
import { User } from 'labstep-web/models/user.model';
import { Signature } from 'labstep-web/models/signature.model';
import { PermaLink } from 'labstep-web/models/perma-link.model';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { Thread } from 'labstep-web/models/thread.model';
import { ShareLink } from 'labstep-web/models/share-link.model';
import { Entity } from 'labstep-web/models/entity.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { IPermissions, PermissionActions } from 'labstep-web/typings';
import { Group } from 'labstep-web/models/group.model';
import { Log } from 'labstep-web/models/log.model';
import { IIconProps } from 'labstep-web/core/Icon/types';
import { ExperimentWorkflowLink } from 'labstep-web/models/experiment-workflow-link.model';

interface SignatureTypeInfo {
  icon: IIconProps['name'];
  text: string;
  params: {
    signature_request_user_id?: 'false' | 'true';
    has_signature?: 'false' | 'true';
    has_signature_request?: 'false' | 'true';
  };
}

type SignatureTypeName = 'unsigned' | 'signed' | 'request_pending';

export const SignedTypes: Record<
  SignatureTypeName,
  SignatureTypeInfo
> = {
  unsigned: {
    icon: 'edit outline',
    text: 'Unsigned',
    params: {
      signature_request_user_id: undefined,
      has_signature: 'false',
      has_signature_request: 'false',
    },
  },
  request_pending: {
    icon: 'clock',
    text: 'Request Pending',
    params: {
      signature_request_user_id: undefined,
      has_signature: undefined,
      has_signature_request: 'true',
    },
  },
  signed: {
    icon: 'edit',
    text: 'Signed',
    params: {
      signature_request_user_id: undefined,
      has_signature: 'true',
      has_signature_request: 'false',
    },
  },
};

type StatusTypeName = 'planning' | 'in_progress' | 'completed';

interface StatusTypeInfo {
  icon: IIconProps['name'];
  text: string;
  color?: IIconProps['color'];
  params: {
    is_started: 'false' | 'true';
    is_ended: 'false' | 'true';
  };
}

export const ExperimentWorkflowStatusKeys = {
  planning: 'planning',
  in_progress: 'in_progress',
  completed: 'completed',
};

type IExperimentWorkflowStatusValues = {
  [key in keyof typeof ExperimentWorkflowStatusKeys]: string;
};

export const ExperimentWorkflowStatusValues: IExperimentWorkflowStatusValues =
  {
    planning: 'Planning',
    in_progress: 'In progress',
    completed: 'Completed',
  };

export const StatusTypes: Record<StatusTypeName, StatusTypeInfo> = {
  planning: {
    icon: 'clock outline',
    text: ExperimentWorkflowStatusValues.planning,
    params: {
      is_started: 'false',
      is_ended: 'false',
    },
  },
  in_progress: {
    icon: 'check circle',
    text: ExperimentWorkflowStatusValues.in_progress,
    color: 'yellow',
    params: {
      is_started: 'true',
      is_ended: 'false',
    },
  },
  completed: {
    icon: 'check circle',
    color: 'green',
    text: ExperimentWorkflowStatusValues.completed,
    params: { is_started: 'true', is_ended: 'true' },
  },
};

export class ExperimentWorkflow extends Entity {
  static readonly entityName = 'experiment_workflow';

  get entityName(): typeof ExperimentWorkflow.entityName {
    return ExperimentWorkflow.entityName;
  }

  constructor(data: Partial<ExperimentWorkflow> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  protected name!: string;

  custom_identifier!: string;

  started_at!: string;

  ended_at!: string;

  start_planned_at!: string;

  end_planned_at!: string;

  permissions!: IPermissions;

  allowed_actions!: PermissionActions[];

  allowed_actions_lock!: PermissionActions[];

  description!: string;

  comment_count!: number;

  experiment_count!: number;

  metadata_count!: number;

  protocol_device_count!: number;

  protocol_value_count!: number;

  forward_link_count!: number;

  back_link_count!: number;

  signature_count!: number;

  locked_at?: string;

  metadata_thread_ids!: number[];

  experiment_ids!: number[];

  is_template!: boolean;

  entity_users_count!: number;

  @Type(() => Group)
  owner!: Group;

  @Type(() => User)
  author!: User;

  @Type(() => Thread)
  thread!: Thread;

  @Type(() => Experiment)
  root_experiment!: Experiment;

  @Type(() => MetadataThread)
  metadata_thread!: MetadataThread;

  @Type(() => Experiment)
  experiments!: Experiment[];

  @Type(() => Signature)
  signatures!: Signature[];

  @Type(() => Tag)
  tags!: Tag[];

  @Type(() => ShareLink)
  share_link!: ShareLink;

  @Type(() => Log)
  locked_log!: Log;

  @Type(() => EntityUser)
  entity_users_preview!: EntityUser[];

  @Type(() => PermaLink)
  perma_link?: PermaLink;

  @Type(() => ExperimentWorkflow)
  forward_links!: ExperimentWorkflowLink[];

  @Type(() => ExperimentWorkflow)
  back_links!: ExperimentWorkflowLink[];

  get nameNoCustomIdentifier(): string {
    return this.name;
  }

  get displayName(): string {
    return this.custom_identifier
      ? `${this.custom_identifier} - ${this.name}`
      : this.name;
  }

  get isSigned(): boolean {
    return this.signature_count > 0;
  }

  get totalLinkCount(): number {
    return this.forward_link_count + this.back_link_count;
  }

  get status(): StatusTypeName {
    if (!this.started_at) {
      return 'planning';
    }
    if (!this.ended_at) {
      return 'in_progress';
    }
    return 'completed';
  }

  get activeStart(): string {
    return this.started_at || this.start_planned_at;
  }

  get activeEnd(): string {
    return this.ended_at || this.end_planned_at;
  }

  get incompleteExperimentsCount(): number {
    return this.experiments.reduce((count, e) => {
      const add = e.ended_at ? 0 : 1;
      return count + add;
    }, 0);
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
