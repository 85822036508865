/**
 * Labstep
 *
 * @module components/Entity/DataGrid/Action/ShowSideBar
 * @desc Entity DataGrid Action Show Side Bar
 */

import Button from 'labstep-web/core/Button';
import { useDataGridContext } from 'labstep-web/core/DataGrid/context';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { IEntityDataGridActionShowSideBarProps } from './types';

export const DATA_TEST_ID = 'entity-data-grid-action-show-side-bar';

export const EntityDataGridActionShowSideBar: React.FC<
  IEntityDataGridActionShowSideBarProps
> = ({ className }) => {
  const { agGrid } = useDataGridContext();
  if (!agGrid) {
    return null;
  }
  return (
    <Button
      data-testid={DATA_TEST_ID}
      className={className}
      onClick={() => {
        agGrid.api.setSideBarVisible(!agGrid.api.isSideBarVisible());
      }}
    >
      <Icon name="th list" />
      Columns
    </Button>
  );
};

export default EntityDataGridActionShowSideBar;
