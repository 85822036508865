/**
 * Labstep
 *
 * @module components/UserGroupNotification/Mode
 * @desc Order Request status + option to change
 */

import React from 'react';
import { EntityUpdateContainer } from 'labstep-web/containers';
import Dropdown from 'labstep-web/core/Dropdown';
import Icon from 'labstep-web/core/Icon';
import {
  UserGroupNotification,
  UserGroupNotificationMode,
  UserGroupNotificationModeValues,
} from 'labstep-web/models';
import { IUserGroupNotificationActionUpdateModeProps } from './types';
import styles from './styles.module.scss';

const UserGroupNotificationActionUpdateMode: React.FC<
  IUserGroupNotificationActionUpdateModeProps
> = ({ userGroupNotification }) => (
  <EntityUpdateContainer
    entityName={UserGroupNotification.entityName}
    id={userGroupNotification.id}
    optimistic
  >
    {({ update }) => (
      <Dropdown.Dropdown
        trigger={
          <span className={styles.trigger}>
            {
              UserGroupNotificationModeValues[
                userGroupNotification.mode
              ]
            }
            <Icon name="chevron down" />
          </span>
        }
        icon={null}
      >
        <Dropdown.Menu>
          {Object.keys(UserGroupNotificationMode).map((key) => (
            <Dropdown.Item
              key={key}
              onClick={() => {
                update({ mode: key });
              }}
            >
              {
                UserGroupNotificationModeValues[
                  key as keyof typeof UserGroupNotificationModeValues
                ]
              }{' '}
              {userGroupNotification &&
                userGroupNotification.mode === key && (
                  <Icon name="check" />
                )}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown.Dropdown>
    )}
  </EntityUpdateContainer>
);

export default UserGroupNotificationActionUpdateMode;
