import orderBy from 'lodash/orderBy';
import { Metadata, MetadataThread } from 'labstep-web/models';
import { IEntityWithMetadata } from 'labstep-web/models/metadata/types';

type getMetadatasType = (
  entity: IEntityWithMetadata,
  templateMetadataThread: MetadataThread | null,
  showAll: boolean,
) => [Metadata[], Metadata[], number];

// Number of metadata to display before hiding the rest behind 'show more'
export const INITIAL_METADATA_SHOWN = 4;

export const firstNFilter =
  (N: number) =>
  (e: unknown, index: number): boolean =>
    index < N;

export const positionAndDeletionFilter = (
  metadatas: Metadata[],
): Metadata[] =>
  orderBy(
    metadatas.filter((m) => !m.deleted_at),
    ['position'],
  );

export const getMetadatas: getMetadatasType = (
  entity,
  templateMetadataThread,
  showAll,
) => {
  const cap = INITIAL_METADATA_SHOWN;

  const templateMetadataIds = templateMetadataThread
    ? templateMetadataThread.metadatas.map((m) => m.id)
    : [];

  const baseMetadatas = positionAndDeletionFilter(
    templateMetadataThread
      ? templateMetadataThread.metadatas
      : entity.metadata_thread.metadatas,
  );

  const replacementMetadatas = positionAndDeletionFilter(
    templateMetadataThread
      ? entity.metadata_thread.metadatas.filter(
          (m) =>
            !m.template_id ||
            templateMetadataIds.indexOf(m.template_id) === -1,
        )
      : [],
  );

  const totalFieldsCount =
    baseMetadatas.length + replacementMetadatas.length;

  if (showAll) {
    return [baseMetadatas, replacementMetadatas, totalFieldsCount];
  }

  const cappedBaseMetadata = baseMetadatas.filter(firstNFilter(cap));
  const cappedReplacementMetadata = replacementMetadatas.filter(
    firstNFilter(cap - cappedBaseMetadata.length),
  );

  return [
    cappedBaseMetadata,
    cappedReplacementMetadata,
    totalFieldsCount,
  ];
};
