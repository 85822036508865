/**
 * Labstep
 *
 * @module screens/ProtocolCollection/Index/LeftPane
 * @desc ProtocolCollection Index LeftPane
 */

import FolderPane from 'labstep-web/components/Folder/Pane';
import LayoutLeftPane from 'labstep-web/components/Layout/LeftPane';
import ProtocolCollectionFilterPane from 'labstep-web/components/ProtocolCollection/Filter/Pane';
import { FolderType } from 'labstep-web/models';
import React from 'react';

export const ProtocolCollectionIndexLeftPane: React.FC = () => (
  <LayoutLeftPane>
    <FolderPane folderType={FolderType.protocol_collection} />
    <ProtocolCollectionFilterPane />
  </LayoutLeftPane>
);

export default ProtocolCollectionIndexLeftPane;
