import React, { useEffect, useState } from 'react';
import Redirect from 'labstep-web/core/Redirect';
import Loader from 'labstep-web/core/Loader';
import store from 'labstep-web/state/store';

const SetupAuthenticated = (props) => {
  const [setup, setSetup] = useState(false);
  const { username } = props.match.params;

  const id = username === 'member@labstep.com' ? 2 : 1;

  useEffect(() => {
    store.dispatch({
      type: 'SUCCESS_INTERNAL_LOGIN',
      meta: {
        method: 'POST',
        url: 'http://localhost/index.php/public-api/user/login',
        headers: {
          'Content-Type': 'application/json',
          'labstep-web-app-version': '10.7.0',
        },
        body: {
          username,
          password: username,
        },
        authProvider: 'internal',
        denormalized_payload: {
          id,
          username,
          enabled: true,
          group: {
            id: 1,
            name: 'Untitled Workspace',
            purchase_order_template_id: null,
            user_count: 1,
            created_at: '2021-07-07T14:24:33+00:00',
            updated_at: '2021-07-07T14:24:33+00:00',
            deleted_at: null,
            member_permission: 'edit',
            description: null,
            organization: null,
            profile: {
              id: 2,
              created_at: '2021-07-07T14:24:33+00:00',
              updated_at: '2021-07-07T14:24:33+00:00',
              photo: null,
            },
            allowed_actions: [],
            is_home: false,
            logged_user_user_group: null,
          },
          group_count: 3,
          is_external: false,
          enabled_db: true,
          name: 'test@labstep.com test@labstep.com',
          roles: [],
          experiment_workflow_count: 0,
          resource_count: 0,
          protocol_collection_count: 0,
          authenticated: null,
          auth_provider: null,
          token:
            'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MjU2NjgxNTYsImV4cCI6MTYyNTY3MTc1Niwicm9sZXMiOlsiUk9MRV9VU0VSIiwiUk9MRV9HUk9VUF8xIiwiUk9MRV9HUk9VUF80IiwiUk9MRV9HUk9VUF81Il0sInVzZXJuYW1lIjoidGVzdEBsYWJzdGVwLmNvbSIsInVzZXJfaWQiOjEsInVzZXJfZ3VpZCI6ImI2YTBiZTYyLWI2YmMtNDU4Yi1iZDMwLTYzNTRkMjQwZjY4NSJ9.SmT7Vfn9o1CoHRl4YNtX0Y_xkSdIY3cV1tunOHM7uxztuAGfgHV2v3syXMFStKCRO2sI5b1yRWo3lUN00U2U8oYN9hNG21w8tDXxcXSCG0XH2sJaHAY0a-43ejOfglYBfwxLDNVDnbAeyDtx-TO75ugZKReImw1f3RH9z-oj0dN4M_v6Oggh_MqfH2JbWXwqj-etu_-HeLPeqEuq6jfgY2guK3BRFl53sB1EVnXhDFs2vBbJkau42h1prUQJuvR-gBn2QaYC_4WF2ZUsxopnx5S_8NsZiL5IfqJ1EO0CfjehyDc2WRMTN2kj19ITf0RrafeMv_ZA59LxPGlFEndk8FVdcDG4Ex3Ps8EO3IIJL380wz4ncmDGu2xk-uH7ELz-2P_wxSYCpuLn-l0Qb6t26YRjsuMHw6lvPfGMh9vW415FYNvUxW2WQK8Q6FvODSs9jjdizghI4ran9LqLA2oKfqm_k71JFr6-9A33Hz_CNU1qrRblZLV7MESAOGOOupzP5OhMfoO4TG3ZYqdFlg5t-cNl6ouWwiLtnNIiz0pNI8jRciFEx9ZTR30ZnNdl80JXOgMLyLqDcJvo_IYGrv_oCC54pw8GUaoEE6KHMuQbofWQLyqsEriofnwgqXIwgruBH-i8X5EiABCvEtS15CR2eOjxNy6lY7bfE2XrAYs4VBo',
          refresh_token: username,
          api_key: username,
          guid: 'b6a0be62-b6bc-458b-bd30-6354d240f685',
          created_at: '2021-07-05T14:24:33+00:00',
          updated_at: '2021-07-07T14:28:55+00:00',
          deleted_at: null,
          consented_at: null,
          monthly_report_optout_at: null,
          first_name: username,
          last_name: username,
          is_two_factor_enabled: false,
          is_enterprise: false,
          is_signup_enterprise: false,
          testing: [],
          profile: {
            id: 1,
            created_at: '2021-07-07T14:24:33+00:00',
            updated_at: '2021-07-07T14:24:33+00:00',
            photo: null,
          },
          user_organizations: [],
          allowed_actions: [],
          thread: {
            all_comment_count: 0,
            comment_count: 0,
            id: 1,
          },
          is_new: null,
          metadata_thread: {
            metadata_count: 0,
            id: 1,
          },
          biography: null,
        },
      },
      payload: {
        id,
        username,
        enabled: true,
        group: {
          id: 1,
          name: 'Untitled Workspace',
          purchase_order_template_id: null,
          user_count: 1,
          created_at: '2021-07-07T14:24:33+00:00',
          updated_at: '2021-07-07T14:24:33+00:00',
          deleted_at: null,
          member_permission: 'edit',
          description: null,
          organization: null,
          profile: {
            id: 2,
            created_at: '2021-07-07T14:24:33+00:00',
            updated_at: '2021-07-07T14:24:33+00:00',
            photo: null,
          },
          allowed_actions: [],
          is_home: false,
          logged_user_user_group: null,
        },
        group_count: 3,
        is_external: false,
        enabled_db: true,
        name: 'test@labstep.com test@labstep.com',
        roles: [],
        experiment_workflow_count: 0,
        resource_count: 0,
        protocol_collection_count: 0,
        authenticated: null,
        auth_provider: null,
        token:
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MjU2NjgxNTYsImV4cCI6MTYyNTY3MTc1Niwicm9sZXMiOlsiUk9MRV9VU0VSIiwiUk9MRV9HUk9VUF8xIiwiUk9MRV9HUk9VUF80IiwiUk9MRV9HUk9VUF81Il0sInVzZXJuYW1lIjoidGVzdEBsYWJzdGVwLmNvbSIsInVzZXJfaWQiOjEsInVzZXJfZ3VpZCI6ImI2YTBiZTYyLWI2YmMtNDU4Yi1iZDMwLTYzNTRkMjQwZjY4NSJ9.SmT7Vfn9o1CoHRl4YNtX0Y_xkSdIY3cV1tunOHM7uxztuAGfgHV2v3syXMFStKCRO2sI5b1yRWo3lUN00U2U8oYN9hNG21w8tDXxcXSCG0XH2sJaHAY0a-43ejOfglYBfwxLDNVDnbAeyDtx-TO75ugZKReImw1f3RH9z-oj0dN4M_v6Oggh_MqfH2JbWXwqj-etu_-HeLPeqEuq6jfgY2guK3BRFl53sB1EVnXhDFs2vBbJkau42h1prUQJuvR-gBn2QaYC_4WF2ZUsxopnx5S_8NsZiL5IfqJ1EO0CfjehyDc2WRMTN2kj19ITf0RrafeMv_ZA59LxPGlFEndk8FVdcDG4Ex3Ps8EO3IIJL380wz4ncmDGu2xk-uH7ELz-2P_wxSYCpuLn-l0Qb6t26YRjsuMHw6lvPfGMh9vW415FYNvUxW2WQK8Q6FvODSs9jjdizghI4ran9LqLA2oKfqm_k71JFr6-9A33Hz_CNU1qrRblZLV7MESAOGOOupzP5OhMfoO4TG3ZYqdFlg5t-cNl6ouWwiLtnNIiz0pNI8jRciFEx9ZTR30ZnNdl80JXOgMLyLqDcJvo_IYGrv_oCC54pw8GUaoEE6KHMuQbofWQLyqsEriofnwgqXIwgruBH-i8X5EiABCvEtS15CR2eOjxNy6lY7bfE2XrAYs4VBo',
        refresh_token: username,
        api_key: username,
        guid: 'b6a0be62-b6bc-458b-bd30-6354d240f685',
        created_at: '2021-07-05T14:24:33+00:00',
        updated_at: '2021-07-07T14:28:55+00:00',
        deleted_at: null,
        consented_at: null,
        monthly_report_optout_at: null,
        first_name: username,
        last_name: username,
        is_two_factor_enabled: false,
        is_enterprise: false,
        is_signup_enterprise: false,
        testing: [],
        profile: {
          id: 1,
          created_at: '2021-07-07T14:24:33+00:00',
          updated_at: '2021-07-07T14:24:33+00:00',
          photo: null,
        },
        user_organizations: [],
        allowed_actions: [],
        thread: {
          all_comment_count: 0,
          comment_count: 0,
          id: 1,
        },
        is_new: null,
        metadata_thread: {
          metadata_count: 0,
          id: 1,
        },
        biography: null,
      },
    });
    setTimeout(() => {
      setSetup(true);
    });
  }, [username, id]);
  return setup ? (
    <Redirect to="app_homepage" />
  ) : (
    <Loader active inline />
  );
};

export default SetupAuthenticated;
