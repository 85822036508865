/**
 * Labstep
 *
 * @module components/Protocol/Form/ShowEdit/State/Container
 * @desc State container
 */

import React from 'react';
import { Mobile } from 'labstep-web/core/MediaQuery';
import ScrollToStepContainer from 'labstep-web/containers/ScrollToStep';
import ProseMirrorDebouncingContainer from 'labstep-web/containers/ProseMirror/Debouncing';
import { EntityUpdateContainer } from 'labstep-web/containers';
import ProtocolFormShowEditState from '..';
import { IProtocolFormShowEditStateContainerProps } from './types';
import ProtocolFormShowEditStateContainerEdit from './Edit';

export const ProtocolFormShowEditStateContainer: React.FC<
  IProtocolFormShowEditStateContainerProps
> = ({ protocol, ...rest }) => (
  <ScrollToStepContainer>
    <EntityUpdateContainer
      entityName={protocol.entityName}
      id={protocol.id}
      optimistic
    >
      {({ update, status }): JSX.Element => (
        <Mobile>
          {(mobileContainerProps): JSX.Element => (
            <ProseMirrorDebouncingContainer
              entity={protocol}
              status={status}
              update={update}
            >
              {(debouncingContainerProps): JSX.Element => (
                <ProtocolFormShowEditStateContainerEdit
                  entity={protocol}
                >
                  {(editContainerProps): JSX.Element => (
                    <ProtocolFormShowEditState
                      protocol={protocol}
                      {...mobileContainerProps}
                      {...debouncingContainerProps}
                      {...editContainerProps}
                      {...rest}
                    />
                  )}
                </ProtocolFormShowEditStateContainerEdit>
              )}
            </ProseMirrorDebouncingContainer>
          )}
        </Mobile>
      )}
    </EntityUpdateContainer>
  </ScrollToStepContainer>
);

export default ProtocolFormShowEditStateContainer;
