/**
 * Labstep
 *
 * @module components/ResourceTemplate/Action/Create
 * @desc Create Action for resource category
 */

import React from 'react';
import { navigation } from 'labstep-web/services/navigation';
import CoreActionCreate from 'labstep-web/components/Entity/Action/Create';
import { withRouter } from 'react-router';
import { Resource } from 'labstep-web/models';
import { IResourceTemplateActionCreateProps } from './types';

export const ResourceTemplateActionCreate: React.FC<
  IResourceTemplateActionCreateProps
> = ({ history, options = {}, actionComponentProps }) => {
  const enhancedOptions = {
    onSuccess: ({ response }) =>
      history.push(
        navigation.get('resource_template_show', {
          id: response.result,
        }),
      ),
    ...options,
  };

  return (
    <CoreActionCreate
      entityName={Resource.entityName}
      body={{ is_template: true }}
      options={enhancedOptions}
      actionComponentProps={{
        type: 'button',
        ...actionComponentProps,
      }}
    />
  );
};

export default withRouter(ResourceTemplateActionCreate);
