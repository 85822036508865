/**
 * Labstep
 *
 * @module prosemirror
 * @desc ProseMirror main entry point
 */

import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import { DOMParser, DOMSerializer } from 'prosemirror-model';
import schema from 'labstep-web/prosemirror/schema';
import { plugins as pluginsMarks } from 'labstep-web/prosemirror/marks';
import { getExtensionsPlugins } from 'labstep-web/prosemirror/extensions';
import { plugins as pluginsNodes } from 'labstep-web/prosemirror/nodes';
import { buildInputRules } from 'labstep-web/prosemirror/state/input-rules';
import ToolbarMain from 'labstep-web/prosemirror/components/Toolbar/Main';
import DangerousDiv from 'labstep-web/core/DangerousDiv';
import { EditorView } from 'prosemirror-view';
import { EditorState } from 'prosemirror-state';
import styles from '../styles.module.scss';
import { IProseMirrorHtmlOnlyProps } from './types';

let view: EditorView | null;

const ProseMirrorHtmlOnly: React.FC<IProseMirrorHtmlOnlyProps> = ({
  onChange,
  initialState,
  editable = true,
}) => {
  const [state, setState] = useState<EditorState | null>(null);
  const editorViewRef = useRef(null);

  const createEditorView = useCallback((editorViewDOM) => {
    const content = document.querySelector('#content');
    view = new EditorView(editorViewDOM, {
      state: EditorState.create({
        doc: content
          ? DOMParser.fromSchema(schema).parse(content)
          : undefined,
        schema,
        plugins: [
          ...pluginsNodes,
          ...pluginsMarks,
          ...getExtensionsPlugins(),
          buildInputRules(schema),
        ],
      }),
      dispatchTransaction(transaction) {
        if (!view) {
          return;
        }
        const newState = view.state.apply(transaction);
        view.updateState(newState);
        if (transaction.docChanged) {
          const target = document.createElement('div');
          const html = DOMSerializer.fromSchema(
            schema,
          ).serializeFragment(transaction.doc.content, {
            document,
          });
          target.append(html);
          onChange(target.innerHTML);
        }

        setState(view.state);
      },
      editable: () => editable,
    });
    view.focus();
    setState(view.state);

    return () => {
      if (view) {
        // This is required otherwise when navigating away from the
        // editor another transaction is sent causing the
        // step content to disappear and not saved
        view.destroy();
        view = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Create + Destroy prosemirror
    const editorViewDOM = editorViewRef.current;
    if (editorViewDOM) {
      createEditorView(editorViewDOM);
    }
    return () => {
      if (view) {
        // This is required otherwise when navigating away from the
        // editor another transaction is sent causing the
        // step content to disappear and not saved
        view.destroy();
        view = null;
      }
    };
  }, [createEditorView]);

  // useEffect(() => {
  //   window.addEventListener('keydown', preventKeyPropagation);
  //   return () =>
  //     window.removeEventListener('keydown', preventKeyPropagation);
  // }, []);

  if (editable) {
    return (
      <div
        className={classnames(styles.container, styles.editable)}
        style={{ maxHeight: 500, overflow: 'auto' }}
      >
        {state && view && view.editable && (
          <div className={styles.topMenu}>
            <ToolbarMain view={view} />
          </div>
        )}
        <div className={styles.editorContainer}>
          <div className={styles.innerEditorContainer}>
            <div
              className={styles.editor}
              style={{ minHeight: 400, paddingTop: 25 }}
            >
              <div
                className="prose-mirror-editor"
                ref={editorViewRef}
              />
              <DangerousDiv
                id="content"
                style={{ display: 'none' }}
                html={initialState}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.prosemirrorStyles}>
      <div className="prose-mirror-editor" ref={editorViewRef} />
    </div>
  );
};

export default ProseMirrorHtmlOnly;
