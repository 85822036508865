import FileActionDownload from 'labstep-web/components/File/Action/Download';
import { FileNamePreview } from 'labstep-web/components/File/Table';
import { GroupInitials } from 'labstep-web/components/Group/Initials';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import { Size } from 'labstep-web/core/Initials/types';
import { ISimpleTableProps } from 'labstep-web/core/Table/Simple/types';
import {
  PurchaseOrder,
  ResourceItem,
  File as FileModel,
} from 'labstep-web/models';
import { IObject } from 'labstep-web/typings';
import React from 'react';
import ProtectedEntityLink from '../../ProtectedEntityLink';

/**
 *
 * @description Function to get columns for table
 */
export const getColumns = (
  entityName: string,
): ISimpleTableProps['columns'] => {
  let columns = [
    {
      header: 'Owner',
      content: (entity) => {
        let group;
        if (entity instanceof ResourceItem) {
          group = entity.resource.owner;
        } else if (entity instanceof PurchaseOrder) {
          group = entity.group;
        } else {
          group = entity.owner;
        }

        return <GroupInitials group={group} />;
      },
      cellProps: { style: { width: 100 } },
    },
    {
      header: 'Name',
      content: (entity) => <ProtectedEntityLink entity={entity} />,
      cellProps: { style: { flex: 3 } },
    },
    {
      header: 'Creator',
      content: (entity) =>
        entity.author && (
          <ProfilePicture size={Size.mini} entity={entity.author} />
        ),
      cellProps: { style: { width: 100 } },
    },
  ];
  if (entityName === FileModel.entityName) {
    columns = [
      {
        header: 'Name',
        content: (entity) => <FileNamePreview file={entity} />,
        cellProps: { style: { flex: 3 } },
      },
      {
        header: '',
        content: (entity: FileModel) => (
          <FileActionDownload
            file={entity}
            actionComponentProps={{ type: 'icon' }}
          />
        ),
        cellProps: { style: { width: 100 } },
      },
    ];
  }
  return columns;
};

/**
 *
 * @description Function to transform group to group_id in searchParams
 */
export const getParams = (searchParams: IObject): IObject => {
  const params: any = {};
  if (searchParams.group) {
    params.group_id = searchParams.group.id;
  }
  return params;
};
