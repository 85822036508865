/**
 * Labstep
 */

import { ProtocolValue, ResourceItem } from 'labstep-web/models';
import { ICreateAction } from 'labstep-web/typings';

export const getOnChange =
  (
    protocolValue: ProtocolValue,
    createProtocolCondition: ICreateAction,
    createProtocolValue: ICreateAction,
    protocolValues: ProtocolValue[],
  ) =>
  (resourceItems: ResourceItem[] | null): void => {
    if (!resourceItems) {
      return;
    }

    const createBody = (
      values: ProtocolValue[],
    ): { resource_item_id: number; guid: string }[] =>
      values.map((v, i) => ({
        resource_item_id: resourceItems[i].id,
        guid: v.guid,
      }));

    if (protocolValues.length > 1 && protocolValue.is_input) {
      createProtocolValue(createBody(protocolValues));
      return;
    }

    const protocol = protocolValue.parent;
    createProtocolCondition(
      Array(resourceItems.length).fill({
        [`${protocol.entityName}_id`]: protocol.id,
      }),
      {
        onSuccess: ({ response }) => {
          createProtocolValue(
            createBody(
              (
                Object.values(
                  response.entities.protocol_value,
                ) as ProtocolValue[]
              ).filter(
                (v) =>
                  v.variable_template_guid === protocolValue.guid,
              ),
            ),
          );
        },
      },
    );
  };
