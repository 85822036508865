/**
 * Labstep
 *
 * @module components/Group/Settings/Experiment
 * @desc Set a default ExperimentWorkflow template for this group
 */

import React from 'react';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import GroupFormShowEditExperimentWorkflowTemplate from 'labstep-web/components/Group/Form/ShowEdit/DefaultExperimentWorkflowTemplate';
import GroupFormShowEditDefaultSignatureRequestMessage from 'labstep-web/components/Group/Form/ShowEdit/DefaultSignatureRequestMessage';
import GroupFormShowEditDefaultSignatureStatement from 'labstep-web/components/Group/Form/ShowEdit/DefaultSignatureStatement';
import GroupFormShowEditExperimentWorkflowCustomIdentifierSet from 'labstep-web/components/Group/Form/ShowEdit/ExperimentWorkflowCustomIdentifierSet';
import GroupActionSetIsSignatureTwoFactorRequired from 'labstep-web/components/Group/Action/SetIsSignatureTwoFactorRequired';
import GroupActionUpdateIsCompletionChecklistEnabled from 'labstep-web/components/Group/Action/UpdateIsCompletionChecklistEnabled';
import { Group } from 'labstep-web/models';
import { IGroupSettingsExperimentProps } from './types';

export const getRows = (group: Group) => {
  let rows = [
    {
      header: (
        <TextWithHelp
          text="Default Template"
          helperText="If you set a default template for this Workspace, all new experiments will use this template by default unless another template is specified at creation."
        />
      ),
      content: () => (
        <GroupFormShowEditExperimentWorkflowTemplate group={group} />
      ),
    },
    {
      header: (
        <TextWithHelp
          text="Completion Checklist"
          helperText="When users finish a protocol or experiment, show a checklist highlighting missing data fields and incomplete steps."
        />
      ),
      content: () => (
        <GroupActionUpdateIsCompletionChecklistEnabled
          group={group}
        />
      ),
    },
    {
      header: 'Default Signature Request Message',
      content: () => (
        <GroupFormShowEditDefaultSignatureRequestMessage
          group={group}
        />
      ),
    },
    {
      header: 'Default Signature Statement',
      content: () => (
        <GroupFormShowEditDefaultSignatureStatement group={group} />
      ),
    },
  ];

  if (group.canEnableSignatureTwoFactorRequirement) {
    rows = [
      ...rows,
      {
        header: (
          <TextWithHelp
            text="Require 2FA to Sign"
            helperText="Require users to enter a code from a verified device when signing experiments."
          />
        ),
        content: () => (
          <GroupActionSetIsSignatureTwoFactorRequired group={group} />
        ),
      },
    ];
  }

  rows = [
    ...rows,
    {
      header: 'Custom Identifier',
      content: () => (
        <GroupFormShowEditExperimentWorkflowCustomIdentifierSet
          group={group}
        />
      ),
    },
  ];

  return rows;
};

const GroupSettingsExperiment: React.FC<
  IGroupSettingsExperimentProps
> = ({ group }) => (
  <div id="group-settings-experiment">
    <HorizontalTable
      padded
      headerProps={{ width: 3 }}
      rows={getRows(group)}
      columns={[group]}
    />
  </div>
);

export default GroupSettingsExperiment;
