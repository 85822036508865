/**
 * Labstep
 *
 * @desc PermissionRole Form Create
 */

import React from 'react';
import FormCreate from 'labstep-web/core/Form/Create';
import { PermissionRole } from 'labstep-web/models';
import { fieldName } from 'labstep-web/components/PermissionRole/Form/fields';
import { IPermissionRoleFormCreateProps } from './types';

export const PermissionRoleFormCreate: React.FC<
  IPermissionRoleFormCreateProps
> = ({ organization, ...rest }) => (
  <FormCreate
    autoFocus
    entityName={PermissionRole.entityName}
    parentName={organization.entityName}
    parentId={organization.id}
    fields={[fieldName]}
    {...rest}
  />
);

export default PermissionRoleFormCreate;
