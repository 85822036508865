/**
 * Labstep
 *
 * @module state/reducers/ui
 * @desc Reducers for UI
 */

import { combineReducers } from 'redux';
import { REHYDRATE } from 'redux-persist/constants';
import { CONSENT_GDPR } from 'labstep-web/state/constants/user';
import { Action } from 'labstep-web/models';
import {
  CLEAR_ACTIVE_ENTITY,
  SET_ACTIVE_ENTITY,
  SET_CONNECTIVITY,
  TOGGLE_NEW_CONTENT,
} from 'labstep-web/state/constants';

/**
 * Generic active entity reducers
 */
export const activeEntity = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIVE_ENTITY:
      return {
        ...state,
        entityName: action.entityName,
        id: action.id,
      };
    case CLEAR_ACTIVE_ENTITY:
      return {};
    default:
      return state;
  }
};

/**
 * Generic connectivity reducer
 */
export const isConnected = (state = true, action) => {
  switch (action.type) {
    case SET_CONNECTIVITY:
      return action.isConnected;
    default:
      return state;
  }
};

/**
 * Reducer for generic api actions
 * @param  {Object} state  - State
 * @param  {object} action - Action
 * @return {object} - Returns new state
 */
export const apiActions = (state: any = {}, action: Action) => {
  if (action.type && action.type.includes('_RAW_OUTPUT')) {
    return state;
  }

  if (
    !(
      action &&
      action.type &&
      action.meta &&
      action.meta.customReducer
    )
  ) {
    return state;
  }

  if (action.type.startsWith('REQUEST')) {
    return {
      ...state,
      [action.meta.action_type]: { isFetching: true, error: null },
    };
  }
  if (action.type.startsWith('SUCCESS')) {
    return {
      ...state,
      [action.meta.action_type]: { isFetching: false, error: null },
    };
  }
  if (action.type.startsWith('FAIL')) {
    return {
      ...state,
      [action.meta.action_type]: {
        isFetching: false,
        error: action.error,
      },
    };
  }
  return state;
};

/**
 * Holds state to detect if app has been
 * rehydrated
 * @param  {Boolean} state
 * @param  {object}  action
 * @return {Boolean} state
 */
export const rehydrated = (state: any = false, action: Action) => {
  if (action.type === REHYDRATE) {
    return true;
  }
  return state;
};

export const consent = (state: any = false, action: Action) => {
  if (action.type === CONSENT_GDPR) {
    return !state;
  }
  return state;
};

/**
 * Reducer for toast
 * @param  {Object} state  - State
 * @param  {Object} action - Redux Action
 * @return {Object}        - New state
 */
export const toast = (state: any = {}, action: Action) => {
  if (
    Boolean(action.meta && action.meta.toast) ||
    (action.type && action.type.startsWith('FILE_UPLOAD')) ||
    (action.type && action.type.startsWith('FAIL_UPDATE')) ||
    (action.type && action.type.startsWith('FAIL_DELETE')) ||
    (action.type && action.type.startsWith('FAIL_CREATE'))
  ) {
    const message: any =
      typeof action.meta.toast === 'object'
        ? action.meta.toast.message
        : undefined;

    return {
      action_type: action.type,
      message,
      error: action.error,
      meta: action.meta,
      response: action.payload,
      timestamp: new Date(),
    };
  }
  if (action.type === 'CUSTOM_TOAST') {
    return {
      action_type: 'CUSTOM_TOAST',
      toast: action.toast,
      timestamp: new Date(),
    };
  }
  return state;
};

export const pendingReads = (state = 0, action) => {
  if (action.type && action.type.includes('_RAW_OUTPUT')) {
    return state;
  }
  if (action.type && action.type.startsWith('REQUEST_READ')) {
    return state + 1;
  }
  if (
    action.type &&
    (action.type.startsWith('SUCCESS_READ') ||
      action.type.startsWith('FAIL_READ'))
  ) {
    return state - 1;
  }
  return state;
};

export const maintenance = (state = false, action) => {
  if (action.type === 'SET_MAINTENANCE_ENABLED') {
    return true;
  }
  return state;
};

export const protocolEditorRequiresRefresh = (
  state = false,
  action,
) => {
  if (action.type.includes('READ')) {
    return false;
  }

  if (
    action.type.startsWith('FAIL_UPDATE_PROTOCOL') &&
    action.error.data &&
    action.error.data.message.includes(
      'has been used in an experiment and is now locked for editing',
    )
  ) {
    return true;
  }
  return state;
};

/**
 * Print
 */
export const print = (state: any = {}, action) => {
  if (action.type === 'PRINT_SUCCESS') {
    return {
      ...state,
      [action.payload.id]: action.payload.link,
    };
  }

  return state;
};

const reducers: any = combineReducers({
  active_entity: activeEntity,
  is_connected: isConnected,
  api_actions: apiActions,
  pending_reads: pendingReads,
  protocol_editor_requires_refresh: protocolEditorRequiresRefresh,
  rehydrated,
  consent,
  toast,
  maintenance,
  print,
});

export default reducers;
