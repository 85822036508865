/**
 * Labstep
 *
 * @module components/GroupAutoSharing/Action/Delete
 */

import React from 'react';
import ModalDefault from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ICONS } from 'labstep-web/constants';
import GroupAutoSharingFormDelete from 'labstep-web/components/GroupAutoSharing/Form/Delete';
import { GroupAutoSharingActionDeleteProps } from './types';

export const GroupAutoSharingActionDelete: React.FC<
  GroupAutoSharingActionDeleteProps
> = ({ groupAutoSharing }) => (
  <ModalDefault
    header="Delete an Autosharing Rule"
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        onClick={toggleModal}
        type="icon"
        icon={ICONS.file.actions.delete}
      />
    )}
    content={({ toggleModal }) => (
      <GroupAutoSharingFormDelete
        body={{
          guid: groupAutoSharing.guid,
        }}
        options={{
          onSuccess: toggleModal,
          toast: true,
        }}
        groupAutoSharing={groupAutoSharing}
      />
    )}
  />
);

export default GroupAutoSharingActionDelete;
