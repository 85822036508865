/**
 * Labstep
 */

import FilterMetadata from 'labstep-web/components/Filter/Metadata';
import { PostFilterMetadatasSupportedTypes } from 'labstep-web/components/Filter/Metadata/Form/fields';
import { IFilterMetadataProps } from 'labstep-web/components/Filter/Metadata/types';
import MetadataActionEdit from 'labstep-web/components/Metadata/Action/Edit';
import MetadataActionDelete from 'labstep-web/components/Metadata/Action/Delete';
import colDefValue from 'labstep-web/components/Metadata/DataGrid/colDefs/value';
import colDefMolecule from 'labstep-web/components/Molecule/DataGrid/colDefs';
import { ICONS } from 'labstep-web/constants';
import {
  ColDef,
  ColDefParams,
  ColGroupDef,
} from 'labstep-web/core/DataGrid/types';
import { getEntityDefault } from 'labstep-web/core/DataGrid/utils';
import { Metadata } from 'labstep-web/models';
import {
  IEntityWithMetadata,
  MetadataType,
  MetadataTypeValues,
} from 'labstep-web/models/metadata/types';
import React from 'react';
import { FilterMetadataType } from 'labstep-web/components/Filter/Metadata/Form/types';
import { IEntityDataGridContextMenuFilterProps } from '../../ContextMenu/types';

export const COLUMN_WIDTH_METADATA = 200;

/**
 * Using the template metadata (column) either return the corresponding metadata from
 * resource_item metadata thread or if it doesn't exist return the template metadata.
 * @param metadataTemplate Metadata template
 * @returns Function on metadata or template metadata
 */
export const getNestedMetadata =
  <T extends IEntityWithMetadata>(
    metadataTemplate: Metadata,
    getNestedParentEntity?: (
      params: ColDefParams<T>,
    ) => IEntityWithMetadata,
  ) =>
  (params: ColDefParams<T>): Metadata | undefined => {
    const getParentEntity = getNestedParentEntity || getEntityDefault;
    const parentEntity = getParentEntity(params);
    const metadata = parentEntity.metadata_thread?.metadatas?.find(
      (m) => m.template_id === metadataTemplate.id,
    );
    return metadata || metadataTemplate;
  };

/**
 * For all metadatas on resource template, return a column
 * @param entityName Entity name
 * @param template Template
 * @param getNestedParentEntity Custom fn to get parent entity as nested field
 * @returns Column definitions
 */
export const getMetadataColDefs = <T extends IEntityWithMetadata>(
  entityName: IEntityWithMetadata['entityName'],
  template: IEntityWithMetadata,
  getNestedParentEntity?: (
    params: ColDefParams<T>,
  ) => IEntityWithMetadata,
  filterProps?: Pick<
    IFilterMetadataProps,
    'entityName' | 'childEntityName'
  >,
): ColDef<T>[] => {
  const { metadatas } = template.metadata_thread;
  if (metadatas) {
    const colDefs: ColDef<T>[] = [];
    metadatas.forEach((metadataTemplate) => {
      const columnProps = {
        colId: `metadata:${metadataTemplate.guid}`,
        headerName: metadataTemplate.label || undefined,
        headerComponentParams: {
          icon: ICONS.metadata.secondary,
          cornerIcon: ICONS[entityName].primary,
          firstItems: (
            <MetadataActionEdit
              entity={template}
              metadata={metadataTemplate}
              actionComponentProps={{
                type: 'option',
                icon: 'cog',
                text: 'Configure field',
              }}
            />
          ),
          lastItems: (
            <MetadataActionDelete
              metadata={metadataTemplate}
              entity={template}
            />
          ),
          filter:
            filterProps &&
            PostFilterMetadatasSupportedTypes.includes(
              metadataTemplate.type,
            )
              ? ({
                  addPostFilter,
                  ...rest
                }: IEntityDataGridContextMenuFilterProps) =>
                  addPostFilter ? (
                    <FilterMetadata
                      defaultValues={{
                        label: metadataTemplate.label,
                        type: {
                          value:
                            metadataTemplate.type as FilterMetadataType,
                          label:
                            MetadataTypeValues[metadataTemplate.type],
                        },
                      }}
                      {...filterProps}
                      {...rest}
                      addPostFilter={addPostFilter}
                    />
                  ) : null
              : undefined,
        },
        width: COLUMN_WIDTH_METADATA,
      };
      const getNestedEntity = getNestedMetadata(
        metadataTemplate,
        getNestedParentEntity,
      );

      if (metadataTemplate.type === MetadataType.molecule) {
        const moleculeColDefs = colDefMetadataMolecule(
          entityName,
          getNestedEntity,
          getNestedParentEntity,
        ).children;
        moleculeColDefs.forEach((colDef) =>
          colDefs.push({
            ...colDef,
            colId: `${columnProps.colId}_molecule_${colDef.colId}`,
          }),
        );
      } else {
        colDefs.push(
          colDefValue({
            getNestedEntity,
            columnProps,
            getNestedParentEntity,
          }),
        );
      }
    });
    return colDefs;
  }
  return [];
};

/**
 * Column definition for metadata molecule
 * @param entityName Entity name
 * @param getNestedMetadataEntity Custom fn to get metadata as nested field
 * @param getNestedParentEntity Custom fn to get parent entity as nested field
 * @returns Column definition
 */
export const colDefMetadataMolecule = <T extends IEntityWithMetadata>(
  entityName: T['entityName'],
  getNestedMetadataEntity: (
    params: ColDefParams<T>,
  ) => Metadata | undefined,
  getNestedParentEntity?: (
    params: ColDefParams<T>,
  ) => IEntityWithMetadata,
): ColGroupDef<T> => {
  const getParentEntity = getNestedParentEntity || getEntityDefault;
  return colDefMolecule({
    getNestedEntity: (params) =>
      getNestedMetadataEntity(params)?.molecule,
    getCreateProps: (params) =>
      getNestedMetadataEntity(params)?.getCreateProps(
        getParentEntity(params),
      ),
    columnProps: {
      headerComponentParams: {
        icon: ICONS.metadata.secondary,
        cornerIcon: ICONS[entityName].primary,
      },
      width: COLUMN_WIDTH_METADATA,
    },
  });
};
