/**
 * Labstep
 *
 * @module components/Group/Action/Menu
 * @desc Action Menu for group
 */

import React from 'react';
import ActionMenu from 'labstep-web/core/Action/Menu';
import DeleteGroupAction from 'labstep-web/components/Group/Action/Delete';
import { IGroupActionMenuProps } from './types';

const GroupActionMenu: React.FC<IGroupActionMenuProps> = ({
  group,
  actions,
}) => (
  <ActionMenu type="icon">
    {actions.includes('delete') && (
      <DeleteGroupAction group={group} />
    )}
  </ActionMenu>
);

GroupActionMenu.defaultProps = {
  actions: [],
};

export default GroupActionMenu;
