/**
 * Labstep
 *
 * @module components/Group/Action/Create
 * @desc Action to create group
 */

import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import Button from 'labstep-web/core/Button';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import { Group } from 'labstep-web/models';
import { IGroupActionCreateProps } from './types';
import styles from './styles.module.scss';

export const GroupActionCreate: React.FC<IGroupActionCreateProps> = ({
  authenticatedUserEntity,
}) => {
  const { userOrganization } = authenticatedUserEntity;

  if (userOrganization?.type === 'guest') {
    return null;
  }

  if (
    userOrganization &&
    userOrganization.type === 'member' &&
    !userOrganization.organization.is_allow_member_create_group
  ) {
    return null;
  }
  return (
    <Button
      size="small"
      route={{ to: 'group_create' }}
      primary
      className={styles.button}
    >
      {`New ${getHumanReadableEntityName(
        Group.entityName,
        false,
        true,
      )}`}
    </Button>
  );
};

export default withAuthenticatedUserEntity(GroupActionCreate);
