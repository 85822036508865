/**
 * Labstep
 *
 * @module prosemirror/components/ReactPluginComponent
 */

import React, { ReactPortal, useContext } from 'react';
import ReactDOM from 'react-dom';
import shortid from 'shortid';
import {
  Experiment,
  ExperimentWorkflow,
  Protocol,
} from 'labstep-web/models';
import { EditorView } from 'prosemirror-view';

export interface IReactPluginComponentContext {
  view: EditorView;
  entity?: Experiment | Protocol;
  experimentWorkflow?: ExperimentWorkflow;
}

const ReactPluginComponentContext = React.createContext<
  Partial<IReactPluginComponentContext>
>({
  view: undefined,
  entity: undefined,
  experimentWorkflow: undefined,
});

class ReactPluginComponent {
  componentRef: React.RefObject<HTMLDivElement>;

  dom?: HTMLElement;

  contentDOM?: HTMLElement;

  component: React.FC<IReactPluginComponentContext>;

  view: EditorView;

  entity: Experiment | Protocol;

  experimentWorkflow: ExperimentWorkflow;

  createdPortal: ReactPortal;

  element?: HTMLElement;

  alwaysShow: boolean;

  constructor(
    view: EditorView,
    component: React.FC<any>,
    entity: Experiment | Protocol,
    experimentWorkflow: ExperimentWorkflow,
    element: HTMLElement,
    alwaysShow: boolean,
  ) {
    this.view = view;

    this.component = component;
    this.componentRef = React.createRef();
    this.entity = entity;
    this.experimentWorkflow = experimentWorkflow;
    this.element = element;
    this.alwaysShow = alwaysShow;
  }

  init() {
    if (this.element) {
      this.dom = this.element;
    } else {
      this.dom = document.createElement('div');
      this.view.dom.parentNode.appendChild(this.dom);
    }

    return {
      pluginComponent: this,
      portal: this.renderPortal(this.dom),
    };
  }

  update() {
    if (this.createdPortal) {
      this.createdPortal.children = this.render();
    }
  }

  render() {
    return this.view.editable || this.alwaysShow ? (
      <div className="ProseMirror__reactComponent">
        <ReactPluginComponentContext.Provider
          value={{
            view: this.view,
          }}
        >
          <this.component
            view={this.view}
            entity={this.entity}
            experimentWorkflow={this.experimentWorkflow}
          />
        </ReactPluginComponentContext.Provider>
      </div>
    ) : null;
  }

  renderPortal(container: HTMLElement) {
    this.createdPortal = ReactDOM.createPortal(
      this.render(),
      container,
      shortid.generate(),
    );
    return this.createdPortal;
  }

  destroy() {
    this.createdPortal.children = undefined;
    this.dom.remove();
  }
}

export interface TCreateReactPluginComponent
  extends IReactPluginComponentContext {
  component: React.FC<IReactPluginComponentContext>;
  element?: HTMLElement;
  onCreatePortal: (portal: any) => void;
  alwaysShow: boolean;
}

export const createReactPluginComponent = ({
  entity,
  experimentWorkflow,
  view,
  component,
  onCreatePortal,
  element,
  alwaysShow,
}: TCreateReactPluginComponent) => {
  const reactPluginComponent = new ReactPluginComponent(
    view,
    component,
    entity,
    experimentWorkflow,
    element,
    alwaysShow,
  );
  const { pluginComponent, portal } = reactPluginComponent.init();
  onCreatePortal(portal);

  return pluginComponent;
};
export const useReactPluginComponent = () =>
  useContext(ReactPluginComponentContext);
export default ReactPluginComponent;
