/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Main
 * @desc All available menus in ProseMirror
 * Each menu will handle its position and visibility based on its own business logic
 */

import React from 'react';
import { isMobile } from 'react-device-detect';
import MenuFile from 'labstep-web/prosemirror/components/Menu/File';
import MenuComment from 'labstep-web/prosemirror/components/Menu/Comment';
import MenuLink from 'labstep-web/prosemirror/components/Menu/Link';
import MenuCode from 'labstep-web/prosemirror/components/Menu/Code';
import MenuReferencing from 'labstep-web/prosemirror/components/Menu/Referencing';
import MenuCommands from 'labstep-web/prosemirror/components/Menu/Commands';
import { IMenuMainProps } from './types';

export const MenuMain: React.FC<IMenuMainProps> = ({
  entity,
  experimentWorkflow,
  view,
  state,
  dispatch,
  cursorPosition,
  editable,
}) => (
  <>
    <MenuFile
      state={state}
      dispatch={dispatch}
      view={view}
      cursorPosition={cursorPosition}
      editable={editable}
    />
    <MenuComment
      state={state}
      dispatch={dispatch}
      view={view}
      cursorPosition={cursorPosition}
    />
    {editable && (
      <>
        <MenuCode
          state={state}
          dispatch={dispatch}
          view={view}
          cursorPosition={cursorPosition}
        />
        <MenuLink
          state={state}
          dispatch={dispatch}
          view={view}
          cursorPosition={cursorPosition}
        />
        <MenuReferencing
          entity={entity}
          state={state}
          dispatch={dispatch}
          cursorPosition={cursorPosition}
          experimentWorkflow={experimentWorkflow}
        />
        {!isMobile && (
          <MenuCommands
            state={state}
            dispatch={dispatch}
            view={view}
            entity={entity}
            experimentWorkflow={experimentWorkflow}
            cursorPosition={cursorPosition}
          />
        )}
      </>
    )}
  </>
);

export default MenuMain;
