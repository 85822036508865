/**
 * Labstep
 *
 * @module core/Spreadsheet/Inline
 * @desc A simpler, read-only component for displaying the Spreadsheet tables
 */

import React from 'react';
import Spreadsheet from 'labstep-web/core/Spreadsheet';
import { v4 } from 'uuid';
// import { calculateTableSize } from 'labstep-web/services/table.service';
import { withActiveEditModal } from 'labstep-web/containers';
import { ISpreadsheetInlineProps } from './types';
import styles from './styles.module.scss';

export class SpreadsheetInline extends React.Component<ISpreadsheetInlineProps> {
  public shouldComponentUpdate(
    nextProps: ISpreadsheetInlineProps,
  ): boolean {
    return (
      nextProps.activeEditModal?.id !== this.props.activeEditModal?.id
    );
  }

  public render(): React.ReactElement {
    const { data, scaleToFit, ...rest } = this.props;

    const uuid = v4();
    return (
      <div
        className={styles.inlineSpreadsheet}
        key={uuid}
        style={{ height: rest.showToolbar ? '100%' : '400px' }}
      >
        <Spreadsheet
          data={data}
          showToolbar={false}
          spreadSheetProps={
            rest.showToolbar ? {} : { hostStyle: { height: '100%' } }
          }
          {...rest}
        />
      </div>
    );
  }
}

export default withActiveEditModal(SpreadsheetInline);
