/**
 * Labstep
 *
 * @module components/OrderRequest/FilterPane
 * @desc Urgent filter for order request
 */

import React from 'react';
import { FilterList } from 'labstep-web/components/Filter/List';
import { ICONS } from 'labstep-web/constants';
import { STRINGS } from 'labstep-web/strings';

const OrderRequestFilterUrgentList: React.FC = () => (
  <FilterList
    filters={[
      {
        icon: ICONS.order_request.info.urgent,
        text: STRINGS.order_request.info.urgent,
        params: { is_urgent: 'true' },
      },
    ]}
  />
);

export default OrderRequestFilterUrgentList;
