/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/FilterPane
 * @desc Filter pane for experiment worklfow
 */

import React from 'react';
import { FilterSection } from 'labstep-web/components/Filter/List';
import Icon from 'labstep-web/core/Icon';
import { StatusTypes } from 'labstep-web/models';

const ExperimentWorkflowFilterStatusList: React.FC = () => (
  <FilterSection
    filter={{ entityName: 'experiment_workflow', name: 'status' }}
    header="Status"
    filters={Object.values(StatusTypes).map((status) => {
      return {
        component: <Icon name={status.icon} color={status.color} />,
        text: status.text,
        params: status.params,
      };
    })}
  />
);

export default ExperimentWorkflowFilterStatusList;
