/**
 * Labstep
 *
 * @module containers/Position
 * @desc container to manipulate position of entities
 */

import { connect } from 'react-redux';
import { resetPositionActionCreator } from 'labstep-web/state/actions';

export const ResetPositionContainerChildren = ({
  children,
  ...rest
}) => {
  return children(rest);
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  resetPosition(entityName, entities) {
    dispatch(resetPositionActionCreator(entityName, entities));
  },
});

export const ResetPositionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPositionContainerChildren);
