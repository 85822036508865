/**
 * Labstep
 *
 * @desc Rendering links only on mobile
 */

import React from 'react';
import LayoutLinks from 'labstep-web/components/Layout/Links';
import Switch from 'labstep-web/core/Switch';
import { Desktop } from 'labstep-web/core/MediaQuery';
import { ILayoutLinksMobileProps } from './types';

const LayoutLinksMobile: React.FC<ILayoutLinksMobileProps> = ({
  links,
  screens,
}) => {
  const linksWithRender = links.map((route, i) => ({
    ...route,
    render: screens[i],
  }));

  return (
    <Desktop>
      {({ match }) =>
        !match ? (
          <LayoutLinks links={linksWithRender} noBottomMargin />
        ) : (
          <Switch links={linksWithRender} />
        )
      }
    </Desktop>
  );
};

export default LayoutLinksMobile;
