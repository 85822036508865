/**
 * Labstep
 *
 * @module screens/Tag/Index
 * @desc Tag manager page
 */

import TagActionCreate from 'labstep-web/components/Tag/Action/Create';
import TagEmptyState from 'labstep-web/components/Tag/Card/EmptyState';
import TagTable from 'labstep-web/components/Tag/Table';
import { Tag } from 'labstep-web/models';
import MasterIndex from 'labstep-web/screens/Master/Index';
import React, { useMemo } from 'react';
import TagIndexLeftPane from './LeftPane';
import { ITagIndexProps } from './types';

const TagIndex: React.FC<ITagIndexProps> = ({ group }) => {
  const params = useMemo(() => ({ group_id: group.id }), [group.id]);
  const leftPane = useMemo(() => <TagIndexLeftPane />, []);
  const action = useMemo(() => <TagActionCreate />, []);
  const noResultsMessage = useMemo(() => <TagEmptyState />, []);
  return (
    <MasterIndex
      entityName={Tag.entityName}
      params={params}
      leftPane={leftPane}
      tableFormat
      action={action}
      noResultsMessage={noResultsMessage}
    >
      {({ entities, ...rest }): React.ReactElement => (
        <TagTable tags={entities} {...rest} />
      )}
    </MasterIndex>
  );
};

export default TagIndex;
