/**
 * Labstep
 *
 * @module components/Chemical/Action/LinkToInventory
 * @desc Link to Inventory action
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import { useToggle } from 'labstep-web/hooks/toggle';
import {
  EntityReadCursorContainer,
  withActiveGroup,
} from 'labstep-web/containers';
import { Resource } from 'labstep-web/models';
import React from 'react';
import { IChemicalActionLinkToInventoryProps } from './types';

export const ChemicalActionLinkToInventory: React.FC<
  IChemicalActionLinkToInventoryProps
> = ({ chemical, disabled, activeGroup, onFetchEntities }) => {
  const [isActive, toggleIsActive] = useToggle();
  return (
    <EntityReadCursorContainer
      entityName={Resource.entityName}
      params={{
        search: 1,
        group_id: activeGroup?.id,
        molecule_inchi: chemical.properties.InChI,
      }}
    >
      {({ status, entities, read }) => {
        if (!(status && status.isFetching) && isActive) {
          // only call onFetchEntities once onClick
          toggleIsActive();
          onFetchEntities(entities);
        }
        return (
          <ActionComponent
            type="text"
            text="Link to Inventory"
            disabled={disabled}
            status={status}
            onClick={() => {
              toggleIsActive();
              read();
            }}
            elementProps={{ placeholder: true }}
          />
        );
      }}
    </EntityReadCursorContainer>
  );
};

export default withActiveGroup(ChemicalActionLinkToInventory);
