/**
 * Labstep
 *
 * @module components/ResourceItem/Action/Create
 * @desc Fields for resource item
 */

import rules from 'labstep-web/services/validation/rules';
import { Resource, ResourceItem } from 'labstep-web/models';
import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { unitsAsOptions } from 'labstep-web/constants/unit';

export const fieldResourceItemAmount: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'amount',
  placeholder: 'Add Amount',
  fieldLabel: 'Amount',
  validation: rules.resource_item.amount,
};

export const getFieldResourceItemUnit = (
  isChemical = false,
  additionalUnit?: string,
): IFieldWithoutActionProps => ({
  name: 'unit',
  placeholder: 'Add Unit',
  fieldLabel: 'Unit',
  validation: rules.resource_item.unit,
  fieldType: FieldType.ReactSelect,
  elementProps: {
    options: unitsAsOptions(isChemical, additionalUnit),
    creatable: !isChemical,
  },
});

export const fieldResourceItemName: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'name',
  placeholder: 'Specify Item Name (Optional)',
  fieldLabel: `${ResourceItem.getHumanReadableEntityName(
    false,
    true,
  )} name`,
  validation: rules.resource_item.name,
  header: true,
};

export const fieldResourceItemAmountAlertThreshold: IFieldWithoutActionProps =
  {
    fieldType: FieldType.Text,
    fieldLabel: 'Alert Threshold',
    name: 'amount_alert_threshold',
    validation: rules.resource_item.amount_alert_threshold,
    placeholder: 'Set alert threshold',
    fieldExplainer:
      'When the amount remaining falls below this threshold it will trigger a notification',
  };

export const getFieldsResource = ({
  groupId,
  resourceTemplateId,
}: {
  groupId?: number;
  resourceTemplateId?: number;
}): IFieldWithoutActionProps => {
  const field: IFieldWithoutActionProps = {
    fieldType: FieldType.SearchSelect,
    name: 'resource',
    elementProps: {
      entityName: 'resource',
      creatable: true,
      params: { group_id: groupId },
    },
    fieldLabel: Resource.getHumanReadableEntityName(false, true),
    validation: rules.resource_item.resource,
  };

  if (resourceTemplateId && field.elementProps) {
    field.elementProps.params = {
      ...field.elementProps.params,
      template_id: resourceTemplateId,
    };
    field.elementProps.createBody = {
      template_id: resourceTemplateId,
    };
  }

  return field;
};

export const getFieldsResourceItemCreateForm = ({
  groupId,
  resourceTemplateId,
}: {
  groupId?: number;
  resourceTemplateId?: number;
}): IFieldWithoutActionProps[] => {
  const fields = [
    getFieldsResource({ groupId, resourceTemplateId }),
    fieldResourceItemName,
  ];
  return fields;
};
