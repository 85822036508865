/**
 * Labstep
 *
 * @module components/EntityImport/DataGrid
 * @desc EntityImport Data Grid
 */

import React, { useCallback, useEffect, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { GetContextMenuItemsParams } from 'ag-grid-community';
import DataGrid from 'labstep-web/core/DataGrid';
import Icon from 'labstep-web/core/Icon';
import { ICONS } from 'labstep-web/constants';
import { useAgGridEntityImportServiceFromContext } from 'labstep-web/core/DataGrid/context';
import { AGGridEntityImportService } from 'labstep-web/services/agGrid/ag-grid-entity-import.service';
import { AGGridEntityImportValidationService } from 'labstep-web/services/agGrid/ag-grid-entity-import-validation.service';
import EntityImportActionAddRow from '../Action/AddRow';
import { EntityImportDataGridProps } from './types';

export const EntityImportDataGrid: React.FC<
  EntityImportDataGridProps
> = ({
  targetEntityName,
  template,
  columnDefs,
  rowData,
  showOnlyInvalidRows,
}) => {
  const agGridEntityImportService =
    useAgGridEntityImportServiceFromContext();

  useEffect(() => {
    if (agGridEntityImportService) {
      agGridEntityImportService.onFilterChanged();
    }
  }, [agGridEntityImportService, showOnlyInvalidRows]);

  const statusBar = useMemo(
    () => ({
      statusPanels: [
        {
          statusPanel: () => <EntityImportActionAddRow />,
          align: 'left',
        },
      ],
    }),
    [],
  );

  return (
    <DataGrid
      height="100%"
      defaultColDef={AGGridEntityImportService.defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
      context={{ template, targetEntityName }}
      suppressRowClickSelection
      isExternalFilterPresent={useCallback(
        (): boolean => showOnlyInvalidRows,
        [showOnlyInvalidRows],
      )}
      statusBar={statusBar}
      doesExternalFilterPass={useCallback(
        (node) =>
          AGGridEntityImportValidationService.isNodeInvalid(
            node,
            agGridEntityImportService?.getColumnDefs(),
          ),
        [
          showOnlyInvalidRows,
          agGridEntityImportService?.getColumnDefs(),
        ],
      )}
      getContextMenuItems={(event: GetContextMenuItemsParams) => [
        {
          name: 'Delete Row',
          icon: ReactDOMServer.renderToStaticMarkup(
            <Icon name={ICONS.ag_grid.delete_row} />,
          ),
          action: () => {
            if (event.api && event.node?.data) {
              event.api.applyTransaction({
                remove: [event.node.data],
              });
            }
          },
        },
        'separator',
        'copy',
        'separator',
        'export',
      ]}
    />
  );
};

export default EntityImportDataGrid;
