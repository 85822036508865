/**
 * Labstep
 */

import { MetadataType } from 'labstep-web/models/metadata/types';
import {
  EntityImportColDef,
  ENTITY_IMPORT_DATE_FORMATS,
  ENTITY_IMPORT_DATETIME_FORMATS,
} from 'labstep-web/models';

export const getOptions = (colDef: EntityImportColDef): string[] => {
  const metadata = colDef.entity_import?.metadata;
  if (metadata && metadata.type === MetadataType.date) {
    return ENTITY_IMPORT_DATE_FORMATS;
  }
  if (metadata && metadata.type === MetadataType.datetime) {
    return ENTITY_IMPORT_DATETIME_FORMATS;
  }
  return [];
};
