/**
 * Labstep
 *
 * @module components/Group/Settings/Ordering
 * @desc Ordering Group Card
 */

import OrderRequestTemplate from 'labstep-web/components/Group/Template/OrderRequest';
import PurchaseOrderTemplate from 'labstep-web/components/Group/Template/PurchaseOrder';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import React from 'react';
import styles from './styles.module.scss';
import { IGroupSettingsOrderingProps } from './types';

const GroupSettingsOrdering: React.FC<
  IGroupSettingsOrderingProps
> = ({ group }) => (
  <Flex column className={styles.container}>
    <div>
      <Header size="small">Request Template</Header>
      <OrderRequestTemplate group={group} />
    </div>

    <div>
      <Header size="small">Order Template</Header>
      <PurchaseOrderTemplate group={group} />
    </div>
  </Flex>
);

export default GroupSettingsOrdering;
