import {
  fieldCAS,
  fieldMolecularFormula,
  fieldMolecularWeight,
  fieldName,
} from 'labstep-web/components/PubChemMetadata/Form/fields';
import { ColGroupDef } from 'labstep-web/core/DataGrid/types';
import { Molecule } from 'labstep-web/models';
import { IEntityWithMetadata } from 'labstep-web/models/metadata/types';
import colDefPubchem from './pubchem';
import colDefSafety from './safety';
import colDefStructure from './structure';
import { colDefMoleculeParametersType } from './types';

const colDefMolecule = <T extends Molecule | IEntityWithMetadata>(
  options?: colDefMoleculeParametersType<T>,
): ColGroupDef<T> => ({
  headerName: Molecule.getHumanReadableEntityName(false, true),
  children: [
    colDefStructure(options),
    colDefPubchem(fieldName, options),
    colDefPubchem(fieldCAS, options),
    colDefPubchem(fieldMolecularFormula, options),
    colDefPubchem(fieldMolecularWeight, options),
    colDefSafety(options),
  ],
});

export default colDefMolecule;
