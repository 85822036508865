import { OrganizationPlanPrice } from 'labstep-web/models';
import { CurrencyISO } from 'labstep-web/services/currency.service';

export const defaultCurrency: CurrencyISO = 'gbp';
export const supportedCurrencies: CurrencyISO[] = [
  'usd',
  'gbp',
  'eur',
];

export const supportedIntervals: OrganizationPlanPrice['interval'][] =
  ['month', 'year'];
export const intervalLabels = {
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly',
  year: 'Annually',
} as const;
