/**
 * Labstep
 *
 * @module containers/VerifyEmail
 * @desc Sends request to backend to verify email and redirects the user
 * to homepage if successful
 */

import { connect } from 'react-redux';
import { confirmToken } from 'labstep-web/state/actions';
import { selectConfirmUserStatus } from 'labstep-web/state/selectors';

const mapStateToProps = (state, ownProps) => ({
  status: selectConfirmUserStatus(state, ownProps.match.params.token),
});

const mapDispatchToProps = (dispatch) => ({
  confirmToken(token, options) {
    dispatch(confirmToken(token, options));
  },
});

export const withVerifyEmail = (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);
