/**
 * Labstep
 *
 * @module screens/Authentication/Component
 * @desc Common component for authentication screen
 */

import React, { useEffect } from 'react';
import { UserLoginContainer } from 'labstep-web/containers';
import { withRouter } from 'react-router';
import {
  IAuthenticationComponentContainerProps,
  IAuthenticationComponentProps,
} from './types';

export const AuthenticationComponent: React.FC<
  IAuthenticationComponentProps
> = ({ url, children, clearLoginError }) => {
  useEffect(() => {
    clearLoginError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
  return <>{children}</>;
};

export const AuthenticationComponentContainer: React.FC<
  IAuthenticationComponentContainerProps
> = ({ match, children }) => (
  <UserLoginContainer>
    {({ clearLoginError }) => (
      <AuthenticationComponent
        url={match.url}
        clearLoginError={clearLoginError}
        children={children}
      />
    )}
  </UserLoginContainer>
);

export default withRouter(AuthenticationComponentContainer);
