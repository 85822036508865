/**
 * Labstep
 *
 * @module components/Entity/ActionView
 * @desc Core view action
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import { IPermissionRoleActionViewAllProps } from './types';

export const PermissionRoleActionViewAll: React.FC<
  IPermissionRoleActionViewAllProps
> = ({ authenticatedUserEntity }) => {
  if (
    !authenticatedUserEntity.userOrganization?.organization.hasFeatureEnabled(
      'custom_permissions',
    )
  ) {
    return null;
  }
  return (
    <Icon
      route={{
        to: 'organization_show_permissions',
        params: {
          identifier:
            authenticatedUserEntity.userOrganization.organization
              .identifier,
        },
      }}
      name="cog"
    />
  );
};

export default withAuthenticatedUserEntity(
  PermissionRoleActionViewAll,
);
