/**
 * Labstep
 *
 * @module components/ProtocolValue/List
 * @desc Protocol Value list
 */

import React from 'react';
import List from 'labstep-web/core/List';
import ProtocolValueItem from 'labstep-web/components/ProtocolValue/Item';
import EntityDraggable from 'labstep-web/components/Entity/Draggable';
import { Experiment, ProtocolValue } from 'labstep-web/models';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import {
  IProtocolValueListProps,
  IProtocolValueListContainerProps,
} from './types';

export const ProtocolValueList: React.FC<IProtocolValueListProps> = ({
  protocolValues,
}) => (
  <List id="protocol-value-list">
    {protocolValues.map((value) => (
      <EntityDraggable
        key={value.id}
        entityId={value.id}
        entityGuid={value.guid}
        entityName={value.entityName}
      >
        <ProtocolValueItem protocolValue={value} />
      </EntityDraggable>
    ))}
  </List>
);

export const ProtocolValueListContainer: React.FC<
  IProtocolValueListContainerProps
> = ({ protocol }) => {
  const { entityName } = ProtocolValue;
  return (
    <ReadOnMountHOC
      type="cursor"
      entityName={entityName}
      params={{
        [protocol instanceof Experiment
          ? 'experiment_id'
          : 'protocol_id']: protocol.id,
        has_variable_template: false,
        search: 1,
      }}
      children={({ entities: protocolValues }) => (
        <ProtocolValueList protocolValues={protocolValues} />
      )}
    />
  );
};

export default ProtocolValueList;
