/**
 * Labstep
 *
 * @module components/Folder/Action/Remove
 * @desc Action to remove parentEntity to folder
 */

import React from 'react';
import EntityActionRemove from 'labstep-web/components/Entity/Action/Remove';
import { ParamsContext } from 'labstep-web/hoc/Params/context';
import { Folder } from 'labstep-web/models';
import { EntityReadEntityContainer } from 'labstep-web/containers';
import { ParamsHOC } from 'labstep-web/hoc';
import { IFolderActionRemoveProps } from './types';

const FolderActionRemove: React.FC<IFolderActionRemoveProps> = ({
  parentId,
  parentName,
  actionComponentProps = {},
}) => (
  <ParamsHOC historyAction="replace">
    <ParamsContext.Consumer>
      {({ searchParams }) =>
        'folder_id' in searchParams && (
          <EntityReadEntityContainer
            entityName={Folder.entityName}
            id={searchParams.folder_id}
          >
            {({ entity }) =>
              entity ? (
                <EntityActionRemove
                  noConfirmation
                  entity={null}
                  entityId={entity.id}
                  entityName={entity.entityName}
                  parentName={parentName}
                  parentId={parentId}
                  actionComponentProps={{
                    type: 'option',
                    text: 'Remove from collection',
                    ...actionComponentProps,
                  }}
                />
              ) : null
            }
          </EntityReadEntityContainer>
        )
      }
    </ParamsContext.Consumer>
  </ParamsHOC>
);

export default FolderActionRemove;
