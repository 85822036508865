/**
 * Labstep
 *
 * @module components/Protocol/Action/AddVariable/Group
 * @desc Add Variable action group for Protocol
 */

import { ReadOnMountHOC } from 'labstep-web/hoc';
import React from 'react';
import ProtocolActionAddVariableGroupTable from './Table';
import { IProtocolActionAddVariableGroupProps } from './types';

export const ProtocolActionAddVariableGroup: React.FC<
  IProtocolActionAddVariableGroupProps
> = ({
  entityName,
  name,
  params,
  icon,
  isInput,
  action,
  protocol,
}) => (
  <>
    <ReadOnMountHOC
      // required for switching between input/output variable managers
      key={`${!!isInput}`}
      type="cursor"
      entityName={entityName}
      params={{
        count: 5,
        has_variable_template: false,
        is_input: !!isInput,
        search: 1,
        ...params,
      }}
      children={({ entities }) =>
        entities.length ? (
          <ProtocolActionAddVariableGroupTable
            entities={entities}
            entityName={entityName}
            name={name}
            icon={icon}
            isInput={isInput}
            protocol={protocol}
          />
        ) : null
      }
    />
    {action}
  </>
);

export default ProtocolActionAddVariableGroup;
