/**
 * Labstep
 *
 * @module components/Filter/Tag/Active
 * @desc Active tag filters
 */

import React from 'react';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { EntityReadEntityContainer } from 'labstep-web/containers';
import { Tag } from 'labstep-web/models';
import { onTagClear } from 'labstep-web/components/Filter/Tag/utils';
import { IFilterTagActiveProps } from './types';

export const FilterTagActive: React.FC<IFilterTagActiveProps> = ({
  searchParams,
  setParams,
}) => (
  <span>
    {searchParams.tag_id &&
      searchParams.tag_id.split(',').map((id: string) => (
        <EntityReadEntityContainer
          key={id}
          entityName={Tag.entityName}
          id={id}
        >
          {({ entity: tag }) =>
            tag ? (
              <RemoveLabel
                name={tag.name}
                icon="tag"
                status={{
                  isFetching: false,
                  error: null,
                }}
                onRemoveClick={() =>
                  onTagClear(tag.id, searchParams, setParams)
                }
              />
            ) : null
          }
        </EntityReadEntityContainer>
      ))}
  </span>
);

export default FilterTagActive;
