/**
 * Labstep
 *
 * @module components/UserGroup/Manager
 * @desc User group manager
 */

import React from 'react';
import Header from 'labstep-web/core/Header';
import UserGroupTable from 'labstep-web/components/UserGroup/Table';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { UserGroup } from 'labstep-web/models';
import { IUserGroupManagerProps } from './types';

export const UserGroupManager: React.FC<IUserGroupManagerProps> = ({
  userId,
}) => (
  <ReadOnMountHOC
    type="cursor"
    entityName={UserGroup.entityName}
    params={{ user_id: userId, search: 1, count: 10 }}
    children={({ entities }) => (
      <div>
        <Header size="mini">Workspaces</Header>
        <UserGroupTable userGroups={entities} />
      </div>
    )}
  />
);

export default UserGroupManager;
