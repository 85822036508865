/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/Index
 * @desc Experiment index
 */

import React, { useCallback, useMemo } from 'react';
import ExperimentWorkflowEmptyState from 'labstep-web/components/ExperimentWorkflow/Card/EmptyState';
import ExperimentWorkflowActionCreateMenu from 'labstep-web/components/ExperimentWorkflow/Action/CreateMenu';
import { ExperimentWorkflow } from 'labstep-web/models';
import ExperimentWorkflowListOrTable from 'labstep-web/components/ExperimentWorkflow/ListOrTable';
import MasterIndex from 'labstep-web/screens/Master/Index';
import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import { withUiPersistent } from 'labstep-web/containers';
import { IExperimentWorkflowIndexProps } from './types';
import ExperimentWorkflowIndexLeftPane from './LeftPane';
import ExperimentWorkflowIndexBreadcrumb from './Breadcrumb';
import { bulkActions, filters } from './constants';

export const ExperimentWorkflowIndex: React.FC<
  IExperimentWorkflowIndexProps
> = ({ group, uiPersistent }) => {
  const isTimelineActive =
    uiPersistent.experiment_workflow_view === 'timeline';
  const bulkActionWithExperimentWorkflowFinish = useMemo(
    () =>
      group.is_completion_checklist_enabled
        ? bulkActions
        : [...bulkActions, BulkAction.experiment_workflow_finish],
    [group],
  );
  const action = useCallback(({ searchParams }) => {
    const folderId = searchParams.folder_id;
    return (
      <ExperimentWorkflowActionCreateMenu
        body={folderId ? { folder_id: folderId } : undefined}
      />
    );
  }, []);
  const params = useMemo(() => ({ group_id: group.id }), [group.id]);
  const noResultsMessage = useCallback(
    ({ searchParams }) => (
      <ExperimentWorkflowEmptyState
        action={action({ searchParams })}
      />
    ),
    [action],
  );
  const breadcrumb = useMemo(
    () => <ExperimentWorkflowIndexBreadcrumb />,
    [],
  );
  const leftPane = useMemo(
    () => <ExperimentWorkflowIndexLeftPane />,
    [],
  );
  return (
    <MasterIndex
      breadcrumb={breadcrumb}
      leftPane={leftPane}
      params={params}
      noResultsMessage={noResultsMessage}
      filters={filters}
      action={action}
      entityName={ExperimentWorkflow.entityName}
      bulkActions={bulkActionWithExperimentWorkflowFinish}
      isCursor={isTimelineActive}
      hideAction
    >
      {({ entities, ...rest }): React.ReactElement => (
        <ExperimentWorkflowListOrTable
          experimentWorkflows={entities}
          {...rest}
        />
      )}
    </MasterIndex>
  );
};

export default withUiPersistent(ExperimentWorkflowIndex);
