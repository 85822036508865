/**
 * Labstep
 *
 * @module components/JupyterNotebook/Action/Run
 * @desc Run a jupyter notebook
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import Loader from 'labstep-web/core/Loader';
import Popup from 'labstep-web/core/Popup';
import RunStatus from 'labstep-web/components/JupyterInstance/RunStatus';
import { withJupyterRun } from 'labstep-web/containers';
import { JupyterNotebookActionRunProps } from './types';

export const JupyterNotebookActionRun: React.FC<
  JupyterNotebookActionRunProps
> = ({ jupyterNotebook, status, run }) => {
  if (!jupyterNotebook.data) {
    return null;
  }
  if (
    jupyterNotebook.jupyter_instance_run &&
    !jupyterNotebook.jupyter_instance_run.ended_at
  ) {
    return (
      <Popup
        inverted
        size="mini"
        content="Running..."
        trigger={<Loader size="tiny" inline active />}
      />
    );
  }
  return (
    <>
      <RunStatus
        jupyterInstance={jupyterNotebook.jupyter_instance_run}
      />
      <ActionComponent
        type="icon"
        icon={!jupyterNotebook.jupyter_instance_run ? 'play' : 'redo'}
        elementProps={{
          color: 'blue',
          popup: {
            content: !jupyterNotebook.jupyter_instance_run
              ? 'Run Code'
              : 'Re-run Code',
          },
        }}
        onClick={() => {
          run(jupyterNotebook.guid);
        }}
        status={status}
      />
    </>
  );
};

export default withJupyterRun(JupyterNotebookActionRun);
