/**
 * Labstep
 *
 *
 * @module prosemirror/components/NodeView/Metadata/Block
 * @desc The element that is rendered when a protocol value is added to the text editor
 */

import React from 'react';
import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import MetadataValue from 'labstep-web/components/Metadata/Value';
import MetadataFormShowEditValue from 'labstep-web/components/Metadata/Form/ShowEdit/Value';
import MetadataActionEdit from 'labstep-web/components/Metadata/Action/Edit';
import MetadataRequired from 'labstep-web/components/Metadata/Required';
import ProtocolConditionActionViewVariablesTable from 'labstep-web/components/ProtocolCondition/Action/ViewVariablesTable';
import { ICONS } from 'labstep-web/constants';
import { ProtocolCondition } from 'labstep-web/models';
import { useHasAccess } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import styles from './styles.module.scss';
import { IMetadataBlockComponentProps } from './types';

const MetadataBlockComponent: React.FC<
  IMetadataBlockComponentProps
> = ({ metadata, entity, readOnly }) => {
  const canEdit = useHasAccess(
    metadata.entityName,
    metadata.id,
    Action.edit,
  );
  const value = metadata.is_variable ? (
    <ProtocolConditionActionViewVariablesTable
      protocol={entity}
      actionComponentProps={{
        type: 'text',
        icon: 'external',
        text: `${
          entity.n_protocol_conditions
        } ${ProtocolCondition.getHumanReadableEntityName(true)}`,
      }}
    />
  ) : (
    <>
      {!readOnly && canEdit ? (
        <MetadataFormShowEditValue
          entity={entity}
          metadata={metadata}
        />
      ) : (
        <MetadataValue canEdit={false} metadata={metadata} />
      )}
      <MetadataRequired metadata={metadata} />
    </>
  );

  return (
    <div
      className={classnames(styles.metadataComponentContainer, {
        [styles.fullWidth]:
          ['file', 'sequence', 'molecule'].indexOf(metadata.type) >
            -1 && metadata.hasValue,
      })}
    >
      <div contentEditable={false}>
        {!readOnly && canEdit ? (
          <MetadataActionEdit
            entity={entity}
            metadata={metadata}
            actionComponentProps={{
              type: 'text',
              icon: ICONS.metadata.default,
              text: metadata.label || undefined,
              elementProps: {
                className: styles.metadataLabel,
              },
            }}
          />
        ) : (
          <span className={styles.metadataLabel}>
            <Icon name={ICONS.metadata.default} />
            {metadata.label}
          </span>
        )}
      </div>
      <div className={styles.metadataValue}>{value}</div>
    </div>
  );
};

export default MetadataBlockComponent;
