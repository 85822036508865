/**
 * Labstep
 *
 * @module components/Folder/Action/Delete
 * @desc Action to delete
 */

import React from 'react';
import DeleteAction from 'labstep-web/components/Entity/Action/Delete';
import { Folder } from 'labstep-web/models';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { IFolderDeleteActionProps } from './types';

const folderHumanReadableEntityName =
  Folder.getHumanReadableEntityName(false, true);

export const FolderDeleteAction: React.FC<
  IFolderDeleteActionProps
> = ({ folder, ...rest }) => (
  <DeleteAction
    {...rest}
    entity={folder}
    confirmMessage={`Are you sure you want to delete this ${folderHumanReadableEntityName}? ${getHumanReadableEntityName(
      folder.type,
      true,
      true,
    )} within this ${folderHumanReadableEntityName} will not be deleted.`}
  />
);

export default FolderDeleteAction;
