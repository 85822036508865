/**
 * Labstep
 *
 * @desc Imports (lazy) import action in error boundary
 */

import React, { Suspense, lazy } from 'react';
import { ErrorBoundaryContainer } from 'labstep-web/containers/ErrorBoundary';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ICONS } from 'labstep-web/constants';
import { ResourceItem } from 'labstep-web/models';
import { ResourceItemActionImportContainerProps } from './types';

const ResourceItemImportActionCreateComponent = lazy(
  () => import('./component'),
);

const ResourceItemImportActionCreate: React.FC<
  ResourceItemActionImportContainerProps
> = (props) => (
  <ErrorBoundaryContainer FallbackComponent={<div />}>
    <Suspense
      fallback={
        <ActionComponent
          type="text"
          icon={ICONS.resource_item.primary}
          text={`Add ${ResourceItem.getHumanReadableEntityName(
            true,
            true,
            props.resource.is_template,
          )}`}
          {...(props.actionComponentProps as any)}
          elementProps={{
            ...props.actionComponentProps?.elementProps,
            disabled: true,
          }}
        />
      }
    >
      <ResourceItemImportActionCreateComponent {...props} />
    </Suspense>
  </ErrorBoundaryContainer>
);

export default ResourceItemImportActionCreate;
