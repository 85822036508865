/**
 * Labstep
 *
 * @module components/ResourceLocation/Breadcrumb
 * @desc Breadcrumb for ResourceLocation
 */

import React from 'react';
import Breadcrumb from 'labstep-web/core/Breadcrumb';
import { IBreadcrumbLink } from 'labstep-web/core/Breadcrumb/types';
import { ICONS } from 'labstep-web/constants';
import { ResourceLocation } from 'labstep-web/models';
import { IResourceLocationBreadcrumbProps } from './types';

export const getOuterLocationLinks = (
  resourceLocation: ResourceLocation,
) => {
  const links: IBreadcrumbLink[] = [];

  let outerLocation = resourceLocation.outer_location;
  while (outerLocation) {
    links.unshift({
      name: outerLocation.name,
      icon: ICONS.resource_location.primary,
      route: {
        to: 'resource_location_show',
        params: {
          id: outerLocation.guid,
        },
      },
    });
    outerLocation = outerLocation.outer_location;
  }
  return links;
};

export const ResourceLocationBreadcrumb: React.FC<
  IResourceLocationBreadcrumbProps
> = ({ resourceLocation, includeSelf }) => {
  const links = getOuterLocationLinks(resourceLocation);

  if (includeSelf) {
    links.push({
      name: resourceLocation.name,
      icon: ICONS.resource_location.primary,

      route: {
        to: 'resource_location_show',
        params: {
          id: resourceLocation.guid,
        },
      },
    });
  }

  return <Breadcrumb links={links} />;
};

export default ResourceLocationBreadcrumb;
