/**
 * Labstep
 *
 * @module components/ResourceLocation/MapModal/Content/Right
 * @desc Right part of content
 */

import React from 'react';
import LayoutLinksState from 'labstep-web/components/Layout/Links/State';
import EntityLink from 'labstep-web/components/Entity/Link';
import ElementCard from 'labstep-web/core/Card/Element';
import ResourceLocationActionCreateSub from 'labstep-web/components/ResourceLocation/Action/CreateSub';
import ActionMenu from 'labstep-web/core/Action/Menu';
import ResourceItemActionDelete from 'labstep-web/components/ResourceItem/Action/Delete';
import ResourceLocationActionDelete from 'labstep-web/components/ResourceLocation/Action/Delete';
import { ResourceSubLocationEmptyState } from 'labstep-web/components/ResourceLocation/Card/EmptyState';
import { ResourceItem, ResourceLocation } from 'labstep-web/models';
import { ICONS } from 'labstep-web/constants';
import { IResourceLocationMapModalContentRightProps } from './types';
import ResourceLocationMapModalContentRightList from './List';

const ResourceLocationMapModalContentRight: React.FC<
  IResourceLocationMapModalContentRightProps
> = ({
  resourceLocation,
  onItemClick,
  selected,
  onDeleteSuccess,
}) => {
  const tabs = [
    {
      key: 'Items',
      render: () => (
        <ResourceLocationMapModalContentRightList
          selected={selected}
          entityName={ResourceItem.entityName}
          resourceLocation={resourceLocation}
          onItemClick={onItemClick}
          renderItem={({ entity, position }) => (
            <ElementCard
              icon={ICONS.resource_item.primary}
              right={
                <div style={{ cursor: 'pointer' }}>
                  <span>
                    {position
                      ? 'Click for position'
                      : 'Click or drag to place'}
                  </span>
                </div>
              }
              name={<EntityLink entity={entity} />}
              actions={
                <ActionMenu>
                  <ResourceItemActionDelete
                    resourceItem={entity as ResourceItem}
                    options={{
                      onSuccess: () =>
                        onDeleteSuccess(
                          `${entity.entityName}-${entity.id}`,
                        ),
                    }}
                  />
                </ActionMenu>
              }
            />
          )}
        />
      ),
    },
    {
      key: 'Sub-Locations',
      render: () => (
        <ResourceLocationMapModalContentRightList
          entityName={ResourceLocation.entityName}
          resourceLocation={resourceLocation}
          onItemClick={onItemClick}
          selected={selected}
          noResultsMessage={
            <ResourceSubLocationEmptyState
              resourceLocation={resourceLocation}
            />
          }
          action={
            <ResourceLocationActionCreateSub
              resourceLocation={resourceLocation}
            />
          }
          renderItem={({ entity, position }) => (
            <ElementCard
              icon={ICONS.resource_location.primary}
              name={<EntityLink secondary entity={entity} />}
              right={
                <div style={{ cursor: 'pointer' }}>
                  <span>
                    {position
                      ? 'Click for position'
                      : 'Click or drag to place'}
                  </span>
                </div>
              }
              actions={
                <ActionMenu>
                  <ResourceLocationActionDelete
                    resourceLocation={entity as ResourceLocation}
                    options={{
                      onSuccess: () =>
                        onDeleteSuccess(
                          `${entity.entityName}-${entity.id}`,
                        ),
                    }}
                  />
                </ActionMenu>
              }
            />
          )}
        />
      ),
    },
  ];
  return <LayoutLinksState tabs={tabs} />;
};

export default ResourceLocationMapModalContentRight;
