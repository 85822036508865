/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/PaymentMethod
 * @desc Organization plan card for payment method
 */

import React from 'react';
import OrganizationPlanCard from 'labstep-web/components/OrganizationPlan/Card';
import {
  PAYMENT_TYPE_BACS,
  PAYMENT_TYPE_CARD,
  PAYMENT_TYPE_SEPA,
} from 'labstep-web/models';
import { OrganizationPlanCardPaymentMethodProps } from './types';

export const OrganizationPlanCardPaymentMethod: React.FC<
  OrganizationPlanCardPaymentMethodProps
> = ({ organizationPlan, organization }) => (
  <OrganizationPlanCard
    icon="payment"
    header="Payment Method"
    secondary={
      <>
        {organization.stripe_payment_method_type ===
          PAYMENT_TYPE_CARD &&
          organizationPlan.payment_brand && (
            <div>
              <p>
                {organizationPlan.payment_brand.toUpperCase()} ending
                in {organizationPlan.payment_last4}
              </p>
              <p>
                Exp: {organizationPlan.payment_exp_month}/
                {organizationPlan.payment_exp_year}
              </p>
            </div>
          )}
        {organization.stripe_payment_method_type ===
          PAYMENT_TYPE_SEPA && <p>SEPA Direct Debit</p>}
        {organization.stripe_payment_method_type ===
          PAYMENT_TYPE_BACS && <p>BACS Direct Debit</p>}
        {!organization.stripe_payment_method_type && (
          <p>No payment details</p>
        )}
      </>
    }
  />
);

export default OrganizationPlanCardPaymentMethod;
