/**
 * Labstep
 *
 * @module components/UserOrganization/Action/ManagePermissions
 * @desc Update workspace permissions per user
 */

import React from 'react';
import ModalDefault from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import SearchSelect from 'labstep-web/core/Select/Search';
import UserGroupManager from 'labstep-web/components/UserGroup/Manager';
import {
  EntityCreateContainer,
  withAuthenticatedUserEntity,
} from 'labstep-web/containers';
import { Group, User, UserGroup } from 'labstep-web/models';
import styles from './styles.module.scss';
import { IUserOrganizationActionManagePermissionsProps } from './types';

export const UserOrganizationActionManagePermissions: React.FC<
  IUserOrganizationActionManagePermissionsProps
> = ({ userOrganization, authenticatedUserEntity }) => {
  if (authenticatedUserEntity.userOrganization?.type !== 'admin') {
    return null;
  }
  return (
    <ModalDefault
      header="Manage User Permissions"
      showDone
      content={() => (
        <div className={styles.content}>
          <EntityCreateContainer
            entityName={UserGroup.entityName}
            parentName={User.entityName}
            parentId={userOrganization.user.id}
          >
            {({ create, status }) => (
              <SearchSelect
                entityName={Group.entityName}
                params={{
                  organization_id: userOrganization.organization.id,
                  user_not_id: userOrganization.user.id,
                }}
                placeholder="Search for workspaces to add to..."
                onChange={(option) => {
                  create({
                    group_id: option.id,
                  });
                }}
                isClearable={false}
                isDisabled={status && status.isFetching}
              />
            )}
          </EntityCreateContainer>
          <UserGroupManager userId={userOrganization.user.id} />
        </div>
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon="users"
          text="Manage Permissions"
          onClick={toggleModal}
        />
      )}
    />
  );
};

export default withAuthenticatedUserEntity(
  UserOrganizationActionManagePermissions,
);
