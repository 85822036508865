/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/LockSettings
 * @desc Action to change an experiment workflow lock settings
 */

import React from 'react';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import { ExperimentWorkflow } from 'labstep-web/models';
import {
  EntityUpdateContainer,
  withAuthenticatedUserEntity,
} from 'labstep-web/containers';
import { ExperimentWorkflowActionLockSettingsProps } from './types';

export const ExperimentWorkflowActionLockSettings: React.FC<
  ExperimentWorkflowActionLockSettingsProps
> = ({ authenticatedUserEntity, experimentWorkflow }) => {
  if (
    !authenticatedUserEntity.userOrganization?.organization.hasFeatureEnabled(
      'lock_settings',
    )
  ) {
    return null;
  }
  return (
    <EntityUpdateContainer
      entityName={ExperimentWorkflow.entityName}
      id={experimentWorkflow.id}
    >
      {({ update }) => (
        <Modal
          header="Lock settings"
          viewComponent={({ toggleModal }) => (
            <ActionComponent
              type="button"
              text="Lock settings"
              onClick={toggleModal}
            />
          )}
          content={() => {
            return (
              <div>
                <div>
                  Lock fields exceptions:{' '}
                  {experimentWorkflow.allowed_actions_lock.length ===
                  0
                    ? 'no field'
                    : experimentWorkflow.allowed_actions_lock.join(
                        ', ',
                      )}
                </div>
                <div>
                  <ActionComponent
                    type="button"
                    text="Comment only"
                    onClick={() => {
                      update({ allowed_actions_lock: ['comment:*'] });
                    }}
                  />
                </div>
                <div>
                  <ActionComponent
                    type="button"
                    text="Partial"
                    onClick={() => {
                      update({
                        allowed_actions_lock: ['comment:*', 'edit'],
                      });
                    }}
                  />
                </div>
                <div>
                  <ActionComponent
                    type="button"
                    text="Full"
                    onClick={() => {
                      update({ allowed_actions_lock: [] });
                    }}
                  />
                </div>
              </div>
            );
          }}
        />
      )}
    </EntityUpdateContainer>
  );
};

export default withAuthenticatedUserEntity(
  ExperimentWorkflowActionLockSettings,
);
