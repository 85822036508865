/**
 * Labstep
 */

import { Metadata } from 'labstep-web/models';
import {
  METADATA_TYPES,
  METADATA_TYPES_NOT_LINKED,
} from 'labstep-web/models/metadata/constants';
import { MetadataType } from 'labstep-web/models/metadata/types';
import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';

export const FIELD_METADATA_LABEL: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'label',
  placeholder: 'Enter field name',
  fieldLabel: 'Field Name',
  validation: rules.metadata.label,
};

export const FIELD_METADATA_IS_REQUIRED: IFieldWithoutActionProps = {
  name: 'is_required',
  fieldLabel: 'Required',
  fieldType: FieldType.Checkbox,
  validation: rules.metadata.is_required,
};

export const FIELD_METADATA_TYPE: IFieldWithoutActionProps = {
  name: 'type',
  fieldLabel: 'Type',
  fieldType: FieldType.ReactSelect,
  validation: rules.metadata.type,
  elementProps: {
    options: METADATA_TYPES.map((metadataType) => ({
      label: metadataType.label,
      value: metadataType.value,
    })),
  },
};

export const FIELD_METADATA_TYPE_NOT_LINKED: IFieldWithoutActionProps =
  {
    name: 'type',
    fieldLabel: 'Type',
    fieldType: FieldType.ReactSelect,
    validation: rules.metadata.type,
    elementProps: {
      options: METADATA_TYPES_NOT_LINKED.map((metadataType) => ({
        label: metadataType.label,
        value: metadataType.value,
      })),
    },
  };

/* Return placeholder based on isTemplate */
export const getPlaceholder = (
  field: string,
  isTemplate?: boolean,
): string => `Enter${isTemplate ? ` default` : ''} ${field}`;

/* Return field label based on isTemplate */
export const getFieldLabel = (
  field: string,
  isTemplate?: boolean,
): string => `${isTemplate ? `Default ` : ''}${field}`;

/* Return field value definition */
export const getFieldsValue = (
  isTemplate?: boolean,
): IFieldWithoutActionProps[] => [
  {
    name: 'value',
    placeholder: getPlaceholder('value', isTemplate),
    fieldLabel: getFieldLabel('Value', isTemplate),
    fieldType: FieldType.Text,
    validation: rules.metadata.value,
  },
];

/* Return field date definition */
export const getFieldsDate = (
  isTemplate?: boolean,
): IFieldWithoutActionProps[] => [
  {
    name: 'date',
    placeholder: getPlaceholder('date', isTemplate),
    fieldLabel: getFieldLabel('Date', isTemplate),
    fieldType: FieldType.DateTimePicker,
    validation: rules.metadata.date,
  },
];

/* Return field datetime definition */
export const getFieldsDatetime = (
  isTemplate?: boolean,
): IFieldWithoutActionProps[] => [
  {
    name: 'date',
    placeholder: getPlaceholder('date', isTemplate),
    fieldLabel: getFieldLabel('Date and time', isTemplate),
    fieldType: FieldType.DateTimePicker,
    validation: rules.metadata.date,
    elementProps: {
      enableTime: true,
    },
  },
];

export const fieldUnit: IFieldWithoutActionProps = {
  name: 'unit',
  placeholder: 'unit',
  fieldLabel: 'Unit',
  fieldType: FieldType.Text,
  validation: rules.metadata.unit,
  elementProps: {
    withSymbols: true,
  },
};

/* Return fields numeric definition */
export const getFieldsNumeric = (
  isTemplate?: boolean,
): IFieldWithoutActionProps[] => [
  {
    name: 'number',
    placeholder: getPlaceholder('number', isTemplate),
    fieldLabel: getFieldLabel('Value', isTemplate),
    fieldType: FieldType.Text,
    validation: rules.metadata.number,
  },
  {
    name: 'unit',
    placeholder: getPlaceholder('unit', isTemplate),
    fieldLabel: getFieldLabel('Unit', isTemplate),
    fieldType: FieldType.Text,
    validation: rules.metadata.unit,
    elementProps: {
      withSymbols: true,
    },
  },
];

/* Return fields options definition */
export const getFieldsOptions = (
  metadata?: Metadata,
  inline?: boolean,
  isTemplate?: boolean,
  blurOnUnmount?: boolean,
): IFieldWithoutActionProps[] => {
  if (metadata && inline) {
    const options = metadata.optionsTemplate
      ? metadata.optionsTemplate.options
      : metadata.options;
    return [
      {
        placeholder: getPlaceholder('options', isTemplate),
        name: 'options_values',
        fieldLabel: isTemplate
          ? getFieldLabel('Options', isTemplate)
          : undefined,
        fieldType: FieldType.SelectOptions,
        validation: rules.metadata.options_values,
        elementProps: {
          multiple: !options ? false : options.is_allow_multiple,
          allowAdd: !options ? false : options.is_allow_add,
          blurOnUnmount,
        },
      },
    ];
  }
  return [
    {
      placeholder: getPlaceholder('options', isTemplate),
      fieldLabel: getFieldLabel('Options', isTemplate),
      name: 'options_values',
      fieldType: FieldType.SelectMulti,
      validation: rules.metadata.options_values,
    },
    {
      name: 'options_is_allow_multiple',
      fieldLabel: 'Allow multiple options',
      fieldType: FieldType.Checkbox,
      validation: rules.metadata.options_is_allow_multiple,
    },
    {
      name: 'options_is_allow_add',
      fieldLabel: 'Allow adding new options',
      fieldType: FieldType.Checkbox,
      validation: rules.metadata.options_is_allow_add,
      elementProps: {
        defaultValue: true,
      },
    },
  ];
};

/* Return field file definition */
export const getFieldsFile = (
  isTemplate?: boolean,
): IFieldWithoutActionProps[] => [
  {
    fieldLabel: getFieldLabel('File', isTemplate),
    placeholder: `Upload ${isTemplate ? 'default' : ''} file`,
    name: 'files',
    fieldType: FieldType.File,
    validation: rules.metadata.file,
    elementProps: {
      multiple: true,
    },
  },
];

/**
 * Returns form fields based on metadata type.
 *
 * @param type          Metadata type
 * @param isTemplate    If true, it means the metadata parent is a template
 *                      between configuring it or returning the field to select options.
 * @param metadata      This variable is only used for metadata 'options'
 *                      to send configuration variables to the field via the 'selectOptions' key.
 */
export const getMetadataFormFieldsAll = (
  type: MetadataType | null,
  isTemplate?: boolean,
  metadata?: Metadata,
  noValue?: boolean,
  hideLinkedTypes?: boolean,
): IFieldWithoutActionProps[] => {
  let fields: IFieldWithoutActionProps[] = [FIELD_METADATA_LABEL];
  if (!metadata) {
    fields.push(
      hideLinkedTypes
        ? FIELD_METADATA_TYPE_NOT_LINKED
        : FIELD_METADATA_TYPE,
    );
  }

  if (!noValue) {
    if (type === MetadataType.default) {
      fields = fields.concat(getFieldsValue(isTemplate));
    } else if (type === MetadataType.date) {
      fields = fields.concat(getFieldsDate(isTemplate));
    } else if (type === MetadataType.datetime) {
      fields = fields.concat(getFieldsDatetime(isTemplate));
    } else if (type === MetadataType.numeric) {
      fields = fields.concat(getFieldsNumeric(isTemplate));
    } else if (type === MetadataType.file) {
      fields = fields.concat(getFieldsFile(isTemplate));
    }
  }

  if (type === MetadataType.options) {
    fields = fields.concat(
      getFieldsOptions(metadata, false, isTemplate),
    );
  }

  if (isTemplate) {
    fields = [...fields, FIELD_METADATA_IS_REQUIRED];
  }

  return fields;
};

/**
 * Form fields (label only)
 */
export const getMetadataFormFieldsLabelOnly = (
  isTemplate?: boolean,
): IFieldWithoutActionProps[] => {
  if (isTemplate) {
    return [FIELD_METADATA_LABEL, FIELD_METADATA_IS_REQUIRED];
  }

  return [FIELD_METADATA_LABEL];
};

/**
 * Returns label only if metadata is a file and parent is template
 */
export const getMetadataFormFields = (
  type: MetadataType | null,
  isTemplate?: boolean,
  metadata?: Metadata,
  noValue?: boolean,
  hideLinkedTypes?: boolean,
) =>
  metadata && metadata.type === MetadataType.file && isTemplate
    ? getMetadataFormFieldsLabelOnly(isTemplate)
    : getMetadataFormFieldsAll(
        type,
        isTemplate,
        metadata,
        noValue,
        hideLinkedTypes,
      );
