/**
 * Labstep
 *
 * @module screens/OrderRequest/Show/Center
 * @desc OrderRequest Show  Center
 */

import React from 'react';
import OrderRequestStatusLog from 'labstep-web/components/OrderRequest/Status/Log';
import OrderRequestResourceItemCount from 'labstep-web/components/OrderRequest/ResourceItemCount';
import ResourceItemActionImport from 'labstep-web/components/ResourceItem/Action/Import';
import LayoutContainerActions from 'labstep-web/components/Layout/Container/Actions';
import { OrderRequestStatus, ResourceItem } from 'labstep-web/models';
import { IScreensOrderRequestShowCenterBottomProps } from './types';

const ScreensOrderRequestShowCenterBottom: React.FC<
  IScreensOrderRequestShowCenterBottomProps
> = ({ orderRequest }) => (
  <div>
    <LayoutContainerActions>
      {(orderRequest.status ===
        OrderRequestStatus.partially_received ||
        orderRequest.status === OrderRequestStatus.received) &&
        orderRequest.resource_item_count < orderRequest.quantity && (
          <>
            <ResourceItemActionImport
              resource={orderRequest.resource}
              orderRequest={orderRequest}
              actionComponentProps={{
                type: 'button',
                elementProps: { basic: true },
                text: `Add ${ResourceItem.getHumanReadableEntityName(
                  true,
                  false,
                  orderRequest.resource.is_template,
                )} to Inventory`,
              }}
            />
          </>
        )}
      <OrderRequestResourceItemCount orderRequest={orderRequest} />
    </LayoutContainerActions>
    <OrderRequestStatusLog orderRequest={orderRequest} />
  </div>
);

export default ScreensOrderRequestShowCenterBottom;
