/**
 * Labstep
 *
 * @module core/Number/Circle
 * @desc Number in a circle
 */

import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { INumberCircleProps } from './types';

const NumberCircle: React.FC<INumberCircleProps> = ({
  number,
  primary,
  complete,
}) => (
  <span
    className={classnames(styles.numberCircle, {
      [styles.primary]: primary,
      [styles.complete]: complete,
    })}
  >
    {complete ? <Icon name="check" /> : number}
  </span>
);

export default NumberCircle;
