/**
 * Labstep
 *
 * @module components/ResourceItem/Filter/ResourceLocation
 * @desc Select filter for resource location
 */

import React from 'react';
import Modal from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import SearchSelect from 'labstep-web/core/Select/Search';
import {
  EntityReadEntityContainer,
  withActiveGroup,
} from 'labstep-web/containers';
import { ICONS } from 'labstep-web/constants';
import { ResourceLocation } from 'labstep-web/models';
import { PostFilterResourceLocation } from 'labstep-web/services/postFilter/filters/resourceLocation';
import { placeholder } from './strings';
import { IResourceItemFilterResourceLocationProps } from './types';

export const ResourceItemFilterResourceLocation: React.FC<
  IResourceItemFilterResourceLocationProps
> = ({
  setParams,
  searchParams,
  actionComponentProps,
  activeGroup,
  addPostFilter,
}) => (
  <Modal
    header={ResourceLocation.getHumanReadableEntityName(false, true)}
    content={({ toggleModal }) => (
      <EntityReadEntityContainer
        entityName={ResourceLocation.entityName}
        id={searchParams.resource_location_id || 'null'}
      >
        {({ entity: resourceLocation }) => (
          <SearchSelect
            ejectFromElasticsearch
            value={resourceLocation}
            placeholder={placeholder}
            entityName={ResourceLocation.entityName}
            isClearable
            params={{
              group_id: activeGroup?.id,
            }}
            onChange={(selectedOption) => {
              if (addPostFilter) {
                addPostFilter(
                  PostFilterResourceLocation.getNode([
                    selectedOption?.guid,
                  ]),
                );
              } else {
                setParams({
                  resource_location_guid: selectedOption
                    ? selectedOption.guid
                    : undefined,
                });
              }
              toggleModal();
            }}
          />
        )}
      </EntityReadEntityContainer>
    )}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        type="option"
        icon={ICONS.resource_location.primary}
        text={ResourceLocation.getHumanReadableEntityName(
          false,
          true,
        )}
        onClick={toggleModal}
        {...actionComponentProps}
      />
    )}
  />
);

export default withActiveGroup(ResourceItemFilterResourceLocation);
