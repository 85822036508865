/**
 * Labstep
 *
 * @module components/Filter/Menu
 * @desc Filters menu
 */

import React from 'react';
import Dropdown from 'labstep-web/core/Dropdown';
import FilterDateRange from 'labstep-web/components/Filter/DateRange';
import FilterDeleted from 'labstep-web/components/Filter/Deleted';
import FilterTag from 'labstep-web/components/Filter/Tag/Select';
import FilterResourceItemStatus from 'labstep-web/components/Filter/ResourceItemStatus';
import FilterCreatedBy from 'labstep-web/components/Filter/CreatedBy';
import FilterAssignedTo from 'labstep-web/components/Filter/AssignedTo';
import FilterAvailableResourceItemCount from 'labstep-web/components/Filter/AvailableResourceItemCount';
import FileFilterExtensionSelect from 'labstep-web/components/File/Filter/Extension/Select';
import ExperimentWorkflowFilterSigned from 'labstep-web/components/ExperimentWorkflow/Filter/Signed/Select';
import ExperimentWorkflowFilterProtocol from 'labstep-web/components/ExperimentWorkflow/Filter/Protocol/Select';
import ExperimentWorkflowFilterResource from 'labstep-web/components/ExperimentWorkflow/Filter/Resource/Select';
import ExperimentWorkflowFilterStatus from 'labstep-web/components/ExperimentWorkflow/Filter/Status/Select';
import PurchaseOrderFilterStatus from 'labstep-web/components/PurchaseOrder/Filter/Status/Select';
import ResourceItemFilterResourceLocation from 'labstep-web/components/ResourceItem/Filter/ResourceLocation';
import FilterMetadata from 'labstep-web/components/Filter/Metadata';
import MoleculeFilter from 'labstep-web/components/Molecule/Filter';
import { Resource, ResourceItem } from 'labstep-web/models';
import { useParamsContext } from 'labstep-web/hoc/Params/context';
import { useEntitySearchContext } from 'labstep-web/components/Entity/Search/context';
import {
  Filter,
  IFilterMenuContentProps,
  IFilterMenuProps,
} from './types';

export const FilterMenuContent: React.FC<IFilterMenuContentProps> = ({
  setParams,
  filters,
  searchParams,
  entityName,
}) => (
  <>
    {filters.indexOf(Filter.deleted) > -1 && (
      <FilterDeleted
        setParams={setParams}
        searchParams={searchParams}
      />
    )}

    {filters.indexOf(Filter.is_user_deleted) > -1 && (
      <FilterDeleted
        setParams={setParams}
        searchParams={searchParams}
        paramKey="is_user_deleted"
      />
    )}

    {filters.indexOf(Filter.is_group_deleted) > -1 && (
      <FilterDeleted
        setParams={setParams}
        searchParams={searchParams}
        paramKey="is_group_deleted"
      />
    )}

    {filters.indexOf(Filter.date_range) > -1 && (
      <FilterDateRange
        setParams={setParams}
        searchParams={searchParams}
      />
    )}

    {(filters.indexOf(Filter.resource_item_status) > -1 ||
      filters.indexOf(Filter.order_request_status) > -1) && (
      <FilterResourceItemStatus
        isOrderRequest={
          filters.indexOf(Filter.order_request_status) > -1
        }
        setParams={setParams}
        searchParams={searchParams}
      />
    )}

    {filters.indexOf(Filter.available_resource_item_count) > -1 && (
      <FilterAvailableResourceItemCount
        setParams={setParams}
        searchParams={searchParams}
      />
    )}

    {filters.includes(Filter.extension) && (
      <FileFilterExtensionSelect
        setParams={setParams}
        searchParams={searchParams}
      />
    )}

    {(filters.indexOf(Filter.created_by) > -1 ||
      filters.indexOf(Filter.requested_by) > -1) && (
      <FilterCreatedBy
        searchParams={searchParams}
        setParams={setParams}
        filter={
          filters.indexOf(Filter.requested_by) > -1
            ? Filter.requested_by
            : Filter.created_by
        }
      />
    )}

    {filters.indexOf(Filter.assigned_to) > -1 && (
      <FilterAssignedTo
        searchParams={searchParams}
        setParams={setParams}
      />
    )}

    {filters.indexOf(Filter.tag) > -1 && (
      <FilterTag
        setParams={setParams}
        searchParams={searchParams}
        type={entityName}
      />
    )}

    {filters.indexOf(Filter.protocol) > -1 && (
      <ExperimentWorkflowFilterProtocol
        setParams={setParams}
        searchParams={searchParams}
      />
    )}

    {filters.indexOf(Filter.resource) > -1 && (
      <ExperimentWorkflowFilterResource
        setParams={setParams}
        searchParams={searchParams}
      />
    )}

    {filters.indexOf(Filter.signed) > -1 && (
      <ExperimentWorkflowFilterSigned
        setParams={setParams}
        searchParams={searchParams}
      />
    )}

    {filters.indexOf(Filter.experiment_workflow_status) > -1 && (
      <ExperimentWorkflowFilterStatus
        setParams={setParams}
        searchParams={searchParams}
      />
    )}

    {filters.indexOf(Filter.purchase_order_status) > -1 && (
      <PurchaseOrderFilterStatus
        setParams={setParams}
        searchParams={searchParams}
      />
    )}

    {filters.indexOf(Filter.resource_location) > -1 && (
      <ResourceItemFilterResourceLocation
        setParams={setParams}
        searchParams={searchParams}
      />
    )}

    {filters.indexOf(Filter.molecule) > -1 && (
      <MoleculeFilter
        setParams={setParams}
        searchParams={searchParams}
      />
    )}
  </>
);

export const FilterMenuContentPostFilter: React.FC<
  IFilterMenuContentProps
> = ({ filters, setParams, searchParams, addPostFilter }) => (
  <>
    {filters.indexOf(Filter.deleted) > -1 && (
      <FilterDeleted
        setParams={setParams}
        searchParams={searchParams}
        addPostFilter={addPostFilter}
      />
    )}

    {filters.indexOf(Filter.metadata) > -1 && (
      <FilterMetadata
        entityName={ResourceItem.entityName}
        addPostFilter={addPostFilter}
      />
    )}

    {filters.indexOf(Filter.resource_metadata) > -1 && (
      <FilterMetadata
        entityName={ResourceItem.entityName}
        childEntityName={Resource.entityName}
        addPostFilter={addPostFilter}
      />
    )}

    {filters.indexOf(Filter.resource_item_status) > -1 && (
      <FilterResourceItemStatus
        isOrderRequest={false}
        setParams={setParams}
        searchParams={searchParams}
        addPostFilter={addPostFilter}
      />
    )}

    {filters.indexOf(Filter.created_by) > -1 && (
      <FilterCreatedBy
        searchParams={searchParams}
        setParams={setParams}
        filter={Filter.created_by}
        addPostFilter={addPostFilter}
      />
    )}

    {filters.indexOf(Filter.assigned_to) > -1 && (
      <FilterAssignedTo
        searchParams={searchParams}
        setParams={setParams}
        addPostFilter={addPostFilter}
      />
    )}

    {filters.indexOf(Filter.resource_location) > -1 && (
      <ResourceItemFilterResourceLocation
        setParams={setParams}
        searchParams={searchParams}
        addPostFilter={addPostFilter}
      />
    )}
  </>
);

const FilterMenu: React.FC<IFilterMenuProps> = ({
  trigger,
  ...rest
}) => {
  const { searchParams, setParams, addPostFilter } =
    useParamsContext();
  const { usePostFilter } = useEntitySearchContext();
  return (
    <Dropdown
      data-testid="filter-menu"
      icon={null}
      trigger={trigger}
      pointing="top right"
    >
      {usePostFilter ? (
        <FilterMenuContentPostFilter
          searchParams={searchParams}
          setParams={setParams}
          addPostFilter={addPostFilter}
          {...rest}
        />
      ) : (
        <FilterMenuContent
          searchParams={searchParams}
          setParams={setParams}
          addPostFilter={addPostFilter}
          {...rest}
        />
      )}
    </Dropdown>
  );
};

export default FilterMenu;
