/**
 * Labstep
 *
 * @module screens/Device/Show
 * @desc Device Show Screen
 */

import React from 'react';
import MasterShow from 'labstep-web/screens/Master/Show';
import DocumentHead from 'labstep-web/core/DocumentHead';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { Device } from 'labstep-web/models';
import Center from './Center';
import Right from './Right';
import {
  IDeviceShowScreenContainerProps,
  IDeviceShowScreenProps,
} from './types';

export const DeviceShowScreen: React.FC<IDeviceShowScreenProps> = ({
  device,
}) => (
  <>
    <DocumentHead title={device.name} />
    <MasterShow
      entity={device}
      center={<Center device={device} />}
      right={<Right device={device} />}
    />
  </>
);

const DeviceShowScreenContainer: React.FC<
  IDeviceShowScreenContainerProps
> = ({ match }) => (
  <ReadOnMountHOC
    type="entities"
    entityName={Device.entityName}
    params={{
      get_single: 1,
      is_deleted: 'both',
      id: match.params.id,
    }}
    loading={{ loader: 'show', cached: true }}
    children={({ entity }) => <DeviceShowScreen device={entity} />}
  />
);

export default DeviceShowScreenContainer;
