/**
 * Labstep
 *
 * @module models/permission-role
 * @desc Typescript export class for PermissionRole
 */

import { Type } from 'class-transformer';
import { Organization } from 'labstep-web/models/organization.model';
import { Entity } from './entity.model';
import { PermissionRoleSetting } from './permission-role-setting.model';

export class PermissionRole extends Entity {
  public static readonly idAttr = 'guid' as const;

  static readonly entityName = 'permission_role';

  get entityName(): string {
    return PermissionRole.entityName;
  }

  constructor(data: Partial<PermissionRole> = {}) {
    super();
    Object.assign(this, data);
  }

  guid!: string;

  name!: string;

  @Type(() => Organization)
  organization!: Organization;

  @Type(() => PermissionRoleSetting)
  permission_role_settings!: PermissionRoleSetting[];
}
