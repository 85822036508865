/**
 * Labstep
 *
 * @module containers/Entity/Read/Entities
 * @desc Container for reading entities
 */

import { connect } from 'react-redux';
import { readEntities } from 'labstep-web/state/actions';
import {
  selectEntities,
  selectReadEntitiesStatus,
  selectSingleItems,
} from 'labstep-web/state/selectors';
import { Action } from 'labstep-web/models';
import { IOptions } from 'labstep-web/typings';
import { LabstepReduxState } from 'labstep-web/state/types';
import {
  IEntityReadEntitiesContainerChildrenProps,
  IEntityReadEntitiesContainerProps,
} from './types';

const Container = ({
  children,
  ...rest
}: {
  children: (
    props: IEntityReadEntitiesContainerChildrenProps,
  ) => React.ReactElement | null;
} & IEntityReadEntitiesContainerChildrenProps): React.ReactElement | null =>
  children(rest);

const mapStateToProps = (
  state: LabstepReduxState,
  ownProps: IEntityReadEntitiesContainerProps,
) => {
  const status = selectReadEntitiesStatus(
    state,
    ownProps.entityName,
    ownProps.params,
  );
  const getSingle =
    ownProps.params && ownProps.params.get_single === 1;
  let entity;
  let entities;
  if (getSingle) {
    entity = selectSingleItems(
      state,
      ownProps.entityName,
      ownProps.params,
    );
  } else {
    entities = selectEntities(
      state,
      ownProps.entityName,
      ownProps.params,
    );
  }
  return {
    entity,
    entities,
    status,
  };
};

const mapDispatchToProps = (
  dispatch: (action: Action) => void,
  ownProps: IEntityReadEntitiesContainerProps,
) => ({
  read(options?: IOptions) {
    dispatch(
      readEntities(ownProps.entityName, ownProps.params, options),
    );
  },
});

export const EntityReadEntitiesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
