import pickBy from 'lodash/pickBy';
import {
  IParamsHOCChildrenProps,
  PostFilterComparison,
  PostFilterNodeType,
  PostFilterPredicateType,
  PostFilterType,
} from './types';

/**
 * Checks if a value is valid
 * @param value unknown
 * @returns boolean
 */
const valueIsValid = (value: unknown): boolean =>
  value !== undefined && value !== '' && value !== null;

/**
 * We clear empty values i.e. when removing a value from a search select
 * We keep filters that don't have a value by default
 * i.e. have a comparison of null or not_null
 * @param predicate PostFilterPredicateType
 * @returns boolean
 */
const predicateIsValid = (
  predicate: PostFilterPredicateType,
): boolean => {
  if (
    predicate.comparison !== PostFilterComparison.null &&
    predicate.comparison !== PostFilterComparison.not_null
  ) {
    const { value } = predicate;
    return value !== undefined && value !== '' && value !== null;
  }
  return true;
};

/**
 * Sanitizes a node by removing predicates that are invalid
 * And removing the node if it has no predicates
 * @param node PostFilterNodeType
 * @returns PostFilterNodeType | null
 */
export const sanitizeNode = (
  node: PostFilterNodeType,
): PostFilterNodeType | null => {
  const newPostFilter = { ...node };
  const predicates: PostFilterPredicateType[] = [];
  node.predicates.forEach((p) => {
    if (predicateIsValid(p)) {
      predicates.push(p);
    }
  });
  if (!predicates.length) {
    return null;
  }
  newPostFilter.predicates = predicates;
  return newPostFilter;
};

/**
 * Sanitizes filter by removing nodes that are invalid
 * And removing the filter if it has no nodes
 * @param filter Filter
 * @returns Filter
 */
export const sanitizeFilter = (
  filter: PostFilterType | undefined,
): PostFilterType | undefined => {
  if (!filter) {
    return undefined;
  }
  const sanitizedFilter = filter.map((f) => {
    const { type, predicates } = f;
    const sanitizedNodes = predicates.map(sanitizeNode);
    // null if the node has no predicates
    const sanitizedNodesWithoutNull = sanitizedNodes.filter(
      (node) => node !== null,
    ) as PostFilterNodeType[];
    return { type, predicates: sanitizedNodesWithoutNull };
  }) as PostFilterType;

  if (sanitizedFilter.every((f) => f.predicates.length === 0)) {
    return undefined;
  }

  return sanitizedFilter;
};

/**
 * Sanitizes parameters without a value
 * @param params Parameters
 * @returns Parameters
 */
export const sanitizeParams = (
  params: IParamsHOCChildrenProps['searchParams'] | undefined,
): IParamsHOCChildrenProps['searchParams'] => {
  let sanitizedParams = { ...params };
  sanitizedParams.filter = sanitizeFilter(sanitizedParams.filter);
  sanitizedParams = pickBy(sanitizedParams || {}, valueIsValid);

  return sanitizedParams;
};
