/**
 * Labstep
 *
 * @module models/signature
 * @desc Typescript export class for ShareLink
 */

import { Type } from 'class-transformer';
import { User } from 'labstep-web/models/user.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Entity } from 'labstep-web/models/entity.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export class Signature extends Entity {
  static readonly entityName = 'signature';

  get entityName(): string {
    return Signature.entityName;
  }

  constructor(data: Partial<Signature> = {}) {
    super();
    Object.assign(this, data);
  }

  id: number;

  @Type(() => ExperimentWorkflow)
  experimentWorkflow: ExperimentWorkflow;

  @Type(() => User)
  author: User;

  statement: string;

  revoked_at: string;

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
