/**
 * Labstep
 *
 * @module components/Entity/Action/UpdateTemplate
 * @desc Action to update template of an entity
 */

import React from 'react';
import ModalDefault from 'labstep-web/core/Modal/Default';
import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import SearchSelect from 'labstep-web/core/Select/Search';
import {
  EntityUpdateContainer,
  withActiveGroup,
} from 'labstep-web/containers';
import { ICONS } from 'labstep-web/constants';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import { IEntityActionUpdateTemplateProps } from './types';

export const EntityActionUpdateTemplate: React.FC<
  IEntityActionUpdateTemplateProps
> = ({
  entity,
  actionType = 'option',
  activeGroup,
  modalProps,
  options,
}) => (
  <ModalDefault
    header="Set Category"
    content={({ toggleModal }): React.ReactElement => (
      <EntityUpdateContainer
        entityName={entity.entityName}
        id={entity.id}
        optimistic={false}
      >
        {({ update, status }): React.ReactElement => (
          <SearchSelect
            params={{ is_template: true, group_id: activeGroup?.id }}
            autoFocus
            openMenuOnFocus
            creatable
            createBody={{ is_template: true }}
            entityName={entity.entityName}
            value={null}
            isDisabled={status && status.isFetching}
            onChange={(selectedOption): void => {
              update(
                { template_id: selectedOption.id },
                enhanceOptions({ options, onSuccess: toggleModal }),
              );
            }}
          />
        )}
      </EntityUpdateContainer>
    )}
    viewComponent={({ toggleModal }): React.ReactElement => (
      <EntityActionEdit
        entity={entity}
        actionComponentProps={{
          type: actionType,
          onClick: toggleModal,
          text: 'Set Category',
          icon: ICONS.resource_template.primary,
        }}
      />
    )}
    {...modalProps}
  />
);

export default withActiveGroup(EntityActionUpdateTemplate);
