/**
 * Labstep
 *
 * @module components/Chemical/Form/ShowEdit/Purity
 * @desc ShowEditPurity for Chemical
 */

import React from 'react';
import FormShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import { Chemical } from 'labstep-web/models';
import { fieldPurity } from 'labstep-web/components/Chemical/Form/fields';
import { IChemicalFormShowEditPurityProps } from './types';

export const ChemicalFormShowEditPurity: React.FC<
  IChemicalFormShowEditPurityProps
> = ({ chemical }) => (
  <FormShowEditText
    entityName={Chemical.entityName}
    entity={chemical}
    field={fieldPurity}
    placeholder="Enter"
  >
    {chemical.purity}
  </FormShowEditText>
);

export default ChemicalFormShowEditPurity;
