/**
 * Lists the versions of a protocol collection
 */

import React from 'react';
import { humanReadableDate } from 'labstep-web/services/date.service';
import cx from 'classnames';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import LinkButton from 'labstep-web/core/Link/Button';
import { Size } from 'labstep-web/core/Initials/types';
import styles from './styles.module.scss';
import {
  IProtocolCollectionVersionProps,
  IProtocolListProps,
} from './types';

export const ProtocolCollectionVersion: React.FC<
  IProtocolCollectionVersionProps
> = ({ protocol, active }) => (
  <div className={styles.item}>
    <ProfilePicture
      size={Size.mini}
      entity={protocol.author}
      linkable={false}
    />
    <LinkButton
      className={cx(styles.changes, { [styles.active]: active })}
      route={{
        to: protocol.is_last_version
          ? 'protocol_collection_show'
          : 'protocol_show',
        params: {
          id: protocol.is_last_version
            ? protocol.collection.id
            : protocol.id,
        },
      }}
    >
      <div className={styles.date}>
        {humanReadableDate(protocol.created_at)}
      </div>
      <div>
        <b>{protocol.printVersion}</b>
      </div>
      <div>{protocol.changes}</div>
    </LinkButton>
  </div>
);

const ProtocolList: React.FC<IProtocolListProps> = ({
  activeProtocol,
  protocolCollectionId,
}) => (
  <ReadOnMountHOC
    type="cursor"
    loading={{ loader: false }}
    pagination={{ infinite: false }}
    entityName="protocol"
    params={{ protocol_collection_id: protocolCollectionId }}
    children={({ entities }) => (
      <div className={styles.list}>
        {entities.map((protocol) => (
          <ProtocolCollectionVersion
            key={protocol.id}
            protocol={protocol}
            active={activeProtocol.id === protocol.id}
          />
        ))}
      </div>
    )}
  />
);

export default ProtocolList;
