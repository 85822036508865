/**
 * Labstep
 *
 * @module components/Comment/Form/Create
 * @desc Reply bar for comments
 */

import React from 'react';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import Mentions from 'labstep-web/core/Mentions';
import Button from 'labstep-web/core/Button';
import FileActionUpload from 'labstep-web/components/File/Action/Upload';
import AttachmentMenu from 'labstep-web/components/Comment/AttachmentMenu';
import { CanCreate } from 'labstep-web/components/Entity/Can';
import { EntityCreateContainer } from 'labstep-web/containers';
import styles from './styles.module.scss';
import {
  ICommentReplyBarCreateContainerProps,
  ICommentReplyBarProps,
  ICommentReplyBarState,
} from './types';

export class CommentReplyBar extends React.Component<
  ICommentReplyBarProps,
  ICommentReplyBarState
> {
  static defaultProps = {
    placeholder: 'Reply with a comment',
  };

  constructor(props: ICommentReplyBarProps) {
    super(props);
    this.state = {
      commentText: '',
    };
    this.handleCreateComment = this.handleCreateComment.bind(this);
    this.handleCreateAttachment =
      this.handleCreateAttachment.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleCreateComment(evt: React.MouseEvent | React.KeyboardEvent) {
    evt.preventDefault();
    const { create, onSuccess, threadId } = this.props;

    const { commentText } = this.state;
    if (commentText === '') {
      return;
    }

    create(
      { parent_thread_id: threadId, body: commentText },
      { onSuccess },
    );

    this.setState({ commentText: '' }); // Clear Input
  }

  handleCreateAttachment(
    body: Record<string, unknown>,
    options = {},
  ) {
    const { create, onSuccess, threadId } = this.props;
    create(
      { body: '', ...body, parent_thread_id: threadId },
      enhanceOptions({ options, onSuccess }),
    );
  }

  handleInputChange(value: string) {
    this.setState({ commentText: value });
  }

  handleKeyDown(evt: React.KeyboardEvent) {
    if (evt.key === 'Enter' && !evt.shiftKey) {
      this.handleCreateComment(evt);
    }
  }

  render() {
    const { commentText } = this.state;
    const { status, autoFocus } = this.props;

    return (
      <div>
        <div onKeyDown={this.handleKeyDown}>
          <div className={styles.container}>
            <AttachmentMenu
              create={this.handleCreateAttachment}
              status={status}
            />
            <div className={styles.textAreaContainer}>
              <FileActionUpload
                showDragEnter
                multiple
                noClick
                options={{
                  onSuccess: ({ response }) => {
                    this.handleCreateAttachment({
                      body: ' ',
                      file_id: [response.result],
                    });
                  },
                }}
              >
                <Mentions
                  value={commentText}
                  onChange={this.handleInputChange}
                  placeholder="Type @ to mention someone"
                  autoFocus={autoFocus}
                />
              </FileActionUpload>
            </div>
            <div className={styles.sendButtonContainer}>
              <Button
                primary
                disabled={status && status.isFetching}
                onClick={this.handleCreateComment}
                icon="send"
                type="submit"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const CommentReplyBarCreateContainer: React.FC<
  ICommentReplyBarCreateContainerProps
> = (props) => (
  <CanCreate
    parentName="thread"
    parentId={props.threadId}
    entityName="comment"
  >
    <EntityCreateContainer entityName="comment">
      {(containerProps) => (
        <CommentReplyBar {...containerProps} {...props} />
      )}
    </EntityCreateContainer>
  </CanCreate>
);

export default CommentReplyBarCreateContainer;
