/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Create/Bulk
 * @desc Bulk Create Action for order request
 */

import React from 'react';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import ModalDefault from 'labstep-web/core/Modal/Default';
import BulkActionComponent from 'labstep-web/core/Action/Component/Bulk';
import { CanCreate } from 'labstep-web/components/Entity/Can';
import OrderRequestFormCreateBulk from 'labstep-web/components/OrderRequest/Form/Create/Bulk';
import { ICONS } from 'labstep-web/constants';
import { IOrderRequestActionCreateBulkProps } from './types';

const OrderRequestActionCreateBulk: React.FC<
  IOrderRequestActionCreateBulkProps
> = ({ entities, options }) => {
  return (
    <CanCreate entityName="order_request">
      <ModalDefault
        header="Request"
        content={({ toggleModal }) => (
          <OrderRequestFormCreateBulk
            resources={entities}
            options={enhanceOptions({
              options,
              onSuccess: toggleModal,
            })}
          />
        )}
        viewComponent={({ toggleModal }) => (
          <BulkActionComponent
            entitiesWithPermissionCount={entities.length}
            entitiesSelectedCount={entities.length}
            onClick={toggleModal}
            content="Request"
            icon={ICONS.order_request.primary}
          />
        )}
      />
    </CanCreate>
  );
};

export default OrderRequestActionCreateBulk;
