/**
 * Labstep
 *
 * @module components/Resource/Filter/Active/AvailableResourceItemCount
 * @desc Active filter component for available_resource_item_count
 */

import React from 'react';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { ResourceFilterActiveAvailableResourceItemCountProps } from './types';

const ResourceFilterActiveAvailableResourceItemCount: React.FC<
  ResourceFilterActiveAvailableResourceItemCountProps
> = ({ clearParams, searchParams }) => (
  <>
    {('available_resource_item_count_min' in searchParams ||
      searchParams.available_resource_item_count_min === '0') && (
      <RemoveLabel
        name={`≥ ${searchParams.available_resource_item_count_min} available items`}
        onRemoveClick={
          clearParams &&
          (() => clearParams(['available_resource_item_count_min']))
        }
      />
    )}
    {('available_resource_item_count_max' in searchParams ||
      searchParams.available_resource_item_count_max === '0') && (
      <RemoveLabel
        name={`≤ ${searchParams.available_resource_item_count_max} available items`}
        onRemoveClick={
          clearParams &&
          (() => clearParams(['available_resource_item_count_max']))
        }
      />
    )}
  </>
);

export default ResourceFilterActiveAvailableResourceItemCount;
