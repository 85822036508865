/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/ShowEdit/ResourceTemplate
 * @desc ShowEdit for experiment value resource template
 */

import React from 'react';
import FormShowEditSearchSelect from 'labstep-web/core/Form/ShowEdit/SearchSelect';
import ResourceInfoPreview from 'labstep-web/components/Resource/Info/Preview';
import { Resource } from 'labstep-web/models';
import { IProtocolValueShowEditResourceTemplateProps } from './types';

export const ProtocolValueShowEditResourceTemplate: React.FC<
  IProtocolValueShowEditResourceTemplateProps
> = ({ protocolValue, initialEditingState, ...rest }) => (
  <FormShowEditSearchSelect
    placeholder="Restrict to specific category"
    childEntityName={Resource.entityName}
    childKeyName="resource_template"
    parentEntity={protocolValue}
    optimistic={false} // This is because resource_template_id can become null
    searchSelectProps={{
      params: { is_template: true },
      creatable: true,
      createBody: { is_template: true },
      entityNameForText: Resource.templateEntityName,
    }}
    initialEditingState={initialEditingState}
  >
    {protocolValue.resource_template && (
      <ResourceInfoPreview
        resource={protocolValue.resource_template}
        {...rest}
      />
    )}
  </FormShowEditSearchSelect>
);

export default ProtocolValueShowEditResourceTemplate;
