/**
 * Labstep
 *
 * @module state/reducers/helpers
 * @desc Helpers for reducers
 */

import uniq from 'lodash/uniq';
import { nameOfChildKey } from 'labstep-web/services/schema/helpers';
import { Action } from 'labstep-web/models/action.model';
import {
  arrayify,
  isPaginated,
  isSubset,
  jsonParse,
  mergeByIds,
  prependUniqueValuesToArray,
  appendUniqueValuesToArray,
  checkFieldNotInParams,
  shouldAppend,
} from 'labstep-web/state/reducers/helpers/utils';
import { createEntityViewFilter } from 'labstep-web/components/EntityView/utils';

/**
 * Adds the created entity id to the parent's array holding
 * the children of the created entity type
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} action - Redux action
 * @param  {string} entityName
 * @return {object} - Updated state
 */
export const addChildEntityToParent = (
  state: any,
  action: Action,
  entityName: string,
) => {
  if (action.meta && entityName === action.meta.parentName) {
    // Hack so that it works with guid.
    // TODO: Generalize
    if (
      action.type === 'SUCCESS_CREATE_NOTIFICATION_ALERT' &&
      entityName === 'metadata'
    ) {
      const metadataId = action.meta.denormalized_payload.metadata.id;
      return {
        ...state,
        [metadataId]: {
          ...state[metadataId],
          notification_alert: action.payload.result,
        },
      };
    }

    // Sometimes parentId does not exist in state (e.g. when visiting experiment_complete directly)
    if (!state[action.meta.parentId]) {
      return state;
    }

    let stateWithUpdatedChildren = { ...state };
    const childKey = nameOfChildKey(
      entityName,
      action.meta.entityName,
    );

    // Custom childKeyName for OneToOne
    if (
      action.meta.childKeyName &&
      action.meta.parentName === entityName &&
      action.meta.parentId
    ) {
      return {
        ...state,
        [action.meta.parentId]: {
          ...state[action.meta.parentId],
          [action.meta.childKeyName]: action.payload.result,
        },
      };
    }

    // FIXME Custom key 'photo' for profile <> file
    if (
      entityName === 'profile' &&
      action.meta.entityName === 'file' &&
      action.meta.parentId
    ) {
      return {
        ...state,
        [action.meta.parentId]: {
          ...state[action.meta.parentId],
          photo: action.payload.result[0],
        },
      };
    }

    // FIXME Custom key 'image' for resource <> file
    if (
      (entityName === 'resource' ||
        entityName === 'resource_item' ||
        entityName === 'device' ||
        entityName === 'resource_location') &&
      action.meta.entityName === 'file' &&
      action.meta.parentId
    ) {
      return {
        ...state,
        [action.meta.parentId]: {
          ...state[action.meta.parentId],
          image: action.payload.result[0],
        },
      };
    }

    if (
      (!childKey && entityName === 'user_group') ||
      action.meta.entityName === 'organization_saml'
    ) {
      // FIXME JD: this is a hack for OneToOne associations
      const updatedParent = {
        ...state[action.meta.parentId],
        [action.meta.entityName]: action.payload.result,
      };
      stateWithUpdatedChildren = {
        ...state,
        [action.meta.parentId]: updatedParent,
      };
    } else {
      // OneToMany associations
      const childrenArray =
        state[action.meta.parentId][childKey] || [];
      const updatedParentState = {
        ...state[action.meta.parentId],
        [childKey]: uniq([
          ...childrenArray,
          ...arrayify(action.payload.result),
        ]),
      };

      stateWithUpdatedChildren = {
        ...state,
        [action.meta.parentId]: updatedParentState,
      };
    }

    return mergeByIds(
      stateWithUpdatedChildren,
      action.payload &&
        action.payload.entities &&
        action.payload.entities[entityName],
      entityName,
    );
  }

  return state;
};

/**
 * Append entity id to any items array that its key holds the parent id
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @return {object}
 */
export const addAddedEntityToItemsArray = (
  state: any,
  action: Action,
) =>
  Object.keys(state).reduce((result: any, key: string) => {
    const keyState = state[key];
    const params = jsonParse(key);
    const { parentName, parentId, entityIds } = action.meta;

    if (typeof params !== 'object') {
      return { ...result, [key]: keyState };
    } // Single id

    if (
      !(
        params[`${parentName}_id`] &&
        params[`${parentName}_id`] === parentId
      )
    ) {
      return { ...result, [key]: keyState };
    }

    if ('totalPages' in keyState) {
      return {
        ...result,
        [key]: {
          ...keyState,
          pages: Object.keys(keyState.pages).reduce(
            (pagesResult, page) => {
              const pageState = keyState.pages[page];
              if (Number(page) === 1) {
                return {
                  ...pagesResult,
                  [page]: {
                    ...pageState,
                    items: uniq([
                      ...arrayify(entityIds),
                      ...pageState.items,
                    ]),
                  },
                };
              }
              return { ...pagesResult, [page]: pageState };
            },
            {},
          ),
        },
      };
    }

    const keyStateItems = keyState.items || [];
    const items = uniq([...arrayify(entityIds), ...keyStateItems]);
    const total =
      keyState.total + (items.length - keyStateItems.length);

    return {
      ...result,
      [key]: {
        ...keyState,
        items,
        total,
      },
    };
  }, {});

/**
 * Adds newly created entity to array of items of paginated read entities
 */
export const addCreatedEntityToCursorItemsArray = (
  state: any,
  action: Action,
) => {
  return Object.keys(state).reduce((result, key) => {
    const keyState = state[key];
    const params = jsonParse(key);

    const arrayOp = shouldAppend(action.meta.entityName)
      ? appendUniqueValuesToArray
      : prependUniqueValuesToArray;

    // don't add template entities to non-template items
    if (
      (action.meta &&
        action.meta.body &&
        action.meta.body.is_template &&
        !params.is_template) ||
      (action.meta &&
        action.meta.body &&
        !action.meta.body.is_template &&
        params.is_template)
    ) {
      return { ...result, [key]: keyState };
    }

    if (!isPaginated(keyState, params)) {
      return { ...result, [key]: keyState };
    }

    if (action.meta.entityName === 'entity_view' && params.filter) {
      const { entity_name, parameters, context } = action.meta.body;
      const filter = createEntityViewFilter(
        entity_name,
        context,
        parameters,
      );
      if (JSON.stringify(filter) !== JSON.stringify(params.filter)) {
        return { ...result, [key]: keyState };
      }
    }

    if (
      [
        'experiment_id',
        'metadata_thread_id',
        'folder_id',
        'src_id',
        'source_group_guid',
        'is_variable',
        'variable_template_guid',
      ].some((field) => checkFieldNotInParams(action, params, field))
    ) {
      return { ...result, [key]: keyState };
    }

    // One-off. Making this work as add/remove
    if (action.type === 'SUCCESS_CREATE_ENTITY_USER') {
      if ('totalPages' in keyState) {
        return { ...result, [key]: keyState };
      }
      const items = prependUniqueValuesToArray(
        arrayify(action.meta.denormalized_payload.children[0].id),
        keyState.items,
      );
      const total =
        keyState.total + (items.length - keyState.items.length);

      return {
        ...result,
        [key]: {
          ...keyState,
          items,
          total,
        },
      };
    }

    // Prevent adding protocol_value when no resource_item
    if (
      action.meta.entityName === 'protocol_value' &&
      !action.meta.body.resource_item &&
      params.has_resource_item
    ) {
      return { ...result, [key]: keyState };
    }

    if ('totalPages' in keyState) {
      // TODO: Extend for other entities
      if (
        action.meta.normalize === 'resource_items' &&
        !isSubset(params, action.meta.body.items[0])
      ) {
        return { ...result, [key]: keyState };
      }
      return {
        ...result,
        [key]: {
          ...keyState,
          total:
            keyState.total + arrayify(action.payload.result).length,
          pages: Object.keys(keyState.pages).reduce(
            (pagesResult, page) => {
              const pageState = keyState.pages[page];
              if (Number(page) === 1) {
                return {
                  ...pagesResult,
                  [page]: {
                    ...pageState,
                    items: arrayOp(
                      arrayify(
                        // Hack to get batch resource item to display correctly
                        action.meta.normalize === 'resource_items'
                          ? action.payload.result.slice().reverse()
                          : action.payload.result,
                      ),
                      pageState.items,
                    ),
                  },
                };
              }
              return { ...pagesResult, [page]: pageState };
            },
            {},
          ),
        },
      };
    }

    // TODO: Extend for other entities
    if (action.meta.entityName === 'comment') {
      // Other entities as parent
      if ('is_pinned' in params) {
        return { ...result, [key]: keyState };
      }
      if ('experiment_workflow_id' in params) {
        const paramsExperimentWorkflowId = arrayify(
          params.experiment_workflow_id,
        );
        const createdCommentParentThreadId =
          action.meta.body.experiment_workflow_id;
        if (
          paramsExperimentWorkflowId.indexOf(
            createdCommentParentThreadId,
          ) === -1
        ) {
          return { ...result, [key]: keyState };
        }
      } else if ('parent_thread_id' in params) {
        const paramsParentThreadId = arrayify(
          params.parent_thread_id,
        );
        const createdCommentParentThreadId =
          action.meta.body.parent_thread_id;
        if (
          paramsParentThreadId.indexOf(
            createdCommentParentThreadId,
          ) === -1
        ) {
          return { ...result, [key]: keyState };
        }
      }
      // else if (
      //   !Array.isArray(params.thread_id) &&
      //   Number(action.meta.parentId) !== Number(params.thread_id)
      // ) {
      //   return { ...result, [key]: keyState };
      // }
    }
    if (
      action.meta.entityName === 'tag' &&
      params.type !== action.meta.body.type
    ) {
      return { ...result, [key]: keyState };
    }

    // This is needed because post can be used as Put in some cases
    // e.g. EntityUser/Action/Add
    const items = arrayOp(
      arrayify(action.payload.result),
      keyState.items,
    );
    const total =
      keyState.total + (items.length - keyState.items.length);

    return {
      ...result,
      [key]: {
        ...keyState,
        items,
        total,
      },
    };
  }, {});
};

/**
 * Add entity id to array
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {string} action - Redux action
 * @param  {function} filter - Apply filter to state objects
 * @return {object}
 */
export const addEntitiesToItems = (
  state: any,
  action: Action,
  filter?: (keyState, params) => boolean,
) =>
  Object.keys(state).reduce((result: any, key: string) => {
    const keyState = state[key];
    const params = jsonParse(key);

    if (filter && !filter(keyState, params)) {
      return { ...result, [key]: keyState };
    }

    if (!isPaginated(keyState, params)) {
      return { ...result, [key]: keyState };
    }

    // Remove ID from paginated items
    if ('totalPages' in keyState) {
      const newPageState = { ...keyState };
      newPageState.pages = Object.keys(newPageState.pages).reduce(
        (pageResult, pageKey) => {
          const { items } = newPageState.pages[pageKey];
          const filteredItems = prependUniqueValuesToArray(
            arrayify(action.payload.result).slice().reverse(),
            items,
          );
          const total =
            newPageState.pages[pageKey].total +
            (filteredItems.length - items.length);

          return {
            ...pageResult,
            [pageKey]: {
              ...newPageState.pages[pageKey],
              items: filteredItems,
              total,
            },
          };
        },
        {},
      );
      return {
        ...result,
        [key]: {
          ...keyState,
          pages: newPageState.pages,
        },
      };
    }

    const { items } = keyState;
    if (items) {
      const filteredItems = prependUniqueValuesToArray(
        arrayify(action.payload.result).slice().reverse(),
        items,
      );
      const total =
        keyState.total + (filteredItems.length - items.length);
      return {
        ...result,
        [key]: {
          ...keyState,
          items: filteredItems,
          total,
        },
      };
    }
    return { ...result, [key]: keyState };
  }, {});
