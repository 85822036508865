/**
 * Labstep
 *
 * @module containers/AuthenticatedUser
 * @desc Containers that gives access to the logged user
 */

import { connect } from 'react-redux';
import {
  selectAuthenticatedUser,
  selectAuthenticatedUserEntity,
  selectAuthenticatedUserLoadStatus,
  selectLoginStatus,
} from 'labstep-web/state/selectors';
import { readAuthenticatedUser } from 'labstep-web/state/actions';
import {
  IWithAuthenticatedUserEntityInjectedProps,
  IWithAuthenticatedUserEntityMapDispatchToProps,
  IWithAuthenticatedUserEntityMapStateToProps,
  IWithAuthenticatedUserInjectedProps,
} from './types';

const mapStateToProps = (state: any) => ({
  authenticatedUser: selectAuthenticatedUser(state),
  loggingStatus: selectLoginStatus(state),
});

/**
 * A redux connect method to load authenticated user
 */
export const withAuthenticatedUser = <P, S>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<P, Exclude<keyof P, keyof IWithAuthenticatedUserInjectedProps>>
> => connect(mapStateToProps, {})(Component as any);

const mapStateToPropsEntity = (
  state: any,
): IWithAuthenticatedUserEntityMapStateToProps => ({
  authenticatedUserEntity: selectAuthenticatedUserEntity(state),
  authenticatedUserEntityStatus:
    selectAuthenticatedUserLoadStatus(state),
});

const mapDispatchToProps = (
  dispatch: (...args: any) => void,
): IWithAuthenticatedUserEntityMapDispatchToProps => ({
  read(options) {
    dispatch(readAuthenticatedUser(options));
  },
});

export const withAuthenticatedUserEntity = <P, S>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<
    P,
    Exclude<keyof P, keyof IWithAuthenticatedUserEntityInjectedProps>
  >
> =>
  connect(
    mapStateToPropsEntity,
    mapDispatchToProps,
  )(Component as any);
