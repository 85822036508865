/**
 * Labstep
 */

import { Action } from 'labstep-web/models';

export const createOrUpdateResourceItemWithExperimentValueOrigin = (
  action: Action,
): boolean =>
  !!(
    (action.type === 'SUCCESS_CREATE_RESOURCE_ITEM' ||
      action.type === 'SUCCESS_UPDATE_RESOURCE_ITEM') &&
    action.meta &&
    action.meta.body &&
    'protocol_value_origin_guid' in action.meta.body
  );

export const createCustomerWithOrganizationAndStripeCustomerId = (
  action: Action,
): boolean =>
  !!(
    action.type === 'SUCCESS_ORGANIZATION_CREATE_CUSTOMER' &&
    action.meta &&
    action.meta.organization &&
    action.meta.organization.id &&
    action.payload &&
    action.payload.stripe_customer_id
  );

export const createProtocolWithExperimentAndAddToLibrary = (
  action: Action,
): boolean =>
  !!(
    action.type === 'SUCCESS_CREATE_PROTOCOL' &&
    action.meta &&
    action.meta.addToProtocolLibrary &&
    action.meta.body &&
    action.meta.body.experiment_id
  );

export const createResourceItemTemplate = (action: Action): boolean =>
  !!(
    action.type === 'SUCCESS_CREATE_RESOURCE_ITEM' &&
    action.meta &&
    action.meta.body &&
    action.meta.body.is_template
  );

export const createOrderRequestTemplate = (action: Action): boolean =>
  !!(
    action.type === 'SUCCESS_CREATE_ORDER_REQUEST' &&
    action.meta.body?.is_template
  );

export const updateOrderRequest = (action: Action): boolean =>
  action.type === 'SUCCESS_UPDATE_ORDER_REQUEST';

export const updateExperimentWorkflowAndLock = (
  action: Action,
): boolean =>
  action.type === 'SUCCESS_UPDATE_EXPERIMENT_WORKFLOW' &&
  'locked_at' in action.meta.body;

export const updateUserGroupWithHome = (action: Action): boolean =>
  action.type === `SUCCESS_UPDATE_USER_GROUP` &&
  'is_home' in action.meta.body;

export const createTemplatePurchaseOrder = (
  action: Action,
): boolean =>
  action.type === 'SUCCESS_CREATE_PURCHASE_ORDER' &&
  !!action.meta.body.is_template;

export const createFileWithAnnotation = (action: Action): boolean =>
  action.type === 'SUCCESS_CREATE_FILE' &&
  'annotated_file_id' in action.meta;

export const deleteProtocolValue = (action: Action): boolean =>
  !!(
    action.type === 'SUCCESS_UPDATE_PROTOCOL_VALUE' &&
    action.meta.body.deleted_at
  );

export const deleteMetadata = (action: Action): boolean =>
  !!(
    action.type === 'SUCCESS_UPDATE_METADATA' &&
    action.meta.body.deleted_at
  );

export const deleteExperiment = (action: Action): boolean =>
  !!(
    action.type === 'SUCCESS_UPDATE_EXPERIMENT' &&
    action.meta.body.deleted_at
  );

export const recalcStoichiometryTable = (action: Action): boolean =>
  !!(action.type === 'RECALC_STOICHIOMETRY_TABLE');

export const createChemical = (action: Action): boolean =>
  action.type === 'SUCCESS_CREATE_CHEMICAL';

export const isOptionsWithTemplateUpdate = (
  action: Action,
): boolean =>
  action.type === 'SUCCESS_UPDATE_METADATA' &&
  action.meta?.body?.options &&
  (action.meta?.denormalized_payload?.variable_template_guid ||
    action.meta?.denormalized_payload?.template_id);
