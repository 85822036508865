/**
 * Labstep
 *
 * @module components/Metadata/Action/Create/Dropdown
 * @desc dropdown for metadata
 */

import React from 'react';
import { Size } from 'labstep-web/core/MediaQuery/MediaQuery/types';
import MediaQuery from 'labstep-web/core/MediaQuery/MediaQuery';
import Dropdown from 'labstep-web/core/Dropdown';
import DropdownPortal from 'labstep-web/core/Dropdown/Portal';
import {
  METADATA_TYPES,
  METADATA_TYPES_DATA,
} from 'labstep-web/models/metadata/constants';
import { IMetadataActionCreateDropdownProps } from './types';
import { BULK_OPTION } from './constants';

export const MetadataActionCreateDropdown: React.FC<
  IMetadataActionCreateDropdownProps
> = ({
  trigger,
  handleOnClickMetadataOption,
  handleOnClickBulkOption,
  showChemistry,
}) => {
  const metadataOptions = showChemistry
    ? METADATA_TYPES
    : METADATA_TYPES_DATA;
  return (
    <DropdownPortal trigger={trigger} closeOnChange icon={null}>
      {metadataOptions.map((metadataType) => (
        <Dropdown.Item
          key={metadataType.value}
          onClick={() => handleOnClickMetadataOption(metadataType)}
          data-testid={metadataType.label}
        >
          {metadataType.label}
        </Dropdown.Item>
      ))}
      {handleOnClickBulkOption && (
        <MediaQuery size={Size.tablet_desktop}>
          <Dropdown.Divider key={metadataOptions.length} />
          <Dropdown.Item
            key={metadataOptions.length + 1}
            onClick={handleOnClickBulkOption}
            data-testid={BULK_OPTION.label}
          >
            {BULK_OPTION.label}
          </Dropdown.Item>
        </MediaQuery>
      )}
    </DropdownPortal>
  );
};

export default MetadataActionCreateDropdown;
