/**
 * Labstep
 *
 * @module components/Metadata/Settings/Label
 * @desc Label update
 */

import React from 'react';
import ShowEdit from 'labstep-web/core/Form/ShowEdit/Text';
import { Metadata } from 'labstep-web/models';
import rules from 'labstep-web/services/validation/rules';
import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { IMetadataFormShowEditLabelProps } from './types';

/* Field for metadata label */
export const FIELD_METADATA_LABEL: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'label',
  placeholder: 'Add field name',
  fieldLabel: 'Field Name',
  validation: rules.metadata.label,
};

const MetadataFormShowEditLabel: React.FC<
  IMetadataFormShowEditLabelProps
> = ({ metadata }) => (
  <ShowEdit
    entityName={Metadata.entityName}
    entity={metadata}
    field={FIELD_METADATA_LABEL}
  >
    {metadata.label}
  </ShowEdit>
);

export default MetadataFormShowEditLabel;
