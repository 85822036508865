/**
 * Labstep
 */

import { PostFilterComparison } from 'labstep-web/hoc/Params/types';
import { PostFilterMetadatas } from 'labstep-web/services/postFilter/metadatas';

export const PostFilterMetadatasDefault = new PostFilterMetadatas(
  [
    ['label', PostFilterComparison.eq],
    ['value', PostFilterComparison.eq],
  ],
  'metadatas',
);

export const PostFilterMetadatasNumeric = new PostFilterMetadatas(
  [
    ['label', PostFilterComparison.eq],
    ['number', PostFilterComparison.eq],
  ],
  'metadatas',
);

export const PostFilterMetadatasDate = new PostFilterMetadatas(
  [
    ['label', PostFilterComparison.eq],
    ['date', PostFilterComparison.eq],
  ],
  'metadatas',
);

export const PostFilterMetadatasOptions = new PostFilterMetadatas(
  [
    ['label', PostFilterComparison.eq],
    ['options', PostFilterComparison.contains],
  ],
  'metadatas',
);
