/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Template
 * @desc Creates an ExperimentWorkflow from a template one
 */

import React from 'react';
import EntityActionDuplicate from 'labstep-web/components/Entity/Action/Duplicate';
import { ICONS } from 'labstep-web/constants';
import { ExperimentWorkflow } from 'labstep-web/models';
import { IExperimentWorkflowTemplateActionProps } from './types';

export const ExperimentWorkflowTemplateAction: React.FC<
  IExperimentWorkflowTemplateActionProps
> = ({ experimentWorkflow }) => (
  <EntityActionDuplicate
    entity={experimentWorkflow}
    body={{
      is_template: !experimentWorkflow.is_template,
    }}
    actionComponentProps={{
      type: 'option',
      icon: experimentWorkflow.is_template
        ? ICONS.experiment_workflow.primary
        : ICONS.experiment_workflow.template,
      text: experimentWorkflow.is_template
        ? `Create ${ExperimentWorkflow.getHumanReadableEntityName(
            false,
            true,
          )}`
        : `Save as Template`,
    }}
  />
);

export default ExperimentWorkflowTemplateAction;
