/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/Show
 * @desc ExperimentWorkflow Show Screen
 */

import React from 'react';
import DocumentHead from 'labstep-web/core/DocumentHead';
import MasterShow from 'labstep-web/screens/Master/Show';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { ProtocolConditionModalProvider } from 'labstep-web/components/ProtocolCondition/Modal/context';
import ProtocolConditionModal from 'labstep-web/components/ProtocolCondition/Modal/Container';
import { ExperimentWorkflow } from 'labstep-web/models';
import Center from './Center';
import Right from './Right';
import {
  IExperimentWorkflowShowScreenContainerProps,
  IExperimentWorkflowShowScreenProps,
} from './types';

export const ExperimentWorkflowShowScreen: React.FC<
  IExperimentWorkflowShowScreenProps
> = ({ experimentWorkflow, activeExperimentWorkflowRoute }) => (
  <>
    <DocumentHead title={experimentWorkflow.displayName} />

    <ProtocolConditionModalProvider
      entityName={ExperimentWorkflow.entityName}
    >
      <ProtocolConditionModal
        protocol={experimentWorkflow.root_experiment}
      />
      <MasterShow
        entity={experimentWorkflow}
        center={<Center experimentWorkflow={experimentWorkflow} />}
        right={
          <Right
            experimentWorkflow={experimentWorkflow}
            activeExperimentWorkflowRoute={
              activeExperimentWorkflowRoute
            }
          />
        }
      />
    </ProtocolConditionModalProvider>
  </>
);

const ExperimentWorkflowShowScreenContainer: React.FC<
  IExperimentWorkflowShowScreenContainerProps
> = ({ id, activeExperimentWorkflowRoute }) => (
  <ReadOnMountHOC
    type="entities"
    entityName="experiment_workflow"
    params={{
      get_single: 1,
      is_deleted: 'both',
      id,
    }}
    loading={{ loader: 'show', cached: true }}
    children={({ entity }) => (
      <ExperimentWorkflowShowScreen
        experimentWorkflow={entity}
        activeExperimentWorkflowRoute={activeExperimentWorkflowRoute}
      />
    )}
  />
);

export default ExperimentWorkflowShowScreenContainer;
