/**
 * Labstep
 *
 * @module components/ResourceItem/Details
 * @desc Info about order request
 */

import React from 'react';
import ResourceItemResourceLocationModal from 'labstep-web/components/ResourceItem/ResourceLocationModal';
import ResourceItemFormShowEditAmount from 'labstep-web/components/ResourceItem/Form/ShowEdit/Amount';
import ResourceItemFormStatus from 'labstep-web/components/ResourceItem/Form/Status';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import ResourceItemFormShowEditAlertThreshold from 'labstep-web/components/ResourceItem/Form/ShowEdit/AlertThreshold';
import TextWithCount from 'labstep-web/core/Text/WithCount';
import Flex from 'labstep-web/core/Flex';
import EntityLink from 'labstep-web/components/Entity/Link';
import { ICONS } from 'labstep-web/constants';
import { ResourceItem } from 'labstep-web/models';
import { IResourceItemDetailsProps } from './types';

export const getResourceItemDetails = (entity: ResourceItem) => {
  let items = [
    {
      header: (
        <TextWithCount
          header
          text="Availability"
          icon="info circle"
        />
      ),
      content: (resourceItem: ResourceItem) => (
        <ResourceItemFormStatus resourceItem={resourceItem} />
      ),
    },
    {
      header: (
        <TextWithCount
          header
          text="Location"
          icon={ICONS.resource_location.primary}
        />
      ),
      content: (resourceItem: ResourceItem) => (
        <ResourceItemResourceLocationModal
          resourceItem={resourceItem}
        />
      ),
    },
    {
      header: (
        <TextWithCount
          header
          text="Amount Remaining"
          icon="thermometer quarter"
        />
      ),
      content: (resourceItem: ResourceItem) => (
        <Flex>
          <ResourceItemFormShowEditAmount
            resourceItem={resourceItem}
          />
          {resourceItem.amount && (
            <ResourceItemFormShowEditAlertThreshold
              resourceItem={resourceItem}
            />
          )}
        </Flex>
      ),
    },
  ];

  // Experiment inside protocol_value_origin might not be ready until the full resource item loads
  // https://github.com/Labstep/labstep/issues/7218
  if (
    entity.protocol_value_origin &&
    entity.protocol_value_origin.experiment
  ) {
    items = [
      {
        header: <TextWithCount header text="Origin" icon="sitemap" />,
        content: (resourceItem: ResourceItem) => (
          <EntityLink
            entity={resourceItem.protocol_value_origin.experiment}
            showIcon
            secondary
          />
        ),
      },
      ...items,
    ];
  }

  if (entity.order_request) {
    items = [
      ...items,
      {
        header: (
          <TextWithCount
            header
            text="Order Request"
            icon={ICONS.order_request.primary}
          />
        ),
        content: (resourceItem: ResourceItem) => (
          <EntityLink
            entity={resourceItem.order_request}
            secondary
            displayName={`#${resourceItem.order_request.id}`}
          />
        ),
      },
    ];
  }

  return items;
};

export const ResourceItemDetails: React.FC<
  IResourceItemDetailsProps
> = ({ resourceItem: entity }) => (
  <HorizontalTable
    padded
    headerProps={{ width: 4 }}
    rows={getResourceItemDetails(entity)}
    columns={[entity]}
  />
);

export default ResourceItemDetails;
