/**
 * Labstep
 *
 * @module components/Entity/Card/EmptyState
 * @desc Generic Empty State for entities
 */

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { objectOrFunction } from 'labstep-web/services/react.service';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { PostFilterType } from 'labstep-web/hoc/Params/types';
import { IEntityEmptyStateProps } from './types';

export const EntityEmptyState: React.FC<IEntityEmptyStateProps> = ({
  entityName,
  noResultsMessage,
  noResultsMessageOnSearch,
  searchParams,
  initialSearchParams,
  action,
}) => {
  let src;
  let title;
  let explanation;

  /**
   * Filtering out the following keys which do not matter for
   * showing the empty state
   */
  const filteredSearchParams = omit(searchParams, [
    'folder_id',
    'folder_group_not_id',
    'template_id',
  ]);

  // If there are no user-defined filters, remove the filter
  if (
    !(filteredSearchParams.filter as PostFilterType)?.[0].predicates
      .length
  ) {
    delete filteredSearchParams.filter;
  }

  if (searchParams.is_deleted === '1') {
    src = '/img/empty-state/files.svg';
    title = 'Nothing in your deleted items';
    explanation = `You have no deleted ${getHumanReadableEntityName(
      entityName,
      true,
    )}`;
  } else if (
    !isEmpty(filteredSearchParams) &&
    !isEqual(filteredSearchParams, initialSearchParams)
  ) {
    if (noResultsMessageOnSearch) {
      return noResultsMessageOnSearch({ searchParams });
    }
    src = '/img/empty-state/warning.svg';
    title = 'No results found';
    explanation = `We can't find any ${getHumanReadableEntityName(
      entityName,
      true,
    )} matching your criteria`;
  } else if (noResultsMessage) {
    return objectOrFunction(noResultsMessage, {
      searchParams,
    });
  } else {
    src = '/img/empty-state/warning.svg';
    title = `No ${getHumanReadableEntityName(
      entityName,
      true,
    )} created yet`;
  }

  return (
    <EmptyState
      src={src}
      title={title}
      explanation={explanation}
      action={action}
    />
  );
};

export default EntityEmptyState;
