/**
 * Labstep
 *
 * @module state/constants/helpers
 * @desc Helper functions for constants
 */

import { Action } from 'labstep-web/models';

/**
 * List of generic action statuses
 */
const availableStatuses = ['request', 'success', 'fail'];

/**
 * List of generic action types
 */
const availableActionTypes = [
  'create',
  'read',
  'update',
  'delete',
  'read_cursor',
  'read_page',
];

/**
 * Get action type constants
 * @param  {string} status
 * @param  {string} actionType
 * @param  {string} entityName
 * @return {string} - Action type
 */
export const getType = (
  status: string,
  actionType: string,
  entityName: string,
) => {
  if (availableStatuses.indexOf(status) === -1) {
    const error = {
      error: 'Invalid status',
    };
    throw error;
  }
  if (availableActionTypes.indexOf(actionType) === -1) {
    const error = {
      error: 'Invalid action type',
    };
    throw error;
  }

  return `${status}_${actionType}_${entityName}`.toUpperCase();
};

/**
 * Checks if action is of type read
 * @param  {string} action
 * @return {bool} - Whether action is of type read
 */
export const isReadTypeAction = (action: Action) =>
  action &&
  action.type &&
  action.type.includes('READ_') &&
  !action.type.includes('_RAW_OUTPUT');

/**
 * Checks if action is of type request
 * @param  {string} action
 * @return {bool} - Whether action is of type request
 */
export const isRequestTypeAction = (action: Action) =>
  action &&
  action.type &&
  action.type.startsWith('REQUEST_') &&
  !action.type.includes('_RAW_OUTPUT');

/**
 * Checks if action is of type success
 * @param  {string} action
 * @return {bool} - Whether action is of type success
 */
export const isSuccessTypeAction = (action: Action) =>
  action &&
  action.type &&
  action.type.startsWith('SUCCESS_') &&
  !action.type.includes('_RAW_OUTPUT');

/**
 * Checks if action is of type fail
 * @param  {string} action
 * @return {bool} - Whether action is of type fail
 */
export const isFailTypeAction = (action: Action) =>
  action &&
  action.type &&
  action.type.startsWith('FAIL_') &&
  !action.type.includes('_RAW_OUTPUT');

/**
 * Checks if action is of type request
 * @param  {string} action
 * @return {bool} - Whether action is of type request
 */
export const isRequestCreateTypeAction = (action: Action) =>
  action &&
  action.type &&
  action.type.startsWith('REQUEST_CREATE_') &&
  !action.type.includes('_RAW_OUTPUT');

/**
 * Checks if action is of type success
 * @param  {string} action
 * @return {bool} - Whether action is of type success
 */
export const isSuccessCreateTypeAction = (action: Action) =>
  action &&
  action.type &&
  action.type.startsWith('SUCCESS_CREATE_') &&
  !action.type.includes('_RAW_OUTPUT');
