/**
 * Labstep
 *
 * @module core/DataGrid/CellEditor/Date
 * @desc CellEditor Date
 */

import FormShowEditDate from 'labstep-web/core/Form/ShowEdit/Date';
import { Entity } from 'labstep-web/models';
import React from 'react';
import { ICellEditorDateProps } from './types';

export const DataGridCellEditorDate: React.FC<
  ICellEditorDateProps<Entity>
> = React.memo((params) => (
  <FormShowEditDate
    dateTimePickerOnly
    onClose={(): void => params.api.stopEditing()}
    {...params.props}
  />
));

export default DataGridCellEditorDate;
