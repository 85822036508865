/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Sign
 * @desc Action to sign experimentWorkflow
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import SignatureFormCreate from 'labstep-web/components/Signature/Form/Create';
import UserActionEnableMfa from 'labstep-web/components/User/Action/EnableMfa';
import { CanCreate } from 'labstep-web/components/Entity/Can';
import { Signature } from 'labstep-web/models';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import { ISignatureActionCreateProps } from './types';

export const SignatureActionCreate: React.FC<
  ISignatureActionCreateProps
> = ({ experimentWorkflow, authenticatedUserEntity }) => (
  <CanCreate
    parentId={experimentWorkflow.id}
    parentName={experimentWorkflow.entityName}
    entityName="experiment_workflow_signature"
  >
    <ModalDefault
      header={`Electronic ${Signature.getHumanReadableEntityName(
        false,
        true,
      )}`}
      content={({ toggleModal }) =>
        experimentWorkflow.owner.is_signature_two_factor_required &&
        !authenticatedUserEntity.is_two_factor_enabled ? (
          <div>
            <p>
              You need to enable two-factor authentication before you
              can sign{' '}
            </p>
            <UserActionEnableMfa />
          </div>
        ) : (
          <SignatureFormCreate
            experimentWorkflow={experimentWorkflow}
            options={{ onSuccess: toggleModal }}
          />
        )
      }
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="button"
          // status={status}
          text={`Add ${Signature.getHumanReadableEntityName(
            false,
            true,
          )}`}
          onClick={toggleModal}
        />
      )}
    />
  </CanCreate>
);

export default withAuthenticatedUserEntity(SignatureActionCreate);
