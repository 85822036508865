/**
 * Labstep
 *
 * @module core/Spreadsheet/Toolbar
 * @desc Toolbar for Spreadsheet tables
 */

import React, { useState } from 'react';
import { CompactPicker } from 'react-color';
import cx from 'classnames';
import Dropdown from 'labstep-web/core/Dropdown';
import { VerticalDivider } from 'labstep-web/core/Divider';
import Popup from 'labstep-web/core/Popup';
import {
  COLUMN_LIMIT,
  ROW_LIMIT,
} from 'labstep-web/services/table.service';
import {
  executeCommand,
  importXLSX,
  redo,
  saveXLSX,
  undo,
} from 'labstep-web/core/Spreadsheet/commands';
import { getSelection } from 'labstep-web/core/Spreadsheet/helpers';
import Icon from 'labstep-web/core/Icon';
import styles from './styles.module.scss';
import { IToolbarItemProps, IToolbarProps } from './types';

const areRowsSelected = (selections) =>
  selections.length === 1 && selections[0].col === -1;
const areColumnsSelected = (selections) =>
  selections.length === 1 && selections[0].row === -1;
const isWithinRowLimit = (rowCount) => rowCount < ROW_LIMIT;
const isWithinColumnLimit = (columnCount) =>
  columnCount < COLUMN_LIMIT;

export const ToolbarItem: React.FC<IToolbarItemProps> = ({
  icon,
  disabled,
  onClick,
  hoverText,
}) => (
  <Popup
    size="small"
    trigger={
      <div
        className={cx(styles.toolbarItem, {
          [styles.disabled]: disabled,
        })}
        onMouseDown={(evt) => {
          evt.preventDefault();
        }}
        onClick={(evt) => {
          evt.preventDefault();
          if (onClick) {
            onClick();
          }
        }}
      >
        <img
          src={`/img/table-icons/icons8-${icon}-50.png`}
          alt={icon}
        />
      </div>
    }
    content={hoverText}
  />
);

export const Toolbar: React.FC<IToolbarProps> = ({
  spread,
  allowLocking,
}) => {
  const [selections, setSelections] = useState([]);
  if (!spread) {
    return null;
  }
  const rowCount = spread.getActiveSheet().getRowCount();
  const columnCount = spread.getActiveSheet().getColumnCount();
  return (
    <div className={styles.toolbar}>
      <ToolbarItem
        hoverText="Undo"
        icon="undo"
        onClick={() => undo(spread)}
        disabled={!spread.undoManager().canUndo()}
      />
      <ToolbarItem
        hoverText="Redo"
        icon="redo"
        onClick={() => redo(spread)}
        disabled={!spread.undoManager().canRedo()}
      />
      <ToolbarItem
        hoverText="Import from XLSX"
        icon="xls-import"
        onClick={() => {
          const input = document.createElement('input');
          input.type = 'file';
          input.accept = '.xlsx';
          input.onchange = (e: any) => {
            const file = e.target.files[0];
            importXLSX(spread, file);
          };
          input.click();
        }}
      />
      <ToolbarItem
        hoverText="Save as XLSX"
        icon="xls-export"
        onClick={() => {
          saveXLSX(
            spread,
            (blob) => {
              const a = document.createElement('a');
              document.body.appendChild(a);
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = 'LabstepTable.xlsx';
              a.click();
              window.URL.revokeObjectURL(url);
            },
            // eslint-disable-next-line no-console
            (err) => console.log(err),
          );
        }}
      />
      <VerticalDivider />
      <Dropdown.Dropdown
        disabled={!selections}
        trigger={
          <ToolbarItem
            hoverText="Insert Rows / Columns"
            icon="insert-row"
          />
        }
        onClick={() => {
          setSelections(getSelection(spread));
        }}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            text="Row above"
            disabled={!isWithinRowLimit(rowCount)}
            onClick={(evt) => {
              evt.preventDefault();
              executeCommand(spread, 'insert', {
                axis: 'row',
                direction: 'above',
              });
            }}
          />
          <Dropdown.Item
            text="Row below"
            disabled={!isWithinRowLimit(rowCount)}
            onMouseDown={(evt) => {
              evt.preventDefault();
              executeCommand(spread, 'insert', {
                axis: 'row',
                direction: 'below',
              });
            }}
          />

          <Dropdown.Item
            text="Column left"
            disabled={!isWithinColumnLimit(columnCount)}
            onMouseDown={(evt) => {
              evt.preventDefault();
              executeCommand(spread, 'insert', {
                axis: 'col',
                direction: 'left',
              });
            }}
          />
          <Dropdown.Item
            text="Column right"
            disabled={!isWithinColumnLimit(columnCount)}
            onMouseDown={(evt) => {
              evt.preventDefault();
              executeCommand(spread, 'insert', {
                axis: 'col',
                direction: 'right',
              });
            }}
          />
        </Dropdown.Menu>
      </Dropdown.Dropdown>
      <Dropdown.Dropdown
        disabled={!selections}
        trigger={
          <ToolbarItem
            hoverText="Delete Rows / Columns"
            icon="delete-row"
          />
        }
        onClick={() => {
          setSelections(getSelection(spread));
        }}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            text="Delete column(s)"
            disabled={
              !selections ||
              areRowsSelected(selections) ||
              columnCount === 1
            }
            onMouseDown={(evt) => {
              evt.preventDefault();
              executeCommand(spread, 'remove', { axis: 'cols' });
            }}
          />

          <Dropdown.Item
            text="Delete row(s)"
            disabled={
              !selections ||
              areColumnsSelected(selections) ||
              rowCount === 1
            }
            onMouseDown={(evt) => {
              evt.preventDefault();
              executeCommand(spread, 'remove', { axis: 'rows' });
            }}
          />
        </Dropdown.Menu>
      </Dropdown.Dropdown>
      <VerticalDivider />
      <ToolbarItem
        hoverText="Bold"
        icon="bold"
        onClick={() => executeCommand(spread, 'toggleBold')}
      />
      <ToolbarItem
        hoverText="Italic"
        icon="italic"
        onClick={() => executeCommand(spread, 'toggleItalic')}
      />
      <ToolbarItem
        hoverText="Underline"
        icon="underline"
        onClick={() => executeCommand(spread, 'toggleUnderline')}
      />
      <ToolbarItem
        hoverText="Strikethrough"
        icon="strikethrough"
        onClick={() => executeCommand(spread, 'toggleStrike')}
      />
      <VerticalDivider />
      <ToolbarItem
        hoverText="Increase Decimal"
        icon="increase-decimal"
        onClick={() => executeCommand(spread, 'increaseDecimal')}
      />
      <ToolbarItem
        hoverText="Decrease Decimal"
        icon="decrease-decimal"
        onClick={() => executeCommand(spread, 'decreaseDecimal')}
      />
      <Dropdown
        className={styles.formatDropdown}
        icon={null}
        trigger={
          <Icon popup={{ content: 'Format' }} name="percent" />
        }
      >
        <Dropdown.Item
          onClick={() => executeCommand(spread, 'formatAuto')}
        >
          Automatic
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => executeCommand(spread, 'formatPercentage')}
        >
          Percentage
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => executeCommand(spread, 'formatScientific')}
        >
          Scientific
        </Dropdown.Item>
      </Dropdown>
      <VerticalDivider />
      <Dropdown.Dropdown
        trigger={
          <ToolbarItem hoverText="Cell Color" icon="fill-color" />
        }
      >
        <Dropdown.Menu>
          <CompactPicker
            onChangeComplete={(color) => {
              executeCommand(spread, 'setBackColor', {
                color: color.hex,
              });
            }}
          />
        </Dropdown.Menu>
      </Dropdown.Dropdown>
      <Dropdown.Dropdown
        trigger={
          <ToolbarItem hoverText="Text Color" icon="text-color" />
        }
      >
        <Dropdown.Menu>
          <CompactPicker
            onChangeComplete={(color) => {
              executeCommand(spread, 'setForeColor', {
                color: color.hex,
              });
            }}
          />
        </Dropdown.Menu>
      </Dropdown.Dropdown>
      <VerticalDivider />
      <ToolbarItem
        hoverText="Align Left"
        icon="align-left"
        onClick={() =>
          executeCommand(spread, 'hAlign', { mode: 'left' })
        }
      />
      <ToolbarItem
        hoverText="Align Center"
        icon="align-center"
        onClick={() =>
          executeCommand(spread, 'hAlign', { mode: 'center' })
        }
      />
      <ToolbarItem
        hoverText="Align Right"
        icon="align-right"
        onClick={() =>
          executeCommand(spread, 'hAlign', { mode: 'right' })
        }
      />
      <VerticalDivider />
      <ToolbarItem
        hoverText="Align Top"
        icon="align-text-top"
        onClick={() =>
          executeCommand(spread, 'vAlign', { mode: 'top' })
        }
      />
      <ToolbarItem
        hoverText="Align Middle"
        icon="align-text-center"
        onClick={() =>
          executeCommand(spread, 'vAlign', { mode: 'center' })
        }
      />
      <ToolbarItem
        hoverText="Align Bottom"
        icon="align-text-bottom"
        onClick={() =>
          executeCommand(spread, 'vAlign', { mode: 'bottom' })
        }
      />
      <VerticalDivider />
      <Dropdown.Dropdown
        trigger={
          <ToolbarItem
            hoverText="Outer Border"
            icon="border-outside"
          />
        }
      >
        <Dropdown.Menu>
          <CompactPicker
            onChangeComplete={(color) => {
              executeCommand(spread, 'setBorder', {
                mode: 'outline',
                color: color.hex,
              });
            }}
          />
        </Dropdown.Menu>
      </Dropdown.Dropdown>
      <Dropdown.Dropdown
        trigger={
          <ToolbarItem
            hoverText="Inside Border"
            icon="border-inside"
          />
        }
      >
        <Dropdown.Menu>
          <CompactPicker
            onChangeComplete={(color) => {
              executeCommand(spread, 'setBorder', {
                mode: 'inside',
                color: color.hex,
              });
            }}
          />
        </Dropdown.Menu>
      </Dropdown.Dropdown>
      <Dropdown.Dropdown
        trigger={
          <ToolbarItem hoverText="All Borders" icon="border-all" />
        }
      >
        <Dropdown.Menu>
          <CompactPicker
            onChangeComplete={(color) => {
              executeCommand(spread, 'setBorder', {
                mode: 'all',
                color: color.hex,
              });
            }}
          />
        </Dropdown.Menu>
      </Dropdown.Dropdown>
      <Dropdown.Dropdown
        trigger={
          <ToolbarItem hoverText="Left Border" icon="border-left" />
        }
      >
        <Dropdown.Menu>
          <CompactPicker
            onChangeComplete={(color) => {
              executeCommand(spread, 'setBorder', {
                mode: 'left',
                color: color.hex,
              });
            }}
          />
        </Dropdown.Menu>
      </Dropdown.Dropdown>
      <Dropdown.Dropdown
        trigger={
          <ToolbarItem hoverText="Right Border" icon="border-right" />
        }
      >
        <Dropdown.Menu>
          <CompactPicker
            onChangeComplete={(color) => {
              executeCommand(spread, 'setBorder', {
                mode: 'right',
                color: color.hex,
              });
            }}
          />
        </Dropdown.Menu>
      </Dropdown.Dropdown>
      <Dropdown.Dropdown
        trigger={
          <ToolbarItem hoverText="Top Border" icon="border-top" />
        }
      >
        <Dropdown.Menu>
          <CompactPicker
            onChangeComplete={(color) => {
              executeCommand(spread, 'setBorder', {
                mode: 'top',
                color: color.hex,
              });
            }}
          />
        </Dropdown.Menu>
      </Dropdown.Dropdown>
      <Dropdown.Dropdown
        trigger={
          <ToolbarItem
            hoverText="Bottom Border"
            icon="border-bottom"
          />
        }
      >
        <Dropdown.Menu>
          <CompactPicker
            onChangeComplete={(color) => {
              executeCommand(spread, 'setBorder', {
                mode: 'bottom',
                color: color.hex,
              });
            }}
          />
        </Dropdown.Menu>
      </Dropdown.Dropdown>
      <VerticalDivider />
      <ToolbarItem
        hoverText="Merge Cells"
        icon="merge-cells"
        onClick={() => executeCommand(spread, 'mergeCells')}
      />
      <ToolbarItem
        hoverText="Unmerge Cells"
        icon="split-cells"
        onClick={() => executeCommand(spread, 'unMergeCells')}
      />
      <VerticalDivider />
      <ToolbarItem
        hoverText="Word Wrap"
        icon="word-wrap"
        onClick={() => executeCommand(spread, 'wordWrap')}
      />
      <VerticalDivider />
      <ToolbarItem
        hoverText="Clear All Formatting"
        icon="broom"
        onClick={() => executeCommand(spread, 'clearStyles')}
      />
      {allowLocking && (
        <>
          <VerticalDivider />
          <ToolbarItem
            hoverText="Lock Cells"
            icon="lock"
            onClick={() => executeCommand(spread, 'lockCells')}
          />
          <ToolbarItem
            hoverText="Unlock Cells"
            icon="unlock"
            onClick={() => executeCommand(spread, 'unlockCells')}
          />
        </>
      )}
    </div>
  );
};

export default Toolbar;
