/**
 * Labstep
 *
 * @module components/EntityUser/Action/Add
 */

import React from 'react';
import SearchSelect from 'labstep-web/core/Select/Search';
import {
  EntityCreateContainer,
  withActiveGroup,
} from 'labstep-web/containers';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { EntityUser, User } from 'labstep-web/models';
import { IEntityUserActionAddProps } from './types';

export const EntityUserActionAdd: React.FC<
  IEntityUserActionAddProps
> = ({ entity, activeGroup }) => (
  <Can
    entityName={entity.entityName}
    id={entity.idAttr}
    action={Action.assign}
  >
    <EntityCreateContainer entityName={EntityUser.entityName}>
      {({ create }) => (
        <SearchSelect
          value={null}
          placeholder="Search for users to assign"
          entityName={User.entityName}
          params={{
            group_id: activeGroup?.id,
          }}
          onChange={(selectedOption) =>
            create({
              [`${entity.entityName}_id`]: entity.id,
              user_id: selectedOption.id,
              is_assigned: true,
            })
          }
        />
      )}
    </EntityCreateContainer>
  </Can>
);

export default withActiveGroup(EntityUserActionAdd);
