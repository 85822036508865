/**
 * Labstep
 *
 * @module components/Metadata/Thread/Detail
 * @desc Metadata Thread Detail
 */

import React from 'react';
import ThreadModal from 'labstep-web/components/Thread/Modal';
import { Comment } from 'labstep-web/models';
import MetadataCardPreview from 'labstep-web/components/Metadata/Card/Preview';
import { IMetadataThreadDetailProps } from './types';

const MetadataThreadDetail: React.FC<IMetadataThreadDetailProps> = ({
  metadata,
}) => (
  <ThreadModal
    threadId={metadata.thread.id}
    top={<MetadataCardPreview metadata={metadata} />}
    dividerChildren={`${metadata.thread.comment_count} note(s)`}
    noCommentsMessage={`No ${Comment.getHumanReadableEntityName(
      true,
      true,
    )}`}
  />
);

export default MetadataThreadDetail;
