import { GC } from 'labstep-web/core/SpreadJS/imports';

export const placeHolderStyle = new GC.Spread.Sheets.Style();
placeHolderStyle.foreColor = '#cccccc';
placeHolderStyle.hAlign = 1;

// eslint-disable-next-line @typescript-eslint/no-empty-function
function PlaceHolderCells() {}

PlaceHolderCells.prototype = new GC.Spread.Sheets.CellTypes.Text();
PlaceHolderCells.prototype.paint = function placeHolderPaint(
  ctx,
  value,
  x,
  y,
  w,
  h,
  style,
  options,
) {
  if (value == null) {
    GC.Spread.Sheets.CellTypes.Base.prototype.paint.apply(this, [
      ctx,
      '< Insert Field Name >',
      x,
      y,
      w,
      h,
      placeHolderStyle,
      options,
    ]);
  } else {
    GC.Spread.Sheets.CellTypes.Base.prototype.paint.apply(this, [
      ctx,
      value,
      x,
      y,
      w,
      h,
      style,
      options,
    ]);
  }
};

export default {
  PlaceHolderCell: new PlaceHolderCells(),
};
