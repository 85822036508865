/**
 * Labstep
 *
 * @module components/ProtocolCondition/Action/ViewVariablesTable
 * @desc ViewVariablesTable Action for ProtocolCondition
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import { ProtocolCondition } from 'labstep-web/models';
import React from 'react';
import { useProtocolConditionModalContext } from '../../Modal/context';
import { IProtocolConditionActionViewVariablesTableProps } from './types';

export const ProtocolConditionActionViewVariablesTable: React.FC<
  IProtocolConditionActionViewVariablesTableProps
> = ({ actionComponentProps, protocol }) => {
  const { setIsOpen } = useProtocolConditionModalContext();

  return (
    <ActionComponent
      dataTestId="action-view-variables-table"
      type="button"
      icon="external"
      text={`View All ${ProtocolCondition.getHumanReadableEntityName(
        true,
        true,
      )}`}
      onClick={(): void => setIsOpen(protocol)}
      {...actionComponentProps}
    />
  );
};

export default ProtocolConditionActionViewVariablesTable;
