/**
 * Labstep
 *
 * @module components/List/Tree
 * @desc List with hierarchical tree structure
 */

import React from 'react';
import { ParamsHOC, ReadOnMountHOC } from 'labstep-web/hoc';
import EntitySearchCursor from 'labstep-web/components/Entity/Search/Cursor';
import { withActiveGroup } from 'labstep-web/containers';
import { ParamsContext } from 'labstep-web/hoc/Params/context';
import TreeItem from './Item';
import { ITreeContainerProps, ITreeProps } from './types';
import {
  getSetActiveTreeEntity,
  isActiveTreeEntity,
  sortByName,
} from './utils';

export const Tree: React.FC<ITreeProps> = (props) => {
  const {
    entities,
    level = 0,
    unsorted,
    entityName,
    childKeyName,
    activeGroup,
    createSubAction,
    icon,
    iconInactive,
    actionMenu,
    levelMargin = 10,
    maxLevels = 4,
    flatten,
    mapping,
    postFilter,
    useGuid,
  } = props;
  const mappedEntities = mapping ? mapping(entities) : entities;
  return (
    <ParamsHOC historyAction="replace">
      <ParamsContext.Consumer>
        {({
          setParams,
          searchParams,
          addPostFilter,
        }): React.ReactNode => {
          const setActiveTreeEntity = getSetActiveTreeEntity(
            setParams,
            addPostFilter,
            postFilter,
          );
          return (
            <>
              {unsorted && level === 0 && !flatten && (
                <TreeItem
                  icon={unsorted.icon}
                  text={unsorted.text}
                  active={!!searchParams[unsorted.param]}
                  onClick={() => {
                    setParams({
                      [useGuid
                        ? `${entityName}_guid`
                        : `${entityName}_id`]: undefined,
                      [unsorted.param]: activeGroup?.id,
                    });
                  }}
                />
              )}
              {mappedEntities.map((entity) => {
                const isActive = isActiveTreeEntity(
                  searchParams,
                  entity,
                  postFilter,
                );
                const marginLeft =
                  level < maxLevels && level !== 0 && !flatten
                    ? levelMargin
                    : 0;
                return (
                  <div key={entity.id}>
                    <div
                      style={{
                        marginLeft: `${marginLeft}px`,
                      }}
                    >
                      <TreeItem
                        icon={
                          !isActive && iconInactive
                            ? iconInactive
                            : icon
                        }
                        text={entity.name}
                        active={isActive}
                        onClick={(): void =>
                          setActiveTreeEntity(
                            entityName,
                            entity,
                            unsorted,
                          )
                        }
                        actionMenu={actionMenu?.(entity, {
                          onSuccess: () => {
                            if (isActive) {
                              setActiveTreeEntity(
                                entityName,
                                undefined,
                                unsorted,
                              );
                            }
                          },
                        })}
                      >
                        {!flatten && (
                          <ReadOnMountHOC
                            type="cursor"
                            entityName={entityName}
                            params={{
                              group_id: activeGroup?.id,
                              search: 1,
                              [useGuid
                                ? `${childKeyName}_guid`
                                : `${childKeyName}_id`]: useGuid
                                ? entity.guid
                                : entity.id,
                              sort: 'name',
                            }}
                            pagination={{ infinite: false }}
                            children={({ entities: childEntities }) =>
                              childEntities.length > 0 ? (
                                <Tree
                                  {...props}
                                  entities={childEntities}
                                  level={level + 1}
                                />
                              ) : (
                                <div
                                  style={{
                                    marginLeft: `${
                                      marginLeft + levelMargin
                                    }px`,
                                    marginBottom: `${levelMargin}px`,
                                  }}
                                >
                                  {createSubAction &&
                                    createSubAction(entity)}
                                </div>
                              )
                            }
                          />
                        )}
                      </TreeItem>
                    </div>
                  </div>
                );
              })}
            </>
          );
        }}
      </ParamsContext.Consumer>
    </ParamsHOC>
  );
};

Tree.defaultProps = {
  level: 0,
  levelMargin: 10,
  maxLevels: 4,
};

export const TreeContainer: React.FC<ITreeContainerProps> = ({
  activeGroup,
  entityName,
  childKeyName,
  createSubAction,
  icon,
  iconInactive,
  actionMenu,
  unsorted,
  params,
  action,
  searchBarProps,
  postFilter,
  useGuid,
}) => {
  if (!activeGroup) {
    return null;
  }

  return (
    <EntitySearchCursor
      entityName={entityName}
      params={(searchParams) => {
        const commonParams = {
          search: 1,
          group_id: activeGroup?.id,
          ...params,
        };
        if (searchParams.search_query?.length > 0) {
          return commonParams;
        }
        return {
          ...commonParams,
          sort: 'name',
          [`has_${childKeyName}`]: false,
        };
      }}
      emptyStateAction={action}
      searchBarProps={searchBarProps}
      showEmptyStateOnSearchOnly
    >
      {({ entities, searchParams }) => {
        const searchActive = !!searchParams.search_query;
        return (
          <>
            <Tree
              useGuid={useGuid}
              activeGroup={activeGroup}
              entities={entities}
              entityName={entityName}
              childKeyName={childKeyName}
              createSubAction={createSubAction}
              icon={icon}
              iconInactive={iconInactive}
              actionMenu={actionMenu}
              unsorted={unsorted}
              flatten={searchActive}
              mapping={!searchActive ? sortByName : undefined}
              postFilter={postFilter}
            />
            {!searchActive && action}
          </>
        );
      }}
    </EntitySearchCursor>
  );
};

export default withActiveGroup(TreeContainer);
