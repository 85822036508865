/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Table
 * @desc Table listing all experimentWorkflows
 */

import React from 'react';
import EntityTable from 'labstep-web/components/Entity/Table';
import ExperimentWorkflowActionMenu from 'labstep-web/components/ExperimentWorkflow/Action/Menu';
import ExperimentWorkflowShortcuts from 'labstep-web/components/ExperimentWorkflow/Shortcuts';
import ExperimentWorkflowStatus from 'labstep-web/components/ExperimentWorkflow/Status';
import EntityLink from 'labstep-web/components/Entity/Link';
import { DefaultSort } from 'labstep-web/components/Entity/Table/SortOptions/types';
import { DateSmall } from 'labstep-web/core/DatePreview';
import { ExperimentWorkflow } from 'labstep-web/models';
import { IExperimentWorkflowTableProps } from './types';

export const ExperimentWorkflowTable: React.FC<
  IExperimentWorkflowTableProps
> = ({
  experimentWorkflows,
  actions,
  isTemplate,
  primaryColumnHeader,
  ...rest
}) => {
  const primaryColumn = {
    header: primaryColumnHeader || '',
    content: (experimentWorkflow: ExperimentWorkflow) => (
      <div>
        <DateSmall dateString={experimentWorkflow.created_at} />{' '}
        {!isTemplate && (
          <ExperimentWorkflowStatus
            experimentWorkflow={experimentWorkflow}
          />
        )}
        <div>
          <EntityLink editableName entity={experimentWorkflow} />
        </div>
        {!isTemplate && (
          <ExperimentWorkflowShortcuts
            experimentWorkflow={experimentWorkflow}
          />
        )}
      </div>
    ),
    cellProps: {
      style: { flex: 3, paddingTop: '0.5em', paddingBottom: '0.5em' },
    },
  };

  return (
    <EntityTable
      {...rest}
      id="experiment-workflow-table"
      entities={experimentWorkflows}
      primaryColumn={primaryColumn}
      editableName
      sortOptions={
        isTemplate
          ? {
              name: {
                label: 'Name',
                default_sort: DefaultSort.asc,
              },
            }
          : {
              name: {
                label: 'Name',
                default_sort: DefaultSort.asc,
              },
              started_at: {
                label: 'Date Started',
                default_sort: DefaultSort.desc,
              },
              ended_at: {
                label: 'Date Completed',
                default_sort: DefaultSort.desc,
              },
            }
      }
      actionMenu={({ entity }) => (
        <ExperimentWorkflowActionMenu
          experimentWorkflow={entity}
          actions={actions}
        />
      )}
    />
  );
};

export default ExperimentWorkflowTable;
