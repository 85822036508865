/**
 * Labstep
 *
 * @module core/SubHeader
 * @desc To be used for Tabs / Breadcrumbs
 */

import React from 'react';
import cx from 'classnames';
import { ISubHeaderProps } from './types';
import styles from './styles.module.scss';

const SubHeader: React.FC<ISubHeaderProps> = ({
  children,
  className,
  style,
}) => {
  const classes = cx(styles.subHeader, className);

  return (
    <span style={style} className={classes}>
      {children}
    </span>
  );
};

export default SubHeader;
