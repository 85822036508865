/**
 * Labstep
 *
 * @module components/Organization/GroupManager
 */

import React from 'react';
import { withRouter } from 'react-router';
import EntitySearch from 'labstep-web/components/Entity/Search';
import GroupActionCreate from 'labstep-web/components/Group/Action/Create';
import GroupList from 'labstep-web/components/Group/List';
import { GroupEmptyState } from 'labstep-web/components/Group/Card/EmptyState';
import { Group } from 'labstep-web/models';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { withUiPersistent } from 'labstep-web/containers';
import { DefaultSort } from 'labstep-web/components/Entity/Table/SortOptions/types';
import { IOrganizationGroupManagerManagerProps } from './types';

export const OrganizationGroupManager: React.FC<
  IOrganizationGroupManagerManagerProps
> = ({ organization, uiPersistent, location }) => (
  <EntitySearch
    entityName={Group.entityName}
    params={{ organization_id: organization.id }}
    filters={[Filter.deleted]}
    action={() => <GroupActionCreate />}
    noResultsMessage={<GroupEmptyState />}
    sortOptions={{
      name: {
        label: 'Name',
        default_sort: DefaultSort.asc,
      },
    }}
    historyAction="replace"
    initialSearchParams={
      uiPersistent.search_params[location.pathname]
    }
  >
    {({ entities }) => <GroupList groups={entities} />}
  </EntitySearch>
);

export default withRouter(withUiPersistent(OrganizationGroupManager));
