/**
 * Labstep
 *
 * @module hoc/Search
 * @desc Enhances children with Params and ReadOnMount props.
 * Reads On mount + on every searchParams change
 */

import React from 'react';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { filterParams, ParamsHOC } from 'labstep-web/hoc/Params';
import { ParamsContext } from 'labstep-web/hoc/Params/context';
import {
  EntityReadCursorContainer,
  EntityReadPageContainer,
} from 'labstep-web/containers';
import { objectOrFunction } from 'labstep-web/services/react.service';
import { ISearchContainerProps, ISearchHocParams } from './types';

export class SearchContainer extends React.Component<ISearchContainerProps> {
  componentDidMount() {
    const { read, options } = this.props;

    read(options);
  }

  componentDidUpdate(prevProps: ISearchContainerProps) {
    const { read, options } = this.props;

    // Read on params change
    if (!isEqual(prevProps.params, this.props.params)) {
      read(options);
    }
  }

  render() {
    const { children, ...rest } = this.props;

    return children(rest);
  }
}

export const SearchHOC: React.FC<ISearchHocParams> = ({
  historyAction,
  initialParams,
  isCursor,
  noSearchParam,
  usePostFilter,
  ...props
}) => (
  <ParamsHOC
    historyAction={historyAction}
    initialParams={initialParams}
  >
    <ParamsContext.Consumer>
      {(paramsProps) => {
        const { children, entityName } = props;

        const {
          searchParams: { page, ...restSearchParams },
        } = paramsProps;
        const params =
          objectOrFunction(props.params, restSearchParams) || {};
        let finalParams: any = {
          search: 1,
          ...params,
          ...restSearchParams,
        };

        if (noSearchParam) {
          delete finalParams.search;
        }

        if (usePostFilter) {
          // only use params supported with filter
          finalParams = pick(finalParams, filterParams);
        }

        if (isCursor) {
          return (
            <EntityReadCursorContainer
              entityName={entityName}
              params={finalParams}
            >
              {(readProps) => (
                <SearchContainer
                  {...readProps}
                  {...paramsProps}
                  params={{
                    search: 1,
                    ...params,
                    ...paramsProps.searchParams,
                  }}
                  children={children}
                />
              )}
            </EntityReadCursorContainer>
          );
        }

        return (
          <EntityReadPageContainer
            entityName={entityName}
            params={finalParams}
            page={page || 1}
            usePostFilter={usePostFilter}
          >
            {(readProps) => (
              <SearchContainer
                {...readProps}
                {...paramsProps}
                page={page}
                params={{
                  search: 1,
                  ...params,
                  ...paramsProps.searchParams,
                }}
                children={children}
              />
            )}
          </EntityReadPageContainer>
        );
      }}
    </ParamsContext.Consumer>
  </ParamsHOC>
);
