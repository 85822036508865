/**
 * Labstep
 *
 * @module components/PurchaseOrder/Filter/Pane
 * @desc Filter pane for purchaseOrder request
 */

import React from 'react';
import FilterPane from 'labstep-web/components/Filter/Pane';
import PurchaseOrderFilterStatusList from 'labstep-web/components/PurchaseOrder/Filter/Status/List';
import { ICONS } from 'labstep-web/constants';
import { STRINGS } from 'labstep-web/strings';

const PurchaseOrderFilterPane: React.FC = () => (
  <FilterPane
    entityName="purchase_order"
    filters={[]}
    extraFilters={<PurchaseOrderFilterStatusList />}
    icon={ICONS.purchase_order.primary}
    text={STRINGS.purchase_order.plural.capitalized}
  />
);

export default PurchaseOrderFilterPane;
