/**
 * Labstep
 *
 * @module components/User/List
 * @desc List of users
 */

import React from 'react';
import ListCap from 'labstep-web/core/List/Cap';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { User } from 'labstep-web/models';
import { Size } from 'labstep-web/core/Initials/types';
import { IUserListContainerProps, IUserListProps } from './types';

export const UserList: React.FC<IUserListProps> = ({
  users,
  total,
}) => (
  <ListCap
    circular
    total={total}
    items={users.map((user: User) => (
      <ProfilePicture
        popup
        content={user.username}
        key={user.id}
        entity={user}
        size={Size.mini}
      />
    ))}
  />
);

export const UserListContainer: React.FC<IUserListContainerProps> = ({
  params,
}) => (
  <ReadOnMountHOC
    type="cursor"
    loading={false}
    entityName={User.entityName}
    params={{
      ...params,
      count: 4,
      search: 1,
    }}
    children={({ entities, total }) => (
      <UserList users={entities} total={total} />
    )}
  />
);

export default UserListContainer;
