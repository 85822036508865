/**
 * Labstep
 *
 * @module components/ProtocolCollection/FilterPane
 * @desc Filter pane for protocol collection
 */

import React from 'react';
import FilterPane from 'labstep-web/components/Filter/Pane';
import { ICONS } from 'labstep-web/constants';
import { STRINGS } from 'labstep-web/strings';
import { ProtocolCollection } from 'labstep-web/models';

const ProtocolCollectionFilterPane: React.FC = () => (
  <FilterPane
    entityName={ProtocolCollection.entityName}
    icon={ICONS.protocol_collection.primary}
    text={STRINGS.protocol_collection.plural.capitalized}
  />
);

export default ProtocolCollectionFilterPane;
