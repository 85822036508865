/**
 * Labstep
 *
 * @module core/ProtocolCondition/Modal
 * @desc Protocol Condition Modal
 */

import { ICONS } from 'labstep-web/constants';
import Modal from 'labstep-web/core/Modal/Default';
import ModalHeader from 'labstep-web/core/Modal/Default/Header';
import { ProtocolCondition } from 'labstep-web/models';
import React from 'react';
import ProtocolConditionModalContent from './Content';
import { useProtocolConditionModalContext } from './context';
import styles from './styles.module.scss';
import { IProtocolConditionModalProps } from './types';

export const ProtocolConditionModal: React.FC<
  IProtocolConditionModalProps
> = ({ protocol }) => {
  const { close } = useProtocolConditionModalContext();

  return (
    <Modal
      className={styles.modal}
      header={
        <ModalHeader
          icon={ICONS.protocol_condition.info.variable}
          text={`${ProtocolCondition.getHumanReadableEntityName(
            true,
            true,
          )} Table`}
          onClose={close}
        />
      }
      size="fullscreen"
      closeIcon={false}
      open
      onClose={close}
      content={<ProtocolConditionModalContent protocol={protocol} />}
      viewComponent={null}
    />
  );
};

export default ProtocolConditionModal;
