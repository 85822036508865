/**
 * Labstep.
 *
 * @module epics/jwt
 * @desc Redux epic for JWT
 */

import { Observable, concat, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Action } from 'labstep-web/models';
import { configService } from 'labstep-web/services/config.service';

/**
 * If we cannot refresh a token, logout the user.
 * If the backend returns HTTP 5xx, do not logout the user.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onFailRefreshTokenActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'FAIL_REFRESH_TOKEN' &&
        action.error &&
        action.error.status &&
        action.error.status < 500,
    ),
    map(() => {
      return {
        type: configService.debug
          ? 'LOGOUT_DISABLED_IN_DEBUG'
          : 'LOGOUT_SUCCESS',
        meta: {
          reason: 'FAIL_REFRESH_TOKEN',
        },
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_JWT_FAIL_REFRESH_TOKEN',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Detect Invalid JWT and log the user out.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onFailInvalidJwtActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type.startsWith('FAIL') &&
        action.error?.data?.message === 'Invalid JWT Token',
    ),
    map(() => {
      return {
        type: configService.debug
          ? 'LOGOUT_DISABLED_IN_DEBUG'
          : 'LOGOUT_SUCCESS',
        meta: {
          reason: 'INVALID_JWT',
        },
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_SF_API_FAIL_EXPIRED_JWT',
          payload: err,
        }),
        source$,
      ),
    ),
  );
