/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Duplicate
 * @desc Button to duplicate experiment workflow
 */

import React from 'react';
import EntityActionDuplicate from 'labstep-web/components/Entity/Action/Duplicate';
import { ExperimentWorkflow } from 'labstep-web/models';
import { IExperimentWorkflowDuplicateActionProps } from './types';

export const ExperimentWorkflowDuplicateAction: React.FC<
  IExperimentWorkflowDuplicateActionProps
> = ({ experimentWorkflow }) => (
  <EntityActionDuplicate
    entity={experimentWorkflow}
    body={{
      is_template: experimentWorkflow.is_template,
    }}
    actionComponentProps={{
      type: 'option',
      text: experimentWorkflow.is_template
        ? `Duplicate Template`
        : `Repeat ${ExperimentWorkflow.getHumanReadableEntityName(
            false,
            true,
          )}`,
    }}
  />
);

export default ExperimentWorkflowDuplicateAction;
