/**
 * Labstep
 *
 * @module Signature/Form/Create
 * @desc Signature Create Form
 */

import React from 'react';
import { ExperimentWorkflow, Signature } from 'labstep-web/models';
import FormCreate from 'labstep-web/core/Form/Create';
import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import { fieldSignatureStatement } from 'labstep-web/components/Signature/Form/fields';
import { fieldUserCode } from 'labstep-web/components/User/Form/fields';
import { ICONS } from 'labstep-web/constants';
import { withActiveGroup } from 'labstep-web/containers';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IFieldProps } from 'labstep-web/core/Form/Reusable/types';
import { ISignatureFormCreateProps } from './types';

export const SignatureFormCreate: React.FC<
  ISignatureFormCreateProps
> = ({ experimentWorkflow, options, activeGroup }) => {
  let fields: IFieldProps[] = [fieldSignatureStatement];
  if (experimentWorkflow.owner.is_signature_two_factor_required) {
    fields = [...fields, fieldUserCode];
  }
  return (
    <FormCreate
      autoFocus
      fields={fields}
      entityName={Signature.entityName}
      parentName={ExperimentWorkflow.entityName}
      defaultValues={{
        is_lock: false,
        [fieldSignatureStatement.name]:
          activeGroup.default_signature_statement ||
          `I hereby confirm that I have read and approve the ${ExperimentWorkflow.getHumanReadableEntityName()}.`,
      }}
      parentId={experimentWorkflow.id}
      options={options}
      submitButton={({ submitForm, status, setValue }) => (
        <div>
          <Can
            entityName={experimentWorkflow.entityName}
            id={experimentWorkflow.id}
            action={Action.lock}
          >
            {!experimentWorkflow.locked_at && (
              <Button
                primary
                basic
                type="button"
                status={status}
                onClick={() => {
                  setValue('is_lock', true);
                  submitForm();
                }}
              >
                <Icon name={ICONS.signature.actions.lock} />
                Sign and Lock
              </Button>
            )}
          </Can>
          <Button
            status={status}
            type="button"
            primary
            onClick={submitForm}
          >
            Sign
          </Button>
        </div>
      )}
    />
  );
};

export default withActiveGroup(SignatureFormCreate);
