/**
 * Labstep
 *
 * @module components/UserGroup/Table
 * @desc Table listing user groups
 */

import React from 'react';
import TableSimple from 'labstep-web/core/Table/Simple';
import UserGroupActionMenu from 'labstep-web/components/UserGroup/Action/Menu';
import UserGroupActionUpdate from 'labstep-web/components/UserGroup/Action/Update';
import { Action } from 'labstep-web/components/UserGroup/Action/Menu/types';
import GroupInitials from 'labstep-web/components/Group/Initials';
import { UserGroup } from 'labstep-web/models';
import { Size } from 'labstep-web/core/Initials/types';
import { IUserGroupTableProps } from './types';

export const UserGroupTable: React.FC<IUserGroupTableProps> = ({
  userGroups,
}) => {
  const columns = [
    {
      header: '',
      content: (userGroup: UserGroup) => (
        <GroupInitials size={Size.tiny} group={userGroup.group} />
      ),
      cellProps: { style: { width: 60 } },
    },
    {
      header: 'Name',
      content: (userGroup: UserGroup) => (
        <b>{userGroup.group.name}</b>
      ),
      cellProps: { flex: 5 },
    },
    {
      header: 'Permissions',
      content: (userGroup: UserGroup) => (
        <UserGroupActionUpdate userGroup={userGroup} />
      ),
      cellProps: { flex: 2 },
    },
    {
      header: '',
      content: (userGroup: UserGroup) => (
        <UserGroupActionMenu
          userGroup={userGroup}
          actions={[Action.delete_group]}
        />
      ),
      cellProps: { textAlign: 'right', style: { width: 50 } },
    },
  ];
  return (
    <TableSimple hideHeader rows={userGroups} columns={columns} />
  );
};

export default UserGroupTable;
