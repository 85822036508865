/**
 * Labstep
 *
 * @module components/OrderRequest/Card/Overview
 * @desc Overview Card for order requests
 */

import React from 'react';
import EntityCardOverview from 'labstep-web/components/Entity/Card/Overview';
import { OrderRequestEmptyState } from 'labstep-web/components/OrderRequest/Card/EmptyState';
import OrderRequestActionMenu from 'labstep-web/components/OrderRequest/Action/Menu';
import OrderRequestActionCreate from 'labstep-web/components/OrderRequest/Action/Create';
import { actions } from 'labstep-web/components/OrderRequest/ListOrTable/constants';
import { OrderRequest } from 'labstep-web/models';
import { ICONS } from 'labstep-web/constants';
import { IOrderRequestCardOverviewProps } from './types';

export const OrderRequestCardOverview: React.FC<
  IOrderRequestCardOverviewProps
> = ({ params }) => (
  <EntityCardOverview
    emptyState={<OrderRequestEmptyState title={null} />}
    entityName={OrderRequest.entityName}
    icon={ICONS.order_request.primary}
    actionMenu={({ entity }) => (
      <OrderRequestActionMenu
        orderRequest={entity}
        actions={actions}
      />
    )}
    action={<OrderRequestActionCreate />}
    params={{ ...params, is_template: false, status: 'new' }}
  />
);

export default OrderRequestCardOverview;
