/**
 * Labstep
 *
 * @module components/UserOrganization/Action/Menu
 */

import React from 'react';
import ActionMenu from 'labstep-web/core/Action/Menu';
import UserOrganizationActionDelete from 'labstep-web/components/UserOrganization/Action/Delete';
import UserOrganizationActionManagePermissions from 'labstep-web/components/UserOrganization/Action/ManagePermissions';
import UserActionToggleSaml from 'labstep-web/components/User/Action/ToggleSaml';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import { IUserOrganizationActionMenuProps } from './types';

export const UserOrganizationActionMenu: React.FC<
  IUserOrganizationActionMenuProps
> = ({ userOrganization, authenticatedUserEntity }) => {
  return (
    <ActionMenu type="icon">
      <UserOrganizationActionDelete
        userOrganization={userOrganization}
      />
      <UserOrganizationActionManagePermissions
        userOrganization={userOrganization}
      />
      {authenticatedUserEntity.isOrganizationAdmin && (
        <UserActionToggleSaml
          user={userOrganization.user}
          organization={userOrganization.organization}
        />
      )}
    </ActionMenu>
  );
};

export default withAuthenticatedUserEntity(
  UserOrganizationActionMenu,
);
