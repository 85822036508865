/**
 * Labstep
 *
 */

import { isEqual } from 'lodash';
import {
  Experiment,
  ExperimentWorkflow,
  Metadata,
  Protocol,
  ProtocolValue,
} from 'labstep-web/models';
import { handleSetItems } from 'labstep-web/prosemirror/extensions/referencing/commands';
import { getState as getReferencingPluginState } from 'labstep-web/prosemirror/extensions/referencing/commands/selectors';
import { PluginState } from 'labstep-web/prosemirror/extensions/referencing/plugin/types';
import { EditorState } from 'prosemirror-state';
import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';

export const setItems = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
  experimentWorkflow: ExperimentWorkflow,
  entity: Experiment | Protocol,
  selectedCategory: PluginState['selectedCategory'],
) => {
  let itemsToBeSelected;
  if (selectedCategory.value === 'experiments') {
    itemsToBeSelected = experimentWorkflow.experiments;
  } else if (selectedCategory.value.includes('step')) {
    itemsToBeSelected = state.doc.content
      .toJSON()
      .filter((b) => b.type.endsWith('step'))
      .map((b) =>
        entity[selectedCategory.value].find(
          (step) => step.guid === b.attrs.guid,
        ),
      );
  } else if (
    selectedCategory.value.includes('value') ||
    selectedCategory.value.includes('metadata')
  ) {
    // TODO: need to refactor referencing menu to load entities async
    itemsToBeSelected = entity[selectedCategory.value].filter(
      (e: ProtocolValue | Metadata) => !e.variable_template_guid,
    );
  } else {
    itemsToBeSelected = entity[selectedCategory.value];
  }
  handleSetItems(state, dispatch, itemsToBeSelected);
};

export const getReferencingPluginStateChange = (
  state: EditorState,
  nextState: EditorState,
) => {
  const { isOpen, selectedCategory, token } =
    getReferencingPluginState(state);

  const {
    isOpen: nextIsOpen,
    selectedCategory: nextSelectedCategory,
    token: nextToken,
  } = getReferencingPluginState(nextState);

  return [
    !isEqual(nextIsOpen, isOpen),
    !isEqual(selectedCategory, nextSelectedCategory),
    !isEqual(token, nextToken),
  ];
};
