/**
 * Labstep
 *
 * @module components/PurchaseOrder/SearchSelect
 * @desc
 */

import React from 'react';
import SearchSelect from 'labstep-web/core/Select/Search';
import { PurchaseOrder } from 'labstep-web/models';
import { withActiveGroup } from 'labstep-web/containers';
import { IPurchaseOrderSearchSelectProps } from './types';

export const PurchaseOrderSearchSelect: React.FC<
  IPurchaseOrderSearchSelectProps
> = ({ onChange, activeGroup, currency }) => (
  <SearchSelect
    entityName={PurchaseOrder.entityName}
    // Setting status to open because only then can we
    // add order requests
    params={{ group_id: activeGroup?.id, status: 'open' }}
    createBody={{ currency }}
    onChange={onChange}
    autoFocus
    creatable
    allowCreateWithEmptyInput
  />
);

export default withActiveGroup(PurchaseOrderSearchSelect);
