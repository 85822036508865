/**
 * Labstep
 *
 * @module core/HorizontalTable
 * @desc Table by row
 */

import React from 'react';
import classnames from 'classnames';
import Hoverable from 'labstep-web/core/Hoverable';
import Table from 'labstep-web/core/Table';
import {
  IHorizontalTableBodyProps,
  IHorizontalTableProps,
} from './types';
import styles from './styles.module.scss';

export const HorizontalTableBody: React.FC<
  IHorizontalTableBodyProps
> = ({ rows, columns, headerProps }) => (
  <Table.Body className={styles.body}>
    {rows.map((row, rowIndex) => (
      // eslint-disable-next-line react/no-array-index-key
      <Table.Row key={rowIndex} data-testid={row.dataTestId}>
        <Table.Cell {...headerProps}>{row.header}</Table.Cell>
        {columns.map((entity, columnIndex) => {
          const cellComponent = // eslint-disable-next-line react/no-array-index-key
            (
              // eslint-disable-next-line react/no-array-index-key
              <Table.Cell key={columnIndex}>
                {row.content(entity) || ''}
              </Table.Cell>
            );
          return row.action ? (
            <Hoverable
              hoverable={cellComponent}
              component={row.action(entity)}
            />
          ) : (
            cellComponent
          );
        })}
      </Table.Row>
    ))}
  </Table.Body>
);

export const HorizontalTable: React.FC<IHorizontalTableProps> = ({
  rows,
  columns,
  headerProps,
  borderless,
  ...rest
}) => {
  return (
    <Table
      className={classnames(styles.horizontalTable, {
        [styles.borderless]: borderless,
      })}
      {...rest}
    >
      <HorizontalTableBody
        rows={rows}
        columns={columns}
        headerProps={headerProps}
      />
    </Table>
  );
};

export default HorizontalTable;
