/**
 * Labstep
 *
 * @module components/EntityUser/Action/Manage
 */

import React from 'react';
import ModalDefault from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import EntityUserManager from 'labstep-web/components/EntityUser/Manager';
import { STRINGS } from 'labstep-web/strings';
import { ICONS } from 'labstep-web/constants';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IEntityUserActionManageProps } from './types';

const EntityUserActionManage: React.FC<
  IEntityUserActionManageProps
> = ({ entity, ...rest }) => (
  <ModalDefault
    viewComponent={({ toggleModal }) => (
      <Can
        entityName={entity.entityName}
        id={entity.idAttr}
        action={Action.assign}
      >
        <ActionComponent
          type="option"
          text={STRINGS.entity.actions.assign}
          icon={ICONS.entity.actions.assign}
          onClick={toggleModal}
        />
      </Can>
    )}
    {...rest}
    showDone
    header="Manage Collaborators"
    content={<EntityUserManager entity={entity} />}
  />
);

export default EntityUserActionManage;
