/**
 * Labstep
 *
 * @module components/Tag/FilterPane
 * @desc Filter pane for protocol collection
 */

import React from 'react';
import FilterPane from 'labstep-web/components/Filter/Pane';
import TagFilterTypeList from 'labstep-web/components/Tag/Filter/Type/List';
import { ICONS } from 'labstep-web/constants';
import { STRINGS } from 'labstep-web/strings';
import { Filter } from 'labstep-web/components/Filter/Menu/types';

const TagFilterPane: React.FC = () => (
  <FilterPane
    extraFilters={<TagFilterTypeList />}
    entityName="tag"
    filters={[Filter.created_by]}
    icon={ICONS.tag.primary}
    text={STRINGS.tag.plural.capitalized}
  />
);

export default TagFilterPane;
