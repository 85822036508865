import { Resource, ResourceItem } from 'labstep-web/models';
import { getMetadataColDefs } from 'labstep-web/components/Entity/DataGrid/colDefs/metadatas';

/**
 * For all metadatas on resource template, return a column
 * @param resourceTemplate Resource template
 * @returns Column definitions
 */
export const getResourceItemResourceMetadataColDefs = (
  resourceTemplate: Resource,
) =>
  getMetadataColDefs<ResourceItem>(
    Resource.entityName,
    resourceTemplate,
    (params) => params.data.resource,
    {
      entityName: ResourceItem.entityName,
      childEntityName: Resource.entityName,
    },
  );
