/**
 * Labstep
 *
 * @module components/Chemical/Form/ShowEdit/Inventory
 * @desc ShowEditInventory for Chemical
 */

import ModalDefault from 'labstep-web/core/Modal/Default';
import ChemicalActionLinkToInventory from 'labstep-web/components/Chemical/Action/LinkToInventory';
import ProtocolValuePreviewInline from 'labstep-web/components/ProtocolValue/Preview/Inline';
import { EntityUpdateContainer } from 'labstep-web/containers';
import { ProtocolValue } from 'labstep-web/models';
import React, { useState } from 'react';
import ChemicalFormShowEditInventoryMultipleResources from './MultipleResources';
import ChemicalFormShowEditInventoryNoResources from './NoResources';
import {
  IChemicalFormShowEditInventoryProps,
  IChemicalFormShowEditInventoryContainerProps,
} from './types';

export const typeNoResources = 'no_resources';
export const typeMultipleResources = 'multiple_resources';

export const ChemicalFormShowEditInventory: React.FC<
  IChemicalFormShowEditInventoryProps
> = ({
  chemical,
  status,
  update,
  showActionLinkToInventory,
  isTemplate,
}) => {
  const [modalType, setModalType] = useState(null);
  const [resources, setResources] = useState([]);

  const reset = () => {
    setModalType(null);
    setResources(null);
  };

  let header = null;
  let content = null;
  switch (modalType) {
    case typeNoResources:
      header = 'No resources found';
      content = (
        <ChemicalFormShowEditInventoryNoResources
          chemical={chemical}
          update={update}
          options={{ onSuccess: reset }}
        />
      );
      break;
    case typeMultipleResources:
      header = 'Multiple resources found';
      content = (
        <ChemicalFormShowEditInventoryMultipleResources
          status={status}
          update={update}
          options={{ onSuccess: reset }}
          resources={resources}
        />
      );
      break;
    default:
      break;
  }

  return chemical.protocol_value.resource ? (
    <ProtocolValuePreviewInline
      protocolValue={chemical.protocol_value}
      withResourceItem={!isTemplate}
    />
  ) : (
    <>
      <ModalDefault
        open={!!modalType}
        viewComponent={null}
        onClose={reset}
        header={header}
        content={content}
      />
      {showActionLinkToInventory ? (
        <ChemicalActionLinkToInventory
          chemical={chemical}
          disabled={status && status.isFetching}
          onFetchEntities={(entities) => {
            if (entities.length === 1) {
              update({ resource_id: entities[0].id });
              reset();
            } else {
              setResources(entities);
              setModalType(
                entities.length > 1
                  ? typeMultipleResources
                  : typeNoResources,
              );
            }
          }}
        />
      ) : (
        'No inventory'
      )}
    </>
  );
};

export const ChemicalFormShowEditInventoryContainer: React.FC<
  IChemicalFormShowEditInventoryContainerProps
> = ({ chemical, ...rest }) => (
  <EntityUpdateContainer
    entityName={ProtocolValue.entityName}
    id={chemical.protocol_value.guid}
  >
    {({ update, status }) => (
      <ChemicalFormShowEditInventory
        chemical={chemical}
        update={update}
        status={status}
        {...rest}
      />
    )}
  </EntityUpdateContainer>
);

export default ChemicalFormShowEditInventoryContainer;
