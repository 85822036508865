import { PremiumFeature } from 'labstep-web/models';
import { selectAuthenticatedUserEntity } from 'labstep-web/state/selectors';
import { useSelector } from 'react-redux';

export const useHasFeatureFlagEnabled = (
  feature: PremiumFeature,
): boolean => {
  const hasFeatureEnabled = useSelector((state) => {
    const authenticatedUserEntity =
      selectAuthenticatedUserEntity(state);
    if (!authenticatedUserEntity) {
      return false;
    }
    return authenticatedUserEntity.hasFeatureEnabled(feature);
  });
  return hasFeatureEnabled;
};
