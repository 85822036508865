/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/Index/Template
 * @desc ExperimentWorkflow template index
 */

import React, { useMemo } from 'react';
import { ExperimentWorkflowTemplateEmptyState } from 'labstep-web/components/ExperimentWorkflow/Card/EmptyState';
import { ExperimentWorkflow } from 'labstep-web/models';
import ExperimentWorkflowListOrTable from 'labstep-web/components/ExperimentWorkflow/ListOrTable';
import MasterIndex from 'labstep-web/screens/Master/Index';
import ExperimentWorkflowActionCreateTemplate from 'labstep-web/components/ExperimentWorkflow/Action/CreateTemplate';
import { bulkActions, filtersTemplate } from '../constants';
import { IExperimentWorkflowIndexTemplateProps } from './types';

export const ExperimentWorkflowIndexTemplate: React.FC<
  IExperimentWorkflowIndexTemplateProps
> = ({ group }) => {
  const params = useMemo(
    () => ({ group_id: group.id, is_template: true }),
    [group.id],
  );
  const noResultsMessage = useMemo(
    () => <ExperimentWorkflowTemplateEmptyState />,
    [],
  );
  const action = useMemo(
    () => <ExperimentWorkflowActionCreateTemplate />,
    [],
  );
  const searchBarProps = useMemo(
    () => ({ placeholder: 'Search templates...' }),
    [],
  );
  return (
    <MasterIndex
      params={params}
      noResultsMessage={noResultsMessage}
      filters={filtersTemplate}
      action={action}
      entityName={ExperimentWorkflow.entityName}
      bulkActions={bulkActions}
      searchBarProps={searchBarProps}
    >
      {({ entities, ...rest }): React.ReactElement => (
        <ExperimentWorkflowListOrTable
          experimentWorkflows={entities}
          isTemplate
          {...rest}
        />
      )}
    </MasterIndex>
  );
};

export default ExperimentWorkflowIndexTemplate;
