/**
 * Labstep
 *
 * @module ProtocolTimer/Action/Create
 * @desc ProtocolTimer create action
 */

import React from 'react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { ProtocolTimer } from 'labstep-web/models';
import { IProtocolTimerActionCreateProps } from './types';

export const actionText = `Add ${ProtocolTimer.getHumanReadableEntityName(
  false,
  true,
)}`;

const ProtocolTimerActionCreate: React.FC<
  IProtocolTimerActionCreateProps
> = ({ protocol, actionComponentProps = {} }) => (
  <EntityActionCreate
    entityName={ProtocolTimer.entityName}
    body={{
      name: `Untitled ${ProtocolTimer.getHumanReadableEntityName(
        false,
        true,
      )}`,
    }}
    actionComponentProps={{ type: 'option', text: actionText }}
    parentName={protocol.entityName}
    parentId={protocol.id}
    {...actionComponentProps}
  />
);

export default ProtocolTimerActionCreate;
