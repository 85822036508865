/**
 * Labstep
 *
 * @module components/Folder/Action/CreateSub
 * @desc Create Sub Folder Action
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import FolderFormCreateSub from 'labstep-web/components/Folder/Form/CreateSub';
import { CanCreate } from 'labstep-web/components/Entity/Can';
import { ICONS } from 'labstep-web/constants';
import { IFolderActionCreateSubProps } from './types';

export const FolderActionCreateSub: React.FC<
  IFolderActionCreateSubProps
> = ({ folder, folderType, actionComponentProps }) => (
  <CanCreate entityName="folder">
    <ModalDefault
      header="Add Sub-collection(s)"
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          onClick={toggleModal}
          icon={ICONS.folder.primary}
          text="Add Sub-collection(s)"
          {...actionComponentProps}
        />
      )}
      content={({ toggleModal }) => (
        <FolderFormCreateSub
          autoFocus
          body={{ type: folderType, outer_folder_id: folder.id }}
          options={{ onSuccess: toggleModal }}
        />
      )}
    />
  </CanCreate>
);

export default FolderActionCreateSub;
