/**
 * Labstep
 *
 * @module components/UserGroup/Action/Delete
 * @desc Action to delete experiment
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { CanGroup } from 'labstep-web/components/Entity/Can';
import { IUserGroupDeleteAction } from './types';

export const UserGroupDeleteAction: React.FC<
  IUserGroupDeleteAction
> = ({ userGroup }) => (
  <CanGroup action="owner" groupId={userGroup.group.id}>
    <EntityActionDelete
      actionComponentProps={{
        type: 'option',
        icon: 'times',
        text: 'Remove',
      }}
      header="Remove user"
      confirmButtonContent="Remove"
      confirmMessage={`Are you sure you want to remove ${
        userGroup.user.name
      } from this ${getHumanReadableEntityName('group')}`}
      entity={userGroup}
    />
  </CanGroup>
);

export default UserGroupDeleteAction;
