/**
 * Labstep
 *
 * @module components/OrderRequest/SearchSelect
 * @desc
 */

import React from 'react';
import SearchSelect from 'labstep-web/core/Select/Search';
import { OrderRequest } from 'labstep-web/models';
import { withActiveGroup } from 'labstep-web/containers';
import { IOrderRequestSearchSelectProps } from './types';

export const OrderRequestSearchSelect: React.FC<
  IOrderRequestSearchSelectProps
> = ({ onChange, params = {}, activeGroup, getOptionLabel }) => (
  <SearchSelect
    entityName={OrderRequest.entityName}
    params={{ group_id: activeGroup?.id, ...params }}
    onChange={onChange}
    getOptionLabel={getOptionLabel}
    autoFocus
  />
);

export default withActiveGroup(OrderRequestSearchSelect);
