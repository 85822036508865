/**
 * Labstep
 *
 * @module components/AccessKey/Form/ShowEdit/Name
 * @desc Table listing all resources in an protocol
 */

import React from 'react';
import omit from 'lodash/omit';
import ShowEdit from 'labstep-web/core/Form/ShowEdit';
import { accessKeyFieldName } from 'labstep-web/components/AccessKey/Form/fields';
import { AccessKey } from 'labstep-web/models';
import { IFieldWithoutActionProps } from 'labstep-web/core/Form/Reusable/types';
import { IAccessKeyTableCellProps } from './types';

const NameCell: React.FC<IAccessKeyTableCellProps> = ({
  accessKeyItem,
}) => (
  <ShowEdit
    entityName={AccessKey.entityName}
    entity={accessKeyItem}
    field={
      omit(accessKeyFieldName, [
        'fieldExplainer',
        'fieldLabel',
      ]) as IFieldWithoutActionProps
    }
    canEdit
  >
    {accessKeyItem.name}
  </ShowEdit>
);

export default NameCell;
