/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/Sequence
 * @desc Metadata field type sequence
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import UpdateOrCreateContainer from 'labstep-web/containers/UpdateOrCreate';
import { withActiveEditModal } from 'labstep-web/containers';
import { Sequence } from 'labstep-web/models';
import { IMetadataActionCreateSequenceProps } from './types';

export const MetadataActionCreateSequence: React.FC<
  IMetadataActionCreateSequenceProps
> = ({
  metadata,
  setActiveEditModal,
  createProps,
  options,
  actionComponentProps,
}) => (
  <UpdateOrCreateContainer
    createProps={createProps}
    entityName={metadata.entityName}
    updateProps={{ id: metadata.id }}
    // This is needed because the sequence has not yet been created
    optimistic={false}
  >
    {({ action, status }): React.ReactElement => (
      <ActionComponent
        dataTestId="metadata-action-create-sequence"
        type="text"
        text="Create sequence"
        status={status}
        elementProps={{
          placeholder: true,
        }}
        onClick={(): void => {
          action(
            {
              sequence: Sequence.createBodyDefault,
            },
            {
              onSuccess: ({ response }) =>
                setActiveEditModal({
                  entityName: Sequence.entityName,
                  id: response.entities.metadata[response.result]
                    .sequence,
                }),
              ...options,
            },
          );
        }}
        {...actionComponentProps}
      />
    )}
  </UpdateOrCreateContainer>
);

export default withActiveEditModal(MetadataActionCreateSequence);
