/**
 * Labstep
 *
 * @module screens/Device/Index/Breadcrumb
 * @desc Device Index Breadcrumb
 */

import DevicePreview from 'labstep-web/components/Device/Preview';
import EntityTemplateBreadcrumbIndex from 'labstep-web/components/Entity/Template/Breadcrumb/Index';
import EntityImportActionPending from 'labstep-web/components/EntityImport/Action/Pending';
import Flex from 'labstep-web/core/Flex';
import { Device } from 'labstep-web/models';
import React from 'react';

export const DeviceIndexBreadcrumb: React.FC = () => (
  <>
    <Flex
      vAlignContent="center"
      style={{ justifyContent: 'space-between' }}
      shrink={false}
    >
      <EntityTemplateBreadcrumbIndex
        entityName={Device.entityName}
        entityPreview={({ entity, ...rest }): React.ReactElement => (
          <DevicePreview device={entity as Device} {...rest} />
        )}
      />
    </Flex>
    <EntityImportActionPending targetEntityName={Device.entityName} />
  </>
);

export default DeviceIndexBreadcrumb;
