import colDefActionMenu from 'labstep-web/components/Entity/DataGrid/colDefs/actionMenu';
import DeviceActionDelete from 'labstep-web/components/Device/Action/Delete';
import { Device } from 'labstep-web/models';
import EntityActionTag from 'labstep-web/components/Entity/Action/Tag';
import EntityActionTransferOwnership from 'labstep-web/components/Entity/Action/TransferOwnership';
import DeviceBookingManager from 'labstep-web/components/DeviceBooking/Manager';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import { getMetadataColDefs } from 'labstep-web/components/Entity/DataGrid/colDefs/metadatas';
import colDefName from 'labstep-web/components/Entity/DataGrid/colDefs/name';
import React from 'react';
import DeviceActionShare from '../../Action/Share';
import DeviceActionUpdateTemplate from '../../Action/UpdateTemplate';
import DeviceActionDuplicate from '../../Action/Duplicate';

export const colDefDeviceLink = colDefName(Device.entityName, {
  getEntityPreviewProps: (device) => ({
    secondaryInfo: <MetadataManager entity={device} />,
    bottom: !device.is_template && (
      <DeviceBookingManager device={device as Device} />
    ),
  }),
});

export const colDefDeviceActionMenu = (isTemplate?: boolean) =>
  colDefActionMenu<Device>((params) => (
    <>
      <EntityActionTag parentEntity={params.data} />
      <DeviceActionShare device={params.data} />
      <DeviceActionDuplicate device={params.data} />
      {!isTemplate && (
        <DeviceActionUpdateTemplate device={params.data} />
      )}
      <EntityActionTransferOwnership entity={params.data} />
      <DeviceActionDelete device={params.data} />
    </>
  ));

/**
 * For all metadatas on device item template, return a column
 * @param deviceTemplate Device template
 * @returns Column definitions
 */
export const getDeviceMetadataColDefs = (deviceTemplate: Device) =>
  getMetadataColDefs(Device.entityName, deviceTemplate);
