/**
 * Labstep
 */

import { objectOrFunction } from 'labstep-web/services/react.service';
import DataGrid from 'labstep-web/core/DataGrid';
import { suppressKeyboardEventDisableEnterOnEdit } from 'labstep-web/core/DataGrid/CellEditor/utils';
import FilterResourceItemStatus from 'labstep-web/components/Filter/ResourceItemStatus';
import {
  ColDef,
  ColDefParams,
} from 'labstep-web/core/DataGrid/types';
import {
  getEditable,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import TextWithIcon from 'labstep-web/core/Text/WithIcon';
import TextWithMetadata from 'labstep-web/core/DataGrid/Text/WithMetadata';
import Icon from 'labstep-web/core/Icon';
import Flex from 'labstep-web/core/Flex';
import {
  OrderRequest,
  OrderRequestStatus,
  OrderRequestStatusValues,
  ResourceItem,
  ResourceItemStatus,
  ResourceItemStatusValues,
} from 'labstep-web/models';
import React from 'react';
import styles from './styles.module.scss';

/*
 * Column definition for ResourceItem status
 */
const colDefStatus: <T extends ResourceItem | OrderRequest>(
  entityName: T['entityName'],
  metadata?:
    | React.ReactNode
    | ((params: ColDefParams<T>) => React.ReactNode),
  onChange?: (
    entity: T,
    option: T extends ResourceItem
      ? ResourceItemStatus
      : OrderRequestStatus,
  ) => void,
) => ColDef<T> = (entityName, metadata, onChange) => {
  const values =
    entityName === ResourceItem.entityName
      ? ResourceItemStatusValues
      : OrderRequestStatusValues;
  return {
    colId: 'status',
    headerName:
      entityName === ResourceItem.entityName
        ? 'Availability'
        : 'Status',
    headerComponentParams: {
      icon: 'info circle',
      filter: (props) => (
        <FilterResourceItemStatus
          {...props}
          isOrderRequest={entityName === OrderRequest.entityName}
        />
      ),
    },
    cellRenderer: (params) => (
      <TextWithMetadata
        text={
          <Flex className={styles.status}>
            <TextWithIcon
              icon="circle"
              iconProps={{ className: styles[params.data.status] }}
              text={values[params.data.status as keyof typeof values]}
            />
            <Icon
              name="sort down"
              onClick={() =>
                params.api.startEditingCell({
                  rowIndex: params.rowIndex,
                  colKey: 'status',
                })
              }
            />
          </Flex>
        }
        metadata={objectOrFunction(metadata, params)}
      />
    ),
    cellEditor: DataGrid.CellEditor.Select,
    cellEditorParams: (params) => ({
      props: {
        value: {
          label: values[params.data.status as keyof typeof values],
          value: params.data.status,
        },
        options: Object.keys(values).map((status) => ({
          label: values[status as keyof typeof values],
          value: status,
        })),
        onChange: (option) => {
          setValue('status', option.value, {
            entityName: params.data.entityName,
            id: params.data.id,
          });
          onChange?.(params.data, option.value);
        },
      },
    }),
    suppressKeyboardEvent: suppressKeyboardEventDisableEnterOnEdit,
    cellEditorPopup: true,
    editable: getEditable(),
    valueGetter: (params) => params.data.status,
    valueSetter: (params) =>
      setValue('status', params.newValue, {
        entityName: params.data.entityName,
        id: params.data.id,
      }),
    autoHeight: !!metadata,
  };
};

export default colDefStatus;
