/* eslint-disable no-param-reassign */
import { GC } from 'labstep-web/core/SpreadJS/imports';
import {
  COLUMN_LIMIT,
  ROW_LIMIT,
} from 'labstep-web/services/table.service';

/* Initialisation */

export function bindEvents(sheet, onDataChange, onRangeChange) {
  sheet.bind(GC.Spread.Sheets.Events.CommentChanged, onDataChange);
  sheet.bind(GC.Spread.Sheets.Events.CommentRemoved, onDataChange);
  sheet.bind(GC.Spread.Sheets.Events.CellChanged, onDataChange);
  sheet.bind(
    GC.Spread.Sheets.Events.ColumnWidthChanged,
    onDataChange,
  );
  sheet.bind(GC.Spread.Sheets.Events.RowHeightChanged, onRangeChange);
  sheet.bind(GC.Spread.Sheets.Events.ColumnChanged, onRangeChange);
  sheet.bind(GC.Spread.Sheets.Events.RowChanged, onRangeChange);
  sheet.bind(GC.Spread.Sheets.Events.RangeChanged, onRangeChange);
}

export function initialiseContextMenu(spread) {
  const excludeActions = [
    'gc.spread.filter',
    'gc.spread.sort',
    'gc.spread.hideRows',
    'gc.spread.unhideRows',
    'gc.spread.hideColumns',
    'gc.spread.unhideColumns',
  ];
  spread.contextMenu.menuData = spread.contextMenu.menuData.filter(
    (field) => !excludeActions.includes(field.name),
  );
}

/* Helpers */

export function getSelection(spread) {
  const sheet = spread.getActiveSheet();
  const selections = sheet.getSelections();
  return selections;
}

export function toggleScrollbars(spread) {
  const activeSheet = spread.getActiveSheet();
  const brow = activeSheet.getViewportBottomRow(1);
  const lcol = activeSheet.getViewportLeftColumn(1);
  const rcol = activeSheet.getViewportRightColumn(1);
  const trow = activeSheet.getViewportTopRow(1);
  const lastRow = activeSheet.getRowCount() - 1;
  const lastCol = activeSheet.getColumnCount() - 1;
  if (brow === lastRow && trow === 0) {
    spread.options.showVerticalScrollbar = false;
  } else {
    spread.options.showVerticalScrollbar = true;
  }
  if (rcol === lastCol && lcol === 0) {
    spread.options.showHorizontalScrollbar = false;
  } else {
    spread.options.showHorizontalScrollbar = true;
  }
}

export function resizeColumns(sheet, minColumnWidth = 60) {
  for (let i = 0; i < sheet.getColumnCount(); i += 1) {
    sheet.autoFitColumn(i);
    if (sheet.getColumnWidth(i) < minColumnWidth) {
      sheet.setColumnWidth(i, minColumnWidth);
    }
  }
}

export function getCellPositionString(sheet, row, column) {
  row += 1;
  column += 1;
  if (row < 1 || column < 1) {
    return null;
  }

  let letters = '';
  while (column > 0) {
    const num = column % 26;
    if (num === 0) {
      letters = `Z${letters}`;
      column -= 1;
    } else {
      letters =
        String.fromCharCode('A'.charCodeAt(0) + num - 1) + letters;
    }
    column = parseInt((column / 26).toString(), 10);
  }
  letters += row.toString();
  return letters;
}

export function enforceSizeRequirements(
  sheet,
  onError,
  maxRows = ROW_LIMIT,
  maxCols = COLUMN_LIMIT,
) {
  const json = sheet.toJSON();
  let pass = true;
  if (json.rowCount > maxRows) {
    sheet.deleteRows(maxRows, json.rowCount - maxRows);
    pass = false;
  }
  if (json.columnCount > maxCols) {
    sheet.deleteColumns(maxCols, json.columnCount - maxCols);
    pass = false;
  }
  if (json.columnCount < 1) {
    sheet.addColumns(0, 1);
    return false;
  }
  if (json.rowCount < 1) {
    sheet.addRows(0, 1);
    return false;
  }
  if (!pass) {
    onError(
      `Maximum dimensions exceed. Data truncated after ${maxRows} rows and ${maxCols} columns.`,
    );
  }
  return pass;
}
