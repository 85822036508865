/**
 * Labstep
 *
 * @module components/User/Action/EnableMfa
 * @desc Action to enable Mfa
 */

import React, { useEffect, useState } from 'react';
import ModalDefault from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import FormReusable from 'labstep-web/core/Form/Reusable';
import ReactQRCode from 'qrcode.react';
import LayoutLoadingContent from 'labstep-web/components/Layout/LoadingContent';
import { MfaContainer } from 'labstep-web/containers/SetupMfa';
import {
  fieldUserCode,
  fieldUserPassword,
} from 'labstep-web/components/User/Form/fields';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import { IMfaComponentProps, IMfaProps } from './types';

export const MfaComponent: React.FC<IMfaComponentProps> = ({
  options,
  setupMfa,
  setupMfaStatus,
  confirmMfa,
  confirmMfaStatus,
  authenticatedUserEntity,
}) => {
  const [value, setValue] = useState(null);
  useEffect(() => {
    setupMfa({ onSuccess: ({ response }) => setValue(response) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let fields = [fieldUserCode];
  if (!authenticatedUserEntity?.is_external) {
    fields = [
      fieldUserCode,
      { ...fieldUserPassword, fieldLabel: 'Password' },
    ];
  }

  return (
    <LayoutLoadingContent
      status={setupMfaStatus}
      loader="placeholder"
    >
      <div>Scan the QR code below with your Authenticator App</div>
      {value && (
        <div style={{ margin: '10px 0px' }}>
          <ReactQRCode value={`${value.qrcode}`} />
        </div>
      )}
      <div>
        <p>
          To start using two-factor authentication, install an
          authenticator application such as Google Authenticator on
          your mobile device.
        </p>
        <FormReusable
          status={confirmMfaStatus}
          onSubmit={(body) => {
            confirmMfa(body, options);
          }}
          submitButtonText="Enable"
          fields={fields}
        />
      </div>
    </LayoutLoadingContent>
  );
};

const MfaComponentWithAuthenticatedUserEntity =
  withAuthenticatedUserEntity(MfaComponent);

export const Mfa: React.FC<IMfaProps> = (props) => (
  <MfaContainer>
    {(containerProps) => (
      <MfaComponentWithAuthenticatedUserEntity
        {...containerProps}
        {...props}
      />
    )}
  </MfaContainer>
);

const UserActionEnableMfa = () => (
  <ModalDefault
    header="Two-factor Authentication Set-up"
    content={({ toggleModal }) => (
      <Mfa options={{ onSuccess: toggleModal }} />
    )}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        onClick={toggleModal}
        type="button"
        text="Enable"
      />
    )}
  />
);

export default UserActionEnableMfa;
