/**
 * Labstep
 *
 * @module core/PDFObject
 * @desc Wrapper around pdfobject
 * @see https://pdfobject.com
 */

import React from 'react';
import pdfobject from 'pdfobject';
import Placeholder from 'labstep-web/core/Placeholder';
import { FileDownloadContainer } from 'labstep-web/containers';
import { fileService } from 'labstep-web/services/file.service';
import styles from './styles.module.scss';
import {
  IPDFObjectContainerProps,
  IPDFObjectProps,
  IPDFObjectState,
} from './types';

export class PDFObject extends React.Component<
  IPDFObjectProps,
  IPDFObjectState
> {
  constructor(props) {
    super(props);
    this.setUrl = this.setUrl.bind(this);
    this.state = {
      fileDownloaded: false,
      url: null,
    };
  }

  componentDidMount() {
    const { downloadFile, file } = this.props;
    const { fileDownloaded } = this.state;

    if (!fileDownloaded) {
      downloadFile({
        id: file.id,
        onSuccess: this.setUrl,
      });
    }
  }

  componentDidUpdate() {
    const { containerId } = this.props;
    const { url } = this.state;

    if (url) {
      pdfobject.embed(url, `#${containerId}`);
    }
  }

  async setUrl(downloadFileResponse: any) {
    const url: string = await fileService.getFileUrl(
      downloadFileResponse,
    );
    this.setState({
      url,
    });
  }

  render() {
    const { containerId } = this.props;
    const { url } = this.state;

    return (
      <>
        {!url && <Placeholder />}
        <div className={styles.pdfObjectContainer} id={containerId} />
      </>
    );
  }
}

export const PDFObjectContainer: React.FC<
  IPDFObjectContainerProps
> = ({ file, containerId }) => (
  <FileDownloadContainer file={file}>
    {(containerProps) => (
      <PDFObject
        {...containerProps}
        file={file}
        containerId={containerId}
      />
    )}
  </FileDownloadContainer>
);

export default PDFObjectContainer;
