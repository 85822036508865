/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/View/Manager/Select
 * @desc Select for ExperimentWorkflow Table Manager
 */

import React from 'react';
import Select from 'labstep-web/core/Select';
import { ParamsHOC } from 'labstep-web/hoc';
import { IExperimentWorkflowTableViewOptionsProps } from 'labstep-web/components/ExperimentWorkflow/ViewManager/types';
import { ParamsContext } from 'labstep-web/hoc/Params/context';
import styles from './styles.module.scss';
import { IExperimentWorkflowViewManagerSelectProps } from './types';

export const ExperimentWorkflowViewManagerSelect: React.FC<
  IExperimentWorkflowViewManagerSelectProps
> = ({ value, options, onChange }) => (
  <ParamsHOC historyAction="replace">
    <ParamsContext.Consumer>
      {({ setParams }) => (
        <div
          className={styles.toggleAction}
          data-testid="experiment-workflow-view-manager"
        >
          <Select
            menuPortalTarget={document.body}
            isSearchable={false}
            options={options}
            value={value}
            onChange={(
              option: IExperimentWorkflowTableViewOptionsProps,
            ) => {
              if (option.value === 'timeline') {
                setParams({ is_ended: false });
              }
              onChange(option.value);
            }}
          />
        </div>
      )}
    </ParamsContext.Consumer>
  </ParamsHOC>
);

export default ExperimentWorkflowViewManagerSelect;
