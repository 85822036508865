/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/Plan
 * @desc Organization plan card for plan
 */

import React from 'react';
import OrganizationPlanCard from 'labstep-web/components/OrganizationPlan/Card';
import Divider from 'labstep-web/core/Divider';
import OrganizationPlanCardTextBenefit from '../Text/Benefit';
import OrganizationPlanCardTextCount from '../Text/Count';
import styles from './styles.module.scss';
import { OrganizationPlanCardPlanProps } from './types';

export const OrganizationPlanCardPlan: React.FC<
  OrganizationPlanCardPlanProps
> = ({ organizationPlan, organization }) => {
  const hasBenefits =
    organizationPlan.discount_percent_off > 0 ||
    organization.free_seats > 0;
  return (
    <OrganizationPlanCard
      icon="file alternate"
      header="Plan"
      secondary={
        <div>
          {organization.max_seats && (
            <OrganizationPlanCardTextCount
              count={organization.max_seats - organization.free_seats}
              text="users"
            />
          )}
          {organization.free_seats > 0 && (
            <OrganizationPlanCardTextBenefit
              text={`${organization.free_seats} free seats`}
            />
          )}
          <OrganizationPlanCardTextCount
            count="Unlimited"
            text="workspaces"
          />
          <OrganizationPlanCardTextCount
            count={`${organizationPlan.currencyDisplay}${organizationPlan.amountDisplay}`}
            text="per user"
          />
          {hasBenefits && <Divider className={styles.divider} />}
          {organizationPlan.discount_percent_off > 0 && (
            <OrganizationPlanCardTextBenefit
              text={`${organizationPlan.discount_percent_off}% discount`}
            />
          )}
        </div>
      }
    />
  );
};

export default OrganizationPlanCardPlan;
