/**
 * Labstep
 *
 * @desc Manager for PermissionRole
 */

import React from 'react';
import PermissionRoleTable from 'labstep-web/components/PermissionRole/Table';
import PermissionRoleActionCreate from 'labstep-web/components/PermissionRole/Action/Create';
import { IPermissionRoleManagerProps } from './types';
import styles from './styles.module.scss';

const PermissionRoleManager: React.FC<
  IPermissionRoleManagerProps
> = ({ organization }) => (
  <div className={styles.container}>
    <PermissionRoleActionCreate organization={organization} />
    <PermissionRoleTable
      organization={organization}
      permissionRoles={organization.permission_roles}
    />
  </div>
);

export default PermissionRoleManager;
