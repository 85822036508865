/**
 * Labstep
 *
 * @module screens/Onboarding
 * @desc Onboarding page for entereprise
 */

import React from 'react';
import { navigation } from 'labstep-web/services/navigation';
import DocumentHead from 'labstep-web/core/DocumentHead';
import ShareLinkInvitationFormCreateOrganization from 'labstep-web/components/ShareLinkInvitation/Form/Create/Organization';
import LayoutFullScreenPage from 'labstep-web/components/Layout/FullScreenPage';
import Header from 'labstep-web/core/Header';
import Button from 'labstep-web/core/Button';
import OrganizationLogo from 'labstep-web/components/Organization/Logo';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import { withRouter } from 'react-router';
import { IScreensOnboardingProps } from './types';
import styles from './styles.module.scss';

export const ScreensOnboarding: React.FC<IScreensOnboardingProps> = ({
  authenticatedUserEntity,
  history,
}) => {
  const { organization } = authenticatedUserEntity.userOrganization;
  return (
    <>
      <DocumentHead title="Onboarding" />
      <LayoutFullScreenPage>
        <div className={styles.centered}>
          <OrganizationLogo organization={organization} />
          <Header size="large">{organization.name}</Header>
          <Header size="small">
            Next, let&apos;s invite your colleagues.
          </Header>
        </div>
        <ShareLinkInvitationFormCreateOrganization
          organization={organization}
          options={{
            toast: true,
            onSuccess: () =>
              history.push(
                navigation.get('organization_show', {
                  identifier: organization.identifier,
                }),
              ),
          }}
          cancelButton={
            <Button
              primary
              basic
              route={{
                to: 'organization_show',
                params: { identifier: organization.identifier },
              }}
            >
              Skip
            </Button>
          }
        />
      </LayoutFullScreenPage>
    </>
  );
};

export default withAuthenticatedUserEntity(
  withRouter(ScreensOnboarding),
);
