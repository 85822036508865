/**
 * Labstep
 *
 * @desc Component to select entityName
 */

import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import classNames from 'classnames';
import { ICONS } from 'labstep-web/constants';
import Icon from 'labstep-web/core/Icon';
import { ILayoutGlobalSearchEntityNameSelectorProps } from './types';
import styles from './styles.module.scss';
import { ENTITY_NAME_OPTIONS, EntityNameOptions } from './constants';

const LayoutGlobalSearchEntityNameSelector: React.FC<
  ILayoutGlobalSearchEntityNameSelectorProps
> = ({ searchParams, setParams }) => (
  <div
    className={styles.container}
    data-testid="entity-name-selector"
  >
    {ENTITY_NAME_OPTIONS.map((e) => (
      <div
        key={e}
        className={classNames({
          [styles.active]: searchParams.entityName === e,
        })}
        onClick={() => setParams({ entityName: e })}
      >
        <Icon name={ICONS[e as EntityNameOptions].primary} />
        {getHumanReadableEntityName(e, true, true)}
      </div>
    ))}
  </div>
);

export default LayoutGlobalSearchEntityNameSelector;
