/**
 * Labstep
 *
 * @module components/OrderRequest/Filter/Urgent/Active
 * @desc Active filter component for urgent order request
 */

import React from 'react';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { STRINGS } from 'labstep-web/strings';
import { ICONS } from 'labstep-web/constants';
import { IOrderRequestFilterUrgentActiveProps } from './types';

const OrderRequestFilterUrgentActive: React.FC<
  IOrderRequestFilterUrgentActiveProps
> = ({ clearParams, searchParams }) =>
  'is_urgent' in searchParams ? (
    <RemoveLabel
      icon={ICONS.order_request.info.urgent}
      name={STRINGS.order_request.info.urgent}
      onRemoveClick={
        clearParams && (() => clearParams(['is_urgent']))
      }
    />
  ) : null;

export default OrderRequestFilterUrgentActive;
