/**
 * Labstep
 *
 * @module components/ResourceLocation/Filter/Active
 * @desc Active filter component for resource location
 */

import React from 'react';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { ResourceLocation } from 'labstep-web/models';
import { EntityReadEntityContainer } from 'labstep-web/containers';
import { PostFilterResourceLocation } from 'labstep-web/services/postFilter/filters/resourceLocation';
import { ICONS } from 'labstep-web/constants';
import { IFilterPostActiveLabelProps } from 'labstep-web/components/Filter/Post/Active/Label/types';

const ResourceLocationFilterActivePost: React.FC<
  IFilterPostActiveLabelProps
> = ({ node, onRemoveClick }) => {
  const { guid: guidAttribute } =
    PostFilterResourceLocation.getAttributeValues(node);
  const guid = guidAttribute?.value;
  if (!guid) {
    return null;
  }
  return (
    <EntityReadEntityContainer
      entityName={ResourceLocation.entityName}
      id={guid}
    >
      {({ entity }): React.ReactElement | null =>
        entity ? (
          <RemoveLabel
            icon={ICONS.resource_location.primary}
            name={entity.name}
            onRemoveClick={onRemoveClick}
          />
        ) : null
      }
    </EntityReadEntityContainer>
  );
};

export default ResourceLocationFilterActivePost;
