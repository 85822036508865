/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Timeline/Modal
 * @desc Create modal for experiment workflow from the timeline
 */

import React from 'react';
import ModalDefault from 'labstep-web/core/Modal/Default';
import ExperimentWorkflowFormCreate from 'labstep-web/components/ExperimentWorkflow/Form/Create';
import { ExperimentWorkflow } from 'labstep-web/models';
import { IExperimentWorkflowTimelineModalProps } from './types';

export const ExperimentWorkflowTimelineModal: React.FC<
  IExperimentWorkflowTimelineModalProps
> = ({ defaultValues, onClose, options, body }) => (
  <ModalDefault
    header={`New ${ExperimentWorkflow.getHumanReadableEntityName(
      false,
      true,
    )}`}
    open
    onClose={onClose}
    viewComponent={null}
    content={() => (
      <ExperimentWorkflowFormCreate
        defaultValues={defaultValues}
        options={options}
        body={body}
      />
    )}
  />
);

export default ExperimentWorkflowTimelineModal;
