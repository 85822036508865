/**
 * Labstep
 *
 * @module components/JupyterNotebook/Content
 * @desc JupyterNotebook Content
 */

import React, { useEffect, useState } from 'react';
import LayoutLoadingContent from 'labstep-web/components/Layout/LoadingContent';
import {
  withAuthenticatedUser,
  withJupyterEdit,
} from 'labstep-web/containers';
import { JupyterNotebook } from 'labstep-web/models';
import { IStatus } from 'labstep-web/typings';
import { IJupyterNotebookContentProps } from './types';

const getStatus = (
  status: IStatus,
  jupyterNotebook: JupyterNotebook,
) => {
  if (
    jupyterNotebook.jupyter_instance_edit &&
    !jupyterNotebook.jupyter_instance_edit.started_at
  ) {
    return {
      isFetching: true,
      error: null,
    };
  }
  return status;
};

export const JupyterNotebookContent: React.FC<
  IJupyterNotebookContentProps
> = ({ authenticatedUser, jupyterNotebook, getLink, status }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
      if (!jupyterNotebook.jupyter_instance_edit) {
        getLink(jupyterNotebook.guid);
      }
    }
  }, [isLoaded, setIsLoaded, jupyterNotebook, getLink]);

  return (
    <LayoutLoadingContent
      status={getStatus(status, jupyterNotebook)}
      message="Launching Jupyter Notebook Server..."
      loader="main_content"
    >
      {jupyterNotebook.jupyter_instance_edit &&
        jupyterNotebook.jupyter_instance_edit.started_at &&
        !jupyterNotebook.jupyter_instance_edit.ended_at && (
          <iframe
            style={{ flexGrow: 1 }}
            title="jupyter"
            src={jupyterNotebook.jupyter_instance_edit.getLink(
              authenticatedUser.guid,
            )}
          />
        )}
    </LayoutLoadingContent>
  );
};

export default withAuthenticatedUser(
  withJupyterEdit(JupyterNotebookContent),
);
