/**
 * Labstep
 *
 * @module components/Group/Card/EmptyState
 * @desc Empty state for group
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Card from 'labstep-web/core/Card/Card';
import Button from 'labstep-web/core/Button';
import { Group } from 'labstep-web/models';

export const GroupEmptyState = () => (
  <EmptyState
    src="/img/empty-state/workspaces.svg"
    explanation="Workspaces are the collaborative spaces where work happens on Labstep. Collectively manage the shared experiments, protocols and inventory within each workspace and control the permissions of the different members."
    action={
      <Button primary route={{ to: 'group_create' }}>
        New {Group.getHumanReadableEntityName(false, true)}
      </Button>
    }
  />
);

const GroupEmptyStateCard = () => (
  <Card header={Group.getHumanReadableEntityName(true, true)}>
    <GroupEmptyState />
  </Card>
);

export default GroupEmptyStateCard;
