/**
 * Labstep
 *
 * @module components/Experiment/Form/ShowEdit/State
 * @desc State showedit field
 */

import React from 'react';
import { Prompt } from 'react-router';
import ProseMirror from 'labstep-web/prosemirror/Container';
import ModalEdit from 'labstep-web/components/Modal/Edit';
import WarningModal from 'labstep-web/components/Protocol/Form/ShowEdit/State/WarningModal';
import ExperimentActionBar from 'labstep-web/components/Experiment/Action/Bar';
import ExperimentWorkflowTemplateWarning from 'labstep-web/components/ExperimentWorkflowTemplate/Warning';
import ButtonEdit from 'labstep-web/components/Experiment/Form/ShowEdit/State/ButtonEdit';
import ExperimentActionEdit from 'labstep-web/components/Experiment/Form/ShowEdit/State/ExperimentActionEdit';
import ToolbarRight from 'labstep-web/components/Protocol/Form/ShowEdit/State/ToolbarRight';
import ProseMirrorConflict from 'labstep-web/core/ProseMirror/Conflict';
import { withProseMirror } from 'labstep-web/containers';
import { IExperimentFormShowEditStateProps } from './types';
import ExperimentFormShowEditStateEmptyState from './EmptyState';
import { getMessage, getOnClick } from './utils';

export const ExperimentFormShowEditState: React.FC<
  IExperimentFormShowEditStateProps
> = ({
  experiment,
  experimentWorkflow,
  actions,
  onChange,
  canEdit,
  debouncingStatus,
  cancelDebouncing,
  match,
  withActionBar,
  proseMirrorKey,
  conflict,
  forceRefresh,
  editing,
  setEditMode,
  setViewMode,
  topMenuClassName,
}) => {
  const toolbarRight = React.useMemo(
    () => (
      <ToolbarRight
        experimentWorkflow={experimentWorkflow}
        protocol={experiment}
        setViewMode={setViewMode}
      />
    ),
    [experiment, experimentWorkflow, setViewMode],
  );
  return (
    <>
      <ProseMirrorConflict
        conflict={conflict}
        debouncing={debouncingStatus.isFetching}
        cancelDebouncing={cancelDebouncing}
        forceRefresh={forceRefresh}
      />
      <WarningModal status={debouncingStatus} />
      <Prompt
        when={!!conflict}
        message="Copy any unsaved changes before leaving the page"
      />
      <Prompt
        when={debouncingStatus.isFetching || !!debouncingStatus.error}
        message={getMessage(experiment, experimentWorkflow)}
      />
      <ExperimentWorkflowTemplateWarning
        experimentWorkflow={experimentWorkflow}
      />
      <ModalEdit parent={experiment} />
      {withActionBar && (
        <ExperimentActionBar
          experiment={experiment}
          editAction={
            !editing ? (
              <ExperimentActionEdit
                experiment={experiment}
                onClick={setEditMode}
              />
            ) : undefined
          }
          actions={actions}
        />
      )}
      {experiment.state === null && !editing ? (
        <ExperimentFormShowEditStateEmptyState
          experiment={experiment}
          onClick={setEditMode}
        />
      ) : (
        <div
          id="experiment-state"
          onClick={
            !match && canEdit
              ? getOnClick(editing, setEditMode, setViewMode)
              : undefined
          }
          onDragEnter={setEditMode}
        >
          {!editing && !withActionBar && canEdit && (
            <ButtonEdit
              experiment={experiment}
              onClick={setEditMode}
            />
          )}
          <ProseMirror
            key={proseMirrorKey}
            experimentWorkflow={experimentWorkflow}
            editable={!conflict && editing}
            entity={experiment}
            onChange={onChange}
            initialState={experiment.state}
            topMenuClassName={topMenuClassName}
            toolbarRight={toolbarRight}
          />
        </div>
      )}
    </>
  );
};

export default withProseMirror(ExperimentFormShowEditState);
