/**
 * Labstep
 *
 * @module screens/Notification/Redirect
 * @desc Screen to redirect to notification's relevant entity
 */

import React from 'react';
import LayoutMainContentCenter from 'labstep-web/components/Layout/MainContent/Center';
import { getRoute } from 'labstep-web/components/Notification/Card/Preview/utils';
import Redirect from 'labstep-web/core/Redirect';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { Notification as NotificationModel } from 'labstep-web/models';
import { IScreensNotificationRedirectProps } from './types';

const ScreensNotificationRedirect: React.FC<
  IScreensNotificationRedirectProps
> = ({
  match: {
    params: { id },
  },
}) => (
  <LayoutMainContentCenter>
    <ReadOnMountHOC
      entityName={NotificationModel.entityName}
      params={{ get_single: 1, id }}
      type="entities"
      children={({ entity: notification }) => (
        <Redirect {...getRoute(notification)} />
      )}
    />
  </LayoutMainContentCenter>
);

export default ScreensNotificationRedirect;
