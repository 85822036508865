/**
 * Labstep
 *
 * @module components/Guard/AdminLabstep
 * @desc Guard to show only for AdminLabstep
 */

import React from 'react';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import { IGuardAdminLabstepProps } from './types';

export const GuardAdminLabstep: React.FC<IGuardAdminLabstepProps> = ({
  authenticatedUserEntity,
  children,
}) => {
  const { isLabstepAdmin } = authenticatedUserEntity;
  if (typeof children === 'function') {
    return children({ isLabstepAdmin });
  }

  if (!isLabstepAdmin) {
    return null;
  }

  return <>{children}</>;
};

export default withAuthenticatedUserEntity(GuardAdminLabstep);
