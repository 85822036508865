/**
 * Labstep
 *
 * @desc Create Form for templatable entity
 */

import React from 'react';
import FormCreate from 'labstep-web/core/Form/Create';
import { withActiveGroup } from 'labstep-web/containers';
import { fieldEntityName, fieldEntityTemplate } from '../../fields';
import { IEntityFormCreateProps } from './types';

export const EntityFormCreate: React.FC<IEntityFormCreateProps> = ({
  entityName,
  templateId,
  activeGroup,
  defaultValues,
  options,
}) => {
  const fields = [fieldEntityName(entityName)];
  const body: any = {};
  if (!templateId) {
    const fieldTemplate = fieldEntityTemplate(entityName);
    fields.push({
      ...fieldTemplate,
      elementProps: {
        ...fieldTemplate.elementProps,
        params: { group_id: activeGroup?.id },
      },
    });
  } else {
    body.template_id = templateId;
    body.group_id = activeGroup?.id;
  }

  return (
    <FormCreate
      body={body}
      autoFocus
      entityName={entityName}
      fields={fields}
      defaultValues={defaultValues}
      options={options}
    />
  );
};

export default withActiveGroup(EntityFormCreate);
