/**
 * Labstep
 *
 * @module components/Folder/List
 * @desc List of folders
 */

import React from 'react';
import FolderActionMenu from 'labstep-web/components/Folder/Action/Menu';
import Tree from 'labstep-web/core/Tree';
import FolderActionCreateSub from 'labstep-web/components/Folder/Action/CreateSub';
import { ICONS } from 'labstep-web/constants';
import { Folder } from 'labstep-web/models';
import { Action } from 'labstep-web/components/Folder/Action/Menu/types';
import { IFolderPaneListProps } from './types';

export const FolderPaneList: React.FC<IFolderPaneListProps> = ({
  folderType,
  action,
  searchBarProps,
}) => (
  <Tree
    entityName={Folder.entityName}
    childKeyName="outer_folder"
    icon={ICONS.folder.primary}
    iconInactive={ICONS.folder.info.unselected}
    createSubAction={(entity) => (
      <FolderActionCreateSub
        folder={entity}
        folderType={folderType}
        actionComponentProps={{ type: 'text', icon: 'plus' }}
      />
    )}
    actionMenu={(entity, options) => (
      <FolderActionMenu
        folder={entity}
        folderType={folderType}
        actions={[
          Action.edit,
          Action.delete,
          Action.add_subfolders,
          Action.update_outer,
        ]}
        options={options}
      />
    )}
    unsorted={{
      icon: 'inbox',
      text: 'Unsorted',
      param: 'folder_group_not_id',
    }}
    params={{ type: folderType }}
    action={action}
    searchBarProps={searchBarProps}
  />
);

export default FolderPaneList;
