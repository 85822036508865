/**
 * Labstep
 */

import { IActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { Device } from 'labstep-web/models';

// eslint-disable-next-line no-shadow
export enum Action {
  view = 'view',
  share = 'share',
  duplicate = 'duplicate',
  tag = 'tag',
  delete = 'delete',
  assign = 'assign',
  revoke = 'revoke',
  transfer_ownership = 'transfer_ownership',
  set_template = 'set_template',
}

export interface IDeviceActionMenuProps
  extends Pick<
    IActionMenuProps,
    Exclude<keyof IActionMenuProps, 'children'>
  > {
  device: Device;
  actions: Action[];
}
