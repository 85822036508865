/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Commands
 * @desc A menu to select elements triggered by '/'
 */

import React, { useEffect } from 'react';
import { handleSetElements } from 'labstep-web/prosemirror/extensions/slash/commands';
import { getState } from 'labstep-web/prosemirror/extensions/slash/commands/selectors';
import { withAuthenticatedUserEntity } from 'labstep-web/containers';
import MenuCursor from 'labstep-web/prosemirror/components/Menu/Cursor';
import MenuCommandsMenu from './Menu';
import MenuCommandsModalAdd from './Modal/Add';
import { IMenuCommandsProps } from './types';
import menuCommandsElements from './elements';

export const MenuCommands: React.FC<IMenuCommandsProps> = ({
  state,
  dispatch,
  view,
  entity,
  experimentWorkflow,
  cursorPosition,
}) => {
  const { isOpen, availableElements, submittedElement, index } =
    getState(state);

  useEffect(() => {
    handleSetElements(state, dispatch, entity, experimentWorkflow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div>
      <MenuCursor cursorPosition={cursorPosition} isOpen={isOpen}>
        {({ menuRef, activeItemRef }) => (
          <MenuCommandsMenu
            experimentWorkflow={experimentWorkflow}
            ref={menuRef}
            activeItemRef={activeItemRef}
            state={state}
            dispatch={dispatch}
            elements={availableElements.map(
              (availableElement) =>
                menuCommandsElements.find(
                  (element) => element.id === availableElement,
                )!,
            )}
            index={index}
          />
        )}
      </MenuCursor>
      <MenuCommandsModalAdd
        state={state}
        dispatch={dispatch}
        view={view}
        entity={entity}
        experimentWorkflow={experimentWorkflow}
        submittedElement={menuCommandsElements.find(
          (element) => element.id === submittedElement,
        )}
      />
    </div>
  );
};

export default withAuthenticatedUserEntity(MenuCommands);
