/**
 * Labstep
 *
 * @module components/Entity/Action/Duplicate
 * @desc Button to add resource from resource list
 */

import React from 'react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { IEntityActionDuplicateProps } from './types';

export const EntityActionDuplicate: React.FC<
  IEntityActionDuplicateProps
> = ({
  entity,
  actionComponentProps,
  body = {},
  noRedirect,
  ...rest
}) => {
  let bodyWithExtraParams: Record<string, unknown> = {
    source_id: entity.id,
  };
  if ((entity as any).is_template) {
    bodyWithExtraParams.is_template = true;
  }
  // We spread the body after bodyWithExtraParams for experiment workflow case
  // where even though is_template is true we really want to pass false.
  bodyWithExtraParams = { ...bodyWithExtraParams, ...body };
  return (
    <EntityActionCreate
      entityName={entity.entityName}
      actionComponentProps={{
        type: 'option',
        text: 'Duplicate',
        icon: 'clone',
        ...actionComponentProps,
      }}
      redirectRoute={
        noRedirect
          ? undefined
          : ({ response }) => ({
              to: `${entity.entityName}_show`,
              params: { id: response.result },
            })
      }
      body={bodyWithExtraParams}
      {...rest}
    />
  );
};

export default EntityActionDuplicate;
