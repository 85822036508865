/**
 * Labstep
 *
 * @module core/DataGrid/CellEditor/Select
 * @desc CellEditor Select
 */

import Select from 'labstep-web/core/Select';
import { Entity } from 'labstep-web/models';
import React from 'react';
import { stopEditingAndFocus } from '../../utils';
import styles from './styles.module.scss';
import { ICellEditorSelectProps } from './types';

export const DataGridCellEditorSelect: React.FC<
  ICellEditorSelectProps<Entity>
> = React.memo((params) => (
  <div
    className={styles.container}
    style={{
      width: params.column.getActualWidth(),
      height: params.node?.rowHeight || 0,
    }}
  >
    <Select
      autoFocus
      openMenuOnFocus
      backspaceRemovesValue
      onBlur={(): void => stopEditingAndFocus(params)}
      defaultInputValue={params.charPress || ''}
      {...params.props}
      onChange={(option) => {
        params.props.onChange(option);
        stopEditingAndFocus(params);
      }}
    />
  </div>
));

export default DataGridCellEditorSelect;
