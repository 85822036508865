/**
 * Labstep
 *
 * @module components/SignatureRequest/Action/Create
 * @desc Create Action for signature request workflow
 */

import React from 'react';
import FormReusable from 'labstep-web/core/Form/Reusable';
import {
  fieldSignatureRequestMessage,
  fieldSignatureRequestUser,
} from 'labstep-web/components/SignatureRequest/Form/fields';
import { SignatureRequest } from 'labstep-web/models';
import {
  EntityCreateContainer,
  withActiveGroup,
  withAuthenticatedUserEntity,
} from 'labstep-web/containers';
import { IFieldTypeSearchSelectProps } from 'labstep-web/core/Form/Reusable/types';
import { getDefaultSignatureRequestMessage } from '..';
import { ISignatureRequestFormCreateBulkProps } from './types';

export const SignatureRequestFormCreateBulk: React.FC<
  ISignatureRequestFormCreateBulkProps
> = ({
  experimentWorkflows,
  options,
  activeGroup,
  authenticatedUserEntity,
}) => {
  return (
    <EntityCreateContainer
      entityName={SignatureRequest.entityName}
      batch
    >
      {({ create, status }): React.ReactElement => (
        <FormReusable
          autoFocus
          fields={[
            {
              ...fieldSignatureRequestUser,
              elementProps: {
                ...fieldSignatureRequestUser.elementProps,
                params: { group_id: activeGroup?.id },
              },
            } as IFieldTypeSearchSelectProps,
            fieldSignatureRequestMessage,
          ]}
          defaultValues={{
            [fieldSignatureRequestMessage.name]:
              getDefaultSignatureRequestMessage(
                authenticatedUserEntity,
                activeGroup,
              ),
          }}
          status={status}
          onSubmit={(values) =>
            create(
              experimentWorkflows.map((entity) => ({
                experiment_workflow_id: entity.id,
                ...values,
              })),
              { ...options, toast: true },
            )
          }
          submitButtonText="Request"
        />
      )}
    </EntityCreateContainer>
  );
};

export default withAuthenticatedUserEntity(
  withActiveGroup(SignatureRequestFormCreateBulk),
);
