/**
 * Labstep
 *
 * @module components/Layout/RightPane
 * @desc Elements Overview
 */

import React from 'react';
import { navigation } from 'labstep-web/services/navigation';
import { objectOrFunction } from 'labstep-web/services/react.service';
import { IRouting } from 'labstep-web/services/navigation.types';
import Route from 'labstep-web/core/Route';
import { Switch } from 'react-router-dom';
import { Desktop } from 'labstep-web/core/MediaQuery';
import RightPaneIndexPage from './IndexPage';
import RightPaneSubPage from './SubPage';
import { IRightPaneProps } from './types';

// Add more paths to match with route
export const getPath = (
  to: keyof IRouting,
  paths?: string[],
): string | string[] =>
  paths ? [navigation.get(to), ...paths] : navigation.get(to);

const RightPane: React.FC<IRightPaneProps> = ({
  route,
  paths,
  subPages,
  subPagesNested,
}) => (
  <Desktop>
    {({ match }): React.ReactElement => (
      <Switch>
        <Route
          exact
          path={getPath(route.to, paths)}
          render={(): React.ReactElement => (
            <RightPaneIndexPage subPages={subPages} desktop={match} />
          )}
        />
        {subPages.map((page) => (
          <Route
            exact
            key={page.route.to}
            path={getPath(page.route.to, page.paths)}
            render={(): React.ReactElement => (
              <RightPaneSubPage
                header={page.name}
                icon={page.icon}
                expanded={page.expanded}
                backRoute={route}
              >
                {page.component}
              </RightPaneSubPage>
            )}
          />
        ))}
        {subPagesNested?.map((page) => (
          <Route
            exact
            key={page.route.to}
            path={getPath(page.route.to, page.paths)}
            render={(props): React.ReactElement => (
              <RightPaneSubPage
                header={page.name(props)}
                icon={page.icon}
                backRoute={{
                  ...objectOrFunction(page.backRoute, props),
                  params: props.match.params,
                }}
              >
                {page.component(props)}
              </RightPaneSubPage>
            )}
          />
        ))}
      </Switch>
    )}
  </Desktop>
);

export default RightPane;
