/**
 * Labstep
 *
 * @module models/review
 * @desc Typescript export class for Review
 */

import { Type } from 'class-transformer';
import { User } from 'labstep-web/models/user.model';
import { ReviewThread } from 'labstep-web/models/review-thread.model';
import { Entity } from 'labstep-web/models/entity.model';

export class Review extends Entity {
  static readonly entityName = 'review';

  get entityName(): string {
    return Review.entityName;
  }

  constructor(data: Partial<Review> = {}) {
    super();
    Object.assign(this, data);
  }

  id: number;

  body: string;

  rating: number;

  @Type(() => User)
  author: User;

  @Type(() => ReviewThread)
  review_thread: ReviewThread;
}
