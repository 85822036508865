/**
 * Labstep
 *
 * @module components/EntityImport/Table
 * @desc EntityImport list
 */

import React from 'react';
import SimpleTable from 'labstep-web/core/Table/Simple';
import EntityImportActionMenu from 'labstep-web/components/EntityImport/Action/Menu';
import EntityImportProgress from 'labstep-web/components/EntityImport/Progress';
import { humanReadableDate } from 'labstep-web/services/date.service';
import { EntityImport } from 'labstep-web/models';
import Flex from 'labstep-web/core/Flex';
import ProfilePictureImage from 'labstep-web/components/Profile/Picture/Image';
import { Size } from 'labstep-web/core/Initials/types';
import { IEntityImportTableProps } from './types';

const EntityImportTable: React.FC<IEntityImportTableProps> = ({
  entityImports,
  actions = [],
}) => (
  <SimpleTable
    id="data-import-table"
    rows={entityImports}
    columns={[
      {
        header: 'Date',
        content: (entityImport: EntityImport): React.ReactNode => (
          <>{humanReadableDate(entityImport.created_at)}</>
        ),
        cellProps: { width: 2 },
      },
      {
        header: 'Category',
        content: (entityImport: EntityImport): React.ReactNode => (
          <>{entityImport.template?.name}</>
        ),
        cellProps: { width: 2 },
      },
      {
        header: 'Status',
        content: (entityImport: EntityImport): React.ReactElement => (
          <>{entityImport.statusLabel}</>
        ),
        cellProps: { width: 2 },
      },
      {
        header: 'Progress',
        content: (entityImport: EntityImport): React.ReactElement => (
          <EntityImportProgress entityImport={entityImport} />
        ),
        cellProps: { style: { flex: 3 } },
      },

      {
        header: '',
        content: (entityImport: EntityImport): React.ReactElement => (
          <Flex
            vAlignContent="center"
            hAlignContent="right"
            spacing="gap"
          >
            <ProfilePictureImage
              size={Size.mini}
              popup
              content={entityImport.author.username}
              entity={entityImport.author}
            />
            <EntityImportActionMenu
              entityImport={entityImport}
              actions={actions}
            />
          </Flex>
        ),
      },
    ]}
  />
);

export default EntityImportTable;
