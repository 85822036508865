/**
 * Labstep
 *
 * @module screens/UserGroupNotification/Manager
 */

import React from 'react';
import UserGroupNotificationTable from 'labstep-web/components/UserGroupNotification/Table';
import { UserGroupNotification } from 'labstep-web/models';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { withActiveGroup } from 'labstep-web/containers';
import { IUserGroupNotificationManagerProps } from './types';

export const UserGroupNotificationManager: React.FC<
  IUserGroupNotificationManagerProps
> = ({ activeGroup }) => {
  if (!activeGroup) {
    return null;
  }
  return (
    <ReadOnMountHOC
      type="cursor"
      entityName={UserGroupNotification.entityName}
      params={{
        user_group_id: activeGroup.logged_user_user_group.id,
        count: 50,
      }}
      children={({ entities }) => (
        <UserGroupNotificationTable
          userGroupNotifications={entities}
        />
      )}
    />
  );
};

export default withActiveGroup(UserGroupNotificationManager);
