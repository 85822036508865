/**
 * Labstep
 *
 * @module core/DataGrid/CellRenderer/ActionMenu
 * @desc CellRenderer ActionMenu
 */

import DropdownPortal from 'labstep-web/core/Dropdown/Portal';
import Icon from 'labstep-web/core/Icon';
import { Entity } from 'labstep-web/models';
import React from 'react';
import styles from './styles.module.scss';
import { ICellRendererActionMenuProps } from './types';

export const DataGridCellRendererActionMenu: React.FC<
  ICellRendererActionMenuProps<Entity>
> = React.memo((params) =>
  params.props ? (
    <DropdownPortal
      trigger={
        <Icon
          className={styles.actionMenu}
          name="ellipsis vertical"
        />
      }
    >
      {params.props.action}
    </DropdownPortal>
  ) : null,
);

export default DataGridCellRendererActionMenu;
