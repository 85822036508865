import { Resource } from 'labstep-web/models';
import colDefTemplate from 'labstep-web/components/Entity/DataGrid/colDefs/template';
import ResourceTemplateLayoutLinks from 'labstep-web/components/ResourceTemplate/LayoutLinks';
import React from 'react';

export const colDefResourceTemplate = colDefTemplate(
  Resource.entityName,
  undefined,
  {
    getEntityPreviewProps: (resource) => ({
      bottom: (
        <ResourceTemplateLayoutLinks
          resourceTemplate={resource as Resource}
          withRoute={false}
        />
      ),
    }),
    noLink: true,
  },
);
