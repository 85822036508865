/**
 * Labstep
 *
 * @desc Service to strip sensitive information from payloads
 */

import has from 'lodash/has';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import unset from 'lodash/unset';

const forbiddenPaths = [
  'meta.body.new_password',
  'meta.body.new_password_confirm',
  'meta.body.current_password',
  'meta.body.password',
  'meta.body.username',
  'meta.body.email',
  'meta.body.last_name',
  'meta.body.first_name',
  'meta.params.username',
  'meta.denormalized_payload',
  'meta.userData.password',
];

/**
 * Remove paths from an object
 *
 * @function
 * @param  {any} properties - payload containing object
 * @return {any}
 */
export const removeSensitivePaths = (properties: {
  [key: string]: any;
}) => {
  if (!has(properties, 'meta')) {
    return properties;
  }
  const trackedProperties = cloneDeep(properties);
  forEach(forbiddenPaths, (forbiddenPath) =>
    unset(trackedProperties, forbiddenPath),
  );
  return trackedProperties;
};
