/**
 * Labstep
 *
 * @module components/Experiment/RevisionDocument
 * @desc Component loading experiment with timestamp
 */

import LogList from 'labstep-web/components/Log/List';
import { withActiveGroup } from 'labstep-web/containers';
import ModalDefault from 'labstep-web/core/Modal/Default';
import PremiumFeatureEmptyState from 'labstep-web/core/PremiumFeature/EmptyState';
import {
  Comment,
  Experiment,
  Log,
  ProtocolTable,
} from 'labstep-web/models';
import React, { useState } from 'react';
import Component from './Component';
import styles from './styles.module.scss';
import { ITimeMachineProps } from './types';

const TIME_MACHINE_ENTITIES = [
  Experiment.entityName,
  ProtocolTable.entityName,
  Comment.entityName,
];

export const TimeMachine: React.FC<ITimeMachineProps> = ({
  logEntity,
  documentEntity,
  activeGroup,
}) => {
  const [activeLog, setActiveLog] = useState<Log>(null);
  return (
    <>
      <ModalDefault
        header="Time Machine"
        size="fullscreen"
        open={!!activeLog}
        onClose={(): void => setActiveLog(null)}
        content={(): React.ReactElement => {
          if (
            !activeGroup.organization?.hasFeatureEnabled(
              'time_machine',
            )
          ) {
            return (
              <PremiumFeatureEmptyState premiumFeature="time_machine" />
            );
          }

          return (
            activeLog && (
              <div className={styles.container}>
                <div>
                  <Component
                    key={activeLog.ended_at || activeLog.created_at}
                    entityName={activeLog.log_entry_object_class}
                    id={activeLog.log_entry_object_id}
                    timestamp={
                      activeLog.ended_at || activeLog.created_at
                    }
                  />
                </div>
                <LogList
                  showExternalIcon={false}
                  entity={logEntity}
                  extraParams={{
                    log_entry_object_id:
                      activeLog.log_entry_object_id,
                    log_entry_object_class:
                      activeLog.log_entry_object_class,
                  }}
                  getOnClickFunction={(log) => (): void =>
                    setActiveLog(log)
                  }
                  activeLogId={activeLog && activeLog.id}
                />
              </div>
            )
          );
        }}
        viewComponent={(): React.ReactElement => (
          <LogList
            entity={logEntity}
            getOnClickFunction={(log): (() => void) => {
              if (
                TIME_MACHINE_ENTITIES.includes(
                  log.log_entry_object_class,
                )
              ) {
                return (): void => setActiveLog(log);
              }
              return null;
            }}
          />
        )}
      />
    </>
  );
};

export default withActiveGroup(TimeMachine);
