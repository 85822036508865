/**
 * Labstep
 *
 * @module components/Comment/List
 * @desc List of comments
 */

import React from 'react';
import cx from 'classnames';
import EntitySearchCursor from 'labstep-web/components/Entity/Search/Cursor';
import CommentItem from 'labstep-web/components/Comment/Item';
import { Comment } from 'labstep-web/models';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import styles from './styles.module.scss';
import {
  ICommentListProps,
  ICommentListReadContainerProps,
} from './types';
import CommentListPin from './Pin';

export const CommentList: React.FC<ICommentListProps> = ({
  comments,
  commentItemProps,
  showPin,
  header,
}) => (
  <div className={styles.container}>
    <div className={styles.content}>
      {comments.map((comment) => (
        <div key={`${comment.id}`} className={styles.listItem}>
          <CommentItem
            comment={comment}
            header={header}
            showPin={showPin}
            {...commentItemProps}
          />
        </div>
      ))}
    </div>
  </div>
);

const CommentListReadContainer: React.FC<
  ICommentListReadContainerProps
> = ({
  threadId,
  experimentWorkflow,
  reverse,
  extraReadParams,
  noCommentsMessage,
  ...rest
}) => {
  const params = experimentWorkflow
    ? {
        experiment_workflow_id: experimentWorkflow.id,
        ...extraReadParams,
      }
    : {
        parent_thread_id: threadId,
        ...extraReadParams,
      };
  return (
    <>
      <CommentListPin
        experimentWorkflowId={
          experimentWorkflow ? experimentWorkflow.id : undefined
        }
        threadId={threadId}
        header={rest.header}
      />

      <EntitySearchCursor
        key={JSON.stringify(params)}
        entityName={Comment.entityName}
        reverse={reverse}
        params={params}
        noResultsMessage={noCommentsMessage}
        filters={[
          Filter.deleted,
          Filter.date_range,
          Filter.created_by,
        ]}
      >
        {({ entities }) => (
          <div
            className={cx('commentList')}
            data-testid="comment-list"
          >
            <CommentList comments={entities} {...rest} />
          </div>
        )}
      </EntitySearchCursor>
    </>
  );
};

CommentListReadContainer.defaultProps = {
  reverse: true,
  extraReadParams: { count: 10 },
};

export default CommentListReadContainer;
