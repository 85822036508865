/**
 * Labstep
 *
 * @module components/ResourceItem/Action/UpdateStockCheck/Preview
 * @desc Update Stock Check with Preview for a Resource Item
 */

import React, { useEffect } from 'react';
import { generateNewDateString } from 'labstep-web/services/date.service';
import ResourceItemPreviewStockCheck from 'labstep-web/components/ResourceItem/Preview/StockCheck';
import ActionComponent from 'labstep-web/core/Action/Component';
import { EntityUpdateContainer } from 'labstep-web/containers';
import {
  IResourceItemActionUpdateStockCheckPreviewContainerProps,
  IResourceItemActionUpdateStockCheckPreviewProps,
} from './types';

export const ResourceItemActionUpdateStockCheckPreview: React.FC<
  IResourceItemActionUpdateStockCheckPreviewProps
> = ({ resourceItem, resourceLocation, status, update }) => {
  const isInvalidLocation =
    !resourceItem.resource_location ||
    resourceItem.resource_location.id !== resourceLocation.id;
  const isUnavailable = resourceItem.status === 'unavailable';
  const canUpdate = !isInvalidLocation && !isUnavailable;

  useEffect(() => {
    if (canUpdate) {
      update({ stock_checked_at: generateNewDateString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvalidLocation, isUnavailable]);

  let header = 'Item Checked Successfully!';
  let error = false;
  let additionalActions = null;

  if (!canUpdate) {
    error = true;
    header = isInvalidLocation
      ? 'Warning: Incorrect Location'
      : 'Warning: Marked Unavailable';
    const onClick = () =>
      update(
        isInvalidLocation
          ? { resource_location_id: resourceLocation.id }
          : { status: 'available' },
      );
    const text = isInvalidLocation
      ? 'Update Location'
      : 'Mark As Available';
    additionalActions = (
      <ActionComponent
        onClick={onClick}
        text={text}
        status={status}
        type="button"
        elementProps={{ color: 'red', fluid: true, basic: true }}
      />
    );
  }

  return (
    <ResourceItemPreviewStockCheck
      resourceItem={resourceItem}
      header={header}
      error={error}
      additionalActions={additionalActions}
    />
  );
};

export const ResourceItemActionUpdateStockCheckPreviewContainer: React.FC<
  IResourceItemActionUpdateStockCheckPreviewContainerProps
> = (props) => (
  <EntityUpdateContainer
    entityName={props.resourceItem.entityName}
    id={props.resourceItem.id}
  >
    {(containerProps) => (
      <ResourceItemActionUpdateStockCheckPreview
        {...props}
        {...containerProps}
      />
    )}
  </EntityUpdateContainer>
);

export default ResourceItemActionUpdateStockCheckPreviewContainer;
