/**
 * Labstep
 *
 * @module components/Resource/Form/Edit
 * @desc Resource Edit Form
 */

import React from 'react';
import FormShowEditSearchSelect from 'labstep-web/core/Form/ShowEdit/SearchSelect';
import ResourceLocationFormShowEditSubOuter from 'labstep-web/components/ResourceLocation/Form/ShowEdit/SubOuter';
import ResourceLocationLabel from 'labstep-web/components/ResourceLocation/Label';
import { resourceLocationSearchSelectProps } from 'labstep-web/components/ResourceItem/Form/ShowEdit/ResourceLocation';
import { ResourceLocation } from 'labstep-web/models';
import {
  EntityUpdateContainer,
  withActiveGroup,
} from 'labstep-web/containers';
import { IResourceLocationFormShowEditOuterProps } from './types';
import styles from './styles.module.scss';

export const ResourceLocationFormShowEditOuter: React.FC<
  IResourceLocationFormShowEditOuterProps
> = ({ resourceLocation, activeGroup }) => (
  <div className={styles.container}>
    <FormShowEditSearchSelect
      parentEntity={resourceLocation}
      childEntityName={ResourceLocation.entityName}
      childKeyName="outer_location"
      childIdAttr="guid"
      searchSelectProps={{
        params: {
          group_id: activeGroup?.id,
          not_outer_location_guid: resourceLocation.guid,
        },
        ...resourceLocationSearchSelectProps,
      }}
    >
      {resourceLocation.outer_location && (
        <EntityUpdateContainer
          entityName={resourceLocation.entityName}
          id={resourceLocation.guid}
        >
          {({ update }) => (
            <ResourceLocationLabel
              resourceLocation={resourceLocation.outer_location}
              onSelect={(resource_location_guid) =>
                update({
                  outer_location_guid: resource_location_guid,
                })
              }
            />
          )}
        </EntityUpdateContainer>
      )}
    </FormShowEditSearchSelect>
    <div>
      <ResourceLocationFormShowEditSubOuter
        parentEntity={resourceLocation}
        outerLocation={resourceLocation.outer_location}
        childKeyName="outer_location"
      />
    </div>
  </div>
);

export default withActiveGroup(ResourceLocationFormShowEditOuter);
