/**
 * Labstep
 *
 * @module components/File/Viewer
 * @desc Renders file with appropriate component depending on
 * file extension + Actions to download and preview.
 */

import React from 'react';
import {
  FileUploadContainer,
  ThumbnailContainer,
} from 'labstep-web/containers';
import Loadable from 'labstep-web/core/Loadable';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IFileActionAnnotateProps } from './types';

export const ImageAnnotation = React.lazy(
  () => import('labstep-web/core/ImageAnnotation'),
);

const FileActionAnnotate: React.FC<IFileActionAnnotateProps> = ({
  file,
  viewComponent,
}) =>
  file.isImage ? (
    <Can
      entityName={file.entityName}
      id={file.id}
      action={Action.edit}
    >
      <FileUploadContainer
        entityType={file.entityName}
        entityId={file.id}
      >
        {({ uploadFile }) => (
          <Loadable loader={<span />}>
            <ThumbnailContainer
              file={file}
              params={{ w: 1300 }}
              customFetching
            >
              {({ src, status, fetchUrl }) => (
                <ImageAnnotation
                  // Key needed for https://github.com/Labstep/web/issues/6734
                  key={file.id}
                  src={src}
                  viewComponent={viewComponent}
                  annotationData={
                    file.image_annotation &&
                    file.image_annotation.image_annotation_data
                  }
                  loadingStatus={status}
                  onConfirm={(output, options) => {
                    const outputFile = new File(
                      [output.file],
                      file.name,
                    );
                    uploadFile(
                      {
                        file: outputFile,
                        additionalBody: {
                          annotated_file_id: file.id,
                          image_annotation_data: JSON.stringify(
                            output.data,
                          ),
                        },
                      },
                      { ...options, toast: false },
                    );
                  }}
                  fetchUrl={fetchUrl}
                />
              )}
            </ThumbnailContainer>
          </Loadable>
        )}
      </FileUploadContainer>
    </Can>
  ) : null;

export default FileActionAnnotate;
