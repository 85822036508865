/**
 * Labstep
 *
 * @module components/ResourceItemExport/Table
 * @desc Table listing a preview of resourceItems for export
 */

import React from 'react';
import TableSimple from 'labstep-web/core/Table/Simple';
import ActionComponent from 'labstep-web/core/Action/Component';
import ActionAdd from 'labstep-web/components/ResourceItemExport/Action/AddColumn';
import { ICONS } from 'labstep-web/constants';
import { Resource, ResourceItem } from 'labstep-web/models';
import Header from './Header';
import CustomLabel from './CustomLabel';
import { getCustomMetadataField } from './fields';
import { IResourceItemExportTableProps } from './types';

export const getColumn = (field, activeFields, setActiveFields) => {
  const label = field.custom ? (
    <CustomLabel
      name={field.name}
      placeholder={field.name}
      onSubmit={(value) =>
        setActiveFields([
          ...activeFields.map((activeField) =>
            activeField === field
              ? getCustomMetadataField(
                  field.id,
                  value,
                  field.entityName,
                )
              : activeField,
          ),
        ])
      }
    />
  ) : (
    field.name
  );
  const action = !field.required && (
    <ActionComponent
      type="icon"
      icon={ICONS.entity.actions.remove}
      onClick={() => {
        setActiveFields(
          activeFields.filter((activeField) => activeField !== field),
        );
      }}
    />
  );
  return {
    header: (
      <Header icon={field.icon} label={label} action={action} />
    ),
    content: field.content,
    cellProps: {},
  };
};

export const ResourceItemExportTable: React.FC<
  IResourceItemExportTableProps
> = ({
  resourceItems,
  activeFields,
  setActiveFields,
  additionalFields,
}) => {
  let columns = activeFields.map((field) => {
    return getColumn(field, activeFields, setActiveFields);
  });

  if (additionalFields.length > 0) {
    columns = [
      ...columns,
      {
        header: (
          <ActionAdd
            fields={additionalFields}
            entityNames={[
              ResourceItem.entityName,
              Resource.entityName,
            ]}
            onClick={(field) =>
              setActiveFields([...activeFields, field])
            }
          />
        ),
        content: () => null,
        cellProps: { style: { overflow: 'visible' } },
      },
    ];
  }

  return (
    <TableSimple
      rows={resourceItems}
      columns={columns}
      collapsing
      singleLine
      fixed
      celled
    />
  );
};

export default ResourceItemExportTable;
