/**
 * Labstep
 *
 * @module components/Entity/Action/Update/DropdownPill
 * @desc Entity Update action with Dropdown Pill
 */

import React from 'react';
import DropdownPill from 'labstep-web/core/Dropdown/Pill';
import { EntityUpdateContainer } from 'labstep-web/containers';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IEntityActionUpdateDropdownPillProps } from './types';

export const EntityActionUpdateDropdownPill: React.FC<
  IEntityActionUpdateDropdownPillProps
> = ({ entity, items, disabled = false, currentValue, ...rest }) => {
  const canEdit = useHasAccess(
    entity.entityName,
    entity.idAttr,
    Action.edit,
  );

  return (
    <EntityUpdateContainer
      entityName={entity.entityName}
      id={entity.idAttr}
    >
      {({ update }) => (
        <DropdownPill
          {...rest}
          activeLabel={currentValue}
          disabled={!canEdit || disabled}
          items={items.map((item) => {
            return {
              label: item.label,
              onClick:
                item.onClick ||
                (() => update(item.body, item.options)),
            };
          })}
        />
      )}
    </EntityUpdateContainer>
  );
};

export default EntityActionUpdateDropdownPill;
