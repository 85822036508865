/**
 * Labstep
 *
 * @module components/OrderRequest/ShowEdit/PurchaseOrder
 * @desc ShowEdit for setting purchase order for an order request
 */

import React from 'react';
import EntityLink from 'labstep-web/components/Entity/Link';
import FormShowEditSearchSelect from 'labstep-web/core/Form/ShowEdit/SearchSelect';
import { PurchaseOrder } from 'labstep-web/models';
import { withActiveGroup } from 'labstep-web/containers';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IOrderRequestShowEditPurchaseOrderProps } from './types';

export const OrderRequestShowEditPurchaseOrder: React.FC<
  IOrderRequestShowEditPurchaseOrderProps
> = ({ orderRequest, activeGroup, showIcon }) => {
  const canEdit = useHasAccess(
    orderRequest.entityName,
    orderRequest.id,
    Action.edit,
  );
  return (
    <FormShowEditSearchSelect
      placeholder="Add to order"
      childEntityName={PurchaseOrder.entityName}
      parentEntity={orderRequest}
      searchSelectProps={{
        params: {
          status: 'open',
          group_id: activeGroup?.id,
        },
        createBody: { currency: orderRequest.currency },
        creatable: true,
        allowCreateWithEmptyInput: true,
      }}
      disabled={!(orderRequest.canSetPurchaseOrder && canEdit)}
      customShow={() =>
        orderRequest.purchase_order && (
          <EntityLink
            entity={orderRequest.purchase_order}
            secondary
            showIcon={showIcon}
          />
        )
      }
    >
      {orderRequest.purchase_order && (
        <EntityLink
          entity={orderRequest.purchase_order}
          secondary
          showIcon={showIcon}
        />
      )}
    </FormShowEditSearchSelect>
  );
};

export default withActiveGroup(OrderRequestShowEditPurchaseOrder);
