/**
 * Labstep
 *
 * @module services/bugsnag
 * @desc BugsnagService
 */

import Bugsnag, { BrowserConfig } from '@bugsnag/js';
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from '@bugsnag/plugin-react';
import React from 'react';
import { Client, NotifiableError } from '@bugsnag/browser';
import { APP_VERSION } from 'labstep-web/constants';
import LogRocket from 'labstep-web/services/analytics/logrocket';
import { User } from 'labstep-web/models/user.model';
import { configService } from './config.service';

/** Truncate payload bigger than 10k characters */
const MAX_PAYLOAD_SIZE = 10000;

function instanceOfNotifiableError(
  object: unknown,
): object is NotifiableError {
  return (
    typeof object === 'string' ||
    object instanceof Error ||
    (typeof object === 'object' &&
      object !== null &&
      (('message' in object && 'name' in object) ||
        ('errorClass' in object && 'errorMessage' in object)))
  );
}

export class BugsnagService {
  public static getInstance(): BugsnagService {
    if (!BugsnagService.instance) {
      BugsnagService.instance = new BugsnagService();
    }
    return BugsnagService.instance;
  }

  private static instance: BugsnagService;

  private bugsnagClient?: Client;

  private constructor() {
    const { bugsnagApiKey, labstepEnv, nodeEnv } = configService;
    if (
      !bugsnagApiKey ||
      !labstepEnv ||
      nodeEnv === 'test' ||
      (labstepEnv !== 'production' && labstepEnv !== 'staging')
    ) {
      return;
    }
    const bugsnagOptions: BrowserConfig = {
      apiKey: bugsnagApiKey,
      plugins: [new BugsnagPluginReact(React)],
      releaseStage: labstepEnv,
      appVersion: APP_VERSION,
      collectUserIp: false,
    };
    const bugsnagClient = Bugsnag.start(bugsnagOptions);
    bugsnagClient.leaveBreadcrumb('Starting the web application');
    this.bugsnagClient = bugsnagClient;
  }

  public addMetadata(
    section: string,
    values: Record<string, unknown>,
  ): void {
    if (!this.bugsnagClient) {
      return;
    }
    if (values.payload) {
      const payload = JSON.stringify(values.payload);
      if (payload.length > MAX_PAYLOAD_SIZE) {
        // eslint-disable-next-line no-param-reassign
        values.payload = {
          truncated_payload: payload.substring(0, MAX_PAYLOAD_SIZE),
        };
      }
    }
    this.bugsnagClient.addMetadata(section, values);
  }

  public notify(
    exception: unknown,
    metadatas?: Record<string, unknown> | null,
    severity?: string,
    groupingHash?: string,
  ): void {
    // eslint-disable-next-line no-console
    console.error(exception, metadatas);

    if (!this.bugsnagClient) {
      return;
    }
    if (!instanceOfNotifiableError(exception)) {
      return;
    }
    this.bugsnagClient.notify(exception, (event: any) => {
      if (metadatas) {
        Object.keys(metadatas).forEach((key) => {
          event.addMetadata(key, metadatas[key]);
        });
      }
      if (severity) {
        // eslint-disable-next-line no-param-reassign
        event.severity = severity;
      }
      if (groupingHash) {
        // eslint-disable-next-line no-param-reassign
        event.groupingHash = groupingHash;
      }
    });
    if (exception instanceof Error) {
      LogRocket.captureException(exception);
    }
  }

  public leaveBreadcrumb(
    label: string,
    breadcrumb: Record<string, unknown>,
  ): void {
    if (!this.bugsnagClient) {
      return;
    }
    this.bugsnagClient.leaveBreadcrumb(label, breadcrumb);
  }

  public identify(user: User): void {
    if (!this.bugsnagClient) {
      return;
    }
    const { id, name, username, guid } = user;
    try {
      this.bugsnagClient.setUser(guid);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('e=', e);
    }
    this.addMetadata('user', {
      userId: id,
      name,
      username,
      email: username,
    });
  }

  public getReactPlugin(): BugsnagPluginReactResult | undefined {
    if (!this.bugsnagClient) {
      return undefined;
    }
    return this.bugsnagClient.getPlugin('react');
  }
}

const bugsnagServiceInstance = BugsnagService.getInstance();

export default bugsnagServiceInstance;
