/**
 * Labstep
 *
 * @module prosemirror/nodes/protocol-step
 * @desc ProtocolStep node
 */

import { Node as ProseMirrorNode } from 'prosemirror-model';
import { getAttrsForId } from 'labstep-web/prosemirror/nodes/utils';
import { LabstepNode } from '../types';
import { NodeKeys } from '../constants';
import { enterParagraphAfterStep } from './commands';

const NodeStep: LabstepNode = {
  key: NodeKeys.protocol_step,
  spec: {
    attrs: {
      id: { default: null },
      guid: { default: null },
    },
    isolating: true,
    // defining: true,
    content: 'block+',
    group: 'step',
    draggable: true,
    parseDOM: [
      { tag: 'div', getAttrs: getAttrsForId(NodeKeys.protocol_step) },
    ],
    toDOM(node: ProseMirrorNode) {
      const { id, guid } = node.attrs;
      return [
        'div',
        {
          [`data-${NodeStep.key}-id`]: id,
          [`data-${NodeStep.key}-guid`]: guid,
        },
        0,
      ];
    },
  },
  keymap: {
    Enter: enterParagraphAfterStep,
  },
};

export default NodeStep;
