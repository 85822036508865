/**
 * Labstep
 *
 * @module components/OrganizationPlan/Item
 * @desc Action to open stripe billing portal
 */

import { plans } from 'labstep-web/constants';
import PricingTierCard from 'labstep-web/core/PricingTier/Card';
import { CurrencyOption } from 'labstep-web/models';
import CurrencyService, {
  Currency,
} from 'labstep-web/services/currency.service';
import { PricingService } from 'labstep-web/services/pricing.service';
import React, { useCallback } from 'react';
import styles from './styles.module.scss';
import { IOrganizationPlanItemProps } from './types';

export const getCustomLabel = (tier: string): React.ReactNode => {
  if (tier === 'academic_free') {
    return 'Free';
  }

  if (tier === 'academic_institute') {
    return (
      <span className={styles.contact}>
        Contact{' '}
        <a href="mailto:sales@labstep.com">sales@labstep.com</a>
      </span>
    );
  }

  return null;
};

export const getPriceMonthly = (
  tier: string,
  formatPrice: (unitAmount: number) => string,
  currencyOption: CurrencyOption | null,
): string | null => {
  if (tier === 'academic_free') {
    return null;
  }

  return currencyOption
    ? formatPrice(currencyOption.unit_amount)
    : '';
};

export const OrganizationPlanItem: React.FC<
  IOrganizationPlanItemProps
> = ({
  organizationPlanPrice,
  onClick,
  isSelected,
  priceCurrency,
}) => {
  const formatPrice = useCallback(
    (unitAmount: number) =>
      CurrencyService.print(
        organizationPlanPrice.interval === 'year'
          ? unitAmount / 12
          : unitAmount,
        priceCurrency.toUpperCase() as Currency,
        false,
      ),
    [priceCurrency, organizationPlanPrice.interval],
  );

  const plan =
    plans[organizationPlanPrice.tier as keyof typeof plans];

  if (!plan) {
    return null;
  }

  const currencyOption = organizationPlanPrice.currency_options
    ? organizationPlanPrice.currency_options[
        priceCurrency as keyof typeof organizationPlanPrice.currency_options
      ]
    : null;

  const priceBeforeDiscount = currencyOption
    ? PricingService.getPriceBeforeDiscount(
        currencyOption.unit_amount,
        organizationPlanPrice.interval,
      )
    : null;

  return (
    <PricingTierCard
      logoSrc={plan.logoSrc}
      onClick={() => onClick(organizationPlanPrice.id)}
      description={plan.description}
      billingPeriod={organizationPlanPrice.interval}
      priceColor={
        organizationPlanPrice.interval === 'year' ? 'green' : 'blue'
      }
      customLabel={getCustomLabel(organizationPlanPrice.tier)}
      priceMonthly={getPriceMonthly(
        organizationPlanPrice.tier,
        formatPrice,
        currencyOption,
      )}
      priceBeforeDiscount={
        priceBeforeDiscount
          ? formatPrice(priceBeforeDiscount)
          : undefined
      }
      items={plan.features}
      isSelected={isSelected}
    />
  );
};

export default OrganizationPlanItem;
