/**
 * Labstep
 *
 * @module screens/ProtocolStep/Show/Thread
 * @desc ProtocolStep show thread
 */

import React from 'react';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { ProtocolStep } from 'labstep-web/models';
import ThreadForm from 'labstep-web/components/Thread/Form';
import { EntityReadEntityContainer } from 'labstep-web/containers';
import { IProtocolStepShowThreadProps } from './types';

export const ProtocolStepShowThreadName = ({ id, parent }) => (
  <EntityReadEntityContainer
    entityName={ProtocolStep.entityName}
    id={id}
  >
    {({ entity }) =>
      entity ? (
        <span>{`Notes on Step ${entity.getStepNumber(parent)}`}</span>
      ) : null
    }
  </EntityReadEntityContainer>
);

const ProtocolStepShowThread: React.FC<
  IProtocolStepShowThreadProps
> = ({ id }) => (
  <ReadOnMountHOC
    type="entities"
    loading={{ loader: 'placeholder', cached: true }}
    entityName={ProtocolStep.entityName}
    params={{
      get_single: 1,
      id,
    }}
    children={({ entity: protocolStep }) => (
      <ThreadForm
        readThreadIds={[protocolStep.thread.id]}
        createThreadId={protocolStep.thread.id}
        entityNameLabel="notes"
      />
    )}
  />
);

export default ProtocolStepShowThread;
