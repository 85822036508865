/**
 * Labstep
 *
 * @module components/ProtocolTimer/Notifier
 * @desc A component to notify user when alert is running low (under 10s)
 */

import React from 'react';
import { ProtocolTimerItemContainer } from 'labstep-web/containers';
import ProtocolTimerAlert from 'labstep-web/components/ProtocolTimer/Alert';
import { IProtocolAlertTimerProps } from './types';

export const ProtocolAlertTimer: React.FC<
  IProtocolAlertTimerProps
> = ({ protocol }) => (
  <>
    {protocol.protocol_timers.map((protocolTimer) => (
      <ProtocolTimerItemContainer
        key={protocolTimer.guid}
        protocolTimer={protocolTimer}
      >
        {({ timeRemaining }) => (
          <ProtocolTimerAlert
            protocolTimer={protocolTimer}
            protocol={protocol}
            timeRemaining={timeRemaining}
          />
        )}
      </ProtocolTimerItemContainer>
    ))}
  </>
);

export default ProtocolAlertTimer;
