/**
 * Labstep
 *
 * @module components/Chemical/Form/ShowEdit/Equivalence
 * @desc ShowEditEquivalence for Chemical
 */

import React from 'react';
import FormShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import { Chemical } from 'labstep-web/models';
import { fieldEquivalence } from 'labstep-web/components/Chemical/Form/fields';
import { IChemicalFormShowEditEquivalenceProps } from './types';

export const ChemicalFormShowEditEquivalence: React.FC<
  IChemicalFormShowEditEquivalenceProps
> = ({ chemical }) => (
  <FormShowEditText
    entityName={Chemical.entityName}
    entity={chemical}
    field={fieldEquivalence}
    placeholder="Enter"
  >
    {chemical.equivalence}
  </FormShowEditText>
);

export default ChemicalFormShowEditEquivalence;
