/**
 * Labstep
 *
 * @module components/User/Settings/MarketingConsent
 * @desc GDPR consent
 */

import React from 'react';
import { generateNewDateString } from 'labstep-web/services/date.service';
import Checkbox from 'labstep-web/core/Checkbox';
import { EntityUpdateContainer } from 'labstep-web/containers';
import { IUserSettingsMarketingConsentProps } from './types';

export const UserSettingsMarketingConsent: React.FC<
  IUserSettingsMarketingConsentProps
> = ({ user }) => (
  <EntityUpdateContainer entityName="user" id={user.id}>
    {({ update, status }) => (
      <Checkbox
        label="I’d like to receive updates about new cool features"
        checked={!!user.consented_at}
        onChange={() =>
          update({
            consented_at: user.consented_at
              ? null
              : generateNewDateString(),
          })
        }
        disabled={status && status.isFetching}
      />
    )}
  </EntityUpdateContainer>
);

export const UserSettingsMarketingConsentContainer: React.FC<
  IUserSettingsMarketingConsentProps
> = ({ user }) => (
  <div id="marketing-consent">
    <div>
      <span>Marketing and newsletter consent</span>
    </div>
    <UserSettingsMarketingConsent user={user} />
  </div>
);

export default UserSettingsMarketingConsentContainer;
