/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Sign
 * @desc Action to sign signature
 */

import React from 'react';
import { generateNewDateString } from 'labstep-web/services/date.service';
import ActionComponent from 'labstep-web/core/Action/Component';
import { EntityUpdateContainer } from 'labstep-web/containers';
import { ISignatureActionRevokeProps } from './types';

export const SignatureActionRevoke: React.FC<
  ISignatureActionRevokeProps
> = ({ signature, experimentWorkflow }) =>
  !signature.revoked_at &&
  experimentWorkflow.allowed_actions.includes('edit') ? (
    <EntityUpdateContainer
      entityName={signature.entityName}
      id={signature.id}
    >
      {({ update, status }) => (
        <ActionComponent
          icon="times"
          type="option"
          text="Revoke"
          status={status}
          onClick={() => {
            update({ revoked_at: generateNewDateString() });
          }}
        />
      )}
    </EntityUpdateContainer>
  ) : null;

export default SignatureActionRevoke;
