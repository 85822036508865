/**
 * Labstep
 *
 * @module screens/PermaLink
 * @desc PermaLink Screen
 */

import React from 'react';
import Redirect from 'labstep-web/core/Redirect';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import {
  IPermaLinkShowScreenContainerProps,
  IPermaLinkShowScreenProps,
} from './types';

export const PermaLinkShowScreen: React.FC<
  IPermaLinkShowScreenProps
> = ({ permaLink }) => (
  <Redirect
    to={`${permaLink.parent_type}_show`}
    params={{ id: permaLink.parent_id }}
  />
);

const PermaLinkShowScreenContainer: React.FC<
  IPermaLinkShowScreenContainerProps
> = ({ match }) => {
  const { token } = match.params;

  return (
    <ReadOnMountHOC
      type="entity"
      entityName="perma_link"
      id={token}
      loading={{ loader: 'main_content' }}
      children={({ entity }) => (
        <PermaLinkShowScreen permaLink={entity} />
      )}
    />
  );
};

export default PermaLinkShowScreenContainer;
