/**
 * Labstep
 *
 * @module state/reducers/profiles
 * @desc Reducers for Profile
 */

import { Action } from 'labstep-web/models';
import { getEntityReducers } from './entity';
import { failStatus, receiveStatus, requestStatus } from './helpers';

/**
 * Confirm user email
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {array} entities - Array of entities
 * @return {object}
 */
export const confirmingIds = (state: any = {}, action: Action) => {
  switch (action.type) {
    case 'REQUEST_CONFIRM_TOKEN':
      return requestStatus(state, action, action.meta.token);
    case 'SUCCESS_CONFIRM_TOKEN':
      return receiveStatus(state, action, action.meta.token);
    case 'FAIL_CONFIRM_TOKEN':
      return failStatus(state, action, action.meta.token);
    default:
      return state;
  }
};

/**
 * Resend email confirmation
 *
 * @function
 * @param  {object} state - Redux state (only the portion for this reducer)
 * @param  {array} entities - Array of entities
 * @return {object}
 */
export const confirmationEmail = (
  state: any = {},
  action: Action,
) => {
  switch (action.type) {
    case 'REQUEST_RESEND_CONFIRMATION_EMAIL':
      return requestStatus(state, action, 'confirmation_email');
    case 'SUCCESS_RESEND_CONFIRMATION_EMAIL':
      return receiveStatus(state, action, 'confirmation_email');
    case 'FAIL_RESEND_CONFIRMATION_EMAIL':
      return failStatus(state, action, 'confirmation_email');
    default:
      return state;
  }
};

export default getEntityReducers('user', {
  confirmingIds,
  confirmationEmail,
});
