/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Info/StartedAt
 * @desc Showing experimentWorkflow.due_at
 */

import React from 'react';
import ExperimentWorkflowInfoDateAt from 'labstep-web/components/ExperimentWorkflow/Info/DateAt';
import { IExperimentWorkflowInfoStartedAtProps } from './types';

export const ExperimentWorkflowInfoStartedAt: React.FC<
  IExperimentWorkflowInfoStartedAtProps
> = ({ experimentWorkflow }) => (
  <ExperimentWorkflowInfoDateAt
    experimentWorkflow={experimentWorkflow}
    field={
      experimentWorkflow.status !== 'planning'
        ? 'started_at'
        : 'start_planned_at'
    }
    label={
      experimentWorkflow.status !== 'planning'
        ? 'started'
        : 'planned start'
    }
    dateTimePickerOptions={{
      maxDate: experimentWorkflow.activeEnd,
    }}
    popup={
      experimentWorkflow.activeStart &&
      (experimentWorkflow.status !== 'planning'
        ? 'Date started'
        : 'Planned Start Date')
    }
  />
);

export default ExperimentWorkflowInfoStartedAt;
