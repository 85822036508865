/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/fields
 * @desc fields for protocol value
 */

import { fieldResource } from 'labstep-web/components/OrderRequest/Form/fields';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import rules from 'labstep-web/services/validation/rules';
import pick from 'lodash/pick';
import {
  INPUT_OUTPUT_TYPES,
  ProtocolCollection,
  ProtocolValue,
  Resource,
} from 'labstep-web/models';
import { unitsAsOptions } from 'labstep-web/constants/unit';

export const getProtocolValueDefaultValues = (
  protocolValue: ProtocolValue,
) => {
  const defaultValues: any = pick(protocolValue, [
    'name',
    'resource',
    'amount',
    'unit',
    'is_lineage',
  ]);

  defaultValues.type = protocolValue.inputOutputTypeOption;

  return defaultValues;
};

export const protocolValueInputOutputType: IFieldWithoutActionProps =
  {
    name: 'type',
    placeholder: 'Select type',
    fieldLabel: 'Type',
    fieldExplainer: `Input or output.`,
    validation: rules.protocol_value.inputOutputType,
    fieldType: FieldType.ReactSelect,
    elementProps: {
      options: INPUT_OUTPUT_TYPES,
    },
  };

export const protocolValueIsLineage: IFieldWithoutActionProps = {
  name: 'is_lineage',
  fieldLabel: 'Lineage tracking',
  fieldExplainer: `If you check this box, items specified will appear in lineage tracking.`,
  fieldType: FieldType.Checkbox,
  validation: rules.protocol_value.is_lineage,
};

export const protocolValueFieldName: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'name',
  placeholder: 'Enter field name',
  fieldLabel: 'Field Name',
  fieldExplainer: `Field name for this ${ProtocolValue.getHumanReadableEntityName()} i.e. Sample, Forward Primer, Solvent etc.`,
  validation: rules.protocol_value.name,
};

export const protocolValueFieldAmount: IFieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'amount',
  placeholder: 'Enter amount',
  fieldLabel: 'Amount',
  fieldExplainer: `The amount used / produced of this ${ProtocolValue.getHumanReadableEntityName()} in the ${ProtocolCollection.getHumanReadableEntityName()}.`,
  validation: rules.protocol_value.amount,
};

export const getProtocolValueFieldUnit = (
  isChemical = false,
  additionalUnit?: string,
): IFieldWithoutActionProps => ({
  name: 'unit',
  placeholder: 'Enter units',
  fieldLabel: 'Units',
  fieldExplainer: `Units for the amount specified`,
  validation: rules.protocol_value.unit,
  fieldType: FieldType.ReactSelect,
  elementProps: {
    options: unitsAsOptions(isChemical, additionalUnit),
    creatable: !isChemical,
  },
});

export const protocolValueFieldResource: IFieldWithoutActionProps = {
  ...fieldResource,
  fieldLabel: `Default ${getHumanReadableEntityName(
    Resource.entityName,
    false,
    true,
  )} (optional)`,
  fieldExplainer: `Match to a ${getHumanReadableEntityName(
    Resource.entityName,
  )} from your inventory that can be used with this ${ProtocolCollection.getHumanReadableEntityName()}. Leave blank for variable resources i.e samples`,
  validation: rules.protocol_value.resource,
};
