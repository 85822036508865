/**
 * Labstep
 *
 * @module services/analytics/actions
 * @desc All analytic actions (Unified api for all different analytic services)
 */

import { identify as identifyLogRocket } from './logrocket';

/**
 * Identify user
 * @param  {string} username - User's username
 * @param  {string} name - User's name
 */
const identify = (user: any) => {
  identifyLogRocket(user.username, user.name);
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const pageview = () => {
  // Do nothing
};

const track = () => {
  // const curatedProperties = removeSensitivePaths(properties);
};

export default {
  identify,
  track,
  pageview,
};
