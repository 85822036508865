/**
 * Labstep
 */

import { OrderRequest, OrderRequestStatus } from 'labstep-web/models';

export const isReceivedOrPartiallyReceived = (
  selectedStatus: string,
): boolean => {
  return (
    selectedStatus === OrderRequestStatus.partially_received ||
    selectedStatus === OrderRequestStatus.received
  );
};

export const isDifferentStatusClicked = (
  orderRequest: OrderRequest,
  selectedStatus: string,
): boolean => {
  return orderRequest.status !== selectedStatus;
};

export const shouldShowConfirmationModal = (
  orderRequest: OrderRequest,
  selectedStatus: string,
): boolean => {
  return (
    isReceivedOrPartiallyReceived(selectedStatus) &&
    isDifferentStatusClicked(orderRequest, selectedStatus) &&
    orderRequest.hasItemsRemaining
  );
};
