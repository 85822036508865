/**
 * Labstep
 *
 * @module components/Metadata/Item
 * @desc Experiment Value item
 */

import React from 'react';
import ElementCard from 'labstep-web/core/Card/Element';
import ProtocolChildRouter from 'labstep-web/containers/Router/ProtocolChild';
import MetadataThreadComponent from 'labstep-web/components/Metadata/Thread';
import MetadataFormShowEditValueWithClear from 'labstep-web/components/Metadata/Form/ShowEdit/Value/WithClear';
import ProtocolConditionActionViewVariablesTable from 'labstep-web/components/ProtocolCondition/Action/ViewVariablesTable';
import Flex from 'labstep-web/core/Flex';
import MetadataActionMenu from 'labstep-web/components/Metadata/Action/Menu';
import MetadataRequired from 'labstep-web/components/Metadata/Required';
import { ICONS } from 'labstep-web/constants';
import { Experiment, ProtocolCondition } from 'labstep-web/models';
import { METADATA_DEFAULT_LABEL_DATA } from 'labstep-web/models/metadata/constants';
import { IMetadataCardProps } from './types';

export const MetadataCard: React.FC<IMetadataCardProps> = ({
  metadata,
  entity,
  isTemplate,
  additionalActions,
  hideActions,
  dragHandle,
}) => (
  <ProtocolChildRouter route="metadata_show" id={metadata.id}>
    {({ navigate }): React.ReactElement => (
      <ElementCard
        onClick={navigate}
        icon={ICONS.metadata.default}
        name={metadata.label || METADATA_DEFAULT_LABEL_DATA}
        detail={
          metadata.is_variable ? (
            <ProtocolConditionActionViewVariablesTable
              protocol={entity}
              actionComponentProps={{
                type: 'text',
                icon: 'external',
                text: `${
                  entity.n_protocol_conditions
                } ${ProtocolCondition.getHumanReadableEntityName(
                  true,
                )}`,
              }}
            />
          ) : (
            <MetadataFormShowEditValueWithClear
              key={metadata.type}
              entity={entity}
              metadata={metadata}
            />
          )
        }
        actions={
          !hideActions && (
            <Flex wrap style={{ alignItems: 'baseline' }}>
              <span>{metadata.inputOutputTypeLabel}</span>
              <div style={{ width: 10 }} />
              <MetadataRequired metadata={metadata} />
              {!isTemplate && entity instanceof Experiment && (
                <>
                  <div style={{ width: 10 }} />
                  <MetadataThreadComponent metadata={metadata} />
                </>
              )}
              <MetadataActionMenu
                entity={entity}
                metadata={metadata}
                actions={['edit', 'delete']}
              />
              <>{additionalActions}</>
            </Flex>
          )
        }
        dragHandle={dragHandle}
      />
    )}
  </ProtocolChildRouter>
);

export default MetadataCard;
