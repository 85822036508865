/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Action/ProtocolValue
 * @desc Convert selected text into an ProtocolValue node
 */

import React from 'react';
import { EntityCreateContainer } from 'labstep-web/containers';
import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import { getSelectedText } from 'labstep-web/prosemirror/utils';
import NodeProtocolValue from 'labstep-web/prosemirror/nodes/protocol-value';
import { ProtocolValue } from 'labstep-web/models';
import { EditorView } from 'prosemirror-view';
import { ICONS } from 'labstep-web/constants';
import { ICreateAction, IResponse } from 'labstep-web/typings';
import { IMenuTextActionProtocolValueProps } from './types';

export const createProtocolValueNode = (
  view: EditorView,
  response: IResponse,
): void => {
  const { tr } = view.state;
  const { key } = NodeProtocolValue;
  const node = view.state.schema.nodes[key].create({
    id: response.entities[key][response.result].id,
    guid: response.entities[key][response.result].guid,
  });
  tr.setStoredMarks([]);
  tr.replaceSelectionWith(node);
  view.dispatch(tr);
  view.focus();
};

export const createProtocolValue = (
  view: EditorView,
  create: ICreateAction,
): void => {
  const selectedText = getSelectedText(view.state);
  if (selectedText && selectedText.length > 0) {
    create(
      {
        name: selectedText,
      },
      {
        additionalMeta: { redirect: true },
        onSuccess: ({ response }) =>
          createProtocolValueNode(view, response),
      },
    );
  }
};

const MenuTextActionProtocolValue: React.FC<
  IMenuTextActionProtocolValueProps
> = ({ view, protocol }) => (
  <EntityCreateContainer
    entityName={ProtocolValue.entityName}
    parentName={protocol.entityName}
    parentId={protocol.id}
  >
    {({ create, status }): React.ReactElement => (
      <Button
        onClick={(): void => createProtocolValue(view, create)}
        status={status}
      >
        <Icon name={ICONS.resource.primary} />
        <span>Inventory</span>
      </Button>
    )}
  </EntityCreateContainer>
);

export default MenuTextActionProtocolValue;
