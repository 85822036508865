/**
 * Labstep
 *
 * @module screens/Tag/Index/LeftPane
 * @desc Tag Index LeftPane
 */

import TagFilterPane from 'labstep-web/components/Tag/Filter/Pane';
import LayoutLeftPane from 'labstep-web/components/Layout/LeftPane';
import React from 'react';

export const TagIndexLeftPane: React.FC = () => (
  <LayoutLeftPane>
    <TagFilterPane />
  </LayoutLeftPane>
);

export default TagIndexLeftPane;
