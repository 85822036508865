/**
 * Labstep
 *
 * @module components/ProtocolStep/Item
 * @desc ProtocolStep Item
 */

import React from 'react';
import LinkButton from 'labstep-web/core/Link/Button';
import SubHeader from 'labstep-web/core/SubHeader';
import { Desktop } from 'labstep-web/core/MediaQuery';
import { ICONS } from 'labstep-web/constants';
import { CompleteAction } from 'labstep-web/components/ProtocolStep/Card/Detail';
import ElementCard from 'labstep-web/core/Card/Element';
import { Experiment } from 'labstep-web/models';
import styles from './styles.module.scss';
import { IProtocolStepItemProps } from './types';

export const ProtocolStepItem: React.FC<IProtocolStepItemProps> = ({
  protocolStep,
  protocol,
  protocolCollection,
  index,
}) => {
  const stepNumber = index + 1;
  const elementCardProps =
    protocol instanceof Experiment
      ? {
          customIcon: (
            <CompleteAction
              protocol={protocol}
              protocolStep={protocolStep}
            />
          ),
          name: (
            <div
              onClick={(evt) => evt.preventDefault()}
              className={styles.previewContainer}
            >
              <SubHeader>{`STEP ${stepNumber}`}</SubHeader>
            </div>
          ),
        }
      : {
          icon: ICONS.protocol.step,
          name: `Step ${stepNumber}`,
        };

  const redirectEntity = protocolCollection || protocol;

  return (
    <Desktop>
      {({ match }) => (
        <LinkButton
          route={{
            to: match
              ? `${redirectEntity.entityName}_show_steps`
              : `${redirectEntity.entityName}_show`,
            params: { id: redirectEntity.id },
            query: { step_id: protocolStep.guid },
          }}
        >
          <ElementCard {...elementCardProps} />
        </LinkButton>
      )}
    </Desktop>
  );
};

export default ProtocolStepItem;
