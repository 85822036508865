/**
 * Labstep
 *
 * @module components/Table/Card
 * @desc Card component for Table
 */

import React from 'react';
import Loadable from 'labstep-web/core/Loadable';
import AutoSaveSpinner from 'labstep-web/core/AutoSave/Spinner';
import CollapsibleCard from 'labstep-web/core/Collapsible/Card';
import { ITableCardProps } from './types';

export const SpreadsheetInline = React.lazy(
  () => import('labstep-web/core/SpreadJS/spreadsheet-inline'),
);

export const TableCardItem: React.FC<ITableCardProps> = ({
  name,
  debouncingStatus,
  actions,
  data,
  readOnly,
  onChange,
  hideIcon,
  emptyState,
  allowLocking,
  isExpanded,
  ...rest
}) => (
  <CollapsibleCard.Item
    icon={hideIcon ? undefined : 'table'}
    name={name}
    disabled={isExpanded}
    actions={() => (
      <>
        <AutoSaveSpinner status={debouncingStatus} /> {actions}
      </>
    )}
    content={
      emptyState && !data ? (
        emptyState
      ) : (
        <Loadable>
          <SpreadsheetInline
            data={data}
            readOnly={readOnly}
            onChange={onChange}
            showToolbar={isExpanded}
            allowLocking={allowLocking}
            {...rest}
          />
        </Loadable>
      )
    }
  />
);

export const TableCard: React.FC<ITableCardProps> = (props) => (
  <div
    onKeyDown={(e) => {
      e.stopPropagation();
    }}
    onClick={(e) => {
      e.stopPropagation();
    }}
    onDoubleClick={(e) => {
      e.stopPropagation();
    }}
  >
    <CollapsibleCard>
      <TableCardItem {...props} />
    </CollapsibleCard>
  </div>
);

export default TableCard;
