/**
 * Labstep
 *
 * @module screens/Authentication/Columns
 * @desc Shared Columns Layout
 */

import React from 'react';
import classnames from 'classnames';
import Logo from 'labstep-web/core/Logo';
import Header from 'labstep-web/core/Header';
import Link from 'labstep-web/core/Link';
import styles from './styles.module.scss';
import { IColumnsProps } from './types';

const Columns: React.FC<IColumnsProps> = ({
  right,
  left,
  inverted,
  header,
  explainer,
  logo,
  centerAlign = true,
}) => (
  <div className={styles.container}>
    <div className={classnames(styles.grid)}>
      <div className={classnames(styles.column, styles.leftColumn)}>
        <div
          className={classnames(styles.topbar, {
            [styles.inverted]: inverted,
            [styles.fixed]: centerAlign,
          })}
        >
          <Link to="app_homepage">
            {logo || <Logo size="medium" blue={!inverted} />}
          </Link>
        </div>
        <div
          className={classnames({
            [styles.centerAlign]: centerAlign,
          })}
        >
          {left}
        </div>
      </div>
      <div
        className={classnames(styles.column, styles.contentColumn)}
      >
        <div>
          <div className={styles.header}>
            <Header size="small">{header}</Header>
            <p>{explainer}</p>
          </div>
          <div className={styles.right}>{right}</div>
        </div>
      </div>
    </div>
  </div>
);

export default Columns;
