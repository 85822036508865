/**
 * Labstep
 *
 * @module components/Filter/Deleted
 * @desc Filter to toggle between deleted items
 */

import { ICONS } from 'labstep-web/constants';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import { Metadata } from 'labstep-web/models';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import FilterMetadataForm from './Form';
import { IFilterMetadataProps } from './types';

const metadataNameCapitalised = Metadata.getHumanReadableEntityName(
  false,
  true,
);

const FilterMetadata: React.FC<IFilterMetadataProps> = ({
  entityName,
  childEntityName,
  addPostFilter,
  actionComponentProps,
  defaultValues,
  replace,
  addAnother,
  ...rest
}) => (
  <Modal
    header={`Filter by ${metadataNameCapitalised}`}
    viewComponent={({ toggleModal }): React.ReactNode => (
      <ActionComponent
        onClick={toggleModal}
        type="option"
        icon={ICONS.metadata.secondary}
        cornerIcon={ICONS[childEntityName || entityName].primary}
        text={`${getHumanReadableEntityName(
          childEntityName || entityName,
          false,
          true,
        )} ${metadataNameCapitalised}`}
        {...actionComponentProps}
      />
    )}
    content={({ toggleModal }): React.ReactNode => (
      <FilterMetadataForm
        addPostFilter={addPostFilter}
        path={
          childEntityName === 'resource'
            ? 'resource.metadatas'
            : 'metadatas'
        }
        onDone={toggleModal}
        defaultValues={defaultValues}
        replace={replace}
        addAnother={addAnother}
      />
    )}
    {...rest}
  />
);

export default FilterMetadata;
