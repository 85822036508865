import { ORGANIZATION_IDENTIFIERS } from 'labstep-web/constants/organization-identifiers';
import { selectAuthenticatedUserEntity } from 'labstep-web/state/selectors';

const TIMEOUTS = {
  [ORGANIZATION_IDENTIFIERS.ochrebio]: 8 * 3600 * 1000,
  // [ORGANIZATION_IDENTIFIERS.labstep]: 10 * 60 * 1000,
};

export const logoutAction = {
  type: 'START_LOGOUT',
  meta: {
    reason: 'TIMEOUT_LOGOUT',
  },
};

/**
 * @description Fires logout action if timeout time has expired
 * This needs to be a middleware rather than an epic because epics
 * always run through your reducers before your Epics even receive them.
 */
export const timeoutLogout = (store) => (next) => (action) => {
  const state = store.getState();
  if (
    !(
      action.type.includes('REQUEST') ||
      action.type.includes('PREPARE')
    ) ||
    action.type === 'START_LOGOUT'
  ) {
    return next(action);
  }
  const authenticatedUser = (state as any).authenticatedUser.byId;
  if (!authenticatedUser?.id) {
    return next(action);
  }
  const authenticatedUserEntity =
    selectAuthenticatedUserEntity(state);
  const identifier =
    authenticatedUserEntity.userOrganization?.organization.identifier;
  if (!identifier) {
    return next(action);
  }
  const timeout = TIMEOUTS[identifier];

  if (!timeout) {
    return next(action);
  }
  const lastActionTimestamp = (state as any).last_action_timestamp;

  if (lastActionTimestamp === null) {
    return next(action);
  }
  if (Date.now() - lastActionTimestamp > timeout) {
    return next(logoutAction);
  }
  return next(action);
};
