/**
 * Labstep
 *
 * @module components/ProtocolCollection/Info/LockMessage
 * @desc Display warning if protocol is locked for editing
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import { ICONS } from 'labstep-web/constants';
import { CanCreate } from 'labstep-web/components/Entity/Can';
import { ILockMessageProps } from './types';
import styles from './styles.module.scss';

const LockMessage: React.FC<ILockMessageProps> = ({
  protocol,
  protocolCollection,
}) =>
  !!protocol.all_experiment_count || !protocol.is_last_version ? (
    <div className={styles.message}>
      <Icon name={ICONS.experiment_workflow.actions.lock} />
      <span id="lock-message">
        This version is now locked.
        <CanCreate
          parentName={protocolCollection.entityName}
          parentId={protocolCollection.id}
          entityName="protocol"
        >
          {protocolCollection.new_version_required && (
            <span> Create a new version to edit.</span>
          )}
        </CanCreate>
      </span>
    </div>
  ) : null;

export default LockMessage;
