/**
 * Labstep
 *
 * @module state/reducers/entity/togglingIds;
 * @desc Reducer for toggled entities
 */

import { getToggleKey } from 'labstep-web/state/actions';
import {
  failStatus,
  receiveStatus,
  requestStatus,
} from 'labstep-web/state/reducers/helpers';

/**
 * Returns reducer for toggled entities
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} action - Redux action
 */
const togglingIds =
  (entityName: string) =>
  (state: any = {}, action: any): any => {
    if (action.type && action.type.includes('_RAW_OUTPUT')) {
      return state;
    }

    if (
      !(
        action.type &&
        (action.type.includes('FROM_') || action.type.includes('TO_'))
      )
    ) {
      return state;
    }

    if (action.meta.parentName !== entityName) {
      return state;
    }

    const identifier = getToggleKey(
      action.meta.entityName,
      action.meta.entityIds,
      action.meta.parentName,
      action.meta.parentId,
    );

    if (action.type.startsWith('REQUEST')) {
      return requestStatus(state, action, identifier);
    }
    if (action.type.startsWith('SUCCESS')) {
      return receiveStatus(state, action, identifier);
    }
    if (action.type.startsWith('FAIL')) {
      return failStatus(state, action, identifier);
    }
    return state;
  };

export default togglingIds;
