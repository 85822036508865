/**
 * Labstep
 *
 * @module components/Tag/Card/EmptyState
 * @desc Empty state for tag
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import TagActionCreate from 'labstep-web/components/Tag/Action/Create';

export const TagEmptyState: React.FC = () => (
  <EmptyState
    src="/img/empty-state/files.svg"
    title="No tags"
    explanation="Use tags to organise the content within your workspace. Tags can be used to differentiate projects, custom statuses, or research areas. Filter by tags when searching for content. "
    action={<TagActionCreate />}
  />
);

export default TagEmptyState;
