/**
 * Labstep
 *
 * @module containers/Organization/BillingPortal
 * @desc Container to get organization stripe billing portal
 */

import { connect } from 'react-redux';
import { IOptions } from 'labstep-web/typings';
import {
  confirmIntentSepaDebitAction,
  getOrganizationSubscriptionPortalAction,
  setupIntentSepaDebitAction,
} from 'labstep-web/state/actions';
import { OrganizationBillingPortalContainerProps } from './types';

const Container = ({ children, ...rest }) => children(rest);

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getOrganizationSubscriptionPortal(body: any, options: IOptions) {
    dispatch(getOrganizationSubscriptionPortalAction(body, options));
  },
  setupIntentSepaDebitAction(id, body, options) {
    dispatch(setupIntentSepaDebitAction(id, body, options));
  },
  confirmIntentSepaDebitAction(id, options) {
    dispatch(confirmIntentSepaDebitAction(id, options));
  },
});

export const OrganizationBillingPortalContainer: React.FC<OrganizationBillingPortalContainerProps> =
  connect(mapStateToProps, mapDispatchToProps)(Container);
