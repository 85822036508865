/**
 * Labstep
 *
 * @module core/Dropdown/Pill/Trigger
 * @desc Dropdown Pill component
 */

import React from 'react';
import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import styles from './styles.module.scss';
import { IDropdownPillTriggerProps } from './types';

const DropdownPillTrigger: React.FC<IDropdownPillTriggerProps> = ({
  activeLabel,
  disabled,
  triggerClassName,
  triggerIcon,
}) => (
  <div className={classnames(styles.pill, triggerClassName)}>
    {triggerIcon && <Icon name={triggerIcon} />} {activeLabel}{' '}
    {!disabled && <Icon name="chevron down" />}
  </div>
);

export default DropdownPillTrigger;
