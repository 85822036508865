/**
 * Labstep
 *
 * @module components/File/Action/Upload
 * @desc Button to upload files to comment
 */

import React from 'react';
import { IDropzoneProps } from 'labstep-web/core/Dropzone/types';
import GuardGroupCreate from 'labstep-web/components/Guard/GroupCreate';
import { FileUploadContainer } from 'labstep-web/containers';
import Dropzone from 'labstep-web/core/Dropzone';
import {
  IFileActionUploadContainerProps,
  IFileActionUploadProps,
} from './types';

export const FileActionUpload: React.FC<IFileActionUploadProps> = ({
  disabled,
  uploading,
  entityId,
  entityType,
  field,
  orphanUuid,
  uploadFile,
  options = {},
  onDropAccepted: onDropAcceptedProp,
  ...rest
}) => {
  const isDisabled = disabled || uploading;
  const onDropAccepted: NonNullable<
    IDropzoneProps['onDropAccepted']
  > = (files, event) => {
    onDropAcceptedProp?.(files, event);
    files.forEach((file) => {
      uploadFile(
        {
          file,
          entityId,
          entityType,
          orphanUuid,
          field,
        },
        options,
      );
    });
  };
  return (
    <Dropzone
      disabled={isDisabled}
      onDropAccepted={onDropAccepted}
      {...rest}
    />
  );
};

export const FileActionUploadContainer: React.FC<
  IFileActionUploadContainerProps
> = (props) => (
  <GuardGroupCreate>
    <FileUploadContainer
      entityType={props.entityType}
      entityId={props.entityId}
    >
      {(containerProps) => (
        <FileActionUpload {...props} {...containerProps} />
      )}
    </FileUploadContainer>
  </GuardGroupCreate>
);

export default FileActionUploadContainer;
