/**
 * Labstep
 *
 * @module components/PurchaseOrder/Filter/Status/Select
 * @desc Filter for PurchaseOrder status as select
 */

import React from 'react';
import ReactSelect from 'labstep-web/core/Select';
import Modal from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import { STRINGS } from 'labstep-web/strings';
import { PurchaseOrderStatusValues } from 'labstep-web/models';
import { placeholder } from './strings';
import { IPurchaseOrderFilterStatusSelectProps } from './types';

const PurchaseOrderFilterStatusSelect: React.FC<
  IPurchaseOrderFilterStatusSelectProps
> = ({ setParams, searchParams }) => (
  <Modal
    header={STRINGS.filter.header.status}
    content={({ toggleModal }) => (
      <ReactSelect
        defaultValue={
          searchParams.status
            ? {
                value: searchParams.status,
                label:
                  PurchaseOrderStatusValues[
                    searchParams.status as keyof typeof PurchaseOrderStatusValues
                  ],
              }
            : null
        }
        options={Object.keys(PurchaseOrderStatusValues).map(
          (key) => ({
            value: key,
            label:
              PurchaseOrderStatusValues[
                key as keyof typeof PurchaseOrderStatusValues
              ],
          }),
        )}
        isClearable
        onChange={(
          selectedOption: {
            value: string;
            label: string;
          } | null,
        ) => {
          setParams({
            status: selectedOption ? selectedOption.value : undefined,
          });
          toggleModal();
        }}
        placeholder={placeholder}
      />
    )}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        type="option"
        icon="info"
        text={STRINGS.filter.header.status}
        onClick={toggleModal}
      />
    )}
  />
);

export default PurchaseOrderFilterStatusSelect;
