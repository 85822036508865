/**
 * Labstep
 *
 * @module components/Molecule/CollapsibleCardItem
 * @desc Component to show a collapsible molecule item
 */

import React from 'react';
import { isEqual } from 'lodash';
import CollapsibleCard from 'labstep-web/core/Collapsible/Card';
import MoleculeActionEdit from 'labstep-web/components/Molecule/Action/Edit';
import Icon from 'labstep-web/core/Icon';
import MoleculeCardEmptyStateSvg from 'labstep-web/components/Molecule/Card/EmptyState/Svg';
import { ICONS } from 'labstep-web/constants';
import MoleculeStoichiometryTableCreateContainer from '../StoichiometryTable';
import styles from './styles.module.scss';
import { IMoleculeCollapsibleCardProps } from './types';

export class MoleculeCollapsibleCard extends React.Component<IMoleculeCollapsibleCardProps> {
  public shouldComponentUpdate(
    prevProps: IMoleculeCollapsibleCardProps,
  ): boolean {
    return !isEqual(prevProps.molecule, this.props.molecule);
  }

  public render(): React.ReactNode {
    const { molecule, canEdit, entity } = this.props;

    const { svg } = molecule;
    const blob = new Blob([svg], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    return (
      <CollapsibleCard>
        <CollapsibleCard.Item
          icon={ICONS.molecule.primary}
          name="Reaction Scheme"
          actions={
            <MoleculeActionEdit
              molecule={molecule}
              parent={entity}
              viewComponent={({
                toggleModal,
              }): React.ReactElement => (
                <Icon
                  name={ICONS.file.actions.preview}
                  onClick={toggleModal}
                />
              )}
              canEdit={canEdit}
            />
          }
          content={
            molecule.svg ? (
              <MoleculeActionEdit
                molecule={molecule}
                parent={entity}
                viewComponent={({
                  toggleModal,
                }): React.ReactElement => (
                  <div
                    className={styles.reactionScheme}
                    onClick={toggleModal}
                  >
                    <img src={url} alt="reaction-scheme" />
                  </div>
                )}
                canEdit={canEdit}
              />
            ) : (
              <MoleculeCardEmptyStateSvg
                molecule={molecule}
                entity={entity}
                canEdit={canEdit}
              />
            )
          }
          initialExpandedState
          disabled
        />
        <MoleculeStoichiometryTableCreateContainer
          className={styles.collapsibleCard}
          scrollable
          initialExpandedState={false}
          parent={entity}
          guid={molecule.guid}
          attached
        />
      </CollapsibleCard>
    );
  }
}

export default MoleculeCollapsibleCard;
