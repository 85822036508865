/**
 * Labstep
 *
 * @module components/Metadata/Action/FileUpload
 * @desc Metadata File Upload Container
 */

import React from 'react';
import FileActionUpload from 'labstep-web/components/File/Action/Upload';
import { EntityCreateContainer } from 'labstep-web/containers';
import { Metadata, MetadataThread } from 'labstep-web/models';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IMetadataActionCreateFileProps } from './types';

const MetadataActionCreateFile: React.FC<
  IMetadataActionCreateFileProps
> = ({ metadataThreadId, children }) => {
  const canEdit = useHasAccess(
    MetadataThread.entityName,
    metadataThreadId,
    Action.edit,
  );
  return canEdit ? (
    <EntityCreateContainer
      entityName={Metadata.entityName}
      parentName={MetadataThread.entityName}
      parentId={metadataThreadId}
    >
      {({ create }) => (
        <FileActionUpload
          showDragEnter
          multiple
          noClick
          options={{
            onSuccess: ({ response }) => {
              create({
                label: 'File',
                metadata_thread_id: metadataThreadId,
                type: 'file',
                is_output: true,
                file_id: response.result[0],
              });
            },
          }}
          children={children}
        />
      )}
    </EntityCreateContainer>
  ) : (
    <>{children}</>
  );
};
export default MetadataActionCreateFile;
