/**
 * Labstep
 *
 * @module prosemirror/components/Menu/File/ActionList
 * @desc List of actions for manipulating a file node
 */

import React from 'react';
import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import FileActionDownload from 'labstep-web/components/File/Action/Download';
import FileActionAnnotate from 'labstep-web/components/File/Action/Annotate';
import FilePreview from 'labstep-web/components/File/Preview';
import { EntityReadEntityContainer } from 'labstep-web/containers';
import { ICONS } from 'labstep-web/constants';
import { IMenuFileActionListProps } from './types';

/** Number of pixels to display menu above selection */
export const LINE_HEIGHT = 40;

export const MenuFileActionList: React.FC<
  IMenuFileActionListProps
> = ({
  id,
  additionalActions,
  showAnnotate = true,
  viewInlineAction,
}) => (
  <EntityReadEntityContainer entityName="file" id={id}>
    {({ entity: file }) => {
      if (!file) {
        return null;
      }
      return (
        <>
          <FileActionDownload
            file={file}
            actionComponentProps={{ type: 'button', showIcon: true }}
          />
          <FilePreview
            noDiv
            file={file}
            viewComponent={({ toggle }) => (
              <Button onClick={toggle}>
                <Icon name={ICONS.file.actions.preview} />
                <span>Preview</span>
              </Button>
            )}
          />
          {file.canAnnotate && showAnnotate && (
            <FileActionAnnotate
              file={file}
              viewComponent={({ toggle }) => (
                <Button onClick={toggle}>
                  <Icon name={ICONS.file.actions.annotate} />
                  <span>Annotate</span>
                </Button>
              )}
            />
          )}
          {file.canViewInline && viewInlineAction}
          {additionalActions}
        </>
      );
    }}
  </EntityReadEntityContainer>
);

export default MenuFileActionList;
