/**
 * Labstep
 *
 * @module components/Signature/Form/fields
 * @desc Signature fields
 */

import rules from 'labstep-web/services/validation/rules';
import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';

export const fieldSignatureStatement: IFieldWithoutActionProps = {
  name: 'statement',
  fieldLabel: 'Statement',
  fieldType: FieldType.TextArea,
  validation: rules.signature.statement,
};
