/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Comment
 * @desc Display inline comment when hovering on comment decoration
 */

import React from 'react';
import CommentItem from 'labstep-web/components/Comment/Item';
import ActionComponent from 'labstep-web/core/Action/Component';
import {
  commentPluginKey,
  deleteComment,
} from 'labstep-web/prosemirror/extensions/comment';
import { Comment } from 'labstep-web/models';
import { ReadOnMountHOC } from 'labstep-web/hoc';
import { Action } from 'labstep-web/components/Comment/Action/Menu/types';
import styles from './styles.module.scss';
import { ICommentComponentProps, IMenuCommentProps } from './types';

/** Number of pixels to display menu above selection */
export const LINE_HEIGHT = -35;

export const CommentComponent: React.FC<ICommentComponentProps> = ({
  comment,
  state,
  dispatch,
  commentDecoration,
}) => (
  <CommentItem
    comment={comment}
    actions={[Action.edit, Action.comment]}
    additionalActions={
      <ActionComponent
        onClick={() =>
          deleteComment(
            state,
            dispatch,
            commentDecoration.type.spec.comment,
          )
        }
        type="icon"
        icon="check"
        elementProps={{
          popup: { content: 'Mark as Resolved' },
        }}
      />
    }
    showCommentThread
    simple
  />
);

const COMMENT_WIDTH_PX = 300;

const MenuComment: React.FC<IMenuCommentProps> = ({
  state,
  dispatch,
  cursorPosition,
}) => {
  const { selection } = state;

  if (!selection.empty) {
    return null;
  }
  const comments = state.plugins
    .find((plugin) => plugin.spec.key === commentPluginKey)
    .getState(state)
    .commentsAt(selection.from);

  if (!comments.length) {
    return null;
  }

  const singleComment = comments[0];

  const editorElement = document.getElementById(
    'prose-mirror-editor',
  );
  const pageWidth = editorElement ? editorElement.offsetWidth : 600;

  return (
    <div
      className={styles.container}
      style={{
        position: 'fixed',
        top: `${cursorPosition.top - LINE_HEIGHT}px`,
        left: `${
          cursorPosition.left -
          (cursorPosition.left * COMMENT_WIDTH_PX) / pageWidth
        }px`,
      }}
    >
      <ReadOnMountHOC
        type="entities"
        entityName={Comment.entityName}
        params={{
          id: singleComment.type.spec.comment.id,
          get_single: 1,
          is_deleted: 'both',
        }}
        loading={{ cached: true }}
        children={({ entity }) => (
          <CommentComponent
            dispatch={dispatch}
            state={state}
            comment={entity}
            commentDecoration={comments[0]}
          />
        )}
      />
    </div>
  );
};

export default MenuComment;
