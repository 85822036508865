/**
 * Labstep
 *
 * @module core/Action/Component/Label
 * @desc Label on Action Component dropdown/button/option
 */

import React from 'react';
import Icon from 'labstep-web/core/Icon';
import { IActionComponentLabelProps } from './types';

export const ActionComponentLabel: React.FC<
  IActionComponentLabelProps
> = ({ icon, cornerIcon, text }) => (
  <>
    {icon && (
      <>
        <Icon name={icon} cornerIcon={cornerIcon} />{' '}
      </>
    )}
    {text}
  </>
);

export default ActionComponentLabel;
