/**
 * Labstep
 *
 * @module state/reducers/index
 * @desc ES6 barrel for reducers
 */

import { combineReducers } from 'redux';
import { sidebarReducer } from '../new/sidebar';
import { clientReducers } from '../new/client';
import { printReducers } from '../new/print';
import { prosemirrorReducers } from '../new/prosemirror';
import { activeEditModalSlice } from '../slices/activeEditModal';
import { activeGroupIdSlice } from '../slices/activeGroup';
import { activeMetabaseUrlSlice } from '../slices/activeMetabaseUrl';
import { logoutAwareReducer } from './helpers';
import { getEntityReducers } from './entity';
import authenticatedUser from './authenticated-user';
import file from './file';
import ui from './ui';
import optimistic from './optimistic';
import user from './user';
import proseMirrorReferencingSearch from './prosemirror-referencing-search';
import urlParamsReducer from './params';
import thumbnail from './thumbnail';
import uiPersistent, { lastActionTimestamp } from './ui-persistent';
import jupyter from './jupyter';
import websocket from './websocket';

export const entityReducers = {
  access_key: getEntityReducers('access_key'),
  chemical: getEntityReducers('chemical'),
  comment: getEntityReducers('comment'),
  custom_identifier_set: getEntityReducers('custom_identifier_set'),
  data_table: getEntityReducers('data_table'),
  folder: getEntityReducers('folder'),
  rich_text: getEntityReducers('rich_text'),
  molecule: getEntityReducers('molecule'),
  entity_export: getEntityReducers('entity_export'),
  entity_import: getEntityReducers('entity_import'),
  entity_user: getEntityReducers('entity_user'),
  entity_view: getEntityReducers('entity_view'),
  experiment: getEntityReducers('experiment'),
  experiment_workflow: getEntityReducers('experiment_workflow'),
  experiment_workflow_link: getEntityReducers(
    'experiment_workflow_link',
  ),
  file,
  group: getEntityReducers('group'),
  group_auto_sharing: getEntityReducers('group_auto_sharing'),
  device: getEntityReducers('device'),
  device_data: getEntityReducers('device_data'),
  device_booking: getEntityReducers('device_booking'),
  jupyter_instance: getEntityReducers('jupyter_instance'),
  jupyter_notebook: getEntityReducers('jupyter_notebook'),
  log: getEntityReducers('log'),
  notification: getEntityReducers('notification'),
  notification_alert: getEntityReducers('notification_alert'),
  perma_link: getEntityReducers('perma_link'),
  permission: getEntityReducers('permission'),
  permission_role: getEntityReducers('permission_role'),
  permission_role_setting: getEntityReducers(
    'permission_role_setting',
  ),
  profile: getEntityReducers('profile'),
  protocol: getEntityReducers('protocol'),
  protocol_collection: getEntityReducers('protocol_collection'),
  protocol_condition: getEntityReducers('protocol_condition'),
  protocol_device: getEntityReducers('protocol_device'),
  protocol_table: getEntityReducers('protocol_table'),
  protocol_timer: getEntityReducers('protocol_timer'),
  protocol_value: getEntityReducers('protocol_value'),
  protocol_step: getEntityReducers('protocol_step'),
  purchase_order: getEntityReducers('purchase_order'),
  organization: getEntityReducers('organization'),
  organization_invoice: getEntityReducers('organization_invoice'),
  organization_plan: getEntityReducers('organization_plan'),
  organization_plan_price: getEntityReducers(
    'organization_plan_price',
  ),
  organization_saml: getEntityReducers('organization_saml'),
  order_request: getEntityReducers('order_request'),
  outbound_order_request: getEntityReducers('outbound_order_request'),
  outbound_purchase_order: getEntityReducers(
    'outbound_purchase_order',
  ),
  resource: getEntityReducers('resource'),
  resource_template: getEntityReducers('resource_template'),
  resource_location: getEntityReducers('resource_location'),
  resource_item: getEntityReducers('resource_item'),
  resource_item_template: getEntityReducers('resource_item_template'),
  metadata: getEntityReducers('metadata'),
  metadata_thread: getEntityReducers('metadata_thread'),
  metadata_molecule: getEntityReducers('metadata_molecule'),
  metadata_table: getEntityReducers('metadata_table'),
  research_area: getEntityReducers('research_area'),
  review_thread: getEntityReducers('review_thread'),
  review: getEntityReducers('review'),
  security_log: getEntityReducers('security_log'),
  signature: getEntityReducers('signature'),
  signature_request: getEntityReducers('signature_request'),
  share_link: getEntityReducers('share_link'),
  sequence: getEntityReducers('sequence'),
  share_link_invitation: getEntityReducers('share_link_invitation'),
  tag: getEntityReducers('tag'),
  thread: getEntityReducers('thread'),
  user,
  user_group: getEntityReducers('user_group'),
  user_group_notification: getEntityReducers(
    'user_group_notification',
  ),
  user_organization: getEntityReducers('user_organization'),
  user_share_link: getEntityReducers('user_share_link'),
  user_mention: getEntityReducers('user_mention'),
};

export default {
  [activeEditModalSlice.name]: logoutAwareReducer(
    activeEditModalSlice.reducer,
  ),
  [activeGroupIdSlice.name]: logoutAwareReducer(
    activeGroupIdSlice.reducer,
  ),
  [activeMetabaseUrlSlice.name]: logoutAwareReducer(
    activeMetabaseUrlSlice.reducer,
  ),
  entities: logoutAwareReducer(combineReducers(entityReducers)),
  authenticatedUser: logoutAwareReducer(authenticatedUser),
  ui,
  prosemirror: logoutAwareReducer(prosemirrorReducers),
  prosemirror_referencing_search: logoutAwareReducer(
    proseMirrorReferencingSearch,
  ),
  ui_persistent: logoutAwareReducer(uiPersistent),
  last_action_timestamp: logoutAwareReducer(lastActionTimestamp),
  optimistic,
  sidebar: sidebarReducer,
  params: urlParamsReducer,
  websocket,
  thumbnail: logoutAwareReducer(thumbnail),
  jupyter: logoutAwareReducer(jupyter),
  client: logoutAwareReducer(clientReducers),
  print: printReducers,
};
