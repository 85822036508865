/**
 * Labstep
 *
 * @module components/Organization/UserManager
 */

import React from 'react';
import SecurityLogTable from 'labstep-web/components/SecurityLog/Table';
import EntitySearch from 'labstep-web/components/Entity/Search';
import { SecurityLog } from 'labstep-web/models';
import { ISecurityLogManagerProps } from './types';

const SecurityLogManager: React.FC<ISecurityLogManagerProps> = ({
  organization,
}) => (
  <EntitySearch
    entityName={SecurityLog.entityName}
    params={{
      organization_id: organization.id,
      count: 10,
    }}
    noSearchParam
    hideFilters
  >
    {({ entities }) => <SecurityLogTable securityLogs={entities} />}
  </EntitySearch>
);

export default SecurityLogManager;
