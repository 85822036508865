/**
 * Labstep
 *
 * @module components/Academic/SetupWizard
 * @desc Show modal with wizard to complete academic account
 */

import React from 'react';
import { withRouter } from 'react-router';
import { navigation } from 'labstep-web/services/navigation';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import GroupListTransfer from 'labstep-web/components/Group/List/Transfer';
import OrganizationFormCreate from 'labstep-web/components/Organization/Form/Create';
import OrganizationInviteMembers from 'labstep-web/components/Organization/InviteMembers';
import { User } from 'labstep-web/models';
import {
  EntityUpdateContainer,
  withAuthenticatedUserEntity,
} from 'labstep-web/containers';
import { ModalWizardContext } from 'labstep-web/core/Modal/Wizard/context';
import { AcademicSetupWizardProps } from './types';
import styles from './styles.module.scss';

const ROUTE_ID = 'academic-setup';

export const AcademicSetupWizard: React.FC<
  AcademicSetupWizardProps
> = ({ authenticatedUserEntity, history }) => {
  if (authenticatedUserEntity.is_organization_setup_completed) {
    return null;
  }

  return (
    <EntityUpdateContainer
      entityName={User.entityName}
      id={authenticatedUserEntity.guid}
    >
      {({ update, status }) => (
        <ModalWizard
          startIndex={
            authenticatedUserEntity.userOrganizationOrganization
              ? 1
              : 0
          }
          open
          closeIcon={false}
          mode="fullscreen"
          routeId={ROUTE_ID}
          title="Upgrade"
          trigger={({ toggleModal }) => (
            <ActionComponent
              type="icon"
              icon="arrow alternate circle up"
              elementProps={{
                color: 'green',
                size: 'large',
                popup: { content: 'Complete Setup' },
              }}
              onClick={() => toggleModal()}
            />
          )}
          steps={[
            'Create Organization',
            'Transfer Workspaces',
            'Invite Users',
            'Getting Started',
          ]}
        >
          <ModalWizard.Step index={0} hideContinueButton>
            <ModalWizardContext.Consumer>
              {({ goForward }) => (
                <div className={styles.container}>
                  <p>
                    Your new organization will house your workspaces
                    and users
                  </p>
                  <OrganizationFormCreate
                    options={{
                      onSuccess: () => {
                        goForward();
                      },
                    }}
                  />
                </div>
              )}
            </ModalWizardContext.Consumer>
          </ModalWizard.Step>
          <ModalWizard.Step
            index={1}
            hideBackButton
            hideContinueButton
          >
            <ModalWizardContext.Consumer>
              {({ goForward }) => (
                <div className={styles.container}>
                  <GroupListTransfer
                    options={{
                      onSuccess: () => {
                        goForward();
                      },
                    }}
                  />
                </div>
              )}
            </ModalWizardContext.Consumer>
          </ModalWizard.Step>
          <ModalWizard.Step
            index={2}
            hideBackButton
            hideContinueButton
          >
            <ModalWizardContext.Consumer>
              {({ goForward }) => (
                <div className={styles.container}>
                  <OrganizationInviteMembers
                    organization={
                      authenticatedUserEntity.userOrganizationOrganization
                    }
                    options={{
                      onSuccess: () => {
                        goForward();
                      },
                    }}
                  />
                </div>
              )}
            </ModalWizardContext.Consumer>
          </ModalWizard.Step>
          <ModalWizard.Step
            index={3}
            hideBackButton
            hideContinueButton
          >
            <div className={styles.container}>
              <p>Welcome to your new Labstep Organization!</p>
              <p>
                You now have access to the Organization layer of
                Labstep which brings all your workspaces and users
                into one place.
              </p>
              <img
                src="/img/organization/org-demo.gif"
                alt="Gif showing how to manage permissions for organizations"
              />
              <p>
                Manage the permissions for each user across all your
                workspaces, set-up Custom Roles and enjoy access to
                premium features such as Timemachine, and Two Factor
                Authentication.
              </p>
              <ActionComponent
                type="button"
                text="Let's Go!"
                status={status}
                onClick={() => {
                  update(
                    {
                      is_organization_setup_completed: true,
                    },
                    {
                      onSuccess: () => {
                        history.push(
                          navigation.get(
                            'organization_show_billing',
                            {
                              identifier:
                                authenticatedUserEntity.userOrganizationOrganization!
                                  .identifier,
                            },
                          ),
                        );
                      },
                    },
                  );
                }}
              />
            </div>
          </ModalWizard.Step>
        </ModalWizard>
      )}
    </EntityUpdateContainer>
  );
};

export default withRouter(
  withAuthenticatedUserEntity(AcademicSetupWizard),
);
