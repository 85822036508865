/**
 * Labstep
 */

import { format } from 'labstep-web/services/date.service';
import {
  createEntity,
  updateEntity,
} from 'labstep-web/state/actions';
import {
  selectCreateEntityStatus,
  selectUpdateEntityStatus,
} from 'labstep-web/state/selectors';
import { IOptions } from 'labstep-web/typings';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

export const useEntity = (
  entityName: string,
  entityId?: string | number | null,
) => {
  const [uuid] = useState(v4());
  const dispatch = useDispatch();
  return {
    create: {
      call: (
        body: Record<string, unknown>,
        options?: IOptions,
        parentName?: string,
        parentId?: string | number,
      ) => {
        dispatch(
          createEntity(
            entityName,
            body,
            parentName,
            parentId,
            uuid,
            options,
          ),
        );
      },
      status: useSelector((state) =>
        selectCreateEntityStatus(state, entityName, uuid),
      ),
    },
    update: {
      call: (body: Record<string, unknown>, options?: IOptions) => {
        if (entityId) {
          dispatch(updateEntity(entityName, entityId, body, options));
        }
      },
      status: useSelector((state) =>
        entityId
          ? selectUpdateEntityStatus(state, entityName, entityId)
          : false,
      ),
    },
    delete: {
      call: (options?: IOptions) => {
        if (entityId) {
          dispatch(
            updateEntity(
              entityName,
              entityId,
              {
                deleted_at: format(new Date()),
              },
              options,
            ),
          );
        }
      },
      status: useSelector((state) =>
        entityId
          ? selectUpdateEntityStatus(state, entityName, entityId)
          : false,
      ),
    },
  };
};
