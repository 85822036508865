/**
 * Labstep
 *
 * @module components/SecurityLog/Table
 * @desc A multilist listing user organizations
 */

import React from 'react';
import TableSimple from 'labstep-web/core/Table/Simple';
import { SecurityLog } from 'labstep-web/models';
import { ISecurityLogTableProps } from './types';

const SecurityLogTable: React.FC<ISecurityLogTableProps> = ({
  securityLogs,
}) => {
  const columns = [
    {
      header: ' ',
      content: (securityLog: SecurityLog) =>
        securityLog.printTimeStamp,
      cellProps: { flex: 1 },
    },
    {
      header: '',
      content: (securityLog: SecurityLog) =>
        securityLog.type.toUpperCase(),
      cellProps: { style: { width: 120 } },
    },
    {
      header: '',
      content: (securityLog: SecurityLog) => securityLog.user.name,
      cellProps: { flex: 1 },
    },
    {
      header: '',
      content: (securityLog: SecurityLog) => securityLog.ip,
      cellProps: { flex: 1 },
    },
    {
      header: '',
      content: (securityLog: SecurityLog) => securityLog.browser.name,
      cellProps: { flex: 1 },
    },
    {
      header: '',
      content: (securityLog: SecurityLog) =>
        securityLog.printDeviceType,
      cellProps: { flex: 1 },
    },
    {
      header: '',
      content: (securityLog: SecurityLog) => securityLog.os.name,
      cellProps: { flex: 1 },
    },
  ];
  return (
    <TableSimple hideHeader rows={securityLogs} columns={columns} />
  );
};

export default SecurityLogTable;
