/**
 * Labstep
 *
 */

import { v4 } from 'uuid';
import { generateTableData } from 'labstep-web/services/table.service';
import { reactPropsKey } from 'labstep-web/prosemirror/extensions/external-comm';
import { getToken } from 'labstep-web/prosemirror/utils/selection';
import { TRIGGER } from 'labstep-web/prosemirror/extensions/slash/plugin';
import { loaderKey } from 'labstep-web/prosemirror/extensions/loader/plugin';
import { replaceWithNode } from 'labstep-web/prosemirror/nodes/commands';
import { createEntity } from 'labstep-web/state/actions';
import store from 'labstep-web/state/store';
import { Molecule } from 'labstep-web/models';
import { EditorState } from 'prosemirror-state';
import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import { IProseMirrorCommandsElement } from 'labstep-web/prosemirror/extensions/slash/types';
import menuCommandsElements from 'labstep-web/prosemirror/components/Menu/Commands/elements';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';
import { IOptions } from 'labstep-web/typings';

export const elementsShouldCreate: IProseMirrorCommandsElement[] = [
  'protocol_table',
  'protocol_step',
  'protocol_value',
  'metadata',
  'jupyter_notebook',
  'molecule',
  'conditions',
];

export const addPlaceholder = (
  state: EditorState,
  dispatch: IStateDispatchProps['dispatch'],
  id: unknown,
): void => {
  dispatch?.(
    state.tr.setMeta(loaderKey, {
      add: { id, pos: state.tr.selection.from },
    }),
  );
};

export const removePlaceholder = (
  state: EditorState,
  dispatch: IStateDispatchProps['dispatch'],
  id: unknown,
): void => {
  dispatch?.(
    state.tr.setMeta(loaderKey, {
      remove: { id },
    }),
  );
};

/** Set placeholder and replace token with node */
export const updateViewOnCreate = (
  state: EditorState,
  dispatch: IStateDispatchProps['dispatch'],
  key: string,
  addContent?: boolean,
): IOptions => {
  const id = {};
  addPlaceholder(state, dispatch, id);

  const { from, to } = getToken(state, TRIGGER);
  return {
    onSuccess: ({ response }): void => {
      const result = Array.isArray(response.result)
        ? response.result[0]
        : response.result;

      const attrs = {
        [getIdAttribute(key)]: result,
      };

      removePlaceholder(state, dispatch, id);
      replaceWithNode(
        state,
        dispatch,
        from,
        to,
        key,
        attrs,
        addContent && state.schema.nodes.paragraph.createAndFill(),
      );
    },
    onFail: (): void => removePlaceholder(state, dispatch, id),
  };
};

export const createElement = (
  state: EditorState,
  dispatch: IStateDispatchProps['dispatch'],
  element: IProseMirrorCommandsElement,
): void => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { key, entityName, onCreate } = menuCommandsElements.find(
    (el) => el.id === element,
  )!;

  if (onCreate) {
    onCreate(state, dispatch);
    return;
  }

  const reactPropsPlugin = state.plugins.find(
    (p) => p.spec.key === reactPropsKey,
  );

  if (!reactPropsPlugin) {
    return;
  }

  let { entity } = reactPropsPlugin.getState(state);

  let body = {};
  let options: Record<string, unknown> = {
    additionalMeta: { redirect: true },
  };
  let addContent = false;
  if (element === 'protocol_table') {
    body = { name: 'Untitled Table', data: generateTableData() };
  } else if (['protocol_step'].includes(element)) {
    body = [{}];
    options = { ...options, batch: true };
    addContent = true;
  } else if (element === 'metadata') {
    body = { type: 'file', is_output: true };
    entity = entity.metadata_thread;
  } else if (element === 'molecule') {
    body = Molecule.createBodyDefault;
  }

  const additionalOptions = updateViewOnCreate(
    state,
    dispatch,
    key,
    addContent,
  );

  store.dispatch(
    createEntity(
      entityName,
      body,
      entity.entityName,
      entity.id,
      v4(),
      {
        ...options,
        ...additionalOptions,
      },
    ),
  );
};
