import classNames from 'classnames';
import DataGrid from 'labstep-web/core/DataGrid';
import {
  ColDef,
  ColDefParams,
} from 'labstep-web/core/DataGrid/types';
import { Entity } from 'labstep-web/models';
import styles from './styles.module.scss';

const colDefActionMenu = <T extends Entity>(
  action: (params: ColDefParams<T>) => JSX.Element,
): ColDef<T> => ({
  colId: 'action_menu',
  maxWidth: 20,
  pinned: 'left',
  cellRenderer: DataGrid.CellRenderer.ActionMenu,
  cellRendererParams: (params) => ({
    props: {
      action: action(params),
    },
  }),
  headerClass: 'no-border',
  cellClass: classNames('no-border', styles.cell),
  lockPosition: true,
});

export default colDefActionMenu;
