/**
 * Labstep
 *
 * @module screens/Authentication/ForgotPassword
 * @desc ForgotPasswordScreen
 */

import React from 'react';
import Columns from 'labstep-web/screens/Authentication/Columns';
import Image from 'labstep-web/core/Image';
import ForgotPasswordForm from 'labstep-web/components/User/Form/ForgotPassword';
import {
  AlreadyHaveAnAccount,
  SharedBody,
  SharedFooter,
  SignupLinks,
} from 'labstep-web/screens/Authentication/Shared';
import DocumentHead from 'labstep-web/core/DocumentHead';
import { UserForgotPasswordContainer } from 'labstep-web/containers';
import styles from './styles.module.scss';

export const ForgotPasswordComponent = ({
  forgotPassword,
  status,
}) => {
  const hasResetAlready =
    status && status.error === null && status.isFetching === false;

  if (hasResetAlready) {
    return (
      <p>
        <b>Check your inbox for an email to reset your password.</b>
      </p>
    );
  }

  return (
    <div>
      <SharedBody>
        <ForgotPasswordForm
          forgotPassword={forgotPassword}
          status={status}
        />
      </SharedBody>

      <SharedFooter>
        <SignupLinks />
        <AlreadyHaveAnAccount />
      </SharedFooter>
    </div>
  );
};

export const ForgotPasswordColumns = (props) => (
  <>
    <DocumentHead title="Forgot Password" />
    <Columns
      left={
        <div className={styles.imageContainer}>
          <Image src="/img/landing/forgot-graphics.svg" fluid />
        </div>
      }
      right={<ForgotPasswordComponent {...props} />}
      header="Reset password"
      explainer="Enter your email address and we will send you a link to reset your password"
    />
  </>
);

const ForgotPassword = () => (
  <UserForgotPasswordContainer>
    {(containerProps) => (
      <ForgotPasswordColumns {...containerProps} />
    )}
  </UserForgotPasswordContainer>
);

export default ForgotPassword;
