/**
 * Labstep
 *
 * @module components/RichText/Action/Create
 * @desc Action to update comment post
 */

import React from 'react';
import validationRules from 'labstep-web/services/validation/rules';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import Modal from 'labstep-web/core/Modal/Default';
import FormEdit from 'labstep-web/core/Form/Edit';
import CoreCodeMirror from 'labstep-web/core/CodeMirror/Modal';
import { EntityUpdateContainer } from 'labstep-web/containers';
import { FieldType } from 'labstep-web/core/Form/Reusable/types';
import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import { IRichTextActionEditProps } from './types';

const RichTextActionEdit: React.FC<IRichTextActionEditProps> = ({
  richText,
}) => {
  const viewComponent = ({ toggleModal }) => (
    <EntityActionEdit
      entity={richText}
      actionComponentProps={{
        type: 'option',
        onClick: toggleModal,
      }}
    />
  );
  if (richText.type !== 'html') {
    return (
      <EntityUpdateContainer entityName="rich_text" id={richText.id}>
        {({ update, status }) => (
          <CoreCodeMirror
            submitButtonText="Update"
            onSubmit={(body, toggleModal) =>
              update(body, { onSuccess: toggleModal })
            }
            status={status}
            value={richText.description}
            mode={richText.type}
            viewComponent={viewComponent}
          />
        )}
      </EntityUpdateContainer>
    );
  }
  return (
    <Modal
      size="fullscreen"
      header={`Edit ${getHumanReadableEntityName('rich_text')}`}
      content={({ toggleModal }) => (
        <FormEdit
          entity={richText}
          entityName="rich_text"
          fields={[
            {
              name: 'description',
              fieldType: FieldType.ProseMirrorHtml,
              validation: validationRules.rich_text.description,
            },
          ]}
          options={{ onSuccess: toggleModal }}
        />
      )}
      viewComponent={viewComponent}
    />
  );
};

export default RichTextActionEdit;
