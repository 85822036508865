/**
 * Labstep
 *
 * @module containers/Entity/Read/Entities
 * @desc Container for reading entities
 */

import { connect } from 'react-redux';
import {
  readEntitiesPage,
  readEntitiesNextPage,
} from 'labstep-web/state/actions';
import store from 'labstep-web/state/store';
import { LabstepReduxState } from 'labstep-web/state/types';
import {
  selectReadPageEntities,
  selectReadPageEntitiesAllPages,
  selectReadPageStatus,
  selectReadPageStatusAllPages,
  selectReadPageTotal,
  selectReadTotalPages,
} from 'labstep-web/state/selectors';
import {
  IEntityReadPageContainerChildrenProps,
  IEntityReadPageContainerDispatchProps,
  IEntityReadPageContainerProps,
  IEntityReadPageContainerStateProps,
} from './types';

const Container = ({
  children,
  ...rest
}: {
  children: (
    props: IEntityReadPageContainerChildrenProps,
  ) => React.ReactElement | null;
} & IEntityReadPageContainerChildrenProps): React.ReactElement | null =>
  children(rest);

const mapStateToProps = (
  state: LabstepReduxState,
  ownProps: IEntityReadPageContainerProps,
): IEntityReadPageContainerStateProps => ({
  entities: selectReadPageEntities(
    state,
    ownProps.entityName,
    ownProps.params,
    ownProps.page,
  ),
  entitiesAllPages: selectReadPageEntitiesAllPages(
    state,
    ownProps.entityName,
    ownProps.params,
  ),
  status: selectReadPageStatus(
    state,
    ownProps.entityName,
    ownProps.params,
    ownProps.page,
  ),
  statusAllPages: selectReadPageStatusAllPages(
    state,
    ownProps.entityName,
    ownProps.params,
  ),
  totalPages: selectReadTotalPages(
    state,
    ownProps.entityName,
    ownProps.params,
  ),
  total: selectReadPageTotal(
    state,
    ownProps.entityName,
    ownProps.params,
  ),
});

const mapDispatchToProps = (
  dispatch: typeof store.dispatch,
  ownProps: IEntityReadPageContainerProps,
): IEntityReadPageContainerDispatchProps => ({
  read(options): void {
    dispatch(
      readEntitiesPage(
        ownProps.entityName,
        ownProps.params,
        ownProps.page,
        options,
        ownProps.usePostFilter,
      ),
    );
  },
  readNextPage(options): void {
    dispatch(
      readEntitiesNextPage(
        ownProps.entityName,
        ownProps.params,
        options,
        ownProps.usePostFilter,
      ),
    );
  },
});

export const EntityReadPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
