/**
 * Labstep
 *
 * @module components/File/Action/Remove
 * @desc Action to remove a file from parent
 */

import React from 'react';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { Metadata } from 'labstep-web/models';
import { IActionComponentProps } from 'labstep-web/core/Action/Component/types';
import { IFileActionRemoveProps } from './types';

const defaultActionComponentProps = {
  type: 'icon',
  popup: {},
  icon: 'close',
} as IActionComponentProps;

export const FileActionRemove: React.FC<IFileActionRemoveProps> = ({
  file,
  createProps,
  actionComponentProps: customActionComponentProps,
  options,
}) => {
  if (file.parent_type !== Metadata.entityName) {
    return null;
  }
  const actionComponentProps = {
    ...defaultActionComponentProps,
    ...customActionComponentProps,
  } as IActionComponentProps;

  return createProps ? (
    <EntityActionCreate
      parentName={createProps.parentName}
      parentId={createProps.parentId}
      entityName={Metadata.entityName}
      body={{ ...createProps.createBody, file_id: null }}
      actionComponentProps={actionComponentProps}
      options={options}
    />
  ) : (
    <EntityActionUpdate
      entityName={file.entityName}
      id={file.id}
      body={{
        [`${file.parent_type}_id`]: null,
      }}
      actionComponentProps={actionComponentProps}
      options={options}
    />
  );
};

export default FileActionRemove;
