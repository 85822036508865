/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Table
 * @desc Table listing all experimentWorkflows
 */

import React from 'react';
import { humanReadableDate } from 'labstep-web/services/date.service';
import Icon from 'labstep-web/core/Icon';
import Popup from 'labstep-web/core/Popup';
import { SignedTypes } from 'labstep-web/models';
import { ICONS } from 'labstep-web/constants';
import { IIconProps } from 'labstep-web/core/Icon/types';
import { IExperimentWorkflowStatusProps } from './types';

export const ExperimentWorkflowStatus: React.FC<
  IExperimentWorkflowStatusProps
> = ({ experimentWorkflow }) => {
  let color: IIconProps['color'];
  let content = 'Planning';

  if (experimentWorkflow.status === 'completed') {
    color = 'green';
    content = `Completed on ${humanReadableDate(
      experimentWorkflow.ended_at,
    )}`;
  } else if (experimentWorkflow.status === 'in_progress') {
    color = 'yellow';
    content = 'In progress';
  }
  return (
    <>
      <Popup
        trigger={
          <Icon
            name={
              ICONS.experiment_workflow.info[
                experimentWorkflow.status
              ]
            }
            size="small"
            color={color}
          />
        }
        content={content}
        size="tiny"
      />
      {experimentWorkflow.isSigned && (
        <Popup
          trigger={
            <Icon
              name={SignedTypes.signed.icon}
              size="small"
              color="grey"
            />
          }
          content="Signed"
          size="tiny"
        />
      )}
      {experimentWorkflow.locked_at && (
        <Popup
          trigger={
            <Icon
              name={ICONS.experiment_workflow.actions.lock}
              size="small"
              color="grey"
            />
          }
          content="Locked"
          size="tiny"
        />
      )}
    </>
  );
};

export default ExperimentWorkflowStatus;
