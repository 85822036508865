import { ReadOnMountHOC } from 'labstep-web/hoc';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import {
  EntityView,
  EntityViewColumnOptions,
  defaultEntityViewColumnOptions,
} from 'labstep-web/models';
import isEqual from 'lodash/isEqual';
import React, { createContext, useContext, useState } from 'react';
import {
  IEntityViewContextProviderEnabledProps,
  IEntityViewContextProviderProps,
} from './types';
import { createEntityViewFilter } from './utils';

export interface EntityViewContextType {
  activeEntityView: EntityView | null;
  entityName: string;
  context: string;
  allowSave: boolean;
  parameters?: EntityView['parameters'];
  columnOptions: EntityViewColumnOptions;
  setColumnOptions: React.Dispatch<EntityViewColumnOptions>;
}

/**
 * Context for EntityView
 * Enables sorting, filtering and reloading/saving views
 */
export const EntityViewContext =
  createContext<EntityViewContextType | null>(null);

export const useEntityViewContext =
  (): EntityViewContextType | null => {
    const context = useContext(EntityViewContext);
    return context;
  };

export const EntityViewContextProviderEnabled: React.FC<
  IEntityViewContextProviderEnabledProps
> = ({
  entities,
  children,
  parameters,
  entityName,
  context,
  allowSave = true,
}) => {
  const activeEntityView = entities[0] || null;
  // Store column options in state until save
  const [columnOptions, setColumnOptions] =
    useState<EntityViewColumnOptions>(
      activeEntityView?.column_options ||
        defaultEntityViewColumnOptions[
          context in defaultEntityViewColumnOptions
            ? (context as keyof typeof defaultEntityViewColumnOptions)
            : 'default'
        ],
    );
  const value = React.useMemo(
    () => ({
      activeEntityView,
      parameters,
      entityName,
      context,
      allowSave,
      columnOptions,
      setColumnOptions,
    }),
    [
      entities,
      parameters,
      entityName,
      context,
      allowSave,
      columnOptions,
      setColumnOptions,
    ],
  );
  return (
    <EntityViewContext.Provider value={value} children={children} />
  );
};

export const EntityViewContextProvider: React.FC<
  IEntityViewContextProviderProps
> = ({ parameters, entityName, context, allowSave, children }) => {
  const { activeGroup } = useActiveGroup();
  if (!activeGroup) {
    return <>{children}</>;
  }
  return (
    <ReadOnMountHOC
      type="page"
      loading={{ loader: 'main_content', cached: true }}
      entityName={EntityView.entityName}
      page={1}
      params={{
        group_id: activeGroup.id,
        filter: createEntityViewFilter(
          entityName,
          context,
          parameters,
        ),
      }}
      inequalFunc={(prevProps, nextProps): boolean =>
        !isEqual(prevProps.params?.filter, nextProps.params?.filter)
      }
    >
      {({
        entities,
      }: {
        entities: EntityView[];
      }): React.ReactElement => (
        <EntityViewContextProviderEnabled
          entities={entities}
          children={children}
          parameters={parameters}
          entityName={entityName}
          context={context}
          allowSave={allowSave}
        />
      )}
    </ReadOnMountHOC>
  );
};

export const EntityViewContextColumnOptionsConsumer: <
  T extends keyof EntityViewColumnOptions,
>(props: {
  colId: T;
  children: (childrenProps: {
    columnOptions: EntityViewColumnOptions[T];
    setColumnOptions: (
      options: Partial<EntityViewColumnOptions[T]>,
    ) => void;
  }) => JSX.Element | null;
}) => JSX.Element | null = ({ children, colId }) => (
  <EntityViewContext.Consumer>
    {(context) => {
      if (!context) {
        return children({
          columnOptions:
            defaultEntityViewColumnOptions.default[colId],
          setColumnOptions: () => null,
        });
      }
      const { columnOptions, setColumnOptions } = context;
      const columnOptionsForCol = columnOptions[colId];
      const setColumnOptionsForCol = (
        options: Partial<
          EntityViewColumnOptions[keyof EntityViewColumnOptions]
        >,
      ) => {
        setColumnOptions({
          ...columnOptions,
          [colId]: {
            ...columnOptionsForCol,
            ...options,
          },
        });
      };
      return children({
        columnOptions: columnOptionsForCol,
        setColumnOptions: setColumnOptionsForCol,
      });
    }}
  </EntityViewContext.Consumer>
);
