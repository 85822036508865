/**
 * Labstep.
 *
 * @module epics/websocket
 * @desc Redux epic for websocket
 */

import { Observable, concat, of } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { StateObservable } from 'redux-observable';
import { Action } from 'labstep-web/models';
import { authenticationService } from 'labstep-web/services/authentication.service';
import { websocketService } from 'labstep-web/services/websocket.service';
import { LabstepReduxState } from '../types';

/**
 * At login, rehydrate, refresh token success, initializes the websocket.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onTriggerInitActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'WEBSOCKET_RECONNECT' ||
        action.type === 'SUCCESS_INTERNAL_LOGIN' ||
        action.type === 'persist/REHYDRATE',
    ),
    mergeMap((action) => {
      return authenticationService
        .getAuthentication(action, state$)
        .pipe(
          mergeMap((actionOrAuthentication) => {
            if (
              actionOrAuthentication.type.endsWith('REFRESH_TOKEN')
            ) {
              return of(actionOrAuthentication);
            }

            return of({
              type: 'WEBSOCKET_INIT',
              meta: {
                token:
                  actionOrAuthentication.meta?.authentication?.jwt,
              },
            });
          }),
        );
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_WEBSOCKET_TRIGGER_INIT',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * When socket disconnects, wait 5s and reconnect.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onReconnectActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'WEBSOCKET_DISCONNECT' ||
        action.type === 'WEBSOCKET_CHECK_CONNECTED_FALSE',
    ),
    delay(5000),
    map(() => {
      const { connected } = websocketService;
      if (connected) {
        return {
          type: 'WEBSOCKET_CHECK_CONNECTED_TRUE',
        };
      }
      return {
        type: 'WEBSOCKET_RECONNECT',
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_WEBSOCKET_RECONNECT', payload: err }),
        source$,
      ),
    ),
  );

/**
 * At logout, destroys the websocket.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onTriggerDestroyActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === 'SUCCESS_LOGOUT'),
    map(() => ({
      type: 'WEBSOCKET_DESTROY',
    })),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_WEBSOCKET_TRIGGER_DESTROY',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Initializes a websocket.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onInitActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === 'WEBSOCKET_INIT'),
    mergeMap((action: Action) =>
      websocketService.init(action.meta.token),
    ),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_WEBSOCKET_INIT', payload: err }),
        source$,
      ),
    ),
  );

/**
 * Destroys a websocket.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onDestroyActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === 'WEBSOCKET_DESTROY'),
    tap(() => {
      websocketService.destroy();
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_WEBSOCKET_DESTROY', payload: err }),
        source$,
      ),
    ),
  );

/**
 * Check if the websocket is connected.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onCheckConnectedActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) => action.type === 'WEBSOCKET_CHECK_CONNECTED',
    ),
    delay(1000),
    mergeMap((action: Action) => {
      const { connected } = websocketService;
      if (connected) {
        return of({
          type: 'WEBSOCKET_CHECK_CONNECTED_TRUE',
        });
      }
      return of({
        type: 'WEBSOCKET_CHECK_CONNECTED_FALSE',
        meta: action.meta,
      });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_WEBSOCKET_CHECK_CONNECTED',
          payload: err,
        }),
        source$,
      ),
    ),
  );
