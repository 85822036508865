/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/File
 * @desc Metadata showdit for file
 */

import React from 'react';
import ActionComponent from 'labstep-web/core/Action/Component';
import FileActionCreate from 'labstep-web/components/File/Action/Create';
import UpdateOrCreateContainer from 'labstep-web/containers/UpdateOrCreate';
import FileActionUpload from 'labstep-web/components/File/Action/Upload';
import { IOnSuccessProps, Protocol } from 'labstep-web/models';
import { IMetadataFormShowEditFileProps } from './types';

export const MetadataFormShowEditFile: React.FC<
  IMetadataFormShowEditFileProps
> = ({
  createProps,
  metadata,
  entity,
  isDropzone,
  actionComponentType = 'text',
}) => {
  return (
    <UpdateOrCreateContainer
      createProps={createProps}
      entityName={metadata.entityName}
      updateProps={{ id: metadata.id }}
      // Need this cause even though we are sending file_id
      // the field we are changing is "files"
      optimistic={false}
    >
      {({ action, status }) => {
        const options = {
          onSuccess: ({ response }: IOnSuccessProps) => {
            const fileId = Array.isArray(response.result)
              ? response.result[0]
              : response.result;
            action({
              file_id: fileId,
            });
          },
        };
        return isDropzone ? (
          <FileActionUpload options={options} multiple={isDropzone} />
        ) : (
          <FileActionCreate
            multiple
            onFolderWatcherSelect={({ file }) => {
              action({ file_id: file.id });
            }}
            options={options}
          >
            {({ toggleModal }) =>
              actionComponentType === 'icon' ? (
                <ActionComponent
                  type="icon"
                  status={status}
                  onClick={toggleModal}
                  icon="plus"
                  elementProps={{ popup: { content: 'Add more' } }}
                />
              ) : (
                <ActionComponent
                  type="text"
                  status={status}
                  onClick={toggleModal}
                  elementProps={{ showEdit: true }}
                  text={
                    (metadata.is_template ||
                      entity instanceof Protocol) &&
                    !createProps
                      ? 'Select / upload default file'
                      : 'Select / upload file(s)'
                  }
                />
              )
            }
          </FileActionCreate>
        );
      }}
    </UpdateOrCreateContainer>
  );
};

export default MetadataFormShowEditFile;
