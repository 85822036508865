/**
 * Labstep
 *
 * @module screens/OrderRequest/Show/Right
 * @desc OrderRequest Show Right
 */

import React from 'react';
import RightPane from 'labstep-web/components/Layout/RightPane';
import ThreadForm from 'labstep-web/components/Thread/Form';
import OrderRequestEmptyStateResourceItem from 'labstep-web/components/OrderRequest/Card/EmptyState/ResourceItem';
import { ResourceItemEntitySearch } from 'labstep-web/components/ResourceItem/Search';
import LogList from 'labstep-web/components/Log/List';
import { ICONS } from 'labstep-web/constants';
import { IScreensOrderRequestShowRightProps } from './types';

const ScreensOrderRequestShowRight: React.FC<
  IScreensOrderRequestShowRightProps
> = ({ orderRequest }) => (
  <RightPane
    route={{
      to: 'order_request_show',
      params: { id: orderRequest.id },
    }}
    subPages={[
      {
        name: 'Items',
        icon: ICONS.resource_item.primary,
        route: {
          to: 'order_request_show_resource_items',
          params: { id: orderRequest.id },
        },
        component: (
          <ResourceItemEntitySearch
            params={{ order_request_id: orderRequest.id }}
            noResultsMessage={
              <OrderRequestEmptyStateResourceItem
                orderRequest={orderRequest}
              />
            }
            tableFormat={false}
          />
        ),
      },
      {
        name: 'Notes',
        icon: ICONS.thread.primary,
        count: orderRequest.thread.comment_count,
        route: {
          to: 'order_request_show_thread',
          params: { id: orderRequest.id },
        },
        component: (
          <ThreadForm
            createThreadId={orderRequest.thread.id}
            readThreadIds={orderRequest.thread.id}
            entityNameLabel="notes"
          />
        ),
      },
      {
        name: 'Activity',
        icon: 'history',
        route: {
          to: 'order_request_show_activity',
          params: { id: orderRequest.id },
        },
        component: <LogList entity={orderRequest} />,
      },
    ]}
  />
);

export default ScreensOrderRequestShowRight;
