/**
 * Labstep
 *
 * @module core/OneDrivePicker
 * @desc OneDrivePicker component
 */

import React from 'react';
import Picker from 'labstep-web/components/File/Action/Create/Picker';
import { EntityCreateContainer } from 'labstep-web/containers';
import { configService } from 'labstep-web/services/config.service';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import {
  IOneDrivePickerFileContainerProps,
  IOneDrivePickerProps,
  IBody,
  OneDriveSuccessResponseType,
  OneDriveErrorType,
} from './types';

export class OneDrivePicker extends React.Component<IOneDrivePickerProps> {
  public static defaultProps = {
    multiple: false,
  };

  public constructor(props: IOneDrivePickerProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  public onClick(): void {
    const { create, options = {}, onSelect, multiple } = this.props;

    const onSuccess = (body: IBody): void => {
      create(body, options);
    };

    const oneDriveOptions = {
      clientId: configService.oneDriveClientId,
      action: 'share',
      multiSelect: multiple,
      advanced: {
        redirectUri: window.location.origin,
      },
      error(error: OneDriveErrorType): void {
        // eslint-disable-next-line no-alert
        console.log('error=', error);
        bugsnagService.notify(error);
      },
      success: (response: OneDriveSuccessResponseType): void => {
        console.log('response=', response);
        if (!response.value) {
          bugsnagService.notify('OneDrive response failed', {
            response,
          });
          return;
        }
        const filesToUpload = response.value.map(
          (oneDriveFile) =>
            ({
              path: oneDriveFile.permissions[0].link.webUrl,
              size: oneDriveFile.size,
              name: oneDriveFile.name,
              mime_type: oneDriveFile.file.mimeType,
              link_source: 'onedrive',
            } as IBody),
        );

        if (onSelect) {
          onSelect(filesToUpload);
        }

        filesToUpload.forEach((file) => {
          onSuccess(file);
        });
      },
    };
    window.OneDrive.open(oneDriveOptions);
  }

  public render(): React.ReactElement {
    return (
      <Picker onClick={this.onClick} icon="cloud" text="One Drive" />
    );
  }
}

const OneDrivePickerFileContainer: React.FC<
  IOneDrivePickerFileContainerProps
> = ({ parentName, parentId, ...rest }) => (
  <EntityCreateContainer
    entityName="file"
    parentName={parentName}
    parentId={parentId}
  >
    {({ create }): React.ReactElement => (
      <OneDrivePicker create={create} {...rest} />
    )}
  </EntityCreateContainer>
);

export default OneDrivePickerFileContainer;
