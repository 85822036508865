/**
 * Labstep
 *
 * @module components/ProtocolDevice/Card/EmptyState
 * @desc Protocol Device Empty State card
 */

import React from 'react';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { ProtocolDevice, DeviceData } from 'labstep-web/models';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { IProtocolDeviceCardEmptyState } from './types';

export const ProtocolDeviceCardEmptyState: React.FC<
  IProtocolDeviceCardEmptyState
> = ({ parentEntityName, ...rest }) => (
  <EmptyState
    src="/img/empty-state/devices.svg"
    explanation={`Add ${getHumanReadableEntityName(
      ProtocolDevice.entityName,
      true,
    )} to your ${getHumanReadableEntityName(
      parentEntityName,
    )} to link ${getHumanReadableEntityName(
      DeviceData.entityName,
      true,
    )}.`}
    {...rest}
  />
);

export default ProtocolDeviceCardEmptyState;
