/**
 * Labstep
 *
 * @module components/AccessKey/Action/Create
 * @desc Create action for Access Key
 */

import React from 'react';
import ModalDefault from 'labstep-web/core/Modal/Default';
import AccessKeyFormCreate from 'labstep-web/components/AccessKey/Form/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ICONS } from 'labstep-web/constants';
import { AccessKey } from 'labstep-web/models';

const createText = `Add ${AccessKey.getHumanReadableEntityName()}`;

const AccessKeyActionCreate: React.FC = () => (
  <ModalDefault
    header={createText}
    viewComponent={({ toggleModal }) => (
      <ActionComponent
        onClick={toggleModal}
        text={createText}
        type="text"
        icon={ICONS.access_key.primary}
      />
    )}
    content={({ toggleModal }) => (
      <AccessKeyFormCreate options={{ onSuccess: toggleModal }} />
    )}
  />
);

export default AccessKeyActionCreate;
