export const ERRORS = {
  ORGANIZATION_INVALID_IP: 'organization_invalid_ip',
  SHARE_LINK_ORGANIZATION_EXPIRED: 'share_link_organization_expired',
  SHARE_LINK_ORGANIZATION_ALREADY_MEMBER:
    'share_link_organization_already_member',
  SHARE_LINK_NOT_FOUND: 'share_link_not_found',
  SHARE_LINK_DELETED: 'share_link_deleted',
  SHARE_LINK_ACCESS_DENIED: 'share_link_access_denied',
  TOKEN_ALREADY_CONFIRMED: 'token_already_confirmed',
  PROTOCOL_WITH_EXPERIMENT_CANNOT_UPDATE:
    'protocol_with_experiment_cannot_update',
  PROSEMIRROR_STATE_IDS_DUPLICATE: 'prosemirror_state_ids_duplicate',
  PROSEMIRROR_STATE_IDS_MISMATCH: 'prosemirror_state_ids_mismatch',
  LOCK_PARENT_LOCKED: 'lock_parent_locked',
  GROUP_AUTO_SHARING_PROPAGATING: 'group_auto_sharing_propagating',
};
