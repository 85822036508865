/**
 * Labstep
 *
 * @module core/Header
 * @desc Core Header component. Includes 7 sizes.
 */

import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { IHeaderProps } from './types';

export const Header: React.FC<IHeaderProps> = ({
  children,
  size,
  color,
  noMargin,
  className,
  onClick,
}) => {
  const sizeClass = styles[`${size}Size`];
  const cxArgs: cx.ArgumentArray = [
    sizeClass,
    { [styles.noMargin]: noMargin },
  ];
  if (className) {
    cxArgs.push({ [className]: className });
  }
  if (color) {
    cxArgs.push({ [styles[color]]: color });
  }
  const classes = cx(...cxArgs);

  return (
    <span className={classes} onClick={onClick}>
      {children}
    </span>
  );
};

Header.defaultProps = {
  size: 'medium',
};

export default Header;
