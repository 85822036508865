import { ExcelIOService } from 'labstep-web/services/excel-io';
import { GC } from 'labstep-web/core/SpreadJS/imports';

const { Commands } = GC.Spread.Sheets;

function newCommand(func) {
  return (spread, options, isUndo) => {
    if (isUndo) {
      Commands.undoTransaction(spread, options);
      return true;
    }
    Commands.startTransaction(spread, options);
    const sheet = spread.getSheetFromName(options.sheetName);
    func(sheet, options);
    Commands.endTransaction(spread, options);
    return true;
  };
}

export function executeCommand(spread, name, options?) {
  const sheetName = spread.getActiveSheet().name();
  spread
    .commandManager()
    .execute({ cmd: name, sheetName, ...options });
}

function triggerCellChangeEvent(sheet) {
  const cell = sheet.getCell(0, 0);
  const font = cell.font();
  cell.font('12pt Arial');
  cell.font(font);
}

function triggerRangeChangeEvent(sheet) {
  sheet.addRows(0, 1);
  sheet.deleteRows(0, 1);
}

function getSubStrings(source, beginChar, endChar) {
  if (!source) {
    return [];
  }
  const subStrings = [];
  let tempSubString = '';
  let inSubString = false;
  for (let index = 0; index < source.length; index += 1) {
    if (!inSubString && source[index] === beginChar) {
      inSubString = true;
      tempSubString = source[index];
    }
    if (inSubString) {
      tempSubString += source[index];
      if (source[index] === endChar) {
        subStrings.push(tempSubString);
        tempSubString = '';
        inSubString = false;
      }
    }
  }
  return subStrings;
}

function applyToCells(sheet, f) {
  sheet.suspendEvent();
  sheet.suspendPaint();
  for (let p = 0; p < sheet.getSelections().length; p += 1) {
    const selectCells = sheet.getSelections()[p];
    for (let r = 0; r < selectCells.rowCount; r += 1) {
      for (let c = 0; c < selectCells.rowCount; c += 1) {
        f(selectCells.row + r, selectCells.col + c);
      }
    }
  }
  sheet.resumePaint();
  sheet.resumeEvent();
  triggerCellChangeEvent(sheet);
}

function applyToSelection(sheet, f) {
  sheet.suspendPaint();
  sheet.suspendEvent();
  for (let p = 0; p < sheet.getSelections().length; p += 1) {
    const selectCells = sheet.getSelections()[p];
    const range = sheet.getRange(
      selectCells.row,
      selectCells.col,
      selectCells.rowCount,
      selectCells.colCount,
    );
    f(range);
  }
  sheet.resumePaint();
  sheet.resumeEvent();
  triggerRangeChangeEvent(sheet);
}

function styleToFont(style) {
  return `${style.fontStyle} ${style.fontVariant} ${style.fontWeight} ${style.fontSize} ${style.fontFamily}`;
}

function getScientificNotationCheckingFormattter(formatter) {
  /* eslint-disable no-param-reassign */
  if (!formatter) {
    return formatter;
  }
  let i;
  const signalQuoteSubStrings = getSubStrings(formatter, "'", "'");
  for (i = 0; i < signalQuoteSubStrings.length; i += 1) {
    formatter = formatter.replace(signalQuoteSubStrings[i], '');
  }
  const doubleQuoteSubStrings = getSubStrings(formatter, '"', '"');
  for (i = 0; i < doubleQuoteSubStrings.length; i += 1) {
    formatter = formatter.replace(doubleQuoteSubStrings[i], '');
  }
  const colorStrings = getSubStrings(formatter, '[', ']');
  for (i = 0; i < colorStrings.length; i += 1) {
    formatter = formatter.replace(colorStrings[i], '');
  }
  return formatter;
  /* eslint-enable no-param-reassign */
}

export function initialiseKeyboardShortcuts(spread) {
  const host = spread.getHost();
  host.addEventListener(
    'keydown',
    (e) => {
      if (e.key === 'b' && (e.ctrlKey || e.metaKey)) {
        executeCommand(spread, 'toggleBold');
        e.preventDefault();
      } else if (e.key === 'i' && (e.ctrlKey || e.metaKey)) {
        executeCommand(spread, 'toggleItalic');
        e.preventDefault();
      } else if (e.key === 'u' && (e.ctrlKey || e.metaKey)) {
        executeCommand(spread, 'toggleUnderline');
        e.preventDefault();
      } else if (e.key === '`' && (e.ctrlKey || e.metaKey)) {
        executeCommand(spread, 'toggleFormulas');
        e.preventDefault();
      } else if (e.key === 'a' && (e.ctrlKey || e.metaKey)) {
        executeCommand(spread, 'selectAll');
        e.preventDefault();
      }
    },
    true,
  );
}
function getActualCellRange(sheet, cellRange, rowCount, columnCount) {
  if (cellRange.row === -1 && cellRange.col === -1) {
    return new GC.Spread.Sheets.CellRange(
      sheet,
      0,
      0,
      rowCount,
      columnCount,
    );
  }
  if (cellRange.row === -1) {
    return new GC.Spread.Sheets.CellRange(
      sheet,
      0,
      cellRange.col,
      rowCount,
      cellRange.colCount,
    );
  }
  if (cellRange.col === -1) {
    return new GC.Spread.Sheets.CellRange(
      sheet,
      cellRange.row,
      0,
      cellRange.rowCount,
      columnCount,
    );
  }
  return new GC.Spread.Sheets.CellRange(
    sheet,
    cellRange.row,
    cellRange.col,
    cellRange.rowCount,
    cellRange.colCount,
  );
}

function fontToStyle(font) {
  let fontFamily = null;
  let fontSize = null;
  let fontStyle = 'normal';
  let fontWeight = 'normal';
  let fontVariant = 'normal';

  const elements = font.split(/\s+/);
  for (let i = 0; i < elements.length; i += 1) {
    const element = elements[i];
    switch (element) {
      case 'normal':
        break;
      case 'italic':
      case 'oblique':
        fontStyle = element;
        break;
      case 'small-caps':
        fontVariant = element;
        break;
      case 'bold':
      case 'bolder':
      case 'lighter':
      case '100':
      case '200':
      case '300':
      case '400':
      case '500':
      case '600':
      case '700':
      case '800':
      case '900':
        fontWeight = element;
        break;
      default:
        if (!fontSize) {
          fontSize = element;
          break;
        }
        if (!fontFamily) {
          fontFamily = element;
        } else {
          fontFamily += ` ${element}`;
        }
        return {
          fontStyle,
          fontVariant,
          fontWeight,
          fontSize,
          fontFamily,
        };
    }
  }
  return {
    fontStyle,
    fontVariant,
    fontWeight,
    fontSize,
    fontFamily,
  };
}

export const commands = {
  insert: (sheet, options) => {
    if (options.axis === 'row') {
      const offset = options.direction === 'above' ? 0 : 1;
      sheet.addRows(sheet.getActiveRowIndex() + offset, 1);
    } else if (options.axis === 'col') {
      const offset = options.direction === 'left' ? 0 : 1;
      sheet.addColumns(sheet.getActiveColumnIndex() + offset, 1);
    }
  },
  remove: (sheet, options) => {
    const selections = sheet.getSelections();
    if (options.axis === 'rows') {
      selections.forEach((selection) => {
        sheet.deleteRows(selection.row, selection.rowCount);
      });
    } else if (options.axis === 'cols') {
      selections.forEach((selection) => {
        sheet.deleteColumns(selection.col, selection.colCount);
      });
    }
  },
  formatPercentage: (sheet) => {
    for (let p = 0; p < sheet.getSelections().length; p += 1) {
      const selectCells = sheet.getSelections()[p];
      const defaultActiveCell = sheet.getCell(
        selectCells.row,
        selectCells.col,
      );
      defaultActiveCell.formatter('0.00%');
    }
  },
  formatAuto: (sheet) => {
    for (let p = 0; p < sheet.getSelections().length; p += 1) {
      const selectCells = sheet.getSelections()[p];
      const defaultActiveCell = sheet.getCell(
        selectCells.row,
        selectCells.col,
      );
      defaultActiveCell.formatter(null);
    }
  },
  formatScientific: (sheet) => {
    for (let p = 0; p < sheet.getSelections().length; p += 1) {
      const selectCells = sheet.getSelections()[p];
      const defaultActiveCell = sheet.getCell(
        selectCells.row,
        selectCells.col,
      );
      defaultActiveCell.formatter('0.00E+00');
    }
  },
  increaseDecimal: (sheet) => {
    sheet.suspendPaint();
    for (let p = 0; p < sheet.getSelections().length; p += 1) {
      const selectCells = sheet.getSelections()[p];
      const defaultActiveCell = sheet.getCell(
        selectCells.row,
        selectCells.col,
      );
      const defaultFormatter = defaultActiveCell.formatter();
      const defaultText = defaultActiveCell.value();
      let i;
      if (defaultText !== undefined && defaultText !== null) {
        const zero = '0';
        const numberSign = '#';
        const decimalPoint = '.';
        const zeroPointZero = `0${decimalPoint}0`;

        let scientificNotationCheckingFormatter =
          getScientificNotationCheckingFormattter(defaultFormatter);
        if (
          !defaultFormatter ||
          defaultFormatter === 'General' ||
          (scientificNotationCheckingFormatter &&
            (scientificNotationCheckingFormatter.indexOf('E') >= 0 ||
              scientificNotationCheckingFormatter.indexOf('e') >= 0))
        ) {
          scientificNotationCheckingFormatter = zeroPointZero;
          if (
            !Number.isNaN(defaultText) &&
            `${defaultText}`.split('.').length > 1
          ) {
            const afterPointZero = `${defaultText}`.split('.')[1]
              .length;
            for (let m = 0; m < afterPointZero; m += 1) {
              scientificNotationCheckingFormatter += '0';
            }
          }
        } else {
          let formatString = defaultFormatter;
          const formatters = formatString.split(';');
          for (i = 0; i < formatters.length && i < 2; i += 1) {
            if (
              formatters[i] &&
              formatters[i].indexOf('/') < 0 &&
              formatters[i].indexOf(':') < 0 &&
              formatters[i].indexOf('?') < 0
            ) {
              const indexOfDecimalPoint =
                formatters[i].lastIndexOf(decimalPoint);
              if (indexOfDecimalPoint !== -1) {
                formatters[i] =
                  formatters[i].slice(0, indexOfDecimalPoint + 1) +
                  zero +
                  formatters[i].slice(indexOfDecimalPoint + 1);
              } else {
                const indexOfZero = formatters[i].lastIndexOf(zero);
                const indexOfNumberSign =
                  formatters[i].lastIndexOf(numberSign);
                const insertIndex =
                  indexOfZero > indexOfNumberSign
                    ? indexOfZero
                    : indexOfNumberSign;
                if (insertIndex >= 0) {
                  formatters[i] =
                    formatters[i].slice(0, insertIndex + 1) +
                    decimalPoint +
                    zero +
                    formatters[i].slice(insertIndex + 1);
                }
              }
            }
          }
          formatString = formatters.join(';');
          scientificNotationCheckingFormatter = formatString;
        }
        for (
          let r = selectCells.row;
          r < selectCells.rowCount + selectCells.row;
          r += 1
        ) {
          for (
            let c = selectCells.col;
            c < selectCells.colCount + selectCells.col;
            c += 1
          ) {
            const style = sheet.getActualStyle(r, c);
            style.formatter = scientificNotationCheckingFormatter;
            sheet.setStyle(r, c, style);
          }
        }
      }
    }
    sheet.resumePaint();
  },
  decreaseDecimal: (sheet) => {
    sheet.suspendPaint();
    for (let p = 0; p < sheet.getSelections().length; p += 1) {
      const selectCells = sheet.getSelections()[p];
      const defaultActiveCell = sheet.getCell(
        selectCells.row,
        selectCells.col,
      );
      const defaultFormatter = defaultActiveCell.formatter();
      const defaultValue = defaultActiveCell.value();
      const decimalPoint = '.';
      let i;
      if (defaultValue !== undefined && defaultValue !== null) {
        let formatString = null;
        const defaultText = defaultValue.toString();
        if (!defaultFormatter || defaultFormatter === 'General') {
          if (!Number.isNaN(defaultText)) {
            const result = defaultText.split('.');
            if (result.length === 2) {
              result[0] = '0';
              let isScience = false;
              let sb = '';
              for (i = 0; i < result[1].length - 1; i += 1) {
                if (
                  i + 1 < result[1].length &&
                  (result[1].charAt(i + 1) === 'e' ||
                    result[1].charAt(i + 1) === 'E')
                ) {
                  isScience = true;
                  break;
                }
                sb += '0';
              }

              if (isScience) {
                sb += 'E+00';
              }
              result[1] = sb.toString();
              formatString =
                result[0] +
                (result[1] !== '' ? decimalPoint + result[1] : '');
            }
          }
        } else {
          formatString = defaultFormatter;
          const formatters = formatString.split(';');
          for (i = 0; i < formatters.length && i < 2; i += 1) {
            if (
              formatters[i] &&
              formatters[i].indexOf('/') < 0 &&
              formatters[i].indexOf(':') < 0 &&
              formatters[i].indexOf('?') < 0
            ) {
              const indexOfDecimalPoint =
                formatters[i].lastIndexOf(decimalPoint);
              if (
                indexOfDecimalPoint !== -1 &&
                indexOfDecimalPoint + 1 < formatters[i].length
              ) {
                formatters[i] =
                  formatters[i].slice(0, indexOfDecimalPoint + 1) +
                  formatters[i].slice(indexOfDecimalPoint + 2);
                const tempString =
                  indexOfDecimalPoint + 1 < formatters[i].length
                    ? formatters[i].substr(indexOfDecimalPoint + 1, 1)
                    : '';
                if (tempString === '' || tempString !== '0') {
                  formatters[i] =
                    formatters[i].slice(0, indexOfDecimalPoint) +
                    formatters[i].slice(indexOfDecimalPoint + 1);
                }
              } else {
                // do nothing.
              }
            }
          }
          formatString = formatters.join(';');
        }
        for (
          let r = selectCells.row;
          r < selectCells.rowCount + selectCells.row;
          r += 1
        ) {
          for (
            let c = selectCells.col;
            c < selectCells.colCount + selectCells.col;
            c += 1
          ) {
            const style = sheet.getActualStyle(r, c);
            style.formatter = formatString;
            sheet.setStyle(r, c, style);
          }
        }
      }
    }
    sheet.resumePaint();
  },
  toggleBold: (sheet) => {
    applyToSelection(sheet, (range) => {
      const style = fontToStyle(range.font());
      style.fontWeight =
        style.fontWeight !== '700' ? '700' : 'normal';
      const font = styleToFont(style);
      range.font(font);
    });
  },
  toggleItalic: (sheet) => {
    applyToSelection(sheet, (range) => {
      const style = fontToStyle(range.font());
      style.fontStyle =
        style.fontStyle !== 'italic' ? 'italic' : 'normal';
      const font = styleToFont(style);
      range.font(font);
    });
  },
  toggleUnderline: (sheet) => {
    applyToSelection(sheet, (range) => {
      const textDecoration = range.textDecoration();
      range.textDecoration(
        textDecoration !==
          GC.Spread.Sheets.TextDecorationType.underline
          ? GC.Spread.Sheets.TextDecorationType.underline
          : GC.Spread.Sheets.TextDecorationType.none,
      );
    });
  },
  toggleStrike: (sheet) => {
    applyToSelection(sheet, (range) => {
      const textDecoration = range.textDecoration();
      range.textDecoration(
        textDecoration !==
          GC.Spread.Sheets.TextDecorationType.lineThrough
          ? GC.Spread.Sheets.TextDecorationType.lineThrough
          : GC.Spread.Sheets.TextDecorationType.none,
      );
    });
  },
  setBackColor: (sheet, options) => {
    applyToSelection(sheet, (range) => {
      range.backColor(options.color);
    });
  },
  setForeColor: (sheet, options) => {
    applyToSelection(sheet, (range) => {
      range.foreColor(options.color);
    });
  },
  hAlign: (sheet, options) => {
    applyToSelection(sheet, (range) => {
      switch (options.mode) {
        case 'left':
          range.hAlign(GC.Spread.Sheets.HorizontalAlign.left);
          break;
        case 'right':
          range.hAlign(GC.Spread.Sheets.HorizontalAlign.right);
          break;
        case 'center':
          range.hAlign(GC.Spread.Sheets.HorizontalAlign.center);
          break;
        default:
          break;
      }
    });
  },
  vAlign: (sheet, options) => {
    applyToSelection(sheet, (range) => {
      switch (options.mode) {
        case 'top':
          range.vAlign(GC.Spread.Sheets.VerticalAlign.top);
          break;
        case 'bottom':
          range.vAlign(GC.Spread.Sheets.VerticalAlign.bottom);
          break;
        case 'center':
          range.vAlign(GC.Spread.Sheets.VerticalAlign.center);
          break;
        default:
          break;
      }
    });
  },
  setBorder: (sheet, options) => {
    applyToSelection(sheet, (range) => {
      range.setBorder(
        new GC.Spread.Sheets.LineBorder(
          options.color,
          GC.Spread.Sheets.LineStyle.medium,
        ),
        { [options.mode]: true },
        3,
      );
    });
  },
  wordWrap: (sheet) => {
    applyToSelection(sheet, (range) => {
      const wordWrap = range.wordWrap();
      range.wordWrap(!wordWrap);
      for (
        let i = range.row;
        i < range.row + range.rowCount;
        i += 1
      ) {
        sheet.autoFitRow(i);
      }
    });
  },
  clearStyles: (sheet) => {
    applyToCells(sheet, (row, col) => {
      const style = new GC.Spread.Sheets.Style();
      sheet.setStyle(row, col, style);
    });
  },
  mergeCells: (sheet) => {
    const sels = sheet.getSelections();
    const rowCount = sheet.getRowCount();
    const columnCount = sheet.getColumnCount();

    for (let n = 0; n < sels.length; n += 1) {
      const sel = getActualCellRange(
        sheet,
        sels[n],
        rowCount,
        columnCount,
      );
      sheet.addSpan(sel.row, sel.col, sel.rowCount, sel.colCount);
    }
  },
  unMergeCells: (sheet) => {
    function removeSpan(range) {
      sheet.removeSpan(range.row, range.col);
    }

    const sels = sheet.getSelections();
    const rowCount = sheet.getRowCount();
    const columnCount = sheet.getColumnCount();

    for (let n = 0; n < sels.length; n += 1) {
      const sel = getActualCellRange(
        sheet,
        sels[n],
        rowCount,
        columnCount,
      );
      sheet.getSpans(sel).forEach(removeSpan);
    }
  },
  toggleFormulas: (sheet) => {
    // eslint-disable-next-line no-param-reassign
    sheet.options.showFormulas = !sheet.options.showFormulas;
  },
  selectAll: (sheet) => {
    sheet.setSelection(
      0,
      0,
      sheet.getRowCount(),
      sheet.getColumnCount(),
    );
  },
  lockCells: (sheet) => {
    applyToSelection(sheet, (range) => {
      range.locked(true);
    });
  },
  unlockCells: (sheet) => {
    applyToSelection(sheet, (range) => {
      range.locked(false);
    });
  },
};

export function initialiseCommands(spread) {
  const commandManager = spread.commandManager();
  Object.keys(commands).forEach((command) => {
    if ({}.hasOwnProperty.call(commands, command)) {
      commandManager.register(command, {
        canUndo: true,
        execute: newCommand(commands[command]),
      });
    }
  });
  initialiseKeyboardShortcuts(spread);
}

export function undo(spread) {
  if (spread.undoManager().canUndo()) {
    spread.undoManager().undo();
  }
}
export function redo(spread) {
  if (spread.undoManager().canRedo()) {
    spread.undoManager().redo();
  }
}

export function saveXLSX(spread, callback, onError) {
  const excelIO = ExcelIOService.IO;
  excelIO.save(spread.toJSON(), callback, onError);
}

export function importXLSX(spread, file) {
  const excelIO = ExcelIOService.IO;
  excelIO.open(
    file,
    (json) => {
      const sheetName = Object.keys(json.sheets)[0];
      const sheet = spread.getActiveSheet();
      sheet.fromJSON({
        ...json.sheets[sheetName],
        namedStyles: json.namedStyles,
      });
      triggerRangeChangeEvent(sheet);
    },
    (e) => {
      // eslint-disable-next-line no-console
      console.log(e);
    },
  );
}
