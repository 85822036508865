/**
 * Labstep
 *
 * @module core/Spreadsheet
 * @desc Wrapper around grape-city spreadsheet
 */

import React from 'react';
import {
  COLUMN_LIMIT,
  ROW_HEIGHT,
  ROW_LIMIT,
  TableData,
  cleanTableData,
} from 'labstep-web/services/table.service';
import { GC, Workbook } from 'labstep-web/core/SpreadJS/imports';
import { SpreadSheetsProp } from '@grapecity/spread-sheets-react';
import Toolbar from './Toolbar';
import FormulaBar from './FormulaBar';
import styles from './styles.module.scss';
import { ISpreadsheetProps, ISpreadsheetState } from './types';
import {
  bindEvents,
  enforceSizeRequirements,
  initialiseContextMenu,
  resizeColumns,
  toggleScrollbars,
} from './helpers';
import { initialiseCommands } from './commands';

class Spreadsheet extends React.Component<
  ISpreadsheetProps,
  ISpreadsheetState
> {
  public static defaultProps = {
    showToolbar: true,
    rowHeight: ROW_HEIGHT,
    maxRows: ROW_LIMIT,
    maxCols: COLUMN_LIMIT,
    allowLocking: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: (): void => {},
  };

  public constructor(props: ISpreadsheetProps) {
    super(props);
    this.state = {
      spread: null,
    };
    this.initialiseWorkbook = this.initialiseWorkbook.bind(this);
    this.handleRangeChange = this.handleRangeChange.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
  }

  public handleRangeChange(
    sender: unknown,
    args: {
      sheet: GC.Spread.Sheets.Worksheet;
      row: number;
    },
  ): void {
    const { onChange, maxRows, maxCols, onError } = this.props;
    const { sheet } = args;
    toggleScrollbars(sheet.getParent());

    if (onChange) {
      if (enforceSizeRequirements(sheet, onError, maxRows, maxCols)) {
        const json = cleanTableData(sheet.toJSON() as TableData);
        onChange(json);
      }
    }
  }

  public handleDataChange(
    sender: unknown,
    args: {
      sheet: GC.Spread.Sheets.Worksheet;
      row: number;
    },
  ): void {
    const { onChange } = this.props;
    const { sheet, row } = args;
    sheet.autoFitRow(row);
    if (onChange) {
      const json = cleanTableData(args.sheet.toJSON() as TableData);
      onChange(json);
    }
  }

  public initialiseWorkbook(spread: GC.Spread.Sheets.Workbook): void {
    this.setState({ spread });
    const { data, readOnly } = this.props;
    /* eslint-disable no-param-reassign */
    spread.options.allowUndo = true;
    spread.options.allowExtendPasteRange = true;
    spread.options.allowCopyPasteExcelStyle = true;
    spread.options.scrollbarMaxAlign = true;
    /* eslint-enable no-param-reassign */

    const sheet = spread.getActiveSheet();
    sheet.fromJSON(data);
    const style = new GC.Spread.Sheets.Style();
    style.locked = !!readOnly;
    // Set style as default style for all the cells in the sheet
    sheet.setDefaultStyle(style);
    sheet.defaults.rowHeight = this.props.rowHeight;
    sheet.defaults.colHeaderRowHeight = this.props.rowHeight;
    sheet.options.allowCellOverflow = false;
    sheet.options.isProtected = true;
    sheet.options.protectionOptions.allowDeleteRows = true;
    sheet.options.protectionOptions.allowDeleteColumns = true;
    sheet.options.protectionOptions.allowInsertRows = true;
    sheet.options.protectionOptions.allowInsertColumns = true;
    sheet.options.protectionOptions.allowDragInsertRows = true;
    sheet.options.protectionOptions.allowDragInsertColumns = true;
    sheet.options.protectionOptions.allowOutlineColumns = true;
    sheet.options.protectionOptions.allowResizeColumns = true;
    sheet.options.protectionOptions.allowResizeRows = true;
    initialiseContextMenu(spread);
    initialiseCommands(spread);
    spread.touchToolStrip.add(
      new GC.Spread.Sheets.Touch.TouchToolStripItem(
        'Clear',
        'Clear',
        '/img/table-icons/clear.png',
        // eslint-disable-next-line func-names
        function () {
          const selections = sheet.getSelections();
          spread.commandManager().execute({
            cmd: 'clearValues',
            sheetName: sheet.name(),
            ranges: selections,
          });
        },
      ),
    );
    toggleScrollbars(spread);
    if (!data.columns) {
      resizeColumns(sheet);
    }
    bindEvents(sheet, this.handleDataChange, this.handleRangeChange);
  }

  public render(): React.ReactElement {
    const { showToolbar, spreadSheetProps, allowLocking, readOnly } =
      this.props;

    const workbookProps: SpreadSheetsProp = {
      allowUserZoom: false,
      tabStripVisible: false,
      newTabVisible: false,
      tabEditable: false,
      hideSelection: true,
      hostStyle: {
        height: '90%',
        width: '100%',
      },
      ...spreadSheetProps,
    };
    return (
      <div className={styles.container}>
        {showToolbar && (
          <div>
            {!readOnly && (
              <Toolbar
                spread={this.state.spread}
                allowLocking={allowLocking}
              />
            )}
            <FormulaBar
              spread={this.state.spread}
              readOnly={readOnly}
            />
          </div>
        )}
        <Workbook
          {...workbookProps}
          workbookInitialized={this.initialiseWorkbook}
        />
      </div>
    );
  }
}

export default Spreadsheet;
