/**
 * Labstep
 *
 * @module components/OrderRequest/Table
 * @desc Table listing all orderRequests
 */

import React from 'react';
import EntityTable from 'labstep-web/components/Entity/Table';
import OrderRequestActionMenu from 'labstep-web/components/OrderRequest/Action/Menu';
import OrderRequestActionUpdateStatus from 'labstep-web/components/OrderRequest/Action/UpdateStatus';
import OrderRequestFormShowEditAmount from 'labstep-web/components/OrderRequest/Form/ShowEdit/Amount';
import TextError from 'labstep-web/core/Text/Error';
import Icon from 'labstep-web/core/Icon';
import EntityLink from 'labstep-web/components/Entity/Link';
import MetadataPreview from 'labstep-web/components/Metadata/Preview';
import OrderRequestFormShowEditPurchaseOrder from 'labstep-web/components/OrderRequest/Form/ShowEdit/PurchaseOrder';
import { DefaultSort } from 'labstep-web/components/Entity/Table/SortOptions/types';
import OutboundOrderRequestFormShowEditAmount from 'labstep-web/components/OutboundOrderRequest/Form/ShowEdit/Amount';
import OrderRequestPrice from 'labstep-web/components/OrderRequest/Price';
import { IEntityTableProps } from 'labstep-web/components/Entity/Table/types';
import { OrderRequest } from 'labstep-web/models';
import { IOrderRequestTableProps } from './types';

export const OrderRequestTable: React.FC<IOrderRequestTableProps> = ({
  orderRequests,
  actions,
  withPrice,
  isAdminPage,
  withAdditionalInfo,
  ...rest
}) => {
  const primaryColumn = {
    header: '',
    content: (orderRequest: OrderRequest) => (
      <div>
        <EntityLink entity={orderRequest} />
        <MetadataPreview entity={orderRequest.resource} />
      </div>
    ),
    cellProps: { style: { flex: 3 } },
  };
  let extraColumns: IEntityTableProps<OrderRequest>['extraColumns'] =
    [
      {
        header: '',
        content: (orderRequest: OrderRequest) =>
          orderRequest.is_urgent && (
            <TextError>
              <Icon name="exclamation" popup={{ inverted: true }} />
            </TextError>
          ),
        cellProps: { style: { width: 25 } },
      },
      {
        header: '# of items',
        content: (orderRequest: OrderRequest) =>
          orderRequest.printQuantity,
        cellProps: { style: { flex: 1 } },
      },
      {
        header: 'Status',
        content: (orderRequest: OrderRequest) => (
          <OrderRequestActionUpdateStatus
            orderRequest={orderRequest}
            withAdditionalInfo={withAdditionalInfo}
          />
        ),
        sortKey: 'status',
        cellProps: { style: { width: 200 } },
      },
      {
        header: 'Order',
        content: (orderRequest: OrderRequest) => (
          <OrderRequestFormShowEditPurchaseOrder
            orderRequest={orderRequest}
            showIcon
          />
        ),
        cellProps: { style: { flex: 2 } },
      },
    ];

  if (!withPrice) {
    extraColumns = [
      ...extraColumns,
      {
        header: 'Price',
        content: (orderRequest: OrderRequest) => (
          <OrderRequestPrice orderRequest={orderRequest} />
        ),
        cellProps: {
          style: { width: 200 },
        },
      },
    ];
  }

  const actionMenu = ({ entity }: { entity: OrderRequest }) => (
    <OrderRequestActionMenu orderRequest={entity} actions={actions} />
  );

  if (withPrice) {
    extraColumns[3] = {
      header: isAdminPage ? 'What they pay' : 'Price',
      content: (orderRequest: OrderRequest) => (
        <OrderRequestFormShowEditAmount
          withCurrency
          orderRequest={orderRequest}
        />
      ),
      cellProps: {
        style: { paddingRight: 20, width: 100, textAlign: 'end' },
      },
      sortKey: '',
    };
    extraColumns = extraColumns.map((column) => {
      const { sortKey, ...columnRest } = column;
      return columnRest;
    });
  }

  if (isAdminPage) {
    extraColumns = [
      ...extraColumns,
      {
        header: 'What we pay',
        content: (orderRequest: OrderRequest) =>
          orderRequest.outbound_order_request ? (
            <OutboundOrderRequestFormShowEditAmount
              outboundOrderRequest={
                orderRequest.outbound_order_request
              }
            />
          ) : null,
        cellProps: {
          style: {
            width: 260,
            borderLeft: '1px solid black',
            textAlign: 'end',
          },
        },
        sortKey: '',
      },
    ];
  }

  return (
    <EntityTable
      id="order-request-table"
      primaryColumn={primaryColumn}
      entities={orderRequests}
      extraColumns={extraColumns}
      sortOptions={{
        name: {
          label: 'Resource',
          default_sort: DefaultSort.asc,
        },
        received_at: {
          label: 'Date Received',
          default_sort: DefaultSort.desc,
        },
      }}
      actionMenu={actionMenu}
      {...rest}
    />
  );
};

export default OrderRequestTable;
