/**
 * Labstep
 *
 * @module screens/Organization/Show/Center
 * @desc Organization Show Center
 */

import React from 'react';
import LayoutLinks from 'labstep-web/components/Layout/Links';
import UserOrganizationManager from 'labstep-web/components/UserOrganization/Manager';
import OrganizationPlanShow from 'labstep-web/components/OrganizationPlan/Show';
import OrganizationInvoiceList from 'labstep-web/components/OrganizationInvoice/List';
import OrganizationGroupManager from 'labstep-web/components/Organization/GroupManager';
import OrganizationActivity from 'labstep-web/components/Organization/Activity';
import OrganizationSettings from 'labstep-web/components/Organization/Settings';
import SecurityLogManager from 'labstep-web/components/SecurityLog/Manager';
import PermissionRoleManager from 'labstep-web/components/PermissionRole/Manager';
import { IEntityCardLink } from 'labstep-web/components/Layout/Links/types';
import { IScreensOrganizationShowCenterProps } from './types';
import styles from './styles.module.scss';

export const ScreensOrganizationShowCenter: React.FC<
  IScreensOrganizationShowCenterProps
> = ({ organization }) => {
  let links: IEntityCardLink[] = [
    {
      children: 'Workspaces',
      route: {
        to: 'organization_show',
        params: { identifier: organization.identifier },
      },
      render: (): React.ReactNode => (
        <OrganizationGroupManager organization={organization} />
      ),
    },
  ];

  if (organization.logged_user_user_organization.type !== 'guest') {
    links = [
      ...links,
      {
        children: 'Users',
        route: {
          to: 'organization_show_users',
          params: { identifier: organization.identifier },
        },
        render: (): React.ReactNode => (
          <UserOrganizationManager organization={organization} />
        ),
      },
    ];

    if (organization.hasFeatureEnabled('custom_permissions')) {
      links = [
        ...links,
        {
          children: 'Roles & Permissions',
          route: {
            to: 'organization_show_permissions',
            params: { identifier: organization.identifier },
          },
          render: (): React.ReactNode => (
            <div className="organization-permissions">
              <PermissionRoleManager organization={organization} />
            </div>
          ),
        },
      ];
    }
  }

  if (organization.logged_user_user_organization.type === 'admin') {
    if (
      organization.is_stripe_subscription_set ||
      organization.is_trialling
    ) {
      links = [
        ...links,
        {
          children: 'Plans & Billing',
          route: {
            to: 'organization_show_billing',
            params: { identifier: organization.identifier },
          },
          render: (): React.ReactNode => (
            <div className="organization-billing">
              <OrganizationPlanShow organization={organization} />
              <br />
              {organization.is_stripe_subscription_set && (
                <OrganizationInvoiceList />
              )}
            </div>
          ),
        },
      ];
    }
    if (organization.has_metabase_id) {
      links = [
        ...links,
        {
          children: 'Activity',
          route: {
            to: 'organization_show_activity',
            params: { identifier: organization.identifier },
          },
          render: (): React.ReactNode => <OrganizationActivity />,
        },
      ];
    }
    if (organization.hasFeatureEnabled('security_log')) {
      links = [
        ...links,
        {
          children: 'Security',
          route: {
            to: 'organization_show_security',
            params: { identifier: organization.identifier },
          },
          render: (): React.ReactNode => (
            <SecurityLogManager organization={organization} />
          ),
        },
      ];
    }
    links = [
      ...links,
      {
        children: 'Settings',
        route: {
          to: 'organization_show_settings',
          params: { identifier: organization.identifier },
        },
        render: (): React.ReactNode => (
          <OrganizationSettings organization={organization} />
        ),
      },
    ];
  }

  return (
    <div className={styles.container} id="organization-show-screen">
      <LayoutLinks fluid inverted links={links} />
    </div>
  );
};

export default ScreensOrganizationShowCenter;
