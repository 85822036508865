/**
 * Labstep
 *
 * @module components/OrderRequest/Form/ShowEdit/Quantity
 * @desc Info about order request
 */

import React from 'react';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import { fieldQuantity } from 'labstep-web/components/OrderRequest/Form/fields';
import { OrderRequest } from 'labstep-web/models';
import { IOrderRequestFormShowEditQuantityProps } from './types';

export const OrderRequestFormShowEditQuantity: React.FC<
  IOrderRequestFormShowEditQuantityProps
> = ({ orderRequest }) => (
  <ShowEditText
    entityName={OrderRequest.entityName}
    entity={orderRequest}
    field={fieldQuantity}
  >
    {orderRequest.quantity}
  </ShowEditText>
);

export default OrderRequestFormShowEditQuantity;
