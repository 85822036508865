/**
 * Labstep
 *
 * @module prosemirror/extensions/commands/plugin
 * @desc Commands plugin definition to store the commands menu state
 */

import { getToken } from 'labstep-web/prosemirror/utils/selection';
import { Plugin, PluginKey } from 'prosemirror-state';
import {
  SELECT_NEXT,
  SELECT_PREVIOUS,
  SET_ELEMENTS,
  SUBMIT_ELEMENT,
} from 'labstep-web/prosemirror/extensions/slash/commands/actions';
import { IProseMirrorCommandsElement } from 'labstep-web/prosemirror/extensions/slash/types';
import { IMenuCommandsElementProps } from 'labstep-web/prosemirror/components/Menu/Commands/types';
import menuCommandsElements, {
  getHeader,
} from 'labstep-web/prosemirror/components/Menu/Commands/elements';
import { IProseMirrorReferencingToken } from '../../referencing/types';
import { SET_IS_OPEN } from '../commands/actions';
import { PluginState } from './types';

const KEY = 'commands';

export const TRIGGER = '/';

export const commandsPluginKey = new PluginKey(KEY);

export const setAvailableElements = (
  elements: IProseMirrorCommandsElement[],
  token: IProseMirrorReferencingToken,
) => {
  return elements.filter((element) => {
    const menuCommandsElement = menuCommandsElements.find(
      (e) => element === e.id,
    ) as IMenuCommandsElementProps;
    return getHeader(menuCommandsElement)
      .toLowerCase()
      .includes(token.text.toLowerCase());
  });
};

export const updateState = (
  value: PluginState,
  action: any,
  token?: IProseMirrorReferencingToken | null,
) => {
  if (action.type === SET_ELEMENTS) {
    return {
      ...value,
      elements: action.elements,
      availableElements: token
        ? setAvailableElements(action.elements, token)
        : action.elements,
    };
  }
  if (action.type === SELECT_PREVIOUS) {
    return {
      ...value,
      index: value.index > 0 ? value.index - 1 : value.index,
    };
  }
  if (action.type === SELECT_NEXT) {
    return {
      ...value,
      index:
        value.index < value.availableElements.length - 1
          ? value.index + 1
          : value.index,
    };
  }
  if (action.type === SUBMIT_ELEMENT) {
    return {
      ...value,
      submittedElement: action.element,
    };
  }
  if (action.type === SET_IS_OPEN && action.isOpen === false) {
    return initialState;
  }
  if (action.type === SET_IS_OPEN && action.isOpen === true) {
    return { ...value, isOpen: true };
  }
  return value;
};

export const initialState: PluginState = {
  isOpen: false,
  elements: [],
  availableElements: [],
  index: 0,
  submittedElement: undefined,
};

const commandsPlugin = new Plugin({
  key: commandsPluginKey,
  state: {
    init: () => {
      return initialState;
    },
    apply: (tr, value, oldState, newState) => {
      const action = tr.getMeta(commandsPlugin);
      const token = getToken(newState, TRIGGER);

      if (action) {
        return updateState(value, action, token);
      }

      if (!token) {
        return initialState;
      }
      const availableElements = setAvailableElements(
        value.elements,
        token,
      );

      return {
        ...value,
        availableElements,
      };
    },
  },
});

export default commandsPlugin;
