/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/ProtocolValue
 * @desc Renders an ProtocolValue node
 */

import React from 'react';
import ProtocolChildRouter from 'labstep-web/containers/Router/ProtocolChild';
import ViewInline from 'labstep-web/prosemirror/components/Inline/View';
import Read from 'labstep-web/prosemirror/components/NodeView/Read';
import { useReactNodeView } from 'labstep-web/prosemirror/components/ReactNodeView';
import { ICONS } from 'labstep-web/constants/icons';
import { ProtocolValue } from 'labstep-web/models';
import { ProtocolValueNodeProps } from './types';

export class ProtocolValueNode extends React.Component<ProtocolValueNodeProps> {
  // eslint-disable-next-line class-methods-use-this
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { id, timestamp } = this.props;
    const { entityName } = ProtocolValue;
    return (
      <ProtocolChildRouter route="value_show" id={id}>
        {({ navigate }) => (
          <Read entityName={entityName} id={id} timestamp={timestamp}>
            {({
              entity: protocolValue,
            }: {
              entity: ProtocolValue;
            }) => (
              <ViewInline
                entity={protocolValue}
                onClick={!timestamp ? navigate : null}
                icon={ICONS.protocol.value}
              >
                {protocolValue.label}
              </ViewInline>
            )}
          </Read>
        )}
      </ProtocolChildRouter>
    );
  }
}

const ProtocolValueNodeView = ({ timestamp }) => {
  const context = useReactNodeView();
  const { guid } = context.node.attrs;
  return <ProtocolValueNode id={guid} timestamp={timestamp} />;
};

export default ProtocolValueNodeView;
