/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Filter/Protocol/Select
 * @desc Search protocol/protocolCollection to filter ExperimentWorkflows
 */

import React from 'react';
import Modal from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import LayoutContainerActionButtons from 'labstep-web/components/Layout/Container/ActionButtons';
import Container from 'labstep-web/components/Layout/Container';
import SearchSelect from 'labstep-web/core/Select/Search';
import FilterEntity from 'labstep-web/components/Filter/Entity';
import {
  EntityReadEntityContainer,
  withActiveGroup,
} from 'labstep-web/containers';
import { Protocol, ProtocolCollection } from 'labstep-web/models';
import { ICONS } from 'labstep-web/constants';
import {
  IExperimentWorkflowFilterProtocolSelectProps,
  IProtocolChildrenProps,
  IProtocolCollectionChildrenProps,
} from './types';

export const ExperimentWorkflowFilterProtocolSelect: React.FC<
  IExperimentWorkflowFilterProtocolSelectProps
> = ({ activeGroup, setParams, searchParams }) => {
  let children = null;
  if ('protocol_id' in searchParams) {
    children = (
      <EntityReadEntityContainer
        entityName={Protocol.entityName}
        id={searchParams.protocol_id || 'random'}
      >
        {({ entity: protocol }: IProtocolChildrenProps) => (
          <>
            <SearchSelect
              entityName={ProtocolCollection.entityName}
              params={{
                group_id: activeGroup?.id,
              }}
              onChange={(selectedOption) => {
                setParams({
                  protocol_collection_id: selectedOption
                    ? selectedOption.id
                    : undefined,
                  protocol_id: undefined,
                });
              }}
              value={protocol.collection}
            />
            <br />
            <b>Specific version used</b>
            <SearchSelect
              menuPlacement="top"
              entityName={Protocol.entityName}
              labelKey="printVersion"
              params={{
                protocol_collection_id: protocol.collection.id,
              }}
              onChange={(selectedOption) => {
                if (!selectedOption) {
                  setParams({
                    protocol_collection_id: protocol.collection.id,
                    protocol_id: undefined,
                  });
                } else {
                  setParams({
                    protocol_collection_id: undefined,
                    protocol_id: selectedOption.id,
                  });
                }
              }}
              value={protocol}
            />
          </>
        )}
      </EntityReadEntityContainer>
    );
  } else {
    children = (
      <EntityReadEntityContainer
        entityName={ProtocolCollection.entityName}
        id={searchParams.protocol_collection_id || 'random'}
      >
        {({
          entity: protocolCollection,
        }: IProtocolCollectionChildrenProps) => (
          <>
            <SearchSelect
              entityName={ProtocolCollection.entityName}
              params={{
                group_id: activeGroup?.id,
              }}
              onChange={(selectedOption) => {
                setParams({
                  protocol_collection_id: selectedOption
                    ? selectedOption.id
                    : undefined,
                  protocol_id: undefined,
                });
              }}
              value={protocolCollection || null}
            />
            <br />
            <b>Specific version used</b>
            {protocolCollection ? (
              <SearchSelect
                menuPlacement="top"
                entityName={Protocol.entityName}
                labelKey="printVersion"
                params={{
                  protocol_collection_id: protocolCollection.id,
                }}
                onChange={(selectedOption) => {
                  if (!selectedOption) {
                    setParams({
                      protocol_id: undefined,
                    });
                  } else {
                    setParams({
                      protocol_collection_id: undefined,
                      protocol_id: selectedOption.id,
                    });
                  }
                }}
                value={null}
              />
            ) : (
              <p>Select a protocol above first</p>
            )}
          </>
        )}
      </EntityReadEntityContainer>
    );
  }

  return (
    <Modal
      header="Filter by Protocol"
      content={({ toggleModal }) => (
        <div>
          <Container>{children}</Container>
          <LayoutContainerActionButtons>
            <ActionComponent
              type="button"
              text="Done"
              onClick={toggleModal}
            />
          </LayoutContainerActionButtons>
        </div>
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon={ICONS.protocol_collection.primary}
          text="Protocol used"
          onClick={toggleModal}
        />
      )}
    />
  );
};

class ExperimentWorkflowFilterProtocolSelectContainer extends React.Component<
  IExperimentWorkflowFilterProtocolSelectProps,
  { shouldRenderWithFilter: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      shouldRenderWithFilter:
        'protocol_collection_id' in props.searchParams ||
        'protocol_id' in props.searchParams,
    };
  }

  render() {
    const { searchParams, setParams, ...rest } = this.props;
    const children = (
      <ExperimentWorkflowFilterProtocolSelect
        setParams={setParams}
        searchParams={searchParams}
        {...rest}
      />
    );
    if (!this.state.shouldRenderWithFilter) {
      return children;
    }
    let entityName;
    let searchParamKey;

    if (searchParams.protocol_collection_id) {
      entityName = ProtocolCollection.entityName;
      searchParamKey = 'protocol_collection_id';
    } else if (searchParams.protocol_id) {
      entityName = Protocol.entityName;
      searchParamKey = 'protocol_id';
    } else {
      return children;
    }

    return (
      <FilterEntity
        entityName={entityName}
        searchParamKey={searchParamKey}
        searchParams={searchParams}
      >
        {() => children}
      </FilterEntity>
    );
  }
}

export default withActiveGroup(
  ExperimentWorkflowFilterProtocolSelectContainer,
);
