/**
 * Labstep
 *
 * @module core/MediaQuery/Mobile
 * @desc MediaQuery for Mobile
 */

import React from 'react';
import MediaQuery from './MediaQuery';
import { IMediaQueryProps, Size } from './MediaQuery/types';

type IMediaQueryMobileProps = Omit<IMediaQueryProps, 'size'>;

const MediaQueryMobile: React.FC<IMediaQueryMobileProps> = ({
  children,
}) => <MediaQuery size={Size.mobile}>{children}</MediaQuery>;

export default MediaQueryMobile;
