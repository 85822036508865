/**
 * Labstep
 *
 * @module components/User/Settings/MonthlyReportOptout
 * @desc Monthly Report Optout
 */

import React from 'react';
import { generateNewDateString } from 'labstep-web/services/date.service';
import Checkbox from 'labstep-web/core/Checkbox';
import { EntityUpdateContainer } from 'labstep-web/containers';
import { IUserSettingsMonthlyReportOptoutProps } from './types';

export const UserSettingsMonthlyReportOptout: React.FC<
  IUserSettingsMonthlyReportOptoutProps
> = ({ user }) => (
  <EntityUpdateContainer entityName="user" id={user.id}>
    {({ update, status }) => (
      <Checkbox
        label="I’d like to receive the monthly report email"
        checked={!user.monthly_report_optout_at}
        onChange={() =>
          update({
            monthly_report_optout_at: user.monthly_report_optout_at
              ? null
              : generateNewDateString(),
          })
        }
        disabled={status && status.isFetching}
      />
    )}
  </EntityUpdateContainer>
);

export const UserSettingsMonthlyReportOptoutContainer: React.FC<
  IUserSettingsMonthlyReportOptoutProps
> = ({ user }) => (
  <div id="monthly-report">
    <div>
      <span>Monthly report email</span>
    </div>
    <UserSettingsMonthlyReportOptout user={user} />
  </div>
);

export default UserSettingsMonthlyReportOptoutContainer;
