/**
 * Labstep
 *
 * @module screens/Authentication/SAML/Login
 * @desc Assert screen for saml sso
 */

import React from 'react';
import { withRouter } from 'react-router';
import Loader from 'labstep-web/core/Loader';
import { UserSAMLLoginContainer } from 'labstep-web/containers';
import { windowService } from 'labstep-web/services/window.service';
import styles from './styles.module.scss';

export class Login extends React.Component<any> {
  componentDidMount() {
    const { token, samlLogin } = this.props;
    const organization = windowService.detectOrganization();
    const organizationIdentifer = organization
      ? organization.identifier
      : 'not-found';
    samlLogin(organizationIdentifer, { token });
  }

  render() {
    const { status } = this.props;
    return (
      <div className={styles.container}>
        {!(status && status.error) ? (
          <Loader active size="huge">
            Signing in...
          </Loader>
        ) : (
          <div>
            Oops something went wrong! Please refresh your page. If
            the problem still persist please contact Labstep at
            info@labstep.com
          </div>
        )}
      </div>
    );
  }
}

const AssertContainer = ({
  match: {
    params: { token },
  },
  history,
}) => (
  <UserSAMLLoginContainer>
    {({ samlLogin, status }) => (
      <Login
        history={history}
        token={token}
        samlLogin={samlLogin}
        status={status}
      />
    )}
  </UserSAMLLoginContainer>
);

export default withRouter(AssertContainer);
