/**
 * Labstep
 *
 * @module screens/User/Deleted
 * @desc Screen showing deleted user message
 */

import React from 'react';
import Header from 'labstep-web/core/Header';
import OrganizationHeader from 'labstep-web/components/Organization/Header';
import ActionComponent from 'labstep-web/core/Action/Component';
import { withAuthenticatedUser } from 'labstep-web/containers';
import store from 'labstep-web/state/store';
import { logoutHardAction } from 'labstep-web/state/actions';
import styles from './styles.module.scss';
import { IScreensUserDeletedProps } from './types';

export const ScreensUserDeleted: React.FC<
  IScreensUserDeletedProps
> = ({ authenticatedUser }) => (
  <div className={styles.container}>
    <OrganizationHeader
      organization={
        authenticatedUser.user_organizations[0].organization
      }
    />
    <Header>
      This account has been disabled by the organization admin
    </Header>
    <p>Please contact admin to reactivate your account.</p>
    <ActionComponent
      onClick={() => store.dispatch(logoutHardAction())}
      text="Sign Out"
      type="button"
      elementProps={{ color: 'red' }}
    />
  </div>
);

export default withAuthenticatedUser(ScreensUserDeleted);
