/**
 * Labstep
 *
 * @module screens/ResourceTemplate/Index
 * @desc ResourceTemplate index
 */

import React, { useMemo } from 'react';
import ResourceTemplateEmptyState from 'labstep-web/components/ResourceTemplate/Card/EmptyState';
import MasterIndex from 'labstep-web/screens/Master/Index';
import { Resource } from 'labstep-web/models';
import { colDefResourceActionMenu } from 'labstep-web/components/Resource/DataGrid/colDefs/actionMenu';
import ResourceTemplateActionCreate from 'labstep-web/components/ResourceTemplate/Action/Create';
import ResourceListOrTable from 'labstep-web/components/Resource/ListOrTable';
import colDefBulkSelect from 'labstep-web/components/Entity/DataGrid/colDefs/bulkSelect';
import { IEntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import { colDefNameTemplate } from 'labstep-web/components/Entity/DataGrid/colDefs/name/template';
import { bulkActions, filters } from './constants';
import { IResourceTemplateIndexProps } from './types';

export const ResourceTemplateIndex: React.FC<
  IResourceTemplateIndexProps
> = ({ group }) => {
  const params = useMemo(
    () => ({ group_id: group.id, is_template: 1 }),
    [group.id],
  );
  const action = useMemo(() => <ResourceTemplateActionCreate />, []);
  return (
    <MasterIndex
      isTemplate
      params={params}
      noResultsMessage={<ResourceTemplateEmptyState />}
      entityName={Resource.entityName}
      action={action}
      bulkActions={bulkActions}
      filters={filters}
      useAllPages
      tableFormat={false}
    >
      {({
        entities,
        readNextPage,
        status,
      }: IEntitySearchContainerChildrenProps) => (
        <ResourceListOrTable
          resources={entities}
          loadMore={readNextPage}
          status={status}
          columnDefs={[
            colDefResourceActionMenu,
            colDefBulkSelect,
            colDefNameTemplate(Resource.entityName),
          ]}
        />
      )}
    </MasterIndex>
  );
};

export default ResourceTemplateIndex;
