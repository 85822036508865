/**
 * Labstep
 *
 * @module models/file
 * @desc Typescript export class for File
 */

import { Type } from 'class-transformer';
import { User } from 'labstep-web/models/user.model';
import { Entity } from 'labstep-web/models/entity.model';
import filesize from 'filesize';
import { allowedImageExtensions } from 'labstep-web/config';

export const fileTypes = [
  'csv',
  'doc',
  'docx',
  'generic',
  'jpg',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'svg',
  'xls',
  'xlsx',
  'xml',
];

// eslint-disable-next-line no-shadow
enum IconType {
  docx = 'docx',
  doc = 'doc',
  generic = 'generic',
  pdf = 'pdf',
  csv = 'csv',
  jpg = 'jpg',
  png = 'png',
  ppt = 'ppt',
  pptx = 'pptx',
  svg = 'svg',
  xlsx = 'xlsx',
  xls = 'xls',
  xml = 'xml',
}

export class File extends Entity {
  static readonly entityName = 'file';

  get entityName(): typeof File.entityName {
    return File.entityName;
  }

  constructor(data: Partial<File> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  guid!: string;

  name!: string;

  path!: string;

  mime_type!: string;

  description!: string;

  size!: number;

  image_annotation_data: any;

  is_external!: boolean;

  is_snapgene!: boolean;

  is_snapgene_processing!: boolean;

  extension!: string;

  link_source?: 'onedrive' | 'googledrive' | 'dropbox' | 'box';

  parent_type!: string;

  @Type(() => User)
  author!: User;

  @Type(() => File)
  snapgene_output_png!: File;

  @Type(() => File)
  snapgene_output_svg!: File;

  @Type(() => File)
  annotated_file?: File;

  @Type(() => File)
  image_annotation?: File;

  get canDownload() {
    return !this.isLinked;
  }

  get isDna(): boolean {
    return ['gb', 'fasta'].includes(this.extension);
  }

  get filesize(): string {
    return filesize(this.size);
  }

  get printLinkSource(): string {
    let label = '';
    if (this.link_source === 'onedrive') {
      label = 'File from One Drive';
    }
    if (this.link_source === 'googledrive') {
      label = 'File from Google Drive';
    }
    if (this.link_source === 'dropbox') {
      label = 'File from Dropbox';
    }
    if (this.link_source === 'box') {
      label = 'File from Box';
    }
    return label;
  }

  get isImage(): boolean {
    if (
      this.extension === 'svg' &&
      this.mime_type !== 'image/svg+xml'
    ) {
      return false;
    }
    return allowedImageExtensions.includes(`.${this.extension}`);
  }

  get isSvg(): boolean {
    return this.extension === 'svg';
  }

  get isPdf(): boolean {
    return this.extension === 'pdf';
  }

  get isGoogleDoc(): boolean {
    return (
      this.link_source === 'googledrive' &&
      (['gdoc', 'gsheet', 'gslides'].indexOf(this.extension) > -1 ||
        this.mime_type === 'application/vnd.google-apps.drawing')
    );
  }

  get isText(): boolean {
    return (
      this.mime_type.startsWith('text') ||
      this.mime_type.endsWith('json') ||
      this.mime_type.endsWith('xml')
    );
  }

  get icon(): IconType {
    switch (this.extension) {
      case 'docx':
        return IconType.docx;
      case 'doc':
        return IconType.doc;
      case 'pdf':
        return IconType.pdf;
      case 'csv':
        return IconType.csv;
      case 'jpg':
      case 'jpeg':
        return IconType.jpg;
      case 'png':
        return IconType.png;
      case 'ppt':
        return IconType.ppt;
      case 'pptx':
        return IconType.pptx;
      case 'svg':
        return IconType.svg;
      case 'xlsx':
        return IconType.xlsx;
      case 'xls':
        return IconType.xls;
      case 'xml':
        return IconType.xml;
      default:
        return IconType.generic;
    }
  }

  get iconPath(): string {
    return File.getIconPath(this.icon);
  }

  static getIconPath(icon: string): string {
    return `/img/file/icons/files-${icon}.png`;
  }

  get isLinked(): boolean {
    return Boolean(this.link_source);
  }

  get isOfficeFile(): boolean {
    return (
      ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].indexOf(
        this.extension,
      ) > -1
    );
  }

  get canViewInline(): boolean {
    return (
      ((this.isPdf || this.isOfficeFile) && !this.isLinked) ||
      this.isGoogleDoc
    );
  }

  get canAnnotate(): boolean {
    return this.isImage && !this.isLinked;
  }
}
