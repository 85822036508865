/**
 * Labstep
 *
 * @module components/Folder/Card/Manager
 * @desc Folder Manager Card component
 */

import React from 'react';
import FolderList from 'labstep-web/components/Folder/List';
import EntityToggleList from 'labstep-web/components/Entity/List/Toggle';
import Modal from 'labstep-web/core/Modal/Default';
import Popup from 'labstep-web/core/Popup';
import Button from 'labstep-web/core/Button';
import { Folder } from 'labstep-web/models';
import { withActiveGroup } from 'labstep-web/containers';
import { ICONS } from 'labstep-web/constants';
import { CONFIG } from 'labstep-web/constants/config';
import { IFolderCardManagerProps } from './types';

export const FolderCardManager: React.FC<IFolderCardManagerProps> = ({
  parentEntity,
  activeGroup,
}) => (
  <Modal
    header="Manage Collections"
    viewComponent={({ toggleModal }) => (
      <Popup
        trigger={
          <Button
            onClick={toggleModal}
            icon={ICONS.folder.primary}
            circular
          />
        }
        content="Manage Collections"
        size="mini"
      />
    )}
    content={
      <EntityToggleList
        parent={parentEntity}
        entityName={Folder.entityName}
        labelKey="folder_path"
        caseSensitive={false}
        extraParams={{
          group_id: activeGroup?.id,
          count: CONFIG.folder.count,
        }}
        creatable
        createBody={{ type: parentEntity.entityName }}
        actionMessageIcon={ICONS.folder.primary}
        list={({ entities }) => (
          <FolderList
            folders={entities}
            parentEntity={parentEntity}
          />
        )}
      />
    }
  />
);

export default withActiveGroup(FolderCardManager);
