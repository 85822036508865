/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Create
 * @desc Create Action for order request workflow
 */

import React from 'react';
import FormReusable from 'labstep-web/core/Form/Reusable';
import {
  DEFAULT_QUANTITY_VALUE,
  fieldQuantity,
  fieldUrgent,
} from 'labstep-web/components/OrderRequest/Form/fields';
import { OrderRequest } from 'labstep-web/models';
import { EntityCreateContainer } from 'labstep-web/containers';
import { IOrderRequestFormCreateBulkProps } from './types';

export const OrderRequestFormCreateBulk: React.FC<
  IOrderRequestFormCreateBulkProps
> = ({ resources, options }) => {
  const fields: any = [fieldQuantity, fieldUrgent];
  return (
    <EntityCreateContainer entityName={OrderRequest.entityName} batch>
      {({ create, status }) => (
        <FormReusable
          defaultValues={{ quantity: DEFAULT_QUANTITY_VALUE }}
          status={status}
          autoFocus
          onSubmit={(values) =>
            create(
              resources.map((entity) => ({
                resource_id: entity.id,
                ...values,
              })),
              { ...options, toast: true, params: { no_output: 1 } },
            )
          }
          fields={fields}
          submitButtonText="Request"
        />
      )}
    </EntityCreateContainer>
  );
};

export default OrderRequestFormCreateBulk;
